import React, { Component } from 'react';
import {
    Grid,
    withStyles,
    Typography
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import i18n from 'i18next';
import _ from 'lodash'
import { connect } from 'react-redux'


const styles = theme => ({
    invoice__form__total: {
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
    },
    invoice__form__total__preparing: {
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        color: theme.palette.red[100]
    },
    invoice__form__total__pending: {
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        color: theme.palette.error.main
    },
    totalAmount: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    invoice__summary__status: {
        display: 'flex',
        alignItems: 'center'
    },
    invoice__preparing__amount: {
        marginRight: '16px'
    },
    invoice__status__icon__preparing: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    invoice__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    }
});

class Invoices extends Component {

    state = {
        file: this.props.file
    }


    componentWillMount() {
        this.calculateAmounts(this.props)
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file
        })
        this.calculateAmounts(nextProps)
    }


    calculateAmounts = (props) => {
        const { file } = props;

        let invoiced = 0,
            preparing = 0,
            invoicedOtherCosts = 0,
            preparingOtherCost = 0,
            prepatingTaxesTotal = 0,
            invoicedTaxesTotal = 0;

        var afterInvoices = _.after(file.invoices.length, () => {
            this.setState({
                invoiced: invoiced + invoicedOtherCosts + (invoiced === 0 ? 0 : invoicedTaxesTotal),
                preparing: preparing + preparingOtherCost + (preparing === 0 ? 0 : prepatingTaxesTotal)
            })
        })

        file.invoices.forEach((invoice) => {

            let items = invoice.items ? invoice.items.map((item) => {
                let poItem = file && file.clientQuote[0] ? file.clientQuote[0].clientPO.items.find(i => i.id === item.clientPOItemId) : null;

                return {
                    ...poItem,
                    rfqItem: item.rfqItem,
                    invoiceQuantity: item.invoiceQuantity ? item.invoiceQuantity : null
                }
            }) : [];

            if (invoice.status !== 1) {
                invoicedTaxesTotal = invoicedTaxesTotal + items.reduce(((total, row) => {
                    return (total + (row.taxPercent && file.clientQuote[0].taxPercent ? (row.total * file.clientQuote[0].taxPercent) / 100 : 0))
                }), 0);
                invoiced = invoiced + items.reduce(((total, row) => {
                    return (total + row.total)
                }), 0)
                invoicedOtherCosts = invoicedOtherCosts + invoice.otherCosts.reduce(((total, row) => parseFloat(total) + (row.invoiceAmount ? row.invoiceAmount : 0)), 0);
            } else {
                prepatingTaxesTotal = prepatingTaxesTotal + items.reduce(((total, row) => {
                    return (total + (row.taxPercent && file.clientQuote[0].taxPercent ? (row.total * file.clientQuote[0].taxPercent) / 100 : 0))
                }), 0);
                preparing = preparing + items.reduce(((total, row) => {
                    return (total + row.total)
                }), 0)
                preparingOtherCost = preparingOtherCost + invoice.otherCosts.reduce(((total, row) => total + (row.invoiceAmount ? row.invoiceAmount : 0)), 0);
            }
            afterInvoices()
        })
    }


    render() {
        const { classes } = this.props,
            { file, invoiced, preparing } = this.state;

        let requested = file.clientQuote[0].clientPO.items.reduce(((total, row) => {
            return (total + row.total)
        }), 0),
            requestedOtherCosts = file.clientQuote[0].otherCosts.filter(oc => !oc.weighted).reduce(((total, row) => parseFloat(total) + row.amount), 0),
            requestedTaxes = file.clientQuote[0] && file.clientQuote[0].clientPO.items.reduce(((total, row) => {
                return (total + (row.taxPercent && file.clientQuote[0].taxPercent ? (row.total * file.clientQuote[0].taxPercent) / 100 : 0))
            }), 0);

        console.log("Quoted", typeof requested + requestedOtherCosts + requestedTaxes)
        console.log("Invoiced", typeof invoiced)
        console.log("Total", ((requested + requestedOtherCosts + requestedTaxes) - invoiced).toFixed(2))

        return (
            <Grid container spacing={1} style={{ marginLeft: '8px', marginBottom: '8px' }}>
                <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column' }}>

                    <div className={classes.totalAmount}>
                        <Typography variant={'body1'} color={'textPrimary'} style={{ marginRight: '16px' }}>
                            {i18n.t('quotes.invoices.summary.requested')}
                        </Typography>

                        <NumberFormat value={requested + requestedOtherCosts + requestedTaxes}
                            displayType={'text'}
                            decimalScale={2}
                            prefix={file.currencySymbol}
                            className={classes.invoice__form__total}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                        />
                    </div>

                    <div className={classes.totalAmount}>
                        <div className={classes.invoice__summary__status}>
                            <Typography variant={'body1'} color={'textPrimary'} style={{ marginRight: '16px' }}>
                                {i18n.t('quotes.invoices.summary.invoiced')}
                            </Typography>
                        </div>
                        <div>
                            <NumberFormat value={invoiced}
                                displayType={'text'}
                                decimalScale={2}
                                prefix={file.currencySymbol}
                                className={classes.invoice__form__total}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                            />
                            {file.invoices && file.invoices.length > 0 && file.invoices.filter(inv => inv.status === 1).length > 0 &&
                                <>(
                                <NumberFormat value={preparing}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={file.currencySymbol}
                                        className={classes.invoice__form__total__preparing}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                )</>
                            }
                        </div>
                    </div>

                    {((requested + requestedOtherCosts + requestedTaxes) - invoiced).toFixed(2) > parseFloat(0) &&
                        <div className={classes.totalAmount} style={{ borderTop: '1px solid #000' }}>
                            <Typography variant={'body1'} color='error' style={{ marginRight: '16px' }}>
                                {i18n.t('quotes.invoices.summary.pending')}
                            </Typography>
                            <NumberFormat value={((requested + requestedOtherCosts + requestedTaxes) - invoiced).toFixed(2) < 0 ? 0 : ((requested + requestedOtherCosts + requestedTaxes) - invoiced).toFixed(2)}
                                displayType={'text'}
                                decimalScale={2}
                                prefix={file.currencySymbol}
                                className={classes.invoice__form__total__pending}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                            />
                        </div>
                    }
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        files: state.files
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Invoices))
