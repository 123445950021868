import React, { Component } from 'react'
import { blueGrey } from '@material-ui/core/colors'
import {
    Dialog, Grid,
    IconButton,
    Typography,
    Paper, Hidden,
    Fade,
    withStyles,
    Stepper, Step,
    StepLabel,
    DialogContent,
    DialogTitle,
    Tabs, Badge,
    Tab, CircularProgress
} from '@material-ui/core'
import moment from "moment";
import PropTypes from 'prop-types';
import { Close, Notifications } from '@material-ui/icons'
import i18n from 'i18next';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../../actions/filesActions'
import * as clientsActions from '../../actions/clientsActions'
import * as generalActions from '../../actions/generalActions'
import * as apiStatusActions from '../../actions/apiStatusActions'


import { SlideUp } from '../general/Transitions'
import { ClientRFQCard } from './clientRFQ';
import { VendorQuoteRequests } from './vendorQR';
import { PreparingClientQuote } from './preparingQR';
import { POs } from './po';
import { Invoices } from './invoices';
import { NotesList } from '../notes';
import { ActionsBar } from '.';
import API from '../../lib/api'
import auth from '../../authentication/auth';

import Permissions from '../../lib/permissions'

const styles = theme => {
    return ({
        file__details__appbar: {
            position: 'sticky',
        },
        file__details__appbar__toolbar: {
            maxWidth: theme.maxWith,
            margin: 'auto',
            width: '100%',
            position: 'relative',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing()
        },
        file__details__notes_container: {
            padding: theme.spacing(2),
        },
        file__details__appbar__toolbar__header_container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            color: 'white'
        },
        file__details__gridContainer: {
            margin: 'auto',
            marginBottom: 0,
            marginTop: theme.spacing(2),
            maxWidth: theme.maxWith,
        },
        file__details__gridItem: {
            marginBottom: theme.spacing()
        },
        file__details__dialog__paper: {
            height: '100%',
            width: '100%',
            backgroundColor: blueGrey[50]
        },
        loading__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            height: '60vh'
        },
        'file__details__focus__hover--transparent': {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            width: '100%',
            opacity: '0',
            zIndex: 1
        },
        file__details__focus__hover: {
            backgroundColor: '#000',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            width: '100%',
            opacity: '0.3',
            zIndex: 1
        },
        file__tabs__grid: {
            marginBottom: 0
        },
        file__tabs__root: {
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100]
        },
        file__tabs__note__root: {
            minWidth: '90px',
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100],
            marginLeft: 'auto'
        },
        'file__tabs--selected': {
            backgroundColor: '#fff',
            borderTop: `1px solid ${theme.palette.grey[400]}`,
            borderLeft: `1px solid ${theme.palette.grey[400]}`,
            borderRight: `1px solid ${theme.palette.grey[400]}`,
            borderBottom: 'none',
            color: theme.palette.text.primary,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
            zIndex: 2
        },
        'file__tabs--disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[50]
        },
        quotes__statusTabs: {
            zIndex: 4
        },
        quotes__statusTabs__activeIndicator: {
            backgroundColor: 'transparent'
        },
        quotes__fileNotes: {
            position: 'relative'
        },
        notes__tasks__badge: {
            top: theme.spacing() / 2,
            right: theme.spacing(),
            fontSize: '8pt',
            backgroundColor: 'transparent'
        },
        invoice__badge: {
            top: theme.spacing() / 2,
            right: '-12px',
            fontSize: '8pt',
            backgroundColor: '#c4c4c4'
        },
        'tasks__notification__icon--yellow': {
            margin: `0 ${theme.spacing()}px ${theme.spacing() - 5}px`,
            fontSize: '14pt',
            color: theme.palette.orange[100],
        },
        'tasks__notification__icon--red': {
            margin: `0 ${theme.spacing()}px ${theme.spacing() - 5}px`,
            fontSize: '14pt',
            color: theme.palette.red[100],
        },
        action__bar__currentstatus__label: {
            color: theme.palette.blue[100]
        },
        fileDetails__fileName: {
            color: theme.palette.blue[100]
        }
    })
};




class StatusStepsIndicator extends Component {
    static propTypes = {
        currentFileStatus: PropTypes.number.isRequired
    }
    render = () => {
        const {
            currentFileStatus,
            width,
            classes,
            file
        } = this.props;

        const fileTabs = [
            i18n.t("quotes.detailsTabs.rfq"),
            i18n.t("quotes.detailsTabs.vendors"),
            i18n.t("quotes.detailsTabs.quote"),
            i18n.t("quotes.detailsTabs.purchaseOrder"),
            i18n.t("quotes.detailsTabs.accounting"),
            i18n.t("quotes.detailsTabs.delivered")
        ]

        let stepIndex = currentFileStatus <= 3 ? currentFileStatus - 1 : currentFileStatus - 2,
            completed = 0;

        if (file.vendorQuotes.length > 0) {
            completed = 1
        }

        if (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].validFor && file.clientQuote[0].deliveryDays) {
            completed = 2
        }

        if (file.invoices && file.invoices.length > 0) {
            completed = 4
            stepIndex = 4
        }

        if (file.status === 7) {
            completed = 5
        }


        if (currentFileStatus) {
            return <>
                <Hidden xsDown implementation="css">
                    <Paper square>
                        <Stepper
                            activeStep={stepIndex}
                            alternativeLabel={isWidthUp('md', width) || width === 'xs' ? false : true}
                            orientation='horizontal' >
                            {fileTabs.map((fileStep, k) => {
                                // if (!Permissions.checkSensitiveInformationPermission() && fileStep === 'Vendors') {
                                //     return (
                                //         null
                                //     )
                                // } else {
                                return (
                                    <Step completed={completed >= k ? true : false} key={k}>
                                        <StepLabel classes={{ label: classes.step__label }}>{fileStep}</StepLabel>
                                    </Step>
                                )
                                // }
                            })}
                        </Stepper>
                    </Paper>
                </Hidden>

                <Hidden smUp implementation="css">
                    <Paper square>
                        <Stepper activeStep={stepIndex}
                            alternativeLabel={false}
                            orientation='vertical'
                        >
                            {fileTabs.map((fileStep, k) => {
                                // if (!Permissions.checkSensitiveInformationPermission() && fileStep === 'Vendors') {
                                //     return null
                                // } else {
                                return (
                                    <Step completed={completed >= k ? true : false} key={k}>
                                        <StepLabel>{fileStep}</StepLabel>
                                    </Step>
                                )
                                // }
                            })}
                        </Stepper>
                    </Paper>
                </Hidden>
            </>
        } else {
            return null;
        }
    }
}





class FileDetails extends Component {
    state = {
        showDialog: this.props.open,
        fileId: null,
        file: null,
        loading: true,
        selectedInvoice: null,
        fetching: false,
        selectedTab: this.props.showTasksSection ? 5 : 0,
        logedInUser: null,
        focusSection: null,
        disableExitButton: false,
        showLog: false
    }


    fetchFile = (fileId) => {
        if (fileId && !this.state.fetching) {
            this.setState({
                loading: true,
                file: null,
                fetching: true
            }, () => {
                API.Files.getFile({
                    fileId
                }).then(res => {
                    if (res.file) {
                        const { clients } = this.props;
                        this.props.filesActions.setFile(res.file);
                        let client = clients.find(c => c.id === res.file.client.id);
                        this.props.clientsActions.setClient(client);

                        let selectedTab = 0;
                        switch (res.file.status) {
                            case 1:
                            case 2:
                                if (!Permissions.checkSensitiveInformationPermission()) {
                                    selectedTab = 0;
                                } else {
                                    selectedTab = res.file.status - 1;
                                }
                                break
                            case 3:
                                selectedTab = res.file.status - 1;
                                break;
                            case 4:
                                selectedTab = 2;
                                break;
                            case 5:
                                let vpItems = [];

                                res.file.vendorPOs.filter(vq => !vq.isVoid).forEach(vp => {
                                    vpItems = vpItems.concat(vp.items)
                                });

                                let receivedItems = vpItems.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0)

                                if (res.file.clientQuote[0].clientPO.status.id === 2 || res.file.clientQuote[0].clientPO.status.id === 3 || receivedItems > 0) {
                                    selectedTab = 4;
                                } else {
                                    selectedTab = 3;
                                }
                                break;
                            case 6:
                            case 7:
                            case 8:
                                selectedTab = 4;
                                break;
                            default:
                                selectedTab = 0;
                                break;
                        }
                        this.setState({
                            fileId,
                            selectedTab: this.props.showTasksSection ? 5 : selectedTab,
                            file: res.file,
                            loading: false,
                            fetching: false
                        })

                    }
                })
            })
        }
    }

    updateFileStatus = (file) => {
        let selectedTab = 0,
            listIndex = 0;

        switch (file.status) {
            case 1:
            case 2:
            case 3:
                selectedTab = file.status - 1;
                listIndex = file.status - 1;
                break;
            case 4:
                selectedTab = 2;
                listIndex = 2;
                break;
            case 5:
                selectedTab = 3;
                listIndex = 3;
                break;
            case 6:
            case 7:
            case 8:
                selectedTab = 4;
                listIndex = 4;
                break;
            default:
                selectedTab = 0;
                break;
        }
        this.props.refreshFilesLists && this.props.refreshFilesLists(listIndex)

        this.setState({
            file: file,
            loading: false,
            selectedTab: selectedTab
        })

        this.props.filesActions.setFile(file);

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.general.showInvoiceForm) {
            this.setState({
                selectedTab: 4
            })
        }

        if (nextProps.general.showVendorsPo) {
            this.setState({
                selectedTab: 3
            })
        }
        if (nextProps.general.showVendorsTab && Permissions.checkSensitiveInformationPermission()) {
            this.setState({
                selectedTab: 1
            }, () => {
                this.props.generalActions.showVendorsTab && this.props.generalActions.showVendorsTab(false)
            })
        }

        if (nextProps.open !== this.state.showDialog) {
            this.setState({
                showDialog: nextProps.open,
            });

        }
        if ((nextProps.fileId !== this.state.fileId)) {
            this.fetchFile(nextProps.fileId);
        }
        if (nextProps.showTasksSection) {
            this.setState({
                selectedTab: 5,
            })
        }
    }

    componentDidMount = () => {
        const { status } = this.props;

        let selectedTab = 0;

        switch (status) {
            case 1:
            case 2:
                if (!Permissions.checkSensitiveInformationPermission()) {
                    selectedTab = 0;
                } else {
                    selectedTab = status - 1;
                }
                break
            case 3:
                selectedTab = status - 1;
                break;
            case 4:
                selectedTab = 2;
                break;
            case 5:
                selectedTab = 3;
                break;
            case 6:
            case 7:
            case 8:
                selectedTab = 4;
                break;
            default:
                selectedTab = 0;
                break;
        }


        this.setState({
            selectedTab
        })
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    handleClose = () => {
        this.props.filesActions.updateFile(this.state.file)
        this.props.onClose()
    }

    clean = () => {
        this.setState({
            selectedTab: 0,
            fileId: null,
            file: null,
            loading: true,
            focusSection: null,
        })
        this.props.filesActions.setFile({})
    }

    updateFile = (file) => {
        file.rfqItemsCount = file.items.length;
        this.props.updateFileList && this.props.updateFileList(file)
        this.setState({
            file: file
        })
    }


    updateVendorQuote = (file) => {

        this.props.filesActions.updateFile(file)

        this.setState({
            file
        })
    }

    updateClientQuote = (clientQuote) => {
        const { file } = this.state;
        let newClientQuote = [
            ...file.clientQuote
        ];

        newClientQuote[0] = clientQuote;

        this.setState({
            file: {
                ...file,
                clientQuote: newClientQuote
            }
        })
    }

    handleChange = (e, value) => {
        this.setState({ selectedTab: value });
    }

    showLogTab = () => {
        this.setState({
            selectedTab: 5,
            showLog: true
        })
    }

    deleteFile = (fileId, successDeleteMessage) => {
        this.props.filesActions.deleteFile(fileId)
        this.props.onClose(successDeleteMessage)
    }

    showInvoice = () => {
        this.setState({
            selectedTab: 4
        })
    }

    allowInvoice = () => {
        const { file } = this.state;

        let vpItems = []

        file.vendorPOs.filter(vq => !vq.isVoid).forEach(vp => {
            vpItems = vpItems.concat(vp.items)
        });

        let receivedItems = vpItems.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0)

        return receivedItems > 0 ? true : false
    }


    render() {
        const {
            showDialog,
            file,
            loading,
            selectedTab,
            logedInUser,
            disableExitButton,
            showLog
        } = this.state,
            {
                olderTask,
                notesCount,
                focusedVendorQuote,
                classes,
                fileId,
                showVendorsPOs,
                showTasksSection,
            } = this.props;

        if (!file) return null;

        const fileTabs = [
            i18n.t("quotes.detailsTabs.rfq"),
            i18n.t("quotes.detailsTabs.vendors"),
            i18n.t("quotes.detailsTabs.quote"),
            i18n.t("quotes.detailsTabs.purchaseOrder"),
            i18n.t("quotes.detailsTabs.accounting")
        ];

        let currentStatusLabel = ''

        switch (file.status) {
            case (1):
                currentStatusLabel = i18n.t('quotes.actionsBar.preparingRFQ');
                break;
            case (2):
                currentStatusLabel = i18n.t('quotes.actionsBar.requestingQuotes');
                break;
            case (3):
                currentStatusLabel = i18n.t('quotes.actionsBar.preparingQuote');
                break;
            case (4):
                if (file.finished && (file.finishStatus.id === 3 || file.finishStatus.id === 2)) {
                    currentStatusLabel = ''
                } else {
                    currentStatusLabel = i18n.t('quotes.actionsBar.waitingResponse');
                }
                break;
            case (5):
                if (file.invoices && file.invoices.length > 0) {
                    currentStatusLabel = i18n.t('quotes.actionsBar.preparingInvoice');
                } else {
                    currentStatusLabel = i18n.t('quotes.actionsBar.preparingPO');
                }

                break;
            case (9):
                currentStatusLabel = i18n.t('quotes.actionsBar.waitingPo');
                break
            default:
                break;
        }

        if (file.finished) {
            currentStatusLabel = i18n.t('quotes.actionsBar.finished');
        }

        return (
            <Dialog
                classes={{
                    paper: classes.file__details__dialog__paper
                }}
                open={showDialog}
                fullScreen={true}
                TransitionComponent={SlideUp}
                onExited={this.clean}>
                <DialogTitle style={{ padding: 0 }}>
                    <div className={classes.file__details__appbar__toolbar}>
                        <div className={classes.file__details__appbar__toolbar__header_container}>
                            <Grid container spacing={0} alignItems='center' justify='center'>
                                <Grid item sm={8} xs={8}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Typography variant='body1' color="inherit">{i18n.t('quotes.details.file')} {fileId} - {file.client.name}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                disabled={disableExitButton}
                                aria-label="Close">
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent>

                    <Grid container
                        justify="center"
                        className={classes.file__details__gridContainer} spacing={2}>

                        <Grid item
                            xs={11}
                            sm={11}>

                            <StatusStepsIndicator
                                {...this.props}
                                currentFileStatus={(file.finished && file.finishStatus.id === 4) ? 6 : (file.status) ? file.status : 1}
                            />
                        </Grid>
                        <Grid item xs={11} sm={11} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h4' className={classes.fileDetails__fileName}>{i18n.t('quotes.details.file')} {fileId}</Typography>
                            <Typography variant='h6' className={classes.action__bar__currentstatus__label} color='textSecondary'>{currentStatusLabel}</Typography>
                        </Grid>
                    </Grid>



                    {!loading && (
                        <>
                            <ActionsBar
                                file={file}
                                showLogTab={this.showLogTab}
                                updateFileStatus={this.updateFileStatus}
                                deleteFile={this.deleteFile}
                            />

                            <Grid container
                                justify="center"
                                className={classes.file__details__gridContainer}>

                                {/* FILE TABS */}
                                <Grid item
                                    xs={11}
                                    sm={11}
                                    className={classes.file__tabs__grid}>
                                    <Tabs className={classes.quotes__statusTabs}
                                        value={selectedTab}
                                        onChange={this.handleChange}
                                        indicatorColor="primary"
                                        textColor="secondary"
                                        classes={{
                                            indicator: classes.quotes__statusTabs__activeIndicator
                                        }}
                                    >
                                        {fileTabs.map((name, i) => {
                                            if (!Permissions.checkSensitiveInformationPermission() && name === 'Vendors') {
                                                return (
                                                    <Tab disabled={true} style={{ width: 0, minWidth: 0, padding: 0 }} />
                                                )
                                            } else {
                                                return (
                                                    <Tab
                                                        key={i}
                                                        label={
                                                            <div>
                                                                {name}
                                                            </div>
                                                        }
                                                        classes={{
                                                            root: classes.file__tabs__root,
                                                            selected: classes['file__tabs--selected'],
                                                            disabled: classes['file__tabs--disabled']
                                                        }}
                                                        disabled={(i > (
                                                            file.status <= 3 ? file.status - 1 :
                                                                file.status === 4 ? file.status - 2 :
                                                                    file.status === 9 ? 2 :
                                                                        file.status === 5 && !this.allowInvoice() ? file.status - 2 : 10))}
                                                    />
                                                )
                                            }
                                        })}

                                        <Tab
                                            label={
                                                <div>
                                                    {i18n.t('quotes.details.notes')}
                                                    {notesCount > 0 &&
                                                        <Badge
                                                            badgeContent={notesCount}
                                                            classes={{ badge: classes.notes__tasks__badge }} >
                                                            <Notifications fontSize="small" className={moment().diff(moment(olderTask), 'days') > 4 ? classes['tasks__notification__icon--red'] : classes['tasks__notification__icon--yellow']} />
                                                        </Badge>
                                                    }
                                                </div>
                                            }
                                            classes={{
                                                root: classes.file__tabs__note__root,
                                                selected: classes['file__tabs--selected']
                                            }}>
                                        </Tab>
                                    </Tabs>
                                </Grid>

                                {(selectedTab === 4) && (
                                    <Fade in={selectedTab === 4}>
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem}>
                                            <Invoices
                                                fileId={fileId}
                                                editable={this.allowInvoice() ? true : false}
                                                file={file} />
                                        </Grid>
                                    </Fade>
                                )}


                                {(selectedTab === 3) && (
                                    <Fade in={selectedTab === 3}>
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem}>
                                            <POs
                                                fileId={fileId}
                                                file={file}
                                                updateFile={this.updateVendorQuote}
                                                showInvoice={this.showInvoice}
                                                showVendorsPOs={showVendorsPOs}
                                                editable={file.status === 5 && !file.finished ? true : false} />
                                        </Grid>
                                    </Fade>
                                )}

                                {(selectedTab === 2) && (
                                    <Fade in={selectedTab >= 2}>
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem}>
                                            <PreparingClientQuote
                                                fileId={fileId}
                                                file={file}
                                                updateClientQuote={this.updateClientQuote}
                                                editable={(file.status <= 5 || file.status === 9) && !file.finished ? true : false} />
                                        </Grid>
                                    </Fade>
                                )}

                                {selectedTab === 1 && (
                                    <Fade in={selectedTab >= 1} >
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem}
                                        >
                                            <VendorQuoteRequests
                                                updateVendorQuote={this.updateVendorQuote}
                                                fileId={fileId}
                                                file={file}
                                                editable={(file.status <= 5 || file.status === 9) && !file.finished ? true : false}
                                                focusedVendorQuote={focusedVendorQuote}
                                                disabledExit={(disable) => {
                                                    this.setState({
                                                        disableExitButton: disable
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Fade>
                                )}

                                {selectedTab === 0 &&
                                    <Fade in={selectedTab === 0}>
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem} >
                                            <ClientRFQCard
                                                fileId={fileId}
                                                file={file}
                                                updateFile={this.updateFile}
                                                editable={true}  //(file.status <= 5 || file.status === 9) && !file.finished ? true : false}
                                            />
                                        </Grid>
                                    </Fade>
                                }

                                {selectedTab === 5 &&
                                    <Fade in={selectedTab === 5}>
                                        <Grid item
                                            xs={11}
                                            sm={11}
                                            className={classes.file__details__gridItem}>
                                            <Paper className={classes.file__details__notes_container}>
                                                <NotesList
                                                    showLog={showLog}
                                                    olderTask={olderTask}
                                                    tasksCount={notesCount}
                                                    file={file}
                                                    user={logedInUser}
                                                    showTasksSection={showTasksSection}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Fade>
                                }
                            </Grid>
                        </>
                    )}

                    {loading && (
                        <div className={classes.loading__container}>
                            <CircularProgress color='secondary' size={24} />
                        </div>
                    )}

                </DialogContent>
            </Dialog>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        clients: state.clients,
        file: state.file,
        apiStatus: state.apiStatus,
        general: state.general
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(FileDetails)));