import React, { Component } from 'react'
import {
    Typography,
    withStyles,
    Link
} from '@material-ui/core'
import ReactTable from "react-table"
import classNames from 'classnames'
import withWidth from '@material-ui/core/withWidth'
import i18n from 'i18next';
import moment from 'moment'

const styles = theme => {
    return ({
        log: {
            ...theme.modal.container,
            padding: '16px 24px 24px',
            minHeight: 350,
        },
        log__table: {
            fontSize: theme.typography.fontSize * 0.8,
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
            border: `solid thin ${theme.palette.grey[300]}`
        },
        logTable__author: {
            color: theme.palette.green[100]
        },
    })
};

class LogTable extends Component {

    render() {
        const { log, classes, summary } = this.props;

        return (
            <ReactTable
                data={log || []}
                columns={[{
                    Header: i18n.t('notes.logTable.column1'),
                    id: 'date',
                    accessor: row =>
                        <div>
                            <Typography>
                                {`${moment(row.date).format('MMMM Do h:mm a')}`}
                            </Typography>
                        </div>,
                    style: {
                        whiteSpace: 'pre-wrap',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    width: 150
                },
                {
                    Header: i18n.t('notes.logTable.column2'),
                    id: 'log',
                    accessor: row =>
                        <div >
                            <Typography>
                                <span className={classes.logTable__author}>{row.author.name}</span>
                                <span>{` ${row.actions}`}</span>
                            </Typography>

                        </div>,
                    style: {
                        whiteSpace: 'pre-wrap',
                        display: 'flex',
                        alignItems: 'center'
                    }
                },
                {
                    Header: i18n.t('notes.logTable.column3'),
                    id: 'comments',
                    show: summary ? false : true,
                    width: 300,
                    style: {
                        whiteSpace: 'pre-wrap',
                        display: 'flex'
                    },
                    accessor: row =>
                        <div>
                            <Typography>
                                {row.comments}
                            </Typography>
                        </div>
                },
                {
                    Header: '',
                    id: 'details',
                    width: 100,
                    show: false,
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    accessor: row =>
                        <div>
                            <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                variant='button'
                                color='secondary'
                                onClick={() => alert('Not Implemented yet')}>
                                {i18n.t("notes.logTable.details")}
                            </Link>
                        </div>
                }
                ]}
                defaultPageSize={log.length}
                filtrable={false}
                sortable={false}
                resizable={false}
                className={classNames(classes.log__table, ' -striped -highlight')}
                showPaginationTop={false}
                showPaginationBottom={false}
            />
        )
    }
}

export default withWidth()(withStyles(styles)(LogTable));