import React from 'react';
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function NumberFormatInputCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            style={{
                fontSize: '11pt',
                textAlign: 'right'
            }}
            isAllowed={values => values.value <= (props.max || 100000000000)}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
        />
    );
}

NumberFormatInputCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};
export default NumberFormatInputCustom
