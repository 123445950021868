import React, { Component } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    List,
    ListItem,
    Typography,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    Divider,
    withStyles,
    ListItemIcon,
} from '@material-ui/core'
import { Person as SalesPersonIcon, Close } from "@material-ui/icons"
import Permissions from '../../lib/permissions'

import i18n from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as peopleActions from '../../actions/peopleActions'
import * as apiStatusActions from '../../actions/apiStatusActions'

import { SlideUp } from './Transitions'


import API from '../../lib/api'

const styles = theme => ({
    dialog__paper: {
        //width: '100%',
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        width: '400px'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog__content__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    people__list__container: {
        height: '200px',
        overflow: 'auto'
    },
    people__selected__indicator__container: {

    },
    people__actions__container: {
        display: 'flex',
        paddingTop: theme.spacing(),
        justifyContent: 'space-between'
    },
    people__filter__button: {
        marginLeft: '5px'
    },
    list__item__highlighted: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.getContrastText(theme.palette.primary.light),
        }
    },
    menu__list__item: {
        borderRadius: 0
    },
    'menu__list__item--selected': {
        backgroundColor: 'rgba(116,185,255,0.3)',
        borderRadius: 0
    },
    menu__list__item__icon: {
        minWidth: 0,
        marginRight: 10,
        fontSize: '10pt'
    },
    'menu__list__item__icon--selected': {
        minWidth: 0,
        marginRight: 10,
        color: theme.palette.getContrastText('rgba(116,185,255,0.3)'),
        fontSize: '10pt'
    },
})


class PeopleFilter extends Component {
    state = {
        peopleDialogVisible: false,
        loading: false,
        allUsersSelected: true,
        selectedUsers: this.props.people.filterPeople.map(u => u.userId)
    }

    componentWillMount = () => {
        this.fecthUsers();
    }


    fecthUsers = () => {
        API.Users.getUsers().then(res => {
            if (res && res.status === 'success') {
                this.props.peopleActions.setPeople(res.data.records.users.filter(u => u.userActive));
            }
        })
    }

    handleChange = (event) => {
        this.setState({ personName: event.target.value });
    }

    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ personName: value });
    }

    toggleAllUsers = () => {
        this.setState({
            allUsersSelected: !this.state.allUsersSelected,
            selectedUsers: !this.state.allUsersSelected ? [] : this.state.selectedUsers
        })
    }
    toggleSelectUser = (user) => {
        let selectedUsers = [...this.state.selectedUsers],
            userInSelector = selectedUsers.find(u => u === user.userId);


        if (userInSelector) {
            selectedUsers = selectedUsers.filter(u => u !== user.userId)
        } else {
            selectedUsers.push(user.userId);
        }
        this.setState({
            selectedUsers,
            allUsersSelected: selectedUsers.length > 0 ? false : true
        })

    }

    hidePeopleDialog = () => {
        this.setState({
            peopleDialogVisible: false
        })
        this.props.generalActions.showPeopleFilter(false);
    }
    showPeopleDialog = () => {
        this.setState({
            peopleDialogVisible: true
        })
        this.props.generalActions.showPeopleFilter(false);
    }


    handleFilter = () => {
        let { people } = this.props,
            { selectedUsers } = this.state;
        let filteredUsers = selectedUsers.length > 0 ? people.allPeople.filter(person => selectedUsers.indexOf(person.userId) >= 0) : []

        if (filteredUsers.length > 0) {
            API.Files.getUserFilesCounters({ usersIds: filteredUsers.map(u => u.userId) })
                .then(res => {
                    if (res.counters) {
                        this.props.peopleActions.setFilterCounters(res.counters);
                    }
                })
        } else {
            this.props.peopleActions.setFilterCounters(null);
        }
        this.props.generalActions.showPeopleFilter(false);
        this.setState({
            peopleDialogVisible: false
        }, () => {
            this.props.peopleActions.setFilterPeople(filteredUsers);
            this.props.reloadList && this.props.reloadList();
        })
    }

    onEnter = () => {
        let { people } = this.props;

        this.setState({
            allUsersSelected: people.filterPeople.length === 0 ? true : false,
            selectedUsers: people.filterPeople.map(u => u.userId)
        })
    }

    render() {
        const {
            peopleDialogVisible,
            allUsersSelected,
            loading,
            selectedUsers
        } = this.state,
            {
                classes,
                people,
                general,
                showAsMenuItem
            } = this.props;

        let buttonLabel = i18n.t('peopleFilter.everyone');

        if (people.filterPeople && people.filterPeople.length > 0) {
            if (people.filterPeople.length === 1) {
                buttonLabel = people.filterPeople[0].userName;
            } else {
                if(people.filterPeople.length !== people.allPeople.length){
                    buttonLabel = `${people.filterPeople.length} ${i18n.t('peopleFilter.users')}`
                }
            }

        }

        return (
            <div>
                {showAsMenuItem ? (
                    <ListItem button
                        onClick={this.showPeopleDialog}
                        className={people.filterPeople && people.filterPeople.length ? classes.list__item__highlighted : null}
                        variant={people.filterPeople && people.filterPeople.length ? 'contained' : 'text'}
                        color={people.filterPeople && people.filterPeople.length ? 'primary' : 'default'}
                        disabled={Permissions.checkSensitiveInformationPermission() ? false : true}
                    >
                        <ListItemIcon className={classes.menu__list__item__icon}>
                            <SalesPersonIcon fontSize='inherit' />
                        </ListItemIcon>
                        <ListItemText> {buttonLabel}</ListItemText>
                    </ListItem>
                ) : (
                        <Button onClick={this.showPeopleDialog}
                            className={classes.people__filter__button}
                            variant={people.filterPeople && people.filterPeople.length ? 'contained' : 'text'}
                            color={people.filterPeople && people.filterPeople.length ? 'primary' : 'default'}
                            disabled={Permissions.checkSensitiveInformationPermission() ? false : true}>
                            <SalesPersonIcon style={{ marginRight: 5 }} /> {buttonLabel}
                        </Button>
                    )}

                <Dialog
                    maxWidth='sm'
                    open={peopleDialogVisible || general.showPeopleFilter || false}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    onEnter={this.onEnter}
                    TransitionComponent={SlideUp}>
                    <div>
                        <DialogTitle disableTypography={true} >
                            {i18n.t('peopleFilter.title')}
                            <IconButton color="inherit"
                                disabled={loading}
                                onClick={this.hidePeopleDialog}
                                aria-label="Close" className={classes.closeButton}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div className={classes.dialog__content__container}>


                                <div className={classes.people__list__container}>
                                    <List dense={true}>
                                        <ListItem button onClick={this.toggleAllUsers}>
                                            <ListItemText>{i18n.t('peopleFilter.everyone')}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={this.toggleAllUsers}
                                                    checked={allUsersSelected}
                                                />
                                            </ListItemSecondaryAction>

                                        </ListItem>
                                        {people.allPeople && people.allPeople.map((person, i) => (
                                            <ListItem key={i} button onClick={() => this.toggleSelectUser(person)}>
                                                <ListItemText>{person.userName}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => this.toggleSelectUser(person)}
                                                        checked={selectedUsers.indexOf(person.userId) !== -1}
                                                    />
                                                </ListItemSecondaryAction>

                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.people__actions__container}>
                                <Typography color='textSecondary'>
                                    {allUsersSelected ? i18n.t('peopleFilter.allUsers') : `${selectedUsers.length} ${selectedUsers.length === 1 ? i18n.t('peopleFilter.userSelected') : i18n.t('peopleFilter.usersSelected')}`}
                                </Typography>
                                <Button color='primary'
                                    disabled={!allUsersSelected && selectedUsers.length === 0}
                                    size='small'
                                    variant='contained' onClick={this.handleFilter}>
                                    {i18n.t('peopleFilter.filter')}
                                </Button>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        peopleActions: bindActionCreators(peopleActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people,
        general: state.general,
        account: state.account,
        apiStatus: state.apiStatus
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeopleFilter))