import {
    SET_EVENT,
    CLEAR_EVENT,
    UPDATE_EVENT
} from '../actions/eventsActions'


function event(state = {}, action) {
    switch (action.type) {
        case SET_EVENT:
            if (action.event) {
                return { ...action.event }
            }
            return state;
        case UPDATE_EVENT:
            if (action.event) {
                return {
                    ...action.event,
                    lastUpdate: new Date()
                }
            }
            return state
        case CLEAR_EVENT:
            return null
        default:
            return state
    }
}
export default event