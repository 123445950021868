import React from 'react';
import {
    CircularProgress,
    withStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Button,
    TextField,
    MenuItem
} from '@material-ui/core/';
import i18n from 'i18next';
import { GlobalParametersContext } from '../../contexts/globalParametersContext'


const styles = theme => ({
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
})

class ConfirmationReturnStatusDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validate: false,
            comments: null,
            selectedReason: 0
        }
    }

    onConfirm = () => {
        const { comments, selectedReason } = this.state,
            { showReasonsSelect } = this.props;

        this.setState({
            validate: true
        })

        if (comments === null) {
            return
        }

        let body = {
            comments: comments
        }

        if (showReasonsSelect) {
            body.reasonId = selectedReason
        }

        this.props.onConfirm(body)
    }

    componentWillReceiveProps(nextProps) {
        const { comments } = this.state;

        this.setState({
            validate: false,
            comments: nextProps.loading ? comments : null,
            loading: nextProps.loading
        })
    }


    render() {
        const { comments, validate, selectedReason } = this.state,
            { classes,
                loading,
                message,
                confirmLabel,
                cancelLabel,
                open,
                inputLabel,
                showReasonsSelect,
                title } = this.props;

        return (
            <GlobalParametersContext.Consumer>
                {({ rejectReasons }) =>
                    <Dialog
                        open={open}
                        onClose={this.props.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {title &&
                            <DialogTitle id="alert-dialog-title">
                                {title}
                            </DialogTitle>
                        }
                        <DialogContent>
                            <DialogContentText>
                                {message}
                            </DialogContentText>

                            {showReasonsSelect &&
                                <TextField
                                    autoComplete='off'
                                    required
                                    variant="outlined"
                                    id="quoteItemUnit"
                                    select
                                    inputProps={{
                                        className: classes.clientRFQ__form__input
                                    }}
                                    label={"Reason"}
                                    className={classes.clientRFQ__form__textField__unit}
                                    value={selectedReason}
                                    onChange={e => {
                                        this.setState({
                                            selectedReason: e.target.value
                                        })
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    style={{ width: '50%' }}
                                    margin="normal"
                                >
                                    <MenuItem value={0}>
                                        {i18n.t('quotes.quoteRequest.quoteSelectNone')}
                                    </MenuItem>
                                    {rejectReasons && rejectReasons.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }

                            <TextField
                                autoComplete='off'
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                id="comment"
                                error={validate && !comments ? true : false}
                                label={inputLabel}
                                type="text"
                                value={comments ? comments : ''}
                                onChange={(e) => {
                                    this.setState({
                                        comments: e.target.value
                                    })
                                }}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                style={{ width: '110px' }}
                                size='small'
                                variant="outlined"
                                color="secondary"
                                onClick={this.props.handleClose}>
                                {cancelLabel}
                            </Button>
                            <div className={classes.button__wrapper}>
                                <Button
                                    disabled={loading}
                                    color="primary"
                                    variant="contained"
                                    onClick={this.onConfirm}>
                                    {confirmLabel}
                                </Button>
                                {loading && <CircularProgress color='secondary'  className={classes.buttonSpinner} size={24} />}
                            </div>
                        </DialogActions>
                    </Dialog>
                }
            </GlobalParametersContext.Consumer>
        );
    }
}

ConfirmationReturnStatusDialog.contextType = GlobalParametersContext;

export default withStyles(styles)(ConfirmationReturnStatusDialog);