import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const Notes = {
    getNotes: (args) => {
        let { fileId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createNoteOLD: (args) => {
        let {
            fileId,
            note,
            // filesAttachments 
        } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                // var data = new FormData()
                // Object.keys(note).forEach(key => {
                //     if (note[key]){
                //         data.append(key, note[key])
                //     }
                // })

                // for (const file of filesAttachments) {
                //     data.append('files[]', file, file.name)
                // }

                fetch(url, {
                    body: JSON.stringify(note),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createNote: (args) => {
        let {
            fileId,
            note,
            filesAttachments
        } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes/v2`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                var data = new FormData()
                Object.keys(note).forEach(key => {
                    if (note[key]) {
                        data.append(key, typeof note[key] === 'object' ? JSON.stringify(note[key]) : note[key])
                    }
                })

                if (filesAttachments) {
                    for (const file of filesAttachments) {
                        data.append('files', file, encodeURI(file.name))
                    }
                }


                fetch(url, {
                    body: data,//JSON.stringify(note),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateNoteOLD: (args) => {
        let { fileId, note } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes/${note.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(note),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateNote: (args) => {
        let { fileId, note, filesAttachments } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes/${note.id}/v2`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                var data = new FormData()
                Object.keys(note).forEach(key => {
                    if (note[key]) {
                        data.append(key, typeof note[key] === 'object' ? JSON.stringify(note[key]) : note[key])
                    }
                })

                for (const file of filesAttachments) {
                    data.append('files', file, file.name)
                }


                fetch(url, {
                    body: data,//JSON.stringify(note),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteNote: (args) => {
        let { fileId, noteId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes/${noteId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    markTask: (args) => {
        let { fileId, noteId, completed } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/notes/${noteId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify({ completed }),
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Notes;