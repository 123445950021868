import React, { Component } from 'react'
import {
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    withMobileDialog,
    withStyles,
} from '@material-ui/core'
import i18n from 'i18next';
import {
    Home,
    MoveToInbox,
    Archive,
    Delete
} from '@material-ui/icons'
import { withRouter } from "react-router";
import AppVersion from '../AppVersion';
import API from '../../lib/api'
const drawerWidth = 240;

const styles = (theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    menu__list__item: {
        borderRadius: 0
    },
    'menu__list__item--selected': {
        backgroundColor: 'rgba(116,185,255,0.3)',
        borderRadius: 0
    },
    menu__list__item__icon: {
        minWidth: 0,
        marginRight: 10
    },
    'menu__list__item__icon--selected': {
        minWidth: 0,
        marginRight: 10,
        color: theme.palette.getContrastText('rgba(116,185,255,0.3)')
    },
}))

class EmailsLeftSidebar extends Component {
    state = {
        unreadCounters: []
    }
    navigate = (path) => {
        const { history, toggleOpenMenu } = this.props;
        toggleOpenMenu();
        history.push(path);
    }

    componentDidMount = () => {
        API.Emails.getEmails({
            folderId: 1
        })
            .then(response => {
                this.setState({
                    unreadCounters: response.data.records.unreadCounters
                })
            }).catch(err => {
                console.log(err)
            })
    }


    render() {
        const {
            menuVisible,
            toggleOpenMenu,
            classes
        } = this.props,
            {
                unreadCounters
            } = this.state;

        return (
            <div>
                <Drawer open={menuVisible}
                    onClose={toggleOpenMenu}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <List>
                        <ListItem button onClick={() => this.navigate('/dashboard')}>
                            <ListItemIcon
                                className={classes.menu__list__item__icon}
                                style={{ minWidth: 0, marginRight: 10 }}><Home /></ListItemIcon>
                            <ListItemText>{i18n.t("sideBar.home")}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            className={this.props.location.pathname.indexOf('/emails/inbox') >= 0 ? classes['menu__list__item--selected'] : classes.menu__list__item}
                            onClick={() => this.navigate('/emails/inbox')}>
                            <ListItemIcon
                                className={this.props.location.pathname.indexOf('/emails/inbox') >= 0 ? classes['menu__list__item__icon--selected'] : classes.menu__list__item__icon}
                            ><MoveToInbox />
                            </ListItemIcon>
                            <ListItemText>{i18n.t('emails.inbox')}</ListItemText>
                            <ListItemSecondaryAction>
                                {unreadCounters.find(uc => uc.folderId === 1) ? unreadCounters.find(uc => uc.folderId === 1).unreadCount : null}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            button
                            className={this.props.location.pathname.indexOf('/emails/archive') >= 0 ? classes['menu__list__item--selected'] : classes.menu__list__item}
                            onClick={() => this.navigate('/emails/archive')}>
                            <ListItemIcon
                                className={this.props.location.pathname.indexOf('/emails/archive') >= 0 ? classes['menu__list__item__icon--selected'] : classes.menu__list__item__icon}><Archive /></ListItemIcon>
                            <ListItemText>{i18n.t('emails.archive')}</ListItemText>
                            <ListItemSecondaryAction>
                                {unreadCounters.find(uc => uc.folderId === 2) ? unreadCounters.find(uc => uc.folderId === 2).unreadCount : null}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            button
                            className={this.props.location.pathname.indexOf('/emails/trash') >= 0 ? classes['menu__list__item--selected'] : classes.menu__list__item}
                            onClick={() => this.navigate('/emails/trash')}>
                            <ListItemIcon
                                className={this.props.location.pathname.indexOf('/emails/trash') >= 0 ? classes['menu__list__item__icon--selected'] : classes.menu__list__item__icon}><Delete /></ListItemIcon>
                            <ListItemText>{i18n.t('emails.trash')}</ListItemText>
                            <ListItemSecondaryAction>
                                {unreadCounters.find(uc => uc.folderId === 3) ? unreadCounters.find(uc => uc.folderId === 3).unreadCount : null}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </List>
                    <List style={{ position: 'absolute', bottom: '0', margin: 'auto' }}>
                        <ListItem>
                            <AppVersion />
                        </ListItem>
                    </List>
                </Drawer>

            </div>
        )
    }
}


export default withStyles(styles)(withRouter(withMobileDialog()(EmailsLeftSidebar)));