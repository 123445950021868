import {
    SET_VENDOR,
    UPDATE_VENDOR,
    DELETE_VENDOR,
    ADD_VENDOR_CONTACT,
    DELETE_VENDOR_CONTACT,
    UPDATE_VENDOR_CONTACT
} from '../actions/vendorsActions'

function vendor(state = {}, action) {
    let index = null;
    switch (action.type) {
        case SET_VENDOR:
            return { ...action.vendor }
        case UPDATE_VENDOR:
            if (state.id === action.vendor.id) {
                return { ...action.vendor }
            }
            return state;
        case DELETE_VENDOR:
            if (state.id === action.vendor.id) {
                return {}
            }
            return state;
        case ADD_VENDOR_CONTACT:
            let contacts = state.contacts ? [...state.contacts] : []
            return {
                ...state,
                contacts: [
                    ...contacts,
                    { ...action.contact }
                ]
            }
        case UPDATE_VENDOR_CONTACT:
            index = state.contacts.findIndex(c => c.id === action.contact.id);
            if (index >= 0) {
                return {
                    ...state,
                    contacts: [
                        ...state.contacts.slice(0, index),
                        { ...action.contact },
                        ...state.contacts.slice(index + 1),
                    ]
                }
            }
            return state;
        case DELETE_VENDOR_CONTACT:
            return {
                ...state,
                contacts: state.contacts.filter(c => c.id !== action.contact.id)
            }
        default:
            return state;
    }
}

export default vendor