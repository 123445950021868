import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'
const GlobalParameters = {
    getGlobalParameters: (args) => {

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/parameters`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;


            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", prodekAuthToken);

            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
            });
        });
        return promise;
    },
    createOtherCost: (otherCost) => {

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/otherCosts`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;


            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", prodekAuthToken);

            fetch(url, {
                body: JSON.stringify(otherCost),
                headers,
                method: 'POST'
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
            });
        });
        return promise;
    }
}

export default GlobalParameters;