import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    Divider,
    Badge,
    Link,
    Button,
    withStyles,
    Select,
    InputAdornment,
    Fade,
    CircularProgress
} from '@material-ui/core';
import {
    Inbox,
    Drafts,
    Send,
    DoneOutline,
    OpenInNew,
    Search
} from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { TasksShortcut } from './common'
import Permissions from '../../lib/permissions'
import API from '../../lib/api';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import HeaderSummary from './HeaderSummary';
import * as generalActions from '../../actions/generalActions'
import * as filesActions from '../../actions/filesActions'


const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileId: {
            fontWeight: 'bold',
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        list__vendorsTitle: {
            fontWeight: 'bold',
        },
        purchase__orders__noRows: {
            display: 'flex',
            minHeight: '60vh',
            justifyContent: 'center',
            alignItems: 'center'
        },
        table__cell__rigth: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        table__cell__total: {
        },
        rfq__badge: {
            top: '5px',
            right: '-10px'
        },
        rfq__task__badge: {
            top: '5px',
            right: '-28px'
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        poListHeader: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: theme.spacing(3),
        },
        poListHeader__button: {
            borderRadius: '0px'
        },
        'poListHeader__button--selected': {
            backgroundColor: '#efe9c3',
            border: `1px solid ${theme.palette.primary.light}`,
            borderBottom: 'none',
            color: theme.palette.text.primary,
            borderRadius: '0px'
        },
        divider: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.primary.light
        },
        poList__vendorPOs__cell: {
            display: 'flex',
            flexDirection: 'column'
        },
        vendorPosItems__itemPartNumber: {
            color: theme.palette.naval.main,
        },
        vendorPosItems__quantity: {
            color: theme.palette.text.secondary,
            borderBottom: '1px solid #000'
        },
        vendorPosItems__descriptionResumed: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: '95%',
            cursor: 'pointer'
        },
        "vendorPos__status--preparing": {
            marginRight: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.red[100],
        },
        "vendorPos__status--requested": {
            marginRight: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.primary.main,
        },
        "vendorPos__status--ready": {
            marginRight: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.secondary.main,
        },
        vendorPos__tabs__divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        rfq__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        'rfq__notification__icon--yellow': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        'rfq__notification__icon--red': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        eta__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'eta__lastUpdate--soon': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'eta__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        alert__tasks__button__icon: {
            position: 'absolute', right: '0', top: '0',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        alert__tasks__button__icon__mobile: {
            position: 'absolute', right: '-16px', top: '-8px',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        customLink: theme.customLink,
        list__fileDetails: { position: 'relative' },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        list__vendors__cell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        list__vendors__cell__row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        vendor__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.red[100],
        },
        vendor__status__icon__requested: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.primary.main,
        },
        vendor__status__icon__ready: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.secondary.main,
        },
        clientPO__status: {
            fontSize: '10pt'
        },
        "clientPO__status--readyToDeliver": {
            color: theme.palette.green[100],
        },
        "clientPO__status--partial": {
            color: theme.palette.orange[100],
        },
        total_grid: {
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'right'
        },
        totalRow: {
            fontWeight: 'bold'
        },
        profitTotalRow: {
            color: theme.palette.grey[700]
        },
        otherCostTotalRow: {
            color: theme.palette.blue[70]
        },
        row__currency: {
            position: 'absolute',
            top: 0,
            right: theme.spacing()
        },
        clientPO__tabs__divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        status__label: {
            color: theme.palette.orange[100]
        },
        circularProgress: {
            marginTop: '20%'
        },
    })
};


class PurchaseOrders extends Component {
    state = {
        groupBySection: 'client',
        allVendorPOs: [],
        currentTab: 0,
        firstTime: true,
        filteredVendorsPOs: [],
        filteredVendorsPOsSize: 0,
        rows: this.props.files,
        currentClientPOTab: 0,
        filteredClientPO: [],
        filteredClientPOSize: 0,
        fetching: false,
        showTotal: false,
        etaOrder: true
    }

    componentWillReceiveProps(nextProps) {
        const { currentTab, currentClientPOTab, firstTime } = this.state;
        let filtered = [];

        if (nextProps.files) {
            nextProps.files.forEach((vpo) => {
                filtered = vpo.vendorsPOs ? vpo.vendorsPOs.concat(filtered) : filtered
                return vpo
            })
        }

        let processingClientPOs = nextProps.files.filter(row => row.clientPO.status.id === 1),
            partialClientPOs = nextProps.files.filter(row => row.clientPO.status.id === 2),
            readyClientPOs = nextProps.files.filter(row => row.clientPO.status.id === 3),
            filteredClientPO = nextProps.files.filter(row => row.clientPO.status.id === 1 && (!row.invoices || (row.invoices && row.invoices.length === 0))), //currentClientPOTab + 1),
            ClientPOTab = currentClientPOTab;


        // if (firstTime) {
        //     if (processingClientPOs.length > 0) {
        //         ClientPOTab = 0;
        //     } else if (partialClientPOs.length > 0) {
        //         filteredClientPO = partialClientPOs;
        //         ClientPOTab = 1;
        //     } else if (readyClientPOs.length > 0) {
        //         filteredClientPO = readyClientPOs;
        //         ClientPOTab = 2;
        //     }
        // }

        let preparingVendorPOs = filtered.filter(f => f.vendorPOStatusId === 1),
            sentVendorPOs = filtered.filter(f => f.vendorPOStatusId === 2 || f.vendorPOStatusId === 4),
            readyVendorPOs = filtered.filter(f => f.vendorPOStatusId === 3),
            filteredVendorPOs = filtered.filter(f => currentTab === 1 ? f.vendorPOStatusId === 2 || f.vendorPOStatusId === 4 : f.vendorPOStatusId === currentTab + 1),
            currentVendorPoTab = currentTab;

        if (firstTime) {
            if (preparingVendorPOs.length > 0) {
                currentVendorPoTab = 0;
            } else if (sentVendorPOs.length > 0) {
                filteredVendorPOs = sentVendorPOs;
                currentVendorPoTab = 1;
            } else if (readyClientPOs.length > 0) {
                filteredVendorPOs = readyVendorPOs;
                currentVendorPoTab = 2;
            }
        }

        this.setState({
            rows: nextProps.files,
            filteredClientPO: filteredClientPO,
            filteredClientPOSize: filteredClientPO.length,
            currentClientPOTab: ClientPOTab,
            processingRows: processingClientPOs,
            partialRows: partialClientPOs,
            readyRows: readyClientPOs,
            allVendorPOs: filtered,
            firstTime: false,
            currentTab: currentVendorPoTab,
            filteredVendorsPOs: filteredVendorPOs,
            filteredVendorsPOsSize: filteredVendorPOs.length,
            preparingQuotesCount: preparingVendorPOs.length,
            sendToVendorQuotesCount: sentVendorPOs.length,
            readyQuotesCount: readyVendorPOs.length
        })
    }


    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    filterVendorPOs = (e, value) => {
        const { allVendorPOs } = this.state;

        let newVendorPOs = []

        if (value === 1) {
            newVendorPOs = allVendorPOs.filter(v => v.vendorPOStatusId === 2 || v.vendorPOStatusId === 4)
        } else {
            newVendorPOs = allVendorPOs.filter(v => v.vendorPOStatusId === value + 1)
        }
        this.setState({
            currentTab: value,
            filteredVendorsPOs: newVendorPOs,
            filteredVendorsPOsSize: newVendorPOs.length
        })
    }

    calculateStatus = (row) => {
        let { vendorsPOs } = row,
            notReadyVendorPOs = vendorsPOs.filter(vp => vp.vendorPOStatusId !== 3);

        if (notReadyVendorPOs.length > 0) {
            return i18n.t("quotes.pos.statusInProgress")
        } else {
            return i18n.t("quotes.pos.statusReady")
        }
    }

    clientPOStatusTabChange = (e, value) => {
        const { rows } = this.state;

        let newClientPOs = rows.filter(row => row.clientPO.status.id === value + 1)

        this.setState({
            currentClientPOTab: value,
            filteredClientPO: newClientPOs,
            filteredClientPOSize: newClientPOs.length
        })
    }

    fetchQuotesByStatus = (status, params) => {
        return new Promise((resolve, reject) => {
            if (!this.state.fetching) {
                this.setState({
                    fetching: true
                }, _ => {
                    API.Files.getFiles({
                        recordCount: 50,
                        offset: 0,
                        status: status,
                        params: params
                    }).then(res => {
                        const rows = res.data.records.filter(row => row.clientPO.status.id === 1 && (!row.invoices || (row.invoices && row.invoices.length === 0)));
                        this.props.filesActions.setFiles(rows);

                        this.setState({
                            filteredClientPO: rows,
                            filteredClientPOSize: res.data.records.filter(row => row.clientPO.status.id === 1).length,
                            fetching: false,
                            showTotal: true
                        })
                    })
                })
            } else {
                resolve();
            }
        })
    }

    handleDateFilter = ({ selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson }) => {
        this.fetchQuotesByStatus(5, { selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson })
    }

    render = () => {
        const { classes, setSearchParmeters, hideVendorPOs } = this.props,
            { rows,
                groupBySection,
                filteredVendorsPOs,
                filteredVendorsPOsSize,
                currentTab,
                preparingQuotesCount,
                sendToVendorQuotesCount,
                readyQuotesCount,
                currentClientPOTab,
                filteredClientPO,
                filteredClientPOSize,
                fetching,
                etaOrder
            } = this.state,
            counters = [preparingQuotesCount, sendToVendorQuotesCount, readyQuotesCount];

        let vendorsPosCount = rows.reduce((total, row) => {
            return total + (row.vendorsPOs ? row.vendorsPOs.length : 0);
        }, 0)

        let countries = filteredClientPO.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i),
            profitTotalDol = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.poTotals.profitTotal : 0)), 0),
            profitTotalEu = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.poTotals.profitTotal : 0)), 0);

        let quoteTotalUSD = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.poTotals.total : 0)), 0),
            otherCostTotalUSD = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.clientQuoteTotals.otherCostTotal : 0)), 0)

        let quoteTotalEU = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.poTotals.total : 0)), 0),
            otherCostTotalEU = filteredClientPO.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.clientQuoteTotals.otherCostTotal : 0)), 0)

        const options = [
            {
                label: i18n.t('quotes.pos.vendorPOs.preparing'),
                value: 1
            },
            {
                label: i18n.t('quotes.pos.vendorPOs.requested'),
                value: 2
            },
            {
                label: i18n.t('quotes.pos.vendorPOs.ready'),
                value: 3
            },
        ]

        return (
            <div>
                {rows &&
                    <div>
                        {(Permissions.checkSensitiveInformationPermission() && !hideVendorPOs) && (
                            <>
                                <div className={classes.poListHeader}>
                                    <Button
                                        onClick={() =>
                                            this.setState({
                                                groupBySection: 'client'
                                            })
                                        }
                                        className={groupBySection === 'client' ? classes['poListHeader__button--selected'] : classes.poListHeader__button}>
                                        {filteredClientPO ? filteredClientPO.length : null} {i18n.t('quotes.pos.clientPOs')}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            this.setState({
                                                groupBySection: 'vendors'
                                            })
                                        }
                                        className={groupBySection === 'vendors' ? classes['poListHeader__button--selected'] : classes.poListHeader__button}>
                                        {vendorsPosCount} {i18n.t('quotes.pos.vendorsPOS')}
                                    </Button>
                                </div>
                                <Divider className={classes.divider} />
                            </>
                        )}


                        {groupBySection === 'client' &&
                            <div>
                                <Hidden xsDown implementation="css">
                                    {/* <Tabs
                                        className={classes.quotes__statusTabs}
                                        value={currentClientPOTab}
                                        onChange={this.clientPOStatusTabChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                    >

                                        <Tab label={`${processingRows ? processingRows.length : null} ${i18n.t('quotes.pos.statusProcessing')}`}
                                            style={{ width: 120 }}
                                        />
                                        <Tab label={`${partialRows ? partialRows.length : null} ${i18n.t('quotes.pos.statusPartial')}`}
                                            style={{ width: 120 }}
                                        />
                                        <Tab label={`${readyRows ? readyRows.length : null} ${i18n.t('quotes.pos.statusReadyToDeliver')}`}
                                            style={{ width: 180 }}
                                        />
                                    </Tabs>
                                    <Divider className={classes.clientPO__tabs__divider} /> */}

                                    <div style={{ position: 'relative' }}>
                                        <HeaderSummary
                                            fileStatus={5}
                                            totalUSD={quoteTotalUSD}
                                            totalEU={quoteTotalEU}
                                            otherCostTotalUSD={otherCostTotalUSD}
                                            otherCostTotalEU={otherCostTotalEU}
                                            profitUSD={profitTotalDol}
                                            profitEU={profitTotalEu}
                                            handleDateFilter={this.handleDateFilter}
                                            countries={countries}
                                            termsLabel={i18n.t('listHeader.POterms')}
                                        />

                                        { filteredClientPO &&
                                            <Typography style={{ textAlign: 'right', position: 'absolute', right: 0, bottom: 0 }}>
                                                {`All results: ${filteredClientPO.length} files`}
                                            </Typography>
                                        }
                                    </div>

                                    {fetching && (
                                        <Fade in={fetching}>
                                            <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                                        </Fade>
                                    )}

                                    {!fetching && <>
                                        {filteredClientPO.length > 0 ?
                                            <ReactTable
                                                data={filteredClientPO}
                                                columns={[
                                                    {
                                                        Header: i18n.t("quotes.pos.table.column1"),
                                                        id: 'file',
                                                        filterable: false,
                                                        width: Permissions.checkSensitiveInformationPermission() ? 230 : undefined,
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        },
                                                        accessor: row => (
                                                            <div className={classes.rfq__table__fileDescriptions}>
                                                                <Link
                                                                    variant="body2"
                                                                    className={classes.rfq__table__fileLink}
                                                                    onClick={() => {
                                                                        this.props.handleShowQuote(row.id)
                                                                    }}
                                                                    underline={'hover'}
                                                                >
                                                                    <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                                </Link>
                                                                <div className={classes.list__fileDetails}>
                                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                                </div>

                                                                <Typography>{`PO# ${row.clientPO.poNumber}`}</Typography>

                                                                <div className={classes.rfq__table__fileDatesContainer}>
                                                                    <Typography
                                                                        variant='body2'
                                                                        color='textSecondary'>
                                                                        {this.quoteDateFormat(row.clientPO.date)}
                                                                    </Typography>
                                                                </div>
                                                                {row.clientPO.clientPOQuantities && row.clientPO.status.id !== 1 && row.clientPO.clientPOQuantities.received - row.clientPO.clientPOQuantities.invoiced !== 0 &&
                                                                    <div>
                                                                        <Typography className={classes.status__label} noWrap>{row.clientPO.clientPOQuantities.received - row.clientPO.clientPOQuantities.invoiced} {i18n.t("quotes.pos.readyToInvoice")}</Typography>
                                                                    </div>
                                                                }

                                                                {row.clientPO.clientPOQuantities && row.clientPO.status.id !== 1 && row.clientPO.clientPOQuantities.invoiced - row.clientPO.clientPOQuantities.delivered !== 0 &&
                                                                    <div>
                                                                        <Typography className={classes.status__label} noWrap>{row.clientPO.clientPOQuantities.invoiced - row.clientPO.clientPOQuantities.delivered} {i18n.t("quotes.pos.readyToDeliver")}</Typography>
                                                                    </div>
                                                                }
                                                                
                                                            </div>),
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.requestedToVendor.table.column3"),
                                                        filterable: true,
                                                        id: 'vendors',
                                                        show: Permissions.checkSensitiveInformationPermission(),
                                                        accessor: row => {

                                                            return (
                                                                <div className={classes.list__vendors__cell}>
                                                                    {row.vendorsPOs && row.vendorsPOs.filter(vpo => !vpo.vendorPOIsVoid && vpo.vendorPONumber).map((v, i) => (
                                                                        <div key={i} className={classes.list__vendors__cell__row}>
                                                                            {v.vendorPOStatusId === 1 && (
                                                                                <Drafts className={classes.vendor__status__icon__preparing} />
                                                                            )}
                                                                            {(v.vendorPOStatusId === 2 || v.vendorPOStatusId === 4) && (
                                                                                <Send className={classes.vendor__status__icon__requested} />
                                                                            )}
                                                                            {v.vendorPOStatusId === 3 && (
                                                                                <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                            )}
                                                                            <div>
                                                                                <Typography key={i} noWrap>
                                                                                    <strong>{`${v.vendorPONumber} `}</strong>
                                                                                    <span>{v.vendorName}</span>
                                                                                </Typography>
                                                                                <Typography color='textSecondary' style={{ fontSize: '14px' }}>
                                                                                    {v.vendorPOStatusId === 1 &&
                                                                                        `${i18n.t("quotes.pos.vendorPOs.created")} ${this.quoteDateFormat(v.vendorPODate)} \n`
                                                                                    }

                                                                                    {(v.vendorPOStatusId >= 2) &&
                                                                                        `${i18n.t("quotes.pos.vendorPOs.requested")} ${this.quoteDateFormat(v.vendorPOSendToVendorDate)} \n`
                                                                                    }

                                                                                    {v.vendorPOStatusId === 3 &&
                                                                                        `${i18n.t("quotes.pos.vendorPOs.received")} ${this.quoteDateFormat(v.vendorPOReceiveDate)}`
                                                                                    }
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            )
                                                        },
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        },
                                                        Filter: ({ filter, onChange }) => (
                                                            <div className={classes.flexCell}>
                                                                <TextField
                                                                    id='txtVendor'
                                                                    autoComplete='off'
                                                                    style={{ flex: 1 }}
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Search style={{ fontSize: '12px' }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        ),
                                                        filterMethod: (filter, row) => {
                                                            let { value } = filter

                                                            setSearchParmeters({ vendor: value });
                                                            return `${row._original.vendorsPOs && row._original.vendorsPOs.map(v => v.vendorPOId).join(' ')}`.indexOf(value) >= 0 ||
                                                                `${row._original.vendorsPOs && row._original.vendorsPOs.map(v => v.vendorName).join(' ')}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.requestedByClient.table.country"),
                                                        filterable: false,
                                                        id: 'country',
                                                        width: 60,
                                                        accessor: row => (
                                                            <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                        )
                                                    },
                                                    {
                                                        Header: row => {
                                                            return (
                                                                <div onClick={()=>{
                                                                    let newFiltered = filteredClientPO
                                                                        .sort((a, b) => {
                                                                            if (a.clientPO.date < b.clientPO.date) return etaOrder ? -1 : 1;
                                                                            if (a.clientPO.date > b.clientPO.date) return etaOrder ? 1 : -1;
                                                                            return 0
                                                                        })
                                                                    this.setState({
                                                                        etaOrder: !etaOrder,
                                                                        filteredClientPO: newFiltered
                                                                    })
                                                                }}>
                                                                    {i18n.t("quotes.quoted.table.poDate")}
                                                                </div>
                                                            )
                                                        },
                                                        id: 'poDate',
                                                        width: 100,
                                                        style: { textAlign: 'right' },
                                                        filterable: false,
                                                        accessor: row => {
                                                            return (
                                                                <div>
                                                                    <Typography>
                                                                        {moment(row.clientPO.date).format('MM/DD/YYYY')}
                                                                    </Typography>
                                                                    <Typography>
                                                                        + {row.clientQuoteDeliveryDays}
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        Header: row => {
                                                            return (
                                                                <div onClick={()=>{
                                                                    let newFiltered = filteredClientPO
                                                                        .sort((a, b) => {
                                                                            if (a.clientPO.deliveryDate < b.clientPO.deliveryDate) return etaOrder ? -1 : 1;
                                                                            if (a.clientPO.deliveryDate > b.clientPO.deliveryDate) return etaOrder ? 1 : -1;
                                                                            return 0
                                                                        })
                                                                    this.setState({
                                                                        etaOrder: !etaOrder,
                                                                        filteredClientPO: newFiltered
                                                                    })
                                                                }}>
                                                                    {i18n.t("quotes.quoted.table.poEta")}
                                                                </div>
                                                            )
                                                        },
                                                        id: 'poEta',
                                                        width: Permissions.checkSensitiveInformationPermission() ? 100 : 120,
                                                        filterable: false,
                                                        style: { textAlign: 'right' },
                                                        accessor: row => {
                                                            return (
                                                                <Typography 
                                                                    className={moment().isAfter(row.clientPO.deliveryDate) ? classes['eta__lastUpdate--veryLate'] : 
                                                                        moment(row.clientPO.deliveryDate).diff(moment(), 'days') <= 4 ? classes['eta__lastUpdate--soon'] :
                                                                        classes.eta__lastUpdate} 
                                                                    >
                                                                    {moment(row.clientPO.deliveryDate).format('MM/DD/YYYY')}
                                                                </Typography>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.pos.table.quotedItems"),
                                                        width: 110,
                                                        heigth: 50,
                                                        id: 'quotedItems',
                                                        // show: Permissions.checkSensitiveInformationPermission(),
                                                        style: { position: 'relative' },
                                                        className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                                        accessor: row => (
                                                            <div>
                                                                {row.totals.poTotals.total ?
                                                                    <div>
                                                                        <Typography className={classes.row__currency}>{row.currency}</Typography>
                                                                        <NumberFormat value={row.totals.poTotals.total + (row.totals.clientQuoteTotals.otherCostTotal || 0)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            className={classes.totalRow}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.column6"),
                                                        width: 80,
                                                        id: 'Profit',
                                                        show: Permissions.checkSensitiveInformationPermission() ? true : false,
                                                        className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                                        accessor: row => {
                                                            return (
                                                                <div>
                                                                    {row.totals.poTotals.profitTotal && row.totals.poTotals.profitTotal !== 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                                                            <NumberFormat value={row.totals.poTotals.profitTotal}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                            />
                                                                            <NumberFormat value={(row.totals.poTotals.profitTotal * 100) / (row.totals.clientQuoteTotals.subTotal || 1)}
                                                                                displayType={'text'}
                                                                                suffix={'%'}
                                                                                decimalScale={0}
                                                                                fixedDecimalScale={false}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.column3"),
                                                        filterable: false,
                                                        id: 'user_Author',
                                                        accessor: row => {
                                                            let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                            return (
                                                                <div>
                                                                    <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                                                    {row.salesRepresentative && row.salesRepresentative.name && (
                                                                        <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                    )}
                                                                    <TasksShortcut
                                                                        row={row}
                                                                        handleShowQuote={this.props.handleShowQuote}
                                                                    >
                                                                        <div>
                                                                            {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => !task.conplete && task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                                <div>
                                                                                    <Divider />
                                                                                    <div style={{ display: 'flex', marginTop: 5, justifyContent: 'flex-start' }}>
                                                                                        <OpenInNew style={{ fontSize: 14 }} color='secondary' />
                                                                                    </div>
                                                                                    {row.tasks.filter(task =>
                                                                                        !task.complete)
                                                                                        .map(task => {
                                                                                            if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                                usersListed.push(task.followUpUser.id);
                                                                                                return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                            }
                                                                                            return null
                                                                                        })}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </TasksShortcut>

                                                                </div>)

                                                        },
                                                        width: 120,
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        }
                                                    }
                                                ]}
                                                minRows={0}
                                                pageSize={filteredClientPOSize}
                                                onPageSizeChange={(pageSize, page) =>
                                                    this.setState({ page, pageSize })
                                                }
                                                className={classNames(classes.table, ' -striped -highlight')}
                                                showPaginationTop={false}
                                                showPaginationBottom={false}
                                            />
                                            :
                                            <div className={classes.empty__state__container}>
                                                <Inbox style={{ fontSize: 30 }} color='secondary' />
                                                <Typography variant='body2'>{
                                                    currentClientPOTab === 0 ?
                                                        i18n.t('quotes.pos.noQuotesProcesses') :
                                                        currentClientPOTab === 1 ?
                                                            i18n.t('quotes.pos.noQuotesPartialllyProcesses') :
                                                            i18n.t('quotes.pos.noQuotesDelivered')
                                                }</Typography>
                                            </div>
                                        }
                                    </>}
                                </Hidden>
                                <Hidden smUp implementation="css">
                                    <Paper>
                                        <List>
                                            {rows.map((row, i) => {
                                                let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                let status = 1 //this.calculateStatus(row);
                                                return (
                                                    <div key={row.id}>
                                                        <ListItem key={row.id} >
                                                            <ListItemText>
                                                                <div className={classes.rfq__table__fileDescriptions}>
                                                                    <Link
                                                                        variant="body2"
                                                                        className={classes.rfq__table__fileLink}
                                                                        onClick={() => {
                                                                            this.props.handleShowQuote(row.id)
                                                                        }}
                                                                        underline={'hover'}
                                                                    >
                                                                        <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                                    </Link>
                                                                    <div className={classes.rfq__table__fileDatesContainer}>
                                                                        <Typography
                                                                            variant='body2'
                                                                            color='textSecondary'>
                                                                            {this.quoteDateFormat(row.clientPO.date)}
                                                                        </Typography>
                                                                        <Typography className={moment().diff(moment(row.clientPO.date), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.clientPO.date), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                            {moment(row.clientPO.date).fromNow(true)}
                                                                        </Typography>
                                                                    </div>
                                                                    <TasksShortcut row={row}
                                                                        handleShowQuote={this.props.handleShowQuote} />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                                    <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                                    <div className={classes.list__vendors__cell}>
                                                                        {Permissions.checkSensitiveInformationPermission() && (
                                                                            <>
                                                                                <Typography className={`${classes.clientPO__status} ${status === i18n.t("quotes.pos.statusReady") ? classes["clientPO__status--readyToDeliver"] : ''}`} color='textSecondary' noWrap>
                                                                                    {status}
                                                                                </Typography>
                                                                                {row.vendorsPOs && row.vendorsPOs.map((v, i) => (
                                                                                    <div key={i} className={classes.list__vendors__cell__row}>
                                                                                        {v.vendorPOStatusId === 1 && (
                                                                                            <Drafts className={classes.vendor__status__icon__preparing} />
                                                                                        )}
                                                                                        {(v.vendorPOStatusId === 2 || v.vendorPOStatusId === 4) && (
                                                                                            <Send className={classes.vendor__status__icon__requested} />
                                                                                        )}
                                                                                        {v.vendorPOStatusId === 3 && (
                                                                                            <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                                        )}
                                                                                        <Typography key={i} noWrap>
                                                                                            <strong>{`${v.vendorQuoteId} `}</strong>
                                                                                            <span>{v.vendorName}</span>
                                                                                        </Typography>
                                                                                    </div>
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                    <div>
                                                                        <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>


                                                                        {row.salesRepresentative && row.salesRepresentative.name && (
                                                                            <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                        )}

                                                                        <TasksShortcut
                                                                            row={row}
                                                                            handleShowQuote={this.props.handleShowQuote}
                                                                            anchorOrigin={{
                                                                                vertical: 'center',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'center',
                                                                                horizontal: 'right',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {row.tasks && row.tasks.length > 0 && row.tasks.filter(task =>
                                                                                    !task.complete)
                                                                                    .map(task => {
                                                                                        if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                            usersListed.push(task.followUpUser.id);
                                                                                            return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                        }
                                                                                        return null
                                                                                    })}
                                                                            </div>
                                                                        </TasksShortcut>

                                                                    </div>
                                                                    <div className={classes.list__countryContainer}>
                                                                        <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                                    </div>
                                                                    <Typography color='textSecondary'>
                                                                        <NumberFormat value={row.totals.poTotals.total}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            prefix={row.currencySymbol}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true} />
                                                                    </Typography>
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>
                                                        {i < (rows.length - 1) && (
                                                            <Divider />
                                                        )}
                                                    </div>
                                                )
                                            })
                                            }

                                        </List>
                                    </Paper>
                                </Hidden>
                            </div>
                        }

                        {groupBySection === 'vendors' && !hideVendorPOs &&
                            <div>
                                <Tabs
                                    value={currentTab}
                                    onChange={this.filterVendorPOs}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                >

                                    {options.map((option, i) => (
                                        <Tab
                                            key={i}
                                            label={`${counters[i]} ${option.label}`}
                                            style={{ width: 120 }}
                                        />
                                    ))}
                                </Tabs>

                                <Divider className={classes.vendorPos__tabs__divider} />

                                {filteredVendorsPOs && filteredVendorsPOs.length > 0 && (
                                    <div>
                                        <Hidden xsDown implementation="css">
                                            <ReactTable
                                                data={filteredVendorsPOs}
                                                columns={[
                                                    {
                                                        Header: i18n.t("quotes.pos.vendorPOs.table.column1"),
                                                        filterable: true,
                                                        id: 'vendor',
                                                        width: 290,
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        },
                                                        accessor: row => {
                                                            let receivedSum = row.items.reduce(((total, item) => total + parseFloat(item.vendorQuoteItemReceivedQuantity || 0)), 0),
                                                                requestedSum = row.items.reduce(((total, item) => total + parseFloat(item.vendorQuoteItemQuantity || 0)), 0);

                                                            return (
                                                                <div className={classes.rfq__table__fileDescriptions}>
                                                                    <div className={classes.poList__vendorPOs__cell} style={{ whiteSpace: 'pre' }}>
                                                                        <Link
                                                                            variant="body2"
                                                                            className={classes.rfq__table__fileLink}
                                                                            onClick={() => this.props.handleShowQuote(row.fileId, { showVendorsPOs: true })}
                                                                        >
                                                                            <Typography className={classes.rfq__table__fileLink} style={{ width: '90%' }} noWrap><strong>{`${row.vendorPONumber} ${row.vendorName}`}</strong></Typography>
                                                                        </Link>
                                                                        <Typography color='textSecondary'>
                                                                            {i18n.t("quotes.requestedByClient.table.file")} {`${row.fileId}`}
                                                                        </Typography>
                                                                        <Typography color='textSecondary' style={{ fontSize: '14px' }}>
                                                                            {row.vendorPOStatusId === 1 &&
                                                                                `${i18n.t("quotes.pos.vendorPOs.created")} ${this.quoteDateFormat(row.vendorPODate)} \n`
                                                                            }

                                                                            {(row.vendorPOStatusId >= 2) &&
                                                                                `${i18n.t("quotes.pos.vendorPOs.requested")} ${this.quoteDateFormat(row.vendorPOSendToVendorDate)} \n`
                                                                            }

                                                                            {row.vendorPOStatusId === 3 &&
                                                                                `${i18n.t("quotes.pos.vendorPOs.received")} ${this.quoteDateFormat(row.vendorPOReceiveDate)}`
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.list__file__lastUpdate__container}>
                                                                        <Typography className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                            {moment(row.lastUpdateDateTime).fromNow(true)}
                                                                        </Typography>
                                                                    </div>

                                                                    {(row.vendorPOStatusId === 2 || row.vendorPOStatusId === 4) && requestedSum - receivedSum !== 0 &&
                                                                        <div>
                                                                            <Typography className={classes.status__label} noWrap>{`${receivedSum} of ${requestedSum} ${i18n.t("quotes.pos.received")}`}</Typography>
                                                                        </div>
                                                                    }
                                                                    {Permissions.checkSensitiveInformationPermission() && (
                                                                        <TasksShortcut
                                                                            row={row}
                                                                            handleShowQuote={this.props.handleShowQuote}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        },
                                                        Filter: ({ onChange }) => (
                                                            <div className={classes.flexCell}>
                                                                <TextField
                                                                    id='txtUser_Author'
                                                                    autoComplete='off'
                                                                    style={{ flex: 1 }}
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Search style={{ fontSize: '12px' }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        ),
                                                        filterMethod: (filter, row) => {
                                                            return `${row._original.fileId}`.indexOf(filter.value) >= 0 ||
                                                                row._original.vendorName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 || `${row._original.vendorPOStatusId === 1 ? this.quoteDateFormat(row._original.vendorPODate)
                                                                    : (row._original.vendorPOStatusId === 2 || row._original.vendorPOStatusId === 4) ? this.quoteDateFormat(row._original.vendorPOSendToVendorDate)
                                                                        : this.quoteDateFormat(row._original.vendorPOReceiveDate)}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 ||
                                                                `${options.find(v => v.value === row._original.vendorPOStatusId) && options.find(v => v.value === row._original.vendorPOStatusId).label}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.column2"),
                                                        filterable: true,
                                                        id: 'quoteClientName',
                                                        accessor: row => (
                                                            <div className={classes.list__fileDetails}>
                                                                <Typography className={classes.list__fileClient} noWrap>{row.clientName}</Typography>
                                                                <Typography className={classes.list__fileName}>{`${row.rfqName}`}</Typography>
                                                                <Typography className={classes.list__fileReference}>{`${row.rfqReference}`}</Typography>
                                                            </div>
                                                        ),
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        },
                                                        Filter: ({ onChange }) => (
                                                            <div className={classes.flexCell}>
                                                                <TextField
                                                                    id='txtQuoteClientName'
                                                                    autoComplete='off'
                                                                    style={{ flex: 1 }}
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Search style={{ fontSize: '12px' }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        ),
                                                        filterMethod: (filter, row) => {
                                                            let { value } = filter;
                                                            setSearchParmeters({ client: value });
                                                            return (`${row._original.clientName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                                `${row._original.rfqReference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                                `${row._original.rfqName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.requestedByClient.table.country"),
                                                        filterable: true,
                                                        id: 'country',
                                                        width: 60,
                                                        accessor: row => (
                                                            <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.countryCode}</Typography>
                                                        ),
                                                        Filter: ({ filter, onChange }) => (
                                                            <div className={classes.flexCell}>
                                                                <Select
                                                                    disabled={!countries || (countries && countries.length <= 0)}
                                                                    native
                                                                    fullwidth="true"
                                                                    onChange={event => onChange(event.target.value)}
                                                                    style={{ width: '100%', minWidth: '55px' }}
                                                                    classes={{
                                                                        select: classes.country__select
                                                                    }}
                                                                    value={filter ? filter.value : "all"}
                                                                >
                                                                    <option value="all">All</option>
                                                                    {countries && countries.map(country => (
                                                                        <option key={country} value={country}>{country}</option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        ),
                                                        filterMethod: (filter, row) => {
                                                            let { value } = filter;
                                                            return value.toLowerCase() === 'all' ? true : (row._original.countryCode.toLowerCase() === value.toLowerCase())

                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.poDate"),
                                                        id: 'poDate',
                                                        width: 100,
                                                        style: { textAlign: 'right' },
                                                        filterable: false,
                                                        accessor: row => {
                                                            return (
                                                                <div>
                                                                    <Typography>
                                                                        {moment(row.vendorPODate).format("MM/DD/YYYY")}
                                                                    </Typography>
                                                                    <Typography>
                                                                        + {row.vendorQuoteDeliveryDays}
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.poEta"),
                                                        id: 'poEta',
                                                        width: 100,
                                                        filterable: false,
                                                        style: { textAlign: 'right' },
                                                        accessor: row => {
                                                            return (
                                                                <Typography>
                                                                    {moment(row.vendorPODeliveryDate).format("MM/DD/YYYY")}
                                                                </Typography>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.quoted.table.column7"),
                                                        width: 100,
                                                        heigth: 50,
                                                        id: 'total',
                                                        show: Permissions.checkSensitiveInformationPermission(),
                                                        className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                                        accessor: row => {
                                                            let total = row.items.reduce((total, item) => {
                                                                return total + (item.vendorPOItemQuantity * item.vendorPOItemUnitAmount)
                                                            }, 0),
                                                                otherCosts = row.otherCosts.reduce((total, oc) => {
                                                                    return total + oc.vendorPOOtherCostAmount
                                                                }, 0)
                                                            return (
                                                                <div>
                                                                    <NumberFormat value={total + otherCosts}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        prefix={row.currencySymbol}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true} />
                                                                </div>
                                                            )
                                                        },
                                                        Footer: (columnProps) => {
                                                            let bigTotal = columnProps.data.reduce(((total, row) => parseFloat(total) + (parseFloat(
                                                                row._original.items.reduce((itemTotal, item) => {
                                                                    return itemTotal + (item.vendorPOItemQuantity * item.vendorPOItemUnitAmount)
                                                                }, 0))) + (parseFloat(
                                                                    row._original.otherCosts.reduce((ocTotal, oc) => {
                                                                        return ocTotal + oc.vendorPOOtherCostAmount
                                                                    }, 0)))
                                                            ), 0);
                                                            return (<div>
                                                                <NumberFormat value={bigTotal}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true} />
                                                            </div>)
                                                        }
                                                    },
                                                    {
                                                        Header: i18n.t("quotes.pos.vendorPOs.table.column3"),
                                                        filterable: true,
                                                        id: 'user_Author',
                                                        accessor: (row) => {
                                                            let currentFile = rows.find(file => file.id === row.fileId)
                                                            return (
                                                                <Typography className={classes.list__fileAuthor}>{currentFile.salesPerson.userName}</Typography>
                                                            )
                                                        },
                                                        width: 150,
                                                        style: {
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                        },
                                                        Filter: ({ onChange }) => (
                                                            <div className={classes.flexCell}>
                                                                <TextField
                                                                    id='txtUser_Author'
                                                                    autoComplete='off'
                                                                    style={{ flex: 1 }}
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Search style={{ fontSize: '12px' }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        ),
                                                        filterMethod: (filter, row) => {
                                                            let { value } = filter;

                                                            let currentFile = rows.find(file => file.id === row._original.fileId)
                                                            setSearchParmeters({ salesperson: value });
                                                            return `${currentFile && currentFile.salesPerson.userName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                        }
                                                    },
                                                ]}
                                                minRows={0}
                                                pageSize={filteredVendorsPOsSize}
                                                onPageSizeChange={(pageSize, page) =>
                                                    this.setState({ page, pageSize })
                                                }
                                                className={classNames(classes.table, ' -striped -highlight')}
                                                showPaginationTop={filteredVendorsPOs && filteredVendorsPOs.length >= 50 ? true : false}
                                                showPaginationBottom={filteredVendorsPOs && filteredVendorsPOs.length >= 50 ? true : false}
                                                sortable={false}
                                                resizable={false}
                                            />
                                        </Hidden>
                                        <Hidden smUp implementation="css">
                                            <Paper>
                                                <List>
                                                    {rows.map((row, i) => (
                                                        <div key={row.id}>
                                                            <ListItem key={row.id} >
                                                                <ListItemText>
                                                                    <div>
                                                                        <Link
                                                                            variant="body2"
                                                                            className={classes.rfq__table__fileLink}
                                                                            onClick={() => this.props.handleShowQuote(row.id)}
                                                                        >
                                                                            <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                                        </Link>
                                                                        <br />
                                                                        <Badge
                                                                            badgeContent={row.notesCount}
                                                                            color="error"
                                                                            classes={{ badge: classes.rfq__task__badge }}>
                                                                            <Badge badgeContent={moment().diff(moment(row.lastUpdateDateTime), 'days')} color="primary">
                                                                                <Typography className={classes.list__fileDate} >
                                                                                    {i18n.t("quotes.requestedByClient.table.lastUpdate")} {this.quoteDateFormat(row.lastUpdateDateTime)}
                                                                                </Typography>
                                                                            </Badge>
                                                                        </Badge>
                                                                        <Typography
                                                                            variant='body2'
                                                                            color='textSecondary'>
                                                                            {i18n.t("quotes.requestedByClient.table.created")} {this.quoteDateFormat(row.createDateTime)}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                                        <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                                        <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                                        <Typography className={classes.list__fileAuthor} >{row.salesPerson.userName}</Typography>
                                                                    </div>
                                                                </ListItemText>
                                                            </ListItem>
                                                            {i < (rows.length - 1) && (
                                                                <Divider />
                                                            )}
                                                        </div>
                                                    ))}

                                                </List>
                                            </Paper>
                                        </Hidden>
                                    </div>
                                )}
                                {filteredVendorsPOs && filteredVendorsPOs.length === 0 && (
                                    <div className={classes.empty__state__container}>
                                        <Inbox style={{ fontSize: 30 }} color='secondary' />
                                        <Typography variant='body2'>{
                                            currentTab === 0 ?
                                                i18n.t('quotes.pos.noVendorQuotesPreparing') :
                                                currentTab === 1 ?
                                                    i18n.t('quotes.pos.noVendorQuotesSentToVendor') :
                                                    i18n.t('quotes.pos.noVendorQuotesReceived')
                                        }</Typography>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                }

                {rows && rows.length === 0 && !fetching && (
                    <div className={classes.purchase__orders__noRows}>
                        {this.props.people.filterPeople && this.props.people.filterPeople.length === 0 ? (
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('quotes.pos.noQuotes')}</Typography>
                            </div>
                        ) : (
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 50 }} color='secondary' />
                                    <Typography variant='body2'>
                                        {this.props.people.filterPeople.map((person, i) => (
                                            <span key={i}>{`${person.userName}${this.props.people.filterPeople.length > 1 && (this.props.people.filterPeople.length > i + 1) ? ', ' : ' '}`} </span>
                                        ))}
                                        <br />
                                        {this.props.people.filterPeople.length === 1 ? i18n.t('peopleFilter.hasNot') : i18n.t('peopleFilter.haveNot')}
                                        {i18n.t('peopleFilter.pendignPOs')}
                                    </Typography>
                                    {Permissions.checkSensitiveInformationPermission() && (
                                        <Typography variant='body2'>
                                            <Link className={classes.customLink}
                                                onClick={() => {
                                                    this.props.generalActions.showPeopleFilter(true)
                                                }}>
                                                {i18n.t('peopleFilter.changeUsersFilter')}
                                            </Link>
                                        </Typography>
                                    )}
                                </div>
                            )}
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch), 
    }
}

function mapStateToProps(state) {
    return {
        files: state.files,
        people: state.people,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PurchaseOrders))