import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Fade,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    withMobileDialog,
    CircularProgress
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
} from '@material-ui/icons'
import API from '../../lib/api';

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as clientsActions from '../../actions/clientsActions'


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    client__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    buttonSpinner: theme.buttonSpinner,
    client__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    }
});


class EventClientForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: props.client ? props.client : null,
            country: props.country ? props.country : props.client ? props.client.country : null,
            name: props.client ? props.client.name : '',
            taxId: props.client ? props.client.taxId : '',
            address1: props.client ? props.client.address.address1 : '',
            address2: props.client ? props.client.address.address2 : '',
            phone: props.client ? props.client.phone : '',
            fax: props.client ? props.client.fax : '',
            formType: null,
            validating: false,
            saving: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            client: nextProps.client ? nextProps.client : null,
            country: nextProps.country ? nextProps.country : nextProps.client ? nextProps.client.country : null,
            name: nextProps.client ? nextProps.client.name : '',
            taxId: nextProps.client ? nextProps.client.taxId : '',
            address1: nextProps.client && nextProps.client.address ? nextProps.client.address.address1 : '',
            address2: nextProps.client && nextProps.client.address ? nextProps.client.address.address2 : '',
            phone: nextProps.client ? nextProps.client.phone : '',
            fax: nextProps.client ? nextProps.client.fax : ''
        })
    }

    validateFormData = () => {
        const { name, address1, country } = this.state;
        this.setState({
            validating: true
        })

        if (name === '' || address1 === '' || !country || !country.id) {
            return false
        }
        return true;
    }

    manageClient = () => {
        const { name, taxId, address1,
            address2, phone, fax,
            country, client } = this.state

        if (this.validateFormData()) {
            this.setState({
                saving: true
            })

            let newClient = {
                id: client && client.id ? client.id : null,
                name,
                taxId,
                address: {
                    address1,
                    address2
                },
                phone,
                fax,
                country: { id: country.id }
            }

            if (client && client.id) {
                //Update client
                API.Clients.updateClient({ client: newClient }).then(res => {
                    if (res.id) {
                        this.setState({
                            client: newClient,
                            validating: false,
                            saving: false
                        }, () => {
                            this.props.clientsActions.updateClient(res)
                            this.props.updateClient(res)
                            this.props.onClose()
                        })
                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            }
            else {
                //CreateClient
                API.Clients.createClient({ client: newClient }).then(res => {
                    if (res.id) {
                        this.setState({
                            client: newClient,
                            validating: false,
                            saving: false
                        }, () => {
                            this.props.clientsActions.addClient(res)
                            this.props.updateClient(res)
                            this.props.onClose()
                        })
                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            }
        }
    }

    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onExited = () => {
        this.setState({
            client: null,
            country: null,
            name: '',
            taxId: '',
            address1: '',
            address2: '',
            phone: '',
            fax: ''
        })
    }

    render() {
        const { validating, name, taxId, address1, saving,
            address2, phone, fax, client, country } = this.state,
            { classes } = this.props;

        return (
            <div>
                {/* <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={true}
                    open={this.props.open}
                    onExited={this.onExited}
                    TransitionComponent={SlideLeft}
                > */}
                <Fade in={this.props.open}
                    mountOnEnter
                    unmountOnExit>
                    <div>

                        <DialogTitle disableTypography={true} className={classes.client__form__title}>
                            <IconButton onClick={this.props.onClose}>
                                <KeyboardArrowLeft color='inherit' className={classes.client__form__title__back} />
                            </IconButton>
                            <Typography variant='body1' color="inherit">
                                {!client ? i18n.t('visits.client.createClient') : i18n.t('visits.client.updateClient')}
                            </Typography>
                            <div style={{ width: '30px' }} />
                        </DialogTitle>

                        <DialogContent style={{ padding: '0' }}>
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label style={{ alignItems: 'center' }}>
                                        {i18n.t('visits.client.country')}
                                        <input value={country && country.id ? country.name || '' : ''} name='country' disabled />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.name')}
                                        <input required value={name} valid={validating && (!name || name === '') ? false : true} name='name' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.taxId')}
                                        <input required value={taxId} name='taxId' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.address1')}
                                        <input required value={address1} name='address1' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.address2')}
                                        <input value={address2} name='address2' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.phone')}
                                        <input required value={phone} name='phone' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.client.fax')}
                                        <input value={fax} name='fax' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup>
                                    <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                                        <mobiscroll.Button color="primary" disabled={saving} onClick={this.manageClient}>
                                            {i18n.t("visits.client.saveClient")}
                                            {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </mobiscroll.Button>
                                    </div>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        </DialogContent>
                    </div>
                </Fade>
                {/* </Dialog> */}
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        clients: state.clients
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventClientForm)));
