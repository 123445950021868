import React, { Component } from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    withStyles,
    withMobileDialog
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Search
} from '@material-ui/icons/';
import { withRouter } from "react-router";
import EventsLeftSidebar from './EventsLeftSidebar';
import { EventFilterList } from '.'
import LeftSidebar from '../LeftSidebar';
import styled from 'styled-components';
import auth from '../../authentication/auth';
import {
    isMobile
} from "react-device-detect";

// REDUX
import { connect } from 'react-redux'


const logo = require('../../assets/images/logosmall.png');
const logomobile = require('../../assets/images/logomobile.png');

const HeaderLogo = styled.div`
    flex-grow:1;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        margin-right:5px;
    };
`;



const styles = (theme) => ({
    blankSpace: {
        height: '50px',
        background: 'blue'
    },
    logoAndSearchContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: theme.maxWith,
        margin: '0 auto',
        marginLeft: '-20px',
        background: 'yellow',
    },
    toolbar: {
        maxWidth: theme.maxWith,
        margin: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    mail__button__container: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '16px',
        cursor: 'pointer'
    },
    activeFilter: {
        color: theme.palette.orange[100],
        border: `2px solid ${theme.palette.orange[100]}`,
        padding: '4px'
    }
})

class EventsHeader extends Component {
    state = {
        menuVisible: false,
        filterVisible: false,
        anchorFilterEl: null,
        anchorEl: null,
        user: null,
        filterCount: 0
    }

    componentWillMount() {
        const { history } = this.props;
        auth.getAuthenticatedUser()
            .then(user => {
                this.setState({
                    user
                })
            }).catch(err => {
                auth.signOut().then(() => {
                    history.push('/signIn');
                }).catch(err => console.log(err));
            });
    }

    componentWillReceiveProps(nextProps) {
        const { user } = this.state

        let filterCount = 0

        if (nextProps.eventsFilters.search) {
            filterCount += 1
        }

        if (nextProps.eventsFilters.missedCheckIn) {
            filterCount += 1
        }

        if (nextProps.people.filterPeople.length > 1 || (nextProps.people.filterPeople.length === 1 && !nextProps.people.filterPeople[0].userId) || (nextProps.people.filterPeople.length === 1 && nextProps.people.filterPeople[0].userId && user && nextProps.people.filterPeople[0].userId !== parseInt(user.attributes['custom:userID']))) {
            filterCount += 1
        }

        this.setState({
            filterCount
        })

    }


    toggleOpenMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        });
    }

    toggleOpenFilterMenu = () => {
        this.setState({
            filterVisible: !this.state.filterVisible
        })
    }

    render() {
        const { classes, width, eventsFilters, people } = this.props,
            { user } = this.state;

        let activeFilter = (eventsFilters && (eventsFilters.search || eventsFilters.missedCheckIn)) || people.filterPeople.length > 1 || (people.filterPeople.length === 1 && user && user.attributes && people.filterPeople[0].userId !== parseInt(user.attributes['custom:userID'])) ? true : false

        return (
            <div>
                <AppBar position="fixed" color="default">
                    {isMobile || width === 'xs' ? (
                        <EventsLeftSidebar
                            menuVisible={this.state.menuVisible}
                            toggleOpenMenu={this.toggleOpenMenu}
                        />
                    ) : (
                            <LeftSidebar
                                menuVisible={this.state.menuVisible}
                                toggleOpenMenu={this.toggleOpenMenu}
                            />
                        )}
                    <Toolbar className={classes.toolbar} >
                        <div style={width !== 'xs' ? { width: '194px', display: 'flex' } : {}}>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={this.toggleOpenMenu} >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <HeaderLogo style={{ width: '50%', position: 'absolute', left: 0, right: 0, margin: 'auto' }}>
                            <img src={isMobile || width === 'xs' ? logomobile : logo} alt='Prodek'
                                style={{
                                    width: isMobile || width === 'xs' ? 50 : 95,
                                    paddingTop: isMobile || width === 'xs' ? 0 : 8,
                                    margin: 0
                                }} />
                        </HeaderLogo>
                        <div style={width !== 'xs' ? {} : { marginRight: '40px' }}>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                className={activeFilter ? classes.activeFilter : ''}
                                onClick={this.toggleOpenFilterMenu} >
                                <Search />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <EventFilterList
                    menuVisible={this.state.filterVisible}
                    toggleOpenMenu={this.toggleOpenFilterMenu}
                    user={user}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        eventsFilters: state.eventsFilters,
        people: state.people
    }
}

export default connect(mapStateToProps)(withRouter(withMobileDialog()(withStyles(styles)(EventsHeader))));