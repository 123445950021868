import React, { Component } from 'react'
import {
    Paper,
    Link,
    withStyles,
    Grid,
    Typography,
} from '@material-ui/core'
import i18n from 'i18next'
import moment from 'moment'


import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as vendorsActions from '../../../actions/vendorsActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'


import { CustomMessages } from '../../general'
import * as vendorQuotesActions from '../../../actions/vendorQuotesActions'
import {
    VendorQuoteReadOnlyInformation,
    VendorQuoteForm,
    VendorQuoteItems,
    VendorQuotesActions,
    VendorQuoteTotals
} from '.';
import { Warning } from '../../messages'


const styles = theme => {
    return ({
        vendor__quote__rootPaper: {
            margin: theme.spacing(),
            marginTop: 0,
            padding: theme.spacing(2),
            position: 'relative',
        },
        vendor__quote__name: {
            textAlign: 'left'
        },
        vendor__quote__expand__colapse__button: {
            marginLeft: (theme.spacing() / 2),
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing()
            }
        },
        vendor__quote__right__buttons__container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: theme.spacing(),
                right: theme.spacing(),
                flexDirection: 'column',
                alignItems: 'flex-end',
            }
        },
        vendor__quote__expand__edit__button: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing()
            },
            padding: '8px'
        },
        vendor__quote__expand__edit__button__icon: {
            fontSize: theme.typography.fontSize * 1.5
        },
        vendor__quote__collapsable__details: {
            padding: 0
        },
        vendor__quote__header__grid: {
            paddingTop: theme.spacing(),
            paddingBottom: theme.spacing(),
        },
        vendorQuote__editLink: {
            ...theme.customLink,
            marginLeft: theme.spacing(2)
        },
        vendor__form__returnButton: {
            ...theme.smallButton,
            width: '150px',
            minWidth: '150px',
            color: theme.palette.red[80],
            borderColor: theme.palette.red[80],
            marginLeft: theme.spacing()
        },
        vendorRFQ__changeStatus__Button: {
            ...theme.smallButton,
            width: '150px',
            minWidth: '150px',
            marginLeft: theme.spacing(),
            backgroundColor: theme.palette.blue[100],
            '&:hover': {
                backgroundColor: theme.palette.blue[80],
            },
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(),
            }
        },
        buttonSpinner: theme.buttonSpinner,
        button__wrapper: {
            position: 'relative'
        },
        vendor__form__actions__changeStatus__buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        vendor__quote__actions__container: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                flexDirection: 'column'
            },
        }
    })
}
class VendorQuoteCard extends Component {
    constructor(props) {
        super(props)

        this.myRef = React.createRef()
        let newItems = [];

        if (props.vendorQuote) {
            let vendorItem = {};

            newItems = props.vendorQuote && props.vendorQuote.status.id === 1 ? props.file.items.map((item, i) => {
                vendorItem = props.vendorQuote.items.find(vqi => vqi.rfqItemId === item.id);
                if (vendorItem) {
                    return {
                        ...vendorItem,
                        include: true
                    }
                }
                return {
                    ...item,
                    rfqItemId: item.id
                }
            }) : props.vendorQuote && props.vendorQuote.status.id > 1 ?
                    props.vendorQuote.items.map((vq, i) => {
                        return {
                            ...vq,
                            include: true
                        }
                    })
                    : props.file.items.map((item, i) => {
                        return {
                            ...item,
                            rfqItemId: item.id
                        }
                    });
        }

        this.state = {
            itemsAccepted: [],
            saving: false,
            items: newItems,
            showEditVendorQuote: this.props.showEditMode ? true : false,
            editable: this.props.editable,
            vendorQuote: this.props.vendorQuote,
            file: this.props.file,
            apiErrorMessage: null,
            selectedItem: null,
            showEditItemDialog: false
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.vendorQuote) {
            let vendorItem = {},
                newItems = nextProps.vendorQuote && nextProps.vendorQuote.status.id === 1 ? nextProps.file.items.map((item, i) => {
                    vendorItem = nextProps.vendorQuote.items.find(vqi => vqi.rfqItemId === item.id);
                    if (vendorItem) {
                        return {
                            ...vendorItem,
                            include: true
                        }
                    }
                    return {
                        ...item,
                        rfqItemId: item.id
                    }
                }) : nextProps.vendorQuote && nextProps.vendorQuote.status.id > 1 ?
                        nextProps.vendorQuote.items.map((vq, i) => {
                            return {
                                ...vq,
                                include: true
                            }
                        })
                        : nextProps.file.items.map((item, i) => {
                            return {
                                ...item,
                                rfqItemId: item.id
                            }
                        });

            this.setState({
                items: newItems,
                vendorQuote: nextProps.vendorQuote
            })
        }

        this.setState({
            editable: nextProps.editable,
            file: nextProps.file
        })
    }


    componentDidMount = () => {
        var newItemsAccepted = [];

        newItemsAccepted = this.props.vendorQuote.items.filter(vqi => vqi.vendorQuoteItemIsAccepted);
        this.setState({
            itemsAccepted: newItemsAccepted
        })
    }

    openEditVendorQuote = () => {
        this.setState({
            showEditVendorQuote: true
        })
    }

    handleCloseEditVendorQuote = () => {
        this.props.generalActions && this.props.generalActions.showEditVendorQuote(false);
        this.setState({
            showEditVendorQuote: false
        })
    }

    handleAcceptItems = value => () => {
        const { itemsAccepted } = this.state;

        const currentIndex = itemsAccepted.indexOf(value);

        const newitemsAccepted = [...itemsAccepted];

        if (currentIndex === -1) {
            newitemsAccepted.push(value);
        } else {
            newitemsAccepted.splice(currentIndex, 1);
        }

        this.setState({
            itemsAccepted: newitemsAccepted,
        });
    };

    updateVendorQuoteItems = (vendorQuote) => {
        this.props.updateVendorQuote({ vendorQuote: vendorQuote })
        this.setState({
            showEditItemDialog: false
        })
        this.props.generalActions && this.props.generalActions.showEditVendorQuote(false);
    }

    updateQuoteVendor = ({ vendor, contact }) => {
        let { vendorQuote } = this.state,
            updatedVendorQuote = { ...vendorQuote };
        if (vendor) {
            updatedVendorQuote.vendor = { ...vendor };
        }
        if (contact) {
            updatedVendorQuote.contact = { ...contact }
        }
        this.setState({ vendorQuote: updatedVendorQuote })
    }

    afterSaveNote = () => {
        this.setState({
            showEditVendorQuote: true
        })
    }

    render() {
        const {
            classes,
            currencySymbol,
        } = this.props,
            { vendorQuote,
                showEditVendorQuote,
                editable,
                file, saving,
                items,
                apiErrorMessage,
            } = this.state;

        if (!vendorQuote) return null;

        let maxVersion = 0;

        if(vendorQuote.parentId) {
            let quotes = file.vendorQuotes.filter(vq => vq.parentId === vendorQuote.parentId)
            maxVersion = quotes.reduce(
                (max, vqVersion) => (vqVersion.version > max ? vqVersion.version : max),
                0
            );
        }


        let itemsAccepted = vendorQuote.items.filter(vqi => vqi.vendorQuoteItemIsAccepted);
        return (
            <div>
                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />
                {/* Editar vendor quote  */}
                <VendorQuoteForm
                    deleteVendorQuote={this.props.deleteVendorQuote}
                    updateVendorQuote={this.props.updateVendorQuote}
                    vendorQuote={vendorQuote}
                    changeStatusOnSave={this.props.general.showEditVendorQuote ? true : false}
                    open={showEditVendorQuote || (this.props.general.showEditVendorQuote && this.props.general.vendorQuoteId === vendorQuote.id)}
                    file={file}
                    vendorQuotesActions={this.props.vendorQuotesActions}
                    handleClose={this.handleCloseEditVendorQuote}
                    updateQuoteVendor={this.updateQuoteVendor}
                />

                <Paper className={this.props.classNames ? this.props.classNames.vendor_quote_card_paper : classes.vendor__quote__rootPaper}>
                    <Grid container spacing={2} alignItems='center' className={classes.vendor__quote__header__grid}>

                        {((file && file.status === 2) || (file && file.status !== 2 && vendorQuote && vendorQuote.status.id !== 3)) &&
                            <Grid item xs={12} className={classes.vendor__quote__actions__container} style={{ flexDirection: 'column' }}>
                                <VendorQuotesActions
                                    file={file}
                                    editable={editable}
                                    deleteVendorQuote={this.props.deleteVendorQuote}
                                    vendorQuote={vendorQuote}
                                    updateVendorQuote={this.props.updateVendorQuote}
                                />
                                {file.status === 3 && (
                                    <Warning
                                        message={i18n.t('quotes.vendorQuoteRequest.clientQuotePreparingWarningMessage')}
                                        extendedMessage={i18n.t('quotes.vendorQuoteRequest.clientQuotePreparingWarningHelp')} />
                                )}
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ overflow: 'hidden' }}>
                                <Typography variant='h6'>
                                    {vendorQuote.vendor.name}
                                </Typography>

                                {vendorQuote.status.id === 1 && (
                                    <Typography variant='body2' color='textSecondary'>
                                        {i18n.t('quotes.vendorQR.created')} {moment(vendorQuote.createDateTime).format('MMMM Do, YYYY')}
                                    </Typography>
                                )}
                                {vendorQuote.status.id >= 2 && vendorQuote.requestedDateTime && (
                                    <Typography variant='body2' color='textSecondary'>
                                        {i18n.t('quotes.vendorQR.requested')} {moment(vendorQuote.requestedDateTime).format('MMMM Do, YYYY')}
                                    </Typography>
                                )}
                                {vendorQuote.status.id === 3 && vendorQuote.responseDateTime && (
                                    <Typography variant='body2' color='textSecondary'>
                                        {i18n.t('quotes.vendorQR.responded')} {moment(vendorQuote.responseDateTime).format('MMMM Do, YYYY')}
                                    </Typography>
                                )}
                                {itemsAccepted.length > 0 && (
                                    <Typography variant='body2' color='textSecondary'>{itemsAccepted.length} {itemsAccepted.length > 1 ? i18n.t('quotes.vendorQR.itemAccepted') : i18n.t('quotes.vendorQR.itemsAccepted')}</Typography>
                                )}
                            </div>

                            <div>
                                {(editable && vendorQuote.status.id <= 3 && ((!vendorQuote.parentId && (!vendorQuote.quotesVersions || vendorQuote.quotesVersions.length === 0)) || (vendorQuote.parentId && vendorQuote.version === maxVersion))) && (
                                    <Link className={classes.vendorQuote__editLink}
                                        variant='button'
                                        onClick={this.openEditVendorQuote}>
                                        {i18n.t('quotes.vendorQR.editVendorRFQ')}
                                    </Link>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                    <VendorQuoteReadOnlyInformation
                        file={file}
                        vendorQuote={vendorQuote}
                        currencySymbol={currencySymbol}
                        editItem={this.editItem}
                        showEditItemsButton={editable && vendorQuote && vendorQuote.status && vendorQuote.status.id !== 3}
                    />

                    <VendorQuoteItems
                        items={items}
                        desactiveSection={saving}
                        saveOnChange={true}
                        vendorQuoteSummary={editable && true}
                        updateVendorQuoteItems={this.updateVendorQuoteItems}
                        vendorQuote={vendorQuote}
                        currencySymbol={currencySymbol}
                        vendorQuoteStatusId={vendorQuote && vendorQuote.status ? vendorQuote.status.id : 1} />

                    <VendorQuoteTotals
                        currencySymbol={currencySymbol}
                        items={items.filter(item => !item.deleted)}
                        otherCosts={vendorQuote.otherCosts.filter(oc => !oc.deleted) || []}
                    />
                </Paper>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuoteCard));