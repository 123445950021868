import {
    GET_FILES,
    SET_FILES,
    SORT_FILES,
    ADD_FILE,
    UPDATE_FILE,
    MARK_FILE_TASK,
    ADD_FILE_NOTE,
    DELETE_FILE
} from '../actions/filesActions.js'
import {
    VENDOR_QUOTE_UPDATE,
    VENDOR_QUOTE_DELETE
} from '../actions/vendorQuotesActions.js'
import {
    UPDATE_CLIENT_QUOTE
} from '../actions/clientQuoteActions.js'


function files(state = [], action) {
    let file = null,
        files = [],
        fileIndex = -1,
        vendors = [],
        task = null,
        note = null;
    switch (action.type) {
        case GET_FILES:
            return state;
        case SET_FILES:
            files = action.files;
            return [...files];
        case ADD_FILE:
            file = action.file;
            return [
                {
                    ...file,
                    tasks: []
                },
                ...state
            ];

        case DELETE_FILE:
            fileIndex = state.findIndex(f => f.id === action.fileId);
            if (fileIndex >= 0) {
                return [
                    ...state.slice(0, fileIndex),
                    ...state.slice(fileIndex + 1)
                ]
            }
            return state

        case UPDATE_CLIENT_QUOTE:
            fileIndex = state.findIndex(f => f.id === action.clientQuote.fileId);
            if (fileIndex >= 0) {
                return [
                    ...state.slice(0, fileIndex),
                    {
                        ...state[fileIndex],
                        total: action.clientQuote.total,
                        subTotal: action.clientQuote.subTotal,
                        profitTotal: action.clientQuote.profitTotal,
                        otherCostTotal: action.clientQuote.otherCostTotal,
                        taxesTotal: action.clientQuote.taxesTotal
                    },
                    ...state.slice(fileIndex + 1)
                ]
            }
            return state;

        case VENDOR_QUOTE_UPDATE:
            fileIndex = state.findIndex(f => f.id === action.vendorQuote.fileId);
            if (fileIndex >= 0) {
                let vendorQuoteIndex = state[fileIndex].vendors.findIndex(v => v.vendorQuoteId === action.vendorQuote.id);

                if (vendorQuoteIndex >= 0) {
                    return [
                        ...state.slice(0, fileIndex),
                        {
                            ...state[fileIndex],
                            vendors: [
                                ...state[fileIndex].vendors.slice(0, vendorQuoteIndex),
                                {
                                    ...state[fileIndex].vendors[vendorQuoteIndex],
                                    vendorQuoteId: action.vendorQuote.id,
                                    vendorName: action.vendorQuote.vendor.name,
                                    statusId: action.vendorQuote.status.id,
                                    statusName: action.vendorQuote.status.name,
                                    items: action.vendorQuote.items
                                },
                                ...state[fileIndex].vendors.slice(vendorQuoteIndex + 1)
                            ]
                        },
                        ...state.slice(fileIndex + 1)
                    ]
                }
            }
            return state;

        case VENDOR_QUOTE_DELETE:
            fileIndex = state.findIndex(f => f.id === action.vendorQuote.fileId);

            if (fileIndex >= 0) {
                let vendorQuoteIndex = state[fileIndex].vendors.findIndex(v => v.vendorQuoteId === action.vendorQuote.id);

                if (vendorQuoteIndex >= 0) {
                    vendors = state[fileIndex].vendors;
                    vendors.splice(vendorQuoteIndex, 1)

                    return [
                        ...state.slice(0, fileIndex),
                        {
                            ...state[fileIndex],
                            ...{
                                ...file,
                                vendors: [...vendors]
                            }
                        },
                        ...state.slice(fileIndex + 1)
                    ]
                }
            }

            return state

        case UPDATE_FILE:
            file = action.file;
            fileIndex = state.findIndex(f => f.id === file.id);
            vendors = file.vendorQuotes && file.vendorQuotes.length > 0 ?
                file.vendorQuotes.map(vq => ({
                    vendorQuoteId: vq.id,
                    vendorName: vq.vendor.name,
                    statusId: vq.status.id,
                    statusName: vq.status.name,
                    vendorQuoteDateTimeCreated: vq.createDateTime,
                    vendorQuoteDateTimeRequested: vq.requestedDateTime,
                    vendorQuoteDateTimeResponse: vq.responseDateTime,
                    items: vq.items
                }))
                : [];

            if (fileIndex >= 0) {
                return [
                    ...state.slice(0, fileIndex),
                    {
                        ...state[fileIndex],
                        ...{
                            ...file,
                            vendors: [...vendors],
                            items: state[fileIndex].items ? [
                                ...state[fileIndex].items,
                                ...file.items
                            ] : file.items
                        }
                    },
                    ...state.slice(fileIndex + 1)
                ]
            }
            return state;
        case MARK_FILE_TASK:
            //TODO: Realmente este hace update de todo el task
            task = action.task;
            fileIndex = state.findIndex(f => f.id === task.fileId);
            if (fileIndex >= 0) {
                let tasks = [...state[fileIndex].tasks],
                    taskIndex = tasks.findIndex(t => t.id === task.id);
                return [
                    ...state.slice(0, fileIndex),
                    {
                        ...state[fileIndex],
                        tasks: [
                            ...state[fileIndex].tasks.slice(0, taskIndex),
                            {
                                ...task,
                                followUpUser: task.taskFollowUpUser,
                                complete: task.taskComplete
                            },
                            ...state[fileIndex].tasks.slice(taskIndex + 1)
                        ]
                    },
                    ...state.slice(fileIndex + 1)
                ]
            }

            return state;
        case ADD_FILE_NOTE:
            note = action.note;
            fileIndex = state.findIndex(f => f.id === note.fileId);
            if (fileIndex >= 0) {
                return [
                    ...state.slice(0, fileIndex),
                    {
                        ...state[fileIndex],
                        tasks: [
                            ...state[fileIndex].tasks,
                            {
                                ...note,
                                followUpUser: note.taskFollowUpUser
                            }
                        ]
                    },
                    ...state.slice(fileIndex + 1)
                ]
            }
            return state;
        case SORT_FILES:
            const { sortBy } = action;
            let sortedFiles = [...state];

            if (sortedFiles && sortedFiles.length > 0) {

                switch (sortBy) {
                    case 0:
                        sortedFiles = sortedFiles.sort((a, b) => {

                            switch (a.status) {
                                case 1:
                                case 2:
                                    if (a.requestForQuote.dateTime < b.requestForQuote.dateTime) return 1;
                                    if (a.requestForQuote.dateTime > b.requestForQuote.dateTime) return -1;
                                    break;
                                case 3:
                                case 4:
                                    if (a.clientQuote[0].date < b.clientQuote[0].date) return 1;
                                    if (a.clientQuote[0].date > b.clientQuote[0].date) return -1;
                                    break;
                                case 5:
                                    if (a.clientPO.date < b.clientPO.date) return 1;
                                    if (a.clientPO.date > b.clientPO.date) return -1;
                                    break;
                                default:
                                    if (a.createDateTime < b.createDateTime) return 1;
                                    if (a.createDateTime > b.createDateTime) return -1;
                                    break;
                            }
                            return 0
                        })
                        break;
                    case 1:
                        sortedFiles = sortedFiles.sort((a, b) => {
                            if (a.lastUpdateDateTime < b.lastUpdateDateTime) return 1;
                            if (a.lastUpdateDateTime > b.lastUpdateDateTime) return -1;
                            return 0
                        })
                        break;
                    case 2:
                        sortedFiles = sortedFiles.sort((a, b) => {
                            if (a.oportunity.id + a.probability.id < b.oportunity.id + b.probability.id) return 1;
                            if (a.oportunity.id + a.probability.id > b.oportunity.id + b.probability.id) return -1;
                            return 0
                        })
                        break;
                    default:
                        break;
                }
            }
            return [...sortedFiles]
        default:
            return state;
    }
}

export default files;