import {
    SET_FILTERS
} from '../actions/eventsActions'

function eventsFilter(state = {}, action) {
    switch (action.type) {
        case SET_FILTERS:
            return {
                ...state,
                ...action.filters
            };
        default:
            return state;
    }
}

export default eventsFilter;