import React from 'react'

import {
    Checkbox,
    withStyles,
    Grid,
    TextField,
    Link,
    Typography,
    InputAdornment
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import i18n from 'i18next'
import NumberFormat from 'react-number-format'
import { NumberFormatInputCustom } from '../../general'
import { VendorQuoteEditItem } from '.'
import ReacTable from 'react-table'
import classNames from 'classnames'
import { connect } from 'react-redux'

const styles = theme => ({
    vendor__quote__items__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main,
    },
    vendor__quote__items__table__quantity: {
        color: theme.palette.text.secondary
    },
    vendor__quote__items__table__grid: {
        display: 'flex',
        flexDirection: 'column'
    },
    vendor__form__textField: {
        width: '100%',
        margin: `${theme.spacing()}px 0`
    },
    vendor__form__textField__editable: {
        //background: '#fafafa'
    },
    vendor__quote__items__table__descriptionResumed: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    vendor__quote__items__table__descriptionExtended: {
        width: '100%',
        cursor: 'pointer'
    },
    customLink: {
        ...theme.customLink,
        fontSize: '16px'
    },
    quote__items__table__cell__right: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    items__not__included__label: {
        color: theme.palette.orange[100]
    },
    extendeDescription__icon: {
        fontSize: '20px',
        color: theme.palette.grey[500]
    }
})

class VendorQuoteSelectItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            vendorQuoteItems: props.vendorQuoteItems,
            showEditItemDialog: false,
            selectedItem: null,
            selectedDescription: null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            vendorQuoteItems: nextProps.vendorQuoteItems,
        })
    }

    extendDescription = (e, index) => {
        e.stopPropagation()
        const { selectedDescription } = this.state;

        this.setState({
            selectedDescription: index === selectedDescription ? null : index
        })
    }

    renderItemsFields = (cellInfo) => {
        const { vendorQuoteStatusId, classes, editable, isVendorPO } = this.props,
            { vendorQuoteItems, selectedDescription } = this.state;

        return (
            <Grid container spacing={1}>
                <Grid className={classes.vendor__quote__items__table__grid} item sm={editable ? 6 : 12} xs={12} style={{ whiteSpace: 'pre-line' }}>
                    {editable && vendorQuoteStatusId < 4 ? <>
                        <Typography className={classes.vendor__quote__items__table__itemPartNumber}>
                            {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${cellInfo.original.partNumber}`}
                        </Typography>
                        <TextField
                            id="itemReference"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.vendor__form__input
                            }}
                            fullWidth
                            autoComplete='off'
                            disabled={!vendorQuoteItems[cellInfo.index].include}
                            onChange={e => {
                                const items = [...vendorQuoteItems]
                                items[cellInfo.index].references = e.target.value
                                this.setState({
                                    vendorQuoteItems: items
                                })
                                this.props.updateVendorQuoteItems(items)
                            }}
                            className={`${classes.vendor__form__textField} ${classes.vendor__form__textField__editable}`}
                            type="text"
                            variant="outlined"
                            value={cellInfo.original.references}
                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')}
                        />
                    </> : <>
                            {isVendorPO ? <>
                                {cellInfo.original.partNumber && cellInfo.original.partNumber !== cellInfo.original.rfqItemPartNumber ? <>
                                    <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${cellInfo.original.rfqItemPartNumber}`}
                                    </Typography>
                                    <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')} ${cellInfo.original.partNumber}`}
                                    </Typography>
                                </> :
                                    <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                        {cellInfo.original.partNumber}
                                    </Typography>
                                }
                            </> : <>
                                    {cellInfo.original.references && cellInfo.original.references !== cellInfo.original.partNumber ? <>
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${cellInfo.original.partNumber}`}
                                        </Typography>
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')} ${cellInfo.original.references}`}
                                        </Typography>
                                    </> :
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {cellInfo.original.references}
                                        </Typography>
                                    }
                                </>}
                        </>}
                    <Typography color='textSecondary'>
                        {`${cellInfo.original.quantity} ${cellInfo.original.unit}`}
                    </Typography>
                    {editable && vendorQuoteStatusId === 1 ?
                        <TextField autoComplete='off'
                            id="itemDescription"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.vendor__form__input
                            }}
                            fullWidth
                            multiline
                            rows="3"
                            onChange={e => {
                                const items = [...vendorQuoteItems]
                                items[cellInfo.index].description = e.target.value
                                this.setState({
                                    vendorQuoteItems: items
                                })
                                this.props.updateVendorQuoteItems(items)
                            }}
                            disabled={vendorQuoteStatusId > 2 || !vendorQuoteItems[cellInfo.index].include}
                            value={cellInfo.original.description || ''}
                            className={`${classes.vendor__form__textField} ${vendorQuoteStatusId <= 2 && vendorQuoteItems[cellInfo.index].include ? classes.vendor__form__textField__editable : ''}`}
                            type="text"
                            variant="outlined"
                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.description')}
                        />
                        :
                        <div onClick={(e) => this.extendDescription(e, cellInfo.index)}
                            className={classes.vendor__quote__items__table__descriptionResumed}
                            style={{ alignItems: selectedDescription === cellInfo.index ? 'flex-start' : 'center' }}>
                            <Typography color='textSecondary'
                                style={{ whiteSpace: selectedDescription === cellInfo.index ? 'pre-line' : 'nowrap' }}
                                noWrap>
                                {cellInfo.original.description}
                            </Typography>
                            {cellInfo.original.description.length > 35 && <>
                                {selectedDescription === cellInfo.index ?
                                    <KeyboardArrowUp className={classes.extendeDescription__icon} />
                                    :
                                    <KeyboardArrowDown className={classes.extendeDescription__icon} />
                                }
                            </>}
                        </div>
                    }
                </Grid>
                {editable &&
                    <Grid className={classes.vendor__quote__items__table__grid} style={{ justifyContent: 'flex-end' }} item sm={6} xs={12}>
                        <TextField
                            id="vendorComments"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.vendor__form__input
                            }}
                            fullWidth
                            autoComplete='off'
                            multiline
                            disabled={!vendorQuoteItems[cellInfo.index].include}
                            rows={vendorQuoteStatusId === 1 ? "3" : "2"}
                            onChange={e => {
                                const items = [...vendorQuoteItems]
                                items[cellInfo.index].comments = e.target.value
                                this.setState({
                                    vendorQuoteItems: items
                                })
                                this.props.updateVendorQuoteItems(items)
                            }}
                            className={`${classes.vendor__form__textField} ${classes.vendor__form__textField__editable}`}
                            type="text"
                            variant="outlined"
                            value={cellInfo.original.comments ? cellInfo.original.comments : ''}
                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.comments')}
                        />
                    </Grid>
                }
            </Grid>
        )
    }

    renderOtherFields = (cellInfo) => {
        const { vendorQuoteStatusId, classes, currencySymbol } = this.props,
            { vendorQuoteItems } = this.state;
        return (
            <div>
                <TextField
                    fullWidth
                    autoComplete='off'
                    required={this.props.validateVendorQuote ? true : false}
                    variant="outlined"
                    // disabled={vendorQuoteStatusId === 3}
                    id="vendor_quote_amount"
                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.amount')}
                    name="otherCostAmount"
                    className={`${classes.vendor__form__textField} ${vendorQuoteStatusId === 2 ? classes.vendor__form__textField__editable : ''}`}
                    onChange={(e) => {
                        const items = [...vendorQuoteItems]
                        items[cellInfo.index].unitAmount = e.target.value
                        this.setState({
                            vendorQuoteItems: items
                        })
                        this.props.updateVendorQuoteItems(items)
                    }}
                    value={cellInfo.original.unitAmount === null ? 0 : cellInfo.original.unitAmount}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                        inputComponent: NumberFormatInputCustom,
                    }}
                    error={this.props.validateVendorQuote && !cellInfo.original.unitAmount}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br />
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id="vendor_quote_delivery_days"
                    // disabled={vendorQuoteStatusId === 3}
                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.delivery')}
                    name="otherCostAmount"
                    className={`${classes.vendor__form__textField} ${vendorQuoteStatusId === 2 ? classes.vendor__form__textField__editable : ''}`}
                    onChange={(e) => {
                        const items = [...vendorQuoteItems]
                        items[cellInfo.index].deliveryDays = e.target.value
                        this.setState({
                            vendorQuoteItems: items
                        })
                        this.props.updateVendorQuoteItems(items)
                    }}
                    style={{
                        marginTop: '10px',
                    }}
                    value={cellInfo.original.deliveryDays}
                    InputProps={{
                        endAdornment: <InputAdornment position="end" >
                            {i18n.t('quotes.vendorQuoteRequest.itemsTable.days')}
                        </InputAdornment>,
                        inputComponent: NumberFormatInputCustom,
                    }}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        )
    }

    editItem = (row) => {
        this.setState({
            selectedItem: row,
            showEditItemDialog: true
        })
    }

    onClose = () => {
        this.setState({
            showEditItemDialog: false,
            selectedItem: null
        })
        this.props.generalActions && this.props.generalActions.showEditVendorQuote(false);
    }
    render() {
        const { vendorQuoteStatusId, classes,
            currencySymbol, editable,
            vendorQuoteSummary, vendorQuote, isVendorPO } = this.props,
            { vendorQuoteItems, selectedItem, showEditItemDialog } = this.state;

        if (!vendorQuoteItems) return null

        return (
            <div style={{ marginTop: '16px' }}>
                <ReacTable
                    data={vendorQuoteItems.sort((a, b) => {
                        if (a.rfqItemId < b.rfqItemId) return -1;
                        if (a.rfqItemId > b.rfqItemId) return 1;
                        return 0
                    })}
                    getTrProps={(state, rowInfo, column, instance) => {
                        if (vendorQuoteSummary) {
                            if (rowInfo.original.include) {
                                return {
                                    style: {
                                        background: '#f5f5f5',
                                    },
                                }
                            } else {
                                return {
                                    style: {
                                        //opacity: 0.7,
                                        background: '#ededed',
                                    },
                                }
                            }
                        } else {
                            return {
                                style: {}
                            }
                        }
                    }}
                    columns={[
                        {
                            Header: "",
                            accessor: "",
                            style: {
                                textAlign: 'center',
                            },
                            width: 30,
                            filterable: false,
                            Cell: row => (row.index + 1)
                        },
                        {
                            Header: i18n.t('quotes.quoteRequest.quoteItemitemsInfo'),
                            id: 'items_info',
                            accessor: "",
                            style: { fontSize: '12px' },
                            Cell: this.renderItemsFields
                        },
                        {
                            Header: i18n.t('quotes.quoteRequest.quoteItemComment'),
                            id: 'items_comment',
                            show: !editable,
                            style: { fontSize: '12px' },
                            accessor: row => (row.comments && (
                                <Typography color='textSecondary' variant='body2' style={vendorQuoteStatusId === 2 ? {} : { whiteSpace: 'pre-line' }} noWrap={vendorQuoteStatusId === 2 ? true : false}>
                                    {row.comments}
                                </Typography>
                            ))
                        },
                        {
                            Header: i18n.t("quotes.vendorQR.table.column3"),
                            id: 'vendorDelivery',
                            className: classes.quote__items__table__cell__right,
                            width: 100,
                            show: (isVendorPO ? false : (vendorQuoteStatusId >= 2 && vendorQuoteSummary) || vendorQuoteSummary === false ? true : false),
                            accessor: row => (row.deliveryDays && (
                                <div style={{ fontSize: '16px' }}>
                                    {row.deliveryDays}
                                </div>
                            ))
                        },
                        {
                            Header: i18n.t("quotes.vendorQR.table.received"),
                            id: 'received',
                            className: classes.quote__items__table__cell__right,
                            width: 100,
                            style: { justifyContent: 'center', display: 'flex' },
                            show: isVendorPO ? true : false,
                            accessor: row => (
                                <div style={{ fontSize: '16px' }}>
                                    {row.receivedQuantity || 0}
                                </div>
                            )
                        },
                        {
                            Header: i18n.t("quotes.vendorQR.table.column4"),
                            id: 'vendorUnitAmount',
                            className: classes.quote__items__table__cell__right,
                            width: 100,
                            show: ((vendorQuoteStatusId >= 2 && vendorQuoteSummary) || vendorQuoteSummary === false ? true : false),
                            accessor: row => (
                                <div>
                                    <NumberFormat
                                        value={row.unitAmount || 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        decimalScale={2}
                                        style={{ fontSize: '16px' }}
                                        fixedDecimalScale={true} />
                                </div>
                            )
                        },
                        {
                            Header: i18n.t("quotes.vendorQR.table.column5"),
                            id: 'total',
                            className: classes.quote__items__table__cell__right,
                            width: 100,
                            show: ((vendorQuoteStatusId >= 2 && vendorQuoteSummary) || vendorQuoteSummary === false ? true : false),
                            accessor: row => (
                                <div>
                                    <NumberFormat
                                        value={(row.unitAmount || 0) * row.quantity}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        decimalScale={2}
                                        style={{ fontSize: '16px' }}
                                        fixedDecimalScale={true} />
                                </div>
                            ),
                            Footer: () => {
                                let vendorQuoteTotalAmount = vendorQuoteItems.reduce(((total, row) => {
                                    return (parseFloat(total) + (row.unitAmount * row.quantity))
                                }), 0)

                                if (vendorQuoteTotalAmount === 0) return null;

                                return (
                                    <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                        <NumberFormat value={vendorQuoteTotalAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            prefix={currencySymbol}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: '',
                            width: 100,
                            show: (vendorQuoteStatusId <= 2 && vendorQuoteSummary ? true : false),
                            Cell: row => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                    <Link className={classes.customLink}
                                        variant='button'
                                        onClick={() => this.editItem(row.original)}>
                                        {i18n.t('quotes.vendorQR.editItem')}
                                    </Link>
                                    {!row.original.include && (
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color='textSecondary' className={classes.items__not__included__label}>{i18n.t('quotes.vendorQuoteRequest.itemNotInRfq')}</Typography>
                                        </Grid>
                                    )}
                                </div>
                            )
                        },
                        {
                            Header: i18n.t('quotes.vendorQuoteRequest.itemsTable.addToVendorQuote'),
                            width: 100,
                            show: (this.props.file.status >= 2 && vendorQuoteStatusId === 1 && !vendorQuoteSummary ? true : false),
                            className: classes.vendor__quote__items__table__cell__center,
                            Cell: row => (
                                <div>
                                    <Checkbox
                                        checked={vendorQuoteItems[row.index].include}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(e) => {
                                            const items = [...vendorQuoteItems]


                                            items[row.index].include = e.target.checked

                                            this.props.updateVendorQuoteItems(items)
                                        }}
                                    />
                                </div>
                            )
                        },
                        {
                            Header: i18n.t('quotes.vendorQuoteRequest.itemsTable.column3'),
                            show: (vendorQuoteStatusId > 1 && editable ? true : false),
                            width: 150,
                            id: 'items_info',
                            accessor: "",
                            Cell: this.renderOtherFields
                        }
                    ]}
                    pageSize={vendorQuoteItems.length}
                    className={classNames(classes.vendor__quote__items__table, ' -striped -highlight')}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filtrable={false}
                    resizable={false}
                    sortable={false}
                />

                <VendorQuoteEditItem
                    showEditItemDialog={showEditItemDialog}
                    selectedItem={selectedItem}
                    items={vendorQuoteItems}
                    onClose={this.onClose}
                    updateVendorQuoteItems={this.props.updateVendorQuoteItems}
                    vendorQuote={vendorQuote}
                    currencySymbol={currencySymbol}
                    vendorQuoteStatusId={vendorQuote && vendorQuote.status ? vendorQuote.status.id : 1}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    file: state.file
})


export default connect(mapStateToProps)(withStyles(styles)(VendorQuoteSelectItems))
