export const SET_CLIENTS = 'SET_CLIENTS'
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const SET_CLIENT = 'SET_CLIENT'
export const ADD_CLIENT_CONTACT = 'ADD_CLIENT_CONTACT'
export const UPDATE_CLIENT_CONTACT = 'UPDATE_CLIENT_CONTACT'
export const DELETE_CLIENT_CONTACT = 'DELETE_CLIENT_CONTACT'


export function setClients(clients) {
    return {
        type: SET_CLIENTS,
        clients
    }
}
export function addClient(client) {
    return {
        type: ADD_CLIENT,
        client
    }
}

export function updateClient(client) {
    return {
        type: UPDATE_CLIENT,
        client
    }
}

export function deleteClient(client) {
    return {
        type: DELETE_CLIENT,
        client
    }
}

export function setClient(client) {
    return {
        type: SET_CLIENT,
        client
    }
}

export function addClientContact(contact) {
    return {
        type: ADD_CLIENT_CONTACT,
        contact
    }
}

export function updateClientContact(contact) {
    return {
        type: UPDATE_CLIENT_CONTACT,
        contact
    }
}
export function deleteClientContact(contact) {
    return {
        type: DELETE_CLIENT_CONTACT,
        contact
    }
}