import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    Link,
    Select,
    InputAdornment
} from '@material-ui/core';
import { Inbox, OpenInNew, Search } from '@material-ui/icons'

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'

import { withStyles } from '@material-ui/core/styles'
import i18n from 'i18next';
import classNames from 'classnames'

import { TasksShortcut } from './common'

const styles = theme => {
    return ({
        customLink: theme.customLink,
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileId: {
            fontWeight: 'bold',
        },
        list__fileDate: {

        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        list__fileName: {

        },
        list__fileReference: {

        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        rfq__noRows: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        rfq__warning__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        rfq__noItems: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.orange[80],
            borderRadius: theme.spacing() / 2,
            width: '80px',
            padding: '0 5px'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        oportunity__probability: {
            border: `solid thin ${theme.palette.secondary.main}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '8pt',
            color: theme.palette.text.secondary,
            padding: theme.spacing() / 2,
            textAlign: 'center',
            width: '80px'
        },
        'oportunity__probability--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        list__fileDetails: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    })
};


const probabilities = ['unknow', 'likely', 'verylikely'];


class RequestedByClient extends Component {

    state = {
        files: this.props.files,
        newRow: null,
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.files.length < nextProps.files.length) {
            this.setState({
                newRow: nextProps.files[0].id
            })
        }
        this.setState({
            files: nextProps.files
        })
    }


    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: event.target.value
        });
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    render = () => {
        const { classes, setSearchParmeters } = this.props,
            {
                files,
                newRow } = this.state;


        let countries = files.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i);
        return (
            <>
                {files && files.length > 0 && (
                    <div>
                        <Hidden xsDown implementation="css">
                            <ReactTable
                                data={files}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            background: (rowInfo ? (newRow === rowInfo.original.id ? '#ffffb3' : null) : null)
                                        }
                                    }
                                }}
                                columns={[

                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.dateFile"),
                                        id: 'file',
                                        width: 200,
                                        filterable: true,
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            minHeight: '80px'
                                        },
                                        accessor: row => (
                                            <div className={classes.rfq__table__fileDescriptions}>
                                                <Link
                                                    variant="body2"
                                                    className={classes.rfq__table__fileLink}
                                                    onClick={() => {
                                                        this.setState({
                                                            newRow: null
                                                        })
                                                        this.props.handleShowQuote(row.id)
                                                    }}
                                                    underline={'hover'}
                                                >
                                                    <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                </Link>



                                                <div className={classes.rfq__table__fileDatesContainer}>
                                                    <Typography
                                                        variant='body2'
                                                        color='textSecondary'>
                                                        {this.quoteDateFormat(row.createDateTime)}
                                                    </Typography>
                                                </div>
                                                <div className={classes.list__file__lastUpdate__container}>
                                                    <Typography
                                                        className={moment().diff(moment(row.createDateTime), 'days') < 2 ?
                                                            classes.list__file__lastUpdate : (moment().diff(moment(row.createDateTime), 'days') < 4 ?
                                                                classes['list__file__lastUpdate--late'] :
                                                                classes['list__file__lastUpdate--veryLate'])} >
                                                        {moment(row.createDateTime).fromNow(true)}
                                                    </Typography>
                                                </div>


                                                <TasksShortcut
                                                    row={row}
                                                    handleShowQuote={this.props.handleShowQuote}
                                                />


                                            </div>),
                                        Filter: ({ onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    id='txtFile'
                                                    autoComplete='off'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Search style={{ fontSize: '12px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            setSearchParmeters({ quote: value });
                                            return `${row._original.id}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || `${this.quoteDateFormat(row._original.createDateTime)}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                        }
                                    },

                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.clientNameRef"),
                                        filterable: true,
                                        id: 'quoteClientName',
                                        accessor: row => (
                                            <div className={classes.list__fileDetails}>
                                                <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                <Typography className={classes.list__fileReference}>{`Ref: ${row.requestForQuote.reference}`}</Typography>
                                                {row.rfqItemsCount === 0 &&
                                                    <div className={classes.rfq__noItems}>
                                                        <Typography>
                                                            {i18n.t('quotes.requestedByClient.noClientQuotesItems')}
                                                        </Typography>
                                                    </div>
                                                }
                                            </div>
                                        ),
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    id='txtQuoteClientName'
                                                    autoComplete='off'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Search style={{ fontSize: '12px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            setSearchParmeters({ client: value });
                                            return (`${row._original.client.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.requestForQuote.reference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.requestForQuote.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.client.country.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.client.country.code}`.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.country"),
                                        filterable: true,
                                        id: 'country',
                                        width: 60,
                                        accessor: row => (
                                            <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                        ),
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <Select
                                                    disabled={!countries || (countries && countries.length <= 0)}
                                                    native
                                                    fullwidth="true"
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: '100%', minWidth: '55px' }}
                                                    classes={{
                                                        select: classes.country__select
                                                    }}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">All</option>
                                                    {countries && countries.map(country => (
                                                        <option key={country} value={country}>{country}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            return value.toLowerCase() === 'all' ? true : (row._original.client.country.code.toLowerCase() === value.toLowerCase())

                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.importance"),
                                        id: 'importance',
                                        width: 90,
                                        Cell: row => (

                                            row.original.oportunity.name ? (
                                                <div className={classNames(classes.oportunity__probability, classes[`oportunity__probability--${probabilities[row.original.probability.id - 1]}`])}>
                                                    {row.original.oportunity.name}
                                                </div>
                                            ) : null

                                        )
                                    },
                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.users"),
                                        filterable: true,
                                        id: 'user_Author',
                                        accessor: row => {
                                            let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                            return (
                                                <div>
                                                    <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                                    {row.salesRepresentative && row.salesRepresentative.name && (
                                                        <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                    )}


                                                    <TasksShortcut
                                                        row={row}
                                                        handleShowQuote={this.props.handleShowQuote}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <div>
                                                            {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                <div>
                                                                    <Divider />
                                                                    <div style={{ display: 'flex', marginTop: 5, justifyContent: 'flex-start' }}>
                                                                        <OpenInNew style={{ fontSize: 14 }} color='secondary' />
                                                                    </div>
                                                                    {row.tasks.filter(task =>
                                                                        !task.complete
                                                                    ).map(task => {
                                                                        if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                            usersListed.push(task.followUpUser.id);
                                                                            return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                        }
                                                                        return null
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </TasksShortcut>
                                                </div>)

                                        },
                                        width: 150,
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                        Filter: ({ onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    autoComplete='off'
                                                    id='txtUser_Author'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Search style={{ fontSize: '12px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            setSearchParmeters({ salesperson: value });

                                            return (`${row._original.salesPerson.userName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                (row._original.salesRepresentative ? `${row._original.salesRepresentative.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 : false) ||
                                                (row._original.tasks && row._original.tasks.length > 0 ? row._original.tasks.find(t => t.followUpUser.name.toLowerCase().indexOf(value.toLowerCase() >= 0)) : false)
                                            )
                                        }
                                    }
                                ]}
                                pageSize={files && files.length >= 50 ? 50 : files.length}
                                className={classNames(classes.table, ' -striped -highlight')}
                                showPaginationTop={files && files.length >= 50 ? true : false}
                                showPaginationBottom={files && files.length >= 50 ? true : false}
                                sortable={false}
                                resizable={false}
                            />
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <Paper>
                                <List>
                                    {files.map((row, i) => {
                                        let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                        return (
                                            <div key={`${row.id}-${i}`}>
                                                <ListItem key={row.id} >
                                                    <ListItemText>
                                                        <div className={classes.rfq__table__fileDescriptions}>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.rfq__table__fileLink}
                                                                underline={'hover'}
                                                                onClick={() => {
                                                                    this.props.handleShowQuote(row.id)
                                                                }}
                                                            >
                                                                <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                            </Link>
                                                            <div className={classes.rfq__table__fileDatesContainer}>
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary'>
                                                                    {this.quoteDateFormat(row.createDateTime)}
                                                                </Typography>
                                                                <Typography className={moment().diff(moment(row.createDateTime), 'days') < 2 ?
                                                                    classes.list__file__lastUpdate : (moment().diff(moment(row.createDateTime), 'days') < 4 ?
                                                                        classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                    {moment(row.createDateTime).fromNow(true)}
                                                                </Typography>
                                                            </div>
                                                            <TasksShortcut row={row}
                                                                handleShowQuote={this.props.handleShowQuote} />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                            <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                            <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                            {row.oportunity.name ? (
                                                                <div className={classNames(classes.oportunity__probability, classes[`oportunity__probability--${probabilities[row.probability.id - 1]}`])}>
                                                                    {row.oportunity.name}
                                                                </div>
                                                            ) : null}

                                                            <div>
                                                                <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>
                                                                {row.salesRepresentative && row.salesRepresentative.name && (
                                                                    <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                )}
                                                                <TasksShortcut
                                                                    row={row}
                                                                    handleShowQuote={this.props.handleShowQuote}
                                                                >
                                                                    <div>
                                                                        {row.tasks && row.tasks.length > 0 &&
                                                                            row.tasks
                                                                                .filter(task =>
                                                                                    !task.complete)
                                                                                .map(task => {
                                                                                    if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                        usersListed.push(task.followUpUser.id);
                                                                                        return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                    }
                                                                                    return null
                                                                                })}
                                                                    </div>
                                                                </TasksShortcut>
                                                            </div>
                                                            <div className={classes.list__countryContainer}>
                                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                            </div>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {i < (files.length - 1) && (
                                                    <Divider />
                                                )}
                                            </div>
                                        )
                                    })
                                    }

                                </List>
                            </Paper>
                        </Hidden>
                    </div>
                )}
                {files && files.length === 0 && (
                    <div className={classes.rfq__noRows}>
                        {this.props.people.filterPeople && this.props.people.filterPeople.length === 0 ? (
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('quotes.requestedByClient.noQuotes')}</Typography>
                            </div>
                        ) : (
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 50 }} color='secondary' />
                                    <Typography variant='body2'>
                                        {this.props.people.filterPeople.map((person, i) => (
                                            <span key={i}>{`${person.userName}${this.props.people.filterPeople.length > 1 && (this.props.people.filterPeople.length > i + 1) ? ', ' : ' '}`} </span>
                                        ))}
                                        <br />
                                        {this.props.people.filterPeople.length === 1 ? i18n.t('peopleFilter.hasNot') : i18n.t('peopleFilter.haveNot')}
                                        {i18n.t('peopleFilter.pendingRFQ')}
                                    </Typography>
                                    <Typography variant='body2'>
                                        <Link className={classes.customLink}
                                            onClick={() => {
                                                this.props.generalActions.showPeopleFilter(true)
                                            }}>
                                            {i18n.t('peopleFilter.changeUsersFilter')}
                                        </Link>
                                    </Typography>
                                </div>
                            )}
                    </div>
                )}
            </>
        );

    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        files: state.files,
        people: state.people,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestedByClient));