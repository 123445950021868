import store from '../store'
import API from '../lib/api';
import auth from '../authentication/auth';


let accountPermissions = {
    routes: {
        dashboard: {
            allowedUserTypes: [1, 2, 3],
            allowedUserRoles: []
        },
        visits: {
            allowedUserTypes: [1, 2, 3],
        },
        quotes: {
            allowedUserTypes: [1, 2, 3],
        },
        search: {
            allowedUserTypes: [1, 2, 3],
        },
        purchaseOrders: {
            allowedUserTypes: [1, 3],
        },
        invoicing: {
            allowedUserTypes: [1, 3],
        },
        warehouse: {
            allowedUserTypes: [1, 3],
        },
        accounting: {
            allowedUserTypes: [1, 3],
        },
        reports: {
            allowedUserTypes: [1, 2, 3],
        },
        emails: {
            allowedUserTypes: [1, 3],
        },
        options: {
            allowedUserTypes: [1, 3],
        },
        companies: {
            allowedUserTypes: [1, 3],
        },
        users: {
            allowedUserTypes: [1, 3],
        },
        others: {
            allowedUserTypes: [1, 3],
        },
    },
    menu: {
        dashboard: {
            allowedUserTypes: [1, 2, 3],
        },
        visits: {
            allowedUserTypes: [1, 2, 3],
        },
        quotes: {
            allowedUserTypes: [1, 2, 3],
        },
        purchaseOrders: {
            allowedUserTypes: [1, 3],
        },
        invoicing: {
            allowedUserTypes: [1, 3],
        },
        warehouse: {
            allowedUserTypes: [1, 3],
        },
        accounting: {
            allowedUserTypes: [1, 3],
        },
        reports: {
            allowedUserTypes: [1, 2, 3],
        },
        emails: {
            allowedUserTypes: [1, 3], 
        },
        options: {
            allowedUserTypes: [1, 3],
        }
    },
}

const dafaultUserTypePage = {
    Admin: '/dashboad',
    Agent: '/visits',
    SuperAdmin: '/dashboard'
}


const Permissions = {
    getDefaultPage: (route) => {
        let state = store.getState(),
            account = state.account,
            routeParts = route && route.split('/'),
            baseRoute = routeParts[0] !== '' ? routeParts[0] : routeParts[1],
            page = account && account.userType ? dafaultUserTypePage[account.userType.name] : null;

        if(baseRoute === 'quotes' && parseInt(account.userTypeId) === 2){
            let secondRoute = routeParts[0] !== '' ? routeParts[1] : routeParts[2]

            if(secondRoute && secondRoute === 'vrfq'){
                return '/quotes/rfq'
            }
        }

        if(baseRoute === 'invoicing' || baseRoute === 'purchaseOrders'){
            return '/visits'
        }

        return page ? page : '/visits'
    },
    checkRoutePermission: async (route) => {
        let routeParts = route.split('/'),
            baseRoute = routeParts[0] !== '' ? routeParts[0] : routeParts[1];

        const account = await Permissions.checkUser()

        if(baseRoute === 'quotes' && parseInt(account.userTypeId) === 2){
            let secondRoute = routeParts[0] !== '' ? routeParts[1] : routeParts[2]

            if(secondRoute && secondRoute === 'vrfq'){
                return false
            }
        }

        if(baseRoute === 'invoicing' || baseRoute === 'purchaseOrders'){
            return false
        }

        if (account &&
            account.userType &&
            accountPermissions.routes[baseRoute] &&
            accountPermissions.routes[baseRoute].allowedUserTypes &&
            accountPermissions.routes[baseRoute].allowedUserTypes.indexOf(account.userType.id) >= 0) {
            return true
        } else {
            return false
        }
    },
    checkMenuPermission: (menu) => {
        let state = store.getState(),
            account = state.account;
        if (account &&
            account.userType &&
            accountPermissions.menu[menu] &&
            accountPermissions.menu[menu].allowedUserTypes &&
            accountPermissions.menu[menu].allowedUserTypes.indexOf(account.userType.id) >= 0) {
            return true
        } else {
            return false
        }
    },
    checkSensitiveInformationPermission: () => {
        let state = store.getState(),
            account = state.account;
        //Si el usuario es admin puede ver informacion sensible. El resto de los usuarios no pueden
        //Luego se puede cambiar esta funcion para hacerla mas especializada a unos permisos basados en
        //Roles
        if (account &&
            account.userType &&
            (account.userType.id === 1 || account.userType.id === 3)) {
            return true;
        } else {
            return false;
        }
    },
    checkUser: async ()=>{
        let state = store.getState(),
            account = state.account;

        if (!account.userId) {
            const userAuth = await auth.getAuthenticatedUser()
            const userAccountResponse = await API.Users.getUser({
                userId: userAuth.attributes['custom:userID']
            })

            if (userAccountResponse.status === 'success' && userAccountResponse.data.records) {
                store.dispatch({
                    type: 'SET_ACCOUNT',
                    account: { ...userAccountResponse.data.records }
                })
                return { ...userAccountResponse.data.records }
            }
            return {}
        } else {
            return account
        }
    }
}

export default Permissions