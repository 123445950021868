import auth from './authentication/auth';
import React, { Component } from 'react'
import i18n from 'i18next';
import "./i18n";
import API from './lib/api';
import Permissions from './lib/permissions'

// REDUX
import store from './store'

class WithUserComponent extends Component {
    state = {
        showComponent: false,
        loggedIn: false
    }
    componentWillUpdate = () => {
        let state = store.getState(),
            account = state.account;
        if (!account.userId) {
            auth.getAuthenticatedUser().then(user => {
                API.Users.getUser({
                    userId: user.attributes['custom:userID']
                }).then(dbUserResponse => {
                    if (dbUserResponse.status === 'success' && dbUserResponse.data.records) {
                        store.dispatch({
                            type: 'SET_ACCOUNT',
                            account: { ...dbUserResponse.data.records }
                        })
                        if (!Permissions.checkSensitiveInformationPermission()) {
                            store.dispatch({
                                type: 'SET_FILTER_PEOPLE',
                                filter: [{ ...dbUserResponse.data.records }]
                            })
                        }
                    }
                })
            });
        }
    }
    componentDidMount = () => {
        i18n.changeLanguage('en-US', (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            auth.getAuthenticatedUser().then(user => {
                API.Users.getUser({
                    userId: user.attributes['custom:userID']
                }).then(dbUserResponse => {
                    if (dbUserResponse.status === 'success' && dbUserResponse.data.records) {
                        store.dispatch({
                            type: 'SET_ACCOUNT',
                            account: { ...dbUserResponse.data.records }
                        })
                        if (!Permissions.checkSensitiveInformationPermission()) {
                            store.dispatch({
                                type: 'SET_FILTER_PEOPLE',
                                filter: [{ ...dbUserResponse.data.records }]
                            })
                        }
                    }
                    this.setState({
                        showComponent: true,
                        loggedIn: true
                    })
                })
            }).catch(_ => {
                this.setState({
                    showComponent: true,
                    loggedIn: false
                })
            });
        });
    }
    render() {
        const {
            showComponent,
            loggedIn
        } = this.state;
        return showComponent ? React.cloneElement(this.props.children, { loggedIn }) : null
    }
}



//This functions guarantees the auth token is updated in session storage for current authenticated user
function withUser(WrappedComponent) {
    return <WithUserComponent>{WrappedComponent}</WithUserComponent>;
}

export default withUser;