import React, { Component } from 'react'
import moment from "moment";
import {
    CircularProgress,
    Typography,
    withStyles,
    Tabs,
    Tab,
    Hidden,
    Card, Paper,
    CardHeader,
    CardContent,
    withMobileDialog
} from '@material-ui/core/';
import { Inbox, 
        Room,
        Today, 
        DoneOutline, 
        NotInterested } from '@material-ui/icons'
import ReactTable from "react-table";
import i18n from 'i18next';
import classNames from 'classnames'
import { EventDetails } from '.'


const styles = theme => ({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        textAlign: 'left',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    activeSelect: {
        fontFamily: theme.typography.fontFamily,
        marginTop: '6px'
    },
    circularProgress: {
        marginTop: '20%'
    },
    done_icon: {
        color: 'green',
        fontSize: '20px',
    },
    scheduled_icon: {
        color: 'blue',
        fontSize: '20px',
    },
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(4)
    },
    divider: {
        border: `1px solid ${theme.palette.primary.secondary}`
    },
    event__status__icon__scheduled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    event__status__icon__canceled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        },
    },
    event__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.green[100],
    },
    card: {
        maxWidth: 400,
        textAlign: 'left',
        marginTop: theme.spacing(2)
    },
    card__content: {
        padding: '0 16px 16px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '16px'
    },
    event__tab__eventCancelled: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        marginLeft: 'auto',
        width: 70
    },
    event__tab__event: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        width: 130
    },
    event__paper__section: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: '1px 0 0'
        },
    },
    visits_tabs:{
        zIndex: 4
    },
    visits__statusTabs__activeIndicator: {
        backgroundColor: 'transparent'
    },
    'visits__tabs--selected': {
        backgroundColor: '#fff',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: 'none',
        color: theme.palette.text.primary,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        zIndex: 2
    },
});

class EventsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: props.loading,
            events: props.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id),
            originalEvents: props.events,
            pageSize: props.events ? props.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id).length : 0,
            selectedEvent: null,
            openEventDetails: false,
            selectedUsersIds: props.selectedUsersIds || [],
            currentTab: 0,
            scheduledCount: 0,
            doneCount: 0,
            canceledCount: 0
        }
    }


    componentWillReceiveProps(nextProps) {
        const { currentTab } = this.state;

        let scheduledCount = nextProps.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id).length,
            doneCount = nextProps.events.filter(event => event.visit.status.id === 2).length,
            canceledCount = nextProps.events.filter(event => event.visit.status.id === 3).length

        this.setState({
            loading: nextProps.loading,
            events: nextProps.events.filter(event => currentTab === 0 ? !event.visit.status.id || event.visit.status.id === currentTab + 1 : event.visit.status.id === currentTab + 1),
            originalEvents: nextProps.events,
            scheduledCount,
            doneCount,
            canceledCount,
            pageSize : nextProps
                .events
                .filter(event => currentTab === 0
                    ? !event.visit.status.id || event.visit.status.id === currentTab + 1
                    : event.visit.status.id === currentTab + 1)
                .length,
            selectedUsersIds: nextProps.selectedUsersIds || []
        })
    }

    openEventDetailsModal=(event)=>{
        this.setState({
            selectedEvent: event,
            openEventDetails: true
        })
    }

    updateEvent=(event)=>{
        this.setState({
            selectedEvent: event
        })
        this.props.updateEvent(event)
    }

    changeTab = (e, value) => {
        const { originalEvents } = this.state;

        this.setState({
            events: originalEvents.filter(event => value === 0 ? !event.visit.status.id || event.visit.status.id === value + 1 : event.visit.status.id === value + 1),
            pageSize: originalEvents.filter(event => value === 0 ? !event.visit.status.id || event.visit.status.id === value + 1 : event.visit.status.id === value + 1).length,
            currentTab: value
        })
    }

    render() {
        const { classes, width } = this.props;
        const { loading, events, pageSize,
                openEventDetails, selectedEvent,currentTab } = this.state

        return (
            <div>
                {loading &&
                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                }
                {events && !loading &&
                    <div>
                        <Tabs
                            value={currentTab}
                            onChange={this.changeTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            className={classes.visits_tabs}
                            classes={{
                                indicator: classes.visits__statusTabs__activeIndicator
                            }}
                        >

                            <Tab
                                key={0}
                                label={
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Today className={classes.event__status__icon__scheduled} />
                                        <Typography color='textSecondary'>{`${i18n.t('visits.status.scheduled')}`}</Typography>
                                    </div>
                                }
                                classes={{
                                    selected: classes['visits__tabs--selected'],
                                    root: classes.event__tab__event,
                                }}
                            />

                            <Tab
                                key={1}
                                label={
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <DoneOutline className={classes.event__status__icon__ready} />
                                        <Typography color='textSecondary'>{`${i18n.t('visits.status.done')}`}</Typography>
                                    </div>
                                }
                                classes={{
                                    selected: classes['visits__tabs--selected'],
                                    root: classes.event__tab__event,
                                }}
                            />

                            <Tab 
                                key={2}
                                label={
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <NotInterested className={classes.event__status__icon__canceled} />
                                        {width !== 'xs' &&
                                            <Typography color='textSecondary'>{`${i18n.t('visits.status.canceled')}`}</Typography>                                        
                                        }
                                    </div>
                                }
                                classes={{
                                    selected: classes['visits__tabs--selected'],
                                    root: classes.event__tab__eventCancelled,
                                }}
                            />
                        </Tabs>
                        
                        <Paper className={classes.event__paper__section}>
                            { events && events.length > 0 ? <>
                                <Hidden xsDown implementation="css">
                                    <ReactTable
                                        data={events}
                                        getTdProps={(state, rowInfo, column, instance) => {
                                            return {
                                                style: {
                                                    cursor: 'pointer'
                                                },
                                                onClick: (e, handleOriginal) => {
                                                    if (rowInfo) {
                                                        this.openEventDetailsModal(rowInfo.original);
                                                        if (handleOriginal) {
                                                            handleOriginal()
                                                        }
                                                    }
                                                }
                                            }
                                        }}

                                        columns={[
                                            {
                                                Header: i18n.t("visits.table.date"),
                                                id: 'date',
                                                accessor: row => {
                                                    return (
                                                        <div>
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {moment.utc(row.from).format('ddd, MMM Do')}
                                                            </Typography>
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {moment.utc(row.from).format('hh:mm a')}
                                                            </Typography>
                                                        </div>
                                                    )
                                                },
                                                maxWidth: 170,
                                                filterable: false,
                                                sortable: false,
                                                resizable:false,
                                            },
                                            {
                                                Header: i18n.t("visits.table.client"),
                                                id: 'client',
                                                accessor: row => {
                                                    return (
                                                        <div>
                                                            <Typography variant='body1' color='textPrimary' noWrap style={{fontWeight: 'bold'}}>
                                                                {row.isVisit ? row.client.name : ''}
                                                            </Typography>
                                                            <Typography variant='body2' color='textSecondary'>{row.isVisit ? row.visit.type : ''}</Typography>
                                                        </div>
                                                    )
                                                },
                                                minWidth: 100,
                                                filterable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: i18n.t("visits.table.event"),
                                                id: 'event',
                                                accessor: row => {
                                                    return (
                                                        <div>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                { row.isVisit === 1 && 
                                                                    <Room color='secondary' style={{fontSize: '16px', marginRight: '4px'}}/>
                                                                }
                                                                <Typography variant='body1' color='textPrimary' noWrap>
                                                                    {row.title}
                                                                </Typography>
                                                            </div>
                                                            <Typography variant='body2' color='textSecondary'>
                                                                <strong>{row.isVisit && row.checkIn.address ? `${i18n.t("visits.table.checkIn")}  ` : ''}</strong>
                                                                {row.isVisit && row.checkIn.address ? moment(row.checkIn.dateTime).format('hh:mm a') : ''}
                                                            </Typography>
                                                        </div>
                                                    )
                                                },
                                                sortable: false,
                                                filterable: false,
                                                minWidth: 110
                                            },
                                            {
                                                Header: i18n.t("visits.table.user"),
                                                id: 'user',
                                                style:{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                },
                                                accessor: row => <Typography component='span'>{row.user.name}</Typography>,
                                                sortable: false,
                                                filterable: false,
                                                resizable: false,
                                                minWidth: 50
                                            }
                                        ]}
                                        minRows={0}
                                        defaultPageSize={events.length}
                                        pageSize={pageSize}
                                        filterable={false}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize })
                                        }
                                        className={classNames(classes.table, ' -striped -highlight')}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                    />
                                </Hidden>
                                <Hidden smUp implementation="css">
                                    {events.map((row, i) => (
                                        <Card key={i} className={classes.card} onClick={()=>this.openEventDetailsModal(row)}>
                                            <CardHeader
                                                title={<>
                                                    {row.isVisit === 1 && row.client.id ?
                                                        <Typography variant='h6' color='textPrimary' style={{fontSize: '15px'}}>
                                                            {`${row.client.id ? `${row.client.name} `: ''} ${row.clientContact.id ? ` - ${row.clientContact.firstName || ''} ${row.clientContact.lastName || ''}` : ''}`}
                                                        </Typography>
                                                    :
                                                        <Typography variant='h6' color='textPrimary' style={{fontSize: '15px'}}>
                                                            {row.title}
                                                        </Typography>
                                                    }
                                                </>}
                                                subheader={moment(row.from).format('MMM Do hh:mm a')}
                                            />
                                            <CardContent className={classes.card__content} style={{paddingBottom: '16px' }}>
                                                <Typography variant='body2' color='textPrimary'>
                                                    {row.user.name}
                                                </Typography>
                                                
                                                {row.isVisit === 1 ?
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Room style={{fontSize: '20px', marginRight: '4px'}}/>
                                                        <Typography variant='body2' color='textSecondary'>
                                                            {row.title}
                                                        </Typography>
                                                    </div>
                                                :
                                                    <Typography variant='body2' color='textSecondary'>
                                                        {row.description}
                                                    </Typography>
                                                }
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Hidden>
                                <div style={{display: 'flex', margin: '16px 0'}}>
                                    <Room color='secondary' style={{fontSize: '16px', marginRight: '4px'}}/>
                                    <Typography variant='body2' color='textSecondary'>
                                        {i18n.t('visits.form.isVisit')}
                                    </Typography>
                                </div>
                            </>:
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 50 }} color='secondary' />
                                    <Typography variant='body2'>
                                        {currentTab === 0 ? i18n.t('visits.noEventsScheduled') : currentTab === 1 ? i18n.t('visits.noEventsDone') : i18n.t('visits.noEventsCanceled')}
                                    </Typography>
                                </div>
                            }
                        </Paper>
                    </div>
                }


                <EventDetails
                    logedInUser={this.props.logedInUser}
                    event={selectedEvent}
                    open={openEventDetails}
                    updateEvent={this.updateEvent}
                    deleteEvent={this.props.deleteEvent}
                    handleClose={()=>{
                        this.setState({
                            selectedEvent: null,
                            openEventDetails: false
                        })
                    }}
                />
            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(EventsList));
