import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    Link,
    withStyles,
    Select,
    InputAdornment,
} from '@material-ui/core';
import {
    Search
} from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'


const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        divider: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.primary.light
        },
        poList__vendorPOs__cell: {
            display: 'flex',
            flexDirection: 'column'
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        list__fileDetails: { position: 'relative' },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        }
    })
};


class PurchaseOrders extends Component {
    state = {
        files: this.props.files
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            files: nextProps.files
        })
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }


    render = () => {
        const { classes } = this.props,
            { files } = this.state;
        
        if(!files) return null

        let countries = files.map(r => r.countryCode).filter((x, i, a) => a.indexOf(x) === i);

        const options = [
            {
                label: i18n.t('quotes.pos.vendorPOs.preparing'),
                value: 1
            },
            {
                label: i18n.t('quotes.pos.vendorPOs.requested'),
                value: 2
            },
            {
                label: i18n.t('quotes.pos.vendorPOs.ready'),
                value: 3
            },
        ]

        return (
            <div>
                <Hidden xsDown implementation="css">
                    <ReactTable
                        data={files}
                        columns={[
                            {
                                Header: i18n.t("quotes.pos.vendorPOs.table.column1"),
                                filterable: true,
                                id: 'vendor',
                                width: 300,
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                },
                                accessor: row => {
                                    return (
                                        <div className={classes.rfq__table__fileDescriptions}>
                                            <div className={classes.poList__vendorPOs__cell}>
                                                <Link
                                                    variant="body2"
                                                    className={classes.rfq__table__fileLink}
                                                    onClick={() => this.props.handleShowQuote(row)}
                                                >
                                                    <Typography className={classes.rfq__table__fileLink} style={{ width: '90%' }} noWrap><strong>{row.vendor.name}</strong></Typography>
                                                </Link>
                                                <Typography color='textSecondary'>
                                                    {i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}
                                                </Typography>
                                                <Typography color='textSecondary'>
                                                    {row.status.id === 1 &&
                                                        `${i18n.t("quotes.pos.vendorPOs.created")} ${this.quoteDateFormat(row.vendorPODate)}`
                                                    }

                                                    {(row.status.id === 2 || row.status.id === 4) &&
                                                        `${i18n.t("quotes.pos.vendorPOs.requested")} ${this.quoteDateFormat(row.vendorPOSendToVendorDate)}`
                                                    }

                                                    {row.status.id === 3 &&
                                                        `${i18n.t("quotes.pos.vendorPOs.received")} ${this.quoteDateFormat(row.vendorPOReceiveDate)}`
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={classes.list__file__lastUpdate__container}>
                                                <Typography className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                    {moment(row.lastUpdateDateTime).fromNow(true)}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                },
                                Filter: ({ onChange }) => (
                                    <div className={classes.flexCell}>
                                        <TextField
                                            id='txtUser_Author'
                                            autoComplete='off'
                                            style={{ flex: 1 }}
                                            onChange={(e) => onChange(e.target.value)}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search style={{ fontSize: '12px' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                ),
                                filterMethod: (filter, row) => {
                                    return `${row._original.id}`.indexOf(filter.value) >= 0 ||
                                        row._original.vendor.name.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 || `${row._original.vendorPO.status.id === 1 ? this.quoteDateFormat(row._original.vendorPODate)
                                            : (row._original.vendorPO.status.id === 2 || row._original.vendorPO.status.id === 4) ? this.quoteDateFormat(row._original.vendorPOSendToVendorDate)
                                                : this.quoteDateFormat(row._original.vendorPOReceiveDate)}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 ||
                                        `${options.find(v => v.value === row._original.vendorPO.status.id) && options.find(v => v.value === row._original.vendorPO.status.id).label}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                }
                            },
                            {
                                Header: i18n.t("quotes.quoted.table.column2"),
                                filterable: true,
                                id: 'quoteClientName',
                                accessor: row => (
                                    <div className={classes.list__fileDetails}>
                                        <Typography className={classes.list__fileClient} noWrap>{row.clientName}</Typography>
                                        <Typography className={classes.list__fileName}>{`${row.rfqName}`}</Typography>
                                        <Typography className={classes.list__fileReference}>{`PO# ${row.poNumber}`}</Typography>
                                    </div>
                                ),
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                },
                                Filter: ({ onChange }) => (
                                    <div className={classes.flexCell}>
                                        <TextField
                                            id='txtQuoteClientName'
                                            autoComplete='off'
                                            style={{ flex: 1 }}
                                            onChange={(e) => onChange(e.target.value)}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search style={{ fontSize: '12px' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                ),
                                filterMethod: (filter, row) => {
                                    let { value } = filter;
                                    return (`${row._original.clientName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                        `${row._original.poNumber}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                        `${row._original.rfqName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                                }
                            },
                            {
                                Header: i18n.t("quotes.requestedByClient.table.country"),
                                filterable: true,
                                id: 'country',
                                width: 60,
                                accessor: row => (
                                    <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.countryCode}</Typography>
                                ),
                                Filter: ({ filter, onChange }) => (
                                    <div className={classes.flexCell}>
                                        <Select
                                            disabled={!countries || (countries && countries.length <= 0)}
                                            native
                                            fullwidth="true"
                                            onChange={event => onChange(event.target.value)}
                                            style={{ width: '100%', minWidth: '55px' }}
                                            classes={{
                                                select: classes.country__select
                                            }}
                                            value={filter ? filter.value : "all"}
                                        >
                                            <option value="all">All</option>
                                            {countries && countries.map(country => (
                                                <option key={country} value={country}>{country}</option>
                                            ))}
                                        </Select>
                                    </div>
                                ),
                                filterMethod: (filter, row) => {
                                    let { value } = filter;
                                    return value.toLowerCase() === 'all' ? true : (row._original.countryCode.toLowerCase() === value.toLowerCase())

                                }
                            },
                            {
                                Header: i18n.t("quotes.pos.vendorPOs.table.column3"),
                                filterable: true,
                                id: 'user_Author',
                                accessor: (row) => {
                                    return (
                                        <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>
                                    )
                                },
                                width: 150,
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                },
                                Filter: ({ onChange }) => (
                                    <div className={classes.flexCell}>
                                        <TextField
                                            id='txtUser_Author'
                                            autoComplete='off'
                                            style={{ flex: 1 }}
                                            onChange={(e) => onChange(e.target.value)}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search style={{ fontSize: '12px' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                ),
                                filterMethod: (filter, row) => {
                                    let { value } = filter;
                                    return `${row._original.salesPerson.userName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                }
                            },
                        ]}
                        minRows={0}
                        pageSize={files.length}
                        className={classNames(classes.table, ' -striped -highlight')}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        sortable={false}
                        resizable={false}
                    />
                </Hidden>
                <Hidden smUp implementation="css">
                    <Paper>
                        <List>
                            {files.map((row, i) => (
                                <div key={row.id}>
                                    <ListItem key={row.id} >
                                        <ListItemText>
                                            <div className={classes.poList__vendorPOs__cell}>
                                                <Link
                                                    variant="body2"
                                                    className={classes.rfq__table__fileLink}
                                                    onClick={() => this.props.handleShowQuote(row)}
                                                >
                                                    <Typography className={classes.rfq__table__fileLink} style={{ width: '90%' }} noWrap><strong>{row.vendor.name}</strong></Typography>
                                                </Link>
                                                <Typography color='textSecondary'>
                                                    {i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}
                                                </Typography>
                                                <Typography color='textSecondary'>
                                                    {row.status.id === 1 &&
                                                        `${i18n.t("quotes.pos.vendorPOs.created")} ${this.quoteDateFormat(row.vendorPODate)}`
                                                    }

                                                    {(row.status.id === 2 || row.status.id === 4) &&
                                                        `${i18n.t("quotes.pos.vendorPOs.requested")} ${this.quoteDateFormat(row.vendorPOSendToVendorDate)}`
                                                    }

                                                    {row.status.id === 3 &&
                                                        `${i18n.t("quotes.pos.vendorPOs.received")} ${this.quoteDateFormat(row.vendorPOReceiveDate)}`
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={classes.list__fileDetails}>
                                                <Typography className={classes.list__fileClient} noWrap>{row.clientName}</Typography>
                                                <Typography className={classes.list__fileName}>{`${row.rfqName}`}</Typography>
                                                <Typography className={classes.list__fileReference}>{`PO# ${row.poNumber}`}</Typography>
                                            </div>

                                            <div>
                                                <Typography>
                                                    {this.quoteDateFormat(row.vendorPODate)}
                                                </Typography>
                                                <Typography>
                                                    + {row.vendorQuoteDeliveryDays}
                                                </Typography>
                                            </div>

                                            <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                        </ListItemText>
                                    </ListItem>
                                    {i < (files.length - 1) && (
                                        <Divider />
                                    )}
                                </div>
                            ))}

                        </List>
                    </Paper>
                </Hidden>
            </div>
        );
    }
}


export default withStyles(styles)(PurchaseOrders)