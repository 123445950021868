import React, { Component } from 'react'
import {
    Typography,
    Grid,
    withMobileDialog,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Link,
    Divider,
} from '@material-ui/core'
import i18n from 'i18next'
import { Phone } from '@material-ui/icons'
import CustomSelect from '../general/CustomSelect'

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as clientsActions from '../../actions/clientsActions'

import API from '../../lib/api';

const styles = theme => {
    return {
        dialog_content_container: {
            padding: theme.spacing(2)
        },
        buttonSpinner: theme.buttonSpinner,
        "client__editSections__button--disabled": {
            backgroundColor: theme.palette.textPrimary.main
        },
        client__editSections__saveButton: {
        },
        success: {
            backgroundColor: theme.palette.green[100],
        },
        error: {
            backgroundColor: theme.palette.primary.main,
        },
        client__form__bottomButtons__grid: {
            marginTop: theme.spacing()
        },
        customLink: theme.customLink,
        client__action__button__container: {
            textAlign: 'center'
        },
        client__actions__container: {
            marginTop: theme.spacing(2)
        },
        client__actions__divider: {
            marginTop: theme.spacing()
        },
        cancelDelete__buttons__container: {
            display: 'flex'
        },
        client__deleteClientButton: {
            marginRight: theme.spacing(),
            borderColor: theme.palette.red[80],
            '&:hover': {
                backgroundColor: theme.palette.red[60],
                color: theme.palette.getContrastText(theme.palette.red[100])
            },
            marginLeft: theme.spacing(),
            color: theme.palette.red[80],
            [theme.breakpoints.down('sm')]: {
                marginLeft: 'auto',
            }
        },
        rfq__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-3px'
        },
        client__form__textField: {
            margin: `${theme.spacing()}px 0`,
        },
    }
}

const defaultClient = {
    country: {
        id: 'None'
    },
    name: '',
    taxId: '',
    address: {
        address1: '',
        address2: ''
    },
    phone: '',
    fax: '',
    id: null
}

class ManageClients extends Component {
    state = {
        createClient: false,
        editClient: false,
        countryFilter: this.props.selectedClient && this.props.selectedClient.country.id ? this.props.selectedClient.country.id : (this.props.selectedCountryId ? this.props.selectedCountryId : null),
        selectedClient: this.props.selectedClient || null,
        savingClient: false,
        triggerReload: false,
        validateClient: false,
        validateErrorMessage: null,
        deleting: false,
        editClientCountry: null,
        searchClient: this.props.selectedClient ? this.props.selectedClient.name : '',
        fetching: false,
        fetched: false
    } 

    componentWillMount = () => {
        const {
            selectedClient,
            countryFilter
        } = this.state;
        if ((selectedClient && selectedClient.name) || countryFilter) {
            this.fetchClients();
        }
    }

    fetchClients = () => {
        const {
            //searchClient,
            selectedClient,
            countryFilter
        } = this.state,
        {fromCompanies} = this.props;

        this.setState({
            fetching: true,
            fetched: false
        }, _ => {
            API.Clients.getClients({
                //filter: searchClient,
                countryId: countryFilter
            }).then(res => {
                this.props.clientsActions.setClients(res.data.records);
                let client = null;
                if (selectedClient) {
                    client = res.data.records.find(c => c.id === selectedClient.id)
                    if (!client) {
                        this.setState({
                            // countryFilter: null,
                            selectedClient: defaultClient,
                        });
                    }
                }
                //Si el resultado es solo un cliente, lo seleccionamos de una vez
                if (res.data.records.length === 1) {
                    client = res.data.records[0];

                    fromCompanies && this.props.setClient(client);

                    this.setState({
                        countryFilter: client.country.id,
                        selectedClient: client,
                    })
                }
                this.setState({
                    fetching: false,
                    fetched: true
                })
            })
        })
    }

    handleClose = () => {
        this.setState({
            selectedClient: this.props.selectedClient || null,
            countryFilter: this.props.selectedClient && this.props.selectedClient.country ? this.props.selectedClient.country.id : null,
            savingClient: false,
            createClient: false,
            editClient: false,
            validateClient: false,
            validateErrorMessage: null
        })

        this.props.handleClose && this.props.handleClose()
    }

    selectCountry = (country) => {
        const {
            selectedClient
        } = this.state,
            { clients, fromCompanies } = this.props;

        if (country) {
            fromCompanies && this.props.clientsActions.setClient({});
            fromCompanies && this.props.setClient({});

            let newClients = country.value ? clients.filter(c => c.country.id === country.value) : clients,
                clientIndex = newClients.length > 0 && selectedClient ? newClients.findIndex(c => c.id === selectedClient.id) : -1;
            this.setState({
                editClient: false,
                countryFilter: country.value,
                editClientCountry: country.value,
                selectedClient: clientIndex === -1 ? defaultClient : selectedClient,
            }, _ => {
                this.fetchClients();
            })

            this.props.editOrCreateClient && this.props.editOrCreateClient(false)
            
        } else {
            this.setState({
                editClient: false,
                countryFilter: null,
                editClientCountry: null,
                selectedClient: defaultClient,
            })

            this.props.editOrCreateClient && this.props.editOrCreateClient(false)
            
            this.props.clientsActions.setClients([]);
            fromCompanies && this.props.clientsActions.setClient({});
            fromCompanies && this.props.setClient({});
        }
    }

    selectClient = (selectedClient) => {
        const { clients } = this.props;

        if (selectedClient) {
            let client = clients.find(c => c.id === selectedClient.value)

            this.props.setClient && this.props.setClient(client)

            this.setState({
                editClient: false,
                selectedClient: client ? client : defaultClient,
            })

            this.props.editOrCreateClient && this.props.editOrCreateClient(false)

            if (client) {
                if (client.country.id) {
                    this.setState({
                        countryFilter: client.country.id
                    })
                }
            }
        } else {
            this.props.setClient && this.props.setClient(null)
            this.props.editOrCreateClient && this.props.editOrCreateClient(false)

            this.setState({
                editClient: false,
                selectedClient: defaultClient
            })
        }
    }


    handleCreateClientButtonClick = () => {
        this.setState({
            validateClient: false,
            createClient: true,
            currentClient: this.state.selectedClient,
            selectedClient: defaultClient,
            editClientCountry: this.state.countryFilter || null
        });

        this.props.editOrCreateClient && this.props.editOrCreateClient(true)
    }

    handleCancel = () => {
        this.setState({
            selectedClient: this.state.createClient ? (this.state.currentClient ? this.state.currentClient : null) : this.state.selectedClient,
            editClient: false,
            validateClient: false,
            createClient: false,
            validateErrorMessage: false
        })

        this.props.editOrCreateClient && this.props.editOrCreateClient(false)
    }

    validateFormData = () => {
        const { selectedClient, editClientCountry } = this.state;
        this.setState({
            validateClient: true
        })

        if (selectedClient.name === '' || !editClientCountry || selectedClient.address.address1 === '') {
            this.setState({
                validateErrorMessage: i18n.t('clients.clientFormInputsValidationError')
            })
            return false
        }
        return true;
    }
    deleteClient = () => {
        const { selectedClient } = this.state;
        this.setState({ savingClient: true }, () => {

            //Delete client
            API.Clients.deleteClient({
                clientId: selectedClient.id
            }).then(res => {
                if (res.status === 'success') {
                    this.props.changeClient && this.props.changeClient()
                    this.props.clientsActions.deleteClient(selectedClient);
                    this.handleClose();
                    this.setState({
                        selectedClient: defaultClient,
                        seachClient: '',
                        countryFilter: null,
                        savingClient: false
                    })
                } else {
                    this.setState({
                        savingClient: false
                    })

                    this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)

                }
            })
        })
    }

    handleSaveClient = () => {
        const { selectedClient,
            editClientCountry } = this.state,
            { fromCompanies } = this.props;


        if (this.validateFormData()) {
            this.setState({ savingClient: true }, () => {

                if (selectedClient.id) {
                    //Update client
                    API.Clients.updateClient({
                        client: {
                            ...selectedClient,
                            country: { id: editClientCountry }
                        }
                    }).then(res => {
                        if (res.id) {
                            this.props.clientsActions.updateClient(res)
                            this.setState({
                                countryFilter: res.country.id,
                                editClient: false,
                                selectedClient: res,
                                savingClient: false,
                                triggerReload: true,
                            }, () => {
                                if (this.props.selectedClient && this.state.selectedClient.id === this.props.selectedClient.id) {
                                    this.props.changeClient && this.props.changeClient(res)
                                    this.props.updateRFQClient && this.props.updateRFQClient(this.state.selectedClient);
                                }

                                this.setState({
                                    editClient: false
                                })

                                this.props.editOrCreateClient && this.props.editOrCreateClient(false)
                                
                            })
                        } else {
                            this.setState({
                                savingClient: false
                            })

                            this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)

                        }
                    })
                }
                else {
                    //CreateClient
                    API.Clients.createClient({
                        client: {
                            ...selectedClient,
                            country: { id: editClientCountry }
                        }
                    }).then(res => {
                        if (res.id) {
                            this.props.editOrCreateClient && this.props.editOrCreateClient(false)

                            fromCompanies && this.props.clientsActions.setClient(res)
                            fromCompanies && this.props.setClient(res)

                            this.props.clientsActions.addClient(res)
                            this.setState({
                                countryFilter: res.country.id,
                                createClient: false,
                                editClient: false,
                                selectedClient: res,
                                savingClient: false,
                                triggerReload: true,
                            }, () => {
                                this.setState({
                                    editClient: false
                                })
                            })
                            
                        } else {
                            this.setState({
                                savingClient: false
                            })

                            this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)
                        }
                    })
                }
            })
        }
    }

    handleEditClient = () => {
        let { selectedClient } = this.state;
        this.setState({
            editClientCountry: selectedClient && selectedClient.country.id ? selectedClient.country.id : null,
            editClient: true
        });

        this.props.editOrCreateClient && this.props.editOrCreateClient(true)
    }

    handleChangeClient = () => {
        let { selectedClient } = this.state;
        this.props.changeClient(selectedClient)
        this.props.clientsActions.updateClient(selectedClient)
        this.handleClose();
    }
    

    render() {
        const {
            createClient,
            editClient,
            selectedClient,
            countryFilter,
            editClientCountry,
            savingClient,
            validateClient,
            validateErrorMessage,
            deleting,
            searchClient,
            fetching,
            fetched
        } = this.state,
            {
                clients,
                classes,
                general,
                fromCompanies
            } = this.props;

        if(!general || (general && !general.globalParameters)) return null

        return (

            <div style={fromCompanies ? {padding: '16px 0'} : {padding: '16px'}}>

                {!createClient && !editClient && fromCompanies && (
                    <Grid container spacing={0} justify='flex-end' alignItems='center' className={classes.client__actions__container}>
                        <Grid item sm={3} xs={12} className={classes.client__action__button__container} style={{textAlign: 'right', position: 'absolute', top: '20px'}}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={this.handleCreateClientButtonClick}>
                                {i18n.t('clients.addclient')}
                            </Button>
                        </Grid>
                    </Grid>
                )}


                {!createClient && !editClient && (
                    <Grid container style={{justifyContent: 'flex-start'}}>
                        <Grid item sm={6} xs={12} style={{textAlign: 'left'}}>
                            <CustomSelect options={general.globalParameters.countries ? general.globalParameters.countries.map((e) => ({
                                "label": e.countryName,
                                "value": e.countryId
                            })) : []}
                                label={i18n.t('quotes.quoteRequest.quoteCountry')}
                                value={countryFilter ? countryFilter : null}
                                required={true}
                                onSelect={this.selectCountry}
                            />
                        </Grid>
                        {!countryFilter && (
                            <Grid item xs={12} sm={10} style={{textAlign: 'left'}}>
                                <Typography variant='body2' color="textSecondary">{i18n.t('clients.selectClientCountry')}</Typography>
                            </Grid>
                        )}
                        {countryFilter && (
                            <Grid item sm={9} xs={12}>
                                <CustomSelect
                                    options={clients ? clients.filter(c => countryFilter ? c.country.id === countryFilter : true).map(c => ({ label: c.name, value: c.id })) : []}
                                    label={i18n.t('quotes.quoteRequest.client')}
                                    fullWidth
                                    required={true}
                                    value={selectedClient ? selectedClient.id : null}
                                    disabled={!fetched || fetching || savingClient ? true : false}
                                    onSelect={this.selectClient}
                                />
                            </Grid>
                        )}
                        {fetching && (
                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress color='secondary' size={24} style={{ marginLeft: '5px' }} />
                            </Grid>
                        )}

                        {!createClient && !editClient && fromCompanies && (selectedClient && selectedClient.id !== null && !createClient) === true &&(
                            <Grid item sm={3} xs={12} className={classes.client__action__button__container} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Link
                                    className={classes.customLink}
                                    color="secondary"
                                    variant='button'
                                    style={{ textAlign: 'center' }}
                                    onClick={this.handleEditClient}>
                                    {i18n.t('clients.editClient')}
                                </Link>
                            </Grid>
                        )}

                        {fetched && searchClient && (clients.length === 0) && (
                            <Grid item xs={12} sm={10} style={{textAlign: 'left'}}>
                                <Typography variant='body2' color='textSecondary'>{`${i18n.t('clients.noClientsWithTerm')}`}<strong>{`${searchClient}`}</strong></Typography>
                            </Grid>
                        )}

                    </Grid>
                )}

                {(selectedClient && selectedClient.id !== null) === true && !editClient &&
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12} style={{textAlign: 'left'}}>
                            <Typography variant='body2' color="textSecondary">
                                {selectedClient.name || ''}
                                <br />
                                {selectedClient.taxId || ''}
                                <br />
                                {selectedClient.address.address1 || ''}
                                <br />
                                {selectedClient.address.address2 || ''}
                            </Typography>

                            {!fromCompanies &&
                                <Typography variant='body2' color='textSecondary'>
                                    {selectedClient.contacts.length > 0 ?
                                        `${selectedClient.contacts.length} ${selectedClient.contacts.length === 1 ? i18n.t('clients.contact') : i18n.t('clients.contacts')}` :
                                        i18n.t('clients.noContacts')}
                                </Typography>
                            }


                        </Grid>
                        <Grid item sm={5} xs={12} style={{textAlign: 'left'}}>
                            <Typography variant='body2' color="textSecondary">
                                {selectedClient.phone &&
                                    <span>
                                        <Phone className={classes.rfq__icon} />{selectedClient.phone}
                                    </span>
                                }
                                <br />
                                {selectedClient.fax &&
                                    <span>
                                        <strong>{i18n.t('quotes.clientRFQ.fax')}</strong>{selectedClient.fax}
                                    </span>
                                }
                            </Typography>

                        </Grid>
                        {!fromCompanies && (!this.props.selectedClient || (selectedClient && this.props.selectedClient && selectedClient.id !== null && (selectedClient.id !== this.props.selectedClient.id))) &&
                            <Grid item xs={12}>
                                <Link className={classes.customLink}
                                    variant='button'
                                    onClick={this.handleChangeClient}>
                                    {i18n.t('clients.useSelectedClient')}
                                </Link>
                            </Grid>
                        }

                    </Grid>
                }

                {(editClient || createClient) && (
                    <>
                        <Grid container spacing={0} direction='row'>
                            <Grid item sm={6} xs={12} style={{textAlign: 'left'}}>
                                <CustomSelect
                                    options={general.globalParameters.countries ? general.globalParameters.countries.map((e) => ({
                                        "label": e.countryName,
                                        "value": e.countryId
                                    })) : []}
                                    label={i18n.t('quotes.quoteRequest.quoteCountry')}
                                    value={editClientCountry ? editClientCountry : null}
                                    required={true}
                                    inputProps={{
                                        tabIndex: 1
                                    }}
                                    onSelect={(e) => {
                                        this.setState({
                                            editClientCountry: e ? e.value : null
                                        });
                                    }}
                                    error={validateClient && !editClientCountry ? true : false}
                                />
                            </Grid>

                        </Grid>


                        <Grid container spacing={2} direction='row'>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={savingClient}
                                    InputProps={{
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                name: event.target.value
                                            }
                                        });
                                    }}
                                    error={validateClient && selectedClient.name === '' ? true : false}
                                    value={selectedClient && selectedClient.name ? selectedClient.name : ''}
                                    className={classes.client__form__textField}
                                    id="clientName"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientName')}
                                />

                            </Grid>

                            <Grid item sm={4} xs={12}>

                                <TextField
                                    autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    disabled={savingClient}
                                    InputProps={{
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                taxId: event.target.value
                                            }
                                        });
                                    }}
                                    //error={validateClient && selectedClient.taxId === '' ? true : false}
                                    value={selectedClient && selectedClient.taxId ? selectedClient.taxId : ''}
                                    className={classes.client__form__textField}
                                    id="clientTaxId"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientTaxId')}
                                />
                            </Grid>

                        </Grid>
                        <Grid container spacing={2} direction='row'>

                            <Grid item sm={8} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    disabled={savingClient}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                address: {
                                                    ...selectedClient.address,
                                                    address1: event.target.value
                                                }
                                            }
                                        });
                                    }}
                                    error={validateClient && selectedClient.address.address1 === '' ? true : false}
                                    value={selectedClient && selectedClient.address && selectedClient.address.address1 ? selectedClient.address.address1 : ''}
                                    className={classes.client__form__textField}
                                    id="clientAddress1Txt"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientAddress1')}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>

                                <TextField
                                    autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    InputProps={{
                                    }}
                                    disabled={savingClient}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                phone: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedClient && selectedClient.phone ? selectedClient.phone : ''}
                                    className={classes.client__form__textField}
                                    id="clientPhone"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientPhone')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} direction='row'>

                            <Grid item sm={8} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    autoComplete='off'
                                    fullWidth
                                    disabled={savingClient}
                                    InputProps={{
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                address: {
                                                    ...selectedClient.address,
                                                    address2: event.target.value
                                                }
                                            }
                                        });
                                    }}
                                    value={selectedClient && selectedClient.address && selectedClient.address.address2 ? selectedClient.address.address2 : ''}
                                    className={classes.client__form__textField}
                                    id="clientAddress2"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientAddress2')}
                                />


                            </Grid>


                            <Grid item sm={4} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    autoComplete='off'
                                    fullWidth
                                    InputProps={{
                                    }}
                                    disabled={savingClient}
                                    onChange={event => {
                                        this.setState({
                                            selectedClient: {
                                                ...selectedClient,
                                                fax: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedClient && selectedClient.fax ? selectedClient.fax : ''}
                                    className={classes.client__form__textField}
                                    id="clientFax"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.clientFax')}
                                />


                            </Grid>
                            {validateErrorMessage && (
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                    <Typography color='error'>{i18n.t("requiredFields")}</Typography>
                                </Grid>
                            )}

                        </Grid>

                        <Divider className={classes.client__actions__divider} />

                        <Grid container spacing={2} justify='space-between' direction='row' className={classes.client__form__bottomButtons__grid}>
                            <Grid item xs={12} md={4} className={classes.cancelDelete__buttons__container}>
                                <Button
                                    variant="contained"
                                    disabled={savingClient}
                                    size='small'
                                    onClick={this.handleCancel}>
                                    {i18n.t('clients.cancel')}
                                </Button>
                                {!createClient && (
                                    <Button
                                        disabled={savingClient || deleting || (selectedClient.filesCount > 0) ? true : false}
                                        size='small'
                                        onClick={this.deleteClient}
                                        className={classes.client__deleteClientButton}
                                    >
                                        {i18n.t("clients.deleteClient")}
                                        {deleting && !savingClient && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </Button>

                                )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={savingClient}
                                    fullWidth
                                    className={savingClient ? classes["client__editSections__button--disabled"] : classes.client__editSections__saveButton}
                                    size='small'
                                    onClick={this.handleSaveClient}>
                                    {i18n.t('clients.saveClient')}
                                    {savingClient && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </Grid>
                            {selectedClient.filesCount > 0 && (
                                <Grid item xs={12} className={classes.cancelDelete__buttons__container}>
                                    <Typography color='textSecondary'>{i18n.t('clients.cantBeDeleted')}</Typography>
                                </Grid>
                            )}
                        </Grid>

                    </>
                )}


                {countryFilter && clients && clients.length > 0 && !editClient && !createClient && (!selectedClient || (selectedClient && selectedClient.id === null)) && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign: 'left'}}>
                            {!(fetched && searchClient && (clients.length === 0)) && (
                                <Typography variant='body2' color='textSecondary'>{i18n.t('clients.selectClientToEdit')}</Typography>
                            )}
                        </Grid>
                    </Grid>
                )}



                {!createClient && !editClient && !fromCompanies && (
                    <>
                        <Divider className={classes.client__actions__divider} />

                        <Grid container spacing={0} justify='flex-end' alignItems='center' className={classes.client__actions__container}>
                            <Grid item sm={3} xs={12} className={classes.client__action__button__container}>
                                {(selectedClient && selectedClient.id !== null && !createClient) === true && (
                                    <Link
                                        className={classes.customLink}
                                        color="secondary"
                                        variant='button'
                                        style={{ textAlign: 'center' }}
                                        onClick={this.handleEditClient}>
                                        {i18n.t('clients.editClient')}
                                    </Link>
                                )}
                            </Grid>

                            <Grid item sm={3} xs={12} className={classes.client__action__button__container}>
                                <Link
                                    className={classes.customLink}
                                    color="secondary"
                                    variant='button'
                                    style={{ textAlign: 'center' }}
                                    onClick={this.handleCreateClientButtonClick}>
                                    {i18n.t('clients.addclient')}
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                )}

            </div>
        
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
    }
}


function mapStateToProps(state) {
    return {
        clients: state.clients,
        general: state.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(ManageClients)));