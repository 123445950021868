import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const Translate = {
    translateText : (args = {}) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/translate?text=${args.text}`;
            auth
                .getAuthenticatedUser()
                .then(user => {
                    const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                    var headers = new Headers();
                    headers.append("x-api-key", config.apiGateway.api_key);
                    headers.append("Authorization", prodekAuthToken);

                    fetch(url, {headers}).then(function (response) {
                        return response.json();
                    })
                        .then(function (jsonResponse) {
                            resolve(jsonResponse);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
        });
        return promise;
    }
}

export default Translate;