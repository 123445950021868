
import React, { Component } from 'react'
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as generalActions from '../actions/generalActions'

import API from '../lib/api'


let globalParameters = {

}

export const GlobalParametersContext = React.createContext({...globalParameters, addNewOtherCost: () => {}});



class GlobalParametersContextComponent extends Component {
    state = {
        globalParameters: this.props.globalParameters || {}
    }
    componentWillReceiveProps = (nextProps) => {
        const { globalParameters } = this.state,
            { general } = nextProps;
        if (!globalParameters.countries && general && general.globalParameters) {
            this.setState({
                globalParameters: general.globalParameters
            })
        }
    }
    componentDidMount = () => {
        let { general } = this.props;
        if (!general.globalParameters) {
            API.GlobalParameters.getGlobalParameters().then(res => {
                this.props.generalActions.setGlobalParameters(res.data);
                this.setState({ globalParameters: res.data });
            })
        } else {
            this.setState({
                globalParameters: general.globalParameters
            })
        }
    }

    addNewOtherCost=(newOtherCost)=>{
        const { globalParameters } = this.state

        this.setState({
            globalParameters: {
                ...globalParameters,
                otherCosts: [
                    newOtherCost,
                    ...globalParameters.otherCosts
                ]
            }
        }) 
    }

    render() {
        const { globalParameters } = this.state;
        return (
            <GlobalParametersContext.Provider value={{...globalParameters, addNewOtherCost: this.addNewOtherCost}}>
                {React.cloneElement(this.props.children, { ...this.props })}
            </GlobalParametersContext.Provider>
        )
    }
}

GlobalParametersContextComponent.contextType = GlobalParametersContext;


function mapStateToProps(state) {
    return {
        general: state.general,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalParametersContextComponent);