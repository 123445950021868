import React from 'react'
import {CircularProgress} from '@material-ui/core'
import config from '../../config';
import {
    compose,
    //withProps
} from "recompose"
import {
    withScriptjs, withGoogleMap, GoogleMap,
    //InfoWindow,
    Marker
} from 'react-google-maps';
const GOOGLE_API_KEY = config.google.mapsAPIKey;
// /*global google*/
// https://developers.google.com/maps/documentation/javascript/style-reference
const defaultMapOptions = {
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        }, {
            "featureType": "poi.business",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ]
};

const MapWithMarkers = compose(withScriptjs, withGoogleMap)(props => {
    const {positions, zoom} = props.location;

    return (
        <GoogleMap
            defaultOptions={defaultMapOptions}
            defaultCenter={{
                lat: parseFloat(positions[0].lat),
                lng: parseFloat(positions[0].lng)
            }}
            center={{
                lat: parseFloat(positions[0].lat),
                lng: parseFloat(positions[0].lng)
            }}
            defaultZoom={zoom}>
            
            {positions.map((position, i) => {
                return (
                    <Marker key={i}
                        label={position.title || ''}
                        position={{
                            lat: parseFloat(position.lat),
                            lng: parseFloat(position.lng)
                        }}
                    />
                )
            })}
        </GoogleMap>
    )
})

const Map = (props) => {
    return (
        <MapWithMarkers
            {...props}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            containerElement={<div style = {{ height: '100%', width: '100%' }}/>}
            mapElement={<div style = {{ height: `100%` }}/>}
            loadingElement={
                <div style = {{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }} > 
                    <CircularProgress style={{
                        color: 'grey'
                    }}/> 
                </div>
            }/>
    )
}

export default Map