import React, { Component } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    withStyles
} from '@material-ui/core'
import i18n from 'i18next'
import {
    VendorQuoteCard
} from '.'
import { SlideUp } from '../../general/Transitions'

const styles = theme => ({
    dialog__paper: {
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
    },
    vendor_quote_card_paper: {
        // padding: 0,
        margin: 0,
        // margin: theme.spacing(),
        padding: theme.spacing(2),
        position: 'relative',
    },
    dialog__content: {
        margin: 0,
        padding: 0
    }
})

class VendorQuotePopup extends Component {
    onExited = () => {
        this.props.onExited && this.props.onExited();
    }
    updateVendorQuote = (vendorQuote) => {
        this.props.updateVendorQuote(vendorQuote);
    }
    deleteVendorQuote = (vendorQuote) => {
        this.props.deleteVendorQuote(vendorQuote);
        this.props.handleClose();
    }
    render() {
        const { file, vendorQuote, vendors, classes } = this.props;

        if (!file || !vendorQuote) return null;

        return (
            <Dialog
                maxWidth={'md'}
                classes={{
                    paper: classes.dialog__paper
                }}
                fullWidth={true}
                TransitionComponent={SlideUp}
                aria-labelledby="vendor-quote-form"
                open={this.props.open || false}
                onExited={this.onExited}
            >
                <DialogTitle id="vendor-quote-form" disableTypography={true}>
                    {i18n.t('quotes.details.file')} {file.id} - {vendorQuote ? `${i18n.t('quotes.vendorQuoteRequest.vendorQuote')} ${vendorQuote.id}` : `${i18n.t('quotes.vendorQuoteRequest.new')}`}
                </DialogTitle>
                <DialogContent className={classes.dialog__content}>
                    <VendorQuoteCard
                        deleteVendorQuote={this.deleteVendorQuote}
                        editable={true}
                        vendorQuote={vendorQuote}
                        file={file}
                        vendors={vendors}
                        showError={this.showError}
                        updateVendorQuote={this.updateVendorQuote}
                        currencySymbol={file.currencySymbol}
                        showEditMode={false}
                        fetchVendors={this.props.fetchVendors}
                        classNames={{
                            vendor_quote_card_paper: classes.vendor_quote_card_paper
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color='default'
                        variant="outlined"
                        onClick={this.props.handleClose}>
                        {i18n.t("quotes.quoteRequest.cancelEdit")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(VendorQuotePopup)