import React, { Component } from 'react'
import {
    Dialog,
    Typography,
    IconButton,
    withMobileDialog,
    Button,
    withStyles,
    DialogTitle
} from '@material-ui/core'
import i18n from 'i18next'
import { Close } from '@material-ui/icons'
import { SlideUp } from '../general/Transitions'
import { CustomMessages } from '../general'
import { ManageClients } from '.'

const styles = theme => {
    return {
        dialog__paper: {
            width: '100%',
            overflow: 'visible'
        },
        success: {
            backgroundColor: theme.palette.green[100],
        },
        error: {
            backgroundColor: theme.palette.primary.main,
        },
        errorLabel: {
            color: theme.palette.getContrastText(theme.palette.primary.main)
        },
        closeButton: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
        manage__button: theme.manage__button
    }
}

class ManageClientsPopup extends Component {
    state = {
        countryFilter: this.props.selectedClient && this.props.selectedClient.country.id ? this.props.selectedClient.country.id : (this.props.selectedCountryId ? this.props.selectedCountryId : null),
        selectedClient: this.props.selectedClient || null,
        searchClient: this.props.selectedClient ? this.props.selectedClient.name : '',
        apiErrorMessage: null
    }

    handleClose = () => {
        this.setState({
            showManageClientPopup: false
        })
    }

    openManageClientsPopup = () => {
        this.setState({
            showManageClientPopup: true
        })
    }

    showApiErrorMessage=(error)=>{
        this.setState({
            apiErrorMessage: error
        })
    }

    render() {
        const {
            savingClient,
            showManageClientPopup,
            apiErrorMessage,
        } = this.state,
            {
                classes
            } = this.props;

        return (
            <div>
                <Button
                    className={classes.manage__button}
                    size="small"
                    variant="outlined"
                    onClick={this.openManageClientsPopup}>
                    {i18n.t('clients.manageClients')}
                </Button>

                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    open={showManageClientPopup}
                    maxWidth='sm'
                    TransitionComponent={SlideUp}
                >

                    <DialogTitle disableTypography={true}>
                        <Typography variant='body1' color="inherit">{i18n.t('clients.clientsTitle')}</Typography>
                        <IconButton color="inherit"
                            disabled={savingClient}
                            onClick={this.handleClose}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <ManageClients
                        selectedClient={this.props.selectedClient}
                        handleClose={this.handleClose}
                        changeClient={this.props.changeClient}
                        showApiErrorMessage={this.showApiErrorMessage}
                    />

                </Dialog>
            </div>
        )
    }
}



export default withMobileDialog()(withStyles(styles)(ManageClientsPopup));