import { createMuiTheme } from '@material-ui/core/styles'
import { grey, blueGrey } from '@material-ui/core/colors'
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Inconsolata',
      'monospace'
    ]
  },
  customLink: {
    color: 'rgba(30, 144, 255, 1)',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: 'rgba(30, 144, 255,0.8)'
    }
  },
  manage__button: {
    backgroundColor: blueGrey[50],
    color: grey[600],
    '&:hover': {
      backgroundColor: blueGrey[100],
    }
  },
  modal: {
    paper: {
      maxWidth: '350px',
      margin: '40px auto'
    },
    title: {
      background: '#575759',
      textAlign: 'center',
      padding: '8px 0',
      borderRadius: '4px 4px 0 0'
    },
    container: {
      padding: '24px'
    },
    buttons: {
      display: 'flex',
      marginTop: '24px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  buttonSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px'
  },
  smallButton: {
    padding: '3px',
    width: '80px',
    minWidth: '80px'
  },
  dashboardCard: {
    header: {
      fontFamily: [
        'Inconsolata',
        'monospace'
      ],
      color: '#fff',
      fontSize: '12px',
      width: '95%',
      margin: '0px auto',
      padding: `8px 16px`,
      borderRadius: '3px'
    },
    smallHeader: {
      fontFamily: [
        'Inconsolata',
        'monospace'
      ],
      color: '#fff',
      fontSize: '12px',
      width: '28%',
      marginLeft: '15px',
      padding: `8px 16px`,
      borderRadius: '3px'
    },
    actionButton: {
      margin: '8px',
      background: 'transparent',
      color: '#fff',
      boxShadow: 'none'
    },
    'actionButton--selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    }
  },
  popperOverlay: {
    background: 'rgba(100,100,100,0.5)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    pointerEvents: 'all'
  },
  sort__component__container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px'
  },
  palette: {
    primary: {
      main: '#F4D915'
    },
    primaryTransparent: {
      '10': 'rgba(246, 224, 67,0.1)',
      '20': 'rgba(246, 224, 67,0.2)',
      '30': 'rgba(246, 224, 67,0.3)',
      '40': 'rgba(246, 224, 67,0.4)',
      '50': 'rgba(246, 224, 67,0.5)',
      '60': 'rgba(246, 224, 67,0.6)',
      '70': 'rgba(246, 224, 67,0.7)',
      '80': 'rgba(246, 224, 67,0.8)',
      '90': 'rgba(246, 224, 67,0.9)',
      '100': 'rgba(246, 224, 67,1)'
    },
    secondary: {
      main: '#575759'
    },
    textPrimary: {
      main: '#c4c4c4'
    },
    specialBlue: {
      main: '#007bff'
    },
    purple: {
      'light': '#ab47bc',
      'dark': '#8e24aa'
    },
    green: {
      '10': 'rgba(0, 204, 0,0.1)',
      '20': 'rgba(0, 204, 0,0.2)',
      '30': 'rgba(0, 204, 0,0.3)',
      '40': 'rgba(0, 204, 0,0.4)',
      '50': 'rgba(0, 204, 0,0.5)',
      '60': 'rgba(0, 204, 0,0.6)',
      '70': 'rgba(0, 204, 0,0.7)',
      '80': 'rgba(0, 204, 0,0.8)',
      '90': 'rgba(0, 204, 0,0.9)',
      '100': 'rgba(0, 204, 0, 1)'
    },
    orange: {
      '10': 'rgba(255, 165, 0,0.1)',
      '20': 'rgba(255, 165, 0,0.2)',
      '30': 'rgba(255, 165, 0,0.3)',
      '40': 'rgba(255, 165, 0,0.4)',
      '50': 'rgba(255, 165, 0,0.5)',
      '60': 'rgba(255, 165, 0,0.6)',
      '70': 'rgba(255, 165, 0,0.7)',
      '80': 'rgba(255, 165, 0,0.8)',
      '90': 'rgba(255, 165, 0,0.9)',
      '100': 'rgba(255, 165, 0, 1)'
    },
    yellow: {
      '10': 'rgba(254, 254, 10,0.1)',
      '20': 'rgba(254, 254, 10,0.2)',
      '30': 'rgba(254, 254, 10,0.3)',
      '40': 'rgba(254, 254, 10,0.4)',
      '50': 'rgba(254, 254, 10,0.5)',
      '60': 'rgba(254, 254, 10,0.6)',
      '70': 'rgba(254, 254, 10,0.7)',
      '80': 'rgba(254, 254, 10,0.8)',
      '90': 'rgba(254, 254, 10,0.9)',
      '100': 'rgba(254, 254, 10, 1)'
    },
    beige: {
      '10': 'rgba(245, 245, 220,0.1)',
      '20': 'rgba(245, 245, 220,0.2)',
      '30': 'rgba(245, 245, 220,0.3)',
      '40': 'rgba(245, 245, 220,0.4)',
      '50': 'rgba(245, 245, 220,0.5)',
      '60': 'rgba(245, 245, 220,0.6)',
      '70': 'rgba(245, 245, 220,0.7)',
      '80': 'rgba(245, 245, 220,0.8)',
      '90': 'rgba(245, 245, 220,0.9)',
      '100': 'rgba(245, 245, 220, 1)'
    },
    blue: {
      '20': 'rgba(30, 144, 255,0.2)',
      '10': 'rgba(30, 144, 255,0.1)',
      '30': 'rgba(30, 144, 255,0.3)',
      '40': 'rgba(30, 144, 255,0.4)',
      '50': 'rgba(30, 144, 255,0.5)',
      '60': 'rgba(30, 144, 255,0.6)',
      '70': 'rgba(30, 144, 255,0.7)',
      '80': 'rgba(30, 144, 255,0.8)',
      '90': 'rgba(30, 144, 255,0.9)',
      '100': 'rgba(30, 144, 255, 1)'
    },
    red: {
      '20': 'rgba(192, 57, 43,0.2)',
      '10': 'rgba(192, 57, 43,0.1)',
      '30': 'rgba(192, 57, 43,0.3)',
      '40': 'rgba(192, 57, 43,0.4)',
      '50': 'rgba(192, 57, 43,0.5)',
      '60': 'rgba(192, 57, 43,0.6)',
      '70': 'rgba(192, 57, 43,0.7)',
      '80': 'rgba(192, 57, 43,0.8)',
      '90': 'rgba(192, 57, 43,0.9)',
      '100': 'rgba(192, 57, 43, 1)'
    },
    seabook: {
      main: '#487eb0'
    },
    naval: {
      main: '#40739e'
    },
    error: {
      main: '#DB5461'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  // Ejemplo para cambiar el estilo de los botones del tema
  overrides: {
    MuiButton: {
      root: {
        minWidth: '150px'
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiCollapse: {
      container: {
        paddingLeft: '20px'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '9.5px'
      },
      // error: {
      //   backgroundColor: '#ffffa3'
      // }
      root: {
        "&$error": {
          "backgroundColor": "#ffffa3"
        }
      }
    },
    MuiFormLabel: {
      // required: {
      //   color: '#DB5461'
      // },
      "root": {
        "&$required": {
          "color": "#DB5461"
        }
      }
    },
    MuiDialogTitle: {
      root: {
        color: '#fff',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        background: '#575759',
        textAlign: 'center',
        padding: '15px 60px 15px',
        fontFamily: [
          'Inconsolata',
          'monospace'
        ]
      }
    },

    MuiStepIcon: {
      active: {
        color: 'rgba(30, 144, 255,0.7) !important'
      },
      completed: {
        color: 'rgba(30, 144, 255,0.7) !important'
      }
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: 0,
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative'
      }
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          backgroundColor: '#f5f5f5'
        },
      },
    },

    MuiFormControl: {
      root: {
        backgroundColor: 'white',
      },
    }
  },
  maxWith: '1100px'
})

export default theme
