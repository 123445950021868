import React, { Component } from 'react'
import { UsersAndCalendar } from '../components/agenda'
import { AdminDashboard } from '../components/widgets'
import { Helmet } from 'react-helmet';
import moment from 'moment'
import i18n from 'i18next'

const today = moment();

class Dashboard extends Component {

    state = {
        month: today.format('MM'),
        year: today.format('YYYY')
    }

    setParams=(month, year)=>{
        this.setState({
            month,
            year
        })
    }   

    render() {
        const { month, year } = this.state

        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.dashboard')}`} />
                <AdminDashboard 
                    month={month}
                    year={year}
                />
                <UsersAndCalendar 
                    setParams={this.setParams}
                />
            </div>
        );
    }
}

export default Dashboard;
