import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const ItemUnits = {
    createItemUnit: (args) => {
        let { name, code } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/units`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify({name, code}),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateItemUnit: (args) => {
        let { name, code, itemUnitId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/units/${itemUnitId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify({name, code}),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteItemUnit: (args) => {
        let { itemUnitId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/units/${itemUnitId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
}

export default ItemUnits;