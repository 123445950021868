import { createStore, compose } from 'redux'
import rootReducer from './reducers/rootReducer'

const defaultState = {
    files: [],
    file: {},
    apiStatus: {},
    people: {
        allPeople: [],
        filterPeople: [],
        counters: null
    },
    general: {
        showEditVendorQuote: false,
        showAddItem: false,
        showAddVendorQuote: false,
        showSelectItemVendor: false,
        showVendorsPo: false,
        showInvoiceForm: false,
        showAddVendorQuoteItem: false,
        showEditVendorQuoteItem: false,
        showPeopleFilter: false,
        showCreateClientContact: false,
        showCreateVendorContact: false,
        vendorQuoteId: null,
        globalParameters: null
    },
    vendorQuotes: [],
    clients: [],
    client: {},
    vendors: [],
    vendor: {},
    events: [],
    eventsFilters: {},
    event: null,
    account: {}
}

const enhancers = compose(
    window.window.__REDUX_DEVTOOLS_EXTENSION__ ? window.window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);


const store = createStore(
    rootReducer,
    defaultState,
    enhancers);
export default store;