import React, { Component } from 'react'
import {
    withStyles,
    Typography,
    Checkbox,
    TextField,
    InputAdornment
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import { NumberFormatInputCustom } from '../../general'
import NumberFormat from 'react-number-format'
import ReactTable from "react-table"
import classNames from 'classnames'
import i18n from 'i18next'
import moment from 'moment'
import config from '../../../config'


const styles = theme => {
    return (
        {
            preparing__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`,
                marginTop: theme.spacing()
            },
            preparing__items__table__align: {
                margin: `0 ${theme.spacing()}px ${theme.spacing()}px`,
            },
            quoteitems__table__itemPartNumber: {
                color: theme.palette.naval.main
            },
            quoteitems__table__descriptionExtended: {
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between'
            },
            quoteitems__table__quantity: {
                color: theme.palette.text.secondary
            },
            quoteitems__table__textInput: {
                fontSize: theme.typography.fontSize * 0.8,
            },
            quoteitems__table__cell__center: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            quoteitems__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
            },
            quoteitems__list: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__paper: {
                padding: theme.spacing(),
                marginBottom: theme.spacing(),
            },
            quoteitems__list__item: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__edit__grid__container: {
                marginTop: theme.spacing()
            },
            quoteitems__table__accepted: {
                color: theme.palette.green[100],
                fontWeigth: 'bold'
            },
            quoteItems__table__btn: {
                ...theme.smallButton,
                borderColor: theme.palette.blue[100],
                color: theme.palette.blue[100]
            },
            "quoteItems__table__btn--remove": {
                borderColor: theme.palette.red[100],
                color: theme.palette.red[100]
            },
            quoteItems__table__textField: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
                width: '90%',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginRight: 0,
                }
            },
            quoteItems__table__menu: {
                width: "auto"
            },
            quoteItem__selectVendor__link: {
                ...theme.customLink,
                whiteSpace: 'normal'
            },
            preparing__form__textField: {
                marginTop: theme.spacing()
            },
            clientQuote__noItems: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.orange[80],
                borderRadius: theme.spacing() / 2,
                padding: '0 5px',
                marginLeft: theme.spacing(2)
            },
            extendeDescription__icon: {
                fontSize: '20px',
                color: theme.palette.grey[500]
            }
        })
}

class ClientQuoteAcceptedItems extends Component {
    state = {
        file: this.props.file,
        selectedItem: null,
        items: this.props.items
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file,
            items: nextProps.items
        })
    }

    selectItem = (e, index) => {
        e.stopPropagation()
        const { selectedItem } = this.state;

        this.setState({
            selectedItem: index === selectedItem ? null : index
        })
    }

    renderItemDescription = (cellInfo) => {
        const { classes } = this.props,
            { selectedItem } = this.state;

        let row = cellInfo.original
        return (
            <div>
                {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.vendor ?
                    <Typography noWrap>
                        {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.vendor ? cellInfo.original.acceptedVendorQuoteItem.vendor.name : ''}
                    </Typography>
                    :
                    <div style={{ width: '100%' }}>
                        <Typography>{i18n.t('quotes.preparatingQuoteInformation.noVendors')}</Typography>
                    </div>
                }
                <Typography className={classes.quoteitems__table__itemPartNumber} >{row.rfqItem.partNumber}</Typography>
                <div
                    className={classes.quoteitems__table__descriptionExtended}
                    style={{ alignItems: selectedItem === cellInfo.index ? 'flex-start' : 'center' }}
                    onClick={(e) => this.selectItem(e, cellInfo.index)}>
                    <Typography color='textSecondary'
                        style={{ whiteSpace: selectedItem === cellInfo.index ? 'pre-line' : 'nowrap' }}
                        noWrap>
                        {row.rfqItem.description}
                    </Typography>
                    {row.rfqItem.description.length > 35 && <span>
                        {selectedItem === cellInfo.index ?
                            <KeyboardArrowUp className={classes.extendeDescription__icon} />
                            :
                            <KeyboardArrowDown className={classes.extendeDescription__icon} />
                        }
                    </span>}
                </div>
            </div>
        )
    }

    renderCostField = (cellInfo) => {
        const { classes } = this.props,
            { items, file } = this.state;

        return (
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id="quoteAmount"
                    disabled={!items[cellInfo.index].include}
                    className={classes.preparing__form__textField}
                    value={items[cellInfo.index].include ? items[cellInfo.index].amount : 0}
                    onBlur={e => {
                        const newItems = [...items],
                            quoteAmount = parseFloat(e.target.value),
                            itemCost = quoteAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0);

                        newItems[cellInfo.index].amount = quoteAmount;
                        newItems[cellInfo.index].total = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                            items[cellInfo.index].quantity * parseFloat(itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100)).toFixed(2) :
                            items[cellInfo.index].quantity * parseFloat(quoteAmount + ((quoteAmount * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / items[cellInfo.index].quantity) : 0)).toFixed(2)

                        this.setState({
                            items: newItems
                        });
                        this.props.updatePOItems(newItems);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment
                            position="start"
                        >{file.currencySymbol}</InputAdornment>,
                        inputComponent: NumberFormatInputCustom
                    }}
                />
                {items[cellInfo.index].weightedCost !== 0 && items[cellInfo.index].include && moment(file.clientQuote[0].date).isAfter(config.prorratedDate) &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography variant='body2' color="textSecondary">Pro.</Typography>
                            <NumberFormat
                                value={items[cellInfo.index].weightedCost}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)', marginLeft: '10px' }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid rgba(0, 0, 0, 0.54)' }}>
                            <Typography variant='body2' color="textSecondary"></Typography>
                            <NumberFormat value={items[cellInfo.index].amount + items[cellInfo.index].weightedCost}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                style={{ fontSize: '16px', textAlign: 'right', marginLeft: '10px' }}
                                fixedDecimalScale={true} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderQuantityField = (cellInfo) => {
        const { classes } = this.props,
            { items, file } = this.state;

        return (
            <div>
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id="quoteAmount"
                    className={classes.preparing__form__textField}
                    value={items[cellInfo.index].include ? cellInfo.original.quantity : 0}
                    disabled={!items[cellInfo.index].include}
                    onBlur={e => {
                        const newItems = [...items],
                            quoteAmount = items[cellInfo.index].amount,
                            itemCost = quoteAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0);

                        newItems[cellInfo.index].quantity = e.target.value;
                        newItems[cellInfo.index].total = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                            parseInt(e.target.value) * parseFloat(itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100)).toFixed(2) :
                            parseInt(e.target.value) * parseFloat(quoteAmount + ((quoteAmount * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / parseInt(e.target.value)) : 0)).toFixed(2)

                        this.setState({ items: newItems });
                        this.props.updatePOItems(newItems);
                    }}
                    InputProps={{
                        inputComponent: NumberFormatInputCustom
                    }}
                />
            </div>
        )
    }

    renderIncludeField = (cellInfo) => {
        const { items } = this.state;

        return (
            <Checkbox
                checked={cellInfo.original.include}
                tabIndex={-1}
                onChange={(e) => {
                    const newItems = [...items];
                    newItems[cellInfo.index].include = e.target.checked;

                    this.setState({ items: newItems });
                    this.props.updatePOItems(newItems);
                }}
            />
        )
    }


    render() {
        const { file, items } = this.state,
            { classes, clientQuoteTaxPercent } = this.props;

        if (!file) return null;

        return (
            <div style={{ marginTop: '16px', width: '100%' }} >
                <ReactTable
                    data={items}
                    columns={[
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column1'),
                            id: 'rowIndex',
                            className: classes.quoteitems__table__cell__center,
                            Cell: row => (row.index + 1),
                            width: 40
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column2Summary'),
                            id: 'description',
                            Cell: this.renderItemDescription
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.quantity'),
                            width: 100,
                            id: 'quantity',
                            style: { display: 'flex' },
                            Cell: this.renderQuantityField
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column5'),
                            width: 120,
                            id: 'cost',
                            style: { display: 'flex', alignItems: 'flex-start' },
                            className: classes.quoteitems__table__cell__rigth,
                            Cell: this.renderCostField,
                            Footer: () => {
                                let quoteTotalAmount = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                                    items.reduce(((total, row) => {
                                        let itemCost = row.amount + row.weightedCost
                                        return (
                                            parseFloat(total) + (row.quantity * itemCost)
                                        )
                                    }), 0) :
                                    items.reduce(((total, row) => parseFloat(total) + row.amount), 0);

                                if (quoteTotalAmount === 0) return null;
                                return (
                                    <div>
                                        <NumberFormat value={quoteTotalAmount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            style={{ fontSize: '16px' }}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column8'),
                            width: 140,
                            id: 'totalColumn',
                            style: {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                textAlign: 'right'
                            },
                            Cell: row => {
                                let profit = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                                    (row.original.quantity * (((row.original.amount + row.original.weightedCost) * row.original.markupPercent) / 100)) : 
                                    (row.original.quantity * ((row.original.amount * row.original.markupPercent) / 100));

                                if (!row.original.include) return <div />

                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <NumberFormat value={row.original.total - profit}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true} />

                                        {profit > 0 &&
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: '14px' }} color='textSecondary'>{i18n.t('quotes.preparatingQuoteInformation.profit')}</Typography>

                                                <NumberFormat value={profit}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                                    fixedDecimalScale={true} />
                                            </div>
                                        }

                                        {row.original.taxPercent !== 0 && clientQuoteTaxPercent &&
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: '14px' }} color='textSecondary'>{i18n.t('quotes.preparatingQuoteInformation.tax')}</Typography>

                                                <NumberFormat value={(clientQuoteTaxPercent * row.original.total) / 100}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                                    fixedDecimalScale={true} />
                                            </div>
                                        }

                                        <div style={{display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid rgba(0, 0, 0, 0.54)'}}>
                                            <NumberFormat value={row.original.total}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                                fixedDecimalScale={true} />
                                        </div>
                                    </div>
                                )
                            },
                            Footer: () => {
                                let quotedClientTotalAmount = items.reduce(((total, row) => {
                                    return parseFloat(total) + (row.include ? (row.total + (file.clientQuote[0].taxPercent && row.taxPercent ? ((parseFloat(file.clientQuote[0].taxPercent) * row.total) / 100) : 0)) : 0)
                                }), 0);

                                if (quotedClientTotalAmount === 0) return null;

                                return (
                                    <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                        <NumberFormat value={quotedClientTotalAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.include'),
                            id: 'include',
                            width: 80,
                            style: { display: 'flex', justifyContent: 'center' },
                            Cell: this.renderIncludeField
                        }
                    ]}
                    defaultPageSize={items.length}
                    className={classNames(classes.preparing__items__table, ' -striped -highlight')}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filtrable={false}
                    resizable={false}
                />
            </div>
        )
    }
}


export default withStyles(styles)(ClientQuoteAcceptedItems)