import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Fade,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    withMobileDialog,
    CircularProgress
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
} from '@material-ui/icons'
import API from '../../lib/api';

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as clientsActions from '../../actions/clientsActions'


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    client__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    buttonSpinner: theme.buttonSpinner,
    client__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    }
});


class EventClientContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: props.client ? props.client : null,
            clientContact: props.clientContact ? props.clientContact : null,
            firstName: props.clientContact ? props.clientContact.firstName : '',
            lastName: props.clientContact ? props.clientContact.lastName : '',
            email: props.clientContact ? props.clientContact.email : '',
            phone: props.clientContact ? props.clientContact.phone : '',
            validating: false,
            saving: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            client: nextProps.client ? nextProps.client : null,
            clientContact: nextProps.clientContact ? nextProps.clientContact : null,
            firstName: nextProps.clientContact ? nextProps.clientContact.firstName : '',
            lastName: nextProps.clientContact ? nextProps.clientContact.lastName : '',
            email: nextProps.clientContact ? nextProps.clientContact.email : '',
            phone: nextProps.clientContact ? nextProps.clientContact.phone : '',
        })
    }

    validateFormData = () => {
        const { firstName, lastName } = this.state;
        this.setState({
            validating: true
        })

        if (firstName === '' || lastName === '') {
            return false
        }
        return true;
    }

    manageClientContact = () => {
        const { firstName, lastName, email, phone, clientContact, client } = this.state;

        let newClientContact = {
            id: clientContact ? clientContact.id : null,
            firstName,
            lastName,
            email,
            phone,
            clientId: client.id
        }

        if (this.validateFormData()) {
            this.setState({
                saving: true
            })
            if (!clientContact || !clientContact.id) {
                API.Clients.createClientContact({ clientContact: newClientContact }).then(res => {
                    if (res.id) {
                        this.setState({
                            saving: false
                        })

                        this.props.clientsActions.addClientContact({ ...res, clientId: client.id });
                        this.props.updateClientContact(res, client.id)
                        this.props.onClose()

                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            } else {
                API.Clients.updateClientContact({ clientContact: newClientContact }).then(res => {
                    if (res.id) {
                        this.setState({
                            saving: false
                        })

                        this.props.clientsActions.updateClientContact({ ...res, clientId: client.id });
                        this.props.updateClientContact(res, client.id)
                        this.props.onClose()

                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            }
        }

    }

    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { firstName, lastName, email, phone, validating,
            clientContact, saving, client } = this.state,
            { classes } = this.props;

        if (!client || !client.id) return null

        return (
            <>
                {/* <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={true}
                    open={this.props.open}
                    onExited={this.onExited}
                    TransitionComponent={SlideLeft}
                > */}
                <Fade in={this.props.open}
                    mountOnEnter
                    unmountOnExit>
                    <div>

                        <DialogTitle disableTypography={true} className={classes.client__form__title}>
                            <IconButton onClick={this.props.onClose}>
                                <KeyboardArrowLeft color='inherit' className={classes.client__form__title__back} />
                            </IconButton>
                            <Typography variant='body1' color="inherit">
                                {!clientContact ? i18n.t('visits.clientContact.createClientContact') : i18n.t('visits.clientContact.updateClientContact')}
                            </Typography>
                            <div style={{ width: '30px' }} />
                        </DialogTitle>

                        <DialogContent style={{ padding: '0' }}>
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label style={{ alignItems: 'center' }}>
                                        {i18n.t('visits.clientContact.client')}
                                        <input disabled value={client ? client.name : ''} name='client' />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.clientContact.firstName')}
                                        <input required value={firstName} valid={validating && (!firstName || firstName === '') ? false : true} name='firstName' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.clientContact.lastName')}
                                        <input required value={lastName} valid={validating && (!lastName || lastName === '') ? false : true} name='lastName' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.clientContact.email')}
                                        <input required value={email} name='email' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    <mobiscroll.Form.Label>
                                        {i18n.t('visits.clientContact.phone')}
                                        <input required value={phone} name='phone' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup>
                                    <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                                        <mobiscroll.Button color="primary" disabled={saving} onClick={this.manageClientContact}>
                                            {i18n.t("visits.clientContact.saveClientContact")}
                                            {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </mobiscroll.Button>
                                    </div>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        </DialogContent>
                    </div>
                </Fade>
                {/* </Dialog> */}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        clients: state.clients
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventClientContactForm)));
