import {
    VENDOR_QUOTES_SET,
    VENDOR_QUOTE_UPDATE,
    VENDOR_QUOTE_ADD,
    VENDOR_QUOTE_DELETE
} from '../actions/vendorQuotesActions'

import {
    UPDATE_FILE
} from '../actions/filesActions'

function vendorQuotes(state = [], action) {
    let index = -1, file = null;

    switch (action.type) {
        case VENDOR_QUOTES_SET:
            return action.vendorQuotes
        case VENDOR_QUOTE_ADD:
            return [
                action.vendorQuote,
                ...state
            ]
        case VENDOR_QUOTE_DELETE:
            return state.filter(vq => vq.id !== action.vendorQuote.id);

        case VENDOR_QUOTE_UPDATE:
            index = state.findIndex(vq => vq.vendorQuoteId === action.vendorQuote.id)
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        ...action.vendorQuote,
                        vendorQuoteStatusId: action.vendorQuote.status.id,

                    },
                    ...state.slice(index + 1)
                ]
            }
            return state;
        case UPDATE_FILE:
            file = action.file;
            let vendorQuotes = file.vendorQuotes,
                fileVendorQuotes = state.filter(vq => vq.fileId === file.id);
            //El file actualizado no tiene vendor quotes
            if (!vendorQuotes || (vendorQuotes && vendorQuotes.length === 0)) {
                return state.filter(vq => vq.fileId !== file.id)
            }

            //El file actualizado tiene vendor quotes pero en el state no existen, se agregaron
            if (!fileVendorQuotes || (fileVendorQuotes && fileVendorQuotes.length === 0)) {
                return [
                    ...state,
                    ...vendorQuotes.map(vq => ({
                        ...vq,
                        clientId: file.client.id,
                        clientName: file.client.name,
                        rfqName: file.requestForQuote.name,
                        rfqReference: file.requestForQuote.reference,
                        vendorQuoteStatusId: vq.status.id,
                        vendorName: vq.vendor.name,
                        salesPersonName: file.salesPerson.userName,
                        vendorQuoteId: vq.id,
                        vendorQuoteDateTimeCreated: vq.createDateTime,
                        vendorQuoteDateTimeRequested: vq.requestedDateTime,
                        vendorQuoteDateTimeResponse: vq.responseDateTime,
                        vendorItems: vq.items.map(vqi => ({
                            vendorQuotedItemUnitAmount: vqi.unitAmount,
                            vendorQuoteItemQuantity: vqi.quantity,
                            vendorQuoteItemDescription: vqi.description,
                            vendorQuoteItemDeliveryDays: vqi.deliveryDays,
                            rfqItemPartNumber: vqi.partNumber,
                            rfqItemUnit: vqi.unit
                        }))
                    }))
                ]
            }

            if (vendorQuotes && vendorQuotes.length > 0 && fileVendorQuotes && fileVendorQuotes.length > 0) {
                let updatedState = [...state];
                //Quitamos los vq que esten en el file para poner solo los actualizados
                updatedState = updatedState.filter(vq => vq.fileId !== file.id);
                //Ahora actualizar el resto
                return [
                    ...updatedState,
                    ...vendorQuotes.map(vq => ({
                        ...vq,
                        clientId: file.client.id,
                        clientName: file.client.name,
                        rfqName: file.requestForQuote.name,
                        rfqReference: file.requestForQuote.reference,
                        vendorQuoteStatusId: vq.status.id,
                        vendorName: vq.vendor.name,
                        salesPersonName: file.salesPerson.userName,
                        vendorQuoteId: vq.id,
                        vendorQuoteDateTimeCreated: vq.createDateTime,
                        vendorQuoteDateTimeRequested: vq.requestedDateTime,
                        vendorQuoteDateTimeResponse: vq.responseDateTime,
                        vendorItems: vq.items.map(vqi => ({
                            vendorQuotedItemUnitAmount: vqi.unitAmount,
                            vendorQuoteItemQuantity: vqi.quantity,
                            vendorQuoteItemDescription: vqi.description,
                            vendorQuoteItemDeliveryDays: vqi.deliveryDays,
                            rfqItemPartNumber: vqi.partNumber,
                            rfqItemUnit: vqi.unit
                        }))
                    }))
                ]
            }
            return state;
        default:
            return state;
    }
}

export default vendorQuotes;