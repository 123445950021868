import React from 'react';
import {
    withStyles,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Link,
    Button
} from '@material-ui/core/';
import {
    KeyboardArrowLeft, TrainRounded
} from '@material-ui/icons'
import i18n from 'i18next'
import NumberFormat from 'react-number-format'
import { CustomSelect } from '../../general'

const styles = theme => ({
    vendor__quote__items__table: {
        marginTop: '16px',
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    vendor__quote__items__table__quantity: {
        color: theme.palette.text.secondary
    },
    customLink: {
        ...theme.customLink,
        fontSize: '16px'
    },
    quoteItem__selectVendor__link: {
        ...theme.customLink,
        whiteSpace: 'normal'
    },
})

class ClientQuoteSelectItemsByVendor extends React.Component {

    state = {
        selectedVendor: null,
        itemsWithVendor: [],
        vendorsSelected: [],
        thereAreChanges: false,
        vendors: [],
        vendorQuoteItems: [],
    }

    componentWillMount() {
        const { file, items } = this.props;

        if (!file) return null;

        let vendorQuotes = [],
            vendorsSelected = [];

        let itemsWithVendor = items.map((item, i) => {
            if (item.acceptedVendorQuoteItem.id) {
                vendorsSelected.push({
                    item: item.id,
                    vendorQuote: file.vendorQuotes.find(vq => vq.items.find(itm => itm.id === item.acceptedVendorQuoteItem.id)).id
                })
            }

            return {
                ...item,
                vendorsQuotes: file.vendorQuotes.filter((vq) => {
                    return (
                        vq.items.find(vqItem => item.rfqItem.id === vqItem.rfqItem.id && !vqItem.deleted) && vq.status.id === 3
                    )
                }).map(vendorQ => ({
                    id: vendorQ.id,
                    parentId: vendorQ.parentId,
                    vendor: vendorQ.vendor,
                    item: vendorQ.items.find(itm => itm.rfqItemId === item.rfqItem.id)
                }))
            }
        })

        let itemsRFQIds = items.map((item, i) => {
            return item.rfqItem.id
        })

        vendorQuotes = file.vendorQuotes.filter((vq) => {
            return (
                vq.items.filter(item => itemsRFQIds.includes(item.rfqItem.id)).length > 0 && vq.status.id === 3
            )
        });

        let vendors = vendorQuotes.map((vq, i) => {
            return {
                id: vq.vendor.id,
                parentId: vq.parentId,
                name: vq.vendor.name,
                vendorQuoteId: vq.id
            }
        });

        this.setState({
            itemsWithVendor,
            vendorQuotes,
            vendors,
            vendorsSelected
        })
    }


    onConfirm = () => {
        const { items, file } = this.props,
            { vendorQuotes, vendorsSelected, vendors } = this.state;

        let newItems = items.map((item, index) => {

            let vendorSelected = vendorsSelected.find(vqItem => vqItem.item === item.id)

            if (vendorSelected) {
                let selectedVendorQuote = vendorQuotes
                    .find(vq => vq.id === vendorSelected.vendorQuote),
                    vendorQuoteItem = vendorQuotes
                        .find(vq => vq.id === vendorSelected.vendorQuote).items
                        .find(it => it.rfqItem.id === item.rfqItem.id && !it.deleted);

                if (vendorQuoteItem) {
                    return {
                        ...item,
                        markupPercent: file && file.status > 4 ? parseFloat(((item.amount - parseFloat(vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.quantity : 0))) * 100) / parseFloat(vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.quantity : 0))) : item.markupPercent,
                        vendorQuoteItemId: vendorQuoteItem.id,
                        amount: file && file.status > 4 ? parseFloat(item.amount) : vendorQuoteItem.unitAmount,
                        noVendorComments: '',
                        unitAmount: vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.quantity : 0),
                        acceptedVendorQuoteItem: {
                            ...item.acceptedVendorQuoteItem,
                            vendor: {
                                name: vendors.find(v => v.vendorQuoteId === selectedVendorQuote.id).name,
                                id: vendors.find(v => v.vendorQuoteId === selectedVendorQuote.id).id
                            },
                            unitAmount: vendorQuoteItem.unitAmount,
                            deliveryDays: vendorQuoteItem.deliveryDays,
                            quantity: vendorQuoteItem.quantity,
                            unit: vendorQuoteItem.unit
                        }
                    }
                } else {
                    return {
                        ...item
                    }
                }
            } else {
                return {
                    ...item,
                    vendorQuoteItemId: null,
                    amount: 0,
                    unitAmount: 0,
                    markupPercent: 0,
                    acceptedVendorQuoteItem: {}
                }
            }
        })

        this.props.onConfirm(newItems);
        this.props.handleClose()
    }

    selectVendor = (vendor) => {
        const { vendorQuotes, itemsWithVendor } = this.state;

        let vendorsSelectedCopy = []

        if (vendor) {
            let vendorQuote = vendorQuotes.find(vq => vq.id === vendor.value);

            vendorsSelectedCopy = itemsWithVendor.map(item => ({
                item: item.id,
                vendorQuote: vendor.value
            }))

            this.setState({
                selectedVendor: vendor,
                vendorQuoteItems: vendorQuote.items.filter(it => !it.deleted),
                vendorsSelected: vendorsSelectedCopy,
                thereAreChanges: true
            })
        } else {
            this.setState({
                selectedVendor: null,
                vendorsSelected: [],
                thereAreChanges: TrainRounded
            })
        }

    }

    handleSelectVendor = (e, item, vendorQuote) => {
        const { vendorsSelected } = this.state,
            vendorsSelectedCopy = [...vendorsSelected];

        let vendorSelectedIndex = vendorsSelectedCopy.findIndex(vqItem => vqItem.item === item.id)

        if (vendorSelectedIndex !== -1) {
            if (e.target.checked) {
                vendorsSelectedCopy[vendorSelectedIndex] = {
                    item: item.id,
                    vendorQuote: vendorQuote.id
                }
            } else {
                vendorsSelectedCopy.splice(vendorSelectedIndex, 1)
            }
        } else {
            if (e.target.checked) {
                vendorsSelectedCopy.push({
                    item: item.id,
                    vendorQuote: vendorQuote.id
                })
            }
        }

        this.setState({
            vendorsSelected: vendorsSelectedCopy,
            thereAreChanges: true
        })

    }

    render() {
        const { file, classes, currencySymbol, handleClose } = this.props,
            { itemsWithVendor,
                vendorsSelected,
                thereAreChanges,
                vendors,
                selectedVendor,
                vendorQuoteItems
            } = this.state;

        if (!file) return null;

        let vendorPO = null
        if (file.status > 4 && selectedVendor) {
            vendorPO = file.vendorPOs.find(vp => vp.vendorQuoteId === selectedVendor.value && vp.status.id > 2)
        }

        return (

            <div style={{ marginTop: '16px' }}>

                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 8 }}>
                    <Link className={classes.quoteItem__selectVendor__link}
                        style={{ display: 'flex' }}
                        variant='button'
                        color='secondary'
                        onClick={() => {
                            if (thereAreChanges) {
                                this.onConfirm()
                            } else {
                                handleClose()
                            }
                        }}>
                        <KeyboardArrowLeft />
                        {thereAreChanges ? i18n.t('quotes.preparatingQuoteInformation.backAndSave') : i18n.t('quotes.preparatingQuoteInformation.back')}
                    </Link>
                </div>

                <Typography variant='body2' color='textSecondary' style={{ marginBotton: '8px' }}>
                    {i18n.t('quotes.preparing.selectVendorMessage')}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '300px' }}>
                        <CustomSelect
                            disabled={vendorPO ? true : false}
                            options={vendors.map(vendor => ({ label: vendor.name, value: vendor.vendorQuoteId }))}
                            label={i18n.t('quotes.preparing.vendors')}
                            value={selectedVendor ? selectedVendor : null}
                            onSelect={(vendor) => this.selectVendor(vendor)}
                        />
                    </div>
                    {selectedVendor &&
                        <Typography variant='body1' color='textPrimary'>{`${vendorQuoteItems.length} ${i18n.t('quotes.preparing.itemsSelected')}`}</Typography>
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead style={{ background: '#ccc' }}>
                            <TableRow>
                                <TableCell align={'left'} >
                                    {i18n.t('quotes.preparatingQuoteInformation.item')}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {i18n.t('quotes.preparatingQuoteInformation.quantityUnit')}
                                </TableCell>
                                <TableCell align={'left'} >
                                    {i18n.t('quotes.preparatingQuoteInformation.vendor')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itemsWithVendor.map(item => {

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={item.id}
                                    >
                                        <TableCell align="left" style={{ maxWidth: '150px' }}>
                                            {item.rfqItem.partNumber}
                                        </TableCell>

                                        <TableCell align="right" style={{ width: '70px' }}>
                                            {`${item.quantity} ${item.rfqItem.unit}`}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.vendorsQuotes.map(vendorQuote => {
                                                let selected = vendorsSelected.findIndex(elem => elem.item === item.id && elem.vendorQuote === vendorQuote.id)
                                                return (
                                                    <div key={vendorQuote.id} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Checkbox checked={selected !== -1 ? true : false} onChange={(e) => this.handleSelectVendor(e, item, vendorQuote)} />

                                                        <NumberFormat
                                                            value={vendorQuote.item.unitAmount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                                                        />

                                                        <Typography variant='body2' color="inherit" style={{ marginLeft: '16px' }}>
                                                            {vendorQuote.parentId ? vendorQuote.parentId : vendorQuote.id} {vendorQuote.vendor.name}
                                                        </Typography>

                                                    </div>
                                                )
                                            })}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>

                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={this.props.handleClose}>
                        {i18n.t("quotes.preparatingQuoteInformation.cancelBtn")}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={vendorPO ? true : false}
                        onClick={this.onConfirm}>
                        {i18n.t('quotes.preparatingQuoteInformation.selectItemByVendorDone')}
                    </Button>
                </div>

            </div>

        );
    }
}

export default withStyles(styles)(ClientQuoteSelectItemsByVendor);