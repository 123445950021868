import MyDeals from './MyDeals'
import DealForm from './DealForm'
import DealFormSub1 from './DealFormSub1'
import DealsList from './DealsList'

export {
    MyDeals,
    DealForm,
    DealFormSub1,
    DealsList
}