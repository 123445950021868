import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Typography
} from '@material-ui/core/';
import { Warning } from '@material-ui/icons';
import i18n from 'i18next'
import NumberFormat from 'react-number-format'


const styles = theme => ({
    preparing__itemsList__closeWindow: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    preparing__itemsList__tabs__divider: {
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.secondary.main}`
    },
    quoteitems__table__quantity: {
        color: theme.palette.text.secondary
    },
    quoteitems__table__itemPartNumber: {
        color: theme.palette.blue[100],
        fontWeight: 'bold'
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
})

class ClientQuoteItemVendorsList extends React.Component {

    state = {
        selected: null,
        vendorQuotes: []
    }

    componentWillReceiveProps(nextProps) {
        const { file, row, items } = nextProps;

        if (!file || row === this.props.row) return null;

        let vendorQuotes = [];

        if (row) {
            vendorQuotes = file.vendorQuotes.filter((vq) => {
                return (
                    vq.items.filter(item =>
                        item.rfqItem.id === row.rfqItem.id
                    ).length > 0 && vq.status.id === 3
                )
            });

        } else {
            let itemsRFQIds = items.map((item, i) => {
                return item.rfqItem.id
            })

            vendorQuotes = file.vendorQuotes.filter((vq) => {
                return (
                    vq.items.filter(item => itemsRFQIds.includes(item.rfqItem.id)).length > 0 && vq.status.id === 3
                )
            });
        }

        let vendors = vendorQuotes.map((vq, i) => {
            return {
                id: vq.vendor.id,
                name: vq.vendor.name,
                vendorQuoteId: vq.id,
                parentId: vq.parentId
            }
        });

        let vendorQuote = row && row.vendorQuoteItemId ? file.vendorQuotes.find(v => v.items.findIndex(item => item.id === row.vendorQuoteItemId) !== -1) : null

        this.setState({
            selected: vendorQuote ? vendors.find(v => v.vendorQuoteId === vendorQuote.id) : null,
            vendorQuotes: vendorQuotes,
            vendors: vendors
        })
    }


    handleClick = (e, vendor) => {
        const { selected } = this.state;

        if (selected && vendor.vendorQuoteId === selected.vendorQuoteId) {
            this.setState({
                selected: null
            });
        } else {
            this.setState({
                selected: vendor
            });
        }
    }

    onConfirm = () => {
        const { items, index, file, row } = this.props,
            { vendors, selected } = this.state;

        let newItems = [...items];

        if (selected) {
            let vendorQuoteItem = file.vendorQuotes
                .find(vq => vq.id === selected.vendorQuoteId).items
                .find(item => item.rfqItem.id === row.rfqItem.id);

            newItems[index] = {
                ...newItems[index],
                markupPercent: file && file.status > 4 ? parseFloat(((newItems[index].amount - parseFloat(vendorQuoteItem.unitAmount + (newItems[index].weightedCost ? newItems[index].weightedCost / newItems[index].quantity : 0))) * 100) / parseFloat(vendorQuoteItem.unitAmount + (newItems[index].weightedCost ? newItems[index].weightedCost / newItems[index].quantity : 0))) : newItems[index].markupPercent,
                vendorQuoteItemId: vendorQuoteItem.id,
                amount: file && file.status > 4 ? parseFloat(newItems[index].amount) : vendorQuoteItem.unitAmount,
                noVendorComments: '',
                unitAmount: vendorQuoteItem.unitAmount + (newItems[index].weightedCost ? newItems[index].weightedCost / newItems[index].quantity : 0),
                acceptedVendorQuoteItem: {
                    ...newItems[index].acceptedVendorQuoteItem,
                    vendor: {
                        name: vendors.find(v => v.id === parseInt(selected.id)).name,
                        id: selected.id
                    },
                    id: vendorQuoteItem.id,
                    unitAmount: vendorQuoteItem.unitAmount,
                    deliveryDays: vendorQuoteItem.deliveryDays,
                    quantity: vendorQuoteItem.quantity,
                    unit: vendorQuoteItem.unit
                }
            }

        } else {
            newItems[index] = {
                ...newItems[index],
                vendorQuoteItemId: null,
                amount: 0,
                unitAmount: 0,
                markupPercent: 0,
                acceptedVendorQuoteItem: {}
            }
        }

        this.props.onConfirm(newItems);
        this.props.handleClose()
    }

    render() {
        const { file,
            classes,
            currencySymbol,
            row,
            open
        } = this.props,
            { vendors, selected } = this.state;

        if (!file || !row) return null;


        let vendorPO = null
        if (file.status > 4 && selected) {
            vendorPO = file.vendorPOs.find(vp => vp.vendorQuoteId === selected.vendorQuoteId && vp.status.id > 2)
        }

        return (
            <Dialog
                open={open}
                aria-labelledby="vendorList"
                aria-describedby="Vendors Lists">
                <DialogTitle id="alert-dialog-title" disableTypography={true}>
                    {file ? `${i18n.t('quotes.preparing.file')} ${file.id} - ` : ''}{i18n.t('quotes.preparatingQuoteInformation.selectAVendor')}
                </DialogTitle>

                <DialogContent>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                <TableCell align={'left'} >
                                    {i18n.t('quotes.preparatingQuoteInformation.vendor')}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {i18n.t('quotes.preparatingQuoteInformation.quantityUnit')}
                                </TableCell>
                                <TableCell align={'right'} >
                                    {i18n.t('quotes.preparatingQuoteInformation.cost')}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {i18n.t('quotes.preparatingQuoteInformation.deliveryDays')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vendors && vendors.map(vendor => {
                                const isSelected = selected ? selected.vendorQuoteId === vendor.vendorQuoteId : false;

                                let vendorQuoteId = vendor.vendorQuoteId,
                                    vendorQuoteItem = file.vendorQuotes
                                        .find(vq => vq.id === vendorQuoteId).items
                                        .find(item => item.rfqItem.id === row.rfqItem.id);

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        onClick={e => vendorPO ? {} : this.handleClick(e, vendor)}
                                        key={vendor.vendorQuoteId}
                                        selected={isSelected}
                                    >
                                        <TableCell component="th" scope="row" padding="none" >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <Checkbox checked={isSelected} disabled={vendorPO ? true : false} />

                                                <Typography variant='body2' color="inherit" noWrap style={{ width: '145px' }}>
                                                    {vendor.parentId ? vendor.parentId : vendor.vendorQuoteId} {vendor.name}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">{`${vendorQuoteItem.quantity} ${vendorQuoteItem.unit}`}</TableCell>
                                        <TableCell align="right">
                                            <NumberFormat
                                                value={vendorQuoteItem.unitAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </TableCell>
                                        <TableCell align="right">{vendorQuoteItem.deliveryDays}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    {vendorPO &&
                        <div style={{ display: 'flex', margin: '16px 0', right: '8px' }}>
                            <Warning className={classes.warning__message__icon} />
                            <Typography>{i18n.t('quotes.preparatingQuoteInformation.dontAllowChanges')}</Typography>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={this.props.handleClose}>
                        {i18n.t("quotes.preparatingQuoteInformation.cancelBtn")}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={vendorPO ? true : false}
                        onClick={this.onConfirm}>
                        {i18n.t('quotes.preparatingQuoteInformation.selectVendorDone')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ClientQuoteItemVendorsList);