import React, { Component } from 'react'
import {
    Dialog,
    Typography,
    Divider,
    Grid,
    IconButton,
    withMobileDialog,
    TextField,
    Button,
    Link,
    Radio,
    CircularProgress,
    DialogTitle,
    Switch,
    FormControlLabel,
    withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import ReactTable from "react-table"
import PropTypes from 'prop-types'
import i18n from 'i18next'
import moment from 'moment'
import { Close, Star } from '@material-ui/icons'
import { SlideUp } from '../general/Transitions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as vendorsActions from '../../actions/vendorsActions'
import * as apiStatusActions from '../../actions/apiStatusActions'


import API from '../../lib/api';

const styles = theme => ({
    customLink: theme.customLink,
    dialog__paper: {
        width: '100%',
        overflow: 'visible',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog_content_container: {
        padding: theme.spacing(2)
    },
    vendor__contact__actions__divider: {
        marginTop: theme.spacing()
    },
    vendor__actions__container: {
        marginTop: theme.spacing(2)
    },
    vendor__action__button__container: {
        textAlign: 'center'
    },
    vendorcontacts__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`,
    },
    contacts__table__cell: {
        display: 'flex',
        alignItems: 'center'
    },
    vendor__contact__form__input: {
        padding: theme.spacing() - 1,
        fontSize: '0.8em'
    },
    vendor__contact__form__textField: {
        // width: '90%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        // },
        margin: `${theme.spacing()}px 0`
    },
    vendor__form__bottomButtons__grid: {
        marginTop: theme.spacing()
    },
    cancelDelete__buttons__container: {
        display: 'flex'
    },
    vendor__deleteClientButton: {
        marginRight: theme.spacing(),
        borderColor: theme.palette.red[80],
        '&:hover': {
            backgroundColor: theme.palette.red[60],
            color: theme.palette.getContrastText(theme.palette.red[100])
        },
        marginLeft: theme.spacing(),
        color: theme.palette.red[80],
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
    "client__editSections__button--disabled": {
        backgroundColor: theme.palette.textPrimary.main
    },
    client__editSections__saveButton: {
    },
    manage__button: theme.manage__button,
    contacts__table__container: {
        maxHeight: '500px',
        overflowY: 'auto'
    },
    centerCell: {
        display: 'flex',
        alignItems: 'center'
    }
})

const defaultVendorContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: null
}


class ManageVendorContactsPopup extends Component {
    state = {
        showManageVendorContactsPopup: false,
        editVendorContact: false,
        createVendorContact: false,
        validateVendorContact: false,
        selectedVendorContact: this.props.selectedVendorContact || null,
        vendorContacts: this.props.vendor ? this.props.vendor.contacts : [],
        currentSelectedContactId: this.props.selectedVendorContact ? this.props.selectedVendorContact.id : null,
        triggerReload: false,
        validateErrorMessage: false,
        savingVendorContact: false,
        deleting: false
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.general.showCreateVendorContact) {
            this.setState({
                createVendorContact: true
            })
        }

        this.setState({
            selectedVendorContact: nextProps.selectedVendorContact || null,
            vendorContacts: nextProps.vendor ? nextProps.vendor.contacts : [],
            currentSelectedContactId: nextProps.selectedVendorContact ? nextProps.selectedVendorContact.id : null,
        })
    }

    handleClose = () => {
        const { triggerReload } = this.state,
            { onClose } = this.props;
        this.props.generalActions.showCreateVendorContact(false)
        this.setState({
            showManageVendorContactsPopup: false,
            selectedVendorContact: this.props.selectedVendorContact || null,
            savingVendorContact: false,
            createVendorContact: false,
            editVendorContact: false,
            vendorContact: this.props.vendor ? this.props.vendor.contacts : [],
            validateVendorContact: false,
            validateErrorMessage: null,
            triggerReload: false,
        }, () => {
            onClose && onClose({ triggerReload });
        })
    }
    handleCreateVendorContact = () => {
        this.setState({
            validateVendorContact: false,
            createVendorContact: true,
            selectedVendorContact: defaultVendorContact
        });
    }

    handleChangeRadio = contactId => {
        this.setState({ currentSelectedContactId: contactId });
    }
    handleChangeVendorContact = () => {
        const { vendorContacts, currentSelectedContactId, triggerReload } = this.state;
        let contact = vendorContacts.find(c => c.id === currentSelectedContactId);
        this.props.changeVendorContact && this.props.changeVendorContact(contact, { reloadVendors: triggerReload })
        this.handleClose();
    }

    handleEditVendorContact = (contact) => {
        this.setState({
            selectedVendorContact: contact,
            editVendorContact: true
        })
    }

    handleCancel = () => {
        this.setState({
            selectedVendorContact: this.state.createVendorContact ? null : this.state.selectedVendorContact,
            editVendorContact: false,
            validateVendorContact: false,
            createVendorContact: false,
            validateErrorMessage: false
        })
        if (this.props.general.showCreateVendorContact) {
            this.handleClose();
        }
    }

    validateFormData = () => {
        const { selectedVendorContact } = this.state;
        this.setState({
            validateVendorContact: true
        })

        if (selectedVendorContact.firstName === '' || selectedVendorContact.lastName === '') {
            this.setState({
                validateErrorMessage: i18n.t('vendors.contactFormInputsValidationError')
            })
            return false
        }
        return true;
    }

    handleSaveVendorContact = () => {
        const { selectedVendorContact, vendorContacts } = this.state,
            { vendor } = this.props;
        let newVendorContacs = [...vendorContacts];
        if (this.validateFormData()) {
            if (!selectedVendorContact.id) {
                this.setState({
                    validateErrorMessage: null,
                    savingVendorContact: true,
                    validateVendorContact: false
                })

                selectedVendorContact.vendorId = vendor.id;

                API.Vendors.createVendorContact({ vendorContact: selectedVendorContact }).then(res => {
                    this.props.vendorsActions.addVendorContact(res.data.record)
                    newVendorContacs[newVendorContacs.length] = res.data.record;
                    this.props.refreshVendorAndContacts && this.props.refreshVendorAndContacts();
                    this.setState({
                        savingVendorContact: false,
                        createVendorContact: false,
                        selectedVendorContact: res.data.record,
                        vendorContacts: newVendorContacs,
                        triggerReload: true
                    })
                    if (this.props.general.showCreateVendorContact) {
                        this.setState({
                            currentSelectedContactId: res.data.record.id
                        }, () => {
                            this.handleChangeVendorContact();
                        })
                    }
                })
            } else {
                this.setState({
                    validateErrorMessage: null,
                    savingVendorContact: true,
                    validateVendorContact: false
                })

                selectedVendorContact.vendorId = vendor.id;

                API.Vendors.updateVendorContact({ vendorContact: selectedVendorContact }).then(res => {
                    this.props.vendorsActions.updateVendorContact(res.data.record)
                    let newVendorContacs = [...vendorContacts],
                        index = newVendorContacs.findIndex(c => c.id === selectedVendorContact.id);

                    this.props.refreshVendorAndContacts && this.props.refreshVendorAndContacts();
                    this.setState({
                        editVendorContact: false,
                        selectedVendorContact: res.data.record,
                        vendorContacts: [
                            ...newVendorContacs.slice(0, index),
                            { ...res.data.record },
                            ...newVendorContacs.slice(index + 1),
                        ],
                        savingVendorContact: false,
                        triggerReload: true,
                    })
                })
            }
        }
    }
    deleteVendorContact = () => {
        const { selectedVendorContact } = this.state,
            { vendor } = this.props;
        selectedVendorContact.vendorId = vendor.id;
        this.setState({
            savingVendorContact: true
        }, _ => {
            API.Vendors.deleteVendorContact({
                vendorContact: selectedVendorContact
            }).then(res => {
                if (res.status === 'success') {
                    this.props.vendorsActions.deleteVendorContact(selectedVendorContact)

                    this.props.handleVendorContactChange && this.props.handleVendorContactChange(null)

                    this.setState({
                        editVendorContact: false,
                        selectedVendorContact: defaultVendorContact,
                        savingVendorContact: false,
                        triggerReload: true,
                        vendorContacts: this.state.vendorContacts.filter(c => c.id !== selectedVendorContact.id),
                    })

                } else {
                    this.setState({
                        savingVendor: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        })

    }
    openVendorContactPopup = () => {
        this.setState({
            showManageVendorContactsPopup: true,
        })
    }
    onEnter = () => {
        this.setState({
            selectedVendorContact: this.props.selectedVendorContact,
            vendor: this.props.vendor,
            vendorContacts: this.props.vendor.contacts,
            showManageVendorContactsPopup: true,
            currentSelectedContactId: this.props.selectedVendorContact ? this.props.selectedVendorContact.id : null,
        })
    }
    onExited = () => {
        this.setState({
            showManageVendorContactsPopup: false,
            editVendorContact: false,
            createVendorContact: false,
            validateVendorContact: false,
            triggerReload: false,
            validateErrorMessage: false,
            savingVendorContact: false,
            deleting: false
        }, () => {
            this.props.generalActions.showCreateVendorContact(false)
        })
    }

    setAsFavorite=(row)=>{

    }

    render() {

        let {
            showManageVendorContactsPopup,
            editVendorContact,
            createVendorContact,
            vendorContacts,
            currentSelectedContactId,
            validateErrorMessage,
            savingVendorContact,
            selectedVendorContact,
            validateVendorContact,
            deleting,
        } = this.state, {
            vendor,
            classes,
            general,
            theme,
            fromCompanies
        } = this.props;

        if (!vendor) return null;

        return (
            <>
                <Button
                    className={classes.manage__button}
                    size="small"
                    variant='outlined'
                    onClick={this.openVendorContactPopup}>
                    {i18n.t('vendors.manageVendorContacts')}
                </Button>

                <Dialog
                    classes={
                        {
                            paper: classes.dialog__paper
                        }
                    }
                    maxWidth='sm'
                    open={showManageVendorContactsPopup || general.showCreateVendorContact || false}
                    TransitionComponent={SlideUp}
                    onExited={this.onExited}
                    onEnter={this.onEnter}
                >
                    <DialogTitle disableTypography={true}>
                        {vendor.name} {i18n.t('vendors.contacts')}
                        <IconButton color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                            className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <div className={classes.dialog_content_container}>

                        {!editVendorContact && !createVendorContact && vendorContacts && vendorContacts.length > 0 && (
                            <>
                                <div className={classes.contacts__table__container}>
                                    <ReactTable
                                        data={vendorContacts}
                                        getTdProps={(state, rowInfo, column, instance) => (
                                            {
                                                style: {
                                                    background: (rowInfo ?
                                                        (this.props.selectedVendorContact.id === rowInfo.original.id ? theme.palette.blue[10] :
                                                            null) : null)
                                                }
                                            }
                                        )}
                                        columns={[
                                            {
                                                Header: i18n.t('vendors.contactFirstName'),
                                                id: 'firstName',
                                                className: classes.contacts__table__cell,
                                                width: 120,
                                                filterable: vendorContacts.length < 5 ? false : true,
                                                Cell: row => (
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <div className={classes.centerCell}>
                                                            {row.original.favorite && 
                                                                <Star color={'primary'} style={{fontSize: '16px', marginRight: '8px'}}/>
                                                            }
                                                            <Typography style={{fontSize: '11.2px'}}>{row.original.firstName}</Typography>
                                                        </div>
                                                        {moment(row.lastedUsed).format('MMM Do, YYYY')}
                                                    </div>
                                                ),
                                                Filter: ({ filter, onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            autoComplete='off'
                                                            fullWidth
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    return row._original.firstName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                                }
                                            },
                                            {
                                                Header: i18n.t('vendors.contactLastName'),
                                                accessor: 'lastName',
                                                className: classes.contacts__table__cell,
                                                width: 110,
                                                filterable: vendorContacts.length < 5 ? false : true,
                                                Filter: ({ filter, onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            autoComplete='off'
                                                            fullWidth
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    return row._original.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                                }
                                            },
                                            {
                                                Header: i18n.t('vendors.contactEmail'),
                                                accessor: 'email',
                                                className: classes.contacts__table__cell,
                                            },
                                            {
                                                Header: i18n.t('vendors.contactPhone'),
                                                accessor: 'phone',
                                                className: classes.contacts__table__cell,
                                                width: 110
                                            },
                                            {
                                                Header: '',
                                                className: classes.contacts__table__cell,
                                                Cell: row => (
                                                    <Link className={classes.customLink}
                                                        variant='button'
                                                        onClick={() => this.handleEditVendorContact(row.original)}>
                                                        {i18n.t('clients.edit')}
                                                    </Link>),
                                                width: 40
                                            },
                                            {
                                                Header: '',
                                                className: classes.contacts__table__cell,
                                                textAlign: 'center',
                                                show: !fromCompanies,
                                                Cell: row => (
                                                    <>
                                                        {row.original.active ? (
                                                            <Radio name='contact'
                                                                checked={currentSelectedContactId === row.original.id ? true : false}
                                                                onChange={() => {
                                                                    this.handleChangeRadio(row.original.id)
                                                                }}
                                                            />
                                                        ) : (
                                                                <div>{i18n.t('vendors.contactInactive')}</div>
                                                            )
                                                        }
                                                    </>
                                                ),
                                                width: 50
                                            }
                                        ]}
                                        resizable={false}
                                        sortable={false}
                                        defaultPageSize={vendorContacts.length < 5 ? vendorContacts.length : 5}
                                        pageSize={vendorContacts.length < 5 ? vendorContacts.length : 5}
                                        className={classNames(classes.vendorcontacts__table, ' -striped -highlight')}
                                        showPaginationTop={false}
                                        showPaginationBottom={vendorContacts.length < 5 ? false : true}
                                        showPageSizeOptions={false}
                                        filtrable={vendorContacts.length < 5 ? false : true}
                                    />
                                </div>
                                {this.props.selectedVendorContact && this.props.selectedVendorContact.id !== currentSelectedContactId && (
                                    <Link
                                        className={classes.customLink}
                                        variant='button'
                                        onClick={this.handleChangeVendorContact}>
                                        {i18n.t('vendors.useSelectedContact')}
                                    </Link>
                                )}
                            </>
                        )}


                        {(editVendorContact || createVendorContact) && (
                            <>
                                <Grid container spacing={2} direction='row' >
                                    <Grid item sm={6} xs={12}>
                                        <TextField autoComplete='off' required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            disabled={savingVendorContact}
                                            inputProps={{
                                                className: classes.vendor__contact__form__input
                                            }}
                                            onChange={event => {
                                                this.setState({
                                                    selectedVendorContact: {
                                                        ...selectedVendorContact,
                                                        firstName: event.target.value
                                                    }
                                                });
                                            }}
                                            error={validateVendorContact && selectedVendorContact.firstName === '' ? true : false}
                                            value={selectedVendorContact ? selectedVendorContact.firstName ? selectedVendorContact.firstName : '' : ''}
                                            className={classes.vendor__contact__form__textField}
                                            id="clientContactFirstName"
                                            type="text"
                                            variant="outlined"
                                            label={i18n.t('quotes.quoteRequest.contactFirstName')}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField autoComplete='off' required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            disabled={savingVendorContact}
                                            inputProps={{
                                                className: classes.vendor__contact__form__input
                                            }}
                                            onChange={event => {
                                                this.setState({
                                                    selectedVendorContact: {
                                                        ...selectedVendorContact,
                                                        lastName: event.target.value
                                                    }
                                                });
                                            }}
                                            error={validateVendorContact && selectedVendorContact.lastName === '' ? true : false}
                                            value={selectedVendorContact ? selectedVendorContact.lastName ? selectedVendorContact.lastName : '' : ''}
                                            className={classes.vendor__contact__form__textField}
                                            id="clientContactLastName"
                                            type="text"
                                            variant="outlined"
                                            label={i18n.t('quotes.quoteRequest.contactLastName')}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <TextField
                                            autoComplete='off'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            disabled={savingVendorContact}
                                            inputProps={{
                                                className: classes.vendor__contact__form__input
                                            }}
                                            onChange={event => {
                                                this.setState({
                                                    selectedVendorContact: {
                                                        ...selectedVendorContact,
                                                        email: event.target.value
                                                    }
                                                });
                                            }}
                                            value={selectedVendorContact ? selectedVendorContact.email ? selectedVendorContact.email : '' : ''}
                                            className={classes.vendor__contact__form__textField}
                                            id="clientContactEmail"
                                            type="text"
                                            variant="outlined"
                                            label={i18n.t('quotes.quoteRequest.contactEmail')}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            autoComplete='off'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            disabled={savingVendorContact}
                                            inputProps={{
                                                className: classes.vendor__contact__form__input
                                            }}
                                            onChange={event => {
                                                this.setState({
                                                    selectedVendorContact: {
                                                        ...selectedVendorContact,
                                                        phone: event.target.value
                                                    }
                                                });
                                            }}
                                            value={selectedVendorContact ? selectedVendorContact.phone ? selectedVendorContact.phone : '' : ''}
                                            className={classes.vendor__contact__form__textField}
                                            id="clientContactPhone"
                                            type="text"
                                            variant="outlined"
                                            label={i18n.t('quotes.quoteRequest.contactPhone')}
                                        />
                                    </Grid>

                                    {editVendorContact && (
                                        <Grid item sm={3} xs={12} style={{display: 'flex'}}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={selectedVendorContact.active ? true : false}
                                                        onChange={() => {
                                                            this.setState({
                                                                selectedVendorContact: {
                                                                    ...selectedVendorContact,
                                                                    active: !selectedVendorContact.active
                                                                }
                                                            })
                                                        }}
                                                        value={i18n.t('vendors.contactActive')}
                                                    />
                                                }
                                                label={selectedVendorContact.active ? i18n.t('vendors.contactActive') : i18n.t('vendors.contactInactive')}
                                            />

                                        </Grid>
                                    )}

                                    <Grid item sm={3} xs={12}>
                                        <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {
                                                this.setState({
                                                    selectedVendorContact: {
                                                        ...selectedVendorContact,
                                                        favorite: !selectedVendorContact.favorite
                                                    }
                                                })
                                            }}>
                                            <IconButton aria-label="add to favorites" >
                                                <Star color={selectedVendorContact.favorite ? 'primary' : 'secondary'}/>
                                            </IconButton>
                                                
                                            <Typography>{i18n.t('vendors.contactFavorite')}</Typography>
                                        </div>
                                    </Grid>

                                    {validateErrorMessage && (
                                        <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                            <Typography color='error'>{i18n.t("requiredFields")}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        )}


                        {!editVendorContact && !createVendorContact && (!vendorContacts || (vendorContacts && vendorContacts.length === 0)) && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>{i18n.t('vendors.selectVendorContactToEdit')}</Typography>
                                </Grid>
                            </Grid>
                        )}


                        <Divider className={classes.vendor__contact__actions__divider} />


                        {!createVendorContact && !editVendorContact && (
                            <Grid container spacing={0} alignItems='center' justify='flex-end' className={classes.vendor__actions__container}>
                                <Grid item sm={3} xs={12} className={classes.vendor__action__button__container}>
                                    <Link
                                        className={classes.customLink}
                                        color="secondary"
                                        variant='button'
                                        onClick={this.handleCreateVendorContact}>
                                        {i18n.t('vendors.addVendorContact')}
                                    </Link>
                                </Grid>
                            </Grid>
                        )}

                        {(editVendorContact || createVendorContact) && (
                            <Grid container spacing={2} justify='space-between' direction='row' className={classes.vendor__form__bottomButtons__grid}>
                                <Grid item xs={12} md={4} className={classes.cancelDelete__buttons__container}>
                                    <Button
                                        variant="contained"
                                        disabled={savingVendorContact}
                                        size='small'
                                        onClick={this.handleCancel}>
                                        {i18n.t('vendors.cancel')}
                                    </Button>
                                    {!createVendorContact && (
                                        <Button
                                            disabled={savingVendorContact || deleting ? true : false}
                                            size='small'
                                            onClick={this.deleteVendorContact}
                                            className={classes.vendor__deleteClientButton}
                                        >
                                            {i18n.t("vendors.deleteVendor")}
                                            {deleting && !savingVendorContact && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={savingVendorContact}
                                            fullWidth
                                            className={savingVendorContact ? classes["client__editSections__button--disabled"] : classes.client__editSections__saveButton}
                                            size='small'
                                            onClick={this.handleSaveVendorContact}>
                                            {i18n.t('vendors.saveVendorContact')}
                                            {savingVendorContact && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        )}

                    </div>
                </Dialog>
            </>
        )
    }
}
ManageVendorContactsPopup.propTypes = {
    vendor: PropTypes.object
}


function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(ManageVendorContactsPopup)))