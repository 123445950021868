import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const Vendors = {
    getVendors: (args = {}) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors?${args.filter ? `filter=${args.filter}` : ''}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createVendor: (args) => {
        let { vendor } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendor),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateVendor: (args) => {
        let { vendor } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors/${vendor.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendor),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteVendor: (args) => {
        let { vendor } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors/${vendor.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendor),
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createVendorContact: (args) => {
        let { vendorContact } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors/${vendorContact.vendorId}/contacts`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendorContact),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateVendorContact: (args) => {
        let { vendorContact } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors/${vendorContact.vendorId}/contacts/${vendorContact.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendorContact),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteVendorContact: (args) => {
        let { vendorContact } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/vendors/${vendorContact.vendorId}/contacts/${vendorContact.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(vendorContact),
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }

}

export default Vendors;