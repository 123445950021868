import React, { Component } from 'react'
import {
    Grid,
    TextField,
    withStyles,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Link,
    IconButton,
    InputAdornment
} from '@material-ui/core'
import i18n from 'i18next';
import { Close } from '@material-ui/icons'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import ReactTable from "react-table";
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import { NumberFormatInputCustom, ConfirmationDialog } from '../../general'


const styles = theme => {
    return (
        {
            preparing__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            },
            preparing__form__paymentSchedule__container: {
            },
            preparing__form__paymentSchedule__radioLabel: {
                color: theme.palette.text.secondary
            },
            preparing__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`
            },
            preparing__form__other__cost__buttons: {
                display: 'flex',
                flexDirection: 'column',

            },
            payment__schedule__add__button: {
                ...theme.customLink
            },
            payment__schedule__title: {
            },
            payment__schedule__container: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing()
            },
            payment__schedule__table__container: {
                padding: 0,
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            preparing__paymentSchedule__addButton: {
                ...theme.smallButton,
                marginTop: theme.spacing(2),
                borderColor: theme.palette.blue[100],
                color: theme.palette.blue[100]
            },
            preparing__paymentSchedule__cancelButton: {
                ...theme.smallButton,
                marginTop: theme.spacing(2)
            },
            preparing__paymentSchedule__closeWindow: {
                position: 'absolute',
                top: 0,
                right: theme.spacing(),
            },
            preparing__paymentSchedule__delete: {
                minWidth: '60px',
                borderColor: theme.palette.red[80],
                '&:hover': {
                    backgroundColor: theme.palette.red[60],
                    color: theme.palette.getContrastText(theme.palette.red[100])
                },
                color: theme.palette.red[80],
            },
            subTitle: {
                color: theme.palette.blue[100]
            },
            manage__button: theme.manage__button
        })
}



const customDatePickerStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000',
        }
    })
}


class PaymentSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quotePaymentSchedules: props.quotePaymentSchedules,
            paymentScheduleAmount: '',
            paymentScheduleComment: '',
            paymentScheduleDate: null,
            validatePaymentSchedule: false,
            openConfirmationDialog: false,
            selectedPaymentSchedule: null,
            paymentIndex: null
        }
    }

    componentWillReceiveProps = (nextProps) => {

        this.setState({
            quotePaymentSchedules: nextProps.quotePaymentSchedules,
            paymentScheduleSize: nextProps.quotePaymentSchedules.length
        })
    }

    handleDateChange = date => {
        this.setState({ paymentScheduleDate: date });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addPaymentSchedule = () => {
        const { paymentScheduleAmount,
            paymentScheduleComment,
            paymentScheduleDate,
            selectedPaymentSchedule,
            paymentIndex } = this.state;

        this.setState({
            validatePaymentSchedule: true
        })

        if (paymentScheduleAmount === '' || !paymentScheduleDate) {
            return
        }

        let newPaymentSchedules = [...this.state.quotePaymentSchedules];


        if (selectedPaymentSchedule) {
            newPaymentSchedules[paymentIndex] = {
                ...newPaymentSchedules[paymentIndex],
                dueDate: paymentScheduleDate,
                amount: paymentScheduleAmount,
                comments: paymentScheduleComment
            }
        } else {
            let newPayment = {
                dueDate: paymentScheduleDate,
                amount: paymentScheduleAmount,
                comments: paymentScheduleComment
            };
            newPaymentSchedules = [...newPaymentSchedules, newPayment];
        }

        this.props.updatePaymentSchedules(newPaymentSchedules)

        this.setState({
            paymentIndex: null,
            displaySchedule: false,
            validatePaymentSchedule: false,
            paymentScheduleAmount: '',
            paymentScheduleComment: '',
            paymentScheduleDate: null,
            selectedPaymentSchedule: null
        })
    }

    confirmDeletePaymentSchedule = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    cancelConfirmationDeletePaymentSchedule = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    deletePaymentSchedule = () => {
        const { paymentIndex } = this.state,
            quotePaymentSchedules = [...this.state.quotePaymentSchedules]

        quotePaymentSchedules.splice(paymentIndex, 1)

        this.props.updatePaymentSchedules(quotePaymentSchedules)

        this.setState({
            openConfirmationDialog: false,
            displaySchedule: false,
            paymentIndex: null,
            selectedPaymentSchedule: null,
            paymentScheduleSize: quotePaymentSchedules.length
        })
    }

    editPaymentSchedule = (row) => {
        const { quotePaymentSchedules } = this.state,
            index = quotePaymentSchedules && quotePaymentSchedules.length > 0 ? quotePaymentSchedules.findIndex(ps => ps.id === row.id) : null;
        this.setState({
            displaySchedule: true,
            selectedPaymentSchedule: row,
            paymentIndex: index,
            paymentScheduleAmount: row.amount,
            paymentScheduleComment: row.comments,
            paymentScheduleDate: row.dueDate
        })
    }

    closePaymentSchedule = () => {
        this.setState({
            displaySchedule: false,
            paymentIndex: null,
            selectedPaymentSchedule: null,
            validatePaymentSchedule: false,
            paymentScheduleAmount: '',
            paymentScheduleComment: '',
            paymentScheduleDate: null
        })
    }

    render() {
        const { classes, currencySymbol, fileId } = this.props,
            { validatePaymentSchedule,
                selectedPaymentSchedule,
                displaySchedule,
                quotePaymentSchedules,
                paymentScheduleComment,
                paymentScheduleDate,
                paymentScheduleAmount,
                paymentScheduleSize,
                openConfirmationDialog,
            } = this.state;

        return (
            <GlobalParametersContext.Consumer>
                {({ paymentTerms }) =>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.payment__schedule__title}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.preparatingQuoteInformation.paymentSchedule')}</Typography>
                                    <Button
                                        className={classes.manage__button}
                                        size="small"
                                        variant='outlined'
                                        onClick={() => this.setState({ displaySchedule: !displaySchedule })}>
                                        {i18n.t('quotes.preparatingQuoteInformation.addPaymentSchedule')}
                                    </Button>
                                </div>

                            </Grid>

                            {quotePaymentSchedules && quotePaymentSchedules.length > 0 &&
                                <Grid item xs={12}>
                                    <ReactTable
                                        data={quotePaymentSchedules}
                                        columns={[{
                                            width: 80,
                                            style: {
                                                whiteSpace: 'pre-wrap',
                                                display: 'flex',
                                                alignItems: 'center'
                                            },
                                            Header: i18n.t('quotes.preparatingQuoteInformation.paymentScheduleTable.column1'),
                                            id: 'otherCostTableName',
                                            accessor: row => <div>{moment(row.dueDate).format('MMMM Do ')}</div>
                                        },
                                        {
                                            Header: i18n.t('quotes.preparatingQuoteInformation.paymentScheduleTable.column2'),
                                            style: {
                                                whiteSpace: 'pre-wrap',
                                                display: 'flex',
                                                alignItems: 'center'
                                            },
                                            id: 'otherCostTableComments',
                                            accessor: 'comments'
                                        },
                                        {
                                            Header: i18n.t('quotes.preparatingQuoteInformation.paymentScheduleTable.column3'),
                                            id: 'otherCostTableAmount',
                                            className: classes.preparing__table__cell__rigth,
                                            accessor: row =>
                                                <div>
                                                    <NumberFormat value={row.amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </div>,
                                            width: 80
                                        },
                                        {
                                            Header: '',
                                            id: 'deletePaymentSchedules',
                                            style: {
                                                textAlign: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            },
                                            width: 45,
                                            filterable: false,
                                            accessor: row =>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Link
                                                        variant='button'
                                                        className={classes.payment__schedule__add__button}
                                                        onClick={() => this.editPaymentSchedule(row)}>
                                                        {i18n.t('quotes.preparatingQuoteInformation.editPaymentSchedule')}
                                                    </Link>
                                                </div>
                                        }
                                        ]}
                                        pageSize={paymentScheduleSize}
                                        defaultPageSize={quotePaymentSchedules.length}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize })
                                        }
                                        filtrable={false}
                                        sortable={false}
                                        resizable={false}
                                        className={classNames(classes.preparing__items__table, ' -striped -highlight')}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                    />
                                </Grid>
                            }
                        </Grid>

                        {displaySchedule &&
                            <Dialog
                                maxWidth={'xs'}
                                fullWidth={true}
                                scroll='paper'
                                aria-labelledby="notes_form"
                                open={true}>
                                <DialogTitle id="notes_form" disableTypography={true}>
                                    {fileId ? `${i18n.t('quotes.preparing.file')} ${fileId} - ` : ''}{i18n.t('quotes.preparatingQuoteInformation.addPaymentSchedule')}
                                    <IconButton color="inherit"
                                        onClick={this.closePaymentSchedule}
                                        aria-label="Close" className={classes.preparing__paymentSchedule__closeWindow}>
                                        <Close />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2} alignItems='flex-start'>
                                        <Grid item md={6} sm={6} xs={12} className={classes.preparing__form__gridItem}>
                                            <MuiPickersUtilsProvider
                                                utils={MomentUtils}
                                                className={classes.preparing__form__pickerProvider}>
                                                <InlineDatePicker
                                                    required
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    style={{
                                                        marginTop: '16px',
                                                        width: '100%'
                                                    }}
                                                    fullWidth
                                                    format="MMM DD YYYY"
                                                    error={!paymentScheduleDate && validatePaymentSchedule}
                                                    name='paymentScheduleDate'
                                                    variant="outlined"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.duedate')}
                                                    value={paymentScheduleDate}
                                                    className={classes.preparing__form__datePicker}
                                                    styles={customDatePickerStyles}
                                                    onChange={this.handleDateChange}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12} className={classes.preparing__form__gridItem}>
                                            <TextField autoComplete='off' required
                                                variant="outlined"
                                                id="text_schedule_amount"
                                                error={validatePaymentSchedule && paymentScheduleAmount === ''}
                                                label={i18n.t('quotes.preparatingQuoteInformation.amount')}
                                                className={classes.preparing__form__textField}
                                                fullWidth
                                                onChange={(e) => this.handleChange({
                                                    ...e,
                                                    target: { ...e.target, name: 'paymentScheduleAmount' }
                                                })
                                                }
                                                style={{
                                                    width: '100%'
                                                }}
                                                value={paymentScheduleAmount}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                                                    inputComponent: NumberFormatInputCustom,
                                                }}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.preparing__form__gridItem}>
                                            <TextField
                                                autoComplete='off'
                                                id="text-comment"
                                                name="paymentScheduleComment"
                                                label={i18n.t('quotes.preparatingQuoteInformation.comment')}
                                                className={classes.preparing__form__textField}
                                                value={paymentScheduleComment || ''}
                                                fullWidth
                                                multiline
                                                rows={3}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions style={{ justifyContent: selectedPaymentSchedule ? 'space-between' : 'flex-end' }}>
                                    {selectedPaymentSchedule &&
                                        <Button
                                            className={classes.preparing__paymentSchedule__delete}
                                            onClick={this.confirmDeletePaymentSchedule}>
                                            {i18n.t('quotes.preparatingQuoteInformation.deletePayment')}
                                        </Button>
                                    }
                                    <Button
                                        style={{ minWidth: '100px' }}
                                        color="primary"
                                        variant="contained"
                                        onClick={this.addPaymentSchedule}>
                                        {i18n.t('quotes.preparatingQuoteInformation.addPayment')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }

                        <ConfirmationDialog
                            handleClose={this.cancelConfirmationDeletePaymentSchedule}
                            onConfirm={this.deletePaymentSchedule}
                            message={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmPaymentScheduleMessage')}
                            cancelLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.cancelLabel')}
                            confirmLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmLabel')}
                            open={openConfirmationDialog}
                        />
                    </div>
                }
            </GlobalParametersContext.Consumer>
        )
    }
}
PaymentSchedule.contextType = GlobalParametersContext;

export default withStyles(styles)(PaymentSchedule)
