import React, { Component } from 'react'
import {
    withStyles,
    Typography,
    Snackbar,
    SnackbarContent,
} from '@material-ui/core'

const styles = theme => {
    return (
        {
            root: {
                zIndex: '2000',
                top: '64px',
                [theme.breakpoints.down('xs')]: {
                    top: '56px'
                }
            },
            success: {
                backgroundColor: theme.palette.green[100],
            },
            successLabel: {
                color: '#fff'
            },
            warning: {
                backgroundColor: theme.palette.primary.main,
            },
            warningLabel: {
                color: theme.palette.getContrastText(theme.palette.primary.main)
            },
            error: {
                backgroundColor: theme.palette.red[100],
            },
            errorLabel: {
                color: theme.palette.getContrastText(theme.palette.red[100])
            },
        })
}


class CustomMessages extends Component {

    render() {
        const { type, classes, message } = this.props;

        return (

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.root}
                open={message !== null}
                autoHideDuration={6000}
                onClose={() => {
                    this.props.clearMessage()
                }} >
                <SnackbarContent
                    className={classes[type]}
                    message={<Typography className={classes[`${type}Label`]}>
                        {message}
                    </Typography>} 
                />
            </Snackbar>

        )
    }
}

export default withStyles(styles)(CustomMessages);