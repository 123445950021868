import React, { Component } from 'react'
import {
    Paper,
    Badge,
    Divider,
    Link,
    Popover,
    Typography,
    Checkbox,
    Tabs,
    Tab,
    withStyles
} from '@material-ui/core';
import {
    Notifications,
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from "moment"
import i18n from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../../../actions/filesActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'



import auth from '../../../authentication/auth';
import { NoteForm } from '../../notes'

import API from '../../../lib/api'

const styles = theme => {
    return {
        customLink: theme.customLink,
        rfq__tasks__icon: {
            marginRight: theme.spacing(),
            color: theme.palette.grey[500],
            '&:hover': {
                color: theme.palette.grey[600]
            }
        },
        rfq__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        'rfq__notification__icon--yellow': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        'rfq__notification__icon--red': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        rfq__warning__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        alert__tasks__button__icon: {
            position: 'absolute', right: '0', top: '0',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            },
            [theme.breakpoints.down('xs')]: {
                position: 'absolute', right: '-16px', top: '-8px',
                cursor: 'pointer',
                '&:hover': {
                    filter: 'brightness(1.1);'
                }
            }
        },
        popover__paper: {
            padding: theme.spacing(2),
            maxWidth: '400px'
        },
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            },
        },
        rfq__tasks_badge: {
            top: '5px',
            right: '6px',
            fontSize: '8pt',
            backgroundColor: 'transparent'
        },
        rfq__tasks__actions__container: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: theme.spacing()
        },
        rfq__tasks__list: {
            minWidth: '300px'
        },
        task__due__date: {
            display: 'flex',
            padding: '5px',
            position: 'absolute',
            right: theme.spacing(),
            marginTop: '5px',
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontSize: '8pt'
        },
        "task__due__date--complete": {
            background: '#519839',
            color: theme.palette.getContrastText('#519839')
        },
        "task__due__date--due": {
            background: '#cf513d',
            color: theme.palette.getContrastText('#cf513d')
        },
        "task__due__date--due_soon": {
            background: '#e6c60d',
            color: theme.palette.getContrastText('#e6c60d')
        },
        "task__due__date--default": {
            background: 'rgba(9,45,66,.08)',
            color: theme.palette.getContrastText('rgba(9,45,66,.08)')
        },
        task__checkbox: { padding: '0', marginRight: '8px' },
        task__checkbox__icon: {
            fontSize: '15px',
        },
        'task__checkbox__icon--complete': {
            color: theme.palette.getContrastText('#519839')
        },
        'task__checkbox__icon--due': {
            color: theme.palette.getContrastText('#cf513d')
        },
        'task__checkbox__icon--due--soon': {
            color: theme.palette.getContrastText('#e6c60d')
        },
        tab__root: {
            minWidth: '32%'
        },
        noTasks: {
            textAlign: 'center',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            width: '100%'
        }
    }
}

class TasksShortcut extends Component {
    state = {
        openPopper: false,
        anchorEl: null,
        openNewNote: false,
        row: this.props.row,
        logedInUser: null,
        currentTab: 0,
    }
    componentDidMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    componentWillReceiveProps = nextProps => {
        this.setState({
            row: nextProps.row
        })
    }
    toggleCompleteTask = task => {
        let { row } = this.state;
        let currentTaskIndex = row.tasks.findIndex(t => t.id === task.id);

        this.setState({
            row: {
                ...row,
                tasks: [
                    ...row.tasks.slice(0, currentTaskIndex),
                    {
                        ...task,
                        complete: !task.complete,
                        modified: true
                    },
                    ...row.tasks.slice(currentTaskIndex + 1)
                ]
            },
        }, () => {
            API.Notes.markTask({
                fileId: task.fileId,
                noteId: task.id,
                completed: !task.complete
            }).then(updated => {
                if (updated.status === 'success') {
                    let updatedTask = updated.data.record[0];
                    this.props.filesActions.markFileTask(updatedTask)
                }
            })
        })

    }

    closeNewNote = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    handelNoteSaved = (note) => {
        this.props.filesActions.addNote(note);
        this.setState({
            note: null,
            openNewNote: false
        })
    }

    close = () => {
        this.setState({
            openPopper: null,
            currentTab: 0,
        })
    }

    render() {
        const { classes } = this.props,
            { openPopper,
                anchorEl,
                openNewNote,
                row,
                logedInUser,
                note,
                currentTab } = this.state;

        if (!row) return null;

        let dueTasks = [],
            pendingTasks = [],
            tasks = [];

        let olderTask = row.olderTask;

        if (row.tasks && row.tasks.length > 0) {
            dueTasks = row.tasks.filter(row => (!row.complete || (row.complete && row.modified)) && moment().format('MM-DD-YY') >= moment(row.taskDateTime).format('MM-DD-YY'));
            pendingTasks = row.tasks.filter(row => (!row.complete || (row.complete && row.modified)) && moment().format('MM-DD-YY') < moment(row.taskDateTime).format('MM-DD-YY'));
            let olderTaskObjet = row.tasks
                .filter(row => (!row.complete && moment().format('MM-DD-YY') >= moment(row.taskDateTime).format('MM-DD-YY')))
                .sort((a, b) => {
                    if (a.taskDateTime < b.taskDateTime) return -1;
                    if (a.taskDateTime > b.taskDateTime) return 1;
                    return 0
                })[0];
            if (olderTaskObjet) {
                olderTask = olderTaskObjet.taskDateTime;
            } else {
                olderTask = null;
            }

        }


        tasks = currentTab === 0 ? dueTasks : currentTab === 1 ? pendingTasks : row.tasks;


        return (
            <>
                {!this.props.children ?
                    (
                        <div className={classes.alert__tasks__button__icon}
                            onClick={(e) => this.setState({
                                openPopper: openPopper === row.id ? null : row.id,
                                anchorEl: e.currentTarget
                            })}>
                            <Badge
                                badgeContent={row.tasks && row.tasks.length}
                                classes={{ badge: classes.rfq__tasks_badge }} >
                                <Notifications fontSize="small" className={
                                    !olderTask ? classes.rfq__tasks__icon :
                                        (moment().diff(moment(olderTask), 'days') > 4 ?
                                            classes['rfq__notification__icon--red'] :
                                            moment().diff(moment(olderTask), 'days') >= 0 ?
                                                classes['rfq__notification__icon--yellow'] :
                                                classes.rfq__tasks__icon)}
                                />
                            </Badge>
                        </div>
                    )
                    :
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={(e) => this.setState({
                            openPopper: openPopper === row.id ? null : row.id,
                            anchorEl: e.currentTarget
                        })}>
                        {this.props.children}
                    </div>
                }


                <Popover
                    id={row.id}
                    open={openPopper === row.id}
                    onClose={this.close}
                    anchorEl={anchorEl}
                    anchorOrigin={this.props.anchorOrigin || {
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={this.props.transformOrigin || {
                        vertical: 'center',
                        horizontal: 'left',
                    }} >
                    <Paper className={classes.popover__paper}>


                        {row.tasks && row.tasks.length > 0 &&

                            <div className={classes.rfq__tasks__list}>

                                <Tabs
                                    value={currentTab}
                                    indicatorColor='secondary'
                                    textColor='secondary'
                                    onChange={(e, value) => {
                                        this.setState({
                                            currentTab: value
                                        })
                                    }}
                                >
                                    <Tab
                                        classes={{
                                            root: classes.tab__root
                                        }}
                                        label={i18n.t('tasks.dueTasks')} />
                                    <Tab classes={{
                                        root: classes.tab__root
                                    }}
                                        label={i18n.t('tasks.pendingTasks')} />
                                    <Tab
                                        classes={{
                                            root: classes.tab__root
                                        }}
                                        label={i18n.t('tasks.allTasks')} />
                                </Tabs>
                                <Divider />

                                {tasks.length === 0 && (
                                    <div className={classes.noTasks}>
                                        <Typography color='textSecondary'>
                                            {currentTab === 0 && i18n.t('tasks.noTasksDue')}
                                            {currentTab === 1 && i18n.t('tasks.noTasksPending')}
                                            {currentTab === 2 && i18n.t('tasks.noTasks')}
                                        </Typography>
                                    </div>
                                )}

                                {tasks.map(task => (
                                    <div key={task.id}>
                                        <Typography className={classNames(classes.task__due__date,
                                            task.complete ?
                                                classes['task__due__date--complete'] : (
                                                    moment().format('MM-DD-YY') < moment(task.taskDateTime).format('MM-DD-YY') ? classes["otes__list__task__due__date--default"] :
                                                        moment().format('MM-DD-YY') > moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__due__date--due"] :
                                                            moment().format('MM-DD-YY') === moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__due__date--due_soon"] : null
                                                )
                                        )}
                                            onClick={() => { this.toggleCompleteTask(task) }}
                                        >
                                            <Checkbox
                                                className={classes.task__checkbox}
                                                icon={<CheckBoxOutlineBlank
                                                    className={classNames(classes.task__checkbox__icon,
                                                        task.complete ?
                                                            classes['task__checkbox__icon--complete'] :
                                                            moment().format('MM-DD-YY') > moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__checkbox__icon--due"] :
                                                                moment().format('MM-DD-YY') === moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__checkbox__icon--due_soon"] : null
                                                    )}
                                                />}

                                                checkedIcon={<CheckBoxIcon
                                                    className={classNames(classes.task__checkbox__icon,
                                                        task.complete ?
                                                            classes['task__checkbox__icon--complete'] :
                                                            moment().format('MM-DD-YY') > moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__checkbox__icon--due"] :
                                                                moment().format('MM-DD-YY') === moment(task.taskDateTime).format('MM-DD-YY') ? classes["task__checkbox__icon--due_soon"] : null
                                                    )}
                                                />}
                                                checked={task.complete ? true : false}
                                                color="default"
                                            />
                                            {moment(task.taskDateTime).format('MMM Do')}
                                        </Typography>
                                        {task.followUpUser && task.followUpUser.id && (
                                            <Typography color='textSecondary'>{task.followUpUser.name}</Typography>
                                        )}
                                        <Typography color='textPrimary'>{task.title}</Typography>
                                        <Typography color='textSecondary' noWrap>{decodeURI(task.content)}</Typography>

                                        <Divider />
                                    </div>
                                ))
                                }
                            </div>
                        }

                        {(!row.tasks || (row.tasks && row.tasks.length === 0)) ? (
                            <Link
                                variant='button'
                                className={classes.customLink}
                                onClick={() => {
                                    this.setState({
                                        openPopper: null,
                                        openNewNote: true
                                    });
                                }}>
                                {i18n.t('tasks.createNewTask')}
                            </Link>
                        ) : (
                                <div className={classes.rfq__tasks__actions__container}>
                                    <Link
                                        variant='button'
                                        className={classes.customLink}
                                        onClick={() => {
                                            this.setState({
                                                openPopper: null
                                            });
                                            this.props.handleShowQuote(row.id, {
                                                showTasksSection: true
                                            })
                                        }}>
                                        {i18n.t('tasks.viewFileTasks')}
                                    </Link>
                                    <Link
                                        variant='button'
                                        className={classes.customLink}
                                        onClick={() => {
                                            this.setState({
                                                openPopper: null,
                                                openNewNote: true
                                            });
                                        }}>
                                        {i18n.t('tasks.createNewTask')}
                                    </Link>
                                </div>
                            )}
                    </Paper>
                </Popover>

                {openNewNote &&
                    <NoteForm
                        fileId={row.id}
                        open={openNewNote}
                        note={note}
                        user={logedInUser}
                        section={1}//Seccion de tasks
                        onlyTask={true}
                        addNewNote={this.handelNoteSaved}
                        handleClose={this.closeNewNote}
                    />
                }
            </>
        )
    }
}

TasksShortcut.propTypes = {
    row: PropTypes.object.isRequired
}


function mapDispatchToProps(dispatch) {
    return {
        filesActions: bindActionCreators(filesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TasksShortcut))