import { combineReducers } from 'redux'
import files from './files'
import vendorQuotes from './vendorQuotes'
import file from './file'
import apiStatus from './apiStatus'
import people from './people'
import general from './general'
import clients from './clients'
import client from './client'
import vendors from './vendors'
import vendor from './vendor'
import event from './event'
import events from './events'
import eventsFilters from './eventsFilters'
import account from './account'

const rootReducer = combineReducers({
    files,
    file,
    general,
    people,
    apiStatus,
    vendorQuotes,
    clients,
    client,
    vendors,
    vendor,
    event,
    events,
    eventsFilters,
    account
})

export default rootReducer