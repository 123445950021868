import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'


const Invoices = {
    
    createInvoice: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${body.fileId}/invoices`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateInvoice: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${body.fileId}/invoices/${body.invoiceId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Invoices;