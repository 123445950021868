import React from 'react';
import {
    withStyles,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Link,
    withMobileDialog
} from '@material-ui/core/';
import {
    Close
} from '@material-ui/icons'
import i18n from 'i18next';
import { NotesList } from '../notes'
import { FileDetails } from '../quotes'
import { SlideUp } from '../general/Transitions'
import auth from '../../authentication/auth'
import API from '../../lib/api'
import Permissions from '../../lib/permissions'



const styles = theme => ({
    noteForm__closeWindow: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dashboard__taskDetails__file: {
        color: theme.palette.blue[100],
        textDecoration: 'underline',
        marginRight: theme.spacing(),
    },
    notes__list__task__due__date: {
        display: 'flex',
        padding: '5px 0 5px 10px',
        width: '200px'
    },
    notes__list__task__due__date__label: {
        textDecoration: 'underline',
        fontWeight: 'bold'
    },
    "notes__list__task__due__date--complete": {
        background: '#519839'
    },
    "notes__list__task__due__date--due": {
        background: '#cf513d'
    },
    "notes__list__task__due__date--due_soon": {
        background: '#e6c60d'
    },
    "notes__list__task__due__date--default": {
        background: 'rgba(9,45,66,.08)'
    },
    dashboard__taskDetails__fileLink: {
        ...theme.customLink,
        fontSize: '19px',
        marginRight: '10px'
    },
    dashboard__taskDetails__fileLink__disabled: {
        color: theme.palette.text.primary,
        fontSize: '19px',
        marginRight: '10px'
    },
});


class TaskDetailDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTask: props.selectedTask,
            openNewNote: false,
            logedInUser: null,
            file: null,
            showFileDetail: false
        }
    }

    componentWillMount() {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedTask) {
            API.Files.getFile({
                fileId: nextProps.selectedTask.file.id
            }).then(res => {
                if (res.file) {
                    this.setState({
                        file: res.file
                    })
                }
            })
            this.setState({
                selectedTask: nextProps.selectedTask
            })
        }
    }

    openFileDetails = () => {
        this.setState({
            showFileDetail: true
        })
    }

    closeFileDetails = () => {
        this.setState({
            showFileDetail: false
        })
    }

    onExited = () => {
        this.setState({
            selectedTask: null,
            openNewNote: false,
            logedInUser: null,
            file: null,
            showFileDetail: false
        })
    }

    render() {
        const { classes, width } = this.props,
            { selectedTask, logedInUser, file, showFileDetail } = this.state;

        if (!selectedTask) return null

        return (
            <div>
                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    fullScreen={width === 'xs' ? true : false}
                    style={width !== 'xs' ? { height: '70vh' } : {}}
                    onClose={this.props.onClose}
                    scroll='body'
                    aria-labelledby="taskDetails"
                    onExited={this.onExited}
                    open={this.props.open}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="notes_form" disableTypography={true}>
                        {selectedTask && selectedTask.file.id ? `${i18n.t("dashboard.taskDetails.file")} ${selectedTask.file.id} / ` : ''}  {`${i18n.t("dashboard.taskDetails.title")} ${selectedTask ? selectedTask.id : ''}`}
                        <IconButton color="inherit"
                            onClick={this.props.onClose}
                            aria-label="Close" className={classes.noteForm__closeWindow}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ padding: '16px', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', margin: '10px' }}>
                            <Link
                                variant='button'
                                className={Permissions.checkSensitiveInformationPermission() ? classes.dashboard__taskDetails__fileLink : classes.dashboard__taskDetails__fileLink__disabled}
                                onClick={() => {
                                    if (Permissions.checkSensitiveInformationPermission()) {
                                        this.openFileDetails()
                                    }
                                }}
                                underline={Permissions.checkSensitiveInformationPermission() ? 'hover' : 'never'}>
                                {`${i18n.t("dashboard.taskDetails.file")} ${selectedTask.file.id}`}
                            </Link>
                            <Typography variant='h6' color='textPrimary' noWrap>
                                {` - ${selectedTask.client.name}`}
                            </Typography>
                        </div>

                        <div style={{ padding: '0 8px 32px 8px', overflow: 'scroll', height: '100%' }}>
                            {file &&
                                <NotesList
                                    file={file}
                                    olderTask={selectedTask.olderTask}
                                    tasksCount={selectedTask.notesCount}
                                    user={logedInUser}
                                    showTasksSection={true}
                                    dashboardSummary={true}
                                />
                            }
                        </div>

                    </DialogContent>

                </Dialog>

                <FileDetails
                    open={showFileDetail}
                    onClose={this.closeFileDetails}
                    fileId={file ? file.id : null}
                    olderTask={selectedTask.olderTask}
                    notesCount={selectedTask.notesCount}
                    status={file ? file.status : null}
                />

            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(TaskDetailDialog));