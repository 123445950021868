import React, { Component } from 'react'
import {
    Button,
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Grid,
    TextField,
    DialogActions,
    CircularProgress,
    Typography
} from '@material-ui/core'
import {
    Close,
    Warning
} from '@material-ui/icons';
import i18n from 'i18next';
import { CustomMessages } from '../../general';
import { SlideUp } from '../../general/Transitions'
import API from '../../../lib/api'

const style = theme => (
    {
        noteForm: {
            display: 'flex',
            flexDirection: 'column'
        },
        noteForm__noteTitle: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        noteForm__noteContent: {},
        noteForm__input: {
        },
        note__actions__cancelBtn: {
            width: '110px'
        },
        buttonSpinner: theme.buttonSpinner,
        button__wrapper: {
            margin: theme.spacing(),
            position: 'relative'
        },
        noteForm__closeWindow: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
        tab__container: {
            paddingTop: theme.spacing(3)
        },
        dialog__buttons__container__root: {
            padding: 0,
            justifyContent: 'space-between'
        },
        noteForm__warningMessage: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        warning__message__icon: {
            marginRight: theme.spacing(),
            fontSize: '14pt',
            color: theme.palette.orange[100],
        }
    }
)



class NoteEditDocuments extends Component {
    state = {
        showAddNote: false,
        validateForm: false,
        fileNoteContent: '',
        apiErrorMessage: null,
        saving: false
    }

    validateNoteForm = () => {
        this.setState({
            validateForm: true
        })
        const { fileNoteContent } = this.state;

        if (fileNoteContent === '') return false

        return true
    }

    handleNote = () => {
        if (this.validateNoteForm()) {
            this.setState({
                validateForm: false,
                saving: true
            })

            const { fileId, vendorQuoteId, title } = this.props,
                  { fileNoteContent } = this.state;

            let body = {
                id: null,
                title,
                content: fileNoteContent,
                isPrivate: true
            }

            if (vendorQuoteId) {
                body.vendorQuoteId = vendorQuoteId
            }

            API.Notes.createNote({ fileId, note: body }).then(res => {
                if (res.id) {
                    this.setState({
                        saving: false,
                        fileNoteContent: ''
                    },_=> {
                        this.props.onSuccess()
                        this.props.closeNote()
                    })
                } else {
                    this.setState({
                        saving: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        }
    }

    resetForm=()=>{
        this.setState({
            fileNoteContent: ''
        })
    }

    render() {
        const { fileId, classes, open, message } = this.props,
            { fileNoteContent,
                apiErrorMessage,
                saving,
                validateForm } = this.state;

        return (
            <Dialog
                fullScreen={false}
                maxWidth={'sm'}
                fullWidth={true}
                classes={{
                    paper: classes.dialog__paper
                }}
                scroll='paper'
                aria-labelledby="notes_form"
                open={open}
                onExit={this.resetForm}
                TransitionComponent={SlideUp}
            >
                <DialogTitle id="notes_form" disableTypography={true}>
                    {fileId ? `${i18n.t("notes.form.file")} ${fileId} / ` : ''} {i18n.t("notes.form.title")}
                    <IconButton color="inherit"
                        onClick={this.props.closeNote}
                        aria-label="Close" className={classes.noteForm__closeWindow}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.tab__container}>
                        <Typography className={classes.noteForm__warningMessage}>
                            <Warning className={classes.warning__message__icon} />
                            {message}
                        </Typography>
                        <form className={classes.noteForm} autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' required
                                        disabled={saving}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.noteForm__input
                                        }}
                                        onChange={(e) => {
                                            this.setState({
                                                fileNoteContent: e.target.value
                                            })
                                        }}
                                        error={validateForm && fileNoteContent === '' ? true : false}
                                        className={classes.noteForm__noteContent}
                                        multiline
                                        fullWidth
                                        rows="4"
                                        type="text"
                                        id="content"
                                        variant="outlined"
                                        defaultValue={fileNoteContent}
                                        label={i18n.t("notes.editQuoteContent")}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>

                    <CustomMessages
                        type={'error'}
                        message={apiErrorMessage}
                        clearMessage={() => this.setState({
                            apiErrorMessage: null
                        })}
                    />

                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}
                    classes={{
                        root: classes.dialog__buttons__container__root
                    }}>
                    <div className={classes.button__wrapper}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={saving}
                            onClick={this.handleNote}>
                            {i18n.t("notes.form.createBtn")}
                        </Button>
                        {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(style)(NoteEditDocuments);
