import React, { Component } from 'react'
import {
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography
} from '@material-ui/core/';
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import API from '../../lib/api'
import i18n from 'i18next';
import auth from '../../authentication/auth';

const styles = theme => ({
    checkAll: {
        marginLeft: '-5px'
    },
    listItem: {
        height: '30px',
        paddingLeft: '10px',
        paddingRight: '5px'
    },
    listItemText: {
        paddingLeft: 0,
        paddingRight: '10px'
    }
});


class Users extends Component {
    state = {
        checked: [],
        checkedAll: false,
        loading: true,
        users: null,
    }

    componentDidMount = () => {
        API.Users.getUsers({ agenda: true }).then(res => {
            auth.getAuthenticatedUser().then(user => {
                const currentUserId = parseInt(user.attributes['custom:userID']);
                const { checked } = this.state;
                if (currentUserId) {
                    const currentIndex = checked.indexOf(currentUserId);
                    const newChecked = [...checked];

                    if (currentIndex === -1) {
                        newChecked.push(currentUserId);
                    }
                    this.setState({
                        checked: newChecked,
                    });
                    this.props.selectUsers(newChecked);
                }
            })
            this.setState({
                loading: false,
                users: res.data.records.users.filter(user => user.userActive)
            })
        }).catch(error => {
            this.setState({
                loading: false,
                users: null
            })
        })
    }

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
        this.props.selectUsers(newChecked);
    };

    handleAllChange = (e) => {
        const { users, checked } = this.state;
        var newChecked = [];

        if (users.length !== checked.length) {
            newChecked = users.map(user => {
                return user.userId
            })
        }

        this.setState({
            checked: newChecked,
            checkedAll: e.target.checked
        });
        this.props.selectUsers(newChecked);
    }

    render() {
        const { classes } = this.props;
        const {
            loading,
            users,
            checkedAll,
            checked
        } = this.state;
        return (
            <div>
                {loading && (
                    <CircularProgress color='secondary' />
                )}
                {!loading && users && users.length > 0 &&
                    <div>
                        <List>
                            <ListItem>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.checkAll}
                                        control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlank style={{ fontSize: '15px' }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: '15px' }} />}
                                                checked={checkedAll}
                                                onChange={this.handleAllChange}
                                            />
                                        }
                                        label={i18n.t("calendar.checkAllUsers")}
                                    />
                                </FormGroup>
                            </ListItem>
                            {users.map(user => (
                                <ListItem className={classes.listItem}
                                    key={user.userId}
                                    dense button
                                    onClick={this.handleToggle(user.userId)}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank style={{ fontSize: '15px' }} />}
                                        checkedIcon={<CheckBoxIcon style={{ fontSize: '15px' }} />}
                                        checked={checked.indexOf(user.userId) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText className={classes.listItemText} primary={user.userName} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                }
                {!loading && (!users || users.length === 0) && (
                    <Typography>{i18n.t("calendar.thereAreNoUsers")}</Typography>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(Users);