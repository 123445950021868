// Actions types

export const FETCHING = "FETCHING"
export const FETCHED = "FETCHED"


//Actions
export function fetching() {
    return {
        type: FETCHING
    }
}

export function fetched() {
    return {
        type: FETCHED
    }
}
