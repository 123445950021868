import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'


const Items = {
    getQuotedItem: (args = {}) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/items/${encodeURIComponent(args.partNumber)}?count=${config.itemsLookUp.peviousQuotesCount}&returnOneResult=${args.returnOneResult}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getSuggestedVendors: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/items/suggestedVendors/${args.fileId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Items;