import React, { Component } from 'react'
import {
    Paper,
    Button,
    withStyles,
    Snackbar,
    SnackbarContent,
    Typography,
    Tabs,
    Tab,
} from '@material-ui/core'
import {
    Send, Drafts, DoneOutline, Block, Sync
} from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as vendorsActions from '../../../actions/vendorsActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'

// import API from '../../../lib/api'
import { VendorQuoteForm, VendorQuoteCard } from '.'
import { SuggestedVendors } from '../../itemsLookup';

const styles = theme => ({
    newQuoteButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing(3)}px`
    },
    newQuoteButtonContainerRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: ` 0 ${theme.spacing()}px ${theme.spacing(3)}px ${theme.spacing()}px`
    },
    vendorQuoteContainer: {
        padding: theme.spacing(2),
    },
    quoteDetailsActiveSection: {
        position: 'relative'
    },
    vendor__requests__title__container: {
        padding: theme.spacing(),
        position: 'absolute'
    },
    vendor__requests__focused__section: {
        zIndex: 2,
        position: 'relative'
    },
    vendor__requests__focus__hover: {
        backgroundColor: '#000',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        opacity: '0.3',
        zIndex: 1
    },
    vendorQuotes__tab: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100]
    },
    vendorQuotes__tab__ready: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        marginLeft: 'auto'
    },
    'vendorQuotes__tab--selected': {
        backgroundColor: '#fff',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: 'none',
        color: theme.palette.text.primary,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        zIndex: 10
    },
    vendorQuotes__tabs__versions: {
        padding: `16px ${theme.spacing()}px 0`
    },
    vendorQuotes__tabs: {
        margin: `0 ${theme.spacing()}px`
    },
    vendorQuotes__tabs__flexContainer: {
    },
    vendorQuotes__tabs__activeIndicator: {
        backgroundColor: 'transparent',
        zIndex: 11,
        marginLeft: '1px',
        width: 'calc(100% - 2px) !important'
    },
    legend__root__row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '230px',
        [theme.breakpoints.down('sm')]: {
            width: '200px'
        }
    },
    legend__root__row__versions: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '150px',
        [theme.breakpoints.down('sm')]: {
            width: '100px'
        }
    },
    vendor__status__icon__preparing: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    vendor__status__icon__requested: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.primary.main,
    },
    vendor__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.primary.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    snackbar__root__style: {
        top: '64px',
        [theme.breakpoints.down('xs')]: {
            top: '56px'
        }
    },
    vendorQuotes__rfqItems__container: {
        display: 'flex',
        alignItems: 'center'
    },
    vendor__quote__expand__colapse__button: {
        marginLeft: (theme.spacing() / 2),
        padding: theme.spacing()
    },
    vendorQuotes__rfqItems__paper: {
        // margin: theme.spacing(),
    },
    quoteitems__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        marginTop: theme.spacing(),
        border: `solid thin ${theme.palette.grey[300]}`
    },
    quoteitems__table__row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    quoteitems__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    quoteitems__table__descriptionResumed: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: '95%',
        cursor: 'pointer'
    },
    quoteitems__table__descriptionExtended: {
        width: '100%',
        cursor: 'pointer'
    },
    quoteitems__table__manufacturer: {
        color: theme.palette.text.secondary
    },
    quoteitems__table__references: {
        color: theme.palette.text.secondary
    },
    vendorRFQ__includedItem__mark: {
        width: '20px',
        height: '20px',
        borderRadius: '50%'
    },
    "item--included": {
        background: theme.palette.green[100]
    },
    "item--noIncluded": {
        background: theme.palette.red[100]
    },
    missingToQuote: {
        color: theme.palette.secondary.main,
        marginLeft: theme.spacing() / 2,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    missingItems__warning__icon: {
        marginLeft: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    subaction__bar__actionButton: {
        marginTop: theme.spacing() / 2,
        padding: '5px',
        width: '190px',
        '&:disabled': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    subaction__bar__actionButton__next: {
        backgroundColor: "#009688",
        color: 'white',
        width: '190px',
        '&:hover': {
            backgroundColor: "#4fccc4",
            filter: 'brightness(1.1);'
        }
    },
})



class VendorQuoteRequests extends Component {
    state = {
        showCreateVendorQuote: false,
        vendorQuoteItems: [],
        file: this.props.file,
        activeEditCard: null,
        focusedVendorQuote: null,
        vendors: this.props.vendors || [],
        editable: this.props.editable,
        selectedTab: 0,
        selectedVersionTab: 0,
        changeStatusMessage: null,
        updatedVendorQuote: null,
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.file) {
            this.setState({
                file: nextProps.file,
                editable: nextProps.editable,
            })

        }

        if (nextProps.focusedVendorQuote !== this.state.focusedVendorQuote) {
            let cardIndex = nextProps.file.vendorQuotes ? nextProps.file.vendorQuotes.findIndex(vq => vq.id === nextProps.focusedVendorQuote) : -1;
            if (cardIndex > -1) {
                this.setState({
                    focusedVendorQuote: nextProps.focusedVendorQuote,
                    activeEditCard: cardIndex
                })
            }
        }
        if (nextProps.vendors && nextProps.vendors.length && this.state.vendors.length === 0) {
            this.setState({
                vendors: nextProps.vendors
            })
        }

    }

    handleCreateNewQuote = () => {
        this.setState({
            showCreateVendorQuote: true
        })
    }

    handleCloseCreateVendorQuote = () => {
        this.setState({
            showCreateVendorQuote: false
        })
        this.props.generalActions && this.props.generalActions.showAddVendorQuote(false);
    }

    updateVendorQuote = (args) => {
        const { file } = args
        this.props.updateVendorQuote(file)
    }

    deleteVendorQuote = (vendorQuote) => {
        const { file } = this.state;

        let newFile = { ...file },
            index = newFile.vendorQuotes.findIndex(vq => vq.id === vendorQuote.id);

        newFile.vendorQuotes.splice(index, 1)
        this.setState({
            file: newFile,
            selectedTab: newFile.vendorQuotes.length > 0 ? newFile.vendorQuotes.length - 1 : 0
        })
    }

    handleChange = (e, value) => {
        this.setState({
            selectedTab: value
        });
    }

    handleChangeVersionTab = (e, value) => {
        this.setState({
            selectedVersionTab: value
        });
    }

    showError = ({ changeStatusMessage }) => {
        this.setState({
            changeStatusMessage: changeStatusMessage
        })
    }

    calculateNoItemsRequested = () => {
        const { file } = this.state;

        let rfqItemsIds = file.items.map((item, i) => { return item.id }),
            includedInQuote = [],
            quotedItems = 0,
            noQuotedItems = 0;

        file.vendorQuotes.filter(vq => !vq.parentId).forEach(vq => {
            let selectedQuote = vq;

            if(vq.quotesVersions && vq.quotesVersions.length > 0){
                selectedQuote = vq.quotesVersions.reduce(
                    (max, vqVersion) => (vqVersion.version > max.version ? vqVersion : max),
                    vq.quotesVersions[0]
                );
            }

            includedInQuote = includedInQuote.concat(selectedQuote.items.filter(item => rfqItemsIds.includes(item.rfqItem.id)))
        })

        let vRFQItemsIds = includedInQuote.map(item => { return item.rfqItem.id })

        rfqItemsIds.forEach(item => {
            if (vRFQItemsIds.includes(item)) {
                quotedItems = quotedItems + 1
            } else {
                noQuotedItems = noQuotedItems + 1
            }
        })

        return noQuotedItems
    }

    render() {
        const {
            classes,
            // theme,
            focusedVendorQuote,
            general
        } = this.props,
            { file,
                editable,
                showCreateVendorQuote,
                vendors,
                selectedTab,
                selectedVersionTab,
                changeStatusMessage,
            } = this.state;

        let firstReadyVQ = false,
            firstReadyVQVersion = false;

        if (!file) return null;

        let { showAddVendorQuote } = general;

        //Ordenamos por estatus
        let vendorQuotes = file.vendorQuotes && file.vendorQuotes.length > 0 ? file.vendorQuotes.sort((a, b) => {
            return a.status.id - b.status.id;
        }) : [];

        let parentsQuotes = vendorQuotes.filter(vq => !vq.parentId),
            quotesVersions = vendorQuotes.filter(vq => vq.parentId === parentsQuotes[selectedTab].id)

        if(quotesVersions.length > 0){
            quotesVersions.push(parentsQuotes[selectedTab])
        }

        quotesVersions = quotesVersions.sort((a, b) => {
            if(a.status.id === b.status.id){
                if(a.version < b.version) return 1
                if(a.version > b.version) return -1
                return 0
            } else {
                return a.status.id - b.status.id;
            }
        });

        let maxVersion = 0;
        maxVersion = quotesVersions.reduce(
            (max, vqVersion) => (vqVersion.version > max ? vqVersion.version : max),
            0
        );

        return (
            <>
                <Paper className={editable ? `${classes.quoteDetailsActiveSection} ${classes.vendorQuoteContainer}` : classes.vendorQuoteContainer}>
                    {file.status === 2 && (
                        <SuggestedVendors />
                    )}
                    <div className={classes.vendor__requests__title__container}>
                        {vendorQuotes && vendorQuotes.length !== 0 &&
                            <Typography variant='body1'>{i18n.t('quotes.vendorQR.title')}</Typography>
                        }
                    </div>

                    {/* crear nuevo vendor quote */}
                    <VendorQuoteForm
                        open={showCreateVendorQuote || (showAddVendorQuote ? true : false)}
                        vendors={vendors}
                        file={file}
                        updateVendorQuote={this.updateVendorQuote}
                        deleteVendorQuote={this.deleteVendorQuote}
                        handleClose={this.handleCloseCreateVendorQuote} />

                    {editable && (
                        <div className={(vendorQuotes && vendorQuotes.length === 0) ? classes.newQuoteButtonContainer : classes.newQuoteButtonContainerRight}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleCreateNewQuote}>
                                {i18n.t("quotes.vendorQR.newrequestbutton")}
                            </Button>
                        </div>
                    )}

                    {vendorQuotes && vendorQuotes.length !== 0 &&
                        <div>
                            {/* <Hidden xsDown implementation="css"> */}
                            <Tabs
                                className={classes.vendorQuotes__tabs}
                                value={selectedTab}
                                onChange={this.handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                classes={{
                                    indicator: classes.vendorQuotes__tabs__activeIndicator,
                                    flexContainer: classes.vendorQuotes__tabs__flexContainer,
                                    root: classes.file__tabs__root,
                                }}
                                variant={vendorQuotes.filter(vq => !vq.parentId).length > 4 ? "scrollable" : "standard"}
                                scrollButtons="auto"
                            >
                                {/* TABS vendor quotes ORIGINALES  */}
                                {parentsQuotes.map((vq, i) => {
                                    //Logica para alinear los vendor requests a la derecha. 
                                    //Se debe agregar una clase distinta solo al primer vendor request tab ready
                                    let alignToR = false,
                                        isReady = false;

                                    if(vq.quotesVersions.filter(version => version.status.id === 3).length > 0){
                                        isReady = true
                                    }

                                    if ((vq.status.id === 3 || vq.status.id === 4 || isReady) && !firstReadyVQ) {
                                        firstReadyVQ = true;
                                        alignToR = true;
                                    }
                                    return (<Tab
                                        key={i}
                                        label={(
                                            <div className={classes.legend__root__row}>
                                                {vq.status.id === 1 && (
                                                    <Drafts className={classes.vendor__status__icon__preparing} />
                                                )}
                                                {vq.status.id === 2 && !isReady && (
                                                    <Send className={classes.vendor__status__icon__requested} />
                                                )}
                                                {(vq.status.id === 3 || isReady)&& (
                                                    <DoneOutline className={classes.vendor__status__icon__ready} />
                                                )}
                                                {vq.status.id === 4 && (
                                                    <Block style={{ color: '#ccc' }} className={classes.vendor__status__icon__ready} />
                                                )}
                                                <Typography noWrap >{`${vq.id} - ${vq.vendor.name}`}</Typography>
                                            </div>)}
                                        classes={{
                                            root: classNames(classes.vendorQuotes__tab, alignToR ? classes.vendorQuotes__tab__ready : ''),
                                            selected: classes['vendorQuotes__tab--selected']
                                        }}
                                    />)
                                })}
                            </Tabs>

                            { quotesVersions.length === 0 ?
                                <VendorQuoteCard
                                    deleteVendorQuote={this.deleteVendorQuote}
                                    editable={editable}
                                    vendorQuote={parentsQuotes[selectedTab]}
                                    file={file}
                                    vendors={vendors}
                                    showError={this.showError}
                                    updateVendorQuote={this.updateVendorQuote}
                                    currencySymbol={file.currencySymbol}
                                    showEditMode={focusedVendorQuote === parentsQuotes[selectedTab].id}
                                    disabledExit={this.props.disabledExit}
                                />
                            :
                                <Paper style={{margin: '0 8px'}}>

                                    <Tabs
                                        className={classes.vendorQuotes__tabs__versions}
                                        value={selectedVersionTab}
                                        onChange={this.handleChangeVersionTab}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        classes={{
                                            indicator: classes.vendorQuotes__tabs__activeIndicator,
                                            flexContainer: classes.vendorQuotes__tabs__flexContainer,
                                            root: classes.file__tabs__root,
                                        }}
                                        variant={quotesVersions.length > 4 ? "scrollable" : "standard"}
                                        scrollButtons="auto"
                                    >
                                        {quotesVersions.map((vqVersion, i) => {
                                            //Logica para alinear los vendor requests a la derecha. 
                                            //Se debe agregar una clase distinta solo al primer vendor request tab ready
                                            let alignToR = false,
                                                requoted = false;

                                            if(vqVersion.version < maxVersion){
                                                requoted = true
                                            }

                                            if ((vqVersion.status.id === 3 || vqVersion.status.id === 4 || requoted) && !firstReadyVQVersion) {
                                                firstReadyVQVersion = true;
                                                alignToR = true;
                                            }
                                            return (
                                                <Tab
                                                    key={i}
                                                    label={(
                                                        <div className={classes.legend__root__row__versions}>
                                                            { requoted ? 
                                                                <Sync style={{ color: '#ccc' }} className={classes.vendor__status__icon__ready} />
                                                            : 
                                                                <>
                                                                    {vqVersion.status.id === 1 && (
                                                                        <Drafts className={classes.vendor__status__icon__preparing} />
                                                                    )}
                                                                    {vqVersion.status.id === 2 && (
                                                                        <Send className={classes.vendor__status__icon__requested} />
                                                                    )}
                                                                    {vqVersion.status.id === 3 && (
                                                                        <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                    )}
                                                                    {vqVersion.status.id === 4 && (
                                                                        <Block style={{ color: '#ccc' }} className={classes.vendor__status__icon__ready} />
                                                                    )}
                                                                </>
                                                            }
                                                           
                                                            <Typography noWrap >{`${parentsQuotes[selectedTab].id} Rev-${vqVersion.version}`}</Typography>
                                                        </div>)}
                                                    classes={{
                                                        root: classNames(classes.vendorQuotes__tab, alignToR ? classes.vendorQuotes__tab__ready : ''),
                                                        selected: classes['vendorQuotes__tab--selected']
                                                    }}
                                                />
                                            )
                                        })}
                                    </Tabs>

                                    <VendorQuoteCard
                                        deleteVendorQuote={this.deleteVendorQuote}
                                        editable={editable}
                                        vendorQuote={quotesVersions[selectedVersionTab] }
                                        file={file}
                                        vendors={vendors}
                                        showError={this.showError}
                                        updateVendorQuote={this.updateVendorQuote}
                                        currencySymbol={file.currencySymbol}
                                        showEditMode={focusedVendorQuote === quotesVersions[selectedVersionTab].id}
                                        disabledExit={this.props.disabledExit}
                                    />
                                    
                                </Paper>
                            }


                            <Snackbar
                                className={classes.snackbar__root__style}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={changeStatusMessage !== null}
                                autoHideDuration={4000}
                                onClose={() => {
                                    this.setState({
                                        changeStatusMessage: null
                                    });
                                }} >
                                <SnackbarContent
                                    className={classes.error}
                                    message={<Typography className={classes.errorLabel}>{changeStatusMessage}</Typography>} />
                            </Snackbar>

                        </div>
                    }
                </Paper>

            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(VendorQuoteRequests));