import React, { Component } from 'react'
import {
    Typography,
    Divider,
    Grid,
    withMobileDialog,
    TextField,
    Button,
    Link,
    Radio,
    CircularProgress,
    FormControlLabel,
    Switch,
    withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import ReactTable from "react-table"
import i18n from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as clientsActions from '../../actions/clientsActions'
import * as apiStatusActions from '../../actions/apiStatusActions'

import API from '../../lib/api';

const styles = theme => ({
    client__form__input: {
        padding: theme.spacing() - 1,
        fontSize: '0.8em'
    },
    client__form__textField: {
        margin: `${theme.spacing()}px 0`
    },
    buttonSpinner: theme.buttonSpinner,
    "client__editSections__button--disabled": {
        backgroundColor: theme.palette.textPrimary.main
    },
    cancelDelete__buttons__container: {
        display: 'flex'
    },
    client__deleteClientButton: {
        marginRight: theme.spacing(),
        borderColor: theme.palette.red[80],
        '&:hover': {
            backgroundColor: theme.palette.red[60],
            color: theme.palette.getContrastText(theme.palette.red[100])
        },
        marginLeft: theme.spacing(),
        color: theme.palette.red[80],
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
    client__editSections__saveButton: {
    },
    client__createButton__container: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: theme.spacing(2)
    },
    success: {
        backgroundColor: theme.palette.green[100],
    },
    error: {
        backgroundColor: theme.palette.primary.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    client__form__bottomButtons__grid: {
        marginTop: theme.spacing()
    },
    dialog__header__client__label: {
        fontSize: '9pt',
    },
    customLink: theme.customLink,
    clientcontacts__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    contacts__table__cell: {
        display: 'flex',
        alignItems: 'center'
    },
    client__actions__divider: {
        marginTop: theme.spacing()
    },
    client__actions__container: {
        marginTop: theme.spacing(2)
    },
    client__action__button__container: {
        textAlign: 'center'
    },
    contacts__table__container: {
        maxHeight: '500px',
        overflowY: 'auto'
    }
})

const defaultClientContact = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: 0
}



class ManageClientContactsPopup extends Component {
    state = {
        selectedClientContact: null,
        savingClientContact: false,
        createClientContact: false,
        editClientContact: false,
        validateClientContact: false,
        validateErrorMessage: null,
        eventClientContactId: this.props.eventClientContactId,
        clientContacts: this.props.client ? this.props.client.contacts : [],
        client: {},
        currentSelectedContactId: this.props.selectedClientContact ? this.props.selectedClientContact.id : null,
        deleting: false
    }

    handleClose = () => {
        const { onClose } = this.props;
        this.props.generalActions.showCreateClientContact(false)
        this.setState({
            selectedClientContact: this.props.selectedClientContact || null,
            savingClientContact: false,
            createClientContact: false,
            editClientContact: false,
            clientContacts: this.props.client ? this.props.client.contacts : [],
            validateClientContact: false,
            validateErrorMessage: null,
        }, () => {
            onClose();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.general.showCreateClientContact) {
            this.setState({
                createClientContact: true
            })
        }
        if (nextProps.client) {
            this.setState({
                client: nextProps.client,
                clientContacts: nextProps.client.contacts
            });
        }

        if (nextProps.selectedClientContact || nextProps.eventClientContactId) {
            this.setState({
                selectedClientContact: nextProps.selectedClientContact.id ? nextProps.selectedClientContact : nextProps.client.contacts ? nextProps.client.contacts.find(contact => contact.id === nextProps.eventClientContactId) : defaultClientContact,
                currentSelectedContactId: nextProps.selectedClientContact.id ? nextProps.selectedClientContact.id : nextProps.eventClientContactId
            })
        }
    }

    handleChangeClientContact = () => {
        const { clientContacts, currentSelectedContactId } = this.state;
        let contact = clientContacts.find(c => c.id === currentSelectedContactId);
        this.props.changeClientContact && this.props.changeClientContact(contact)
        this.handleClose();
    }
    handleCancel = () => {
        this.setState({
            selectedClientContact: this.state.createClientContact ? null : this.state.selectedClientContact,
            editClientContact: false,
            validateClientContact: false,
            createClientContact: false,
            validateErrorMessage: false
        })
        if (this.props.general.showCreateClientContact) {
            this.handleClose();
        }
    }

    handleEditClientContact = (contact) => {
        this.setState({
            selectedClientContact: contact,
            editClientContact: true
        })
    }

    validateFormData = () => {
        const { selectedClientContact } = this.state;
        this.setState({
            validateClientContact: true
        })

        if (selectedClientContact.firstName === '' || selectedClientContact.lastName === '') {
            this.setState({
                validateErrorMessage: i18n.t('clients.contactFormInputsValidationError')
            })
            return false
        }
        return true;
    }
    deleteContact = () => {
        const { selectedClientContact } = this.state,
            { client } = this.props;
        this.setState({ savingClientContact: true }, () => {
            //Delete client contact
            API.Clients.deleteClientContact({
                clientContact: {
                    ...selectedClientContact,
                    clientId: client.id
                }
            }).then(res => {
                if (res.status === 'success') {
                    this.props.clientsActions.deleteClientContact({
                        ...selectedClientContact,
                        clientId: client.id
                    });
                    this.handleClose();
                } else {
                    this.setState({
                        savingClientContact: false
                    })

                    this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)
                }
            })
        })
    }
    handleSaveClientContact = () => {
        const { selectedClientContact, clientContacts } = this.state,
            { client } = this.props;
        let newClientsContacs = [...clientContacts];
        if (this.validateFormData()) {
            if (!selectedClientContact.id) {
                this.setState({
                    validateErrorMessage: null,
                    savingClientContact: true,
                    validateClientContact: false
                })

                selectedClientContact.clientId = client.id;

                API.Clients.createClientContact({ clientContact: selectedClientContact }).then(res => {
                    if (res.id) {
                        this.props.clientsActions.addClientContact({ ...res, clientId: client.id });

                        newClientsContacs[newClientsContacs.length] = res;

                        this.setState({
                            savingClientContact: false,
                            createClientContact: false,
                            selectedClientContact: res,
                            clientContacts: newClientsContacs,
                        })
                        if (this.props.general.showCreateClientContact) {
                            this.setState({
                                currentSelectedContactId: res.id
                            }, () => {
                                this.handleChangeClientContact();
                            })
                        }
                        
                        this.handleClose();

                    } else {
                        this.setState({
                            savingClientContact: false
                        })

                        this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)
                    }
                })
            } else {
                this.setState({
                    validateErrorMessage: null,
                    savingClientContact: true,
                    validateClientContact: false
                })

                selectedClientContact.clientId = client.id;

                API.Clients.updateClientContact({ clientContact: selectedClientContact }).then(res => {
                    if (res.id) {
                        this.props.clientsActions.updateClientContact({ ...res, clientId: client.id });
                        let newClientContacs = [...clientContacts],
                            index = newClientContacs.findIndex(c => c.id === selectedClientContact.id);
                        if (index) {
                            newClientContacs = [...clientContacts.slice(0, index), { ...res }, ...clientContacts.slice(index + 1)]
                        }
                        this.setState({
                            editClientContact: false,
                            selectedClientContact: res,
                            clientContacts: newClientContacs,
                            savingClientContact: false,
                        }, () => {
                            if (this.props.selectedClientContact && this.state.selectedClientContact.id === this.props.selectedClientContact.id) {
                                this.props.updateRFQClientContact && this.props.updateRFQClientContact(this.state.selectedClientContact)
                            }
                        })
                    } else {
                        this.setState({
                            savingClientContact: false
                        })
                        
                        this.props.showApiErrorMessage && this.props.showApiErrorMessage(`${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`)
                    }
                })
            }
        }
    }

    handleCreateClientContactButtonClick = () => {
        this.setState({
            validateClientContact: false,
            createClientContact: true,
            selectedClientContact: defaultClientContact
        });
    }

    handleChangeRadio = contactId => {
        this.setState({ currentSelectedContactId: contactId });
    }

    render() {

        let {
            createClientContact,
            editClientContact,
            clientContacts,
            selectedClientContact,
            validateErrorMessage,
            validateClientContact,
            savingClientContact,
            currentSelectedContactId,
            deleting,
        } = this.state, {
            classes,
            client,
            theme,
            fromCompanies
        } = this.props;


        if (!client) return null;

        return (

            <>
                {!editClientContact && !createClientContact && clientContacts && clientContacts.length > 0 && (
                    <>
                        <div className={classes.contacts__table__container}>
                            <ReactTable
                                data={clientContacts}
                                getTdProps={(state, rowInfo, column, instance) => (
                                    {
                                        style: {
                                            background: (rowInfo ?
                                                (this.props.selectedClientContact.id === rowInfo.original.id ? theme.palette.blue[10] :
                                                    null) : null)
                                        }
                                    }
                                )}
                                columns={[
                                    {
                                        Header: i18n.t('quotes.quoteRequest.contactFirstName'),
                                        id: 'firstName',
                                        accessor: 'firstName',
                                        className: classes.contacts__table__cell,
                                        width: 120,
                                        filterable: clientContacts.length < 5 ? false : true,
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    autoComplete='off'
                                                    fullWidth
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            return row._original.firstName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                        }
                                    },
                                    {
                                        Header: i18n.t('quotes.quoteRequest.contactLastName'),
                                        accessor: 'lastName',
                                        className: classes.contacts__table__cell,
                                        width: 110,
                                        filterable: clientContacts.length < 5 ? false : true,
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    autoComplete='off'
                                                    fullWidth
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            return row._original.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                        }
                                    },
                                    {
                                        Header: i18n.t('quotes.quoteRequest.contactEmail'),
                                        accessor: 'email',
                                        className: classes.contacts__table__cell,
                                    },
                                    {
                                        Header: i18n.t('quotes.quoteRequest.contactPhone'),
                                        accessor: 'phone',
                                        className: classes.contacts__table__cell,
                                        width: 110
                                    },
                                    {
                                        Header: i18n.t('clients.editClientContact'),
                                        className: classes.contacts__table__cell,
                                        Cell: row => (
                                            <Link className={classes.customLink}
                                                variant='button'
                                                onClick={() => this.handleEditClientContact(row.original)}>
                                                {i18n.t('clients.edit')}
                                            </Link>),
                                        width: 40
                                    },
                                    {
                                        Header: i18n.t('clients.editClientContact'),
                                        className: classes.contacts__table__cell,
                                        show: !fromCompanies,
                                        Cell: row => (
                                            <div style={{ width: '100%', height: '100%' }}>
                                                {row.original.active ? (
                                                    <Radio name='contact'
                                                        checked={currentSelectedContactId === row.original.id ? true : false}
                                                        onChange={() => {
                                                            this.handleChangeRadio(row.original.id)
                                                        }}
                                                    />
                                                ) : (
                                                        <div>{i18n.t('clients.contactInactive')}</div>
                                                    )
                                                }
                                            </div>
                                        ),
                                        //width: 50
                                    }
                                ]}
                                resizable={false}
                                sortable={false}
                                defaultPageSize={clientContacts.length < 5 ? clientContacts.length : 5}
                                pageSize={clientContacts.length < 5 ? clientContacts.length : 5}
                                className={classNames(classes.clientcontacts__table, ' -striped -highlight')}
                                showPaginationTop={false}
                                showPaginationBottom={clientContacts.length < 5 ? false : true}
                                showPageSizeOptions={false}
                                filtrable={clientContacts.length < 5 ? false : true}
                            />
                        </div>
                        {!fromCompanies && this.props.selectedClientContact && currentSelectedContactId && this.props.selectedClientContact.id !== currentSelectedContactId && (
                            <Link
                                className={classes.customLink}
                                variant='button'
                                onClick={this.handleChangeClientContact}>
                                {i18n.t('clients.useSelectedContact')}
                            </Link>
                        )}
                    </>
                )}

                {(editClientContact || createClientContact) && (
                    <>
                        <Grid container spacing={2} direction='row' >
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={savingClientContact}
                                    inputProps={{
                                        className: classes.client__form__input
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClientContact: {
                                                ...selectedClientContact,
                                                firstName: event.target.value
                                            }
                                        });
                                    }}
                                    error={(editClientContact || createClientContact) && validateClientContact && selectedClientContact.firstName === '' ? true : false}
                                    value={selectedClientContact ? selectedClientContact.firstName ? selectedClientContact.firstName : '' : ''}
                                    className={classes.client__form__textField}
                                    id="clientContactFirstName"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.contactFirstName')}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={savingClientContact}
                                    inputProps={{
                                        className: classes.client__form__input
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClientContact: {
                                                ...selectedClientContact,
                                                lastName: event.target.value
                                            }
                                        });
                                    }}
                                    error={(editClientContact || createClientContact) && validateClientContact && selectedClientContact.lastName === '' ? true : false}
                                    value={selectedClientContact ? selectedClientContact.lastName ? selectedClientContact.lastName : '' : ''}
                                    className={classes.client__form__textField}
                                    id="clientContactLastName"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.contactLastName')}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    disabled={savingClientContact}
                                    inputProps={{
                                        className: classes.client__form__input
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClientContact: {
                                                ...selectedClientContact,
                                                email: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedClientContact ? selectedClientContact.email ? selectedClientContact.email : '' : ''}
                                    className={classes.client__form__textField}
                                    id="clientContactEmail"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.contactEmail')}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    disabled={savingClientContact}
                                    inputProps={{
                                        className: classes.client__form__input
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            selectedClientContact: {
                                                ...selectedClientContact,
                                                phone: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedClientContact ? selectedClientContact.phone ? selectedClientContact.phone : '' : ''}
                                    className={classes.client__form__textField}
                                    id="clientContactPhone"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('quotes.quoteRequest.contactPhone')}
                                />
                            </Grid>
                            {editClientContact && selectedClientContact &&(
                                <Grid item sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={selectedClientContact.active ? true : false}
                                                onChange={() => {
                                                    this.setState({
                                                        selectedClientContact: {
                                                            ...selectedClientContact,
                                                            active: !selectedClientContact.active
                                                        }
                                                    })
                                                }}
                                                value={i18n.t('clients.contactActive')}
                                            />
                                        }
                                        label={selectedClientContact.active ? i18n.t('clients.contactActive') : i18n.t('clients.contactInactive')}
                                    />

                                </Grid>
                            )}
                            {validateErrorMessage && (
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                    <Typography color='error'>{i18n.t("requiredFields")}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}

                {!editClientContact && !createClientContact && (!clientContacts || (clientContacts && clientContacts.length === 0)) && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{i18n.t('clients.selectClientContactToEdit')}</Typography>
                        </Grid>
                    </Grid>
                )}



                <Divider className={classes.client__actions__divider} />


                {!createClientContact && !editClientContact && client && client.id &&
                    <Grid container spacing={1} alignItems='center' justify='flex-end' className={classes.client__actions__container}>
                        <Grid item sm={3} xs={12} className={classes.client__action__button__container}>
                            <Link
                                className={classes.customLink}
                                color="secondary"
                                variant='button'
                                onClick={this.handleCreateClientContactButtonClick}>
                                {i18n.t('clients.addClientContact')}
                            </Link>
                        </Grid>
                    </Grid>
                }

                {(editClientContact || createClientContact) && (
                    <Grid container spacing={2} justify='space-between' direction='row' className={classes.client__form__bottomButtons__grid}>
                        <Grid item xs={12} md={4} className={classes.cancelDelete__buttons__container}>
                            <Button
                                variant="contained"
                                disabled={savingClientContact}
                                size='small'
                                onClick={this.handleCancel}>
                                {i18n.t('clients.cancel')}
                            </Button>
                            {!createClientContact && (
                                <Button
                                    disabled={savingClientContact || deleting || (selectedClientContact.filesCount > 0) ? true : false}
                                    size='small'
                                    onClick={this.deleteContact}
                                    className={classes.client__deleteClientButton}
                                >
                                    {i18n.t("clients.deleteClient")}
                                    {deleting && !savingClientContact && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            )}
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={savingClientContact}
                                fullWidth
                                className={savingClientContact ? classes["client__editSections__button--disabled"] : classes.client__editSections__saveButton}
                                size='small'
                                onClick={this.handleSaveClientContact}>
                                {i18n.t('clients.saveClientContact')}
                                {savingClientContact && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                            </Button>
                        </Grid>

                        {selectedClientContact && selectedClientContact.filesCount > 0 && (
                            <Grid item xs={12} className={classes.cancelDelete__buttons__container}>
                                <Typography color='textSecondary'>{i18n.t('clients.contactCantBeDeleted')}</Typography>
                            </Grid>
                        )}
                    </Grid>
                )}

            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        client: state.client,
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withMobileDialog()(withStyles(styles, { withTheme: true })(ManageClientContactsPopup))))