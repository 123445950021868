import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const PaymentTerms = {
    createPaymentTerm: (args) => {
        let { name } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/paymentTerms`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify({name}),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updatePaymentTerm: (args) => {
        let { name, paymentTermId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/paymentTerms/${paymentTermId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify({name}),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deletePaymentTerm: (args) => {
        let { paymentTermId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/paymentTerms/${paymentTermId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
}

export default PaymentTerms;