import React, { Component } from 'react'
import {
    Button,
    withStyles,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Link
} from '@material-ui/core'
import { LogTable } from '.' 
import withWidth from '@material-ui/core/withWidth'
import i18n from 'i18next';
import API from '../../../lib/api'


const styles = theme => {
    return ({
        log: {
            ...theme.modal.container,
            padding: `${theme.spacing(3)}px 0 0 `,
            minHeight: 350,
        },
        log__title__closeIcon: {
        },
        log__item: {
        },
        log__loading__container: {
            position: 'relative',
            height: '50px',
            width: '50px',
            margin: 'auto'
        },
        log__showMore: {
            ...theme.customLink
        },
    })
};

class FileLogDialog extends Component {

    state = {
        loading: false,
        log: []
    }

    componentWillMount() {
        const { fileId } = this.props;

        this.setState({
            loading: true
        })

        API.Notes.getNotes({
            fileId: fileId
        }).then(res => {
            if (res.data) {
                this.setState({
                    log: res.data.records.log.slice(0,6),
                    loading: false
                })
            }
        })
    }

    render() {
        const { classes, fileId, open } = this.props,
              { log, loading } = this.state;

        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={open}
                scroll='paper'
                onClose={this.props.handleClose}
                aria-labelledby="log-dialog-title"
                aria-describedby="log-dialog-description"
            >
                <DialogTitle id="log-dialog-title" disableTypography={true}>
                    {`${i18n.t('file.log.dialogTitle')} ${fileId} - Log`}
                </DialogTitle>
                <DialogContent>
                    {loading &&
                        <div className={classes.log__loading__container}>
                            <CircularProgress color='secondary'  size={24} className='form__submitButton__spinner' />
                        </div>
                    }

                    { !loading && 
                        <div className={classes.log}>
                            <LogTable
                                summary={true}
                                log={log}
                            />

                            <div style={{marginTop: '8px'}}>
                                <Link
                                    variant='button'
                                    className={classes.log__showMore}
                                    onClick={this.props.showNotesTab}>
                                    {i18n.t("file.log.showLog")}
                                </Link>
                            </div>
                        </div>
                    }
                </DialogContent>
                <DialogActions style={{justifyContent: 'flex-end'}}>
                    <Button
                        style={{ width: '110px' }}
                        color="primary"
                        size='small'
                        variant="contained"
                        onClick={this.props.onClose}>
                        {i18n.t('file.log.confirmButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withWidth()(withStyles(styles)(FileLogDialog));