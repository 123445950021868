import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'



const Reports = {
    getClientPDFReport: (args) => {
        let { fileId, reportType, invoiceId } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/reports/${fileId}/client/${reportType}${invoiceId ? `/${invoiceId}` : ''}`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getVendorPDFReport: (args) => {
        let { fileId, reportType, vendorQuoteId } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/reports/${fileId}/vendor/${vendorQuoteId}/${reportType}`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },

}

export default Reports;