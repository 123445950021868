import React, { Component } from 'react'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Drawer
} from '@material-ui/core'
import {
    Done,
} from '@material-ui/icons'
import { GlobalParametersContext } from '../../contexts/globalParametersContext'
import API from '../../lib/api';

const drawerWidth = 280;

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
     drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    events__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    }
});

class ListEventCountryItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.countryId}
                data-selected={item.selected}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 0,
                }}>
                    {item.countryName}
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}>
                        {item.selected ? <Done style={{ color: '#3f97f6' }} /> : ''}
                    </div>
                </div>
            </li>
        )
    }
}

class ListEventClientItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id}
                data-selected={item.selected}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 0,
                }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px',
                    alignItems: 'center'
                }}>
                    {item.name}
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}>
                        {item.selected ? <Done style={{ color: '#3f97f6' }} /> : ''}
                    </div>
                </div>
            </li>
        )
    }
}

class ListEventTypeItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id} data-role={item.header ? "list-divider" : ""}
                data-selected={item.selected}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {item.value}
                <div style={{ margin: -10, padding: 10, display: 'flex' }}>
                    {item.selected && !item.header ? <Done style={{ color: '#3f97f6' }} /> : ''}
                </div>
            </li>
        )
    }
}


const options = [
    {
        id: 1,
        value: "Event"
    }, {
        id: 2,
        header: true,
        value: ""
    }, {
        id: 3,
        value: 'New Client'
    }, {
        id: 4,
        value: 'Sales Visit'
    }, {
        id: 5,
        value: 'Followup'
    }, {
        id: 6,
        value: 'Technical Visit'
    }
]

class EventFilterSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formType: null,
            client: null,
            country: null,
            eventType: '',
            clients: []
        }
    }

    componentWillReceiveProps(nextProps) {
       this.setState({
            formType: null,
            client: null,
            country: null,
            eventType: ''
        })
    }

    fetchClients = (countryId) => {
        API.Clients.getClients({
            countryId: countryId
        }).then(res => {
            this.setState({
                clients: res.data.records
            })
        })
    }

    selectCountry = (e, countries) => {
        this.setState({
            country: countries[e.index]
        })

        this.fetchClients(countries[e.index].countryId)
        
        this.props.selectCountry(countries[e.index])
        this.props.toggleOpenMenu()
    }

    selectClient = (e) => {
        const { clients } = this.state

        this.setState({
            client: clients[e.index]
        })

        this.props.selectClient(clients[e.index])
        this.props.toggleOpenMenu()
    }

    selectType = (e) => {
        this.setState({
            eventType: options[e.index].value
        })

        this.props.selectType(options[e.index].value)       
        this.props.toggleOpenMenu()
    }

    render() {
        const { classes, type, menuVisible, toggleOpenMenu, } = this.props,
            { country, client, eventType, clients } = this.state;

        return (
            <GlobalParametersContext.Consumer>
                {({ countries }) => (<>
                    <Drawer open={menuVisible}
                        anchor="right"
                        onClose={toggleOpenMenu}
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {type === 'country' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Listview
                                        lang="es"
                                        theme="ios"
                                        itemType={ListEventCountryItem}
                                        onItemTap={(e) => this.selectCountry(e, countries)}
                                        data={countries.map((i, index) => {
                                            if (country && i.countryId === country.id) {
                                                return {
                                                    ...i,
                                                    id: i.countryId,
                                                    selected: true,
                                                }
                                            } else {
                                                return {
                                                    ...i,
                                                    id: i.countryId,
                                                    selected: false,
                                                }
                                            }
                                        })}
                                        select="off"
                                    />
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }

                        {type === 'client' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup style={{ marginTop: '11px' }}>
                                    <mobiscroll.FormGroupTitle>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px', fontSize: '16px' }}>
                                            {country && country.name}
                                        </div>
                                    </mobiscroll.FormGroupTitle>
                                    <mobiscroll.Listview
                                        lang="es"
                                        theme="ios"
                                        itemType={ListEventClientItem}
                                        onItemTap={this.selectClient}
                                        data={clients.map((i, index) => {
                                            if (client && i.id === client.id) {
                                                return {
                                                    ...i,
                                                    index: index,
                                                    selected: true,
                                                }
                                            } else {
                                                return {
                                                    ...i,
                                                    index: index,
                                                    selected: false,
                                                }
                                            }
                                        })}
                                        select="off"
                                    />
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }

                        {type === 'eventType' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Listview
                                        lang="es"
                                        theme="ios"
                                        itemType={ListEventTypeItem}
                                        onItemTap={this.selectType}
                                        data={options.map(i => {
                                            if (eventType && i.value === eventType) {
                                                return {
                                                    ...i,
                                                    id: i.value,
                                                    selected: true,
                                                }
                                            } else {
                                                return {
                                                    ...i,
                                                    id: i.value,
                                                    selected: false
                                                }
                                            }
                                        })}
                                        select="single"
                                    />
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }
                    </Drawer>
                </>)}
            </GlobalParametersContext.Consumer>
        );
    }
}


export default withStyles(styles)(EventFilterSelector);
