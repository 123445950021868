import React, { Component } from 'react'
import {
    TextField,
    withStyles,
    InputAdornment,
    Link,
    Typography,
    Divider,
    Checkbox,
    IconButton
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    Warning,
    KeyboardArrowRight,
    Delete
} from '@material-ui/icons'
import ReactTable from "react-table"
import NumberFormat from 'react-number-format'
import classNames from 'classnames'
import i18n from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import config from '../../../config';
import { NumberFormatInputCustom } from '../../general'
import { ClientQuoteSelectVendorByItem } from '../preparingQR'
import Permissions from '../../../lib/permissions'



const styles = theme => {
    return (
        {
            preparing__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`,
                marginTop: theme.spacing(),
                marginLeft: theme.spacing()
            },
            preparing__items__table__align: {
                margin: `0 ${theme.spacing()}px ${theme.spacing()}px`,
            },
            quoteitems__table__itemPartNumber: {
                color: theme.palette.naval.main
            },
            quoteitems__table__descriptionExtended: {
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between'
            },
            quoteitems__table__quantity: {
                color: theme.palette.text.secondary
            },
            quoteitems__table__textInput: {
                fontSize: theme.typography.fontSize * 0.8,
            },
            quoteitems__table__cell__center: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            quoteitems__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
            },
            quoteitems__list: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__paper: {
                padding: theme.spacing(),
                marginBottom: theme.spacing(),
            },
            quoteitems__list__item: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__edit__grid__container: {
                marginTop: theme.spacing()
            },
            quoteitems__table__accepted: {
                color: theme.palette.green[100],
                fontWeigth: 'bold'
            },
            quoteItems__table__btn: {
                ...theme.smallButton,
                borderColor: theme.palette.blue[100],
                color: theme.palette.blue[100]
            },
            "quoteItems__table__btn--remove": {
                borderColor: theme.palette.red[100],
                color: theme.palette.red[100]
            },
            quoteItems__table__textField: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
                width: '90%',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginRight: 0,
                }
            },
            quoteItems__table__menu: {
                width: "auto"
            },
            quoteItem__selectVendor__link: {
                ...theme.customLink,
                whiteSpace: 'normal'
            },
            customLink: theme.customLink,
            preparing__form__textField: {
                marginTop: theme.spacing()
            },
            clientQuote__noItems: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.orange[80],
                borderRadius: theme.spacing() / 2,
                padding: '0 5px',
                marginLeft: theme.spacing(2)
            },
            extendeDescription__icon: {
                fontSize: '20px',
                color: theme.palette.grey[500]
            },
            warning__message__icon: {
                marginRight: theme.spacing(),
                fontSize: '14pt',
                color: theme.palette.orange[100],
            },
        })
}

class ClientQuoteItemsList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedItem: null,
            items: props.editable ? props.items : props.items.filter(item => !item.deleted),
            selectedVendor: 0,
            checked: [0],
            openSelectVendorDialog: false,
            // openSelectItemsByVendorDialog: false,
            selectedRow: null,
            selectedRowIndex: null,
            clientQuoteTaxPercent: props.clientQuoteTaxPercent,
            applyTax: props.applyTax,
            deletedItem: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        //if(!nextProps.editable){

        this.setState({
            items: nextProps.editable ? nextProps.items : nextProps.items.filter(item => !item.deleted),
        })
        //}
        this.setState({
            applyTax: this.props.applyTax
        })

        this.setState({
            clientQuoteTaxPercent: nextProps.clientQuoteTaxPercent,
            applyTax: nextProps.applyTax
        })
    }


    selectItem = (e, index) => {
        e.stopPropagation()
        const { selectedItem } = this.state;

        this.setState({
            selectedItem: index === selectedItem ? null : index
        })
    }

    selectVendor = (items) => {
        this.setState({
            items: items
        });

        this.props.warningVendorPOMessage()

        this.setWeightedCost(items);
    }

    cancelSelectVendor = () => {
        this.setState({
            selectedRow: null,
            openSelectVendorDialog: false
        })
    }

    renderSelectVendorFields = (cellInfo) => {
        const { file, editable, classes } = this.props,
            { items } = this.state;

        let vendorQuotes = file.vendorQuotes.filter((vq) => {
            return (
                vq.items.filter(item =>
                    item.rfqItem.id === cellInfo.original.rfqItem.id && !item.deleted && item.unitAmount
                ).length > 0 && vq.status.id === 3
            )
        });

        let vendors = vendorQuotes.map((vq, i) => {
            return {
                id: vq.vendor.id,
                name: vq.vendor.name,
                vendorQuoteId: vq.id
            }
        });

        if (items[cellInfo.index].deleted){
            return null;
        }

        return (
            <>
                {vendors.length > 0 ?
                    <>
                        {editable && !items[cellInfo.index].deleted ?
                            <>
                                {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.vendor && cellInfo.original.acceptedVendorQuoteItem.vendor.id ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Link className={classes.quoteItem__selectVendor__link}
                                            variant='button'
                                            onClick={() => this.setState({
                                                openSelectVendorDialog: true,
                                                selectedRow: cellInfo.original,
                                                selectedRowIndex: cellInfo.index
                                            })}>
                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                {cellInfo.original.acceptedVendorQuoteItem.vendor.name}
                                            </div>
                                        </Link>
                                        {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.deliveryDays &&
                                            <Typography className={classes.quoteitems__table__quantity}>
                                                {`${cellInfo.original.acceptedVendorQuoteItem.deliveryDays} ${i18n.t('quotes.preparatingQuoteInformation.deliveryOn')}`}
                                            </Typography>
                                        }

                                        <TextField
                                            autoComplete='off'
                                            id="quote-comments-multiline"
                                            label={i18n.t('quotes.preparatingQuoteInformation.noUseVendorQuoteComment')}
                                            className={classes.preparing__form__textField}
                                            value={items[cellInfo.index].comment ? items[cellInfo.index].comment : ''}
                                            onChange={e => {
                                                const items = [...this.state.items];

                                                items[cellInfo.index].comment = e.target.value;
                                                this.setState({ items });
                                                this.props.updateItems(items);
                                            }}
                                            multiline
                                            rowsMax='3'
                                            rows='1'
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={!editable}
                                        />
                                    </div>
                                    :
                                    <div style={{ width: '100%' }}>
                                        <Link
                                            className={classes.quoteItem__selectVendor__link}
                                            variant='button'
                                            onClick={() => this.setState({
                                                openSelectVendorDialog: true,
                                                selectedRow: cellInfo.original,
                                                selectedRowIndex: cellInfo.index
                                            })}>
                                            {i18n.t('quotes.preparatingQuoteInformation.selectAVendor')}
                                        </Link>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-tart' }}>
                                            <TextField
                                                autoComplete='off'
                                                id="quote-comments-multiline"
                                                label={i18n.t('quotes.preparatingQuoteInformation.noUseVendorQuoteComment')}
                                                className={classes.preparing__form__textField}
                                                value={items[cellInfo.index].comment ? items[cellInfo.index].comment : ''}
                                                onChange={e => {
                                                    const items = [...this.state.items];

                                                    items[cellInfo.index].comment = e.target.value;
                                                    this.setState({ items });
                                                    this.props.updateItems(items);
                                                }}
                                                multiline
                                                rowsMax='3'
                                                rows='1'
                                                fullWidth
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!editable}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.vendor ? cellInfo.original.acceptedVendorQuoteItem.vendor.name : ''}
                                </Typography>

                                {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.deliveryDays &&
                                    <Typography className={classes.quoteitems__table__quantity}>
                                        {`${cellInfo.original.acceptedVendorQuoteItem.deliveryDays} ${i18n.t('quotes.preparatingQuoteInformation.deliveryOn')} `}
                                    </Typography>
                                }
                            </div>
                        }
                    </>
                    :
                    <div style={{ width: '100%' }}>
                        <Typography>{i18n.t('quotes.preparatingQuoteInformation.noVendors')}</Typography>
                        {editable && !items[cellInfo.index].deleted &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-tart', marginTop: '10px' }}>
                                <TextField
                                    autoComplete='off'
                                    id="quote-comments-multiline"
                                    label={i18n.t('quotes.preparatingQuoteInformation.noUseVendorQuoteComment')}
                                    className={classes.preparing__form__textField}
                                    value={items[cellInfo.index].comment ? items[cellInfo.index].comment : ''}
                                    onChange={e => {
                                        const items = [...this.state.items];

                                        items[cellInfo.index].comment = e.target.value;
                                        this.setState({ items });
                                        this.props.updateItems(items);
                                    }}
                                    fullWidth
                                    multiline
                                    rowsMax='3'
                                    rows='1'
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={!editable}
                                />
                            </div>
                        }
                    </div>
                }
            </>
        )
    }

    renderCostsFields = (cellInfo) => {
        const { editable, classes, currencySymbol, file } = this.props,
            { items } = this.state;

        if (items[cellInfo.index].deleted){
            return null;
        }
        
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {editable && !items[cellInfo.index].deleted ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>

                        {/* Vendor Cost / Cost */}
                        {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.vendor &&
                            cellInfo.original.acceptedVendorQuoteItem.vendor.id && cellInfo.original.acceptedVendorQuoteItem.unitAmount &&
                            (cellInfo.original.acceptedVendorQuoteItem.unitAmount === cellInfo.original.unitAmount || !cellInfo.original.unitAmount) ?
                            <div style={{ marginBottom: '8px', textAlign: 'right' }}>
                                <NumberFormat
                                    value={items[cellInfo.index].unitAmount ? items[cellInfo.index].unitAmount : ''}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    style={{ fontSize: '16px' }}
                                    fixedDecimalScale={true}
                                />
                            </div>
                            :
                            <div>
                                {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.unitAmount !== 0 && cellInfo.original.unitAmount !== 0 && cellInfo.original.acceptedVendorQuoteItem.unitAmount !== cellInfo.original.unitAmount &&
                                    <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '14px' }}>
                                        <Warning className={classes.warning__message__icon} />
                                        {`${currencySymbol}${cellInfo.original.acceptedVendorQuoteItem.unitAmount}`}
                                    </Typography>
                                }
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    variant="outlined"
                                    id={`quoteNoVendorAmount-${cellInfo.index}`}
                                    style={{ marginBottom: '8px' }}
                                    className={classes.preparing__form__textField}
                                    label={i18n.t('quotes.preparatingQuoteInformation.cost')}
                                    value={items[cellInfo.index].unitAmount ? items[cellInfo.index].unitAmount : ''}
                                    onBlur={e => {
                                        const newItems = [...items];
                                        newItems[cellInfo.index].unitAmount = parseFloat(e.target.value);
                                        newItems[cellInfo.index].amount = parseFloat(items[cellInfo.index].markupPercent ?
                                            parseFloat(e.target.value) + ((parseFloat(e.target.value) * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0) :
                                            parseFloat(e.target.value) + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0)).toFixed(2);

                                        if (cellInfo.original.acceptedVendorQuoteItem && !cellInfo.original.acceptedVendorQuoteItem.unitAmount) {
                                            newItems[cellInfo.index].acceptedVendorQuoteItem = {
                                                unitAmount: e.target.value
                                            }
                                        }
                                        this.setState({ items: newItems });
                                        this.props.updateItems(newItems);
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment
                                            position="start"
                                        >{currencySymbol}</InputAdornment>,
                                        inputComponent: NumberFormatInputCustom
                                    }}
                                />
                            </div>
                        }
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        {cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.unitAmount && cellInfo.original.unitAmount && cellInfo.original.acceptedVendorQuoteItem.unitAmount !== cellInfo.original.unitAmount &&
                            <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Warning className={classes.warning__message__icon} />
                                {i18n.t('quotes.preparatingQuoteInformation.newPrice')}
                            </Typography>
                        }
                        {cellInfo.original.unitAmount !== null && cellInfo.original.unitAmount !== 0 &&
                            <NumberFormat
                                value={cellInfo.original.unitAmount ? cellInfo.original.unitAmount : null}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                style={{ fontSize: '16px' }}
                            />
                        }
                    </div>
                }

                {cellInfo.original.weightedCost > 0 && (file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate)) &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography variant='body2' color="textSecondary">Pro.</Typography>
                            <NumberFormat value={cellInfo.original.weightedCost}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)', textAlign: 'right', marginLeft: '10px' }}
                                fixedDecimalScale={true} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid rgba(0, 0, 0, 0.54)' }}>
                            <Typography variant='body2' color="textSecondary"></Typography>
                            <NumberFormat value={cellInfo.original.unitAmount + cellInfo.original.weightedCost}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                style={{ fontSize: '16px', textAlign: 'right', marginLeft: '10px' }}
                                fixedDecimalScale={true} />
                        </div>
                    </div>
                }
            </div>
        )
        
    }

    renderProfitsFields = (cellInfo) => {
        const { editable, classes, file } = this.props,
            { items } = this.state;

        if (items[cellInfo.index].deleted){
            return null;
        }

        if (editable && !items[cellInfo.index].deleted) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete='off'
                        id={`profitPercentText-${cellInfo.index}`}
                        label={i18n.t('quotes.preparatingQuoteInformation.profit')}
                        className={classes.quoteitems__table__textInput}
                        disabled={!items[cellInfo.index].acceptedVendorQuoteItem.vendor && !items[cellInfo.index].unitAmount}
                        onBlur={e => {
                            const newItems = [...this.state.items];
                            let profitPercent = e.target.value,
                                itemCost = items[cellInfo.index].unitAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0) 

                            newItems[cellInfo.index].markupPercent = parseFloat(profitPercent).toFixed(4);

                            if(file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate)){
                                newItems[cellInfo.index].amount = itemCost + ((itemCost * profitPercent) / 100) ;
                                newItems[cellInfo.index].total = items[cellInfo.index].quantity * (itemCost + ((itemCost * profitPercent) / 100)).toFixed(2)
                            } else {
                                newItems[cellInfo.index].amount = items[cellInfo.index].unitAmount + ((items[cellInfo.index].unitAmount * profitPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / items[cellInfo.index].quantity) : 0);
                                newItems[cellInfo.index].total = items[cellInfo.index].quantity * parseFloat(items[cellInfo.index].unitAmount + ((items[cellInfo.index].unitAmount * profitPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / items[cellInfo.index].quantity) : 0)).toFixed(2)
                            }

                            this.setState({ newItems });
                            this.props.updateItems(newItems);
                        }}
                        value={items[cellInfo.index].markupPercent}
                        InputProps={{
                            endAdornment: <InputAdornment position="end" >%</InputAdornment>,
                            inputComponent: NumberFormatInputCustom
                        }}
                    />

                </div>)
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {((cellInfo.original.acceptedVendorQuoteItem && cellInfo.original.acceptedVendorQuoteItem.unitAmount) || (cellInfo.original.unitAmount !== null && cellInfo.original.unitAmount !== 0)) &&
                        <NumberFormat value={items[cellInfo.index].markupPercent || items[cellInfo.index].markupPercent !== 0 ? parseFloat(items[cellInfo.index].markupPercent).toFixed(4) : null}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={'%'}
                            decimalScale={2}
                            style={{ fontSize: '16px' }}
                            fixedDecimalScale={true}
                        />
                    }
                </div>
            )
        }
    }

    renderQuotedFields = (cellInfo) => {
        const { currencySymbol, file } = this.props,
            { items } = this.state;

        if (items[cellInfo.index].deleted){
            return null;
        }

        return (
            <div style={{ width: '100%' }}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id={`quoteToClientAmountText-${cellInfo.index}`}
                    label={i18n.t('quotes.preparatingQuoteInformation.quoted')}
                    value={items[cellInfo.index].amount}
                    disabled={!items[cellInfo.index].acceptedVendorQuoteItem.vendor && !items[cellInfo.index].unitAmount}
                    onBlur={e => {
                        const newItems = [...this.state.items];

                        let quoteAmount = parseFloat(e.target.value), 
                            profitPercent = null;

                        newItems[cellInfo.index].amount = parseFloat(quoteAmount);

                        if(file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate) ){
                            let itemCost = items[cellInfo.index].unitAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0);
                            profitPercent = quoteAmount ? ((quoteAmount - itemCost) * 100) / itemCost : null;

                            newItems[cellInfo.index].markupPercent = parseFloat(profitPercent).toFixed(4);
                            newItems[cellInfo.index].total = items[cellInfo.index].quantity * parseFloat(itemCost + ((itemCost * profitPercent) / 100)).toFixed(2)
                        } else {
                            profitPercent = quoteAmount ? ((quoteAmount - (newItems[cellInfo.index].weightedCost / cellInfo.original.quantity) - items[cellInfo.index].unitAmount) * 100) / items[cellInfo.index].unitAmount : null;
                            
                            newItems[cellInfo.index].markupPercent = parseFloat(profitPercent).toFixed(4);
                            newItems[cellInfo.index].total = items[cellInfo.index].quantity * parseFloat(items[cellInfo.index].unitAmount + ((items[cellInfo.index].unitAmount * profitPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / items[cellInfo.index].quantity) : 0)).toFixed(2)
                        }
                        
                        this.setState({ newItems });
                        this.props.updateItems(newItems);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment
                            position="start"
                        >{currencySymbol}</InputAdornment>,
                        inputComponent: NumberFormatInputCustom
                    }}
                />

                {cellInfo.original.weightedCost > 0 && moment(file.clientQuote[0].date).isBefore(config.prorratedDate) &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body2' color="textSecondary">{i18n.t('quotes.preparatingQuoteInformation.weighted')}</Typography>
                        <NumberFormat value={cellInfo.original.weightedCost / cellInfo.original.quantity}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)', textAlign: 'right', marginLeft: '10px' }}
                            fixedDecimalScale={true} />
                    </div>
                }
            </div>
        )
        
    }

    renderTotalColumn = (cellInfo) => {
        const { items, applyTax, clientQuoteTaxPercent } = this.state,
            { classes, editable, file, currencySymbol } = this.props;

        let unit = items[cellInfo.index].unitAmount,
            itemCost = items[cellInfo.index].unitAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0),
            total = items[cellInfo.index].total,
            amountWithProfit = (file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate) ) ? itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100) : 
                unit + ((unit * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost / cellInfo.original.quantity : 0);

        if(items[cellInfo.index].deleted){ 
            return (
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', alignItems: 'flex-end'}}>
                    <NumberFormat value={0}
                        displayType={'text'}
                        thousandSeparator={true}
                        suffix={currencySymbol}
                        decimalScale={2}
                        style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' }}
                        fixedDecimalScale={true}
                    />
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                    {(items[cellInfo.index].acceptedVendorQuoteItem.unitAmount || items[cellInfo.index].acceptedVendorQuoteItem.unitAmount !== 0 || items[cellInfo.index].unitAmount || items[cellInfo.index].unitAmount !== 0) && (cellInfo.original.amount * (cellInfo.original.quantity ? cellInfo.original.quantity : cellInfo.original.rfqItem.quantity) > 0) && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column', padding: '0 8px' }}>
                            <div className={classes.quoteitems__table__quantity} style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16px' }}>
                                { editable ? 
                                    <TextField
                                        // fullWidth
                                        variant="outlined"
                                        autoComplete='off'
                                        style={{width: '95px'}}
                                        id={`quantity-${cellInfo.index}`}
                                        label={i18n.t('quotes.preparatingQuoteInformation.quantityNumber')}
                                        className={classes.quoteitems__table__textInput}
                                        disabled={!items[cellInfo.index].acceptedVendorQuoteItem.vendor && !items[cellInfo.index].unitAmount}
                                        onBlur={e => {
                                            const newItems = [...this.state.items],
                                                itemCost = items[cellInfo.index].unitAmount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0),
                                                quantity = e.target.value;

                                            newItems[cellInfo.index].quantity = quantity;
                
                                            if(file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate)){
                                                newItems[cellInfo.index].total = quantity * (itemCost + ((itemCost * newItems[cellInfo.index].markupPercent) / 100)).toFixed(2)
                                            } else {
                                                newItems[cellInfo.index].total = quantity * parseFloat(items[cellInfo.index].unitAmount + ((items[cellInfo.index].unitAmount * newItems[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? (items[cellInfo.index].weightedCost / quantity) : 0)).toFixed(2)
                                            }
                
                                            this.setState({ newItems });
                                            this.props.updateItems(newItems);
                                        }}
                                        value={cellInfo.original.quantity}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end" >{cellInfo.original.rfqItem.unit}</InputAdornment>,
                                            inputComponent: NumberFormatInputCustom
                                        }}
                                    />
                                :
                                    `${cellInfo.original.quantity ? cellInfo.original.quantity : cellInfo.original.rfqItem.quantity} ${cellInfo.original.rfqItem.unit}`
                                }
                            </div>

                            <div className={classes.quoteitems__table__quantity} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NumberFormat value={amountWithProfit}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    style={{ fontSize: '16px' }}
                                    fixedDecimalScale={true} />
                            </div>
                            <Divider style={{ width: '90%' }} />

                            <div className={classes.quoteitems__table__quantity} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NumberFormat value={total}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    style={{ fontSize: '16px' }}
                                    fixedDecimalScale={true} />
                            </div>
                        </div>
                    )}
                    <div className={classes.quoteitems__table__quantity} style={{ fontSize: '16px', marginTop: '16px', display: 'flex', justifyContent: editable ? 'flex-start' : 'flex-end', alignItems: 'center' }}>
                        {editable && !items[cellInfo.index].deleted &&
                            <Checkbox
                                checked={cellInfo.original.taxPercent && applyTax ? true : false}
                                tabIndex={-1}
                                style={{ padding: '0 8px' }}
                                disabled={applyTax ? false : true}
                                onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[cellInfo.index].taxPercent = e.target.checked;

                                    this.setState({ items: newItems });
                                    this.props.updateItems(newItems);
                                }}
                            />
                        }
                        <span>

                            {editable && !items[cellInfo.index].deleted ? i18n.t('quotes.preparatingQuoteInformation.tax') : ''}

                            {cellInfo.original.taxPercent !== 0 && (!editable || (editable && applyTax && clientQuoteTaxPercent)) && ((clientQuoteTaxPercent * total) / 100) !== 0 && <>
                                {!editable ? i18n.t('quotes.preparatingQuoteInformation.tax') : ''}
                                <NumberFormat value={(clientQuoteTaxPercent * total) / 100}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    style={{ fontSize: '14px', marginLeft: '8px' }}
                                    fixedDecimalScale={true} />
                                {!editable && `(${parseInt(clientQuoteTaxPercent)}%)`}
                            </>}
                        </span>
                    </div>
                </div>
            )
        }
    }

    renderItemDescription = (cellInfo) => {
        const { classes } = this.props,
            { selectedItem } = this.state;

        let row = cellInfo.original
        return (
            <>
                <br />
                {row.acceptedVendorQuoteItem.reference && row.acceptedVendorQuoteItem.reference !== row.rfqItem.partNumber ? <>
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${row.rfqItem.partNumber}`}
                    </Typography>
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')} ${row.acceptedVendorQuoteItem.reference}`}
                    </Typography>
                </> :
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {row.rfqItem.partNumber}
                    </Typography>
                }
                <div
                    className={classes.quoteitems__table__descriptionExtended}
                    style={{ alignItems: selectedItem === cellInfo.index ? 'flex-start' : 'center' }}
                    onClick={(e) => this.selectItem(e, cellInfo.index)}>
                    <Typography color='textSecondary'
                        style={{ whiteSpace: selectedItem === cellInfo.index ? 'pre-line' : 'nowrap' }}
                        noWrap>
                        {row.rfqItem.description}
                    </Typography>
                    {row.rfqItem.description.length > 35 && <>
                        {selectedItem === cellInfo.index ?
                            <KeyboardArrowUp className={classes.extendeDescription__icon} />
                            :
                            <KeyboardArrowDown className={classes.extendeDescription__icon} />
                        }
                    </>}
                </div>
            </>
        )
    }

    recalculateWeightedCost = (items) => {
        const { file } = this.props;
        let weighted = this.props.quoteOtherCosts ? this.props.quoteOtherCosts.filter(oc => oc.weighted) : [],
            newItems = [...items];

        let totalItems = 0,
            totalWeightedCost = 0,
            weightPercent = 0;

        if (weighted.length > 0 && items && items.length > 0) {
            if(file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate) ) {
                totalItems = items.filter(it => !it.deleted).reduce(((total, item) => parseInt(total) + (parseInt(item.quantity) * parseFloat(item.unitAmount))), 0)
                totalWeightedCost = weighted.reduce(((total, item) => parseInt(total) + parseInt(item.amount)), 0)
                weightPercent = parseFloat(totalWeightedCost / totalItems)

                newItems = newItems.map((item) => {
                    let unitAmount = parseFloat(item.unitAmount || 0),
                        itemCost = unitAmount + (item.deleted ? 0 : (unitAmount * weightPercent))

                    return {
                        ...item,
                        unitAmount: item.unitAmount,
                        total: _.round(itemCost + ((itemCost * parseFloat(item.markupPercent)) / 100), 2) * item.quantity,
                        amount: unitAmount ? _.round(itemCost + ((itemCost * parseFloat(item.markupPercent)) / 100), 2) : item.amount,  
                        weightedCost: item.deleted ? 0 : unitAmount * weightPercent
                    }
                })
            } else {
                totalItems = items.filter(it => !it.deleted).reduce(((total, item) => parseInt(total) + (item.quantity * (item.unitAmount + ((item.unitAmount * item.markupPercent) / 100)))), 0);
                totalWeightedCost = weighted.reduce(((total, item) => parseInt(total) + (item.unitAmount + ((item.unitAmount * item.markupPercent) / 100))), 0);

                newItems = newItems.map((item) => {
                    let lineWeightedPercent = (item.quantity * (item.unitAmount + ((item.unitAmount * item.markupPercent) / 100)) * 100) / totalItems,
                        weightedCost = item.deleted ? 0 : (lineWeightedPercent * totalWeightedCost) / 100,
                        unitAmount = item.unitAmount ? item.unitAmount : 0;

                    return {
                        ...item,
                        unitAmount: item.unitAmount,
                        amount: unitAmount + ((unitAmount * parseFloat(item.markupPercent)) / 100) + (weightedCost / parseInt(item.quantity)),
                        total: item.quantity * (unitAmount + ((unitAmount * parseFloat(item.markupPercent)) / 100) + (weightedCost / parseInt(item.quantity))),
                        weightedCost
                    }
                })
            }

        }
        this.props.updateItems(newItems);
    }

    setWeightedCost = (items) => {
        this.recalculateWeightedCost(items)
    }

    updateDeletedItems=(checked, index) => {
        const newItems = [...this.state.items];
        newItems[index].deleted = checked ? 1 : 0;

        this.setState({ newItems });

        this.recalculateWeightedCost(newItems)
    }


    render() {
        const { editable, currencySymbol, classes, file } = this.props;
        let { items, openSelectVendorDialog,
            selectedRow, selectedRowIndex,
            // openSelectItemsByVendorDialog,
            clientQuoteTaxPercent,
        } = this.state;

        if (!file) return null;

        return (
            <div style={{ marginTop: '16px', width: '100%' }} >
                {editable &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px', marginBottom: 8 }}>
                        <Link className={classes.quoteItem__selectVendor__link}
                            style={{ display: 'flex' }}
                            variant='button'
                            onClick={() => {
                                this.props.showSelectorWindow()
                                // this.setState({
                                //     openSelectItemsByVendorDialog: true
                                // })
                            }}>
                            {i18n.t('quotes.preparatingQuoteInformation.selectItemsByVendor')}
                            <KeyboardArrowRight />
                        </Link>
                    </div>
                }

                <ReactTable
                    data={items}
                    columns={[
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column1'),
                            id: 'rowIndex',
                            className: classes.quoteitems__table__cell__center,
                            Cell: row => (row.index + 1),
                            width: 30
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column2'),
                            id: 'description',
                            width: editable ? 220 : undefined,
                            style: { whiteSpace: 'pre-line' },
                            Cell: this.renderItemDescription
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column4'),
                            id: 'vendor',
                            show: Permissions.checkSensitiveInformationPermission() ? true : false,
                            style: {
                                display: 'flex',
                                alignItems: 'center'
                            },
                            width: editable ? undefined : 230,
                            Cell: this.renderSelectVendorFields
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column5'),
                            width: 100,
                            id: 'cost',
                            show: Permissions.checkSensitiveInformationPermission() ? true : false,
                            style: { display: 'flex', alignItems: 'center' },
                            className: classes.quoteitems__table__cell__rigth,
                            Cell: this.renderCostsFields,
                            Footer: () => {
                                let totalCost = (file.clientQuote[0].date ? moment(file.clientQuote[0].date).isAfter(config.prorratedDate) : moment().isAfter(config.prorratedDate) ) ? items.filter(it => !it.deleted).reduce(((total, row) => parseFloat(total) + ((row.unitAmount + (row.weightedCost ? row.weightedCost : 0)) * row.quantity)), 0) : 
                                    items.filter(it => !it.deleted).reduce(((total, row) => parseFloat(total) + (row.unitAmount * row.quantity)), 0);

                                if (totalCost === 0) return null;
                                return (
                                    <div>
                                        <NumberFormat value={totalCost}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            style={{ fontSize: '16px' }}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column6'),
                            width: 100,
                            id: 'profit',
                            show: Permissions.checkSensitiveInformationPermission() ? true : false,
                            className: classes.quoteitems__table__cell__rigth,
                            style: { display: 'flex', alignItems: 'center' },
                            Cell: this.renderProfitsFields,
                            Footer: () => {
                                let quotedClientTotalProfit = items.filter(it => !it.deleted).reduce(((total, row) => {
                                    return (
                                        parseFloat(total) + parseFloat(row.markupPercent)
                                    )
                                }), 0);

                                if (quotedClientTotalProfit === 0) return null;

                                return (
                                    <div>
                                        <NumberFormat value={parseFloat(quotedClientTotalProfit / items.length).toFixed(4)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            suffix={'%'}
                                            fixedDecimalScale={true}
                                            style={{ fontSize: '16px' }}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column7'),
                            width: 120,
                            id: 'quoted',
                            show: editable && Permissions.checkSensitiveInformationPermission() ? true : false,
                            style: { display: 'flex', alignItems: 'center' },
                            className: classes.quoteitems__table__cell__rigth,
                            Cell: this.renderQuotedFields
                        }, {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column8'),
                            width: 140,
                            id: 'totalColumn',
                            style: { display: 'flex' },
                            // className: classes.quoteitems__table__cell__rigth,
                            Cell: this.renderTotalColumn,
                            Footer: () => {
                                let quotedClientTotalAmount = items.filter(it => !it.deleted).reduce(((total, row) => {
                                    return (
                                        parseFloat(total) + (
                                            row.amount ? row.total + (clientQuoteTaxPercent && row.taxPercent ? (row.total * clientQuoteTaxPercent) / 100 : 0) : 0)
                                    )
                                }), 0)


                                if (quotedClientTotalAmount === 0) return null;

                                return (
                                    <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                        <NumberFormat value={quotedClientTotalAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column10'),
                            width: 60,
                            id: 'deleteItem',
                            show: editable && Permissions.checkSensitiveInformationPermission() && items.length > 1 ? true : false,
                            style: { 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',

                            },
                            Cell: row => (
                                <IconButton
                                    // checked={row.original.deleted ? true : false}
                                    onClick={()=>this.updateDeletedItems(!row.original.deleted, row.index)}
                                >
                                    <Delete style={{color: row.original.deleted ? 'red' : 'inherit'}}/>
                                </IconButton>
                            ),
                        }
                    ]}
                    defaultPageSize={items.length}
                    className={classNames(classes.preparing__items__table, ' -striped -highlight', editable ? classes.preparing__items__table__align : '')}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filtrable={false}
                    resizable={false}
                />

                <ClientQuoteSelectVendorByItem
                    handleClose={this.cancelSelectVendor}
                    onConfirm={this.selectVendor}
                    currencySymbol={currencySymbol}
                    file={file}
                    items={items}
                    index={selectedRowIndex}
                    row={selectedRow}
                    open={openSelectVendorDialog}
                />

                {/* <ClientQuoteSelectItemsByVendor
                    handleClose={() => {
                        this.setState({
                            openSelectItemsByVendorDialog: false
                        })
                    }}
                    onConfirm={this.selectVendor}
                    currencySymbol={currencySymbol}
                    file={file}
                    items={items}
                    open={openSelectItemsByVendorDialog}
                /> */}
            </div >
        )
    }
}


export default withStyles(styles)(ClientQuoteItemsList)