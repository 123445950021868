import React, { Component } from 'react'
import {
    Link,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    CircularProgress,
    Popover,
    withStyles
} from '@material-ui/core'
import { Close, Phone } from '@material-ui/icons'
import i18n from 'i18next'
import ReactTable from 'react-table'
import API from '../../lib/api'
import moment from 'moment'
import { connect } from 'react-redux'

const style = theme => ({
    customLink: theme.customLink,
    dialog__paper: {
        width: '100%',
        overflow: 'visible',
        minHeight: 300
    },
    suggested__vendors__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    suggested__vendors__table: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    loading__container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    items__lookup__table: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    popover__content: {
        padding: theme.spacing(2)
    },
    phone__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
})


class SuggestedVendors extends Component {
    state = {
        openSuggestedVendorsDialog: false,
        fetching: false,
        suggestedVendors: [],
        clientOpen: null,
        popoverIndex: null,
        anchorElement: null,
    }
    onClose = () => {
        this.setState({
            openSuggestedVendorsDialog: false
        })
    }
    onEnter = () => {
        this.setState({
            fetching: true
        }, _ => {
            API.Items.getSuggestedVendors({ fileId: this.props.file.id })
                .then(res => {
                    if (res.status === 'success') {
                        this.setState({
                            fetching: false,
                            suggestedVendors: res.records
                        })
                    } else {
                        this.setState({
                            fetching: false,
                            suggestedVendors: []
                        })
                    }

                })
        })
    }
    renderVendorData = (row) => {
        const { classes } = this.props,
            vendor = row.lastQuotes[0].vendor,
            vendorQuote = row.lastQuotes[0].vendorQuote;
        return (
            <div className={classes.popover__content}>
                <Typography variant='body1' color='textSecondary' style={{ whiteSpace: 'pre-line', maxWidth: '300px' }}>
                    {vendor.name}
                    <br />
                    {vendor.address.address1 && (
                        <>
                            {vendor.address.address1}
                            <br />
                        </>
                    )}
                    {vendor.address.address2 && (
                        <>
                            {vendor.address.address2}
                            <br />
                        </>
                    )}
                    {vendor.address.phone1 && (
                        <>
                            <Phone className={classes.phone__icon} /> {vendor.address.phone1}
                            <br />
                        </>
                    )}
                    {vendor.address.phone2 && (
                        <>
                            +<Phone className={classes.phone__icon} />{vendor.address.phone2}
                            <br />
                        </>
                    )}
                    {vendorQuote.item.comments && (
                        <>
                            <strong>{i18n.t('lookUpItems.vendorQuoteComments')}</strong> {vendorQuote.item.comments}
                            <br />
                        </>
                    )}
                </Typography>
            </div>
        )
    }
    handleClose = () => {
        this.setState({
            popoverIndex: null,
            anchorElement: null
        })
    }
    render() {
        const { classes } = this.props,
            { openSuggestedVendorsDialog,
                fetching,
                suggestedVendors,
                popoverIndex,
                anchorElement } = this.state;
        return (
            <React.Fragment>
                <Typography>
                    <Link
                        className={classes.customLink}
                        onClick={() => {
                            this.setState({
                                openSuggestedVendorsDialog: true
                            })
                        }}
                    >
                        {i18n.t('lookUpItems.suggestedVendors')}
                    </Link>
                </Typography>
                <Dialog
                    open={openSuggestedVendorsDialog}
                    onClose={this.onClose}
                    fullWidth={true}
                    maxWidth='md'
                    onEnter={this.onEnter}
                    classes={
                        {
                            paper: classes.dialog__paper
                        }
                    }>
                    <DialogTitle disableTypography={true} style={{ position: 'relative' }}>
                        <Typography variant='body1' color="inherit">{i18n.t('lookUpItems.suggestedVendorsDialogTitle')}</Typography>
                        <IconButton
                            color="inherit"
                            style={{ position: 'absolute', right: 8, top: 4 }}
                            onClick={this.onClose}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <div className={classes.suggested__vendors__container}>
                        {fetching && (
                            <div className={classes.loading__container}>
                                <CircularProgress fontSize={30} color='secondary' />
                            </div>
                        )}
                        {!fetching && (
                            <>
                                {suggestedVendors.length > 0 && (
                                    <>
                                        <Typography color='textSecondary' variant='body1'>{i18n.t('lookUpItems.sugestedVendorsTitle')}</Typography>
                                        <ReactTable
                                            data={suggestedVendors}
                                            columns={[
                                                {
                                                    id: 'item',
                                                    Header: i18n.t('lookUpItems.item'),
                                                    accessor: row => {
                                                        return (
                                                            <div style={{ width: '100%' }}>
                                                                <Typography variant='body1' color='textPrimary'>{row.item.partNumber}</Typography>
                                                                <Typography ariant='body2' color='textSecondary' style={{ whiteSpace: 'pre-line' }}>{row.item.description}</Typography>
                                                            </div>)
                                                    }
                                                },
                                                {
                                                    id: 'vendor',
                                                    Header: i18n.t('lookUpItems.vendor'),
                                                    accessor: row => {
                                                        return (
                                                            <React.Fragment>
                                                                <Link className={classes.customLink}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            clientOpen: row.lastQuotes[0].vendor,
                                                                            popoverIndex: row.lastQuotes[0].vendorQuote.id,
                                                                            anchorElement: e.currentTarget
                                                                        })
                                                                    }}>
                                                                    {row.lastQuotes[0].vendor.name}
                                                                </Link>
                                                                <Typography variant='body2' color='textSecondary'>{i18n.t('lookUpItems.vendorQuoteId')} {row.lastQuotes[0].vendorQuote.id}</Typography>
                                                                <Popover
                                                                    anchorEl={anchorElement}
                                                                    open={popoverIndex === row.lastQuotes[0].vendorQuote.id}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    onClose={this.handleClose}
                                                                >
                                                                    {this.renderVendorData(row)}
                                                                </Popover>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                },
                                                {
                                                    id: 'date',
                                                    width: 90,
                                                    Header: i18n.t('lookUpItems.date'),
                                                    accessor: row => {
                                                        return (moment(row.lastQuotes[0].file.createDateTime).format('YYYY/MM/DD'))
                                                    }
                                                },
                                                {
                                                    id: 'price',
                                                    width: 120,
                                                    style: {
                                                        textAlign: 'right'
                                                    },
                                                    Header: i18n.t('lookUpItems.price'),
                                                    accessor: row => {
                                                        return (`${row.lastQuotes[0].clientQuote.currency ? row.lastQuotes[0].clientQuote.currency : ''} ${row.lastQuotes[0].vendorQuote.item.unitAmount}`)
                                                    }
                                                },
                                                {
                                                    id: 'quoted',
                                                    width: 120,
                                                    style: {
                                                        textAlign: 'right'
                                                    },
                                                    Header: i18n.t('lookUpItems.quoted'),
                                                    accessor: row => {
                                                        return (`${row.lastQuotes[0].clientQuote.currency ? row.lastQuotes[0].clientQuote.currency : ''} ${row.lastQuotes[0].clientQuote.amount}`)
                                                    }
                                                }
                                            ]}
                                            filterable={false}
                                            resizable={false}
                                            sortable={false}
                                            pageSize={suggestedVendors.length >= 5 ? 5 : suggestedVendors.length}
                                            showPagination={suggestedVendors.length >= 10 ? true : false}
                                            className={`${classes.items__lookup__table} -striped - highlight`}
                                        />
                                    </>
                                )}
                                {suggestedVendors.length === 0 && (
                                    <Typography color='textSecondary' variant='body1' style={{ textAlign: 'center', marginTop: 90 }}>{i18n.t('lookUpItems.noVendorsSugested')}</Typography>
                                )}
                            </>
                        )}
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
    }
}


export default connect(mapStateToProps)(withStyles(style)(SuggestedVendors))