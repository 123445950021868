export const SET_ACCOUNT = 'SET_ACCOUNT'
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'

export function setAccount(account) {
    return {
        type: SET_ACCOUNT,
        account
    }
}
export function clearAccount() {
    return {
        type: CLEAR_ACCOUNT
    }
}