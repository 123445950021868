import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

import store from '../../store'

const Events = {
    getDashboardEvents: (args) => {
        let {
            m,
            y,
            search,
            eventType
        } = args || {};

        let promise = new Promise((resolve, reject) => {
            let state = store.getState(),
                usersID = [],
                peopleFilter = state.people.filterPeople ? state.people.filterPeople.map(user => user.userId) : null

            if (peopleFilter) {
                usersID = peopleFilter.join(',')
            }

            let url = `${config.apiGateway.url}/dashboard/events?m=${m}&y=${y}${usersID ? `&userID=${usersID}` : ''}&search=${search || ''}&eventType=${eventType || ''}`;


            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    refreshEvents: () => {
        let state = store.getState();
        return Events.getEvents(state.eventsFilters);
    },
    getEvents: (args) => {
        let {
            m,
            y,
            from,
            to,
            search,
            usersID,
            client,
            type,
            missedCheckIn
        } = args || {};

        let promise = new Promise((resolve, reject) => {

            // let state = store.getState();
            // let peopleFilter = (state.people.filterPeople && state.people.filterPeople.filter(p => p.userId).length > 0) ?
            //     state.people.filterPeople.map(p => p.userId) : usersID ? [usersID] : 
            //     state.people.allPeople.map(p => p.userId);

            // if (peopleFilter) {
            //     usersID = (usersID ? usersID + ',' : '') + peopleFilter.join(',')
            // }


            let url = `${config.apiGateway.url}/events?search=${search || ""}`;

            url += m ? `&m=${m}` : ''
            url += y ? `&y=${y}` : ''
            url += from ? `&from=${from}` : ''
            url += to ? `&to=${to}` : ''
            url += usersID ? `&userIDs=${usersID}` : ''
            url += client ? `&client=${client}` : ''
            url += type ? `&type=${type}` : ''
            url += missedCheckIn ? `&missedCheckIn=${missedCheckIn}` : ''

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getEvent: (args) => {
        let { eventId } = args || {};

        let promise = new Promise((resolve, reject) => {

            let url = `${config.apiGateway.url}/events/${eventId}`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createEvent: (args) => {
        let { event } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/events`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(event),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateEvent: (args) => {
        let { event } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/events/${event.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(event),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteEvent: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/events/${args.eventId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Events;