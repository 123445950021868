import React, { Component } from 'react'
import {
    Button,
    CircularProgress,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    LinearProgress,
    Link,
    withStyles
} from '@material-ui/core'
import {
    NavigateNext,
    NavigateBefore,
    Send,
    Drafts,
    DoneOutline,
    Close,
    Sync
} from '@material-ui/icons'

import classNames from 'classnames'
import i18n from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as vendorQuotesActions from '../../../actions/vendorQuotesActions'
import * as vendorsActions from '../../../actions/vendorsActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'


import { ConfirmationReturnStatusDialog, ConfirmationDialog, ConfirmationReturnStatusDialog as ConfirmationDeleteDialog } from '../../general'
import { SlideUp } from '../../general/Transitions'
import API from '../../../lib/api'


const styles = theme => ({
    customLink: { ...theme.customLink, marginLeft: '5px' },
    action__bar__actionButton__current: {
        width: '190px',
        backgroundColor: "#009688",
        '&:hover': {
            backgroundColor: "#4fccc4",
            color: '#fff',
        },
        color: theme.palette.getContrastText("#009688"),
    },
    subaction__bar__actionButton: {
        marginTop: theme.spacing() / 2,
        padding: '5px',
        width: '190px',
        '&:disabled': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    subaction__bar__actionButton__next: {
        backgroundColor: "#009688",
        color: 'white',
        width: '190px',
        '&:hover': {
            backgroundColor: "#4fccc4",
            filter: 'brightness(1.1);'
        }
    },
    subaction__bar__actionButton__reject: {
        backgroundColor: theme.palette.red[100],
        marginLeft: theme.spacing(),
        color: 'white',
        width: '190px',
        '&:hover': {
            backgroundColor: theme.palette.red[100],
            filter: 'brightness(1.1);'
        }
    },
    subactions__separator: { height: '40px', width: '100%' },
    action__bar__buttons__container: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-end',
            flexDirection: 'row'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: 'column'
        },
    },
    vendor__status__icon__preparing: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    vendor__status__icon__requested: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.primary.main,
    },
    vendor__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    action__bar__currentstatus: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing()
    },
    dialog__content__container: {
        padding: theme.spacing(2)
    },
    dialog__loader__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing()
    },
    dialog__paper: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0
    },
    footer__bottons__container: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    subaction__bar__actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        margin: '0 0 5px 5px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    subaction__bar__actionButton__prev: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        margin: '0 5px 5px 0',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    currentStatus__message__label: {
        textAlign: 'center',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2)
    },
    action__bar__missingcondition__label: {
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: 'orange',
        width: '100%',
        whiteSpace: 'pre-line'
    },
    action__buttons__container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    print__component__container: {
        marginTop: theme.spacing(),
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
    }

})

//Current status indicator component
const statusIndicatorStyles = theme => ({
    statusIndicatorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    statusIndicator__labelsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    rootBar: {
        height: theme.spacing(2)
    },
    bar1Determinate: {
        backgroundColor: theme.palette.blue[70]
    },
    bardeterminate: {
        backgroundColor: theme.palette.grey[200]
    },
    step__label: {
        color: theme.palette.text.secondary
    },
    'step__label--done': {
        color: theme.palette.blue[70]
    }
})


class VendorQuoteStatusIndicatorComponent extends Component {
    render() {
        const { classes, vendorQuote } = this.props;
        let progressPercent = 0,
            stepsDone = 1;
        const statuses = [
            i18n.t("vendorQuoteStatus.preparing"),
            i18n.t("vendorQuoteStatus.requested"),
            i18n.t("vendorQuoteStatus.ready")
        ]

        switch (vendorQuote.status.id) {
            case (1):
                progressPercent = 10;
                stepsDone = 1;
                break;
            case (2):
            case (4):
                progressPercent = 50;
                stepsDone = 2;
                break;
            case (3):
                progressPercent = 100;
                stepsDone = 3;
                break;
            default:
                break;
        }
        return (
            <div className={classes.statusIndicatorContainer}>
                <LinearProgress
                    variant="determinate"
                    value={progressPercent}
                    thickness={80}
                    classes={{
                        root: classes.rootBar,
                        determinate: classes.bardeterminate,
                        bar1Determinate: classes.bar1Determinate,

                    }} />
                <div className={classes.statusIndicator__labelsContainer}>
                    {statuses.map((status, i) => (
                        <div key={i}>
                            <Typography
                                className={stepsDone >= (i + 1) ? classes['step__label--done'] : classes.step__label}>
                                {status}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        )

    }
}

const VendorQuoteStatusIndicator = withStyles(statusIndicatorStyles)(VendorQuoteStatusIndicatorComponent)

class VendorQuoteActions extends Component {
    state = {
        loading: false,
        saving: false,
        openActionsDialog: false,
        cancelLabel: '',
        confirmLabel: '',
        message: '',
        file: this.props.file,
        openConfirmationDialog: false,
        openConfirmationDeleteDialog: false,
        apiErrorMessage: null,
        callCustomAction: false,
        deleting: false,
        isNonResponsive: false,
        openConfirmCreateNewQuote: false,
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file
        })
    }

    showActionsDialog = () => {
        this.setState({
            openActionsDialog: true
        })
    }
    hideActionsDialog = () => {
        this.setState({
            openActionsDialog: false,
            enteringPreparing: false
        })
    }

    confirmDeleteVendorQuote = () => {
        this.setState({
            cancelLabel: i18n.t('quotes.vendorQuoteRequest.confirmation.cancelDelete'),
            confirmLabel: i18n.t('quotes.vendorQuoteRequest.confirmation.confirmDeleteLabel'),
            message: i18n.t('quotes.vendorQuoteRequest.confirmation.confirmDeleteVendorQuoteMessage'),
            openConfirmationDeleteDialog: true,
            deleting: true
        })
    }

    cancelConfirmationReturn = () => {
        this.setState({
            deleting: false,
            openConfirmationDialog: false,
            openConfirmationDeleteDialog: false,
            isNonResponsive: false
        })
    }

    deleteVendorQuote = ({ comments }) => {
        const { vendorQuote } = this.props
        this.setState({
            saving: true
        })

        API.Quotes.deleteVendorRFQ({ vendorQuoteId: vendorQuote.id, comments }).then(res => {
            if (res.status === 'success') {

                this.setState({
                    openConfirmationDeleteDialog: false,
                    saving: false,
                    deleting: false
                })
                this.props.vendorQuotesActions.deleteVendorQuote(vendorQuote)
                this.props.vendorsActions.setVendor({})
                this.props.deleteVendorQuote && this.props.deleteVendorQuote(vendorQuote)
                this.hideActionsDialog();
            } else {
                this.setState({
                    openConfirmationDeleteDialog: false,
                    saving: false,
                    deleting: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    closeActionsDialogAndCallCustomAction = () => {
        this.setState({
            openActionsDialog: false,
            callCustomAction: true,
        })
    }

    onExited = () => {
        const { vendorQuote } = this.props,
            { callCustomAction, callUpdateAction, file } = this.state;

        if (callCustomAction) {
            switch (vendorQuote.status.id) {
                case 1:
                    this.props.generalActions && this.props.generalActions.showAddVendorQuoteItem(true);
                    break;
                case 2:
                    this.props.generalActions && this.props.generalActions.showEditVendorQuote(true, vendorQuote.id);
                    break;
                case 3:
                default:
                    break;
            }
            this.setState({
                callCustomAction: false
            })
        } else if (callUpdateAction) {
            this.setState({
                callUpdateAction: false
            })
            this.props.updateVendorQuote({ file: file, vendorQuote: this.state.vendorQuote })
        }
    }

    confirmReturnVendorQuote = ({ isNonResponsive }) => {

        this.setState({
            openConfirmationDialog: true,
            isNonResponsive
        })
    }


    confirmChangeVendorRFQStatus = (body) => {
        const { vendorQuote } = this.props,
            { isNonResponsive } = this.state;

        this.changeVendorRFQStatus(isNonResponsive ? 4 : vendorQuote && vendorQuote.status.id === 3 ? 2 : vendorQuote && vendorQuote.status.id === 2 ? 1 : 2, body.comments)
    }



    changeVendorRFQStatus = (status, returnComments) => {
        const { vendorQuote } = this.props;

        if (vendorQuote.status.id === 1 && status === 2 && vendorQuote.items.filter(it => !it.delete).length === 0) {
            this.props.showError({
                changeStatusMessage: `${i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage1To2')}`
            })
            return
        }

        if (vendorQuote.items.filter(item => (!item.unitAmount || !item.quantity) && !item.delete).length > 0 && vendorQuote.status.id === 2 && status === 3) {
            this.props.showError({
                changeStatusMessage: `${i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage2To3')}`
            })
            return
        }

        this.setState({
            saving: true,
            openConfirmationDialog: false
        })

        let body = {
            id: vendorQuote.id,
            statusId: status
        }

        if (returnComments) {
            body.returnReasonComments = returnComments
        }

        API.Quotes.changeStatusVendorRFQ({ body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    file: res.data.record,
                    openConfirmationDialog: false,
                    vendorQuote: res.data.record.vendorQuotes.find(vq => vq.id === vendorQuote.id),
                    callUpdateAction: true
                }, () => {
                    this.hideActionsDialog()
                })
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    createNewQuoteVersion = () => {
        const { vendorQuote } = this.props;

        this.setState({
            loading: true,
        })

        let body = {
            id: null,
            parentId: vendorQuote.parentId ? vendorQuote.parentId : vendorQuote.id,
            version: vendorQuote.version ? parseInt(vendorQuote.version) + 1 : 1,
            fileId: vendorQuote.fileId,
            vendorId: vendorQuote.vendor.id,
            vendorContactId: vendorQuote.contact.id,
            deliveryDays: vendorQuote.deliveryDays,
            quoteTermsReference: vendorQuote.quoteTermsReference,
            paymentTermsId: vendorQuote.paymentTermsId,
            quoteTermsId: vendorQuote.quoteTermsId,
            comments: vendorQuote.comments,
            otherCosts: vendorQuote.otherCosts ? vendorQuote.otherCosts.map((voc, i) => {
                return {
                    otherCostId: voc.id ? voc.id : voc.otherCostId,
                    amount: voc.amount,
                    comments: voc.comments,
                    deleted: voc.deleted
                }
            }) : [],
            items: vendorQuote.items.map(item => { 
                return {
                    ...item,
                    include: true
                }
            })
        }

        API.Quotes.createVendorRFQ({ body }).then(res => {
            if (res.data) {
                this.setState({
                    loading: false,
                    openConfirmCreateNewQuote: false,
                })
                this.props.updateVendorQuote({ file: res.data.record, vendorQuote: null })

            } else {
                this.setState({
                    loading: false,
                    openConfirmCreateNewQuote: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
            this.hideActionsDialog();
        })

    }


    render() {
        const {
            loading,
            saving,
            openActionsDialog,
            message,
            cancelLabel,
            confirmLabel,
            openConfirmationDialog,
            openConfirmationDeleteDialog,
            deleting,
            file,
            isNonResponsive,
            openConfirmCreateNewQuote
        } = this.state,
            { classes, vendorQuote,
                //fromEditForm, 
                editable } = this.props;

        if (!vendorQuote || !file) return null;


        let disableNextCondition = false,
            missingConditionMessage = '';

        if (vendorQuote.status.id === 1 && vendorQuote.items.filter(it => !it.delete).length === 0) {
            disableNextCondition = true;
            missingConditionMessage = i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage1To2');
        }

        if (vendorQuote.status.id === 2 && vendorQuote.items.filter(item => (!item.unitAmount || !item.quantity) 
            && !item.delete).length > 0 && (!vendorQuote.deliveryDays || !vendorQuote.paymentTermsId || !vendorQuote.quoteTermsId || !vendorQuote.quoteTermsReference) ) {
            disableNextCondition = true;
            missingConditionMessage = i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage2To3MissingAll');
        } else {

            if (vendorQuote.status.id === 2 && (vendorQuote.items.filter(item => (!item.unitAmount || !item.quantity) && !item.delete).length > 0)) {
                disableNextCondition = true;
                missingConditionMessage = i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage2To3');
            }

            if (vendorQuote.status.id === 2 && (!vendorQuote.deliveryDays || !vendorQuote.paymentTermsId || !vendorQuote.quoteTermsId || !vendorQuote.quoteTermsReference)) {
                disableNextCondition = true;
                missingConditionMessage = i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage2To3DeliveryDays');
            }
        }

        const createNewQuoteMessage = `A new editable version of the Vendor Quote will be created. Previous versions won't be editable.`

        let maxVersion = 0,
            statusName = vendorQuote.status.name;

        if(vendorQuote.parentId) {
            let parent = file.vendorQuotes.find(vq => vq.id === vendorQuote.parentId);
            maxVersion = parent.quotesVersions.reduce(
                (max, vqVersion) => (vqVersion.version > max ? vqVersion.version : max),
                0
            );
        } else {
            if(vendorQuote.quotesVersions.length > 0){
                maxVersion = vendorQuote.quotesVersions.reduce(
                    (max, vqVersion) => (vqVersion.version > max ? vqVersion.version : max),
                    0
                );
            }
        }

        if(vendorQuote.version < maxVersion) {
            statusName = 'Requoted';
        }

        return (
            <div>
                {editable && (
                    <div className={classes.action__bar__buttons__container}>
                        {!loading && (
                            <div className={classes.action__bar__currentstatus}>
                                { statusName === 'Requoted' ?
                                    <Sync style={{ color: '#ccc' }} className={classes.vendor__status__icon__ready} />
                                    :
                                    <>
                                        {vendorQuote.status.id === 1 && (
                                            <Drafts className={classes.vendor__status__icon__preparing} />
                                        )}
                                        {vendorQuote.status.id === 2 &&  (
                                            <Send className={classes.vendor__status__icon__requested} />
                                        )}
                                        {vendorQuote.status.id === 3 && (
                                            <DoneOutline className={classes.vendor__status__icon__ready} />
                                        )}
                                    </>
                                }

                                <Typography color='textSecondary'>{statusName}</Typography>
                            </div>
                        )}
                        { vendorQuote.status.id <= 3 && (((!vendorQuote.parentId && (!vendorQuote.quotesVersions || vendorQuote.quotesVersions.length === 0)) || (vendorQuote.parentId && vendorQuote.version === maxVersion))) &&
                            <Button
                                size="small"
                                className={classNames(classes.action__bar__actionButton__current)}
                                variant='contained'
                                disabled={loading}
                                onClick={this.showActionsDialog}
                            >
                                {vendorQuote.status.id === 1 && i18n.t("quotes.vendorQuoteRequest.updateAsRequested")}
                                {vendorQuote.status.id === 2 && i18n.t('quotes.vendorQuoteRequest.vendorRequestActions')} 
                                {vendorQuote.status.id === 3 && i18n.t('quotes.vendorQuoteRequest.returnToRequested')}
                                {vendorQuote.status.id === 4 && i18n.t('quotes.vendorQuoteRequest.returnToRequested')}

                                {/* {i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatus')} */}
                            </Button>
                        }
                    </div>
                )}


                <Dialog
                    maxWidth='sm'
                    open={openActionsDialog}
                    scroll='paper'
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    onExited={this.onExited}
                    TransitionComponent={SlideUp}>
                    <DialogTitle disableTypography={true} >
                        {i18n.t('quotes.actionsBar.vendorRFQ')} {vendorQuote.id} - {vendorQuote.vendor.name}
                        <IconButton color="inherit"
                            disabled={loading}
                            onClick={this.hideActionsDialog}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ padding: '0' }}>
                        <div className={classes.dialog__content__container}>

                            <VendorQuoteStatusIndicator vendorQuote={vendorQuote} />

                            {(loading || saving || deleting) && (
                                <div className={classes.dialog__loader__container}>
                                    <CircularProgress color='secondary' />
                                </div>
                            )}

                            <Typography className={classes.currentStatus__message__label}>
                                {vendorQuote.status.id === 1 && i18n.t('actionBarMessages.vendorQuotePreparing')}
                                {vendorQuote.status.id === 2 && i18n.t('actionBarMessages.vendorQuoteRequested')}
                                {vendorQuote.status.id === 3 && i18n.t('actionBarMessages.vendorQuoteReady')}
                                {vendorQuote.status.id === 4 && i18n.t('actionBarMessages.vendorQuoteNonResponsive')}
                            </Typography>


                            <div className={classes.action__buttons__container}>
                                {disableNextCondition && (
                                    <Typography className={classes.action__bar__missingcondition__label}>
                                        {missingConditionMessage}
                                        <br />
                                        {vendorQuote.items.filter(it => !it.delete).length === 0 ? (
                                            <Link className={classes.customLink} onClick={this.closeActionsDialogAndCallCustomAction}>{i18n.t('quotes.vendorQuoteRequest.addSomeItems')}</Link>
                                        ) : <Link className={classes.customLink} onClick={this.closeActionsDialogAndCallCustomAction}>{i18n.t('quotes.vendorQuoteRequest.finishVendorQuote')}</Link>}
                                    </Typography>

                                )}

                                <div style={{ display: 'flex', marginTop: '20px' }}>

                                    {vendorQuote.status.id < 3 && (
                                        <Button
                                            size="small"
                                            className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__next)}
                                            variant="contained"
                                            disabled={loading || saving || deleting || disableNextCondition}
                                            onClick={() => {
                                                this.changeVendorRFQStatus(vendorQuote.status.id === 1 ? 2 : 3, null);
                                            }}>

                                            {vendorQuote.status.id === 1 && i18n.t("quotes.vendorQuoteRequest.updateAsRequested")}
                                            {vendorQuote.status.id === 2 && i18n.t("quotes.vendorQuoteRequest.updateAsReady")}
                                            <NavigateNext />
                                        </Button>
                                    )}

                                    {(vendorQuote.status.id === 2 && (vendorQuote.items.filter(item => (!item.unitAmount || !item.quantity) && !item.delete).length > 0 || !vendorQuote.deliveryDays)) ?
                                        <Button
                                            size="small"
                                            className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__reject)}
                                            variant="contained"
                                            style={{ marginLeft: '10px' }}
                                            disabled={loading || saving || deleting}
                                            onClick={() => this.confirmReturnVendorQuote({ isNonResponsive: true })}>

                                            {i18n.t("quotes.vendorQuoteRequest.nonResponsive")}
                                        </Button>
                                        : 
                                        <div/>
                                    }

                                </div>

                                {vendorQuote.status.id === 2 && file.status < 5 &&
                                    <div style={{margin: '20px 0'}}>
                                        <Button
                                            size="small"
                                            className={classNames(classes.subaction__bar__actionButton,
                                                classes.subaction__bar__actionButton__next)}
                                            style={{backgroundColor: 'rgba(30, 144, 255, 1)'}}
                                            variant="contained"
                                            disabled={loading || saving || deleting}
                                            onClick={()=>{
                                                this.setState({
                                                    openConfirmCreateNewQuote: true
                                                })
                                            }}>
                                            {i18n.t("quotes.vendorQuoteRequest.createNewQuote")}
                                        </Button>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className={classes.footer__bottons__container}>
                            {(vendorQuote.status.id !== 1) &&
                                <div />
                            }
                            {(vendorQuote.status.id === 1) && //!fromEditForm &&
                                <Button
                                    className={classes.subaction__bar__actionButton__delete}
                                    disabled={loading || deleting}
                                    onClick={this.confirmDeleteVendorQuote}>
                                    {i18n.t('quotes.vendorQuoteRequest.deleteVendorRequest')}
                                </Button>
                            }


                            {(vendorQuote.status.id > 1) && (
                                <Button
                                    disabled={loading || saving}
                                    className={classNames(classes.subaction__bar__actionButton,
                                        classes.subaction__bar__actionButton__prev)}
                                    onClick={() => this.confirmReturnVendorQuote({ isNonResponsive: false })}>
                                    <NavigateBefore />
                                    {(vendorQuote.status.id === 2) && i18n.t('quotes.vendorQuoteRequest.returnToPreparing')}

                                    {(vendorQuote.status.id === 3 || vendorQuote.status.id === 4) && i18n.t('quotes.vendorQuoteRequest.returnToRequested')}

                                </Button>
                            )}


                        </div>

                    </DialogContent>
                </Dialog>


                <ConfirmationDeleteDialog
                    handleClose={this.cancelConfirmationReturn}
                    onConfirm={this.deleteVendorQuote}
                    message={message}
                    loading={saving}
                    inputLabel={i18n.t('quotes.vendorQuoteRequest.confirmation.explainReason')}
                    cancelLabel={cancelLabel}
                    confirmLabel={confirmLabel}
                    open={openConfirmationDeleteDialog}
                />

                <ConfirmationReturnStatusDialog
                    handleClose={this.cancelConfirmationReturn}
                    onConfirm={this.confirmChangeVendorRFQStatus}
                    message={!isNonResponsive ?
                        `${i18n.t('quotes.vendorQuoteRequest.confirmation.confirmReturnVendorQuoteMessage')} ${vendorQuote && (vendorQuote.status.id === 3 || vendorQuote.status.id === 4) ? 'Requested' : 'Preparing'} ? ` :
                        `${i18n.t('quotes.vendorQuoteRequest.confirmation.confirmNonResponsiveMessage')}`}
                    loading={saving}
                    inputLabel={i18n.t('quotes.vendorQuoteRequest.confirmation.explainReason')}
                    cancelLabel={i18n.t('quotes.vendorQuoteRequest.confirmation.cancel')}
                    confirmLabel={!isNonResponsive ?
                        `${i18n.t('quotes.vendorQuoteRequest.confirmation.confirmLabel')} ${vendorQuote && (vendorQuote.status.id === 3 || vendorQuote.status.id === 4) ? 'Requested' : 'Preparing'}` :
                        `${i18n.t('quotes.vendorQuoteRequest.confirmation.confirmLabelNonResponsive')}`
                    }
                    open={openConfirmationDialog}
                />


                <ConfirmationDialog
                    handleClose={()=>{
                        this.setState({
                            openConfirmCreateNewQuote: false
                        })
                    }}
                    onConfirm={this.createNewQuoteVersion}
                    message={createNewQuoteMessage}
                    loading={loading}
                    cancelLabel={i18n.t("quotes.actionsBar.cancelNextStatusLabel")}
                    confirmLabel={i18n.t("quotes.actionsBar.confirmNextStatusLabel")}
                    open={openConfirmCreateNewQuote}
                />  


            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch),
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuoteActions))