import React, { Component } from 'react'
import {
    Dialog,
    Link,
    withStyles,
    DialogTitle,
    Typography,
    IconButton,
    Button,
    Grid,
    Card,
    CircularProgress,
    Popover
} from '@material-ui/core'
import { Close, Phone, Search } from '@material-ui/icons'
import ReactTable from 'react-table'
import Select from 'react-select';
import i18n from 'i18next'
import moment from 'moment'
import API from '../../lib/api';


const style = theme => ({
    customLink: theme.customLink,
    items__lookup__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    dialog__paper: {
        width: '100%',
        overflow: 'visible'
    },
    items__lookup__table: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    popover__content: {
        padding: theme.spacing(2)
    },
    phone__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    last__item__container: {
        padding: theme.spacing()
    },
    search__icon: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    itemSelector__container:{
        padding: theme.spacing(2),
    },
    noteForm__select: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        fontFamily: theme.typography.fontFamily
    },
    noteForm__users_select: {
        zIndex: 50,
        width: '50%'
    },
})

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',
        fontFamily: 'inherit',
        '&:hover': {
            borderColor: '#000',
        }
    }),
}

class PreviousQuotedItems extends Component {
    state = {
        openItemsLookupDialog: false,
        clientOpen: null,
        popoverIndex: null,
        anchorElement: null,
        fetching: false,
        itemData: null,
        items: [],
        selectedItem: '',
        showError: false
    }
    handleClose = () => {
        this.setState({
            popoverIndex: null,
            anchorElement: null
        })
    }
    fetchQuotedItem = (partNumber, returnOneResult) => {
        if(partNumber.length < 3){
            this.props.fetchingItem(true);

            return
        }

        this.props.fetchingItem();

        this.setState({
            fetching: true,
            lastPartNumber: partNumber
        }, () => {
            API.Items.getQuotedItem({ partNumber, returnOneResult })
                .then(res => {
                    if (res.status === 'success') {
                        let itemData = res.data;
                        this.props.fetched();

                        if (itemData.record && itemData.record.item) {
                            this.setState({ 
                                openItemsLookupDialog: true,
                                itemData 
                            })
                        } 
                        
                        if (itemData.items) {
                            this.setState({
                                openItemsLookupDialog: true,
                                items: itemData.items,
                                itemData: null
                            })
                        }
                        
                        if (!itemData.record && !itemData.items){
                            this.setState({ 
                                itemData: null,
                                openItemsLookupDialog: true
                            })
                        }

                    } else {
                        this.setState({ itemData: null })
                    }
                    this.setState({
                        typing: false,
                        fetching: false
                    })
                })
        })
    }
    renderClientData = (client) => {
        const { classes } = this.props;
        return (
            <div className={classes.popover__content}>
                <Typography variant='body1' color='textSecondary'>
                    {client.name}
                    <br />
                    {client.taxId && (
                        <>
                            {client.taxId}
                            <br />
                        </>
                    )}
                    {client.country.name}
                    <br />
                    {client.address.address1 && (
                        <>
                            {client.address.address1}
                            <br />
                        </>
                    )}
                    {client.address.address2 && (
                        <>
                            {client.address.address2}
                            <br />
                        </>
                    )}
                    {client.address.phone && (
                        <>
                            <Phone className={classes.phone__icon} /> {client.address.phone}
                            <br />
                        </>
                    )}
                    {client.address.fax && (
                        <>
                            <strong>{i18n.t('quotes.clientRFQ.fax')}</strong> {client.address.fax}
                            <br />
                        </>
                    )}
                </Typography>
            </div>
        )
    }
    renderVendorData = (row) => {
        const { classes } = this.props,
            vendor = row.vendor,
            vendorQuote = row.vendorQuote;
        return (
            <div className={classes.popover__content}>
                <Typography variant='body1' color='textSecondary' style={{ whiteSpace: 'pre-line', maxWidth: '300px' }}>
                    {vendor.name}
                    <br />
                    {vendor.address.address1 && (
                        <>
                            {vendor.address.address1}
                            <br />
                        </>
                    )}
                    {vendor.address.address2 && (
                        <>
                            {vendor.address.address2}
                            <br />
                        </>
                    )}
                    {vendor.address.phone1 && (
                        <>
                            <Phone className={classes.phone__icon} /> {vendor.address.phone1}
                            <br />
                        </>
                    )}
                    {vendor.address.phone2 && (
                        <>
                            +<Phone className={classes.phone__icon} />{vendor.address.phone2}
                            <br />
                        </>
                    )}
                    {vendorQuote.item.comments && (
                        <>
                            <strong>{i18n.t('lookUpItems.vendorQuoteComments')}</strong> {vendorQuote.item.comments}
                            <br />
                        </>
                    )}
                </Typography>
            </div>
        )
    }
    useItem = () => {
        const { itemData } = this.state,
            { index } = this.props;
        this.props.setItem(itemData, index)
        this.close()
    }
    close = () => {
        this.setState({
            openItemsLookupDialog: false
        })
    }

    handleChange = (item) => {
        if(item){
            this.fetchQuotedItem(item.value, 1)
        } else {
            this.setState({ itemData: null })
        }

        this.setState({
            selectedItem: item
        })
    }

    render() {
        const { classes, partNumber } = this.props,
            { openItemsLookupDialog, popoverIndex, anchorElement,
                fetching, itemData, items, selectedItem } = this.state;

        let item = itemData && itemData.record && itemData.record.item ? itemData.record.item : null,
            lastQuotes = itemData && itemData.record && itemData.record.lastQuotes ? itemData.record.lastQuotes : [];

        return (
            <React.Fragment>
                {fetching && (
                    <CircularProgress color='secondary' size={24} />
                )}
                {!fetching && (
                    <IconButton
                        className={classes.search__icon}
                        disableRipple={true}
                        onClick={() => {
                            this.fetchQuotedItem(this.props.partNumber, 0)
                        }}>
                        <Search size={24} />
                    </IconButton>
                )}

                <Dialog
                    open={openItemsLookupDialog}
                    onClose={() => {
                        this.setState({
                            openItemsLookupDialog: false
                        })
                    }}
                    fullWidth={true}
                    maxWidth={item ? 'md' : 'sm'}
                    classes={
                        {
                            paper: classes.dialog__paper
                        }
                    }>
                    <DialogTitle disableTypography={true} style={{ position: 'relative' }}>
                        <Typography variant='body1' color="inherit">{i18n.t('lookUpItems.dialogTitle')}</Typography>
                        <IconButton
                            color="inherit"
                            style={{ position: 'absolute', right: 8, top: 4 }}
                            onClick={this.close}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    {items.length > 0 && 
                        <div className={classes.itemSelector__container} style={{height: item ? 'auto' : '50vh'}}>
                            <Select
                                required
                                isClearable={true}
                                value={selectedItem}
                                className={`${classes.noteForm__select} ${classes.noteForm__users_select}`}
                                placeholder={i18n.t("lookUpItems.selectItem")}
                                onChange={this.handleChange}
                                options={items.map(it =>{
                                    return {
                                        label: it,
                                        value: it
                                    }
                                })}
                                
                                maxMenuHeight='150'
                                styles={{
                                    ...customStyles,
                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                }}
                                menuPosition='fixed'
                                menuPlacement='auto'
                            />
                            {fetching && (
                                <div style={{ margin: '48px auto', width: '100%', textAlign: 'center' }}>
                                    <CircularProgress color='secondary' size={24} />
                                </div>
                            )}

                        </div>
                    }

                    {item && !fetching &&(
                        <div className={classes.items__lookup__container} >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='body2' color='textSecondary'><strong>{i18n.t('lookUpItems.itemInfo')}</strong></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={classes.last__item__container}>
                                        <Typography variant='body1' color='textPrimary'>{item.partNumber}</Typography>
                                        {item.manufacturer && (
                                            <Typography variant='body1' color='textSecondary'>{item.manufacturer}</Typography>
                                        )}
                                        {item.description && (
                                            <Typography variant='body2' color='textSecondary'>{item.description}</Typography>
                                        )}
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: 20
                                        }}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={this.useItem}>
                                                {i18n.t('lookUpItems.useItem')}
                                            </Button>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' color='textSecondary'>{i18n.t('lookUpItems.otherfilesUsingItem')}<strong>{partNumber}</strong></Typography>
                                    <ReactTable
                                        data={lastQuotes}
                                        columns={[
                                            {
                                                id: 'client',
                                                Header: i18n.t('lookUpItems.client'),
                                                accessor: row => {
                                                    return (
                                                        <React.Fragment>
                                                            <Link className={classes.customLink}
                                                                onClick={(e) => {
                                                                    this.setState({
                                                                        clientOpen: row.client,
                                                                        popoverIndex: row.file.id,
                                                                        anchorElement: e.currentTarget
                                                                    })
                                                                }}>
                                                                {row.client.name} {row.file.id}
                                                            </Link>
                                                            <Typography variant='body2' color='textSecondary'>{i18n.t('lookUpItems.file')} {row.file.id}</Typography>
                                                            <Popover
                                                                anchorEl={anchorElement}
                                                                open={popoverIndex === row.file.id}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                                onClose={this.handleClose}
                                                            >
                                                                {this.renderClientData(row.client)}
                                                            </Popover>
                                                        </React.Fragment>)
                                                }
                                            },
                                            {
                                                id: 'date',
                                                width: 90,
                                                Header: i18n.t('lookUpItems.date'),
                                                accessor: row => {
                                                    return (moment(row.file.createDateTime).format('YYYY/MM/DD'))
                                                }
                                            },
                                            {
                                                id: 'vendor',
                                                Header: i18n.t('lookUpItems.vendor'),
                                                accessor: row => {
                                                    return (
                                                        <React.Fragment>
                                                            <Link className={classes.customLink}
                                                                onClick={(e) => {
                                                                    this.setState({
                                                                        clientOpen: row.vendor,
                                                                        popoverIndex: row.vendorQuote.id,
                                                                        anchorElement: e.currentTarget
                                                                    })
                                                                }}>
                                                                {row.vendor.name}
                                                            </Link>
                                                            <Typography variant='body2' color='textSecondary'>{i18n.t('lookUpItems.vendorQuoteId')} {row.vendorQuote.id}</Typography>
                                                            <Popover
                                                                anchorEl={anchorElement}
                                                                open={popoverIndex === row.vendorQuote.id}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                                onClose={this.handleClose}
                                                            >
                                                                {this.renderVendorData(row)}
                                                            </Popover>
                                                        </React.Fragment>
                                                    )
                                                }
                                            },
                                            {
                                                id: 'price',
                                                width: 120,
                                                style: {
                                                    textAlign: 'right'
                                                },
                                                Header: i18n.t('lookUpItems.price'),
                                                accessor: row => {
                                                    return (`${row.clientQuote.currency ? row.clientQuote.currency : ''} ${row.vendorQuote.item.unitAmount || 0}`)
                                                }
                                            },
                                            {
                                                id: 'quoted',
                                                width: 120,
                                                style: {
                                                    textAlign: 'right'
                                                },
                                                Header: i18n.t('lookUpItems.quoted'),
                                                accessor: row => {
                                                    return (`${row.clientQuote.currency ? row.clientQuote.currency : ''} ${row.clientQuote.amount || 0}`)
                                                }
                                            }
                                        ]}
                                        filterable={false}
                                        resizable={false}
                                        sortable={false}
                                        pageSize={lastQuotes.length >= 5 ? lastQuotes.length : 5}
                                        showPagination={false}
                                        className={`${classes.items__lookup__table} -striped - highlight`}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {!item && items.length === 0 && (
                        <div className={classes.items__lookup__container}>
                            <Typography color='textSecondary' variant='body1' style={{ textAlign: 'center' }}>{i18n.t('lookUpItems.noItem')}<strong>{partNumber}</strong></Typography>
                        </div>
                    )}
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(PreviousQuotedItems)
