import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    Done
} from '@material-ui/icons'
import { SlideLeft } from '../general/Transitions'
import { GlobalParametersContext } from '../../contexts/globalParametersContext'

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    events__form__title__back: {
        fontSize: '30px',
        color: '#ccc'
    },
    events__fab: {
        backgroundColor: "#fff",
        color: '#0078d7',
        width: '36px',
        height: '36px'
    },
    form: {
        height: '100%'
    }
});

class ListEventCountryItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.countryId} data-selected={item.selected} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {item.countryName}
                {item.selected ? <Done style={{ color: '#3f97f6' }} /> : ''}
            </li>
        )
    }
}

class EventCountryList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            country: props.country ? props.country : null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            country: nextProps.country ? nextProps.country : null,
        })
    }

    selectCountry = (e, countries) => {
        this.props.setCountry({
            id: countries[e.index].countryId,
            name: countries[e.index].countryName
        })

        this.setState({
            country: {
                id: countries[e.index].countryId,
                name: countries[e.index].countryName
            }
        })

    }

    render() {
        const { classes } = this.props,
            { country } = this.state;


        return (
            <GlobalParametersContext.Consumer>
                {({ countries }) => (
                    <Dialog
                        classes={{
                            paper: classes.dialog__paper
                        }}
                        scroll='paper'
                        fullScreen={true}
                        open={this.props.open}
                        TransitionComponent={SlideLeft}
                    >

                        <DialogTitle disableTypography={true} className={classes.events__form__title}>
                            <IconButton onClick={this.props.onClose}>
                                <KeyboardArrowLeft className={classes.events__form__title__back} />
                            </IconButton>
                            <Typography variant='body1' color="inherit">
                                {i18n.t('visits.eventForm.selectConuntry')}
                            </Typography>
                            <div style={{ width: '23px' }} />
                        </DialogTitle>

                        <DialogContent style={{ padding: '0' }}>
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Listview
                                        lang="es"
                                        theme="ios"
                                        itemType={ListEventCountryItem}
                                        onItemTap={(e) => this.selectCountry(e, countries)}
                                        data={countries ? countries.map(i => {
                                            if (country && i.countryId === country.id) {
                                                return {
                                                    ...i,
                                                    selected: true
                                                }
                                            } else {
                                                return {
                                                    ...i,
                                                    selected: false
                                                }
                                            }
                                        }) : []}
                                        select="single"
                                    />
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        </DialogContent>
                    </Dialog>
                )}
            </GlobalParametersContext.Consumer>
        );
    }
}


export default withStyles(styles)(EventCountryList);
