import moment from 'moment'
import config from '../config';

export function eventIsInValidCheckinTime({ event }) {
    let validPastHours = config.events.validPastHours,
        nowFormatted = moment(moment().format('YYYY-MM-DDTHH:mm:00') + 'Z').utc();
    return moment(event.to).utc().isBefore(nowFormatted.add((-1 * validPastHours), 'hours'))
}

export function eventCanCheckIn({ event }) {
    let validCheckinHours = config.events.validCheckinHours,
        nowFormatted = moment(moment().format('YYYY-MM-DDTHH:mm:00') + 'Z').utc(),
        diff = nowFormatted.diff(moment(event.from).utc(), 'hours');
    return diff <= validCheckinHours && diff >= 0 ? true : false;
}

export function formatDateAsAbsolute(date) {
    return moment(moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z'
}