import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    withStyles,
    Link,
    Tabs,
    Tab,
    Fade,
    CircularProgress
} from '@material-ui/core';
import { Inbox, OpenInNew } from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { TasksShortcut } from './common'
import Permissions from '../../lib/permissions'
import API from '../../lib/api';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import HeaderSummary from './HeaderSummary';


const styles = theme => {
    return ({
        finished__orders__flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        finished__orders__table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        finished__orders__noRows: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__void: {
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        customLink: theme.customLink,
        list__fileDetails: { position: 'relative' },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        sort__component__container: theme.sort__component__container,
        country__select: {
            width: '100%'
        },
        divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        table__cell__rigth: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        totalRow: {
            fontWeight: 'bold'
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        circularProgress: {
            marginTop: '20%'
        },
    })
};

class Invoices extends Component {

    state = {
        pageSize: 0,
        deliveredRows: [],
        preparingRows: [],
        totalRows: [],
        currentTab: 0,
        rows: this.props.files,
        fetching: false,
        showTotal: false
    }

    componentWillMount() {
        const { files } = this.props

        if (files) {
            this.setState({
                deliveredRows: files.filter(row => row.finishStatus.id === 4),
                preparingRows: files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished),
                totalRows: files.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished)),
                rows: files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished),
                pageSize: files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished).length
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { files } = nextProps,
            { currentTab } = this.state;

        if (files) {
            this.setState({
                deliveredRows: files.filter(row => row.finishStatus.id === 4),
                preparingRows: files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished),
                totalRows: files.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished)),
                rows: currentTab === 1 ?
                    files.filter(row => row.finishStatus.id === 4) : currentTab === 0 ?
                        files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished) :
                        files.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished)),
                pageSize: currentTab === 1 ?
                    files.filter(row => row.finishStatus.id === 4).length : currentTab === 0 ?
                        files.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished).length :
                        files.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished)).length
            })
        }
    }


    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }
    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    }

    groupBy = (e, value) => {
        const { preparingRows, deliveredRows, totalRows } = this.state;

        this.setState({
            showTotal: false,
            currentTab: value,
            rows: value === 1 ? deliveredRows : value === 0 ? preparingRows : totalRows,
            pageSize: value === 1 ? deliveredRows.length : value === 0 ? preparingRows.length : totalRows.length
        })
    }

    fetchQuotesByStatus = (status, params) => {
        return new Promise((resolve, reject) => {
            if (!this.state.fetching) {
                this.setState({
                    fetching: true
                }, _ => {
                    API.Files.getFiles({
                        recordCount: 50,
                        offset: 0,
                        status: status,
                        params: params
                    }).then(res => {
                        const originalRows = res.data.records;
                        let rows = [];
                        switch (this.state.currentTab) {
                            case 1:
                                rows = originalRows.filter(row => row.finishStatus.id === 4);
                                break;
                            case 0:
                                rows = originalRows.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished);
                                break;
                            default:
                                rows = originalRows.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished));
                                break;
                        }

                        this.setState({
                            deliveredRows: originalRows.filter(row => row.finishStatus.id === 4),
                            preparingRows: originalRows.filter(row => row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished),
                            totalRows: originalRows.filter(row => row.finishStatus.id === 4 || (row.status === 5 && row.clientPO.poNumber && row.invoicesCount > 0 && !row.finished)),
                            rows,
                            pageSize: rows.length,
                            fetching: false,
                            showTotal: true
                        })
                    })
                })
            } else {
                resolve();
            }
        })
    }

    handleDateFilter = ({ selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson }) => {
        this.fetchQuotesByStatus('invoiced', { selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson })
    }


    render = () => {
        const {
            classes,
        } = this.props,
            { pageSize,
                deliveredRows,
                preparingRows,
                totalRows,
                currentTab,
                fetching,
                showTotal,
                rows } = this.state;

        let countries = rows.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i),
            invoicedTotalDol = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.invoiceTotals.total : 0)), 0),
            invoicedTotalEu = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.invoiceTotals.total : 0)), 0),
            profitTotalDol = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.invoiceTotals.profitTotal : 0)), 0),
            profitTotalEu = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.invoiceTotals.profitTotal : 0)), 0),
            otherCostTotalUSD = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? row.totals.invoiceTotals.otherCostTotal : 0)), 0),
            otherCostTotalEU = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? row.totals.invoiceTotals.otherCostTotal : 0)), 0)


        return (
            <>
                {this.props.files && (
                    <div>
                        <Hidden xsDown implementation="css">

                            <Tabs
                                className={classes.quotes__statusTabs}
                                value={currentTab}
                                onChange={this.groupBy}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >
                                <Tab label={`${preparingRows ? preparingRows.length : null} ${i18n.t('quotes.invoice.preparing')}`}
                                    style={{ width: 120 }}
                                />
                                <Tab label={`${deliveredRows ? deliveredRows.length : null} ${i18n.t('quotes.invoice.delivered')}`}
                                    style={{ width: 120 }}
                                />
                                <Tab label={`${totalRows ? totalRows.length : null} ${i18n.t('quotes.invoice.totals')}`}
                                    style={{ width: 120 }}
                                />
                            </Tabs>
                            <Divider className={classes.divider} />

                            <div style={{ position: 'relative' }}>
                                <HeaderSummary
                                    totalUSD={invoicedTotalDol}
                                    totalEU={invoicedTotalEu}
                                    otherCostTotalUSD={otherCostTotalUSD}
                                    otherCostTotalEU={otherCostTotalEU}
                                    profitUSD={profitTotalDol}
                                    profitEU={profitTotalEu}
                                    handleDateFilter={this.handleDateFilter}
                                    countries={countries}
                                    termsLabel={i18n.t('listHeader.Invoiceterms')}
                                />

                                { rows &&
                                    <Typography style={{ textAlign: 'right', position: 'absolute', right: 0, bottom: 0 }}>
                                        {showTotal ?
                                            `Filter results: ${rows.length} files`
                                        :
                                            `Current month (${moment().format('MMMM')}): ${rows.length} files `
                                        }
                                    </Typography>
                                }
                            </div>

                            {fetching && (
                                <Fade in={fetching}>
                                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                                </Fade>
                            )}

                            {rows && rows.length > 0 && !fetching &&
                                <ReactTable
                                    data={rows.sort((a, b) => {
                                        let aInvoice = a.invoices[0].invoiceId ? a.invoices[0].invoiceId : a.invoices[0].id,
                                            bInvoice = b.invoices[0].invoiceId ? b.invoices[0].invoiceId : b.invoices[0].id;
                                        if (aInvoice > bInvoice) return -1;
                                        if (aInvoice < bInvoice) return 1;
                                        return 0
                                    })}
                                    columns={[
                                        {
                                            Header: i18n.t("quotes.finished.table.column1"),
                                            id: 'file',
                                            width: 170,
                                            filterable: false,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                minHeight: '80px'
                                            },
                                            accessor: row => (
                                                <div className={classes.rfq__table__fileDescriptions}>
                                                    <Link
                                                        variant="body2"
                                                        style={{ cursor: 'pointer' }}
                                                        color='textSecondary'
                                                        onClick={() => {
                                                            this.props.handleShowQuote(row.id)
                                                        }}
                                                        underline={'hover'}
                                                    >
                                                        <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                    </Link>
                                                    <div className={classes.rfq__table__fileDatesContainer}>
                                                        <Typography
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {this.quoteDateFormat(row.invoiceDate)}
                                                        </Typography>
                                                    </div>
                                                </div>),
                                        },
                                        {
                                            Header: i18n.t("quotes.finished.table.column2"),
                                            filterable: false,
                                            id: 'quoteClientName',
                                            accessor: row => (
                                                <div className={classes.list__fileDetails} style={{ whiteSpace: 'pre-line' }}>
                                                    {row.invoices && row.invoices.length > 0 && row.invoices.map(invoice => (
                                                        <Link
                                                            variant="body2"
                                                            className={invoice.isVoid ? classes.rfq__table__fileLink__void : classes.rfq__table__fileLink}
                                                            onClick={() => {
                                                                this.props.handleShowQuote(row.id)
                                                            }}
                                                            underline={'hover'}
                                                        >
                                                            <strong>{i18n.t("quotes.requestedByClient.table.invoice")} #{`${invoice.invoiceId ? invoice.invoiceId : invoice.id}  ${invoice.isVoid ? ' VOID' : ''}\n`}</strong>
                                                        </Link>
                                                    ))}
                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                    <Typography>{`PO# ${row.clientPO.poNumber}`}</Typography>
                                                </div>
                                            ),
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                        },
                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.country"),
                                            filterable: false,
                                            id: 'country',
                                            width: 60,
                                            accessor: row => (
                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.pos.table.invoicedItems"),
                                            width: 110,
                                            heigth: 50,
                                            id: 'invoiced',
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => (
                                                <div>
                                                    {row.totals.invoiceTotals.total ?
                                                        <div style={{ textAlign: 'right' }}>
                                                            <Typography className={classes.row__currency}>{row.currency}</Typography>
                                                            <NumberFormat value={row.totals.invoiceTotals.total + row.totals.invoiceTotals.otherCostTotal}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={`${row.currencySymbol} `}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className={classes.totalRow}
                                                            />
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column6"),
                                            width: 80,
                                            id: 'Profit',
                                            show: Permissions.checkSensitiveInformationPermission() ? true : false,
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => {
                                                return (
                                                    <div>
                                                        {row.totals.invoiceTotals.profitTotal && row.totals.invoiceTotals.profitTotal !== 0 ?
                                                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                                                <NumberFormat value={row.totals.invoiceTotals.profitTotal}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                />
                                                                <NumberFormat value={(row.totals.invoiceTotals.profitTotal * 100) / (row.totals.clientQuoteTotals.subTotal || 1)}
                                                                    displayType={'text'}
                                                                    suffix={'%'}
                                                                    decimalScale={0}
                                                                    fixedDecimalScale={false}
                                                                />
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.finished.table.column3"),
                                            filterable: false,
                                            id: 'user_Author',
                                            accessor: row => {
                                                let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                return (
                                                    <div>
                                                        <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                                        {row.salesRepresentative && row.salesRepresentative.name && (
                                                            <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                        )}
                                                        <TasksShortcut
                                                            row={row}
                                                            handleShowQuote={this.props.handleShowQuote}
                                                        >
                                                            <div>
                                                                {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => !task.complete && task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                    <div>
                                                                        <Divider />
                                                                        <div style={{ display: 'flex', marginTop: 5, justifyContent: 'flex-start' }}>
                                                                            <OpenInNew style={{ fontSize: 14 }} color='secondary' />
                                                                        </div>
                                                                        {row.tasks.filter(task =>
                                                                            !task.complete)
                                                                            .map(task => {
                                                                                if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                    usersListed.push(task.followUpUser.id);
                                                                                    return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                }
                                                                                return null
                                                                            })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </TasksShortcut>
                                                    </div>)

                                            },
                                            width: 150,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }
                                        }
                                    ]}
                                    defaultPageSize={rows && rows.length >= 50 ? 50 : rows.length}
                                    pageSize={pageSize}
                                    minRows={0}
                                    onPageSizeChange={(pageSize, page) =>
                                        this.setState({ page, pageSize })
                                    }
                                    className={classNames(classes.finished__orders__table, ' -striped -highlight')}
                                    showPaginationTop={rows && rows.length >= 50 ? true : false}
                                    showPaginationBottom={rows && rows.length >= 50 ? true : false}
                                    sortable={false}
                                    resizable={false}
                                />
                            }
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <Paper>
                                <List>
                                    {rows.map((row, i) => {
                                        let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                        return (
                                            <div key={row.id}>
                                                <ListItem key={row.id} >
                                                    <ListItemText>
                                                        <div className={classes.rfq__table__fileDescriptions}>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.rfq__table__fileLink}
                                                                onClick={() => {
                                                                    this.props.handleShowQuote(row.id)
                                                                }}
                                                                underline={'hover'}
                                                            >
                                                                <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                            </Link>
                                                            <div className={classes.rfq__table__fileDatesContainer}>
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary'>
                                                                    {this.quoteDateFormat(row.invoiceDate)}
                                                                </Typography>
                                                                <Typography className={moment().diff(moment(row.invoiceDate), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.invoiceDate), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                    {moment(row.invoiceDate).fromNow(true)}
                                                                </Typography>
                                                            </div>
                                                            <TasksShortcut row={row}
                                                                handleShowQuote={this.props.handleShowQuote} />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                            <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                            <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                            <div>
                                                                <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>


                                                                {row.salesRepresentative && row.salesRepresentative.name && (
                                                                    <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                )}
                                                                <TasksShortcut
                                                                    row={row}
                                                                    handleShowQuote={this.props.handleShowQuote}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {row.tasks && row.tasks.length > 0 && row.tasks.filter(task =>
                                                                            !task.complete)
                                                                            .map(task => {
                                                                                if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                    usersListed.push(task.followUpUser.id);
                                                                                    return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                }
                                                                                return null
                                                                            })}
                                                                    </div>
                                                                </TasksShortcut>
                                                            </div>
                                                            <div className={classes.list__countryContainer}>
                                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                            </div>
                                                            <Typography color='textSecondary'>
                                                                <NumberFormat value={row.totals.invoiceTotals.total}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={row.currencySymbol}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true} />
                                                            </Typography>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {i < (rows.length - 1) && (
                                                    <Divider />
                                                )}
                                            </div>
                                        )
                                    })
                                    }

                                </List>
                            </Paper>
                        </Hidden>
                    </div>
                )}

                {rows && rows.length === 0 && !fetching && (
                    <div className={classes.finished__orders__noRows}>
                        {this.props.people.filterPeople && this.props.people.filterPeople.length === 0 ? (
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('quotes.invoice.noQuotes')}</Typography>
                            </div>
                        ) : (
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 50 }} color='secondary' />
                                    <Typography variant='body2'>
                                        {this.props.people.filterPeople.map((person, i) => (
                                            <span key={i}>{`${person.userName}${this.props.people.filterPeople.length > 1 && (this.props.people.filterPeople.length > i + 1) ? ', ' : ' '}`} </span>
                                        ))}
                                        <br />
                                        {this.props.people.filterPeople.length === 1 ? i18n.t('peopleFilter.hasNot') : i18n.t('peopleFilter.haveNot')}
                                        {i18n.t('peopleFilter.invoiced')}
                                    </Typography>
                                    {Permissions.checkSensitiveInformationPermission() && (
                                        <Typography variant='body2'>
                                            <Link className={classes.customLink}
                                                onClick={() => {
                                                    this.props.generalActions.showPeopleFilter(true)
                                                }}>
                                                {i18n.t('peopleFilter.changeUsersFilter')}
                                            </Link>
                                        </Typography>
                                    )}
                                </div>
                            )}
                    </div>
                )}
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        files: state.files,
        people: state.people,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Invoices))