import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const Deals = {
    getDeals: (args) => {
        let { userId, visitId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users/${userId}/deals${visitId ? `?visitId=${visitId}` : ''}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getDealsOrganizations: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/deals/organizations`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getDealsOrganizationPersons: (args) => {
        let { organizationId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/deals/organizations/${organizationId}/persons`;
            
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getDealsCurrencies: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/deals/currencies`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getDealsStages: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/deals/stages`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createDeal: (args) => {
        let { deal, visitId } = args;
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/deals${visitId ? `?visitId=${visitId}` : ''}`;
            auth.getAuthenticatedUser().then(logueduser => {
                const prodekAuthToken = logueduser.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'POST',
                    body: JSON.stringify(deal)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Deals;