import {
    SET_VENDORS,
    ADD_VENDOR,
    UPDATE_VENDOR,
    DELETE_VENDOR,
    ADD_VENDOR_CONTACT,
    DELETE_VENDOR_CONTACT,
    UPDATE_VENDOR_CONTACT
} from '../actions/vendorsActions'

function vendors(state = [], action) {
    let index = null, contactIndex = null;
    switch (action.type) {
        case SET_VENDORS:
            return [...action.vendors].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            })
        case ADD_VENDOR:
            return [...state,
            { ...action.vendor }
            ].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            })
        case UPDATE_VENDOR:
            index = state.findIndex(v => v.id === action.vendor.id);
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    {
                        ...action.vendor
                    },
                    ...state.slice(index + 1)
                ].sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0
                })
            }
            return state
        case DELETE_VENDOR:
            return state.filter(v => v.id !== action.vendor.id)
        case ADD_VENDOR_CONTACT:
            index = state.findIndex(v => v.id === action.contact.vendorId);
            if (index >= 0) {
                let contacts = state[index].contacts ? state[index].contacts : []
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        contacts: [
                            ...contacts,
                            { ...action.contact }
                        ]
                    },
                    ...state.slice(index + 1),
                ]
            }
            return state;
        case UPDATE_VENDOR_CONTACT:
            index = state.findIndex(v => v.id === action.contact.vendorId);
            if (index >= 0) {
                contactIndex = state[index].contacts.findIndex(c => c.id === action.contact.id);
                if (contactIndex >= 0) {
                    return [
                        ...state.slice(0, index),
                        {
                            ...state[index],
                            contacts: [
                                ...state[index].contacts.slice(0, contactIndex),
                                {
                                    ...action.contact
                                },
                                ...state[index].contacts.slice(contactIndex + 1),
                            ]
                        },
                        ...state.slice(index + 1),
                    ]
                }
                return state;
            }
            return state;
        case DELETE_VENDOR_CONTACT:
            index = state.findIndex(v => v.id === action.contact.vendorId);
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        contacts: [
                            ...state[index].contacts.filter(c => c.id !== action.contact.id),
                        ]
                    },
                    ...state.slice(index + 1),
                ]
            }
            return state;
        default:
            return state;
    }
}

export default vendors