import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import i18n from 'i18next'
import { EmailsList } from '../components/emails'

export default class Mails extends Component {
    render() {
        let props = this.props;
        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.emails')}`} />
                <EmailsList {...props} />
            </div>
        )
    }
}
