import React, { Component } from 'react'
import {
    Card, 
    Grid,
    CardHeader,
    Divider,
    List,
    ListItem,
    Typography,
    Link,
    CircularProgress
} from '@material-ui/core/';
import { Today } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { TaskDetailDialog } from '../widgets'
import i18n from 'i18next'
import moment from 'moment';
import API from '../../lib/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as peopleActions from '../../actions/peopleActions'


const styles = theme => ({
    checkAll: {
        marginLeft: '-5px'
    },
    listItem: {
        paddingLeft: '10px',
        paddingRight: '5px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    listItemText: {
        paddingLeft: 0,
        paddingRight: '10px'
    },
    listItemTextFileId: {
        paddingLeft: 0,
        paddingRight: '10px',
        color: theme.palette.blue[100],
        fontSize: '18px'
    },
    dashboard__cardHeader:{
        ...theme.dashboardCard.header,
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
        background: `linear-gradient(60deg, ${theme.palette.purple['light']}, ${theme.palette.purple['dark']})`
    },
    leftIcon: {
        marginRight: theme.spacing() / 2,
        fontSize: '16px'
    },
    dashboard__cardHeader__button: {
        ...theme.dashboardCard.actionButton,
        '&:hover': {
            background: theme.palette.purple['light']
        }
    },
    dashboard__cardHeader__action: {
        marginTop: 0
    },
    dashboard__cardHeader__content: {
        flex: 'none'
    },
    notes__list__task__due__date: {
        borderRadius: '20px',
        display: 'flex'
    },
    tasks__list__task__due__date: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
    },
    "tasks__list__task__due__date--complete": {
        background: '#519839'
    },
    "tasks__list__task__due__date--due": {
        background: '#cf513d'
    },
    "tasks__list__task__due__date--due_soon": {
        background: '#e6c60d'
    },
    "tasks__list__task__due__date--default": {
        background: '#ccc'
    },
    dashboard__tasks__more: {
        ...theme.customLink
    },
});

class Tasks extends Component {

    state={
        showMore: false,
        allTasks: this.props.tasks ? this.props.tasks.sort((a, b) => {
            return new Date(a.taskDateTime) - new Date(b.taskDateTime);
        }) : [],
        openTaskDetail: false,
        selectedTask: null,
        tasks: this.props.tasks ? this.props.tasks.sort((a, b) => {
            return new Date(a.taskDateTime) - new Date(b.taskDateTime);
        }).slice(0, 5) : [],
        loading: false
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading
        })

        if(nextProps.tasks) {
            this.setState({
                showMore: false,
                allTasks: nextProps.tasks.sort((a, b) => {
                    return new Date(a.taskDateTime) - new Date(b.taskDateTime);
                }),
                tasks: nextProps.tasks.length === this.state.tasks.length ? nextProps.tasks.sort((a, b) => {
                        return new Date(a.taskDateTime) - new Date(b.taskDateTime);
                    }) : nextProps.tasks.sort((a, b) => {
                        return new Date(a.taskDateTime) - new Date(b.taskDateTime);
                    }).slice(0, 5) 
            })
        }
    }

    showAllTasks=()=>{
        const { allTasks } = this.state;

        this.setState({
            tasks: allTasks,
            showMore: true
        })
    }

    showLessTasks=()=>{
        const { allTasks } = this.state;

        let newTasks = [...allTasks].splice(0, 5)

        this.setState({
            tasks: newTasks,
            showMore: false
        })
    }
    
    selectTask=(task)=>{
        this.setState({
            selectedTask: task,
            openTaskDetail: true
        })
    }

    closeTaskDetail=()=>{
        this.props.refreshTasks()

        this.setState({
            selectedTask: null,
            openTaskDetail: false
        })
    }

    completeTask = (e) => {
        const { allTasks, selectedTask, tasks } = this.state;

        let index = allTasks.findIndex(task => task.id === selectedTask.id),
            newNotes = [
                ...allTasks.slice(0, index),
                {
                    ...allTasks[index],
                    taskComplete: e.target.checked ? 1 : 0
                },
                ...allTasks.slice(index + 1)
            ];

        let newTasks = [...newNotes];

        if(tasks.length !== allTasks.length){
            newTasks = [...newNotes].splice(0, 5)
        }

        this.setState({
            allTasks: newNotes,
            tasks: newTasks
        }, () => {
            let task = newNotes[index];
            API.Notes.markTask({
                fileId: task.file.id,
                noteId: task.id,
                completed: task.taskComplete
            }).then(updated => {
                if (updated.status === 'success') {
                    this.setState({
                        selectedTask: task
                    })
                }
            })
        })
    }

    render() {

        const { classes, people } = this.props,
            { tasks, allTasks, showMore, openTaskDetail, selectedTask, loading } = this.state;
        
        return (
            <div>
                <Card>
                    <CardHeader
                        disableTypography={true}
                        classes={{
                            root: classes.dashboard__cardHeader,
                            action: classes.dashboard__cardHeader__action,
                            content: classes.dashboard__cardHeader__content
                        }}
                        title={<div style={{display: 'flex', alignItems: 'center'}}>
                            <Today />
                            <Typography variant='h6' style={{color: '#fff', marginLeft: '8px'}}>
                                {i18n.t('dashboard.tasks')}
                            </Typography>
                        </div>}
                    />
                    { tasks && tasks.length > 0 && !loading &&
                        <div>
                            <List style={{ padding:'8px 8px 0'}}>
                                {tasks.map((task, i) => {
                                    let diffTaskDueDate = task.taskDateTime ?
                                        moment().format('MM-DD-YY') > moment.utc(task.taskDateTime).format('MM-DD-YY') ? -1 :
                                            moment().format('MM-DD-YY') < moment.utc(task.taskDateTime).format('MM-DD-YY') ? 1 :
                                                moment().format('MM-DD-YY') === moment.utc(task.taskDateTime).format('MM-DD-YY') ? 0 : null
                                        : null
                                    return (
                                        <div key={`${task.id} - ${i}`}>
                                            <ListItem className={classes.listItem}
                                                onClick={()=>this.selectTask(task)}
                                                dense button>
                                                <Grid container spacing={1}>
                                                    {people && people.filterPeople.length > 1 &&
                                                        <Grid item xs={12}>
                                                            <Typography variant='body1' color='textPrimary' className={classes.listItemText} noWrap>
                                                                {task.followUpUser.name}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                
                                                    <Grid item xs={5} sm={3} style={{display: 'flex'}}>
                                                        <Typography variant='body2' color='textSecondary'  className={classes.listItemTextFileId}>
                                                            {`${i18n.t('dashboard.file')} ${task.file.id}`}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={7} sm={6}>
                                                        <Typography variant='body2' color='textSecondary' className={classes.listItemText} noWrap>
                                                            {task.client.name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={4} sm={2} className={classes.notes__list__task__due__date}>
                                                        <Typography variant='body2' color='textSecondary' className={classes.listItemText} style={{ paddingRight: '0' }}>
                                                            {`${moment.utc(task.taskDateTime).format('MMM Do')}`}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={4} sm={1} >
                                                        <div className={`${classes.tasks__list__task__due__date} ${task.taskComplete === 1 ? classes["tasks__list__task__due__date--complete"] :
                                                            diffTaskDueDate === 0 ? classes["tasks__list__task__due__date--due_soon"] :
                                                                diffTaskDueDate < 0 ? classes["tasks__list__task__due__date--due"] : classes["tasks__list__task__due__date--default"]}`}/>
                                                    </Grid>

                                                    { task.vendorQuote && task.vendorQuote.id &&
                                                        <Grid item xs={12}>
                                                            <Typography variant='body2' color='textSecondary' className={classes.listItemText} noWrap>
                                                                {`${i18n.t('dashboard.vendorQuote')} ${task.vendorQuote.id} - ${task.vendorQuote.vendorName}`}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    <Grid item xs={12}>
                                                        <Typography variant='body2' color='textSecondary' className={classes.listItemText} noWrap>
                                                            {task.title}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            { i !== tasks.length - 1 &&
                                                <Divider/>
                                            }
                                        </div>
                                    )
                                })}
                            </List>
                            {allTasks && allTasks.length > 5 && !showMore &&
                                <div style={{ width: '100%', textAlign: 'right', paddingBottom: '8px', paddingRight: '20px'}}>
                                    <Link onClick={this.showAllTasks}
                                        variant='button'
                                        className={classes.dashboard__tasks__more}>
                                        {`${allTasks.length - 5} ${i18n.t("dashboard.more")}`}
                                    </Link>
                                </div>
                            }

                            { allTasks && tasks && allTasks.length === tasks.length && allTasks.length > 5 && showMore &&
                                <div style={{ width: '100%', textAlign: 'right', paddingBottom: '8px', paddingRight: '20px' }}>
                                    <Link onClick={this.showLessTasks}
                                        variant='button'
                                        className={classes.dashboard__tasks__more}>
                                        {i18n.t("dashboard.showLess")}
                                    </Link>
                                </div>
                            }
                        </div>
                    }
                    { tasks.length === 0 && !loading && 
                        <Typography variant='body2' color='textSecondary' className={classes.listItemText} noWrap style={{padding: '24px'}}>
                            {i18n.t('dashboard.noTasks')}
                        </Typography>
                    }

                    {loading &&
                        <CircularProgress style={{margin:'8px 0'}} color='secondary'/>
                    }
                </Card>

                <TaskDetailDialog
                    selectedTask={selectedTask}
                    onClose={this.closeTaskDetail}
                    open={openTaskDetail}
                    completeTask={this.completeTask}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tasks));
