import React from 'react'
import {
    Button,
    CircularProgress,
    Link,
    Grid,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    withStyles,
    Paper
} from '@material-ui/core'
import {
    Visibility, VisibilityOff
} from '@material-ui/icons'
import { CustomSnackBar } from '../messages'
import i18n from 'i18next';
// import API from '../../lib/api'
import auth from '../../authentication/auth'

const logo_image = require('../../assets/images/logosmall.png');

const styles = theme => ({
    paper: {
        maxWidth: 350,
        margin: `${theme.spacing(2)}px auto`,
        marginTop: 50,
        padding: theme.spacing()
    },
    form:{
        margin: '16px'
    },
    forgot__form__title: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
        textAlign: 'center',
    },
    forgot__form__description: {
        color: theme.palette.text.secondary,
    },
    fieldBox__button: {
        // width: '100%',
    },
    error__label: {
        fontSize: '10pt'
    },
    login__header: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '10px 0',
    }
})


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.inputs = {};
        this.state = {
            fetching: false,
            inputs: { email: props.credentials && props.credentials.username ? props.credentials.credentials : '' },
            validate: false,
            validationErrors: {},
            changePassword: false,
            passwordErrors: [],
            responseErrors: null,
            showResendCounter: false,
            counter: 60,
            showPassword: false,
            secretEmail: null
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.authData){
            this.setState({
                inputs: {
                    ...this.state.inputs,
                    email: nextProps.authData
                }
            })
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword            
        })
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleInputChange = event => {
        event.persist();
    
        this.setState({
            inputs:{
                ...this.state.inputs,
                [event.target.name]: event.target.type !== 'checkbox' ? event.target.value : event.target.checked
            }
        })
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePassword = (password) => {
        let errors = [];
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/, valid = false;
        if (password && password.match(passw)) {
            valid = true
        } else {
            if (!password) {
                errors.push(i18n.t('forgotPassword.passwordRequired'))
            } else {
                let showPasswordError = false;
                if (!password) {
                    showPasswordError = true;
                } else {
                    if (!password.match(upper) || !password.match(lower) || !password.match(number) || (password.length < 6 || password.length > 20)) {
                        showPasswordError = true;
                    }
                }
                if (showPasswordError) {
                    errors.push(i18n.t('forgotPassword.invalidPassword'))
                }
            }
        }

        this.setState({
            passwordErrors: errors
        })

        return valid
    }

    validateParameters = () => {
        const {changePassword, inputs} = this.state

        let errors = {};
        // if (!changePassword && !this.validateEmail(inputs.email)) {
        //     errors.email = i18n.t('forgotPassword.invalidCredentials')
        // }

        if (changePassword && !inputs.code) {
            errors.code = i18n.t('forgotPassword.enterYourCode')
        }
        if (changePassword && !this.validatePassword(inputs.newPassword)) {
            errors.newPassword = i18n.t('forgotPassword.invalidPAssword')
        }

        this.setState({
            validationErrors: errors
        })

        if (Object.keys(errors).length > 0) {
            this.setState({
                validationErrors: true
            })
            return false;
        }

        this.setState({
            validate: false
        })

        return true
    }

    resendCode = () => {
        const { inputs } = this.state;

        this.setState({
            fetching: true,
            showResendCounter: true
        })

        let count = 60;
        let timer = setInterval(() => {
            if (count === 1) {
                this.setState({
                    showResendCounter: false
                })

                clearInterval(timer)
                timer = null
                count = 60;
            } else {
                count = count - 1;
            }
            this.setState({
                counter: count
            })
        }, 1000)


        auth.forgotPassword({ username: inputs.email })
            .then(_ => {
                this.setState({
                    fetching: false,
                    changePassword: true
                })
            })
            .catch(err => {
                this.setState({
                    fetching: false
                })

                if (err.code === 'UserNotFoundException') {
                    this.setState({
                        responseErrors: <span style={{ maxWidth: '250px' }}>
                            {i18n.t('forgotPassword.userNotFoundException')}</span>
                    })
                }
                console.log(err)
            })
    }

    onSubmit = e => {
        e.preventDefault();
        const { changePassword, inputs } = this.state,
            { history } = this.props;

        this.setState({
            responseErrors: null,
            passwordErrors: []
        })

        if (this.validateParameters()) {
            this.setState({
                fetching: true
            })

            if (!changePassword) {
                auth.forgotPassword({ username: inputs.email })
                    .then(response => {
                        console.log(response)
                        
                        this.setState({
                            secretEmail: response.CodeDeliveryDetails.Destination,
                            fetching: false,
                            changePassword: true
                        })
                    })
                    .catch(err => {
                        this.setState({
                            fetching: false
                        })

                        if (err.code === 'UserNotFoundException') {
                            this.setState({
                                responseErrors: <span style={{ maxWidth: '250px' }}>
                                    {i18n.t('forgotPassword.userNotFoundException')}</span>
                            })
                        } else {
                            this.setState({
                                responseErrors: `${i18n.t('forgotPassword.uncaughtError')}: ${err.message}`
                            })
                        }
                        console.log(err)
                    })
            } else {
                auth.forgotPasswordSubmit({ username: inputs.email, code: inputs.code, newPassword: inputs.newPassword })
                    .then(_ => {
                        auth.signIn({ username: inputs.email, password: inputs.newPassword })
                            .then(user => {
                                this.setState({
                                    changePassword: false,
                                    fetching: false
                                })
                                this.props.onStateChange('signedIn', user)
                                history.push('/dashboard');
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })
                    .catch(err => {
                        this.setState({
                            fetching: false
                        })
                        //ExpiredCodeException
                        if (err.code === 'CodeMismatchException') {
                            this.setState({
                                responseErrors: i18n.t('forgotPassword.invalidCode')
                            })
                        } else {
                            this.setState({
                                responseErrors: i18n.t('forgotPassword.errorCode')
                            })
                        }
                        console.log(err)
                    })
            }
        }
    }


    backToSignIn = () => {
        this.setState({
            fetching: false,
            responseErrors: null,
            passwordErrors: [],
            validationErrors: {},
            inputs: {email: ''},
            changePassword: false
        })

        this.props.onStateChange('signIn');
    }

    render(){

        const { authState, classes } = this.props,
            { changePassword, inputs, validationErrors, counter, passwordErrors, responseErrors,
                fetching, validate, showPassword, showResendCounter, secretEmail } = this.state;

        if (authState !== "forgotPassword") return null;

        return (
            <Paper className={classes.paper}>

                <div className={classes.login__header} >
                    <img alt="Prodek Inc" src={logo_image} />
                </div>

                <Typography
                    className={classes.forgot__form__title}
                    variant="body1"
                >
                    {i18n.t('forgotPassword.title')}
                </Typography>


                <form
                    className={classes.form}
                    onSubmit={this.onSubmit}
                    noValidate
                    autoComplete='false'
                >
                    <Grid container spacing={2}>
                        {!changePassword && (
                            <>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.forgot__form__description}
                                        variant="body2"
                                    >
                                        {i18n.t('forgotPassword.description')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={fetching}
                                        required
                                        fullWidth
                                        id="email"
                                        label={i18n.t('forgotPassword.email')}
                                        name="email"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                        value={inputs.email}
                                        error={validate && validationErrors.email ? true : false}
                                        InputProps={{
                                            inputProps: {
                                                autoCapitalize: 'off',
                                                autoComplete: 'off',
                                                spellCheck: 'false',
                                                autoCorrect: "off"
                                            }
                                        }}
                                    />
                                    {validationErrors.email && (
                                        <Typography className={classes.error__label} variant='body2' color='error'>{validationErrors.email}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{textAlign: 'center'}}>
                                        <Button
                                            className={classes.fieldBox__button}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={fetching}>
                                            {i18n.t('forgotPassword.sendCode')}
                                            {fetching && (
                                                <CircularProgress
                                                    size={16}
                                                    style={{
                                                        marginLeft: '5px',
                                                        color: 'grey'
                                                    }} />
                                            )}
                                        </Button>
                                    </div>
                                </Grid>

                            </>
                        )}
                        {changePassword && secretEmail && (
                            <>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.forgot__form__description}
                                        variant="body2"
                                    >
                                        {`Enter verification code that we send to ${secretEmail} and your new password`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={fetching}
                                        required
                                        fullWidth
                                        id="code"
                                        label={i18n.t('forgotPassword.code')}
                                        name="code"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                        error={validate && validationErrors.code ? true : false}
                                        InputLabelProps={{
                                            //shrink: true,
                                        }}
                                    />
                                    {validationErrors.code && (
                                        <Typography className={classes.error__label} variant='body2' color='error'>{validationErrors.code}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={fetching}
                                        required
                                        fullWidth
                                        id="newPassword"
                                        label={i18n.t('forgotPassword.newPassword')}
                                        name="newPassword"
                                        autoComplete="off"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={this.handleInputChange}
                                        error={validate && validationErrors.newPassword ? true : false}
                                        InputLabelProps={{
                                            //shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                autoCapitalize: 'off',
                                                autoComplete: 'off',
                                                spellCheck: 'false',
                                                autoCorrect: "off"
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                        onMouseDown={this.handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {validationErrors.newPassword && (
                                        <Typography className={classes.error__label} variant='body2' color='error'>{validationErrors.newPassword}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{textAlign: 'center'}}>
                                        <Button
                                            className={classes.fieldBox__button}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={fetching}
                                            onClick={() => {
                                            }}>
                                            {i18n.t('forgotPassword.submit')}
                                            {fetching && (
                                                <CircularProgress
                                                    size={16}
                                                    style={{
                                                        marginLeft: '5px',
                                                        color: 'grey'
                                                    }} />
                                            )}
                                        </Button>
                                    </div>
                                </Grid>
                                {!showResendCounter ? (
                                    <Grid item xs={12} style={{ alignSelf: 'center' }}>
                                        <Link
                                            underline='always'
                                            color='secondary'
                                            style={{ cursor: 'pointer' }}
                                            onClick={this.resendCode}>
                                             {i18n.t('forgotPassword.resendCode')}
                                        </Link>
                                    </Grid>
                                ) : (
                                        <Grid item xs={12} style={{ alignSelf: 'center' }}>
                                            <Typography variant='body2' color='textSecondary'>
                                            {i18n.t('forgotPassword.resendCodeTime')} {counter} {i18n.t('forgotPassword.second')}
                                            </Typography>
                                        </Grid>
                                    )}
                                {passwordErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {passwordErrors.map((err, i) => (
                                            <Typography key={i} color='error' className={classes.error__label}>{err}</Typography>
                                        ))}
                                    </Grid>
                                )}
                            </>
                        )}
                        <Grid item xs={12} >
                            <Link
                                className={classes.fieldBox__button}
                                underline='hover'
                                color='secondary'
                                style={{ cursor: 'pointer' }}
                                onClick={this.backToSignIn}>
                                {i18n.t('forgotPassword.backToSignIn')}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <CustomSnackBar
                    open={responseErrors !== null}
                    message={responseErrors}
                    variant='error'
                    onClose={() => { 
                        this.setState({
                            responseErrors: null
                        })
                    }} />
            </Paper>
        )
    }
}

export default withStyles(styles)(ForgotPassword)