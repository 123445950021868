import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import i18n from 'i18next';

export default class NotFound extends Component {
    render() {
        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.notFound')}`} />
                {i18n.t("notFound")}
            </div>
        )
    }
}
