import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Button,
    Grid,
    Link,
    withStyles
} from '@material-ui/core/';
import { Warning } from '@material-ui/icons'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import i18n from 'i18next';


const styles = theme => ({
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
    cancelButton: {
        width: '110px',
        cursor: 'pointer'
    },
    warning__message__container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(),
        whiteSpace: 'pre-line'
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
})

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

class ConfirmationDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDeliveryDate: new Date()
        }
    }

    handleDeliveryDateChange = date => {
        this.setState({ selectedDeliveryDate: date });
    }

    render() {
        const { selectedDeliveryDate } = this.state,
            {
                message,
                confirmLabel,
                cancelLabel,
                open,
                loading,
                title,
                warningMessage,
                savePODeliveryDay,
                classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title &&
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                }
                <DialogContent>

                    <DialogContentText>
                        {warningMessage &&
                            <span className={classes.warning__message__container}>
                                <Warning className={classes.warning__message__icon} />
                                {warningMessage}
                            </span>
                        }

                        {message}
                    </DialogContentText>

                    {savePODeliveryDay &&
                        <Grid container spacing={2} style={{ marginTop: '16px' }}>
                            <Grid item sm={4} xs={12}>
                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                    <InlineDatePicker
                                        variant="outlined"
                                        label={i18n.t('quotes.pos.poDetails.poDeliveryDate')}
                                        value={selectedDeliveryDate}
                                        maxDate={moment(new Date()).add(1, 'days')}
                                        style={{ width: '100%', marginTop: '5px' }}
                                        styles={customStyles}
                                        format="MMM DD YYYY"
                                        onChange={this.handleDeliveryDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Link
                        className={classes.cancelButton}
                        size='small'
                        variant="button"
                        color="secondary"
                        onClick={this.props.handleClose}>
                        {cancelLabel}
                    </Link>
                    <div className={classes.button__wrapper}>
                        <Button
                            // style={{ width: '110px' }}
                            disabled={loading}
                            color="primary"
                            size='small'
                            variant="contained"
                            onClick={() => this.props.onConfirm(selectedDeliveryDate)}>
                            {confirmLabel}
                        </Button>
                        {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmationDialog);