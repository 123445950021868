import React, { Component } from 'react'
import moment from "moment";
import {
    CircularProgress,
    Typography,
    withStyles,
    withMobileDialog,
    List,
    ListItem,
    Grid,
    IconButton
} from '@material-ui/core/';
import {
    Add,
    Inbox,
    Person,
    Business,
    Warning,
    NavigateBefore,
    NavigateNext
} from '@material-ui/icons'
import { withRouter } from "react-router";
import CustomSelect from '../../general/CustomSelect'
import i18n from 'i18next';
import API from '../../../lib/api';

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as accountActions from '../../../actions/accountActions'


const styles = theme => ({
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 100,
        paddingBottom: 100
    },
    events__list: {
        paddingTop: 0,
        marginBottom: 50,
    },
    events__list__item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    events__list__date__container: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    events__list__info__container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
    },
    deals__container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px'
    },
    deals__container__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    deals__container__titleContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing()
        },
    },
    deals__icon: {
        marginRight: '8px',
        fontSize: '16px'
    },
    deals__amount: {
        display: 'flex',
        // [theme.breakpoints.down('xs')]: {
            right: '16px',
            position: 'absolute',
            bottom: '8px'
        // }
    },
    deals__addDealButton: {
        color: '#007bff',
        padding: '4px',
        position: 'absolute',
        right: '16px',
        top: '13px',
        zIndex: '1200',
    },
    deals__dueDateTask__icon:{
        color: "#fff",
        background: theme.palette.error.main,
        borderRadius: '15px',
        fontSize: '17px'
    },
    deals__todayTask__icon: {
        color: "#fff",
        background: theme.palette.green[100],
        borderRadius: '15px',
        fontSize: '17px'
    },
    warning__message__icon: {
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
});


class DealsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            filterDeals: [],
            originalDeals: [],
            selectedStatus: 1,
            dealStatus: []
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.newDeal){
            let newDeals = [...this.state.originalDeals]

            newDeals.unshift(nextProps.newDeal)
    
            this.setState({
                selectedStatus: 1,
                originalDeals: newDeals,
                filterDeals: newDeals.filter(deal => deal.stage_id === 1)
            })
        }
    }

    componentDidMount=()=>{
        const { originalDeals } = this.state

        if(originalDeals.length === 0){
            this.fetchUserDeals()
        }
    }

    fetchUserDeals=()=>{
        const { account, visitId } = this.props

        this.setState({
            loading: true
        })
        API.Deals.getDealsStages().then(response => {
            this.setState({
                dealStatus: response.data.records.data
            })
            API.Deals.getDeals({userId: account.userId, visitId})
                .then( res =>{
                    if(res.data.records){
                        this.setState({
                            originalDeals: res.data.records.data ? res.data.records.data : res.data.records,
                            filterDeals: res.data.records.data ? res.data.records.data.filter(deal => deal.stage_id === 1) : res.data.records.filter(deal => deal.stage_id === 1)
                        }, ()=> {
                            this.setState({
                                loading: false
                            })
                        })
                    }
            })
        })
    }

    selectStatus=(status)=>{
        const { originalDeals } = this.state;
        if(status){
            this.setState({
                filterDeals: originalDeals.filter(deal => deal.stage_id === status.value),
                selectedStatus: status.value
            })
        } else {
            this.setState({
                filterDeals: originalDeals,
                selectedStatus: null
            })
        }
    }


    render() {
        const { classes, width } = this.props;
        const {
            dealStatus,
            loading,
            filterDeals,
            selectedStatus } = this.state;

        return (
            <div>
                {loading &&
                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                }

                {filterDeals && !loading && <>
                    { width === 'xs' &&
                        <div>
                            <IconButton className={classes.deals__addDealButton}
                                onClick={this.props.activeDealPopup}>
                                <Add />
                            </IconButton>
                        </div>
                    }
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <CustomSelect options={dealStatus.map((e) => ({
                                    "label": e.name,
                                    "value": e.id
                                }))}
                                label={'Status'}
                                value={selectedStatus}
                                fullWidth={true}
                                disabled={loading}
                                onSelect={this.selectStatus}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <List className={classes.events__list}>
                                <Grid container item spacing={1}>
                                    {filterDeals.map((row, i) => {
                                        return (
                                            <Grid item xs={12} sm={6}>
                                                <ListItem key={i}
                                                    className={classes.events__list__item}
                                                    // onClick={() => {
                                                    //     this.activeDealPopup(row);
                                                    // }}
                                                    >
                                                    <div className={classes.events__list__info__container}>
                                                        <Typography variant='body1'
                                                            color='textPrimary'
                                                            style={{ fontWeight: 'bold' }}>
                                                            {row.title}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.events__list__info__container}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Business className={classes.deals__icon}/>
                                                            <Typography variant='body1'
                                                                color='textPrimary'>
                                                                {row.org_name}
                                                            </Typography>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent: 'space-between', width: '100%'}}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <Person className={classes.deals__icon}/>
                                                                <Typography variant='body2'
                                                                    color= 'textSecondary'>
                                                                    {row.person_name}
                                                                </Typography>
                                                            </div>    

                                                            {row.activities_count === 0 ?
                                                                <Warning className={classes.warning__message__icon} />
                                                            :<>

                                                                {moment().format('YYYY-MM-DD') === row.next_activity_date  ?
                                                                    <NavigateNext className={classes.deals__todayTask__icon}/>
                                                                :<>
                                                                    {moment().diff(moment(row.next_activity_date)) > 0 &&
                                                                        <NavigateBefore className={classes.deals__dueDateTask__icon}/> 
                                                                    }
                                                                </>}
                                                            </>}

                                                        </div>
                                                    </div>
                                                    <div className={classes.events__list__date__container}>
                                                        <Typography variant='body2'>
                                                            {moment(row.expected_close_date).utc().format('MMM Do, YYYY')}
                                                        </Typography>
                                                    </div>

                                                    <div className={classes.deals__amount}>
                                                        <Typography variant='body2'
                                                            color= 'textSecondary'>
                                                            {row.formatted_value}
                                                        </Typography>
                                                    </div>
                                                </ListItem>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </List>
                        </Grid>
                    </Grid>
                </>}

                {filterDeals && filterDeals.length === 0 && !loading &&
                    <div className={classes.empty__state__container}>
                        <Inbox style={{ fontSize: 50, color: '#cdcdcd' }} />
                        <Typography variant='body2' color='textSecondary'>
                            {selectedStatus === 1 && <>{i18n.t('visits.deals.noBusinessOpportunity')}</>}
                            {selectedStatus === 2 && <>{i18n.t('visits.deals.noWaitingForTechnicalInfo')}</>}
                            {selectedStatus === 3 && <>{i18n.t('visits.deals.noProposalInProgress')}</>}
                            {selectedStatus === 4 && <>{i18n.t('visits.deals.noProposalMade')}</>}
                            {selectedStatus === 5 && <>{i18n.t('visits.deals.noNegotiationsStarted')}</>}
                            {selectedStatus === 6 && <>{i18n.t('visits.deals.noBudgetary')}</>}
                        </Typography>
                    </div>
                }
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        account: state.account
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMobileDialog()(withStyles(styles)(DealsList))));
