import React, { Component } from 'react'
import {
    withStyles,
    Grid,
    Typography,
    Divider
} from '@material-ui/core'
import moment from 'moment'
import i18n from 'i18next'
import {
    ClientQuoteReadOnly,
} from '../preparingQR'

const styles = theme => {
    return ({
        po__clientName__label: {
            textAlign: 'left',
            color: '#000',
        },
        po__client__name__container: {
            marginLeft: theme.spacing()
        },
    })
}

class ClientPOReadOnly extends Component {

    state={
        clientPO: this.props.clientPO,
        file: this.props.file
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            clientPO: nextProps.clientPO,
            file: nextProps.file
        })
    }
    

    render() {
        const { clientPO, file } = this.state,
            { clientQuote, classes } = this.props;


        if (!clientPO) return null;

        return (
            <div >
                <Grid container spacing={1} alignItems='baseline' style={{padding: '16px'}}>
                    <Grid item sm={5} md={5} xs={12}>
                        <Typography color='textSecondary' variant='body2'>
                            <strong>{i18n.t('quotes.pos.poDetails.poNumber')}</strong> {`${clientPO.poNumber}`}
                        </Typography>
                    </Grid>
                    <Grid item sm={7} md={7} xs={12}>
                        <Typography color='textSecondary' variant='body2'>
                            <strong>{i18n.t('quotes.pos.poDetails.poDate')}</strong> {`${moment(clientPO.date).format('MMMM Do, YYYY')}`}
                        </Typography>
                    </Grid>
                    {clientPO.deliveryDate &&
                        <Grid item xs={12}>
                            <Typography color='textSecondary' variant='body2'>
                                <strong>{`${i18n.t('quotes.pos.poDetails.eta')} (+ ${clientQuote.deliveryDays} ${i18n.t('quotes.pos.poDetails.quoteDeliveryDays')})  `}</strong>
                                {moment(clientPO.deliveryDate).format('MMMM Do, YYYY')}
                            </Typography>
                        </Grid>
                    }
                    {clientPO.comment &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography color='textSecondary' variant='body2' style={{ whiteSpace: 'pre-line' }}>
                                <strong>{`${i18n.t('quotes.pos.poDetails.poComments')}  `}</strong>
                                {clientPO.comment}
                            </Typography>
                        </Grid>
                    }
                </Grid>

                <Divider style={{marginBottom: '24px'}}/>

                <Grid container spacing={2} className={classes.po__client__name__container}>
                    <Grid item sm={12}>
                        <Typography
                            variant='subtitle1'
                            className={classes.po__clientName__label}
                        > {file.client.name}
                        </Typography>
                    </Grid>
                </Grid>

                <ClientQuoteReadOnly
                    file={file}
                    isPO={true}
                    pos={0}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ClientPOReadOnly);