import React, { Component } from 'react'
import {
    Button,
    withStyles,
    Hidden,
    Fab
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import i18n from 'i18next';
import { EventForm } from '.'

const style = theme => ({
    fab : {
        margin: theme.spacing(),
        width: '40px',
        height: '40px'
    }   
})



class ManageVisists extends Component {
    state = {
        showEventPopup: false
    }

    handleCreateNewVivist = () => {
        this.setState({
            showEventPopup: true,
        })
    }

    closeCreateNewEvent = () => {
        this.setState({
            showEventPopup: false,
        })
    }

    render() {
        const { addNewEvent, classes } = this.props,
            { showEventPopup } = this.state;

        return (
            <>
                {addNewEvent && <>
                    <Hidden xsDown implementation="css">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleCreateNewVivist}
                        >
                            {i18n.t("visits.newVisit")}
                        </Button>
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <Fab color="primary" aria-label="Add" className={classes.fab}
                            onClick={this.handleCreateNewVivist}>
                            <AddIcon />
                        </Fab>
                    </Hidden>
                    
                </>}

                <EventForm
                    open={showEventPopup}
                    event={null}
                    addNewEvent={addNewEvent}
                    handleClose={this.closeCreateNewEvent}
                />

            </>
        )
    }
}

export default withStyles(style)(ManageVisists);
