
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    Typography,
    withStyles
} from "@material-ui/core";
import {
    Warning as WarningIcon
} from "@material-ui/icons"

const styles = theme => ({
    message__container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    warning__icon: {
        color: theme.palette.orange[100],
        marginRight: theme.spacing()
    },
    warning__message: {
        color: theme.palette.text.secondary
    },
    clickeable: {
        cursor: 'pointer'
    },
    extended__message: {
        whiteSpace: 'pre-line',
        textAlign: 'center',
        margin: theme.spacing(2)
    }
})

class Warning extends Component {
    state = {
        openDialog: false
    }
    onClose = () => {
        this.setState({
            openDialog: false
        })
    }
    render() {
        const { classes, message, hideIcon, extendedMessage, classNames = {} } = this.props,
            { openDialog } = this.state;
        if (!message) return null;
        return (
            <>
                <div className={`${classes.message__container} ${extendedMessage ? classes.clickeable : ''}`} onClick={extendedMessage ? () => {
                    this.setState({
                        openDialog: true
                    })
                } : null}>
                    {!hideIcon && (
                        <WarningIcon className={classNames.warning__icon ? classNames.warning__icon : classes.warning__icon} />
                    )}
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        className={`${classNames.warning__message ? classNames.warning__message : classes.warning__message}`}>{message}</Typography>
                </div>
                {
                    extendedMessage && (
                        <Dialog open={openDialog} onClose={this.onClose}>
                            <Typography variant="body2" color='textSecondary' className={classNames.extended__message ? classNames.extended__message : classes.extended__message}>{extendedMessage}</Typography>
                        </Dialog>
                    )
                }
            </>
        )
    }
}

Warning.propTypes = {
    message: PropTypes.string,
    extendedMessage: PropTypes.string,
    hideIcon: PropTypes.bool,
    classNames: PropTypes.object
}

export default withStyles(styles)(Warning)