import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
    CircularProgress,
    withStyles,
    Divider,
    Grid,
    Fade,
    TextField,
    Button,
    Typography,
    MenuItem
} from '@material-ui/core/';
import queryString from 'query-string'
import i18n from 'i18next';

import {
    ClientRFQSearchResponse,
    VendorRFQSearchResponse,
    VendorPOsSearchResponse
} from '../components/search';
import { FileDetails } from '../components/quotes'
import API from '../lib/api';
import * as peopleActions from '../actions/peopleActions'


const styles = theme => ({
    pageTitle: {
        color: theme.palette.text.primary,
    },
    search__container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '900px'
    },
    search__filterSection: {
        padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`
    },
    buttons: theme.modal.buttons,
    search__datePicker: {
        fontFamily: theme.typography.fontFamily
    },
    search__title: {
        display: 'flex',
        marginBottom: '8px'
    },
    buttonSpinner: theme.buttonSpinner,
    circularProgress: {
        marginTop: '20%'
    },
    search__results__header__section: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    search__results__header__section__button: {
        borderRadius: '0px'
    },
    'search__results__header__section__button--selected': {
        backgroundColor: '#efe9c3',
        border: `1px solid ${theme.palette.primary.light}`,
        borderBottom: 'none',
        color: theme.palette.text.primary,
        borderRadius: '0px'
    },
    search__fileReference: {
        width: '100%'
    },
    search_clearIcon: {
        position: 'absolute',
        top: '16px',
        right: '10px',
        cursor: 'pointer'
    },
    search__goBack__button: {
        ...theme.customLink,
    },
    search__goTo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    search__form__input: {
        fontSize: '0.9em',
        textAlign: 'left'
    },
    search__form__textField: {
        width: '100%',
        margin: `${theme.spacing()}px 0`,
    },
    sarch__form__menuItem: {
        fontSize: '1em'
    },
    rfq__noRows: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh'
    },
});

class Search extends Component {
    state = {
        loading: false,
        fileReference: null,
        option: 'file',
        files: null,
        showFileDetail: false,
        selectedFile: null,
        lastUrl: null
    }

    componentWillMount() {
        this.props.peopleActions.setFilterPeople([])
        this.props.peopleActions.setFilterCounters(null)
    }

    componentDidMount() {
        this.handleUrl(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.handleUrl(nextProps)
    }

    handleUrl = (props) => {
        const { location } = props,
            { pathname, search } = location,
            { option, fileReference, files, loading } = this.state;

        if (pathname !== '/search') {
            return
        }

        if (search !== '' && !loading) {
            let params = queryString.parse(search)

            this.setState({
                option: params.option ? params.option : option,
                fileReference: params.fileReference ? params.fileReference : fileReference
            }, () => {
                if (!files) {
                    this.apiSearch(this.state.fileReference, this.state.option)
                }
            })

        }
    }


    handleChange = (e) => {
        const { fileReference } = this.state

        if (fileReference && fileReference !== '') {
            this.setState({
                loading: true,
                files: []
            }, () => {
                this.setState({
                    [e.target.name]: e.target.value
                }, () => {
                    this.searchFiles()
                })
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    apiSearch = (fileReference, option) => {
        this.setState({
            loading: true
        })

        let newFileReference = fileReference.trim().replace(/\s{2,}/g, ' ')

        API.Files.searchFiles({ fileReference: encodeURIComponent(newFileReference), option }).then(res => {
            if (res.data) {
                this.setState({
                    loading: false,
                    files: res.data.records
                })
            } else {
                this.setState({
                    loading: false,
                    validateErrorMessage: (res.errors && res.errors.message ? res.errors.message : "Unhandled error serching files")
                })
            }
        })
    }

    searchFiles = () => {
        const { fileReference, option } = this.state,
            { history } = this.props;

        history.push(`/search?fileReference=${encodeURIComponent(fileReference)}&option=${option}`);

        this.apiSearch(fileReference, option)
    }

    handleShowQuote = (row) => {
        this.setState({
            selectedFile: row,
            showFileDetail: true
        })

    }

    closeFileDetails = () => {
        this.setState({
            selectedFile: null,
            showFileDetail: false
        })
    }

    goTo = () => {
        const { history } = this.props;

        history.goBack();
    }

    render() {
        const { classes } = this.props,
            { loading,
                files,
                option,
                fileReference,
                showFileDetail,
                selectedFile
            } = this.state;

        let options = [
            {
                value: 'file',
                label: i18n.t("search.searchOptions.file")
            },
            {
                value: 'item',
                label: i18n.t("search.searchOptions.item")
            },
            {
                value: 'invoice',
                label: i18n.t("search.searchOptions.invoice")
            }
        ]

        let referenceLabel = ''
        switch (option) {
            case 'file':
                referenceLabel = i18n.t('search.rfqReference');
                break;
            case 'item':
                referenceLabel = i18n.t('search.itemReference');
                break;
            case 'invoice':
                referenceLabel = i18n.t('search.invoiceReference');
                break;
            default:
                break;
        }


        return (
            <div className={classes.search__container}>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.search')}`} />

                <Grid container spacing={1} justify={'flex-start'} className={classes.search__filterSection}>
                    <Grid xs={12} item className={classes.search__title}>
                        <Typography variant="h5">{i18n.t("search.title")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container item spacing={1} justify={'flex-start'}>
                            <Grid item xs={12} sm={7} style={{ marginTop: '16px' }}>
                                <TextField
                                    autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.search__form__input
                                    }}
                                    onChange={event => {
                                        this.setState({
                                            fileReference: event.target.value
                                        });
                                    }}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.searchFiles()
                                        }
                                    }}
                                    disabled={loading ? true : false}
                                    value={fileReference}
                                    className={classes.search__fileReference}
                                    id="fileReference"
                                    variant="outlined"
                                    label={referenceLabel}
                                />
                            </Grid>
                            <Grid item xs={7} sm={3} style={{marginTop: '8px'}}>
                                <TextField autoComplete='off'
                                    id="option"
                                    select
                                    InputProps={{
                                        className: classes.search__form__input
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={loading ? true : false}
                                    label={i18n.t('search.searchOption')}
                                    className={classes.search__form__textField}
                                    value={option}
                                    name='option'
                                    onChange={this.handleChange}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {options ? options.map(opt => (
                                        <MenuItem key={opt.value} value={opt.value} className={classes.sarch__form__menuItem}>
                                            {opt.label}
                                        </MenuItem>
                                    )) : []}
                                </TextField>
                            </Grid>
                            <Grid item xs={5} sm={2} style={{ marginTop: '16px', textAlign: 'right' }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={loading || (!fileReference)}
                                    onClick={this.searchFiles}>
                                    {i18n.t("search.searchBtn")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={2} xs={12} />
                </Grid>

                <Divider />

                {!loading && files && files.clientsFiles && files.clientsFiles.length > 0 &&
                    <ClientRFQSearchResponse
                        option={option}
                        files={files.clientsFiles}
                        handleShowQuote={this.handleShowQuote}
                    />
                }

                {!loading && files && files.vendorFiles && files.vendorFiles.length > 0 && <>
                    <Typography variant='h6' color='textPrimary' style={{ textAlign: 'left', marginTop: '16px' }}>
                        {i18n.t("search.vendorsFiles")}
                    </Typography>

                    <VendorRFQSearchResponse
                        files={files.vendorFiles}
                        handleShowQuote={this.handleShowQuote}
                    />
                </>}

                {!loading && files && files.vendorPOsFiles && files.vendorPOsFiles.length > 0 && <>
                    <Typography variant='h6' color='textPrimary' style={{ textAlign: 'left', marginTop: '16px' }}>
                        {i18n.t("search.vendorsPOsFiles")}
                    </Typography>

                    <VendorPOsSearchResponse
                        files={files.vendorPOsFiles}
                        handleShowQuote={this.handleShowQuote}
                    />
                </>}

                {!loading && files && files.clientsFiles && files.clientsFiles.length === 0 && (
                    <div className={classes.rfq__noRows}>
                        <Typography variant='body2'>{i18n.t('search.noResults')}</Typography>
                    </div>
                )}

                {loading && (
                    <Fade in={loading}>
                        <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                    </Fade>
                )}

                <FileDetails
                    open={showFileDetail}
                    onClose={this.closeFileDetails}
                    fileId={selectedFile ? selectedFile.id : null}
                    olderTask={selectedFile ? selectedFile.olderTask : null}
                    notesCount={selectedFile ? selectedFile.notesCount : 0}
                    status={selectedFile ? selectedFile.status : null}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}

export default connect('', mapDispatchToProps)(withRouter(withStyles(styles)(Search)));
