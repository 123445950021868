// Actions types

export const SET_PEOPLE = "SET_PEOPLE"
export const SET_FILTER_PEOPLE = 'SET_FILTER_PEOPLE'
export const SET_FILTER_COUNTERS = 'SET_FILTER_COUNTERS'

//Actions

export function setPeople(people) {
    return {
        type: SET_PEOPLE,
        people
    }
}
export function setFilterPeople(filter) {
    return {
        type: SET_FILTER_PEOPLE,
        filter
    }
}

export function setFilterCounters(counters) {
    return {
        type: SET_FILTER_COUNTERS,
        counters
    }
}