import React, { Component } from 'react'
import {
    Grid,
    Typography,
    TextField,
    IconButton,
    CircularProgress,
    InputAdornment,
    withStyles
} from '@material-ui/core'
import { Search, Phone, Close } from '@material-ui/icons'
import i18n from 'i18next'
import { ManageVendorPopup } from '.'
import CustomSelect from '../general/CustomSelect'
//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as vendorsActions from '../../actions/vendorsActions'

import API from '../../lib/api';


const style = theme => ({
    vendorRFQ__editActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        minHeight: theme.spacing(4)
    },
    vendor__divider: {
        marginBottom: theme.spacing()
    },
    vendor__form__input: {
        fontSize: '0.9em',
    },
    vendor__form__textField: {
        margin: `${theme.spacing()}px 0`,
    },
    rfq__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    vendor__search__clear: {
        padding: 0,
        margin: 0,
        '&:hover': {
            background: 'transparent'
        }
    },
    vendor__search__button: {
        '&:hover': {
            background: 'transparent'
        }
    },
    vendorRFQ__icon__phone: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    vendor__title: {
        color: theme.palette.blue[100]
    }
})

const defaultVendor = {
    name: '',
    address: {
        address1: '',
        address2: ''
    },
    phone1: '',
    phone2: '',
    website: '',
    remarks: '',
    id: null,
    contacts: []
}



class Vendor extends Component {
    constructor(props) {
        super(props);
        const { vendorQuote } = this.props;
        this.state = {
            selectedVendor: vendorQuote && vendorQuote.vendor ? vendorQuote.vendor : null,//selectedVendor || null,
            loadingVendors: false,
            searchVendor: vendorQuote && vendorQuote.vendor ? vendorQuote.vendor.name : '',
            fetched: false,
            fetching: false,
            showMinCharsError: false
        }
    }

    componentWillMount = () => {
        const {
            searchVendor
        } = this.state;
        if (searchVendor) {
            this.fetchVendors();
        }
    }
    fetchVendors = () => {
        const { vendorQuote } = this.props,
            {
                selectedVendor,
                searchVendor
            } = this.state;
        if (searchVendor.length >= 3) {
            this.setState({
                fetching: true,
                fetched: false,
                showMinCharsError: false
            }, _ => {
                API.Vendors.getVendors({
                    filter: searchVendor
                })
                    .then(vendorsResponse => {
                        this.props.vendorsActions.setVendors(vendorsResponse.data.records || [])
                        let vendor = null;
                        if (selectedVendor) {
                            vendor = vendorsResponse.data.records.find(v => v.id === selectedVendor.id);
                            if (vendor) {
                                this.props.vendorsActions.setVendor(vendor)
                                this.props.changeVendor(vendor)
                            } else {
                                this.setState({
                                    selectedVendor: defaultVendor
                                })
                                this.props.vendorsActions.setVendor({})
                                this.props.changeVendor(defaultVendor)
                            }
                        }

                        if (vendorsResponse.data.records.length === 1) {
                            vendor = vendorsResponse.data.records[0];
                            this.setState({
                                selectedVendor: vendor
                            })
                            this.props.vendorsActions.setVendor(vendor)

                            //Si no hay un vendor pre selecccionado llamamos a change vendor del padre
                            if (!vendorQuote) {
                                this.props.changeVendor(vendor)
                            } else if (!vendorQuote.vendor || (vendorQuote.vendor && vendorQuote.vendor.id !== vendor.id)) {
                                this.props.changeVendor(vendor)
                            }
                        }

                        this.setState({
                            fetching: false,
                            fetched: true
                        })
                    })
            })
        } else {
            this.setState({
                showMinCharsError: true,
                selectedVendor: defaultVendor
            })
            this.props.vendorsActions.setVendors([])
            this.props.vendorsActions.setVendor({})
            this.props.changeVendor(defaultVendor)
        }

    }
    changeVendor = (vendor) => {
        this.setState({
            selectedVendor: vendor || defaultVendor,
            //searchVendor: vendor ? vendor.name : ''
        })
        this.props.changeVendor(vendor);
    }
    handleTextFieldKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                this.fetchVendors();
                break
            case 'Escape':
                this.setState({
                    fetched: false,
                    searchVendor: '',
                })
                break
            default: break
        }
    }
    handleVendorChange = (selectedVendor) => {
        let { vendors } = this.props;
        if (selectedVendor) {
            let vendor = vendors.find(v => v.id === selectedVendor.value);
            this.props.vendorsActions.setVendor(vendor)
            this.setState({
                selectedVendor: vendor,
            })
            this.changeVendor(vendor)
        } else {
            this.props.vendorsActions.setVendor({})
            this.setState({
                selectedVendor: defaultVendor,
            })
            this.changeVendor(defaultVendor)
        }
    }
    render() {
        const {
            vendors,
            classes,
            validateVendorQuote,
            saving,
        } = this.props,
            {
                fetched,
                fetching,
                searchVendor,
                selectedVendor,
                loadingVendors,
                showMinCharsError
            } = this.state;

        return (
            <div>
                <div className={classes.vendorRFQ__editActions}>
                    <Typography variant='h6' className={classes.vendor__title}>{i18n.t('quotes.vendorQuoteRequest.vendor')}</Typography>
                    <ManageVendorPopup
                        selectedVendor={selectedVendor}
                        changeVendor={this.changeVendor}
                    />
                </div>
                <Grid container>
                    <Grid item xs={12} sm={10} >
                        <TextField
                            autoComplete='off'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                className: classes.vendor__form__input,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={classes.vendor__search__button}
                                            onClick={this.fetchVendors}
                                            disabled={fetching || saving}>
                                            {fetching ? (
                                                <CircularProgress color='secondary' size={24} />
                                            ) : (
                                                    <Search color='secondary' fontSize="small" />
                                                )}
                                        </IconButton>
                                        <IconButton
                                            disableRipple={true}
                                            className={classes.vendor__search__clear}
                                            onClick={() => {
                                                this.setState({
                                                    fetched: false,
                                                    searchVendor: ''
                                                });
                                            }}
                                            size='small'
                                        >
                                            <Close fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            disabled={saving || fetching ? true : false}
                            onChange={e => {
                                this.setState({
                                    fetched: false,
                                    searchVendor: e.target.value
                                });
                            }}
                            onKeyDown={this.handleTextFieldKeyDown}
                            value={searchVendor ? searchVendor : ''}
                            className={classes.vendor__form__textField}
                            id="vendorSearch"
                            type="text"
                            variant="outlined"
                            label={i18n.t('vendors.vendorSearchName')}
                        />
                    </Grid>
                    {showMinCharsError && (
                        <Grid item xs={10}>
                            <Typography variant='body2' color='textSecondary'>{i18n.t('vendors.minimumChars')}</Typography>
                        </Grid>
                    )}

                    {vendors && vendors.length > 0 && (
                        <Grid item xs={12} sm={10}>
                            {/* Vendor selector */}
                            <CustomSelect
                                options={vendors.map((vendor, i) => {
                                    return {
                                        value: vendor.id,
                                        label: vendor.name
                                    }
                                })}
                                placeholder={i18n.t('vendors.selectVendor')}
                                label={i18n.t('quotes.vendorQuoteRequest.vendorSelect')}
                                value={selectedVendor && selectedVendor.id ? selectedVendor.id : null}
                                fullWidth={true}
                                required={true}
                                disabled={loadingVendors}
                                error={((selectedVendor && !selectedVendor.id) || !selectedVendor) && validateVendorQuote ? true : false}
                                onSelect={this.handleVendorChange}
                            />
                        </Grid>
                    )}
                    {fetched && searchVendor && (vendors.length === 0) && (
                        <Typography variant='body2' color='textSecondary'>{`${i18n.t('vendors.noVendorsWithTerm')}`}<strong>{`${searchVendor}`}</strong></Typography>
                    )}
                </Grid>


                {selectedVendor && selectedVendor.id !== null &&
                    <Typography variant='body2' color="textSecondary">
                        {selectedVendor.name || ''}
                    </Typography>
                }

                {selectedVendor && selectedVendor.remarks &&
                    <Typography variant='body2' color="textSecondary">{selectedVendor.remarks}</Typography>
                }

                {selectedVendor && selectedVendor.id !== null &&
                    <Typography variant='body2' color="textSecondary">
                        {selectedVendor.address.address1 || ''}
                        <br />
                        {selectedVendor.address.address2 || ''}
                    </Typography>
                }


                {selectedVendor && selectedVendor.id !== 0 &&
                    <div>
                        <Typography variant='body2' color="textSecondary">{selectedVendor.website || ''}</Typography>
                        {selectedVendor.phone1 &&
                            <Typography variant='body2' color="textSecondary" noWrap>
                                <Phone className={classes.vendorRFQ__icon__phone} />
                                {selectedVendor.phone1}
                            </Typography>
                        }
                        {selectedVendor.phone2 &&
                            <Typography variant='body2' color="textSecondary" noWrap>
                                +<Phone className={classes.vendorRFQ__icon__phone} />
                                {selectedVendor.phone2}
                            </Typography>
                        }
                    </div>
                }


            </div>
        )
    }
}

const mapStateToProps = ({ vendors, file, vendor }) => (
    {
        vendors,
        file,
        vendor
    });
function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Vendor))