import React, { Component } from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    withStyles,
    withMobileDialog
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Search as SearchIcon,
    Mail,
} from '@material-ui/icons/';
import { withRouter } from "react-router";
import LeftSideBar from './LeftSidebar';
import styled from 'styled-components';
import i18n from 'i18next';
import auth from '../authentication/auth';
import {
    isMobile
} from "react-device-detect";

const logo = require('../assets/images/logosmall.png');
const logomobile = require('../assets/images/logomobile.png');


const HeaderLogo = styled.div`
    flex-grow:1;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        margin-right:5px;
    };
`;

const styles = (theme) => ({
    blankSpace: {
        height: '50px',
        background: 'blue'
    },
    logoAndSearchContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: theme.maxWith,
        margin: '0 auto',
        marginLeft: '-20px',
        background: 'yellow',
    },
    toolbar: {
        maxWidth: theme.maxWith,
        margin: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    mail__button__container: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '16px',
        cursor: 'pointer'
    }
})

class Header extends Component {
    state = {
        menuVisible: false,
        anchorEl: null,
        openSearch: false,
        hideSearch: (this.props.history.location.pathname === '/search' || this.props.history.location.pathname.indexOf('/emails') >= 0) ? true : false,
        hideEmails: this.props.history.location.pathname.indexOf('/emails') >= 0 ? true : false,
        selectedVisit: false,
        user: null
    }

    componentWillReceiveProps(nextProps) {
        const { history } = nextProps;

        if (history.location.pathname === '/search' || history.location.pathname.indexOf('/emails') >= 0) {
            this.setState({
                hideSearch: true,
                hideEmails: history.location.pathname.indexOf('/emails') >= 0 ? true : false
            })
        } else {
            this.setState({
                hideSearch: false,
                hideEmails: history.location.pathname.indexOf('/emails') >= 0 ? true : false
            })
        }

        if (history.location.pathname === '/visits' || history.location.pathname === '/v1/visits') {
            this.setState({
                selectedVisit: true
            })
        } else {
            this.setState({
                selectedVisit: false
            })
        }


    }


    componentWillMount() {
        const { history } = this.props;

        auth.getAuthenticatedUser()
            .then(user => {
                this.setState({
                    user
                })
            }).catch(err => {
                auth.signOut().then(() => {
                    history.push('/signIn');
                }).catch(err => console.log(err));
            });



        if (history.location.pathname === '/search' || history.location.pathname.indexOf('/emails') >= 0) {
            this.setState({
                hideSearch: true,
                hideEmails: history.location.pathname.indexOf('/emails') >= 0 ? true : false
            })
        } else {
            this.setState({
                hideSearch: false,
                hideEmails: history.location.pathname.indexOf('/emails') >= 0 ? true : false
            })
        }

        if (history.location.pathname === '/visits' || history.location.pathname === '/v1/visits') {
            this.setState({
                selectedVisit: true
            })
        } else {
            this.setState({
                selectedVisit: false
            })
        }
    }


    toggleOpenMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        });
    }

    handleLanguageMenu = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleCloseLanguageMenu = () => {
        this.setState({
            anchorEl: null
        });
    };

    changeLanguage = lng => {
        i18n.changeLanguage(lng);
        this.handleCloseLanguageMenu()
    };

    handleOpenSearch = () => {
        const { history } = this.props;

        this.setState({
            hideSearch: true
        })

        history.push('/search');
    }

    handleOpenEmails = () => {
        const { history } = this.props;
        history.push('/emails/inbox');
    }
    handleOpenProfile = () => {
        const { openProfile } = this.state;

        this.setState({
            hideSearch: false,
            openProfile: !openProfile
        })
    }

    render() {
        const { classes, width } = this.props,
            { hideSearch, hideEmails, selectedVisit } = this.state;
        return (
            <div>
                <AppBar position="fixed" color="default">
                    <LeftSideBar
                        menuVisible={this.state.menuVisible}
                        toggleOpenMenu={this.toggleOpenMenu}
                    />
                    <Toolbar className={classes.toolbar} >
                        <div style={width !== 'xs' ? { width: '194px', display: 'flex' } : {}}>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={this.toggleOpenMenu} >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <HeaderLogo style={{ width: '50%', position: 'absolute', left: 0, right: 0, margin: 'auto' }}>
                            <img src={isMobile || width === 'xs' ? logomobile : logo} alt='Prodek'
                                style={{
                                    width: isMobile || width === 'xs' ? 50 : 95,
                                    paddingTop: isMobile || width === 'xs' ? 0 : 8,
                                    margin: 0
                                }} />
                        </HeaderLogo>
                        {!selectedVisit ?
                            <div>
                                {!hideEmails && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="Menu"
                                        onClick={this.handleOpenEmails} >
                                        <Mail />
                                    </IconButton>
                                )}
                                {!hideSearch &&
                                    <IconButton
                                        color="inherit"
                                        aria-label="Search"
                                        onClick={this.handleOpenSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                }
                            </div> :
                            <div style={{ width: width === 'xs' ? '48px' : '194px' }} />
                        }
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default withRouter(withMobileDialog()(withStyles(styles)(Header)));