// Actions types

export const FETCHING = "FETCHING"
export const FETCHED = "FETCHED"

function apiStatus(state = {}, action) {
    switch (action.type) {
        case FETCHING:
            return {
                ...state,
                status: FETCHING,
                when: new Date()
            }
        case FETCHED:
            return {
                ...state,
                status: FETCHED,
                when: new Date()
            }
        default:
            return state;
    }
}
export default apiStatus