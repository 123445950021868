import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import {
    Tabs,
    Tab,
    CircularProgress,
    Hidden,
    FormControl,
    InputLabel,
    Select,
    withStyles,
    Typography,
    Divider,
    Grid,
    Fade,
    Badge,
    MenuItem
} from '@material-ui/core/';
import i18n from 'i18next';

// import Permissions from '../lib/permissions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../actions/filesActions'
import * as vendorQuotesActions from '../actions/vendorQuotesActions'
import * as apiStatusActions from '../actions/apiStatusActions'

import {
    RequestedByClient,
    RequestedToVendor,
    FileDetails,
    Quoted,
    PurchaseOrders,
    QuotesListLeyend,
    FinishedOrders,
    InvoicedOrders
} from '../components/quotes';

import { PeopleFilter, CustomMessages } from '../components/general'
import moment from 'moment'

import { ManageClientRfq } from '../components/quotes/clientRFQ'
import API from '../lib/api';

const styles = theme => ({
    pageTitle: {
        color: theme.palette.text.primary,
    },
    quotes__container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px'
    },
    quotes__container__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    quotes__container__titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing()
        },
    },
    quotes__statusTabs: {
        marginTop: theme.spacing(2),
        minWidth: 'fit-content!important'
    },
    divider: {
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.primary.main}`
    },
    circularProgress: {
        marginTop: '20%'
    },
    sort__component__container: theme.sort__component__container,
    filtrerBadge: {
        top: '-2px',
        right: '-10px',
    },
    createClientRfq__container: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        }
    }

});
let searchParameters = {};


class Quotes extends Component {

    state = {
        pageTitle: 'Quotes',
        currentTab: 0,
        status: 1,
        loading: true,
        fetching: false,
        showEditQuote: false,
        selectedFileId: null,
        row: null,
        routeStatus: 'rfq',
        focusedVendorQuote: null,
        showVendorsPOs: false,
        showTasksSection: false,
        sortBy: 0,
        sorted: false,
        successMessage: null
    }



    componentWillReceiveProps = (nextProps) => {
        const { match } = nextProps;
        if (match.params.status !== this.state.routeStatus) {
            this.handleRouteChange(match.params.status);
        }
        if (this.state.selectedFileId && !match.params.fileId) {
            this.handleCreateQuoteClose();
        }
        if (!this.state.loading && !this.state.selectedFileId && match.params.fileId) {
            this.handleShowQuote(match.params.fileId, { vendorQuoteId: this.state.focusedVendorQuote });
        }
    }

    componentDidMount() {
        const { match } = this.props;
        this.handleRouteChange(match.params.status);
    }

    fetchQuotesByStatus = (status) => {
        let params = null;
        if(status === 3 || status === 6){
            params = {selectedDateTo: moment().endOf('month'), selectedDateFrom: moment().startOf('month')}
        }
        return new Promise((resolve, reject) => {
            if (!this.state.fetching) {
                this.props.apiStatusActions.fetching()
                this.setState({
                    fetching: true,
                    loading: true
                }, _ => {
                    API.Files.getFiles({
                        recordCount: 50,
                        offset: 0,
                        status: status === 6 ? 'finished' : status === 7 ? 'invoiced' : status,
                        userId: this.props.account ? this.props.account.userId : null,
                        params: params
                    }).then(res => {
                        this.props.filesActions.setFiles(res.data && res.data.records ? res.data.records : []);
                        if (status === 2) {
                            API.Quotes.getVendorQuotesList().then(resp => {
                                if (res.status === 'success') {
                                    this.props.vendorQuotesActions.setVendorQuotes(resp.data.records)
                                    this.sortRows();
                                }
                                this.setState({
                                    fetching: false,
                                    loading: false
                                });
                                this.props.apiStatusActions.fetched()
                                resolve();
                            })
                        } else {
                            this.sortRows();
                            this.setState({
                                fetching: false,
                                loading: false
                            });
                            this.props.apiStatusActions.fetched()
                            resolve();
                        }
                    })
                })
            } else {
                resolve();
            }
        })
    }

    handleRouteChange = (routeStatus) => {
        let status = this.state.status || 1,
            currentTab = this.state.currentTab || 0,
            pageTitle = '';


        switch (routeStatus) {
            case 'rfq':
                status = 1;
                currentTab = 0;
                pageTitle = i18n.t('quotes.navigationTitles.rfq');
                break;
            case 'vrfq':
                status = 2;
                currentTab = 1;
                pageTitle = i18n.t('quotes.navigationTitles.vendorrfq');
                break;
            case 'quote':
                status = 3;
                currentTab = 2;
                pageTitle = i18n.t('quotes.navigationTitles.quote');
                break;
            case 'po':
                status = 5;
                currentTab = 3;
                pageTitle = i18n.t('quotes.navigationTitles.po');
                break;
            case 'invoiced':
                status = 7;
                currentTab = 4;
                pageTitle = i18n.t('quotes.navigationTitles.invoiced');
                break;
            case 'finished':
                status = 6;
                currentTab = 5;
                pageTitle = i18n.t('quotes.navigationTitles.finished');
                break;
            default:
                pageTitle = i18n.t('quotes.navigationTitles.rfq');
                break;
        }
        this.setState({
            status,
            pageTitle,
            routeStatus,
            currentTab
        })

        this.fetchQuotesByStatus(status)
        if (routeStatus === 'file') {
            const { match } = this.props;
            if (match.params.fileId) {
                this.handleShowQuote(parseInt(match.params.fileId, 10), { vendorQuoteId: this.state.focusedVendorQuote });
            }
        }
    }

    reloadList = () => {
        this.setState({
            loading: true
        }, () => {
            this.fetchQuotesByStatus(this.state.status);
        })
    }

    changeTab = (e, value) => {
        const { history } = this.props;
        let status = value + 1,
            url = '';

        switch (status) {
            case 1:
                url = '/quotes/rfq';
                break;
            case 2:
                url = '/quotes/vrfq';
                break;
            case 3:
                url = '/quotes/quote';
                break;
            case 4:
                url = '/quotes/po';
                status = 5;
                break;
            case 5:
                status = 7;
                url = '/quotes/invoiced';
                break;
            case 6:
            case 7:
                status = 6;
                url = '/quotes/finished';
                break;
            default:
                url = '/quotes/rfq';
                status = 1;
                break;
        }
        let parameters = [],
            parametersKeys = Object.keys(searchParameters);

        if (searchParameters && parametersKeys.length > 0) {
            parametersKeys.forEach((key) => {
                let value = searchParameters[key];
                if (value) {
                    parameters.push(`${key}=${value}`)
                }
            })
        }
        if (parameters.length > 0) {
            url += `?${parameters.join('&')}`
        }

        if (status !== this.state.status) {
            if (history.location.pathname !== url) {
                history.push(url)
            }
        } else {
            this.fetchQuotesByStatus(status);
        }

        this.setState({
            currentTab: value,
            newSection: value,
            status: status,
            loading: true,
        })

    };


    handleCreateQuoteClose = (successMessage) => {
        const { history,
            match
        } = this.props,
            { newSection, currentTab } = this.state;
        this.setState({
            successMessage: successMessage || null,
            showEditQuote: false,
            selectedFileId: null,
            focusedVendorQuote: null
        });
        // history.goBack();
        let url = `/quotes/${match.params.status}`

        if (history.location.pathname !== url) {
            history.push(url)
        }

        if (newSection !== currentTab && !isNaN(parseInt(newSection))) {
            this.changeTab(null, newSection)
        }

    }
    handleShowQuote = (fileId, args) => {
        const { vendorQuoteId, showVendorsPOs, showTasksSection } = args || {};
        const { history, match, files } = this.props;
        let file = files.find(r => parseInt(r.id) === parseInt(fileId));

        this.setState({
            showVendorsPOs: false
        })

        // if (file) {
        this.setState({
            showEditQuote: true,
            selectedFileId: fileId,
            row: file || null,
            showVendorsPOs: showVendorsPOs ? true : false,
            focusedVendorQuote: vendorQuoteId,
            showTasksSection: showTasksSection ? true : false
        }, () => {
            let newPath = `/quotes/${match.params.status}/file/${fileId}`;
            if (history.location.pathname !== newPath) {
                history.push(newPath)
            }
        })
        // }
    }

    setSearchParmeters = (newSearchParameters) => {
        searchParameters = {
            ...searchParameters,
            ...newSearchParameters
        };
    }

    addNewRFQ = (file) => {
        const { currentTab } = this.state;
        this.props.filesActions.addFile(file);
        if (currentTab !== 0) {
            this.changeTab(null, 0)
        }
    }

    refreshFilesLists = (tab) => {
        if (!isNaN(parseInt(tab))) {
            this.setState({
                newSection: tab >= 0 ? tab : this.state.currentTab,
            })
        }
    }

    updateFileList = (file) => {
        this.props.filesActions.updateFile(file);
    }

    sortRows = () => {
        const {
            sortBy,
        } = this.state;
        if (!this.state.sorted) {
            this.setState({
                loading: true
            }, () => {
                this.props.filesActions.sortFiles(sortBy);
                //Timer para hacer efecto de fade
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        sorted: true
                    })
                }, 50);
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const labels = [
            i18n.t("quotes.tabs.rfq"),
            i18n.t("quotes.tabs.vendorRFQ"),
            i18n.t("quotes.tabs.quote"),
            i18n.t("quotes.tabs.po"),
            i18n.t("quotes.tabs.invoiced"),
            i18n.t("quotes.tabs.finished")
        ];

        const {
            currentTab,
            loading,
            status,
            showEditQuote,
            selectedFileId,
            row,
            pageTitle,
            focusedVendorQuote,
            showVendorsPOs,
            showTasksSection,
            sortBy,
            successMessage
        } = this.state,
            { classes, files, apiStatus, people, vendorQuotes } = this.props;

        let countersStatusObject = ['rfq', 'vrfq', 'quote', 'po', 'invoiced', 'finished'];
        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${pageTitle}`} />
                <div className={classes.quotes__container}>

                    <div className={classes.quotes__container__header}>
                        <Grid container spaing={0} justify='space-between'>
                            <Grid xs={12} sm={10} item className={classes.quotes__container__titleContainer}>
                                <Typography variant="h5">{i18n.t("quotes.title")}</Typography>
                                <PeopleFilter
                                    reloadList={this.reloadList} />
                            </Grid>


                            <Grid item xs={12} sm={2} className={classes.createClientRfq__container}>
                                <ManageClientRfq addNewRFQ={this.addNewRFQ} />
                            </Grid>
                        </Grid>
                    </div>
                    <Hidden xsDown implementation="css">
                        <Tabs
                            className={classes.quotes__statusTabs}
                            value={currentTab}
                            onChange={this.changeTab}
                            indicatorColor="primary"
                            textColor="secondary"
                        >

                            {labels.map((label, i) => {
                                // if(!Permissions.checkSensitiveInformationPermission() && label === 'Vendor RFQ'){
                                //     return (
                                //         <Tab disabled={true} style={{ width: 0, minWidth: 0, padding: 0 }}/>
                                //     )
                                // } else {
                                return (
                                    <Tab
                                        key={i}
                                        label={(<>
                                            <Badge classes={{ badge: classes.filtrerBadge }}
                                                badgeContent={people && people.counters ? people.counters.statusCounters[countersStatusObject[i]].total : 0}
                                                color="primary">
                                                {label}
                                            </Badge>
                                        </>)}
                                        style={{ width: 120 }}
                                        disabled={loading}
                                    />
                                )
                                // }
                            })}
                        </Tabs>
                        <Divider className={classes.divider} />
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <FormControl style={{
                            margin: '20px 0',
                            width: '100%'
                        }}>
                            <InputLabel htmlFor="quoteStatus">{i18n.t("quotes.status")}</InputLabel>
                            <Select
                                disabled={loading}
                                native
                                value={currentTab}
                                onChange={(e) => this.changeTab(e, parseInt(e.target.value))}
                                inputProps={{
                                    name: 'age',
                                    id: 'quoteStatus',
                                }}
                            >
                                {labels.map((label, i) => {
                                    // if (!Permissions.checkSensitiveInformationPermission() && label === 'Vendor RFQ') {
                                    //     return null
                                    // } else {
                                    return (
                                        <option value={i} key={i} >{label}</option>
                                    )
                                    // }
                                })}
                            </Select>
                        </FormControl>
                    </Hidden>

                    {(files && files.length > 0) && (
                        <div className={classes.sort__component__container}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    value={sortBy}
                                    disabled={loading || apiStatus.status === apiStatusActions.FETCHING}
                                    inputProps={{
                                        name: 'sortby',
                                        id: 'aselect-sortby',
                                    }}
                                    onChange={(e) => {
                                        this.setState({
                                            sortBy: e.target.value,
                                            sorted: false
                                        }, this.sortRows)
                                    }}
                                >
                                    <MenuItem value={0}>{i18n.t('sortByDate')}</MenuItem>
                                    <MenuItem value={1}>{i18n.t('sortByLastUpdate')}</MenuItem>
                                    <MenuItem value={2}>{i18n.t('sortByImportance')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}

                    <Fade in={!loading}>
                        <div>


                            {!loading &&
                                <>
                                    {status === 1 && (
                                        <RequestedByClient
                                            rows={files}
                                            reloadParent={() => { this.changeTab({}, 0) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}

                                    {status === 2 && (
                                        <RequestedToVendor
                                            rows={files}
                                            vendorQuotes={vendorQuotes}
                                            reloadParent={() => { this.changeTab({}, 1) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}
                                    {(status === 4 || status === 3) && (
                                        <Quoted
                                            rows={files}
                                            reloadParent={() => { this.changeTab({}, 2) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}

                                    {status === 5 && (
                                        <PurchaseOrders
                                            rows={files}
                                            reloadParent={() => { this.changeTab({}, 3) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}

                                    {(status === 7) && (
                                        <InvoicedOrders
                                            rows={files}
                                            reloadParent={() => { this.changeTab({}, 4) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}

                                    {(status === 6) && (
                                        <FinishedOrders
                                            rows={files}
                                            reloadParent={() => { this.changeTab({}, 4) }}
                                            handleShowQuote={this.handleShowQuote}
                                            setSearchParmeters={this.setSearchParmeters} />
                                    )}
                                </>
                            }
                        </div>
                    </Fade>
                    {loading && (
                        <Fade in={loading}>
                            <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                        </Fade>
                    )}
                    {(!loading && files.length > 0) && (
                        <QuotesListLeyend status={status} />
                    )}

                </div>

                <CustomMessages
                    type={'success'}
                    message={successMessage}
                    clearMessage={() => this.setState({
                        successMessage: null
                    })}
                />


                <FileDetails
                    open={showEditQuote}
                    onClose={this.handleCreateQuoteClose}
                    fileId={selectedFileId}
                    olderTask={row ? row.olderTask : null}
                    notesCount={row ? row.notesCount : 0}
                    status={row && row.finished ? row.status : status}
                    updateFileList={this.updateFileList}
                    showVendorsPOs={showVendorsPOs}
                    refreshFilesLists={this.refreshFilesLists}
                    focusedVendorQuote={focusedVendorQuote}
                    showTasksSection={showTasksSection} />


            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        files: state.files,
        vendorQuotes: state.vendorQuotes,
        people: state.people,
        account: state.account,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Quotes));

