import React, { Component } from 'react'
import {
    Toolbar,
    Typography,
    Button,
    withStyles,
    CircularProgress,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress,
    Link,
    Popover
} from '@material-ui/core'
import {
    Assignment,
    NavigateNext,
    NavigateBefore,
    Close,
    Warning
} from '@material-ui/icons'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import { ConfirmationDialog, ConfirmationReturnStatusDialog, CustomMessages } from '../general'
import { ConfirmationAcceptQuote } from './preparingQR'
import i18n from 'i18next';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../actions/apiStatusActions'
import { SlideUp } from '../general/Transitions'
import { InvoiceItems, InvoiceOtherCosts } from './invoices'

import API from '../../lib/api'

import { PrintPopup } from '../reports';
import Permissions from '../../lib/permissions'



const styles = theme => {
    return ({
        customLink: {
            ...theme.customLink,
            marginLeft: '3px',
            marginRight: '3px'
        },
        action__bar__toolBar: {
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: theme.maxWith,
            margin: 'auto',
            marginTop: 0,
            marginBottom: 0

        },
        action__bar__actionButton: {
            marginRight: theme.spacing(),
            padding: '5px 30px',
            backgroundColor: theme.palette.blue[100],
            '&:hover': {
                backgroundColor: theme.palette.blue[80],
            },
            color: 'white',
        },
        action__bar__actionButton__reject: {
            marginRight: theme.spacing(),
            padding: '5px 30px',
            backgroundColor: theme.palette.red[80],
            '&:hover': {
                backgroundColor: theme.palette.red[50],
            },
            color: 'white',
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing()
            }
        },
        action__bar__buttons__container: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'flex-end',
                flexDirection: 'row'
            },
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                flexDirection: 'column'
            },

        },
        action__bar__gridItem: {
            padding: '0 20px !important',
            [theme.breakpoints.down('xs')]: {
                padding: '0px !important',
            },
        },
        action__bar__warning_container: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'flex-start'
            },
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
            },
            cursor: 'pointer'
        },
        log: {
            ...theme.modal.container,
            padding: '16px 24px 24px',
            minHeight: 350,
        },
        success: {
            backgroundColor: theme.palette.green[100],
        },
        error: {
            backgroundColor: theme.palette.primary.main,
        },
        action__bar__actionButton__container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
        },
        action__bar__actionButton__current: {
            width: '190px',
            backgroundColor: theme.palette.blue[100],
            '&:hover': {
                backgroundColor: theme.palette.blue[80],
                color: theme.palette.getContrastText(theme.palette.blue[80]),
            },
            color: theme.palette.getContrastText(theme.palette.blue[100]),
        },
        action__bar__actionButton__current__disabled: {
            width: '190px',
            backgroundColor: "#90979e",
            '&:hover': {
                backgroundColor: '#ccc',
                color: theme.palette.getContrastText('#ccc'),
            },
            color: '#fff',
        },
        subaction__bar__actionButton: {
            marginTop: theme.spacing(),
            minWidth: '150px',
            '&:disabled': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        subaction__bar__actionButton__next: {
            backgroundColor: theme.palette.blue[100],
            color: 'white',
            padding: '5px 10px',
            '&:hover': {
                backgroundColor: theme.palette.blue[100],
                filter: 'brightness(1.1);'
            }
        },
        subaction__bar__actionButton__prev: {
            backgroundColor: 'white',
            color: theme.palette.red[90],
            textDecoration: 'underline',
            margin: '0 5px 5px 0',
            '&:hover': {
                backgroundColor: 'white',
                filter: 'brightness(1.1);'
            }
        },
        subaction__bar__actionButton__prev__salesRep: {
            backgroundColor: '#eceff1',
            color: theme.palette.red[90],
            textDecoration: 'underline',
            margin: '0',
            '&:hover': {
                backgroundColor: 'white',
                filter: 'brightness(1.1);'
            }
        },
        subaction__bar__actionButton__reject: {
            backgroundColor: theme.palette.red[100],
            marginLeft: theme.spacing(),
            color: 'white',
            width: '190px',
            '&:hover': {
                backgroundColor: theme.palette.red[100],
                filter: 'brightness(1.1);'
            }
        },
        subaction__bar__actionButton__archive: {
            backgroundColor: 'white',
            color: theme.palette.red[90],
            textDecoration: 'underline',
            width: '100px',
            minWidth: '100px',
            margin: '0 0 5px 5px',
            '&:hover': {
                backgroundColor: 'white',
                filter: 'brightness(1.1);'
            }
        },
        action__bar__missingcondition__label: {
            //fontSize: '9pt',
            textAlign: 'right',
            fontWeight: 'bold',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'orange'
        },
        dialog__paper: {
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
            // overflow: 'visible'
        },
        closeButton: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },

        dialog__content__container: {
            padding: theme.spacing(2)
        },
        dialog__loader__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: theme.spacing()
        },
        currentStatus__message__label: {
            textAlign: 'center',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(2)
        },
        action__buttons__container: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        footer__bottons__container: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        print__component__container: {
            marginRight: theme.spacing(),
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 0,
                margiBottom: theme.spacing(),
            },
        },
        action__bar__readyToDeliver__label: {
            textAlign: 'right',
            fontWeight: 'bold',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: theme.palette.green[100]
        },
        warning__message__icon: {
            marginRight: theme.spacing(),
            fontSize: '14pt',
            color: theme.palette.orange[100],
        },
        messages__container: {
            display: 'flex'
        }
    })
};

//Current status indicator component
const statusIndicatorStyles = theme => ({
    statusIndicatorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    statusIndicator__labelsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    rootBar: {
        height: theme.spacing(2)
    },
    bar1Determinate: {
        backgroundColor: theme.palette.blue[70]
    },
    bardeterminate: {
        backgroundColor: theme.palette.grey[200]
    },
    step__label: {
        color: theme.palette.text.secondary
    },
    'step__label--done': {
        color: theme.palette.blue[70]
    }
})

class statusIndicatorComponent extends Component {
    render() {
        const { classes, file } = this.props;
        let progressPercent = 0,
            stepsDone = 1;



        if (!file) return null;


        const statuses = [
            i18n.t("quotes.detailsTabs.rfq"),
            i18n.t("quotes.detailsTabs.vendors"),
            i18n.t("quotes.detailsTabs.quote"),
            i18n.t("quotes.detailsTabs.purchaseOrder"),
            i18n.t("quotes.detailsTabs.accounting"),
            i18n.t("quotes.detailsTabs.delivered")

        ]

        switch (file.status) {
            case (1):
                progressPercent = 5;
                stepsDone = 1;
                break;
            case (2):
                progressPercent = 16;
                stepsDone = 2;
                break;
            case (3):
            case (4):
            case (9):
                progressPercent = 35;
                stepsDone = 3;
                break;
            case (5):
                if (file.clientQuote[0].clientPO.status.id === 2 || file.clientQuote[0].clientPO.status.id === 3) {
                    progressPercent = 75;
                    stepsDone = 5;
                } else {
                    progressPercent = 54;
                    stepsDone = 4;
                }
                break;
            case (7):
                progressPercent = 100;
                stepsDone = 6;
                break;
            default:
                break;
        }

        return (
            <div className={classes.statusIndicatorContainer}>
                <LinearProgress
                    variant="determinate"
                    value={progressPercent}
                    thickness={80}
                    classes={{
                        root: classes.rootBar,
                        determinate: classes.bardeterminate,
                        bar1Determinate: classes.bar1Determinate,

                    }} />
                <div className={classes.statusIndicator__labelsContainer}>
                    {statuses.map((status, i) => (
                        <div key={i}>
                            <Typography
                                className={stepsDone >= (i + 1) ? classes['step__label--done'] : classes.step__label}>
                                {status}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

const StatusIndicator = withStyles(statusIndicatorStyles)(statusIndicatorComponent)

///


class ActionsBar extends Component {

    state = {
        loading: false,
        file: this.props.file,
        showConfirmation: false,
        openConfirmation: false,
        showConfirmationReject: false,
        showConfirmationAccept: false,
        showDeleteConfirmation: false,
        apiErrorMessage: null,
        archivingFile: false,
        unarchivingFile: false,
        successArchiveMessage: null,
        openActionsDialog: false,
        enteringPreparing: false,
        showVendorReturnWarning: false,
        recentlyChanged: false,
        openConfirmCreateNewQuote: false,
        anchorEl: null,
        callInvoiceCustomAction: false,
        items: this.props.file && this.props.file.clientQuote && this.props.file.clientQuote.length > 0 && this.props.file.clientQuote[0].clientPO && this.props.file.clientQuote[0].clientPO.items && this.props.file.clientQuote[0].clientPO.items.length > 0 ? this.props.file.clientQuote[0].clientPO.items.map((item) => {
            let clientQuoteItem = this.props.file.clientQuote[0].items.find(i => i.rfqItem.id === item.rfqItemId),
                invoicedItem = this.props.invoice ? this.props.invoice.items.find(i => i.clientPOItemId === item.clientPOItemId) : null;

            return {
                ...item,
                rfqItem: clientQuoteItem.rfqItem,
                acceptedVendorQuoteItem: clientQuoteItem.acceptedVendorQuoteItem,
                invoiceQuantity: invoicedItem ? invoicedItem.invoiceQuantity : null
            }
        }) : [],
        otherCosts: this.props.file && this.props.file.clientQuote && this.props.file.clientQuote.length > 0 ? this.props.file.clientQuote[0].otherCosts.filter(oc => !oc.weighted) : []
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file,
            items: nextProps.file && nextProps.file.clientQuote && nextProps.file.clientQuote.length > 0 && nextProps.file.clientQuote[0].clientPO && nextProps.file.clientQuote[0].clientPO.items && nextProps.file.clientQuote[0].clientPO.items.length > 0 ? nextProps.file.clientQuote[0].clientPO.items.map((item) => {
                let clientQuoteItem = nextProps.file.clientQuote[0].items.find(i => i.rfqItem.id === item.rfqItemId),
                    invoicedItem = nextProps.invoice ? nextProps.invoice.items.find(i => i.clientPOItemId === item.clientPOItemId) : null;

                return {
                    ...item,
                    acceptedVendorQuoteItem: clientQuoteItem.acceptedVendorQuoteItem,
                    rfqItem: clientQuoteItem.rfqItem,
                    invoiceQuantity: invoicedItem ? invoicedItem.invoiceQuantity : null
                }
            }) : [],
            otherCosts: nextProps.file && nextProps.file.clientQuote && nextProps.file.clientQuote.length > 0 ? nextProps.file.clientQuote[0].otherCosts.filter(oc => !oc.weighted) : []
        })
    }

    validateStatus = () => {
        const { file } = this.props;
        this.setState({
            showVendorReturnWarning: false
        })

        let canReturn = false;

        switch (file.status) {
            case (2):
                if (!file.vendorQuotes || (file.vendorQuotes && file.vendorQuotes.length === 0)) {
                    canReturn = true
                } else {
                    this.setState({
                        showVendorReturnWarning: true
                    })
                }
                break;
            case (3):
            case (4):
            case (5):
            case (9):
            case (7):
                canReturn = true
                break;
            default:
                canReturn = false
                break;
        }

        if (canReturn) {
            this.setState({
                showConfirmation: true,
                archivingFile: false,
                unarchivingFile: false,
            })
        }
    }
    cancelConfirmationReturn = () => {
        this.setState({
            showConfirmation: false,
        })
    }

    confirmReturnFile = (args) => {
        const { file } = this.props,
            { archivingFile, unarchivingFile } = this.state,
            { comments } = args;

        this.setState({
            loading: true,
            showConfirmation: false,
        })
        let returnStatus = archivingFile ? 'archive' : unarchivingFile ? 'unarchive' :
            (file.status === 7 ? 5 : file.status === 9 ? 4 : file.status - 1);

        if (file.finished && (file.finishStatus.id === 3 || file.finishStatus.id === 2)) {
            returnStatus = 'unarchive';
        }

        let body = {
            fileId: file.id,
            statusId: returnStatus,
            comments: comments
        }

        API.Files.updateFileStatus({ body }).then(res => {
            if (res.data) {
                this.props.updateFileStatus(res.data.record)
                this.setState({
                    successArchiveMessage: archivingFile ? i18n.t('actionBarMessages.successArchived') : null,
                    showConfirmation: false,
                    archivingFile: false,
                    unarchivingFile: false,
                    loading: false
                })
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`,
                    showConfirmation: false,
                    loading: false
                })
            }
        })

    }

    calculateItemsCount = () => {
        const { file, items } = this.state;

        let invoicedItems = 0;
        file.invoices.forEach((inv) => {
            if (inv.status !== 1) {
                invoicedItems = invoicedItems + inv.items.reduce(((total, row) => parseInt(total) + (row.invoiceQuantity ? parseInt(row.invoiceQuantity || 0) : 0)), 0);
            }
        })

        let vPOItems = [];

        file.vendorPOs.forEach(vq => {
            vPOItems = vPOItems.concat(vq.items.filter(vpi => !vpi.deleted))
        });

        let receivedItems = vPOItems.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0),
            requestedItems = vPOItems.reduce(((total, row) => parseInt(total) + (row.quantity ? parseInt(row.quantity) : 0)), 0),
            deliveredItems = items.reduce(((total, row) => parseInt(total) + (row.deliveredQuantity ? parseInt(row.deliveredQuantity) : 0)), 0);

        return {
            receivedItems,
            requestedItems,
            invoicedItems,
            deliveredItems
        }
    }

    calculateOtherCostAmount = () => {
        const { file } = this.state;

        let invoiced = 0,
            requested = 0;

        file.invoices.forEach((inv) => {
            if (inv.status !== 1) {
                invoiced = invoiced + inv.otherCosts.reduce(((total, row) => parseInt(total) + (row.invoiceAmount ? parseInt(row.invoiceAmount || 0) : 0)), 0);
            }
        })

        requested = file.clientQuote[0].otherCosts.filter(oc => !oc.weighted).reduce(((total, row) => parseInt(total) + (row.amount ? parseInt(row.amount || 0) : 0)), 0);

        return {
            requested,
            invoiced
        }
    }

    isStatusCompleted = () => {
        const { file, items } = this.state;

        switch (file.status) {
            case (1):
                if (!file.items || (file.items && file.items.length === 0)) {
                    return false
                }
                return true
            case (2):
                if (file.vendorQuotes) {
                    if (file.vendorQuotes.length === 0 || (file.vendorQuotes.length > 0 && file.vendorQuotes.filter(v => v.status.id === 3).length === 0)) {
                        return false
                    }
                } else {
                    return false
                }
                return true
            case (3):
                if (!file.clientQuote || (file.clientQuote && file.clientQuote.length === 0)) {
                    return false;
                }
                let quoteAddedItems = items.filter(item => (item.vendorQuoteItemId || (item.acceptedVendorQuoteItem && item.acceptedVendorQuoteItem.id)));
                if (!quoteAddedItems || (quoteAddedItems && quoteAddedItems.length === 0)) {
                    return false;
                }
                return true;
            case (4):
                return true
            case (5):
                // let posNoReady = this.calculateItemsCount().receivedItems !== this.calculateItemsCount().requestedItems

                // if (posNoReady) {
                //     return false
                // }
                return true
            case (7):
                return true
            default:
                return false
        }
    }

    calculateFactured = (row) => {
        const { file } = this.state;

        let selectedItem = null,
            invoicedItems = 0;

        let aux = file.invoices.map((inv) => {
            if (inv.status !== 1) {
                selectedItem = inv.items.find(item => item.rfqItem.id === row.rfqItemId)
                if (selectedItem) {
                    invoicedItems = invoicedItems + parseInt(selectedItem.invoiceQuantity || 0)
                }
            }
            return inv
        })
        if (aux) {
            //Solo para quitar el warning mientras Mary revisa 
        }

        return invoicedItems
    }

    nextStatus = (clientPOEta) => {
        const { file, items } = this.state,
            newStatus = file.status === 5 ? 7 : file.status + 1;
        // El status 6 es rejected. Por eso se salta del 5 (po) a el 7 (delivery to client)

        this.setState({
            cantContinueMessage: null,
            loading: true,
            openConfirmation: false,
            enteringPreparing: newStatus === 3 && file.status === 2
        })
        let body = {
            fileId: file.id,
            statusId: !Permissions.checkSensitiveInformationPermission() && file.status === 4 ? 9 : newStatus
        }

        if (file.status === 5) {
            let clientPObody = {
                id: file.clientQuote[0].clientPO.id,
                estimatedDeliveryDate: typeof clientPOEta === 'number' ? new Date(clientPOEta) : clientPOEta,
                poNumber: file.clientQuote[0].clientPO.poNumber,
                comments: file.clientQuote[0].clientPO.comment,
                clientQuoteId: file.clientQuote[0].id,
                date: file.clientQuote[0].clientPO.date
            }

            API.Quotes.updateClientPO({ body: clientPObody }).then(resp => {
                if (resp.record) {
                    let factured = 0;
                    body.clientPOItems = items.map(item => {
                        factured = this.calculateFactured(item);
                        return {
                            clientPOItemId: item.id,
                            clientPOItemDeliveredQuantity: factured
                        }
                    })

                    API.Files.updateFileStatus({ body }).then(res => {
                        if (res.data) {
                            this.props.updateFileStatus(res.data.record)
                            this.setState({
                                loading: false,
                                openConfirmation: false
                            })
                        } else {
                            this.setState({
                                loading: false,
                                openConfirmation: false,
                                apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                            })
                        }
                    })
                } else {
                    this.setState({
                        loading: false,
                        openConfirmation: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${resp.message ? resp.message : ''}`
                    })
                }
            })
        } else {
            API.Files.updateFileStatus({ body }).then(res => {
                if (res.data) {
                    if (file.status === 3) {
                        this.setState({
                            recentlyChanged: true
                        })
                    }
                    this.props.updateFileStatus(res.data.record)
                    this.setState({
                        loading: false,
                        openConfirmation: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        openConfirmation: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        }

    }

    confirmNextStatus = () => {
        const { file } = this.state;

        if (file.status === 4 || file.status === 9) {
            this.setState({
                showConfirmationAccept: true,
            })

        } else {

            if (this.isStatusCompleted()) {
                this.setState({
                    openConfirmation: true,
                })
            }
        }
    }

    cancelConfirmation = () => {
        this.setState({
            openConfirmation: false,
        })
    }

    calculateNoItemsRequested = () => {
        const { file } = this.state;

        let rfqItemsIds = file.items.map((item, i) => { return item.id }),
            includedInQuote = [],
            quotedItems = 0,
            noQuotedItems = 0;

        file.vendorQuotes.forEach(vq => {
            includedInQuote = includedInQuote.concat(vq.items.filter(item => rfqItemsIds.includes(item.rfqItem.id)))
        })

        let vRFQItemsIds = includedInQuote.map(item => { return item.rfqItem.id })

        rfqItemsIds.forEach(item => {
            if (vRFQItemsIds.includes(item)) {
                quotedItems = quotedItems + 1
            } else {
                noQuotedItems = noQuotedItems + 1
            }
        })

        return noQuotedItems
    }


    acceptQuote = (body) => {
        const { file } = this.state

        this.setState({
            loading: true,
            recentlyChanged: true,
            showConfirmationAccept: false
        })
        body.clientQuoteId = file.clientQuote[0].id;

        API.Quotes.acceptClientQuote({ body }).then(res => {
            if (res.data) {
                this.props.updateFileStatus(res.data.record)

                this.setState({
                    loading: false,
                })
            } else {
                this.setState({
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })

    }

    rejectQuote = (body) => {
        const { file } = this.state

        this.setState({
            loading: true,
        })
        body.clientQuoteId = file.clientQuote[0].id;

        API.Quotes.rejectClientQuote({ body }).then(res => {
            if (res.data) {
                this.props.updateFileStatus(res.data.record)

                this.setState({
                    loading: false,
                    showConfirmationReject: false
                })
            } else {
                this.setState({
                    loading: false,
                    showConfirmationReject: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })

    }


    showActionsDialog = () => {
        this.setState({
            openActionsDialog: true
        })
    }
    hideActionsDialog = () => {
        this.setState({
            recentlyChanged: false,
            openActionsDialog: false,
            enteringPreparing: false
        })
    }

    closeActionsDialogAndCallCustomAction = (e, invoice) => {
        this.setState({
            openActionsDialog: false,
            callCustomAction: true,
            callInvoiceCustomAction: invoice ? true : false,
            enteringPreparing: false
        })
    }
    onExited = () => {
        const { file, callCustomAction, callInvoiceCustomAction } = this.state;
        if (callCustomAction) {
            switch (file.status) {
                case 1:
                    this.props.generalActions && this.props.generalActions.showAddItem(true);
                    break;
                case 2:
                    if (file.vendorQuotes.length > 0) {
                        this.props.generalActions && this.props.generalActions.showVendorsTab(true);
                    } else {
                        this.props.generalActions && this.props.generalActions.showAddVendorQuote(true);
                    }
                    break;
                case 3:
                    this.props.generalActions && this.props.generalActions.showSelectItemVendor(true);
                    break;
                case 5:
                    if (callInvoiceCustomAction) {
                        this.props.generalActions && this.props.generalActions.showInvoiceForm(true);
                    } else {
                        this.props.generalActions && this.props.generalActions.showVendorsPo(true);
                    }

                    break;
                default:
                    break;
            }
            this.setState({
                callCustomAction: false,
                callInvoiceCustomAction: false
            })
        }
    }

    confirmDeleteFile = (args) => {
        const { file } = this.props,
            { comments } = args;

        this.setState({
            loading: true,
            showDeleteConfirmation: false,
        })

        let body = {
            fileId: file.id,
            comments: comments
        }

        API.Files.deleteFile({ body }).then(res => {
            if (res.status === 'success') {
                let successDeleteMessage = `File ${file.id} ${i18n.t('actionBarMessages.successDeleted')}`

                this.setState({
                    showConfirmation: false,
                    loading: false
                })
                this.props.deleteFile(file.id, successDeleteMessage)
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`,
                    showConfirmation: false,
                    loading: false
                })
            }
        })
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    createNewQuoteVersion = () => {
        const { file } = this.state;

        this.setState({
            loading: true,
        })

        API.Quotes.createClientQuote({ clientQuoteId: file.clientQuote[0].id, version: file.clientQuote[0].version + 1, fileId: file.id }).then(res => {
            if (res.data) {

                this.props.updateFileStatus(res.data.record)
                this.setState({
                    loading: false,
                    openConfirmCreateNewQuote: false,
                })

            } else {
                this.setState({
                    loading: false,
                    openConfirmCreateNewQuote: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    render() {
        const {
            classes,
        } = this.props,
            { loading,
                otherCosts,
                file, recentlyChanged,
                openConfirmation,
                showConfirmationReject,
                showConfirmationAccept,
                apiErrorMessage,
                successArchiveMessage,
                archivingFile, items,
                unarchivingFile,
                showConfirmation,
                openActionsDialog,
                enteringPreparing,
                showVendorReturnWarning,
                showDeleteConfirmation,
                anchorEl,
                openConfirmCreateNewQuote
            } = this.state;



        let nextStatus = '',
            prevStatusButtonLabel = '',
            prevStatus = '',
            prevStatusConfirmButtonLabel = '',
            createNewQuoteMessage = `A new editable version of the Client Quote will be created. Previous versions won't be editable.`,
            warningMessage = null;

        if (!file) return null;

        let noQuotedItems = this.calculateNoItemsRequested();

        switch (file.status) {
            case (1):
                nextStatus = i18n.t("quotes.actionsBar.requestVRFQLabel");
                break;
            case (2):
                prevStatusButtonLabel = i18n.t("quotes.actionsBar.returnToRFQ");
                prevStatus = i18n.t("quotes.actionsBar.statusRFQ");

                // Calculamos cuantos items han sido incluidos en los vendorRFQ
                if (noQuotedItems > 0) {
                    warningMessage = `There ${noQuotedItems === 1 ? 'is' : 'are'} ${noQuotedItems} ${noQuotedItems === 1 ? 'item' : 'items'} that have not been quoted by a vendor.`
                    nextStatus = `${i18n.t("quotes.actionsBar.prepareClientQuoteLabel")}`;
                } else {
                    nextStatus = i18n.t("quotes.actionsBar.prepareClientQuoteLabel");
                }

                break;
            case (3):
                nextStatus = i18n.t("quotes.actionsBar.sendClientQuoteLabel");
                prevStatusButtonLabel = i18n.t("quotes.actionsBar.returnToVRFQ");
                prevStatus = i18n.t("quotes.actionsBar.statusVRFQ");

                break;
            case (4):
                if (file.finished === 1 && (file.finishStatus.id === 3 || file.finishStatus.id === 2)) {
                    prevStatusButtonLabel = i18n.t("quotes.actionsBar.backToQuote");
                    prevStatus = i18n.t("quotes.actionsBar.statusQuote");
                } else {
                    nextStatus = i18n.t("quotes.actionsBar.acceptQuoteLabel");
                    prevStatusButtonLabel = i18n.t("quotes.actionsBar.retrurnToPreparing");
                    prevStatus = i18n.t("quotes.actionsBar.statusPreparing");
                }

                break;
            case (5):
                nextStatus = i18n.t("quotes.actionsBar.poDeliveredToClientLabel");
                prevStatusButtonLabel = i18n.t("quotes.actionsBar.returnToClientQuote");
                prevStatus = i18n.t("quotes.actionsBar.statusClientQuote");
                break;
            case (9):
                nextStatus = i18n.t("quotes.actionsBar.acceptedQuote");
                prevStatusButtonLabel = i18n.t("quotes.actionsBar.returnToClientQuote");
                prevStatus = i18n.t("quotes.actionsBar.statusClientQuote");
                break;
            case (7):
                prevStatusButtonLabel = i18n.t("quotes.actionsBar.returnToPO");
                prevStatus = i18n.t("quotes.actionsBar.statusPO");
                break;
            default:
                break;
        }

        prevStatusConfirmButtonLabel = prevStatusButtonLabel;

        if (archivingFile) {
            prevStatusConfirmButtonLabel = i18n.t('file.confirmation.archiveFile')
        }

        if (unarchivingFile) {
            prevStatusConfirmButtonLabel = i18n.t('file.confirmation.unarchiveFile')
        }
        let counts = {},
            otherCostsAmounts = {},
            vendorsPosIsSent = true;
        if (file.status >= 5) {
            counts = this.calculateItemsCount()
            otherCostsAmounts = this.calculateOtherCostAmount()
            vendorsPosIsSent = file.vendorPOs.filter(vq => vq.status.id === 1).length !== file.vendorPOs.length
        }

        let vpItems = [],
            receivedItems = [];

        if (file && file.vendorPOs && file.vendorPOs.length > 0) {
            file.vendorPOs.filter(vq => !vq.isVoid).forEach(vp => {
                vpItems = vpItems.concat(vp.items.filter(vpi => !vpi.deleted))
            });

            receivedItems = vpItems.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0)
        }


        return (
            <div color="default">
                <Toolbar className={classes.action__bar__toolBar}>
                    <Grid container spacing={0}
                        justify="space-between"
                        alignItems='center'
                        style={{ margin: 'auto' }}>
                        <Grid item xs={12} sm={6} className={classes.action__bar__gridItem} >

                            {file.finished === 1 && file.finishStatus.id !== 4 ?
                                <div className={classes.action__bar__warning_container} style={{ position: 'relative' }}>
                                    <IconButton color="secondary" className={classes.button} onClick={file.clientQuote && file.clientQuote[0] ? this.handleClick : () => { }}>
                                        <Assignment />
                                    </IconButton>
                                    <Popover
                                        id={'details'}
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Typography style={{ padding: '24px' }}>
                                            {file.clientQuote && file.clientQuote[0] && file.clientQuote[0].rejectComment ? file.clientQuote[0].rejectComment : ''}
                                        </Typography>
                                    </Popover>

                                    <Typography color="secondary">
                                        {`${i18n.t('actionBarMessages.finishedMessage')} ${file.finishStatus.name}`}
                                    </Typography>
                                </div>
                                :
                                <div />
                            }
                            {/* </div> */}
                        </Grid>


                        <Grid item xs={12} sm={6} className={classes.action__bar__gridItem}>
                            <div className={classes.action__bar__buttons__container}>
                                <div className={classNames(classes.action__bar__actionButton__container, classes.print__component__container)}>
                                    <PrintPopup />
                                </div>

                                {Permissions.checkSensitiveInformationPermission() ?
                                    <div className={classes.action__bar__actionButton__container}>
                                        <Button
                                            size="small"
                                            className={
                                                (file.status === 5 && receivedItems === 0) || (file.finished === 1) ||
                                                    (file.status === 1 && (!file.items || (file.items && file.items.length === 0))) ||
                                                    (file.status === 2 && (file.vendorQuotes.length === 0 || (file.vendorQuotes.length > 0 && file.vendorQuotes.filter(v => v.status.id === 3).length === 0))) ||
                                                    (file.status === 3 && (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0))
                                                    ? classes.action__bar__actionButton__current__disabled : classes.action__bar__actionButton__current}
                                            variant='contained'
                                            disabled={loading}
                                            onClick={this.showActionsDialog}
                                        >

                                            {file.finished === 1 ?
                                                i18n.t('quotes.actionsBar.finished')
                                                : <>
                                                    {file.status === 1 &&
                                                        i18n.t('quotes.actionsBar.requestVRFQ')
                                                    }

                                                    {file.status === 2 &&
                                                        i18n.t("quotes.actionsBar.prepareClientQuote")
                                                    }

                                                    {file.status === 3 &&
                                                        i18n.t("quotes.actionsBar.sendClientQuote")
                                                    }

                                                    {file.status === 4 &&
                                                        i18n.t("quotes.actionsBar.acceptQuote")
                                                        // i18n.t('quotes.actionsBar.changeFileStatus')
                                                    }

                                                    {file.status === 5 &&
                                                        <>
                                                            {file.finished !== 1 && (file.invoices.length === 0 || counts.receivedItems === 0) ?
                                                                i18n.t('quotes.actionsBar.invoice')
                                                                :
                                                                i18n.t("quotes.actionsBar.poDeliveredToClient")
                                                            }</>
                                                    }
                                                </>
                                            }

                                        </Button>
                                    </div>
                                    :
                                    <>
                                        {file.status === 4 && !file.finished && (
                                            <div className={classes.action__bar__actionButton__container}>
                                                {!recentlyChanged &&
                                                    <div>
                                                        <Button
                                                            disabled={loading}
                                                            className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__reject)}
                                                            variant="contained"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showConfirmationReject: true,
                                                                });
                                                            }}
                                                        >
                                                            {i18n.t("quotes.actionsBar.rejectQuote")}
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {/* { file.status !== 9 &&
                                            <div className={classes.action__bar__actionButton__container}>
                                                <Button
                                                    size="small"
                                                    className={classes.action__bar__actionButton__current}
                                                    variant='contained'
                                                    disabled={loading}
                                                    onClick={this.nextStatus}
                                                >
                                                    {i18n.t('quotes.actionsBar.acceptQuote')}
                                                </Button>
                                            </div>
                                        }

                                        { file.status === 9 &&
                                            <div className={classes.action__bar__actionButton__container}>
                                                <Button
                                                    disabled={loading || (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0)}
                                                    className={classNames(classes.subaction__bar__actionButton,
                                                        classes.subaction__bar__actionButton__prev__salesRep)}
                                                    onClick={this.validateStatus}>
                                                    <NavigateBefore />
                                                    {i18n.t("quotes.actionsBar.returnToQuoted")}
                                                </Button>
                                            </div>
                                        } */}
                                    </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>

                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <Dialog
                    maxWidth='sm'
                    open={openActionsDialog}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    onExited={this.onExited}
                    TransitionComponent={SlideUp}>

                    <DialogTitle disableTypography={true} >
                        {i18n.t('quotes.details.file')} {file.id} - {file.client.name}
                        <IconButton color="inherit"
                            disabled={loading}
                            onClick={this.hideActionsDialog}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        <div className={classes.dialog__content__container}>

                            <StatusIndicator file={file} />

                            {loading && (
                                <div className={classes.dialog__loader__container}>
                                    <CircularProgress color='secondary' />
                                </div>
                            )}


                            {file.finished === 0 &&

                                <>


                                    {/* RFQ */}
                                    {file.status === 1 && (
                                        <div className={classes.action__buttons__container}>
                                            {(!file.items || (file.items && file.items.length === 0)) ?
                                                <>
                                                    <Typography className={classes.currentStatus__message__label}>
                                                        {i18n.t('actionBarMessages.incompleteRFQ')}
                                                        <Link
                                                            className={classes.customLink}
                                                            onClick={this.closeActionsDialogAndCallCustomAction}>
                                                            {i18n.t('actionBarMessages.addAtLeastOne')}
                                                        </Link>
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                        {i18n.t('actionBarMessages.rfqToVendor')}
                                                    </Typography>

                                                </>
                                            }
                                            <Button
                                                size="small"
                                                className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__next)}
                                                variant="contained"
                                                disabled={loading || (!file.items || (file.items && file.items.length === 0))}
                                                onClick={this.nextStatus}>
                                                {i18n.t("quotes.actionsBar.requestVRFQ")}
                                                <NavigateNext />
                                            </Button>
                                        </div>
                                    )}





                                    {/* VENDOR RFQ */}
                                    {file.status === 2 && (
                                        <div className={classes.action__buttons__container}>
                                            {file.vendorQuotes.length !== 0 ?
                                                <>
                                                    {noQuotedItems > 0 && (
                                                        <Typography className={classes.action__bar__missingcondition__label}>
                                                            {noQuotedItems > 1 ? i18n.t("actionBarMessages.thereAre") : i18n.t("actionBarMessages.thereIs")} {`${noQuotedItems}`} {noQuotedItems > 1 ? i18n.t("actionBarMessages.itemsWithoutVendorRFQ") : i18n.t("actionBarMessages.itemWithoutVendorRFQ")}
                                                        </Typography>
                                                    )}
                                                    {((file.vendorQuotes.length > 0 && file.vendorQuotes.filter(v => v.status.id === 3).length === 0)) ?
                                                        <>
                                                            <Typography className={classes.action__bar__missingcondition__label}>
                                                                {i18n.t("actionBarMessages.atLeastVendorQuoteReady")}
                                                            </Typography>
                                                            <Typography className={classes.currentStatus__message__label}>
                                                                {file.vendorQuotes.filter(v => v.status.id !== 3).length > 1 ? i18n.t("actionBarMessages.thereAre") : i18n.t("actionBarMessages.thereIs")}
                                                                <Link className={classes.customLink}
                                                                    //onClick={this.hideActionsDialog}
                                                                    onClick={this.closeActionsDialogAndCallCustomAction}
                                                                    style={{ marginLeft: '5px' }}>
                                                                    {file.vendorQuotes.filter(v => v.status.id !== 3).length} {file.vendorQuotes.filter(v => v.status.id !== 3).length > 1 ? i18n.t("actionBarMessages.vendorsWaitingForResponse") : i18n.t("actionBarMessages.vendorWaitingForResponse")}
                                                                </Link>
                                                            </Typography>
                                                        </>
                                                        :
                                                        <>
                                                            <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                                {i18n.t('actionBarMessages.vendorsToPreparing')}
                                                            </Typography>
                                                        </>
                                                    }


                                                    <Button
                                                        size="small"
                                                        className={classNames(classes.subaction__bar__actionButton,
                                                            classes.subaction__bar__actionButton__next)}
                                                        variant="contained"
                                                        disabled={loading || (file.vendorQuotes.length === 0 || (file.vendorQuotes.length > 0 && file.vendorQuotes.filter(v => v.status.id === 3).length === 0))}
                                                        onClick={this.nextStatus}>
                                                        {i18n.t("quotes.actionsBar.prepareClientQuote")}
                                                        <NavigateNext />
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                        {i18n.t('actionBarMessages.vendorsNoQuotes')}
                                                        <Link
                                                            className={classes.customLink}
                                                            onClick={this.closeActionsDialogAndCallCustomAction}>
                                                            {i18n.t('actionBarMessages.vendorsRFQ')}
                                                        </Link>
                                                    </Typography>
                                                </>
                                            }
                                            {showVendorReturnWarning && file.vendorQuotes && file.vendorQuotes.length > 0 &&
                                                <Typography className={classes.action__bar__missingcondition__label} onClick={() => {
                                                    this.setState({
                                                        showVendorReturnWarning: false
                                                    })
                                                }}
                                                    style={{
                                                        marginTop: '15px'
                                                    }}>{i18n.t('actionBarMessages.returnToRFQMessage')}</Typography>
                                            }
                                        </div>
                                    )}

                                    {/* PREPARING */}

                                    {file.status === 3 && (
                                        <div className={classes.action__buttons__container}>
                                            {enteringPreparing ?
                                                <>
                                                    <Typography className={classes.currentStatus__message__label}>
                                                        {i18n.t('actionBarMessages.readyToPrepareQuote')} <Link className={classes.customLink}
                                                            onClick={this.closeActionsDialogAndCallCustomAction}
                                                        >
                                                            {i18n.t("actionBarMessages.clientQuote")}
                                                        </Link>
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    {(file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0) ?
                                                        <>
                                                            <Typography className={classes.action__bar__missingcondition__label}>
                                                                {i18n.t('actionBarMessages.missingAcceptedItems')}
                                                            </Typography>
                                                            <Typography className={classes.currentStatus__message__label}>
                                                                <Link className={classes.customLink}
                                                                    onClick={this.closeActionsDialogAndCallCustomAction}
                                                                >
                                                                    {file.clientQuote[0].items.length > 1 ? i18n.t("actionBarMessages.thereAre") : i18n.t("actionBarMessages.thereIs")} {file.clientQuote[0].items.length} {file.clientQuote[0].items.length > 1 ? i18n.t("actionBarMessages.itemsWithoutVendor") : i18n.t("actionBarMessages.itemWithoutVendor")}
                                                                </Link>
                                                            </Typography>
                                                        </>
                                                        :
                                                        <>
                                                            <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                                {file.clientQuote && file.clientQuote.length > 0 ? 
                                                                    `Quote Rev-${file.clientQuote.length} is ready to be sent to client` : 
                                                                    i18n.t('actionBarMessages.preparingToQuote')
                                                                }
                                                            </Typography>
                                                        </>
                                                    }
                                                </>
                                            }


                                            <Button
                                                size="small"
                                                className={classNames(classes.subaction__bar__actionButton,
                                                    classes.subaction__bar__actionButton__next)}
                                                variant="contained"
                                                disabled={loading || (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0)}
                                                onClick={this.nextStatus}>
                                                {i18n.t("quotes.actionsBar.sendClientQuote")}
                                                <NavigateNext />
                                            </Button>
                                        </div>
                                    )}

                                    {/* QUOTE */}
                                    {file.status === 4 && (
                                        <div className={classes.action__buttons__container}>
                                            <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                {file.clientQuote && file.clientQuote.length > 1 ? `Quote Rev-${file.clientQuote.length} has been sent to client` : i18n.t('actionBarMessages.quoteToClientResponse')}
                                            </Typography>
                                            {!recentlyChanged && <>
                                                <div>
                                                    <Button
                                                        size="small"
                                                        className={classNames(classes.subaction__bar__actionButton,
                                                            classes.subaction__bar__actionButton__next)}
                                                        variant="contained"
                                                        disabled={loading}
                                                        onClick={this.confirmNextStatus}>
                                                        {i18n.t("quotes.actionsBar.acceptQuote")}
                                                    </Button>
                                                    <Button
                                                        disabled={loading}
                                                        className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__reject)}
                                                        variant="contained"
                                                        onClick={() => {
                                                            this.setState({
                                                                showConfirmationReject: true,
                                                            });
                                                        }}
                                                    >
                                                        {i18n.t("quotes.actionsBar.rejectQuote")}
                                                    </Button>
                                                </div>
                                                <div style={{marginBottom: '10px'}}>
                                                    <Button
                                                        size="small"
                                                        className={classNames(classes.subaction__bar__actionButton,
                                                            classes.subaction__bar__actionButton__next)}
                                                        style={{backgroundColor: '#009688'}}
                                                        variant="contained"
                                                        disabled={loading}
                                                        onClick={()=>{
                                                            this.setState({
                                                                openConfirmCreateNewQuote: true
                                                            })
                                                        }}>
                                                        {i18n.t("quotes.actionsBar.createNewQuote")}
                                                    </Button>
                                                </div>
                                            </>}
                                        </div>
                                    )}


                                    {/* ACCEPTED QUOTE WITHOUT PO */}
                                    {file.status === 9 && (
                                        <div className={classes.action__buttons__container}>
                                            <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                {i18n.t('actionBarMessages.quoteAccepted')}
                                            </Typography>

                                            <div>
                                                <Button
                                                    size="small"
                                                    className={classNames(classes.subaction__bar__actionButton,
                                                        classes.subaction__bar__actionButton__next)}
                                                    variant="contained"
                                                    disabled={loading}
                                                    onClick={this.confirmNextStatus}>
                                                    {i18n.t("quotes.actionsBar.addPO")}
                                                </Button>
                                                <Button
                                                    disabled={loading}
                                                    className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__reject)}
                                                    variant="contained"
                                                    onClick={() => {
                                                        this.setState({
                                                            showConfirmationReject: true,
                                                        });
                                                    }}
                                                >
                                                    {i18n.t("quotes.actionsBar.rejectQuote")}
                                                </Button>
                                            </div>
                                        </div>
                                    )}


                                    {/* PO */}

                                    {file.status === 5 && (
                                        <div className={classes.action__buttons__container}>
                                            {!recentlyChanged ?
                                                <>
                                                    <InvoiceItems
                                                        readOnly={true}
                                                        invoices={file.invoices}
                                                        items={items}
                                                        file={file}
                                                        vendorPOs={file.vendorPOs ? file.vendorPOs : []}
                                                        clientQuoteTaxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : 0}
                                                    />

                                                    {otherCosts && otherCosts.length > 0 &&
                                                        <div style={{ marginTop: '12px', marginRight: 'auto' }}>
                                                            <InvoiceOtherCosts
                                                                invoices={file.invoices}
                                                                otherCosts={otherCosts}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    }

                                                    {/* Hay una factura pendiente por enviar */}
                                                    {file.invoices.filter(inv => inv.status === 1).length > 0 ?
                                                        <Typography style={{ marginTop: '24px' }} className={classes.messages__container}>
                                                            <Warning className={classes.warning__message__icon} />
                                                            {`Invoice #${file.invoices.find(inv => inv.status === 1).id}`} {i18n.t('actionBarMessages.pendingInvoice')}
                                                        </Typography>
                                                        :
                                                        <div style={{ marginTop: '24px' }}>
                                                            {/* Hay items recibidos que no han sido facturados */}
                                                            {counts.receivedItems !== 0 && counts.receivedItems - counts.invoicedItems !== 0 &&
                                                                <Typography className={classes.action__bar__readyToDeliver__label}>
                                                                    {`${counts.receivedItems - counts.invoicedItems} ${i18n.t('actionBarMessages.itemsNoInvoiced')}`}
                                                                </Typography>
                                                            }

                                                            {/* No se han enviado los POs o recibidos items de los vendors*/}
                                                            {counts.receivedItems === 0 &&
                                                                <Typography className={classes.messages__container}>
                                                                    <Warning className={classes.warning__message__icon} />
                                                                    {vendorsPosIsSent ?
                                                                        i18n.t('actionBarMessages.vendorPosReadys')
                                                                        :
                                                                        i18n.t("actionBarMessages.vendorPosNoSent")
                                                                    }
                                                                    <Link className={classes.customLink}
                                                                        onClick={this.closeActionsDialogAndCallCustomAction}>
                                                                        {vendorsPosIsSent ?
                                                                            i18n.t("actionBarMessages.pendingReceiveItemsLink")
                                                                            :
                                                                            i18n.t("actionBarMessages.pendingSentItemsLink")
                                                                        }
                                                                    </Link>
                                                                </Typography>
                                                            }

                                                            {/* Faltan cosas que recibir de los vendors*/}
                                                            {counts.receivedItems === counts.invoicedItems && counts.receivedItems !== 0 && counts.receivedItems !== counts.requestedItems &&
                                                                <Typography className={classes.messages__container}>
                                                                    <Warning className={classes.warning__message__icon} />
                                                                    {i18n.t('actionBarMessages.waitingForVendors1')}
                                                                    <Link className={classes.customLink}
                                                                        onClick={this.closeActionsDialogAndCallCustomAction}>
                                                                        {i18n.t('actionBarMessages.waitingForVendors2')}
                                                                    </Link>
                                                                    {i18n.t('actionBarMessages.waitingForVendors3')}
                                                                </Typography>
                                                            }

                                                            {/* Se facturo menos other cost de lo cotizado */}
                                                            {counts.receivedItems === counts.invoicedItems && otherCostsAmounts.requested !== otherCostsAmounts.invoiced &&
                                                                <Typography className={classes.messages__container}>
                                                                    <Warning className={classes.warning__message__icon} />
                                                                    {i18n.t('actionBarMessages.missingOtherCostToInvoice')}
                                                                </Typography>
                                                            }

                                                        </div>
                                                    }

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '16px' }}>
                                                        <div style={{ width: '160px' }} />

                                                        {counts.requestedItems !== counts.invoicedItems || otherCostsAmounts.requested !== otherCostsAmounts.invoiced ?
                                                            <Button
                                                                size="small"
                                                                className={classNames(classes.subaction__bar__actionButton,
                                                                    classes.subaction__bar__actionButton__next)}
                                                                variant="contained"
                                                                disabled={loading || file.invoices.filter(inv => inv.status === 1).length > 0 || counts.receivedItems === 0 || (counts.receivedItems === counts.invoicedItems && counts.receivedItems !== 0 && otherCostsAmounts.invoiced === otherCostsAmounts.requested)}
                                                                onClick={(e) => this.closeActionsDialogAndCallCustomAction(e, true)}
                                                            >
                                                                {i18n.t("quotes.actionsBar.invoice")}
                                                            </Button>
                                                            :
                                                            <div style={{ width: '160px' }} />
                                                        }

                                                        <Button
                                                            size="small"
                                                            className={classNames(classes.subaction__bar__actionButton,
                                                                classes.subaction__bar__actionButton__next)}
                                                            variant="contained"
                                                            disabled={loading || file.invoices.length === 0 || counts.receivedItems === 0}
                                                            onClick={this.confirmNextStatus}>
                                                            {otherCostsAmounts.requested !== otherCostsAmounts.invoiced ? i18n.t("quotes.actionsBar.poDeliveredToClientAnyWay") : i18n.t("quotes.actionsBar.poDeliveredToClient")}
                                                            <NavigateNext />
                                                        </Button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                                        {i18n.t('actionBarMessages.readyToInvoice')}
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                    )}
                                </>
                            }

                            {(file.finished === 1 && file.finishStatus.id === 4) && (
                                <div className={classes.action__buttons__container}>
                                    <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                        {i18n.t('actionBarMessages.poInvoiced')}
                                    </Typography>
                                </div>
                            )}

                            {(file.finished === 1 && file.finishStatus.id === 2) && (
                                <div className={classes.action__buttons__container}>
                                    <Typography color='textSecondary' className={classes.currentStatus__message__label}>
                                        {i18n.t('actionBarMessages.quoteRejected')}
                                    </Typography>
                                </div>
                            )}

                        </div>
                        <div className={classes.footer__bottons__container}>


                            {/* UNARCHIVE BUTTON */}
                            {(file.finished === 1 && file.finishStatus.id === 1) &&
                                <Button
                                    className={classes.subaction__bar__actionButton__archive}
                                    disabled={loading}
                                    onClick={() => {
                                        this.setState({
                                            showConfirmation: true,
                                            unarchivingFile: true,
                                        });
                                    }}>
                                    {i18n.t("quotes.actionsBar.unArchiveFile")}
                                </Button>
                            }

                            {/* UN-Rejected BUTTON */}
                            {(file.finished === 1 && file.finishStatus.id === 2) &&
                                <Button
                                    className={classes.subaction__bar__actionButton__archive}
                                    style={{ width: '132px' }}
                                    disabled={loading}
                                    onClick={() => {
                                        this.setState({
                                            showConfirmation: true
                                        });
                                    }}>
                                    {i18n.t("quotes.actionsBar.reactivateFile")}
                                </Button>
                            }


                            {file.finished === 1 &&
                                <div />
                            }


                            {/* ARCHIVE BUTTON */}
                            {(file.finished === 0 && file.invoices && file.invoices.length === 0) &&
                                <Button
                                    className={classes.subaction__bar__actionButton__archive}
                                    disabled={loading}
                                    onClick={() => {
                                        this.setState({
                                            showConfirmation: true, archivingFile: true,
                                        });
                                    }}>
                                    {i18n.t("quotes.actionsBar.archiveFile")}
                                </Button>
                            }


                            {/* DELETE FILE BUTTON */}
                            {(file.finished === 1 || (file.finished === 0 && file.status === 1)) &&
                                <Button
                                    className={classes.subaction__bar__actionButton__archive}
                                    disabled={loading}
                                    onClick={() => {
                                        this.setState({
                                            showDeleteConfirmation: true,
                                        });
                                    }}>
                                    {i18n.t("quotes.actionsBar.deleteFile")}
                                </Button>
                            }


                            {/* Return to RFQ */}
                            {file.finished === 0 && file.status === 2 && (
                                <Button
                                    disabled={loading}
                                    className={classNames(classes.subaction__bar__actionButton,
                                        classes.subaction__bar__actionButton__prev)}
                                    onClick={this.validateStatus}>
                                    <NavigateBefore />
                                    {i18n.t("quotes.actionsBar.returnToRFQ")}
                                </Button>
                            )}


                            {/* Return to vendors */}
                            {file.finished === 0 && file.status === 3 && (
                                <Button
                                    disabled={loading}
                                    className={classNames(classes.subaction__bar__actionButton,
                                        classes.subaction__bar__actionButton__prev)}
                                    onClick={this.validateStatus}>
                                    <NavigateBefore />
                                    {i18n.t("quotes.actionsBar.returnToVRFQ")}
                                </Button>
                            )}


                            {/* Return to preparing */}
                            {file.finished === 0 && file.status === 4 && (
                                <Button
                                    disabled={loading || (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0)}
                                    className={classNames(classes.subaction__bar__actionButton,
                                        classes.subaction__bar__actionButton__prev)}
                                    onClick={this.validateStatus}>
                                    <NavigateBefore />
                                    {i18n.t("quotes.actionsBar.retrurnToPreparing")}
                                </Button>
                            )}

                            {/* Return to quote */}
                            {file.finished === 0 && (file.status === 5 || file.status === 9) && ((file.invoices && file.invoices.length === 0) || !file.invoices) && (file.vendorPOs && file.vendorPOs.filter(v => v.status.id !== 1).length === 0) && (
                                <Button
                                    disabled={loading || (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0)}
                                    className={classNames(classes.subaction__bar__actionButton,
                                        classes.subaction__bar__actionButton__prev)}
                                    onClick={this.validateStatus}>
                                    <NavigateBefore />
                                    {i18n.t("quotes.actionsBar.returnToClientQuote")}
                                </Button>
                            )}

                            {/* Return to PO button */}
                            {
                                // (file.finished === 1 && file.finishStatus.id >= 2) && (
                                // <Button
                                //     disabled={loading || (file.clientQuote && file.clientQuote[0] && file.clientQuote[0].items.length > 0 && file.clientQuote[0].items.filter(item => item.amount).length === 0)}
                                //     className={classNames(classes.subaction__bar__actionButton,
                                //         classes.subaction__bar__actionButton__prev)}
                                //     onClick={this.validateStatus}>
                                //     <NavigateBefore />
                                //     {i18n.t("quotes.actionsBar.returnToPO")}
                                // </Button>
                                // )
                            }

                        </div>
                    </DialogContent>
                </Dialog>

                <ConfirmationDialog
                    handleClose={this.cancelConfirmation}
                    onConfirm={this.nextStatus}
                    message={nextStatus}
                    warningMessage={warningMessage}
                    loading={loading}
                    savePODeliveryDay={file.status === 5 ? true : false}
                    cancelLabel={i18n.t("quotes.actionsBar.cancelNextStatusLabel")}
                    confirmLabel={i18n.t("quotes.actionsBar.confirmNextStatusLabel")}
                    open={openConfirmation}
                />

                <ConfirmationDialog
                    handleClose={()=>{
                        this.setState({
                            openConfirmCreateNewQuote: false
                        })
                    }}
                    onConfirm={this.createNewQuoteVersion}
                    message={createNewQuoteMessage}
                    loading={loading}
                    cancelLabel={i18n.t("quotes.actionsBar.cancelNextStatusLabel")}
                    confirmLabel={i18n.t("quotes.actionsBar.confirmNextStatusLabel")}
                    open={openConfirmCreateNewQuote}
                />  

                <ConfirmationReturnStatusDialog
                    handleClose={() => this.setState({ showConfirmationReject: false })}
                    onConfirm={this.rejectQuote}
                    loading={loading}
                    showReasonsSelect={true}
                    message={i18n.t('file.confirmation.rejectFileConfirmation')}
                    inputLabel={i18n.t('file.confirmation.explainReason')}
                    cancelLabel={i18n.t('file.confirmation.cancel')}
                    confirmLabel={i18n.t('file.confirmation.rejectFile')}
                    open={showConfirmationReject}
                />

                <ConfirmationAcceptQuote
                    handleClose={() => this.setState({ showConfirmationAccept: false })}
                    onConfirm={this.acceptQuote}
                    loading={loading}
                    file={file}
                    open={showConfirmationAccept}
                />

                <CustomMessages
                    type={'success'}
                    message={successArchiveMessage}
                    clearMessage={() => this.setState({
                        successArchiveMessage: null
                    })}
                />

                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <ConfirmationReturnStatusDialog
                    handleClose={this.cancelConfirmationReturn}
                    onConfirm={this.confirmReturnFile}
                    loading={loading}
                    message={`${archivingFile ? i18n.t('file.confirmation.archiveFileConfirmation') : unarchivingFile ? i18n.t('file.confirmation.unarchiveFileConfirmation') : i18n.t('file.confirmation.confirmReturnFileMessage') + prevStatus + '?'}`}
                    inputLabel={i18n.t('file.confirmation.explainReason')}
                    cancelLabel={i18n.t('file.confirmation.cancel')}
                    confirmLabel={prevStatusConfirmButtonLabel}
                    open={showConfirmation}
                />

                <ConfirmationDialog
                    handleClose={() => {
                        this.setState({
                            showDeleteConfirmation: false
                        })
                    }}
                    onConfirm={this.confirmDeleteFile}
                    loading={loading}
                    warningMessage={i18n.t('file.confirmation.deleteFileConfirmation')}
                    cancelLabel={i18n.t('file.confirmation.cancel')}
                    confirmLabel={i18n.t('file.confirmation.deleteFile')}
                    open={showDeleteConfirmation}
                />

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(ActionsBar)));