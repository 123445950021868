const authParams = {
    cognito: {
        Auth: {
            identityPoolId: process.env.REACT_APP_PRODEK_IDENTITYPOOL_ID,
            region: 'us-east-1',
            identityPoolRegion: 'us-east-1',
            userPoolId: process.env.REACT_APP_PRODEK_USERPOOL_ID,
            userPoolWebClientId: process.env.REACT_APP_PRODEK_USERPOOL_WEBCLIENT_ID,
            mandatorySignIn: false,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        }
    }
}
export default authParams;