import React, { Component } from 'react'
import {
    withStyles,
    Grid,
    Typography
} from '@material-ui/core'
import i18n from 'i18next'
import ReactTable from "react-table"
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { Drafts, Phone } from '@material-ui/icons'
import auth from '../../../authentication/auth';
import { NoteForm } from '../../notes'

const styles = theme => {
    return ({
        customLink: theme.customLink,
        vendorQuote__clientcontact__message__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-5px'
        },
        vendorQuote__subtitle__divider: {
            width: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        vendorRFQ__icon__phone: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-3px'
        },
        other__costs__title: {
            marginTop: theme.spacing(),
            marginRight: theme.spacing()
        },
        other__costs__container: {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing()
        },
        other__costs__table__container: {
            padding: 0,
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            marginRight: theme.spacing()
        },
        otherCost__table: {
            fontSize: theme.typography.fontSize * 0.8,
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
            border: `solid thin ${theme.palette.grey[300]}`
        },
        subTitle: {
            color: theme.palette.blue[100]
        }
    })
}
class VendorQuoteReadOnlyInformation extends Component {

    state = {
        selectedItem: null,
        vendorQuote: this.props.vendorQuote,
        openNewNote: false,
        logedInUser: null,
    }

    componentDidMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }
    selectItem = (index) => {
        const { selectedItem } = this.state;
        this.setState({
            selectedItem: index === selectedItem ? null : index
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            vendorQuote: nextProps.vendorQuote
        })
    }

    closeNewNote = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    handelNoteSaved = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    render() {
        const { classes, isVendorPO } = this.props,
            { vendorQuote,
                openNewNote,
                logedInUser,
                currencySymbol } = this.state;
        if (!vendorQuote) return null;
        return (
            <div>
                <Grid container spacing={1} >
                    <Grid item sm={6} xs={12}>
                        <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendor')}</Typography>

                        <Typography variant='body2' color='textSecondary' noWrap>{vendorQuote.vendor.address.address1}</Typography>
                        <Typography variant='body2' color='textSecondary' noWrap>{vendorQuote.vendor.address.address2}</Typography>
                        {vendorQuote.vendor.phone1 &&
                            <Typography variant='body2' color='textSecondary' noWrap>
                                <Phone className={classes.vendorRFQ__icon__phone} />
                                {vendorQuote.vendor.phone1}
                            </Typography>
                        }
                        {vendorQuote.vendor.phone2 &&
                            <Typography variant='body2' color='textSecondary' noWrap>
                                +<Phone className={classes.vendorRFQ__icon__phone} />
                                {vendorQuote.vendor.phone2}
                            </Typography>
                        }

                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendorContact')}</Typography>
                        
                        <Typography variant='body2' color='textSecondary' noWrap>{vendorQuote.contact.firstName} {vendorQuote.contact.lastName}</Typography>
                        {vendorQuote.contact.email &&
                            <Typography
                                variant='body2'
                                color='textSecondary'
                                className={classes.customLink} onClick={() => this.setState({
                                    openNewNote: true,
                                })} noWrap>
                                <Drafts className={classes.vendorQuote__clientcontact__message__icon} />
                                {vendorQuote.contact.email}
                            </Typography>
                        }
                        {vendorQuote.contact.phone && (
                            <Typography variant='body2' color='textSecondary' noWrap>
                                <Phone className={classes.vendorRFQ__icon__phone} />
                                {vendorQuote.contact.phone}
                            </Typography>
                        )}
                        {vendorQuote.deliveryDays &&
                            <Typography variant='body2' color='textSecondary' noWrap>{vendorQuote.deliveryDays}<strong>{`${i18n.t('quotes.vendorQuoteRequest.deliveryDays')} `}</strong></Typography>
                        }
                    </Grid>
                </Grid>


                {(vendorQuote.comments || vendorQuote.quoteTermsName || vendorQuote.quoteTermsReference || vendorQuote.paymentTermsName) && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{padding: '8px 8px 0'}}>
                                <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendorRFQInformation')}</Typography>
                            </Grid>

                            {vendorQuote.comments && !isVendorPO && (
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant='body2' color='textSecondary' style={{ whiteSpace: 'pre-line' }}>{vendorQuote.comments}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={6}>
                                {vendorQuote.quoteTermsName && (
                                    <Typography variant='body2' color='textSecondary' noWrap> <strong>{`${i18n.t('quotes.clientRFQ.quoteTerms')} `}</strong>{vendorQuote.quoteTermsName}</Typography>
                                )}
                                {vendorQuote.quoteTermsReference && (
                                    <Typography variant='body2' color='textSecondary' noWrap> <strong>{`${i18n.t('quotes.quoteRequest.quoteTermsReferences')} `}</strong> {vendorQuote.quoteTermsReference}</Typography>
                                )}
                                {vendorQuote.paymentTermsName && (
                                    <Typography variant='body2' color='textSecondary' > <strong>{`${i18n.t('quotes.clientRFQ.paymentTerms')} `}</strong>{vendorQuote.paymentTermsName}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}

                {/* OTHER COSTS */}
                {(vendorQuote.otherCosts && vendorQuote.otherCosts.length !== 0) &&
                    <Grid container spacing={2}>
                        <Grid container item xs={4}></Grid>
                        <Grid container item xs={8}>
                            <Grid item xs={12} className={classes.other__costs__title}>
                                <Typography variant='h6' className={classes.subTitle} >{i18n.t('quotes.preparatingQuoteInformation.otherCosts')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.other__costs__container}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {vendorQuote.otherCosts && vendorQuote.otherCosts.length > 0 &&
                                            <ReactTable
                                                data={vendorQuote.otherCosts}
                                                columns={[{
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column1'),
                                                    id: 'otherCostTableName',
                                                    accessor: 'name'
                                                },
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column2'),
                                                    id: 'otherCostTableComments',
                                                    accessor: 'comments'
                                                },
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column3'),
                                                    id: 'otherCostTableAmount',
                                                    className: classes.quoteitems__table__cell__rigth,
                                                    accessor: row =>
                                                        <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                            <NumberFormat value={row.amount}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true} />
                                                        </div>,
                                                    width: 80,
                                                    Footer: () => {
                                                        let totalOtherCost = vendorQuote.otherCosts.filter(oc => !oc.deleted).reduce(((total, row) => {
                                                            return ( parseFloat(total) + row.amount ) 
                                                        }), 0)
                        
                                                        if (totalOtherCost === 0) return null;
                        
                                                        return (
                                                            <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                                <NumberFormat value={totalOtherCost}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    style={{ fontSize: '16px' }}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                }]}
                                                pageSize={vendorQuote.otherCosts.length}
                                                filtrable={false}
                                                sortable={false}
                                                resizable={false}
                                                className={classNames(classes.otherCost__table, ' -striped -highlight')}
                                                showPaginationTop={false}
                                                showPaginationBottom={false}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }


                <NoteForm
                    file={this.props.file}
                    open={openNewNote}
                    note={null}
                    user={logedInUser}
                    section={2}
                    sendByEmail={true}
                    emailAddress={vendorQuote && vendorQuote.contact.email ? {
                        value: vendorQuote.contact.email,
                        label: `${vendorQuote.contact.firstName || ''} ${vendorQuote.contact.lastName || ''}`
                    } : null}
                    addNewNote={this.handelNoteSaved}
                    handleClose={this.closeNewNote}
                />
            </div>
        )
    }
}

export default withStyles(styles)(VendorQuoteReadOnlyInformation);