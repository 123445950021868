import React, { Component } from 'react'
import Header from '../components/Header';
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as generalActions from '../actions/generalActions'
import * as peopleActions from '../actions/peopleActions'

import API from '../lib/api'

class Layout extends Component {
  componentDidMount = () => {
    let { general, account } = this.props;
    if (!general.globalParameters) {
      API.GlobalParameters.getGlobalParameters().then(res => {
        this.props.generalActions.setGlobalParameters(res.data);
      })
    }
    if(account && account.userTypeId !== 3){
      this.props.peopleActions.setFilterPeople([{userId: account.userId, userName: account.userName}])
    }
  }
  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div style={{marginTop: '64px'}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    general: state.general,
    account: state.account
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    peopleActions: bindActionCreators(peopleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)