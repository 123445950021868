import React, { Component } from 'react'
import {
    Dialog,
    Typography,
    IconButton,
    withMobileDialog,
    Button,
    withStyles,
    DialogTitle
} from '@material-ui/core'
import i18n from 'i18next'
import { Close } from '@material-ui/icons'
import { SlideUp } from '../general/Transitions'

import { CustomMessages } from '../general'
import { ManageVendor } from '.'


const styles = theme => ({
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog_content_container: {
        padding: theme.spacing(2)
    },
    dialog__paper: {
        width: '100%',
        overflow: 'visible'
    },
    manage__button: theme.manage__button
})

const defaultVendor = {
    name: '',
    address: {
        address1: '',
        address2: ''
    },
    phone1: '',
    phone2: '',
    website: '',
    remarks: '',
    id: null
}

class ManageVendorPopup extends Component {


    state = {
        selectedVendor: this.props.selectedVendor || null,
        currentVendor: this.props.selectedVendor || defaultVendor,
        showManageVendorsPopup: false,
        validateVendorQuote: false,
        validateVendor: false,
        createVendor: false,
        editVendor: false,
        savingVendor: false,
        apiErrorMessage: null,
        deleting: false,
        triggerReload: false,
        searchVendor: this.props.selectedVendor ? this.props.selectedVendor.name : '',
        fetching: false,
        fetched: false,
        showMinCharsError: false
    }

    openManageVendorsPopup = () => {
        this.setState({
            selectedVendor: this.props.selectedVendor,
            showManageVendorsPopup: true
        })
    }

    handleClose = () => {
        this.setState({
            selectedVendor: this.props.selectedVendor || null,
            showManageVendorsPopup: false,
        })
    }
    

    render() {
        const {
            showManageVendorsPopup,
            apiErrorMessage,
        } = this.state,
            {   classes,
                selectedVendor
            } = this.props;

        return (
            <>
                <Button
                    className={classes.manage__button}
                    size="small"
                    variant='outlined'
                    onClick={this.openManageVendorsPopup}>
                    {i18n.t('vendors.manageVendors')}
                </Button>

                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <Dialog
                    classes={
                        {
                            paper: classes.dialog__paper,
                        }
                    }
                    open={showManageVendorsPopup}
                    maxWidth='sm'
                    TransitionComponent={SlideUp}
                >

                    <DialogTitle disableTypography={true}>
                        <Typography variant='body1' color="inherit">{i18n.t('vendors.vendorsTitle')}</Typography>
                        <IconButton color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <div className={classes.dialog_content_container}>
                        <ManageVendor 
                            selectedVendor={selectedVendor}
                            changeVendor={this.props.changeVendor}
                            onClose={this.handleClose}
                        />
                    </div>

                </Dialog>

            </>
        )
    }
}


export default withMobileDialog()(withStyles(styles)(ManageVendorPopup));