export const SET_VENDORS = 'SET_VENDORS'
export const ADD_VENDOR = 'ADD_VENDOR'
export const UPDATE_VENDOR = 'UPDATE_VENDOR'
export const DELETE_VENDOR = 'DELETE_VENDOR'
export const SET_VENDOR = 'SET_VENDOR'
export const ADD_VENDOR_CONTACT = 'ADD_VENDOR_CONTACT'
export const DELETE_VENDOR_CONTACT = 'DELETE_VENDOR_CONTACT'
export const UPDATE_VENDOR_CONTACT = 'UPDATE_VENDOR_CONTACT'

export function setVendors(vendors) {
    return {
        type: SET_VENDORS,
        vendors
    }
}

export function addVendor(vendor) {
    return {
        type: ADD_VENDOR,
        vendor
    }
}

export function updateVendor(vendor) {
    return {
        type: UPDATE_VENDOR,
        vendor
    }
}

export function deleteVendor(vendor) {
    return {
        type: DELETE_VENDOR,
        vendor
    }
}
export function setVendor(vendor) {
    return {
        type: SET_VENDOR,
        vendor
    }
}
export function addVendorContact(contact) {
    return {
        type: ADD_VENDOR_CONTACT,
        contact
    }
}
export function updateVendorContact(contact) {
    return {
        type: UPDATE_VENDOR_CONTACT,
        contact
    }
}
export function deleteVendorContact(contact) {
    return {
        type: DELETE_VENDOR_CONTACT,
        contact
    }
}


