import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import i18n from 'i18next'

export default class Accounting extends Component {
  render() {
    return (
      <div>
        <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.accounting')}`} />
        Accounting
        </div>
    )
  }
}
