import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import styled from 'styled-components'
import moment from 'moment'
import i18n from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Typography,
    withMobileDialog
} from '@material-ui/core/';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'


const localizer = momentLocalizer(moment)

const Loadingview = styled.div`
    position:absolute;
    top:50%;
    margin:auto;
    left:calc(50% - 100px);
    background-color:#cdcdcd;
    display:flex;
    flex-direction:column;
    align-items:center; 
    justify-content:center;
    z-index:2000;
    width:200px;
    height:100px;
    border-radius:5px;
    span{
        color:#3c3c3c;
    }
`;

const CalendarContainer = styled.div`
    position:relative;
    font-size: 16px;
    font-family: Inconsolata, monospace
`;

const styles = theme => ({
    calendar: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    }
})

const today = moment();

class EventsCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.loading,
            month: today.format('MM'),
            year: today.format('YYYY'),
            defaultDate: new Date(),
            events: this.props.events
        }
    }

    componentDidMount = () => {
        if(this.props.dashboard){
            this.props.fetchEvents({
                month: today.format('MM'),
                year: today.format('YYYY')
            });
        }
    }

    
    componentWillReceiveProps = (nextProps) => {
        if(this.changePeople(nextProps.people.filterPeople)){
            this.props.fetchEvents({
                month: this.state.month,
                year: this.state.year
            });
        }
        this.setState({
            events: nextProps.events,
            loading: nextProps.loading
        })
    }

    changePeople=(newPeople)=>{
        const { people } = this.props

        if(newPeople.length !== people.filterPeople.length){
            return true
        } else {
            let list = people.filterPeople.map(p => {
                if (newPeople.find(n => n.userId === p.userId)) {
                    return 1
                } else {
                    return 0
                }
            })

            if(list.filter(l => l).length !== newPeople.length){
                return true
            } else {
                return false
            }
        }
    }

    handleSelect = ({ start, end }) => {
        console.log('hola', start, end)
    }

    render() {
        const { loading, month, year, defaultDate, events } = this.state,
              { classes, width, fromEvents } = this.props;

        const EventComponent = (row) => {
            const { width } = this.props;

            if(width !== 'xs') {
                return (
                    <div style={{fontSize: '10px'}}>
                        <strong>
                            {row.event.client && row.event.client.name ? 
                                row.event.client.name : 
                                row.event.title
                            }
                        </strong>
                    </div>
                )
            } else {
                return ''
            }
        }


        return (
            <div>
                {loading && (
                    <Loadingview>
                        <CircularProgress color='secondary' />
                        <Typography>{i18n.t("calendar.loadingEvents")}</Typography>
                    </Loadingview>
                )}
                <CalendarContainer>
                    <Calendar
                        className={classes.calendar}
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{height: width==='xs' ? ' 60vh' : '80vh'}}
                        views={["month", "week", "day"]}
                        onSelectEvent={(e) => this.props.openElement(e)}
                        popup={true}
                        defaultDate={defaultDate}
                        selectable={ fromEvents ? true : false}
                        onSelectSlot={this.handleSelect}
                        components={{
                            event: EventComponent
                        }}
                        onNavigate={(date, view, action) => {
                            if (moment(date).format('MM') !== month || moment(date).format('YYYY') !== year) {
                                this.setState({
                                    defaultDate: date,
                                    month: moment(date).format('MM'),
                                    year: moment(date).format('YYYY')
                                })
                                this.props.fetchEvents({
                                    month: moment(date).format('MM'),
                                    year: moment(date).format('YYYY')
                                });
                            }
                        }}
                        eventPropGetter={
                            (event, start, end, isSelected) => {
                                let newStyle = {
                                    backgroundColor: event.user && event.user.color ? event.user.color : "rgba(30, 144, 255, 1)",
                                    color: '#000',
                                    borderRadius: "2px",
                                    border: "none"
                                };

                                if (event.file){
                                    newStyle.backgroundColor = "rgba(30, 144, 255, 1)"
                                }

                                return {
                                    className: "",
                                    style: newStyle
                                };
                            }
                        }
                    />

                </CalendarContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
        people: state.people
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventsCalendar)));
