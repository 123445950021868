import React from 'react';
import {
    Grid,
    withStyles,
    Typography,
    withMobileDialog,
    Link,
} from '@material-ui/core';
import {
    Drafts,
    Phone
} from '@material-ui/icons'
import i18n from 'i18next';
import { Client, ManageClientContactsPopup } from '../../clients'
import { CustomSelect} from '../../general'

//REDUX
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as clientsActions from '../../../actions/clientsActions'
import * as generalActions from '../../../actions/generalActions'


const styles = theme => ({
    visit__client__closeButton: {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        padding: '5px'
    },
    customLink: theme.customLink,
    event__clientcontact__message__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-5px'
    },
    event__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    visit__client__editActions: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: theme.spacing(4)
    },
});

const defaultClientContact = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: 0
}

const defaultClient = {
    country: {
        id: null
    },
    name: '',
    taxId: '',
    address: {
        address1: '',
        address2: ''
    },
    phone: '',
    fax: '',
    id: null,
    contacts: []
}

class EventSelectClient extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            eventClientId: props.eventClientId,
            eventClientContactId: props.eventClientContactId
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.client && nextProps.client.contacts && nextProps.eventClientContactId){
            this.setState({
                selectedClientContact: nextProps.client.contacts.find(contact => contact.id === nextProps.eventClientContactId),
                clientContacts: nextProps.client.contacts || [],
            })
        }
        
        this.setState({
            eventClientId: nextProps.eventClientId
        });
    }

    changeClient = (newClient) => {

        this.setState({
            selectedClient: newClient || defaultClient,
            eventClientId: newClient ? newClient.id : null,
            selectedClientContact: defaultClientContact,
            clientContacts: newClient ? newClient.contacts : [],
            countryFilter: newClient ? newClient.country.id : null
        }, () => {
            if (newClient) {
                this.props.clientsActions.setClient(newClient)
            } else {
                this.props.clientsActions.setClient({})
            }
            this.props.selectClient(newClient, null)
        })
    }
    updateVisitClient = (client) => {
        this.props.clientsActions.updateClient(client);
        this.setState({
            selectedClient: client
        })
    }
    
    updateVisitClientContact = (contact) => {
        let { clientContacts, selectedClient } = this.state,
            contactIndex = clientContacts.findIndex(c => c.id === contact.id),
            newClientContacts = [...clientContacts];

        if (contactIndex) {
            newClientContacts = [
                ...clientContacts.slice(0, contactIndex),
                { ...contact },
                ...clientContacts.slice(contactIndex + 1)
            ]
        }
        this.setState({
            clientContacts: newClientContacts,
            selectedClientContact: contact
        })

        this.props.selectClient(selectedClient, contact)

    }


    changeClientContact = (newContact) => {
        const { clientContacts, selectedClient } = this.state;

        let newClientContacts = [...clientContacts]

        if(newClientContacts.length === 0 || !newClientContacts.find(cont => cont.id === newContact.id) ) {
            newClientContacts.push(newContact)
        }

        this.setState({
            selectedClientContact: newContact,
            clientContacts: newClientContacts
        })

        this.props.selectClient(selectedClient, newContact)

    }

    handleCloseClientContactsPopup = () => {
        let { selectedClientContact } = this.state,
            { client } = this.props,
            contact = client.contacts ? client.contacts.find(c => c.id === selectedClientContact.id) : defaultClientContact;

        this.setState({
            selectedClientContact: contact ? contact : defaultClientContact
        })
    }

    selectClientContact = (selectedContact) => {
        if (selectedContact) {
            const { client } = this.props;

            let clientContacts = client && client.contacts ? client.contacts : [],
                contact = clientContacts.find(c => c.id === selectedContact.value);

            this.setState({
                selectedClientContact: contact ? contact : defaultClientContact
            })

            this.props.selectClient(client, contact)

        } else {
            this.setState({
                selectedClientContact: defaultClientContact
            })
        }
    }

    render() {
        const { classes } = this.props;
        const { selectedClientContact, selectedClient, validating, 
                clientContacts, eventClientId, eventClientContactId } = this.state;

        return (
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant='body1' color='textPrimary'>
                        {i18n.t('quotes.quoteRequest.client')}
                    </Typography>
                    <Client
                        eventClient={true}
                        clientId={eventClientId}
                        changeClient={this.changeClient}
                        updateRFQClient={this.updateVisitClient}
                    />
                </Grid>

                {!(selectedClient && selectedClient.id) && (
                    <Grid item xs={12} sm={6}>
                        <div className={classes.visit__client__editActions}>
                            <Typography variant='body1' color='textPrimary'>{i18n.t('quotes.quoteRequest.clientContact')}</Typography>
                        </div>
                        <Typography color="textSecondary">{i18n.t('quotes.quoteRequest.selectClientFirst')}</Typography>
                        {validating && ((!selectedClientContact) || (selectedClientContact && !selectedClientContact.id)) && (
                            <Typography color='error'>{i18n.t('quotes.quoteRequest.clientContactsRequired')}</Typography>
                        )}
                    </Grid>
                )}

                {(selectedClient && selectedClient.id) && (
                    <Grid item xs={12}>
                        <div className={classes.visit__client__editActions}>
                            <Typography variant='body1' color='textPrimary'>
                                {i18n.t('quotes.quoteRequest.clientContact')}
                            </Typography>

                            <ManageClientContactsPopup
                                onClose={this.handleCloseClientContactsPopup}
                                selectedClientContact={selectedClientContact}
                                eventClientContactId={eventClientContactId}
                                changeClientContact={this.changeClientContact}
                                updateRFQClientContact={this.updateVisitClientContact} />
                        </div>
                        {clientContacts && clientContacts.length > 0 &&
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={7}>
                                    <CustomSelect
                                        options={clientContacts.map(c => ({ label: `${c.firstName} ${c.lastName}`, value: c.id }))}
                                        label={i18n.t('quotes.quoteRequest.clientContact')}
                                        placeholder={i18n.t('clients.selectClientContact')}
                                        fullWidth={true}
                                        required={true}
                                        value={selectedClientContact ? (selectedClientContact.id === 0 ? null : selectedClientContact.id) :  null}
                                        onSelect={this.selectClientContact}
                                        error={validating && ((!selectedClientContact) || (selectedClientContact && selectedClientContact.id === 0)) ? true : false}
                                    />
                                </Grid>
                                {
                                // <Grid item xs={12} sm={5} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>                                        
                                //     <ManageClientContactsPopup
                                //         onClose={this.handleCloseClientContactsPopup}
                                //         selectedClientContact={selectedClientContact}
                                //         eventClientContactId={eventClientContactId}
                                //         changeClientContact={this.changeClientContact}
                                //         updateRFQClientContact={this.updateVisitClientContact} />
                                // </Grid>
                                }
                            </Grid>
                        }

                        {selectedClientContact && selectedClientContact.id !== 0 &&
                            <>
                                <Typography variant='body2' color="textSecondary">
                                    {selectedClientContact.firstName || ''} {selectedClientContact.lastName || ''}
                                </Typography>

                                {selectedClientContact.email &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Drafts className={classes.event__clientcontact__message__icon}/>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            onClick={() => this.setState({
                                                openNewNote: true,
                                            })} noWrap>{selectedClientContact.email || ''}
                                        </Typography>
                                    </div>
                                }

                                {selectedClientContact.phone &&
                                    <Typography variant='body2' color="textSecondary">
                                        <Phone className={classes.event__icon} />{selectedClientContact.phone}
                                    </Typography>
                                }
                            </>
                        }

                        {clientContacts && selectedClient && selectedClient.id && clientContacts.length === 0 && (
                            <>
                                <Typography color="textSecondary">{selectedClient.name} {i18n.t('quotes.quoteRequest.noClientContacts')}
                                    <br />
                                    <Link className={classes.customLink} onClick={() => {
                                        this.props.generalActions.showCreateClientContact(true)
                                    }}>
                                        {i18n.t('quotes.quoteRequest.clickToAddContact')}
                                    </Link>
                                </Typography>
                                <ManageClientContactsPopup
                                    hideButton={true}
                                    onClose={this.handleCloseClientContactsPopup}
                                    selectedClientContact={selectedClientContact}
                                    eventClientContactId={eventClientContactId}
                                    changeClientContact={this.changeClientContact}
                                    updateRFQClientContact={this.updateVisitClientContact} />
                            </>
                        )}

                        {validating && clientContacts && clientContacts.length === 0 && (
                            <Typography color='error'>{i18n.t('quotes.quoteRequest.clientContactsRequired')}</Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        client: state.client,
        clients: state.clients
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventSelectClient)));
