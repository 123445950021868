import React, { Component } from 'react'
import {
    Typography,
    Grid,
    IconButton,
    withMobileDialog,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Link,
    Divider,
    InputAdornment,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { Close, Phone, Search } from '@material-ui/icons'

import CustomSelect from '../general/CustomSelect'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as apiStatusActions from '../../actions/apiStatusActions'
import * as vendorsActions from '../../actions/vendorsActions'

import API from '../../lib/api';

const styles = theme => ({
    buttonSpinner: theme.buttonSpinner,
    customLink: theme.customLink,
    actions__section__divider: {
        marginTop: theme.spacing()
    },
    cancelDelete__buttons__container: {
        display: 'flex'
    },
    "vendor__editSections__button--disabled": {
        backgroundColor: theme.palette.textPrimary.main,
        minWidth: '100px'
    },
    vendor__editSections__saveButton: {
        minWidth: '100px'
    },
    vendor__deleteClientButton: {
        marginRight: theme.spacing(),
        borderColor: theme.palette.red[80],
        '&:hover': {
            backgroundColor: theme.palette.red[60],
            color: theme.palette.getContrastText(theme.palette.red[100])
        },
        marginLeft: theme.spacing(),
        color: theme.palette.red[80],
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
    actions__container: {
        marginTop: theme.spacing(2)
    },
    action__button__container: {
        textAlign: 'center'
    },
    vendor__form__textField: {
        // width: '90%',
        // margin: `${theme.spacing()}px 0`,
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%'
        // }
        margin: `${theme.spacing()}px 0`,
    }, vendor__form__input: {
        fontSize: '0.8em'
    },
    vendor__form__bottomButtons__grid: {
        marginTop: theme.spacing()
    },
    vendorRFQ__icon__phone: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    manage__button: theme.manage__button
})

const defaultVendor = {
    name: '',
    address: {
        address1: '',
        address2: ''
    },
    phone1: '',
    phone2: '',
    website: '',
    remarks: '',
    id: null
}

class ManageVendorPopup extends Component {

    state = {
        selectedVendor: this.props.selectedVendor || null,
        currentVendor: this.props.selectedVendor || defaultVendor,
        validateVendorQuote: false,
        validateVendor: false,
        createVendor: false,
        editVendor: false,
        savingVendor: false,
        apiErrorMessage: null,
        deleting: false,
        triggerReload: false,
        searchVendor: this.props.selectedVendor ? this.props.selectedVendor.name : '',
        fetching: false,
        fetched: false,
        showMinCharsError: false
    }

    fetchVendors = () => {
        const {
            searchVendor,
            selectedVendor
        } = this.state,
            { fromCompanies } = this.props;


        if (searchVendor.length >= 2) {
            this.setState({
                fetching: true,
                fetched: false,
                showMinCharsError: false
            }, _ => {
                API.Vendors.getVendors({
                    filter: searchVendor
                })
                    .then(vendorsResponse => {
                        this.props.vendorsActions.setVendors(vendorsResponse.data.records || [])
                        let vendor = null;

                        if (selectedVendor) {
                            vendor = vendorsResponse.data.records.find(v => v.id === selectedVendor.id);
                            if (!vendor) {
                                this.setState({
                                    selectedVendor: defaultVendor
                                })
                            }
                        }

                        //Si el resultado es solo un vendor, lo seleccionamos de una vez
                        if (vendorsResponse.data.records.length === 1) {
                            vendor = vendorsResponse.data.records[0];

                            fromCompanies && this.props.setVendor(vendor)

                            this.setState({
                                selectedVendor: vendor
                            })
                        }
                        this.setState({
                            fetching: false,
                            fetched: true
                        })
                    })
            })
        } else {
            this.setState({
                showMinCharsError: true,
                selectedVendor: defaultVendor
            })
            this.props.vendorsActions.setVendors([])
        }
    }

    handleClose = () => {
        const { triggerReload } = this.state;
        this.setState({
            selectedVendor: this.props.selectedVendor || null,
            createVendor: false,
            editVendor: false,
            validateVendor: false,
            triggerReload: false
        }, () => {
            this.props.onClose && this.props.onClose({
                triggerReload
            })
        })
    }

    handleCancel = () => {
        this.props.editOrCreateVendor && this.props.editOrCreateVendor(false)

        this.setState({
            createVendor: false,
            editVendor: false,
            validateVendor: false,
            selectedVendor: this.state.createVendor ? (this.state.currentVendor ? this.state.currentVendor : defaultVendor) : this.state.selectedVendor
        })
    }

    selectVendor = (vendor) => {
        const { vendors, fromCompanies } = this.props;

        if (vendor) {
            let selectedVendor = vendors.find(v => v.id === vendor.value);

            fromCompanies && this.props.setVendor(selectedVendor)
            
            this.setState({
                editVendor: false,
                selectedVendor: selectedVendor ? selectedVendor : defaultVendor
            })
        } else {
            fromCompanies && this.props.setVendor({})
            this.setState({
                editVendor: false,
                selectedVendor: defaultVendor
            })
        }
    }

    handleEditVendor = () => {
        this.props.editOrCreateVendor && this.props.editOrCreateVendor(true)

        this.setState({
            editVendor: true
        })
    }
    hendleCreateVendor = () => {
        this.props.editOrCreateVendor && this.props.editOrCreateVendor(true)

        this.setState({
            currentVendor: this.state.selectedVendor,
            selectedVendor: defaultVendor,
            createVendor: true
        })
    }
    validateFormData = () => {
        const { selectedVendor } = this.state;
        if (!selectedVendor.name || !selectedVendor.address.address1 || !selectedVendor.phone1) {
            this.setState({
                validateVendor: true
            })
            return false;
        }
        return true;
    }
    handleSaveVendor = () => {
        let { selectedVendor } = this.state,
            { fromCompanies } = this.props;

        if (this.validateFormData()) {
            this.setState({
                validateVendor: false,
                savingVendor: true
            })

            //udpate
            if (selectedVendor.id) {

                API.Vendors.updateVendor({ vendor: selectedVendor }).then(res => {
                    if (res.data) {

                        this.props.vendorsActions.updateVendor(res.data.record)

                        fromCompanies && this.props.setVendor(res.data.record)

                        this.props.editOrCreateVendor && this.props.editOrCreateVendor(false)

                        this.setState({
                            editVendor: false,
                            selectedVendor: res.data.record,
                            savingVendor: false,
                            triggerReload: true,
                            searchVendor: selectedVendor.name
                        }, () => {
                            this.fetchVendors();
                            if (this.props.selectedVendor && this.state.selectedVendor.id === this.props.selectedVendor.id) {
                                this.props.changeVendor && this.props.changeVendor(res.data.record);
                                this.props.selectVendor && this.props.selectVendor(res.data.record)
                            }
                        })

                    } else {

                        this.setState({
                            savingVendor: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })

                //create
            } else {

                API.Vendors.createVendor({ vendor: selectedVendor }).then(res => {
                    if (res.data) {
                        this.props.vendorsActions.addVendor(res.data.record)

                        fromCompanies && this.props.setVendor(res.data.record)

                        this.props.editOrCreateVendor && this.props.editOrCreateVendor(false)

                        this.setState({
                            createVendor: false,
                            selectedVendor: res.data.record,
                            savingVendor: false,
                            triggerReload: true,
                            searchVendor: selectedVendor.name
                        }, _ => {
                            this.fetchVendors();
                        })
                    } else {
                        this.setState({
                            savingVendor: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            }
        }
    }

    handleChangeVendor = () => {
        let { selectedVendor } = this.state;
        this.props.vendorsActions.setVendor(selectedVendor);
        this.props.changeVendor(selectedVendor);
        this.handleClose();
    }

    deleteVendor = () => {
        let { selectedVendor } = this.state,
            { fromCompanies } = this.props;
        this.setState({
            savingVendor: true
        }, () => {
            API.Vendors.deleteVendor({ vendor: selectedVendor }).then(res => {
                if (res.status === 'success') {
                    this.props.vendorsActions.deleteVendor(selectedVendor);

                    this.props.changeVendor && this.props.changeVendor();
                    this.props.editOrCreateVendor && this.props.editOrCreateVendor(false)
                    
                    fromCompanies && this.props.setVendor({})

                    this.fetchVendors();
                    this.setState({
                        editVendor: false,
                        selectedVendor: defaultVendor,
                        searchVendor: '',
                        savingVendor: false,
                        triggerReload: true
                    })

                } else {
                    this.setState({
                        savingVendor: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        })
    }


    handleTextFieldKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                this.fetchVendors();
                break
            case 'Escape':
                this.setState({
                    fetched: false,
                    searchVendor: ''
                })
                break
            default: break
        }
    }

    render() {
        const {
            validateVendorQuote,
            validateVendor,
            selectedVendor,
            createVendor,
            editVendor,
            savingVendor,
            deleting,
            fetching,
            fetched,
            searchVendor,
            showMinCharsError
        } = this.state,
            {
                vendors,
                classes,
                fromCompanies
            } = this.props;

        return (
            <div style={fromCompanies ? {padding: '16px 0'} : {padding: '16px'}}>

                {!editVendor && !createVendor && fromCompanies && (
                    <Grid container spacing={0} justify='flex-end' alignItems='center' className={classes.client__actions__container}>
                        <Grid item sm={3} xs={12} className={classes.client__action__button__container} style={{textAlign: 'right', position: 'absolute', top: '20px'}}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={this.hendleCreateVendor}>
                                {i18n.t('vendors.addVendor')}
                            </Button>
                        </Grid>
                    </Grid>
                )}

                {/* Vendor selector */}
                {!createVendor && !editVendor && (
                    <Grid container alignItems='center'>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    className: classes.vendor__form__input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple={true}
                                                className={classes.vendor__search__clear}
                                                onClick={() => {
                                                    this.setState({
                                                        fetched: false,
                                                        searchVendor: ''
                                                    });
                                                }}
                                                size='small'
                                            >
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                disabled={savingVendor || fetching ? true : false}
                                onChange={e => {
                                    this.setState({
                                        fetched: false,
                                        searchVendor: e.target.value
                                    });
                                }}
                                onKeyDown={this.handleTextFieldKeyDown}
                                value={searchVendor ? searchVendor : ''}
                                className={classes.vendor__form__textField}
                                id="vendorSearch"
                                type="text"
                                variant="outlined"
                                label={i18n.t('vendors.vendorSearchName')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                onClick={this.fetchVendors}
                                disabled={fetching || savingVendor}>
                                {fetching ? (
                                    <CircularProgress color='secondary' size={24} />
                                ) : (
                                        <Search color='secondary' fontSize="small" />
                                    )}
                            </IconButton>
                        </Grid>
                        {showMinCharsError && (
                            <Grid item xs={10}>
                                <Typography variant='body2' color='textSecondary'>{i18n.t('vendors.minimumChars')}</Typography>
                            </Grid>
                        )}
                        {vendors && vendors.length > 0 && (
                            <Grid item sm={9} xs={12}>
                                <CustomSelect
                                    options={vendors.map(vendor => {
                                        return {
                                            value: vendor.id,
                                            label: vendor.name
                                        }
                                    })}
                                    label={i18n.t('vendors.vendorSelect')}
                                    value={selectedVendor ? selectedVendor.id : null}
                                    required={true}
                                    error={((selectedVendor && !selectedVendor.id) || !selectedVendor) && validateVendorQuote ? true : false}
                                    onSelect={this.selectVendor}
                                />
                            </Grid>
                        )}
                        {fetched && searchVendor && (vendors.length === 0) && (
                            <Typography variant='body2' color='textSecondary'>{`${i18n.t('vendors.noVendorsWithTerm')}`}<strong>{`${searchVendor}`}</strong></Typography>
                        )}

                        {!createVendor && !editVendor && fromCompanies && selectedVendor && selectedVendor.id  && (
                            <Grid item sm={3} xs={12} className={classes.client__action__button__container} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Link
                                    className={classes.customLink}
                                    color="secondary"
                                    variant='button'
                                    style={{ textAlign: 'center' }}
                                    onClick={this.handleEditVendor}>
                                    {i18n.t('vendors.editVendor')}
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                )}


                {/* Vendor Read only information */}
                {!createVendor && !editVendor && selectedVendor && selectedVendor.id && (
                    <>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Typography variant='body2' color='textSecondary'>{selectedVendor.remarks}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2' color="textSecondary">
                                    {selectedVendor.name || ''}
                                    <br />
                                    {selectedVendor.address.address1 || ''}
                                    <br />
                                    {selectedVendor.address.address2 || ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2' color="textSecondary">{selectedVendor.website || ''}</Typography>
                                {selectedVendor.phone1 &&
                                    <Typography variant='body2' color="textSecondary" noWrap>
                                        <Phone className={classes.vendorRFQ__icon__phone} />
                                        {selectedVendor.phone1}
                                    </Typography>
                                }
                                {selectedVendor.phone2 &&
                                    <Typography variant='body2' color="textSecondary" noWrap>
                                        +<Phone className={classes.vendorRFQ__icon__phone} />
                                        {selectedVendor.phone2}
                                    </Typography>
                                }
                            </Grid>
                            {(!this.props.selectedVendor || (selectedVendor && this.props.selectedVendor && selectedVendor.id !== null && (selectedVendor.id !== this.props.selectedVendor.id))) && !fromCompanies &&
                                <Grid item xs={12}>
                                    <Link className={classes.customLink}
                                        variant='button'
                                        onClick={this.handleChangeVendor}>
                                        {i18n.t('vendors.useSelectedVendor')}
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    </>
                )}

                {/* mensaje para seleccionar un vendor */}

                {!showMinCharsError && !editVendor && !createVendor && (!selectedVendor || (selectedVendor && !selectedVendor.id)) && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {!(fetched && searchVendor && (vendors.length === 0)) && (
                                <Typography variant='body2' color='textSecondary'>{i18n.t('vendors.selectVendorToEdit')}</Typography>
                            )}
                        </Grid>
                    </Grid>
                )}

                {/* vendor form  */}
                {(editVendor || createVendor) && (
                    <>
                        <Grid container spacing={2}>
                            {/* row 1 */}
                            <Grid item sm={6} xs={12}>
                                {/* vendor name */}
                                <TextField autoComplete='off' required
                                    id="vendorName"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    error={selectedVendor && selectedVendor.name === '' && validateVendor}
                                    fullWidth
                                    disabled={savingVendor}
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                name: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedVendor ? selectedVendor.name : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorName')}
                                />
                            </Grid>

                            {/* row2 */}
                            <Grid item sm={12} xs={12}>
                                {/* vendor remarks */}
                                <TextField
                                    autoComplete='off'
                                    id="vendor-remarks-multiline"
                                    label={i18n.t('vendors.vendorRemarks')}
                                    className={classes.vendor__form__textField}
                                    defaultValue={selectedVendor && selectedVendor.remarks ? selectedVendor.remarks : ''}
                                    multiline
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    disabled={savingVendor}
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                remarks: event.target.value
                                            }
                                        });
                                    }}
                                    rowsMax='20'
                                    rows='3'
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </Grid>


                            {/* column2 */}

                            <Grid item sm={8} xs={12}>
                                {/* vendor address 1 */}
                                <TextField autoComplete='off' required
                                    id="vendorAddress1"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    fullWidth
                                    disabled={savingVendor}
                                    error={selectedVendor && selectedVendor.address && selectedVendor.address.address1 === '' && validateVendor}
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                address: {
                                                    ...selectedVendor.address,
                                                    address1: event.target.value
                                                }
                                            }
                                        });
                                    }}
                                    value={selectedVendor && selectedVendor.address.address1 ? selectedVendor.address.address1 : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorAddress1')}
                                />
                            </Grid>

                            {/* column 3 */}
                            <Grid item sm={4} xs={12}>
                                {/* vendor phone 1 */}
                                <TextField autoComplete='off' required
                                    id="vendorPhone1"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    disabled={savingVendor}
                                    fullWidth
                                    error={selectedVendor && selectedVendor.phone1 === '' && validateVendor}
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                phone1: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedVendor && selectedVendor.phone1 ? selectedVendor.phone1 : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorPhone1')}
                                />
                            </Grid>

                            <Grid item sm={8} xs={12}>
                                {/* vendor address 2 */}
                                <TextField
                                    autoComplete='off'
                                    id="vendorAddress2"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    disabled={savingVendor}
                                    fullWidth
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                address: {
                                                    ...selectedVendor.address,
                                                    address2: event.target.value
                                                }
                                            }
                                        });
                                    }}
                                    value={selectedVendor && selectedVendor.address.address2 ? selectedVendor.address.address2 : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorAddress2')}
                                />
                            </Grid>

                            <Grid item sm={4} xs={12}>
                                {/* vendor phone 2 */}
                                <TextField
                                    autoComplete='off'
                                    id="vendorPhone2"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    fullWidth
                                    disabled={savingVendor}
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                phone2: event.target.value
                                            }
                                        });
                                    }}
                                    value={selectedVendor && selectedVendor.phone2 ? selectedVendor.phone2 : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorPhone2')}
                                />

                            </Grid>


                            <Grid item sm={8} xs={12}>
                                {/* vendor website */}
                                <TextField
                                    autoComplete='off'
                                    id="vendorWebsite"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    fullWidth
                                    onChange={event => {
                                        this.setState({
                                            selectedVendor: {
                                                ...selectedVendor,
                                                website: event.target.value
                                            }
                                        });
                                    }}
                                    disabled={savingVendor}
                                    value={selectedVendor && selectedVendor.website ? selectedVendor.website : ''}
                                    className={classes.vendor__form__textField}
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t('vendors.vendorWebsite')}
                                />
                            </Grid>

                            {validateVendor && (
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                    <Typography color='error'>{i18n.t("requiredFields")}</Typography>
                                </Grid>
                            )}

                        </Grid>
                    </>
                )}


                {/* Edit/create vendors links buttons */}
                {!editVendor && !createVendor && !fromCompanies &&(
                    <>
                        <Divider className={classes.actions__section__divider} />

                        <Grid container spacing={0} justify='flex-end' alignItems='center' className={classes.actions__container}>
                            <Grid item sm={3} xs={12} className={classes.action__button__container}>
                                {(selectedVendor && selectedVendor.id !== null && !createVendor) === true && (
                                    <Link
                                        className={classes.customLink}
                                        color="secondary"
                                        variant='button'
                                        style={{ textAlign: 'center' }}
                                        onClick={this.handleEditVendor}>
                                        {i18n.t('vendors.editVendor')}
                                    </Link>
                                )}
                            </Grid>

                            <Grid item sm={3} xs={12} className={classes.action__button__container}>
                                <Link
                                    className={classes.customLink}
                                    color="secondary"
                                    variant='button'
                                    style={{ textAlign: 'center' }}
                                    onClick={this.hendleCreateVendor}>
                                    {i18n.t('vendors.addVendor')}
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                )}

                {/* Save, cancel, delete buttons */}
                {(editVendor || createVendor) && (
                    <>
                        <Divider className={classes.actions__section__divider} />

                        <Grid container spacing={2} justify='space-between' direction='row' className={classes.vendor__form__bottomButtons__grid}>
                            <Grid item xs={12} md={4} className={classes.cancelDelete__buttons__container}>
                                <Button
                                    variant="contained"
                                    disabled={savingVendor}
                                    size='small'
                                    onClick={this.handleCancel}>
                                    {i18n.t('vendors.cancel')}
                                </Button>
                                {!createVendor && (
                                    <Button
                                        disabled={savingVendor || deleting || (selectedVendor.vendorQuotesCount > 0) ? true : false}
                                        size='small'
                                        onClick={this.deleteVendor}
                                        className={classes.vendor__deleteClientButton}
                                    >
                                        {i18n.t("vendors.deleteVendor")}
                                        {deleting && !savingVendor && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </Button>
                                )}
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={savingVendor}
                                    fullWidth
                                    className={savingVendor ? classes["vendor__editSections__button--disabled"] : classes.vendor__editSections__saveButton}
                                    size='small'
                                    onClick={this.handleSaveVendor}>
                                    {i18n.t('vendors.saveVendor')}
                                    {savingVendor && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </Grid>
                            {(selectedVendor.vendorQuotesCount > 0) && (
                                <Grid item xs={12} className={classes.cancelDelete__buttons__container}>
                                    <Typography color='textSecondary'>{i18n.t('vendors.cantBeDeleted')}</Typography>
                                </Grid>
                            )}
                        </Grid>

                    </>
                )}
            </div>
        )
    }
}


ManageVendorPopup.propTypes = {
    classes: PropTypes.object.isRequired,
}


function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        vendors: state.vendors,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(ManageVendorPopup)));