import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
    Typography,
    withStyles,
    withMobileDialog,
    Grid,
    Button
} from '@material-ui/core/';
import { withRouter } from "react-router";
import { DealForm, DealsList } from '.'
import {
    Add
} from '@material-ui/icons'
import i18n from 'i18next';

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as accountActions from '../../../actions/accountActions'


const styles = theme => ({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 100,
        paddingBottom: 100
    },
    events__list: {
        paddingTop: 0,
        marginBottom: 50,
    },
    events__list__item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    events__list__date__container: {
        width: '20vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    events__list__info__container: {
        width: '80vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
    },
    event__missing__visit__indicator: {
        color: theme.palette.red[80],
        position: 'absolute',
        right: 5,
        bottom: 5,
        fontSize: '16px'
    },
    deals__container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px'
    },
    deals__container__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    deals__container__titleContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing()
        },
    },
    deals__icon: {
        marginRight: '8px',
        fontSize: '16px'
    },
    deals__amount: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            right: '16px',
            position: 'absolute',
            bottom: '8px'
        }
    },
    deals__addDealButton: {
        color: '#007bff',
        padding: '4px',
        position: 'absolute',
        right: '16px',
        top: '13px',
        zIndex: '1200',
    },
    deals__dueDateTask__icon:{
        color: "#fff",
        background: theme.palette.error.main,
        borderRadius: '15px',
        fontSize: '17px'
    },
    deals__todayTask__icon: {
        color: "#fff",
        background: theme.palette.green[100],
        borderRadius: '15px',
        fontSize: '17px'
    },
    warning__message__icon: {
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
});


class MyDeals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDealPopup: false,
            selectedDeal: null,
        }
    }

    activeDealPopup = () => {
        this.setState({
            showDealPopup: true
        })
    }

    addDeal=(newDeal)=>{
        this.setState({
            selectedDeal: newDeal
        })
    }


    render() {
        const { classes, width } = this.props;
        const { showDealPopup,
            selectedDeal } = this.state;

        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | Deals`} />
                <div className={classes.deals__container}>
                    <div className={classes.deals__container__header}>
                        <Grid container spaing={0} justify='space-between'>
                            <Grid xs={12} sm={10} item className={classes.deals__container__titleContainer}>
                                <Typography variant="h5">{i18n.t("visits.deals.title")}</Typography>
                            </Grid>
                            { width !== 'xs' &&
                                <Grid sm={2} item style={{textAlign: 'right'}}>
                                    <Button
                                        style={{
                                            color: '#007bff',
                                            padding: '4px',
                                            margin: '10px 0',
                                            width: 'fit-content',
                                            minWidth: 0
                                        }}
                                        onClick={this.activeDealPopup}>
                                        <Add />
                                        {i18n.t('Add Deal')}
                                    </Button>
                                </Grid>
                            }
                        </Grid>

                        
                    </div>

                    <DealsList 
                        newDeal={selectedDeal}
                        activeDealPopup={this.activeDealPopup}
                    />
                </div>

                <DealForm
                    open={showDealPopup}
                    addDeal={this.addDeal}
                    onClose={()=>{
                        this.setState({
                            showDealPopup: false,
                            selectedDeal: null
                        })
                    }}
                />
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        account: state.account
    }
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMobileDialog()(withStyles(styles)(MyDeals))));
