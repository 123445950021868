// Actions types

export const SET_EVENT = "SET_EVENT"
export const CLEAR_EVENT = 'CLEAR_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const SET_EVENTS = 'SET_EVENTS'
export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'

//Filter actions
export const SET_FILTERS = 'SET_FILTERS'


//Actions

export function setEvent(event) {
    return {
        type: SET_EVENT,
        event
    }
}

export function setEvents(events) {
    return {
        type: SET_EVENTS,
        events
    }
}

export function clearEvent() {
    return {
        type: CLEAR_EVENT
    }
}

export function updateEvent(event) {
    return {
        type: UPDATE_EVENT,
        event
    }
}

export function addEvent(event) {
    return {
        type: ADD_EVENT,
        event
    }
}

export function deleteEvent(event) {
    return {
        type: DELETE_EVENT,
        event
    }
}

export function setFilters(filters) {
    return {
        type: SET_FILTERS,
        filters
    }
}