import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import moment from 'moment'
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    CircularProgress,
    withMobileDialog,
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Person,
    Business
} from '@material-ui/icons'
import { SlideLeft } from '../../general/Transitions'
import API from '../../../lib/api';
import {DealFormSub1} from '.'

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    button__wrapper: {
        margin: `${theme.spacing()}px 0`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    buttonSpinner: theme.buttonSpinner,
    events__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    },
    warning__message__icon: {
        marginRight: theme.spacing(1),
        fontSize: '14pt',
        color: theme.palette.orange[100]
    },
    requiredInput: {
        background: '#ffffa3 !important',
        borderBottom: `2px solid ${theme.palette.error.main}`
    },
    alert__paper: {
        maxWidth: '600px',
        // width: '100%',
        overflow: 'inherit',
        margin: '0 5px'
    },
    location__address__warning: {
        color: theme.palette.orange[80],
        fontSize: '16px',
    },
    dialog__paper: {
        width: '100%'
    },
    confirmation__popup__button: {
        width: '100%', height: '100%', borderRadius: 0, color: '#007bff', textTransform: 'initial', fontFamily: 'Roboto'
    },
    deals__icon: {
        marginRight: '8px',
        fontSize: '16px'
    },
});

const currencies = [
    {
        "id": 46,
        "code": "EUR",
        "name": "Euro",
        "value": "EUR",
        "decimal_points": 2,
        "symbol": "€",
        "active_flag": true,
        "is_custom_flag": false
    },
    {
        "id": 148,
        "code": "USD",
        "value": "USD",
        "name": "US Dollar",
        "decimal_points": 2,
        "symbol": "$",
        "active_flag": true,
        "is_custom_flag": false
    }
]


class EventForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: null,
            validating: false,
            espectedCloseDate: new Date(),
            saving: false,
            formType: null,
            newDeal: {
                title: '',
                value: '',
                currency: 'USD',
                person_id: null,
                org_id: null,
                expected_close_date:  new Date(),
                visible_to: 3
            }
        }
    }

    onEnter=()=>{
        const {organizations} = this.state;

        if(!organizations || (organizations && organizations.length === 0)){
            this.getOrganizations()
        }
    }


    getOrganizations=()=>{
        API.Deals.getDealsOrganizations()
            .then( res => {
                if(res.data.records){
                    this.setState({
                        organizations: res.data.records.data.map(org => ({
                            ...org,
                            value: org.name
                        }))
                    })
                }
            })
    }

    // getCurrencies=()=>{
    //     API.Deals.getDealsCurrencies()
    //         .then( res => {
    //             if(res.data.records){
    //                 this.setState({
    //                     currencies: res.data.records.data.map(cu => ({
    //                         ...cu,
    //                         value: cu.code
    //                     }))
    //                 })
    //             }
    //         })
    // }


    manageDeal = () => {
        const { newDeal } = this.state,
             { visitId } = this.props;

        this.setState({
            validating: true
        })

        if(!newDeal.title){
            return
        }

        this.setState({
            saving: true
        })

        let deal = {
            title: newDeal.title,
            value: newDeal.value,
            currency: newDeal.currency,
            person_id: newDeal.person_id,
            org_id: newDeal.org_id,
            expected_close_date: moment(newDeal.expected_close_date).format('YYYY-MM-DD'),
            visible_to: 3
        }

        API.Deals.createDeal({deal, visitId})
            .then(res => {
                this.setState({
                    saving: false,
                    validating: false
                })
                this.props.addDeal(res.data.records.data)
                this.props.onClose()
            })

    }

    inputChange = (e) => {
        let newDeal = this.state.newDeal;

        this.setState({
            newDeal:{
                ...newDeal,
                [e.target.name]: e.target.value
            }
        })
    }

    dateChange = (e, inst) => {
        let newDeal = this.state.newDeal;

        this.setState({
            newDeal:{
                ...newDeal,
                expected_close_date: moment(inst.getVal())
            }
        })
    }

    
    showDealFormSub1 = (type) => {
        this.setState({
            formType: type
        }, () => {
            this.setState({
                openDealFormSub1: true
            })
        })
    }

    setOrganization=(org)=>{
        let newDeal = this.state.newDeal

        this.setState({
            newDeal: {
                ...newDeal,
                org_id: org.id,
                org_name: org.value
            }
        })
    }

    setPerson=(person)=>{
        let newDeal = this.state.newDeal

        this.setState({
            newDeal: {
                ...newDeal,
                person_id: person.id,
                person_name: person.value
            }
        })
    }

    setCurrency=(currency)=>{
        let newDeal = this.state.newDeal

        this.setState({
            newDeal: {
                ...newDeal,
                currency: currency
            }
        })
    }


    render() {
        const { organizations, newDeal,
                validating, espectedCloseDate, saving,
                openDealFormSub1, formType } = this.state,
            { classes, fullScreen } = this.props;

        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    onEnter={this.onEnter}
                    open={this.props.open}
                    onExited={() => {
                        this.setState({
                            saving: false,
                            unsavedChanges: false
                        })
                    }}
                    TransitionComponent={SlideLeft}
                >

                    <DialogTitle disableTypography={true} className={classes.events__form__title}>
                        <IconButton onClick={this.props.onClose}>
                            <KeyboardArrowLeft color='inherit' className={classes.events__form__title__back} />
                        </IconButton>
                        <Typography variant='body1' color="inherit">
                            {i18n.t('visits.deals.createDealTitle')}
                        </Typography>
                        <div style={{ width: '30px' }} />
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        <mobiscroll.Form className={`${classes.form} deal__form`}>
                            <mobiscroll.FormGroup style={{ marginTop: '1.5em' }}>
                                <mobiscroll.Form.Label
                                    onClick={() => this.showDealFormSub1('organization')} style={{ alignItems: 'center' }}>
                                    <>
                                        <Business className={classes.deals__icon}/>
                                        {i18n.t('visits.deals.form.organizationName')}
                                    </>
                                    <input value={newDeal ? newDeal.org_name : ''} name='organization' disabled />
                                    <KeyboardArrowRight style={{ color: '#ccc' }} />
                                </mobiscroll.Form.Label>

                                { newDeal && newDeal.org_id &&
                                    <mobiscroll.Form.Label
                                        onClick={() => this.showDealFormSub1('person')} style={{ alignItems: 'center' }}>
                                        <>
                                            <Person className={classes.deals__icon}/>
                                            {i18n.t('visits.deals.form.contact')}
                                        </>
                                        <input value={newDeal ? newDeal.person_name : ''} name='person' disabled />
                                        <KeyboardArrowRight style={{ color: '#ccc' }} />
                                    </mobiscroll.Form.Label>
                                }
                            </mobiscroll.FormGroup>

                            <mobiscroll.FormGroup style={{ marginTop: '1.5em' }}>
                                <mobiscroll.Form.Label className={validating && (!newDeal.title || newDeal.title === '') ? classes.requiredInput : ''}>
                                    {i18n.t('visits.deals.form.title')}
                                    <input
                                        value={newDeal.title} autoFocus name='title'
                                        onChange={this.inputChange}/>
                                </mobiscroll.Form.Label>
                                <mobiscroll.Form.Label>
                                    {i18n.t('visits.deals.form.value')}
                                    <input
                                        value={newDeal.value} autoFocus name='value' type='number'
                                        onChange={this.inputChange}/>
                                </mobiscroll.Form.Label>
                                <mobiscroll.Form.Label
                                    onClick={() => this.showDealFormSub1('currency')} style={{ alignItems: 'center' }}>
                                    {i18n.t('visits.deals.form.currency')}
                                    <input value={newDeal ? newDeal.currency : ''} name='person' disabled />
                                    <KeyboardArrowRight style={{ color: '#ccc' }} />
                                </mobiscroll.Form.Label>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup>
                                <mobiscroll.Form.Label>
                                    <mobiscroll.Date
                                        value={espectedCloseDate} dateWheels="|D M d|"
                                        onSet={this.dateChange}>
                                        <mobiscroll.Input id="espectedCloseDate"
                                        >{i18n.t('visits.deals.form.espectedCloseDate')}</mobiscroll.Input>
                                    </mobiscroll.Date>
                                </mobiscroll.Form.Label>
                            </mobiscroll.FormGroup>
                            
                            <mobiscroll.FormGroup>
                                <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                                    <mobiscroll.Button color="primary" disabled={saving} onClick={this.manageDeal}>
                                        {i18n.t("visits.eventForm.saveEvent")}
                                        {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </mobiscroll.Button>
                                </div>
                            </mobiscroll.FormGroup>
                        </mobiscroll.Form>
                    </DialogContent>
                </Dialog>

                <DealFormSub1
                    open={openDealFormSub1}
                    type={formType}
                    deal={newDeal}
                    currencies={currencies}
                    organizations={organizations}
                    setOrganization={this.setOrganization}
                    setPerson={this.setPerson}
                    setCurrency={this.setCurrency}
                    onClose={() => {
                        this.setState({
                            formType: null,
                            openDealFormSub1: false
                        })
                    }}
                />
            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(EventForm));
