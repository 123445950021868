import Amplify, { Auth } from 'aws-amplify';
import authParams from './authParams';
import store from '../store'

Amplify.configure(authParams.cognito);

const auth = {
    signIn: (args, cb) => {
        let username = args.username,
            password = args.password;

        let promise = new Promise((resolve, reject) => {
            if (!username || !password) {
                cb && cb({
                    success: false,
                    error: 'Username and password are required'
                });

                reject({
                    success: false,
                    error: 'Username and password are required'
                });
            } else {
                Auth.signIn(username, password)
                    .then(user => {
                        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            Auth.completeNewPassword(
                                user,
                                password,
                            ).then(user => {
                                sessionStorage.setItem('prodek:authtoken', user.signInUserSession.idToken.jwtToken);
                                cb && cb(user);
                                resolve(user);
                            }).catch(e => {
                                cb && cb({
                                    success: false,
                                    error: e
                                });
                                reject({
                                    success: false,
                                    error: e
                                });
                            });
                        } else {

                            Auth.currentAuthenticatedUser()
                                .then(user => {
                                    sessionStorage.setItem('prodek:authtoken', user.signInUserSession.idToken.jwtToken);
                                    cb && cb(user);
                                    resolve(user);
                                })
                                .catch(err => {
                                    console.error(err);
                                    cb && cb({
                                        success: false,
                                        error: err
                                    });
                                    reject({
                                        success: false,
                                        error: err
                                    });
                                });
                        }

                    })
                    .catch(err => {
                        console.error(err);
                        cb && cb({
                            success: false,
                            error: err
                        });
                        reject({
                            success: false,
                            error: err
                        });
                    });
            }

        })
        return promise;
    },

    verifiedContact: (args, cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.verifiedContact(args)
                .then(data => {
                    cb && cb(data);
                    resolve(data);
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    signUp: (args, cb) => {
        let username = args.username,
            password = args.password,
            name = args.name,
            email = args.email,
            userID = args.userID,
            phone_number = args.phone_number;

        let promise = new Promise((resolve, reject) => {
            if (!username || !password || !email) {
                if (!username) {
                    cb && cb({
                        success: false,
                        error: 'Username is required'
                    });
                }

                if (!password) {
                    cb && cb({
                        success: false,
                        error: 'Password is required'
                    });
                }

                if (!email) {
                    cb && cb({
                        success: false,
                        error: 'Email is required'
                    });
                }

                if (!userID) {
                    cb && cb({
                        success: false,
                        error: 'User Id is required'
                    });
                }

                reject({
                    success: false,
                    error: 'Username, password and email are required'
                });
            } else {
                Auth.signUp({ username, password, attributes: { email, phone_number: phone_number ? phone_number : '', name, 'custom:userID': userID } })
                    .then(user => {
                        cb && cb(user);
                        resolve(user);
                    })
                    .catch(err => {
                        cb && cb({
                            success: false,
                            error: err
                        });
                        reject({
                            success: false,
                            error: err
                        });
                    });
            }

        })
        return promise;
    },
    resendConfirmCode: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.resendSignUp(args.username)
                .then(() => {
                    cb && cb()
                    resolve()
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    completeNewPasswordChallenge: (args, cb) => {
        let newPassword = args.newPassword;
        let promise = new Promise((resolve, reject) => {
            if (!newPassword) {
                let error = {
                    success: false,
                    error: 'New password required'
                };
                cb && cb(error);
                reject(error);
            } else {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        user.completeNewPasswordChallenge(newPassword)
                            .then(() => {
                                cb && cb(user);
                                resolve(user);
                            }).catch(error => {
                                cb && cb({
                                    success: false,
                                    error: error
                                });
                                reject({
                                    success: false,
                                    error: error
                                });
                            });
                    })
                    .catch(err => {
                        cb && cb({
                            success: false,
                            error: err
                        });
                        reject({
                            success: false,
                            error: err
                        });
                    });
            }
        });
        return promise;
    },
    signOut: (cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.signOut()
                .then(data => {
                    cb && cb(data);
                    resolve(data);
                    sessionStorage.removeItem('prodek:authtoken');
                    store.dispatch({ type:'CLEAR_ACCOUNT'})
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    getAuthenticatedUser: (cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    sessionStorage.setItem('prodek:authtoken', user.signInUserSession.idToken.jwtToken);
                    cb && cb(user);
                    resolve(user);
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    forgotPassword: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.forgotPassword(args.username)
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    forgotPasswordSubmit: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(args.username, args.code, args.newPassword)
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    federatedSignIn: ({ provider }, cb) => {
        return new Promise((resolve, reject) => {
            Auth.federatedSignIn({ provider })
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    changePassword: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, args.oldPassword, args.newPassword);
                })
                .then(data => {
                    resolve(data)
                    cb && cb(data)
                })
                .catch(err => {
                    reject(err)
                    cb && cb(err)
                });
        })
    },
    updateParameters: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.updateUserAttributes(user, args)
                })
                .then(data => {
                    Auth.currentAuthenticatedUser({
                        bypassCache: true
                    })
                        .then(userNew => {
                            resolve(data)
                            cb && cb(data)
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err)
                            cb && cb(err)
                        });
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                    cb && cb(err)
                });
        })
    },
    confirmEmailChange: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.verifyCurrentUserAttributeSubmit('email', args.code)
                .then(data => {
                    Auth.currentAuthenticatedUser({
                        bypassCache: true
                    })
                        .then(userNew => {
                            resolve(data)
                            cb && cb(data)
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err)
                            cb && cb(err)
                        });
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                    cb && cb(err)
                });
        })
    },
    resendEmailConfirmationCode: (cb) => {
        return new Promise((resolve, reject) => {
            Auth.verifyCurrentUserAttribute('email')
                .then(() => {
                    console.log('a verification code is sent');
                    resolve();
                    cb && cb();
                }).catch((e) => {
                    console.log('failed with error', e);
                    reject(e);
                    cb && cb(e);
                });
        })
    }
};



export default auth; 
