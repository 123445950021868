import { VENDOR_QUOTE_UPDATE } from '../actions/vendorQuotesActions.js'
import {
    SET_FILE,
    UPDATE_FILE,
    MARK_FILE_TASK
} from '../actions/filesActions.js'

function file(state = {}, action) {
    let index = -1, file = null;
    switch (action.type) {
        case VENDOR_QUOTE_UPDATE:
            index = state.vendorQuotes.findIndex(vq => vq.id === action.vendorQuote.id)

            if (index >= 0) {
                return {
                    ...state,
                    vendorQuotes:
                        [
                            ...state.vendorQuotes.slice(0, index),
                            {
                                ...action.vendorQuote
                            },
                            ...state.vendorQuotes.slice(index + 1)
                        ]
                }
            }

            return state
        case SET_FILE:
            file = action.file;
            return { ...file }
        case UPDATE_FILE:
            file = action.file;
            if (state.id === file.id) {
                return {
                    //...state,
                    ...file
                }
            }
            return state;

        case MARK_FILE_TASK:
            //TODO: Implementar
            return state;
        default:
            return state;
    }
}

export default file