import React, { Component } from 'react'
import {
    TextField,
    withStyles,
    // InputAdornment,
    Typography,
    Divider
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import ReactTable from "react-table"
import NumberFormat from 'react-number-format'
import classNames from 'classnames'
import i18n from 'i18next'
import { NumberFormatInputCustom } from '../../general'

import Permissions from '../../../lib/permissions'
import moment from 'moment'
import config from '../../../config'

const styles = theme => {
    return (
        {
            preparing__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`,
                marginTop: theme.spacing(),
                marginLeft: theme.spacing()
            },
            preparing__items__table__align: {
                margin: `0 ${theme.spacing()}px ${theme.spacing()}px`,
            },
            quoteitems__table__itemPartNumber: {
                color: theme.palette.naval.main
            },
            quoteitems__table__descriptionExtended: {
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between'
            },
            quoteitems__table__quantity: {
                color: theme.palette.text.secondary
            },
            quoteitems__table__textInput: {
                fontSize: theme.typography.fontSize * 0.8,
            },
            quoteitems__table__cell__center: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            quoteitems__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
            },
            quoteitems__list: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__paper: {
                padding: theme.spacing(),
                marginBottom: theme.spacing(),
            },
            quoteitems__list__item: {
                margin: 0,
                padding: 0
            },
            quoteitems__list__edit__grid__container: {
                marginTop: theme.spacing()
            },
            quoteitems__table__accepted: {
                color: theme.palette.green[100],
                fontWeigth: 'bold'
            },
            quoteItems__table__btn: {
                ...theme.smallButton,
                borderColor: theme.palette.blue[100],
                color: theme.palette.blue[100]
            },
            "quoteItems__table__btn--remove": {
                borderColor: theme.palette.red[100],
                color: theme.palette.red[100]
            },
            quoteItems__table__textField: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
                width: '90%',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginRight: 0,
                }
            },
            quoteItems__table__menu: {
                width: "auto"
            },
            quoteItem__selectVendor__link: {
                ...theme.customLink,
                whiteSpace: 'normal'
            },
            preparing__form__textField: {
                marginTop: theme.spacing()
            },
            clientQuote__noItems: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.orange[80],
                borderRadius: theme.spacing() / 2,
                padding: '0 5px',
                marginLeft: theme.spacing(2)
            },
            extendeDescription__icon: {
                fontSize: '20px',
                color: theme.palette.grey[500]
            }
        })
}

class ClientPOItemsList extends Component {
    state = {
        selectedItem: null,
        items: this.props.items,
        clientQuoteTaxPercent: this.props.clientQuoteTaxPercent,
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.items,
            clientQuoteTaxPercent: nextProps.clientQuoteTaxPercent,
        })
    }


    selectItem = (e, index) => {
        e.stopPropagation()
        const { selectedItem } = this.state;

        this.setState({
            selectedItem: index === selectedItem ? null : index
        })
    }


    renderItemDescription = (cellInfo) => {
        const { classes } = this.props,
            { selectedItem } = this.state;

        let row = cellInfo.original

        return (
            <div>
                {row.vendorPartNumber && row.vendorPartNumber !== row.partNumber ? <>
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${row.partNumber}`}
                    </Typography>
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')} ${row.vendorPartNumber}`}
                    </Typography>
                </> :
                    <Typography className={classes.quoteitems__table__itemPartNumber} >
                        {row.partNumber}
                    </Typography>
                }
                <div
                    className={classes.quoteitems__table__descriptionExtended}
                    style={{ alignItems: selectedItem === cellInfo.index ? 'flex-start' : 'center' }}
                    onClick={(e) => this.selectItem(e, cellInfo.index)}>
                    <Typography color='textSecondary'
                        style={{ whiteSpace: selectedItem === cellInfo.index ? 'pre-line' : 'nowrap' }}
                        noWrap>
                        {row.description}
                    </Typography>
                    {row.description.length > 35 && <span>
                        {selectedItem === cellInfo.index ?
                            <KeyboardArrowUp className={classes.extendeDescription__icon} />
                            :
                            <KeyboardArrowDown className={classes.extendeDescription__icon} />
                        }
                    </span>}
                </div>
            </div>
        )
    }

    renderCostsFields = (cellInfo) => {
        const { items } = this.state,
            { file } = this.props; 

        let itemCost = items[cellInfo.index].amount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0)

        let amountWithProfit = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
            itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100) :
            items[cellInfo.index].amount + ((items[cellInfo.index].amount * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost / cellInfo.original.quantity : 0);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {amountWithProfit > 0 &&
                        <NumberFormat
                            value={amountWithProfit}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{ fontSize: '16px' }}
                        />
                    }
                </div>
            </div>
        )
    }

    renderQuantityField = (cellInfo) => {
        const { classes, editable } = this.props,
            { items } = this.state;

        return (
            <div>
                {editable ?
                    <TextField
                        fullWidth
                        autoComplete='off'
                        variant="outlined"
                        id="quoteAmount"
                        className={classes.preparing__form__textField}
                        label={i18n.t('quotes.preparatingQuoteInformation.quantity')}
                        value={items[cellInfo.index].quantity}
                        onBlur={e => {
                            const newItems = [...items];
                            newItems[cellInfo.index].quantity = e.target.value;

                            this.setState({ items: newItems });
                            this.props.updatePOItems(newItems);
                        }}
                        InputProps={{
                            inputComponent: NumberFormatInputCustom
                        }}
                    />
                    :
                    <div>
                        {items[cellInfo.index].quantity > 0 &&
                            <NumberFormat
                                value={items[cellInfo.index].quantity}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={false}
                                style={{ fontSize: '16px' }}
                            />
                        }
                    </div>
                }
            </div>
        )
    }


    render() {
        const { editable, classes, file } = this.props;
        let { items, clientQuoteTaxPercent } = this.state;

        if (!file || items.length === 0) return null;

        return (
            <div style={{ marginTop: '16px', width: '100%' }} >
                <ReactTable
                    data={items}
                    columns={[
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column1'),
                            id: 'rowIndex',
                            className: classes.quoteitems__table__cell__center,
                            Cell: row => (row.index + 1),
                            width: 40
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column2'),
                            id: 'description',
                            width: editable ? 230 : undefined,
                            Cell: this.renderItemDescription
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.quantity'),
                            width: 100,
                            id: 'quantity',
                            style: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
                            Cell: this.renderQuantityField,
                            Footer: () => {
                                let totalQuantity = items.reduce(((total, row) => parseFloat(total) + parseFloat(row.quantity || 0)), 0);

                                if (totalQuantity === 0) return null;
                                return (
                                    <NumberFormat value={totalQuantity}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        fixedDecimalScale={false}
                                        style={{ fontSize: '16px' }}
                                    />
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column5'),
                            width: 120,
                            id: 'cost',
                            show: Permissions.checkSensitiveInformationPermission() ? true : false,
                            style: { display: 'flex', alignItems: 'center' },
                            className: classes.quoteitems__table__cell__rigth,
                            Cell: this.renderCostsFields,
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column8'),
                            width: 140,
                            id: 'totalColumn',
                            style: {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                textAlign: 'right'
                            },
                            Cell: row => {
                                let quotedAmount = parseFloat(row.original.amount),
                                    itemCost = parseFloat(row.original.amount) + (row.original.weightedCost ? row.original.weightedCost : 0),
                                    profit = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                                        ((itemCost * row.original.markupPercent) / 100) * row.original.quantity : 
                                        ((quotedAmount * row.original.markupPercent) / 100) * row.original.quantity;

                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        {Permissions.checkSensitiveInformationPermission() ? <>
                                            <NumberFormat value={row.original.total - profit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                style={{ fontSize: '16px' }}
                                                fixedDecimalScale={true} />

                                            <NumberFormat value={profit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                style={{ fontSize: '16px' }}
                                                fixedDecimalScale={true} />

                                        </> :
                                            <NumberFormat value={row.original.total}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                                fixedDecimalScale={true}
                                            />
                                        }

                                        {row.original.taxPercent !== 0 && clientQuoteTaxPercent &&
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: '14px' }} color='textSecondary'>{i18n.t('quotes.preparatingQuoteInformation.tax')}</Typography>

                                                <NumberFormat value={(clientQuoteTaxPercent * row.original.total) / 100}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                                    fixedDecimalScale={true} />
                                            </div>
                                        }

                                        <Divider />
                                        <NumberFormat value={row.original.total + (clientQuoteTaxPercent ? ((parseFloat(clientQuoteTaxPercent) * row.original.total) / 100) : 0)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            style={{ fontSize: '16px', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                                            fixedDecimalScale={true}
                                        />

                                    </div>
                                )
                            },
                            Footer: () => {
                                let quotedClientTotalAmount = items.reduce(((total, row) => {
                                    return parseFloat(total) + row.total + (clientQuoteTaxPercent ? ((parseFloat(clientQuoteTaxPercent) * row.total) / 100) : 0)
                                }), 0);

                                if (quotedClientTotalAmount === 0) return null;

                                return (
                                    <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                        <NumberFormat value={quotedClientTotalAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                )
                            }
                        }
                    ]}
                    defaultPageSize={items.length}
                    className={classNames(classes.preparing__items__table, ' -striped -highlight', editable ? classes.preparing__items__table__align : '')}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filtrable={false}
                    resizable={false}
                />
            </div>
        )
    }
}


export default withStyles(styles)(ClientPOItemsList)