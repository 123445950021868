import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

const Emails = {
    getEmails: (args) => {
        return new Promise((resolve, reject) => {
            let {
                usersIds,
                search,
                recordCount,
                offset,
                folderId,
                type,
                currentUser
            } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/emails${recordCount ? `?recordCount=${recordCount}` : '?recordCount=50'}&offset=${offset ? offset : 0}&folderId=${folderId || 1}`;

                if (usersIds) {
                    url += `&users=${usersIds.join(',')}`
                }
                if (search) {
                    url += `&search=${search}`
                }
                if(type){
                    url += `&emailType=${type}`
                }
                if(currentUser){
                    url += `&currentUser=${currentUser}`
                }


                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    changeEmailsFolder: (args) => {
        let { body, search, type } = args || {}; // {emailsIds, folderId}

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/emails?recordCount=50`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                if (search) {
                    url += `&search=${search}`
                }
                if(type){
                    url += `&emailType=${type}`
                }

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(body),
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    markEmail: (args) => {
        return new Promise((resolve, reject) => {
            let {
                read,
                folderId,
                emailId
            } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/emails/${emailId}/markEmail?read=${read}&folderId=${folderId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}

export default Emails;