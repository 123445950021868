import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    CircularProgress,
    withMobileDialog
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    Done,
} from '@material-ui/icons'
import { SlideLeft } from '../../general/Transitions'
import API from '../../../lib/api';


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    map__container: {
        height: '320px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    button__wrapper: {
        margin: `${theme.spacing()}px 0`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    buttonSpinner: theme.buttonSpinner,
    events__form__title__back: {
        fontSize: '30px'
    },
    event__client__icon: {
        fontSize: '11pt',
        marginRight: '5px',
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    form: {
        height: '100%'
    },
    requiredInput: {
        background: '#ffffa3 !important',
        borderBottom: `2px solid ${theme.palette.error.main}`
    },
    event__addressLookup__disabled: {
        color: '#ccc',
        pointerEvents: 'none',
    },
    event__addressLookup: {
        fontSize: '40px',
        paddingRight: theme.spacing(1),
    },
    maps__links__containers: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 20
    },
    maps__link: {
        cursor: 'pointer',
        fontSize: '1em'
    },
    location__error__message__container: {
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    address__focussed: {
        border: `solid thin orange`
    },
    dialog__paper: {
        width: '100%'
    },
    events__editButton: {
        padding: '0 !important',
        textDecoration: 'underline',
        background: 'transparent !important'
    },
});

class ListEventTypeItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id} data-role={item.header ? "list-divider" : ""}
                data-selected={item.selected}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {item.value}
                {item.selected && !item.header ? <Done style={{ color: '#3f97f6' }} /> : ''}
            </li>
        )
    }
}


class EventFormSub1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOrg: props.deal ? props.deal.org_id : null,
            organizations: null,
            persons: null,
            currencies: null,
            selectedCurrency: props.deal ? props.deal.currency : null,
            selectedPerson: props.deal ? props.deal.person_id : null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedOrg: nextProps.deal ? nextProps.deal.org_id : null,
            organizations: nextProps.organizations ? nextProps.organizations : null,
            selectedPerson: nextProps.deal ? nextProps.deal.person_id : null,
            selectedCurrency: nextProps.deal ? nextProps.deal.currency : null,
            currencies: nextProps.currencies ? nextProps.currencies : null
        })
    }

    onEnter = () => {
       const {persons, selectedOrg, selectedPerson} = this.state

       if(!persons && selectedOrg && selectedPerson){
           this.getPersons({organizationId: selectedOrg.id})
       }

    }

    getPersons=({organizationId})=>{
        API.Deals.getDealsOrganizationPersons({organizationId})
            .then( res => {
                if(res.data.records){
                    this.setState({
                        persons: res.data.records.data.map(per => ({
                            ...per,
                            value: per.name
                        }))
                    })
                }
            })
    }

    selectOrganization = (e) => {
        const { organizations } = this.state

        this.getPersons({organizationId: organizations[e.index].id})

        this.setState({
            selectedOrg: organizations[e.index].id
        })

        this.props.setOrganization(organizations[e.index])
        this.props.onClose()
    }

    selectPerson = (e) => {
        const { persons } = this.state

        this.setState({
            selectedPerson: persons[e.index].id
        })

        this.props.setPerson(persons[e.index])
        this.props.onClose()
    }

    selectCurrency = (e) => {
        const { currencies } = this.state

        this.setState({
            selectedCurrency: currencies[e.index].code
        })

        this.props.setCurrency(currencies[e.index].code)
        this.props.onClose()
    }

    render() {
        const {
            classes,
            type,
            fullScreen
        } = this.props,
            { selectedOrg, organizations,
              selectedPerson, persons,
              currencies, selectedCurrency } = this.state;

        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    onEnter={this.onEnter}
                    open={this.props.open || false}
                    TransitionComponent={SlideLeft}
                >
                    <DialogTitle disableTypography={true} className={classes.events__form__title}>
                        <IconButton onClick={this.props.onClose}>
                            <KeyboardArrowLeft
                                color='inherit'
                                className={classes.events__form__title__back} />
                        </IconButton>
                        <Typography variant='body1' color="inherit">
                            {type === 'organization' ? i18n.t('visits.deals.form.selectOrganization') : type === 'person' ? i18n.t('visits.deals.form.selectPersons') : i18n.t('visits.deals.form.selectCurrency') }
                        </Typography>
                        <div style={{ width: '60px' }} />
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        {type === 'organization' && <>
                            { organizations ?
                                <mobiscroll.Form className={classes.form}>
                                    <mobiscroll.FormGroup>
                                        <mobiscroll.Listview
                                            lang="es"
                                            theme="ios"
                                            itemType={ListEventTypeItem}
                                            onItemTap={this.selectOrganization}
                                            data={organizations.map(i => {
                                                if (selectedOrg && i.id === selectedOrg) {
                                                    return {
                                                        ...i,
                                                        selected: true
                                                    }
                                                } else {
                                                    return {
                                                        ...i,
                                                        selected: false
                                                    }
                                                }
                                            })}
                                            select="single"
                                        />
                                    </mobiscroll.FormGroup>
                                </mobiscroll.Form>
                            :
                                <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                            }
                        </>}

                        {type === 'person' && <>
                            { persons ?
                                <mobiscroll.Form className={classes.form}>
                                    <mobiscroll.FormGroup>
                                        <mobiscroll.Listview
                                            lang="es"
                                            theme="ios"
                                            itemType={ListEventTypeItem}
                                            onItemTap={this.selectPerson}
                                            data={persons.map(i => {
                                                if (selectedPerson && i.id === selectedPerson) {
                                                    return {
                                                        ...i,
                                                        selected: true
                                                    }
                                                } else {
                                                    return {
                                                        ...i,
                                                        selected: false
                                                    }
                                                }
                                            })}
                                            select="single"
                                        />
                                    </mobiscroll.FormGroup>
                                </mobiscroll.Form>
                            :
                                <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                            }
                        </>}

                        {type === 'currency' && <>
                            { currencies ?
                                <mobiscroll.Form className={classes.form}>
                                    <mobiscroll.FormGroup>
                                        <mobiscroll.Listview
                                            lang="es"
                                            theme="ios"
                                            itemType={ListEventTypeItem}
                                            onItemTap={this.selectCurrency}
                                            data={currencies.map(i => {
                                                if (selectedCurrency && i.code === selectedCurrency) {
                                                    return {
                                                        ...i,
                                                        selected: true
                                                    }
                                                } else {
                                                    return {
                                                        ...i,
                                                        selected: false
                                                    }
                                                }
                                            })}
                                            select="single"
                                        />
                                    </mobiscroll.FormGroup>
                                </mobiscroll.Form>
                            :
                                <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                            }
                        </>}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


export default withMobileDialog()(withStyles(styles)(EventFormSub1));
