import React, { Component } from 'react';
import {
    Button, Grid,
    Divider,
    Typography,
    TextField,
    Link,
    Paper,
    Dialog,
    Tabs,
    Tab,
    DialogContent,
    DialogActions,
    DialogTitle,
    withStyles,
    CircularProgress,
    InputAdornment
} from '@material-ui/core'
import i18n from 'i18next'
import {
    Drafts, Phone, Star
} from '@material-ui/icons'


import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as vendorQuotesActions from '../../../actions/vendorQuotesActions'
import * as generalActions from '../../../actions/generalActions'
import * as vendorsActions from '../../../actions/vendorsActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'


import API from '../../../lib/api'
import moment from 'moment'
import classNames from 'classnames'
import auth from '../../../authentication/auth';

import { NoteForm, Note, NoteForm as NoteFormEmail } from '../../notes'
import { VendorQuoteItems, VendorQuoteTotals } from '.'
import { OtherCosts } from '../common'
import { SlideUp } from '../../general/Transitions'
import { VendorQuotesActions } from '.'
import { NoteEditDocuments } from '../common'
import { ConfirmationReturnStatusDialog, CustomSelect, CustomMessages, NumberFormatInputCustom } from '../../general'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import {
    Vendor,
    ManageVendorContactsPopup
} from '../../vendors'




const styles = theme => ({
    customLink: theme.customLink,
    vendor__form__VendorRFQStatus: {
        margin: `${theme.spacing(2)}px 0`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',

        }
    },
    vendor__form__textField: {
        margin: `${theme.spacing()}px 0`,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    vendorrfq__form__input: {
        fontSize: '0.9em',
    },
    vendorrfq__form__input__description: {
        fontSize: '0.9em',
        padding: '10px',
    },
    vendor__form__save__button: {
    },
    vendor__form__save_as_ready__button: {
        marginLeft: theme.spacing()
    },
    vendor__form__delete__button: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        margin: '0 0 5px 5px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    vendor__form__client__readonlyinfo: {
        marginBottom: theme.spacing(),
        marginTop: theme.spacing()
    },
    vendor__editActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        minHeight: theme.spacing(4)
    },
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        position: 'relative'
    },
    vendorQuoteForm__title__closeButton: {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        padding: '5px'
    },
    oportunity__probability__label: {
        border: `solid thin ${theme.palette.secondary.main}`,
        fontSize: '8pt',
        borderRadius: theme.spacing() / 2,
        padding: theme.spacing() / 2,
        color: theme.palette.text.secondary,
        textAlign: 'center',
        width: '80px',
        lineHeight: 1
    },
    'oportunity__probability__label--unknow': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.getContrastText(theme.palette.grey[100])
    },
    'oportunity__probability__label--likely': {
        backgroundColor: theme.palette.seabook.main,
        color: theme.palette.getContrastText(theme.palette.seabook.main)
    },
    'oportunity__probability__label--verylikely': {
        backgroundColor: theme.palette.green[100],
        color: theme.palette.getContrastText(theme.palette.green[100])
    },
    vendorQuote__rfqSummary__container: {
        marginTop: theme.spacing(),
        padding: `${theme.spacing()}px 0`
    },
    vendorQuote__addNote__button: {
        ...theme.customLink,
        marginRight: theme.spacing()
    },
    vendorQuote__file__button: {
        ...theme.customLink,
        marginRight: theme.spacing()
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    rfqSummary__hader__container__clientName__label: {
        display: 'flex',
        alignItems: 'center'
    },
    vendorQuote__tab__root: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100]
    },
    note__tab__root: {
        minWidth: '60px',
        backgroundColor: theme.palette.grey[100],
        marginLeft: 'auto'
    },
    'vendorQuote__tabs--selected': {
        backgroundColor: '#fff',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: 'none',
        color: theme.palette.text.primary,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        zIndex: 2
    },
    vendorQuotes__tabs: {
        zIndex: 4,
        marginBottom: 0
    },
    vendorQuotes__tabs__activeIndicator: {
        backgroundColor: 'transparent'
    },
    vendorQuote__tab__label: {
        padding: `0px ${theme.spacing()}px`
    },
    notes__paper: {
        padding: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    vendorQuote__addNote__center: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        width: '100%'
    },
    dialog__paper: {
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        height: '100%'
    },
    rfq__clientcontact__message__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-5px'
    },
    vendorRFQ__icon__phone: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    subTitle: {
        color: theme.palette.blue[100]
    },
    vendor__contact__title: {
        color: theme.palette.blue[100]
    },
    subaction__bar__actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        marginLeft: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    centerCell: {
        display: 'flex',
        alignItems: 'center'
    }
});


const defaultVendorContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: null
}

const defaultVendor = {
    name: '',
    address: {
        address1: '',
        address2: ''
    },
    phone1: '',
    phone2: '',
    website: '',
    remarks: '',
    id: null
}

const probabilities = ['unknow', 'likely', 'verylikely'];

class VendorQuoteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            vendorContacts: null,
            vendorQuoteOtherCosts: [],
            comments: `1. FOB _______\n2. Estimated Total Weight _______\n3. Estimated Delivery Time _______ \n4. Estimated Inland Freight _______ \n5. Validity of Quotation _______`,
            selectedVendor: props.vendorQuote ? props.vendorQuote.vendor : null,
            deliveryDays: null,
            selectedVendorContact: props.vendorQuote ? props.vendorQuote.contact : defaultVendorContact,
            validateVendor: false,
            validateVendorContact: false,
            openNewNote: false,
            openConfirmationDialog: false,
            apiErrorMessage: null,
            message: '',
            confirmLabel: '',
            cancelLabel: '',
            note: null,
            logedInUser: null,
            vendorQuote: props.vendorQuote || null,
            notes: props.vendorQuote ? props.vendorQuote.notes : [],
            showRequiredFieldsError: false,
            reloadingVendors: false,
            currentTab: 0,
            openNewNoteEmail: false,
            selectedQuoteTerm: props.vendorQuote ? props.vendorQuote.quoteTermsId : null,
            quoteTermsReference: props.vendorQuote ? props.vendorQuote.quoteTermsReference : null,
            selectedPaymentTerm: props.vendorQuote ? props.vendorQuote.paymentTermsId : null,
            searchVendor: props.vendorQuote && props.vendorQuote.vendor ? props.vendorQuote.vendor.name : null,
            saveAsReady: false,
            showAddNote: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            validateVendorQuote: nextProps.changeStatusOnSave === true ? true : false
        })
    }

    onExited = () => {
        this.setState({
            selectedVendor: null
        })
        this.props.vendorsActions.setVendors([]);
        this.props.vendorsActions.setVendor({});
        this.props.generalActions && this.props.generalActions.showEditVendorQuote(false);
    }

    componentDidMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    setVendorQuoteInfo = (vendorQuote, file) => {
        let vendorItem = {},
            newItems = vendorQuote && vendorQuote.status.id === 1 ? file.items.map((item, i) => {
                vendorItem = vendorQuote.items.find(vqi => vqi.rfqItemId === item.id);
                if (vendorItem) {
                    return {
                        ...vendorItem,
                        include: true
                    }
                }
                return {
                    ...item,
                    rfqItemId: item.id,
                    references: item.partNumber
                }
            }) : vendorQuote && vendorQuote.status.id > 1 ?
                    vendorQuote.items.map((vq, i) => {
                        return {
                            ...vq,
                            include: true
                        }
                    })
                    : file.items.map((item, i) => {
                        return {
                            ...item,
                            include: true,
                            rfqItemId: item.id,
                            references: item.partNumber
                        }
                    });

        this.setState({
            deliveryDays: vendorQuote && vendorQuote.deliveryDays ? vendorQuote.deliveryDays : null,
            comments: vendorQuote && vendorQuote.comments ? vendorQuote.comments : !vendorQuote ? `1. FOB _______\n2. Estimated Total Weight _______\n3. Estimated Delivery Time _______ \n4. Estimated Inland Freight _______ \n5. Validity of Quotation _______` : null,
            vendorQuoteOtherCosts: vendorQuote ? vendorQuote.otherCosts : [],
            items: newItems,
            notes: vendorQuote.notes || [],
            selectedVendor: vendorQuote ? vendorQuote.vendor : defaultVendor,
            selectedVendorContact: vendorQuote ? vendorQuote.contact : defaultVendorContact,
            selectedQuoteTerm: vendorQuote ? vendorQuote.quoteTermsId : null,
            quoteTermsReference: vendorQuote ? vendorQuote.quoteTermsReference : null,
            selectedPaymentTerm: vendorQuote ? vendorQuote.paymentTermsId : null
        })
    }

    resetVendorQuoteInfo = (file) => {
        this.setState({
            selectedVendor: null,
            comments: `1. FOB _______\n2. Estimated Total Weight _______\n3. Estimated Delivery Time _______ \n4. Estimated Inland Freight _______ \n5. Validity of Quotation _______`,
            selectedVendorContact: null,
            validateVendor: false,
            validateVendorContact: false,
            vendorQuoteOtherCosts: null,
            openConfirmationDialog: false,
            deliveryDays: null,
            notes: [],
            message: '',
            confirmLabel: '',
            cancelLabel: '',
            validateVendorQuote: false,
            showRequiredFieldsError: false,
            selectedQuoteTerm: null,
            quoteTermsReference: null,
            selectedPaymentTerm: null,
            items: file ? file.items.map((item, i) => {
                return {
                    ...item,
                    comments: null,
                    include: true,
                    rfqItemId: item.id,
                    references: item.partNumber
                }
            }) : []
        })
    }



    handleVendorContactChange = (selectedContact) => {
        const { selectedVendor } = this.state;
        if (selectedContact) {
            this.setState({
                selectedVendorContact: selectedVendor && selectedVendor.contacts ? selectedVendor.contacts.find(vc => vc.id === selectedContact.value) : null
            })
        } else {
            this.setState({
                selectedVendorContact: defaultVendorContact
            })
        }
    }

    validateParameters = () => {

        const { items, selectedVendor, selectedVendorContact, deliveryDays, saveAsReady, selectedPaymentTerm, selectedQuoteTerm, quoteTermsReference } = this.state,
            { vendorQuote } = this.props;
        let valid = true;
        this.setState({
            validateVendorQuote: true,
            showRequiredFieldsError: false
        })

        if ((selectedVendor && selectedVendor.id === null) ||
            (selectedVendorContact && selectedVendorContact.id === null) ||
            !selectedVendor || !selectedVendorContact ||
            (vendorQuote && vendorQuote.status.id === 2 && (!deliveryDays || !selectedPaymentTerm || !selectedQuoteTerm || !quoteTermsReference ))) {
            valid = false
        }
        if (saveAsReady) {
            if ((items.filter(item => !item.deleted && !item.quantity).length > 0) || !deliveryDays || !selectedPaymentTerm || !selectedQuoteTerm || !quoteTermsReference) {
                valid = false
            }
        }
        if (!valid) {
            this.setState({
                showRequiredFieldsError: true
            })
        }

        return valid
    }

    changeVendorRFQStatus = ({ status, returnComments, vendorQuote }) => {

        if (vendorQuote.status.id === 1 && status === 2 && vendorQuote.items.filter(item => !item.deleted).length === 0) {
            this.setState({
                saving: false,
                apiErrorMessage: `${i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage1To2')}`
            })
            return
        }

        if (vendorQuote.items.filter(item => !item.deleted && !item.quantity).length > 0 && vendorQuote.status.id === 2 && status === 3) {
            this.setState({
                saving: false,
                apiErrorMessage: `${i18n.t('quotes.vendorQuoteRequest.changeVendorQuoteStatusMessage2To3')}`
            })
            return
        }

        this.setState({
            saving: true,
        })

        let body = {
            id: vendorQuote.id,
            statusId: status
        }

        if (returnComments) {
            body.returnReasonComments = returnComments
        }

        API.Quotes.changeStatusVendorRFQ({ body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    saveAsReady: false,
                    comments: null,
                    vendorsUpdated: false
                })
                this.props.updateVendorQuote({ file: res.data.record });
            } else {
                this.setState({
                    saving: false,
                    saveAsReady: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    handleVendorRFQ = () => {
        if (this.validateParameters()) {

            this.setState({
                validateVendorQuote: false,
                saving: true
            })

            const { file, vendorQuote } = this.props,
                { selectedVendor,
                    selectedVendorContact,
                    comments,
                    items,
                    deliveryDays,
                    vendorQuoteOtherCosts,
                    selectedQuoteTerm,
                    quoteTermsReference,
                    selectedPaymentTerm,
                    saveAsReady
                } = this.state;

            let body = {
                id: vendorQuote && vendorQuote.id ? vendorQuote.id : null,
                fileId: file && file.id ? file.id : null,
                vendorId: selectedVendor.id,
                vendorContactId: selectedVendorContact.id,
                deliveryDays: deliveryDays,
                quoteTermsReference: quoteTermsReference,
                paymentTermsId: selectedPaymentTerm,
                quoteTermsId: selectedQuoteTerm,
                comments: comments,
                otherCosts: vendorQuoteOtherCosts ? vendorQuoteOtherCosts.map((voc, i) => {
                    return {
                        otherCostId: voc.id ? voc.id : voc.otherCostId,
                        amount: voc.amount,
                        comments: voc.comments,
                        deleted: voc.deleted
                    }
                }) : [],
                items: items.map((item, i) => {
                    return {
                        id: vendorQuote ? item.id : null,
                        rfqItemId: item.rfqItemId,
                        quantity: item.quantity,
                        unitAmount: item.unitAmount || 0,
                        description: item.description,
                        deliveryDays: item.deliveryDays,
                        references: item.references,
                        include: item.include,
                        comments: item.comments
                    }
                })
            }


            if (!vendorQuote) {
                API.Quotes.createVendorRFQ({ body }).then(res => {
                    if (res.data) {
                        if (saveAsReady) {//(this.props.changeStatusOnSave) {
                            console.log('guardando como ready')
                            this.changeVendorRFQStatus({ status: 3, vendorQuote: res.data.record });
                        } else {
                            this.setState({
                                saving: false,
                                comments: null,
                                vendorsUpdated: false
                            })
                            this.props.updateVendorQuote({ file: res.data.record, vendorQuote: null })
                        }

                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                    if (!this.props.dontCloseAfterSaving) {
                        this.props.handleClose()
                    }
                })
            } else {
                API.Quotes.updateVendorRFQ({ body }).then(res => {
                    if (res.data) {
                        if (saveAsReady) {//(this.props.changeStatusOnSave) {
                            this.changeVendorRFQStatus({ status: 3, vendorQuote: res.data.record.vendorQuotes.find(vq => vq.id === vendorQuote.id) });
                        } else {
                            this.setState({
                                saving: false,
                                comments: null,
                                vendorsUpdated: false
                            })
                            this.props.updateVendorQuote({ file: res.data.record, vendorQuote: res.data.record.vendorQuotes.find(vq => vq.id === vendorQuote.id) })
                        }
                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                    if (!this.props.dontCloseAfterSaving) {
                        this.props.handleClose()
                    }
                })
            }
        }
    }

    confirmDeleteVendorQuote = () => {
        this.setState({
            cancelLabel: i18n.t('quotes.vendorQuoteRequest.confirmation.cancelDelete'),
            confirmLabel: i18n.t('quotes.vendorQuoteRequest.confirmation.confirmDeleteLabel'),
            message: i18n.t('quotes.vendorQuoteRequest.confirmation.confirmDeleteVendorQuoteMessage'),
            openConfirmationDialog: true
        })
    }

    cancelConfirmationReturn = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    deleteVendorQuote = ({ comments }) => {
        const { vendorQuote } = this.props
        this.setState({
            saving: true
        })
        API.Quotes.deleteVendorRFQ({ vendorQuoteId: vendorQuote.id, comments }).then(res => {
            if (res.status === 'success') {
                this.setState({
                    openConfirmationDialog: false,
                    saving: false
                })
                this.onExited();

                this.props.vendorQuotesActions.deleteVendorQuote(vendorQuote)

                this.props.deleteVendorQuote(vendorQuote)
                this.props.handleClose()
            } else {
                this.setState({
                    openConfirmationDialog: false,
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }
    refreshVendorAndContacts = () => {
        const { vendor } = this.props;
        this.setState({
            selectedVendor: vendor || defaultVendor,
            vendorContacts: vendor.contacts || [],
        })
    }
    changeVendor = (selectedVendor) => {
        this.setState({
            selectedVendor: selectedVendor || defaultVendor,
            vendorContacts: selectedVendor ? selectedVendor.contacts : [],
            selectedVendorContact: selectedVendor ? (this.state.selectedVendor && this.state.selectedVendor.id === selectedVendor.id ? this.state.selectedVendorContact : defaultVendorContact) : defaultVendorContact,
            unsavedChanges: true
        })
    }
    changeVendorContact = (selectedVendorContact) => {
        this.setState({
            unsavedChanges: true,
            selectedVendorContact,
        })
    }


    closeNewNote = () => {
        this.setState({
            openNewNote: false,
            openNewNoteEmail: false
        })
    }

    handelNoteSaved = (note) => {
        const { notes } = this.state;
        let newNotes = [...notes],
            newVendorQuote = { ...this.state.vendorQuote };

        newNotes.unshift(note)

        this.setState({
            notes: newNotes
        })

        newVendorQuote.notes = newNotes

        this.props.updateVendorQuote({ vendorQuote: newVendorQuote })
        this.closeNewNote()
    }

    updateNote = (note) => {
        const { notes } = this.state;
        let newNotes = [...notes],
            newVendorQuote = { ...this.state.vendorQuote },
            index = newNotes.findIndex(n => n.id === note.id);

        newNotes[index] = note;

        this.setState({
            notes: newNotes
        })

        newVendorQuote.notes = newNotes

        this.props.updateVendorQuote({ vendorQuote: newVendorQuote })

        this.closeNewNote()
    }

    deleteNote = (noteId) => {
        const { notes } = this.state;
        let newNotes = [...notes],
            newVendorQuote = { ...this.state.vendorQuote },
            index = newNotes.findIndex(n => n.id === noteId);

        newNotes.splice(index, 1);

        this.setState({
            notes: newNotes
        })

        newVendorQuote.notes = newNotes

        this.props.updateVendorQuote({ vendorQuote: newVendorQuote })
        this.closeNewNote()
    }

    openNewNoteDialog = (e, note) => {
        e.stopPropagation()
        this.setState({
            note: note ? note : null,
            openNewNote: true
        })
    }


    changeTab = (e, value) => {
        this.setState({
            currentTab: value
        })
    }

    selectPaymentTerms = (pt) => {
        if (pt) {
            this.setState({
                unsavedChanges: true,
                selectedPaymentTerm: pt.value
            });

        } else {
            this.setState({
                unsavedChanges: true,
                selectedPaymentTerm: null
            });
        }
    }

    selectQuoteTerms = (qt) => {
        if (qt) {
            this.setState({
                unsavedChanges: true,
                selectedQuoteTerm: qt.value
            });

        } else {
            this.setState({
                unsavedChanges: true,
                selectedQuoteTerm: null
            });
        }
    }
    onEnter = () => {
        if (!this.props.vendorQuote) {
            this.resetVendorQuoteInfo(this.props.file)
        } else {
            this.setVendorQuoteInfo(
                this.props.vendorQuote,
                this.props.file
            )
        }
    }

    editVendorQuote = () => {
        const { file, vendorQuote } = this.props;

        if (file.status > 2 && vendorQuote && vendorQuote.status.id === 3) {
            this.setState({
                showAddNote: true
            })
        } else {
            this.setState({
                saveAsReady: false
            }, _ => {
                this.handleVendorRFQ()
            })
        }
    }

    closeNote = () => {
        this.setState({
            showAddNote: false,
        })
    }

    render() {
        const { classes, file, vendorQuote, showFileLink } = this.props,
            {
                items, note,
                comments,
                saving,
                message,
                selectedVendor,
                selectedVendorContact,
                validateVendorQuote,
                vendorQuoteOtherCosts,
                openConfirmationDialog,
                confirmLabel,
                cancelLabel,
                apiErrorMessage,
                logedInUser,
                currentTab,
                openNewNote,
                deliveryDays,
                notes,
                reloadingVendors,
                openNewNoteEmail,
                selectedQuoteTerm,
                quoteTermsReference,
                selectedPaymentTerm,
                showAddNote
            } = this.state;

        if (!file) return null;

        let vendorContacts = this.state.vendorContacts ? this.state.vendorContacts.filter(c => c.active) : [];

        return (

            <GlobalParametersContext.Consumer>
                {({ paymentTerms, quoteTerms, }) =>
                    <Dialog
                        maxWidth={'md'}
                        classes={{
                            paper: classes.dialog__paper
                        }}
                        fullWidth={true}
                        TransitionComponent={SlideUp}
                        aria-labelledby="vendor-quote-form"
                        open={this.props.open || false}
                        onExited={this.onExited}
                        onEnter={this.onEnter}
                    >
                        <DialogTitle id="vendor-quote-form" disableTypography={true}>
                            {i18n.t('quotes.details.file')} {file.id} - {vendorQuote ? `${i18n.t('quotes.vendorQuoteRequest.vendorQuote')} ${vendorQuote.id}` : `${i18n.t('quotes.vendorQuoteRequest.new')}`}
                        </DialogTitle>

                        <DialogContent>
                            <div>
                                {/* Client RFQ summary */}
                                <Grid container spacing={2} className={classes.vendorQuote__rfqSummary__container}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant='body2' color="inherit"
                                            className={classes.rfqSummary__hader__container__clientName__label}>
                                            {showFileLink ?
                                                <Link
                                                    variant='button'
                                                    disabled={saving}
                                                    className={classes.vendorQuote__file__button}
                                                    onClick={() => this.props.handleShowQuote(file.id)}>
                                                    {file ? `${i18n.t('quotes.vendorQuoteRequest.file')} ${file.id}` : ''}
                                                </Link>
                                                :
                                                `${i18n.t('quotes.vendorQuoteRequest.file')} ${file.id} `
                                            }
                                            - {file.client.name}
                                        </Typography>

                                        <Typography color='textSecondary'>
                                            {i18n.t('quotes.requestedByClient.created')} {`${moment(file.createDateTime).format('MMM Do, YYYY')}`}
                                        </Typography>

                                        {file.oportunity && file.oportunity.name && (
                                            <Typography variant='body2' color='textSecondary' className={classNames(classes.oportunity__probability__label, classes[`oportunity__probability__label--${probabilities[file.probability.id - 1]}`])}>
                                                {file.oportunity.name ? file.oportunity.name : ''}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={5} sm={5}>
                                        <Typography variant='body2' color="textSecondary">
                                            {file.requestForQuote.name}
                                        </Typography>
                                    </Grid>


                                </Grid>
                                {((vendorQuote && file && file.status === 2) || (vendorQuote && file && file.status !== 2 && vendorQuote.status.id !== 3)) && (<>
                                    <Divider />
                                    <Grid container spacing={2} className={classes.vendorQuote__rfqSummary__container} style={{ marginBottom: '8px' }}>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <VendorQuotesActions
                                                file={file}
                                                editable={true}
                                                fromEditForm={true}
                                                vendorQuote={vendorQuote}
                                                updateVendorQuote={this.props.updateVendorQuote}
                                                deleteVendorQuote={(vendorQuote) => {
                                                    this.props.vendorQuotesActions.deleteVendorQuote(vendorQuote)
                                                    this.props.deleteVendorQuote(vendorQuote)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>)}

                                {vendorQuote && (
                                    <Tabs
                                        value={currentTab}
                                        onChange={this.changeTab}
                                        className={classes.vendorQuotes__tabs}
                                        indicatorColor="primary"
                                        textColor="secondary"
                                        classes={{
                                            indicator: classes.vendorQuotes__tabs__activeIndicator
                                        }}
                                    >
                                        <Tab
                                            label={i18n.t('quotes.vendorQuoteRequest.vendorQuote')}
                                            classes={{
                                                labelContainer: classes.vendorQuote__tab__label,
                                                root: classes.vendorQuote__tab__root,
                                                selected: classes['vendorQuote__tabs--selected']
                                            }}
                                        />

                                        <Tab
                                            label={i18n.t('quotes.vendorQuoteRequest.notes')}
                                            classes={{
                                                labelContainer: classes.vendorQuote__tab__label,
                                                root: classes.note__tab__root,
                                                selected: classes['vendorQuote__tabs--selected']
                                            }}
                                        />
                                    </Tabs>
                                )}


                                {currentTab === 0 &&
                                    <>
                                        <Paper className={classes.paper}>
                                            {/* Vendor RFQ readOnly */}
                                            {/* {vendorQuote && vendorQuote.status.id !== 1 && (
                                                <Grid container spacing={2} className={classes.vendor__form__client__readonlyinfo}>

                                                    <Grid item sm={12} xs={12}>
                                                        <Grid container spacing={2} justify="space-between">
                                                            <Grid item xs={12} sm={6}>
                                                                <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendor')}</Typography>

                                                                <Typography variant='body2'>{vendorQuote.vendor.name}</Typography>
                                                                <Typography variant='body2' noWrap>{vendorQuote.vendor.address.address1}</Typography>
                                                                <Typography variant='body2' noWrap>{vendorQuote.vendor.address.address2}</Typography>
                                                                <Typography variant='body2'>{vendorQuote.vendor.website}</Typography>
                                                                {vendorQuote.vendor.phone1 &&
                                                                    <Typography variant='body2' noWrap>
                                                                        <Phone className={classes.vendorRFQ__icon__phone} />
                                                                        {vendorQuote.vendor.phone1}
                                                                    </Typography>
                                                                }
                                                                {vendorQuote.vendor.phone2 &&
                                                                    <Typography variant='body2' noWrap>
                                                                        +<Phone className={classes.vendorRFQ__icon__phone} />
                                                                        {vendorQuote.vendor.phone2}
                                                                    </Typography>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} zeroMinWidth>
                                                                <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendorContact')}</Typography>

                                                                <Typography variant='body2' color='textSecondary' noWrap>{vendorQuote.contact.firstName} {vendorQuote.contact.lastName}</Typography>
                                                                {vendorQuote.contact.email && (
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                        <Drafts className={classNames(classes.customLink, classes.rfq__clientcontact__message__icon)}
                                                                            onClick={() => this.setState({
                                                                                openNewNoteEmail: true,
                                                                            })} />
                                                                        <Typography
                                                                            variant='body2'
                                                                            color='textSecondary'
                                                                            className={classes.customLink} onClick={() => this.setState({
                                                                                openNewNoteEmail: true,
                                                                            })} noWrap>{vendorQuote.contact.email}
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                                <Typography variant='body2' color='textSecondary' noWrap>
                                                                    <Phone className={classes.vendorRFQ__icon__phone} />
                                                                    {vendorQuote.contact.phone}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            )} */}


                                            {/* Vendor Section */}
                                            {(!vendorQuote || (vendorQuote && vendorQuote.status.id >= 1)) && (
                                                <>
                                                    <Grid container spacing={3}>
                                                        <Grid item sm={6} xs={12}>
                                                            <Vendor
                                                                vendorQuote={vendorQuote}
                                                                changeVendor={this.changeVendor}
                                                                validateVendorQuote={validateVendorQuote} />
                                                        </Grid>


                                                        {!(selectedVendor && selectedVendor.id) && (
                                                            <Grid item sm={6} xs={12}>
                                                                <div className={classes.vendor__editActions}>
                                                                    <Typography variant='h6' className={classes.vendor__contact__title}>{i18n.t('quotes.vendorQuoteRequest.vendorContact')}</Typography>
                                                                </div>
                                                                <Typography color="textSecondary">{i18n.t('quotes.vendorQuoteRequest.selectVendorFirst')}</Typography>
                                                                {validateVendorQuote && ((!selectedVendorContact) || (selectedVendorContact && !selectedVendorContact.id)) && (
                                                                    <Typography color='error'>{i18n.t('quotes.vendorQuoteRequest.vendorContactsRequired')}</Typography>
                                                                )}
                                                            </Grid>
                                                        )}




                                                        {(selectedVendor && selectedVendor.id) && (
                                                            <Grid item sm={6} xs={12}>
                                                                {/* contact manage */}
                                                                <div className={classes.vendor__editActions} >
                                                                    <Typography variant='h6' className={classes.vendor__contact__title}>{i18n.t('quotes.vendorQuoteRequest.vendorContact')}</Typography>
                                                                    <ManageVendorContactsPopup
                                                                        vendor={selectedVendor}
                                                                        selectedVendorContact={selectedVendorContact}
                                                                        changeVendorContact={this.changeVendorContact}
                                                                        refreshVendorAndContacts={this.refreshVendorAndContacts}
                                                                        handleVendorContactChange={this.handleVendorContactChange}
                                                                        onClose={this.refreshVendorAndContacts} />
                                                                </div>


                                                                {/* Vendor contact section */}
                                                                {vendorContacts && vendorContacts.length > 0 &&
                                                                    <Grid container>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <CustomSelect
                                                                                options={vendorContacts.map(contact => {
                                                                                    return {
                                                                                        value: contact.id,
                                                                                        icon: contact.favorite ? <Star color={'primary'} style={{ fontSize: '16px', marginRight: '8px' }} /> : '',
                                                                                        label: `${contact.firstName} ${contact.lastName}`
                                                                                    }
                                                                                })}
                                                                                placeholder={i18n.t('vendors.selectVendorContact')}
                                                                                label={i18n.t('quotes.vendorQuoteRequest.selectVendorContact')}
                                                                                value={selectedVendorContact ? selectedVendorContact.id : 0}
                                                                                fullWidth={true}
                                                                                required={true}
                                                                                disabled={reloadingVendors}
                                                                                error={validateVendorQuote && ((!selectedVendorContact) || (selectedVendorContact && !selectedVendorContact.id)) ? true : false}
                                                                                onSelect={this.handleVendorContactChange}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                {selectedVendorContact && selectedVendorContact.id &&
                                                                    <>
                                                                        <Typography variant='body2' color="textSecondary">
                                                                            {`${selectedVendorContact.firstName} ${selectedVendorContact.lastName}`}
                                                                        </Typography>
                                                                        {selectedVendorContact.email && (
                                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                                <Drafts className={classNames(classes.customLink, classes.rfq__clientcontact__message__icon)}
                                                                                    onClick={() => this.setState({
                                                                                        openNewNoteEmail: true,
                                                                                    })} />
                                                                                <Typography
                                                                                    variant='body2'
                                                                                    color='textSecondary'
                                                                                    className={classes.customLink} onClick={() => this.setState({
                                                                                        openNewNoteEmail: true,
                                                                                    })} noWrap>{selectedVendorContact.email}
                                                                                </Typography>
                                                                            </div>
                                                                        )}
                                                                        {selectedVendorContact.phone && (
                                                                            <Typography variant='body2' color="textSecondary">
                                                                                <Phone className={classes.vendorRFQ__icon__phone} />
                                                                                {selectedVendorContact.phone}
                                                                            </Typography>
                                                                        )}
                                                                    </>
                                                                }



                                                                {selectedVendor && selectedVendor.id && vendorContacts && vendorContacts.length === 0 && (
                                                                    <Typography color="textSecondary">{selectedVendor.name} {i18n.t('quotes.vendorQuoteRequest.noVendorContacts')}
                                                                        <br />
                                                                        <Link className={classes.customLink} onClick={() => {
                                                                            this.props.generalActions.showCreateVendorContact(true)
                                                                        }}>
                                                                            {i18n.t('quotes.vendorQuoteRequest.clickToAddContact')}
                                                                        </Link>
                                                                    </Typography>

                                                                )}

                                                                {validateVendorQuote && (!vendorContacts || (vendorContacts && vendorContacts.length === 0)) && (
                                                                    <Typography color='error'>{i18n.t('quotes.vendorQuoteRequest.vendorContactsRequired')}</Typography>
                                                                )}
                                                            </Grid>
                                                        )}

                                                    </Grid>
                                                </>
                                            )}


                                            {/* Vendor Quote comments */}
                                            <Grid container spacing={2}>

                                                <Grid item xs={12} style={{ marginTop: '16px', padding: '0 8px' }}>
                                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.vendorQuoteRequest.vendorQuote')}</Typography>
                                                </Grid>

                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        autoComplete='off'
                                                        id="vendor-remarks-multiline"
                                                        label={i18n.t('quotes.vendorQuoteRequest.quoteComments')}
                                                        className={classes.vendor__form__textField}
                                                        value={comments ? comments : ''}
                                                        disabled={saving} // || (vendorQuote && vendorQuote.status.id === 3)}
                                                        multiline
                                                        onChange={event => {
                                                            this.setState({
                                                                comments: event.target.value
                                                            });
                                                        }}
                                                        InputProps={{
                                                            className: classes.vendorrfq__form__input__description,
                                                        }}
                                                        rowsMax='10'
                                                        rows='6'
                                                        fullWidth
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                {vendorQuote && vendorQuote.status.id >= 2 &&
                                                    <Grid item sm={4}>

                                                        <Grid container spacing={2} direction='row'>
                                                            <Grid item xs={6} >
                                                                <CustomSelect options={paymentTerms ? quoteTerms.map(pt => ({ label: pt.quoteTermsName, value: pt.quoteTermsId })) : []}
                                                                    label={i18n.t('quotes.quoteRequest.quoteTerms')}
                                                                    fullWidth
                                                                    required
                                                                    error={validateVendorQuote && !selectedQuoteTerm}
                                                                    disabled={saving} // || (vendorQuote && vendorQuote.status.id === 3)}
                                                                    value={selectedQuoteTerm ? selectedQuoteTerm : null}
                                                                    onSelect={this.selectQuoteTerms}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    autoComplete='off'
                                                                    className={classes.vendor__form__textField}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    fullWidth
                                                                    required
                                                                    InputProps={{
                                                                        className: classes.vendorrfq__form__input
                                                                    }}
                                                                    onBlur={e => {
                                                                        this.setState({
                                                                            unsavedChanges: this.state.quoteTermsReference !== e.target.value.trim() ? true : this.state.unsavedChanges,
                                                                            quoteTermsReference: e.target.value.trim()
                                                                        });
                                                                    }}
                                                                    error={validateVendorQuote && !quoteTermsReference}
                                                                    disabled={saving} //|| (vendorQuote && vendorQuote.status.id === 3)}
                                                                    defaultValue={quoteTermsReference ? quoteTermsReference : ''}
                                                                    type="text"
                                                                    id="quoteTermsReferences"
                                                                    variant="outlined"
                                                                    label={i18n.t('quotes.quoteRequest.quoteTermsReferences')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={0} direction='row'>
                                                            <Grid item xs={12} >
                                                                <CustomSelect
                                                                    options={paymentTerms ? paymentTerms.map(pt => ({ label: pt.paymentTermsName, value: pt.paymentTermsId })) : []}
                                                                    label={i18n.t('quotes.quoteRequest.paymentTerms')}
                                                                    fullWidth
                                                                    required
                                                                    error={validateVendorQuote && !selectedPaymentTerm}
                                                                    disabled={saving} //|| (vendorQuote && vendorQuote.status.id === 3)}
                                                                    optionsStyle={{
                                                                        minWidth: '200px',
                                                                    }}
                                                                    value={selectedPaymentTerm ? selectedPaymentTerm : null}
                                                                    onSelect={this.selectPaymentTerms}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Grid item sm={2} xs={12}>
                                                    {vendorQuote && vendorQuote.status.id >= 2 &&
                                                        <TextField
                                                            className={classes.vendor__form__textField}
                                                            autoComplete='off'
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            error={validateVendorQuote && !deliveryDays}
                                                            id="vendor_quote_delivery_days"
                                                            // disabled={vendorQuote.status.id === 3}
                                                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.delivery')}
                                                            name="otherCostAmount"
                                                            onChange={event => {
                                                                this.setState({
                                                                    deliveryDays: event.target.value
                                                                });
                                                            }}
                                                            value={deliveryDays ? deliveryDays : ''}
                                                            InputProps={{
                                                                className: classes.vendorrfq__form__input,
                                                                endAdornment: <InputAdornment position="end" >
                                                                    {i18n.t('quotes.vendorQuoteRequest.itemsTable.days')}
                                                                </InputAdornment>,
                                                                inputComponent: NumberFormatInputCustom,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>

                                            {/* other costs */}
                                            {vendorQuote && vendorQuote.status.id !== 1 &&
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                                                        <OtherCosts
                                                            saving={saving}
                                                            vendorQuoteStatus={vendorQuote && vendorQuote.status.id ? vendorQuote.status.id : null}
                                                            desactiveSection={saving}
                                                            currencySymbol={file.currencySymbol}
                                                            quoteOtherCosts={vendorQuoteOtherCosts ? vendorQuoteOtherCosts : []}
                                                            updateOtherCosts={newOtherCosts => this.setState({ vendorQuoteOtherCosts: newOtherCosts })}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }

                                            {/* Items */}
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <VendorQuoteItems
                                                        items={items}
                                                        desactiveSection={saving}
                                                        updateVendorQuoteItems={(newItems) => {
                                                            this.setState({
                                                                items: newItems
                                                            })
                                                        }}
                                                        editable={true}
                                                        vendorQuote={vendorQuote}
                                                        currencySymbol={file.currencySymbol}
                                                        vendorQuoteStatusId={vendorQuote && vendorQuote.status ? vendorQuote.status.id : 1}
                                                        validateVendorQuote={this.props.changeStatusOnSave && validateVendorQuote} />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <VendorQuoteTotals
                                                        currencySymbol={file.currencySymbol}
                                                        items={items.filter(item => !item.deleted)}
                                                        otherCosts={vendorQuoteOtherCosts ? vendorQuoteOtherCosts.filter(oc => !oc.deleted) : []}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                        
                                        {/* {vendorQuote && vendorQuote.status.id === 1 &&
                                            <Grid container >
                                                <Grid item xs={12}>
                                                    <Button
                                                        disabled={saving}
                                                        className={classes.vendor__form__delete__button}
                                                        onClick={this.confirmDeleteVendorQuote}>
                                                        {i18n.t('quotes.vendorQuoteRequest.deleteVendorRequest')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        } */}
                                    </>
                                }


                                {currentTab === 1 &&
                                    <Paper className={classes.notes__paper} style={{ minHeight: '100px' }}>
                                        {/* Notas */}
                                        {vendorQuote &&
                                            <Grid container spacing={2}>
                                                {notes.length > 0 ?
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ marginLeft: "8px" }}>
                                                            <Link
                                                                variant='button'
                                                                disabled={saving}
                                                                className={classes.vendorQuote__addNote__button}
                                                                onClick={() => this.setState({ openNewNote: true })}>
                                                                {i18n.t('quotes.vendorQuoteRequest.addNote')}
                                                            </Link>
                                                        </div>

                                                        <Grid item xs={12}>
                                                            {notes.map((note, i) => {
                                                                return (
                                                                    <Note
                                                                        key={i}
                                                                        note={note}
                                                                        index={i}
                                                                        hideVendorQuoteLink={true}
                                                                        user={logedInUser}
                                                                        openNewNote={this.openNewNoteDialog}
                                                                    />
                                                                )
                                                            })}
                                                        </Grid>

                                                    </div>
                                                    :
                                                    <div className={classes.vendorQuote__addNote__center}>
                                                        <Link
                                                            variant='button'
                                                            disabled={saving}
                                                            className={classes.vendorQuote__addNote__button}
                                                            onClick={() => this.setState({ openNewNote: true })}>
                                                            {i18n.t('quotes.vendorQuoteRequest.addNote')}
                                                        </Link>
                                                    </div>
                                                }

                                            </Grid>
                                        }

                                        {vendorQuote &&
                                            <NoteForm
                                                open={openNewNote || false}
                                                file={file}
                                                note={note}
                                                user={logedInUser}
                                                vendorQuoteId={vendorQuote.id}
                                                emailAddress={vendorQuote.vendor && vendorQuote.contact.email ? {
                                                    value: vendorQuote.contact.email,
                                                    label: `${vendorQuote.contact.firstName} ${vendorQuote.contact.lastName}`
                                                } : null}
                                                addNewNote={this.handelNoteSaved}
                                                deleteNote={this.deleteNote}
                                                updateNote={this.updateNote}
                                                handleClose={this.closeNewNote}
                                            />
                                        }
                                    </Paper>
                                }

                                <CustomMessages
                                    type={'error'}
                                    message={apiErrorMessage}
                                    clearMessage={() => this.setState({
                                        apiErrorMessage: null
                                    })}
                                />

                                <ConfirmationReturnStatusDialog
                                    handleClose={this.cancelConfirmationReturn}
                                    onConfirm={this.deleteVendorQuote}
                                    message={message}
                                    loading={saving}
                                    inputLabel={i18n.t('quotes.vendorQuoteRequest.confirmation.explainReason')}
                                    cancelLabel={cancelLabel}
                                    confirmLabel={confirmLabel}
                                    open={openConfirmationDialog || false}
                                />
                            </div>

                        </DialogContent>

                        <DialogActions >
                            <Grid container spacing={0} justify={'space-between'}>
                                {this.state.showRequiredFieldsError && (
                                    <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '5px' }}>
                                        <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                    </Grid>
                                )}

                                <div>
                                    <Button
                                        color='default'
                                        variant="outlined"
                                        onClick={this.props.handleClose}>
                                        {i18n.t("quotes.quoteRequest.cancelEdit")}
                                    </Button>
                                    {(vendorQuote && vendorQuote.status.id === 1) && //!fromEditForm &&
                                        <Button
                                            className={classes.subaction__bar__actionButton__delete}
                                            disabled={saving}
                                            onClick={this.confirmDeleteVendorQuote}>
                                            {i18n.t('quotes.vendorQuoteRequest.deleteVendorRequest')}
                                        </Button>
                                    }
                                </div>
                                {((vendorQuote && vendorQuote.status.id <= 3) || !vendorQuote) &&
                                    <div className={classes.button__wrapper}>
                                        <Button
                                            disabled={saving}
                                            color="primary"
                                            variant="contained"
                                            onClick={this.editVendorQuote}
                                            className={classes.vendor__form__save__button}>
                                            {i18n.t("quotes.vendorQuoteRequest.saveQuote")}
                                            {saving && !this.state.saveAsReady && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </Button>
                                        {vendorQuote && vendorQuote.status.id === 2 && (
                                            <Button
                                                disabled={saving}
                                                //color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    this.setState({
                                                        saveAsReady: true
                                                    }, _ => {
                                                        this.handleVendorRFQ()
                                                    })
                                                }}
                                                className={classes.vendor__form__save_as_ready__button}>
                                                {i18n.t("quotes.vendorQuoteRequest.saveQuoteAsReady")}
                                                {saving && this.state.saveAsReady && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                            </Button>
                                        )}

                                    </div>
                                }

                            </Grid>
                        </DialogActions>

                        <NoteEditDocuments
                            open={showAddNote}
                            closeNote={this.closeNote}
                            fileId={file ? file.id : null}
                            message={i18n.t('notes.vendorQuoteEdit')}
                            title={i18n.t('notes.vendorQuoteEditTitle')}
                            vendorQuoteId={vendorQuote ? vendorQuote.id : null}
                            onSuccess={() => {
                                this.setState({
                                    saveAsReady: false
                                }, _ => {
                                    this.handleVendorRFQ()
                                })
                            }}
                        />


                        <NoteFormEmail
                            open={openNewNoteEmail}
                            section={2}
                            file={this.props.file || null}
                            note={null}
                            user={logedInUser}
                            emailAddress={selectedVendorContact && selectedVendorContact.email ? {
                                value: selectedVendorContact.email,
                                label: `${selectedVendorContact.firstName || ''} ${selectedVendorContact.lastName || ''}`
                            } : null}
                            addNewNote={this.handelNoteSaved}
                            handleClose={this.closeNewNote}
                            sendByEmail={true}
                        />
                    </Dialog>
                }
            </GlobalParametersContext.Consumer>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch),
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        vendor: state.vendor,
        vendors: state.vendors,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuoteForm))