import React from 'react'

import {
    withStyles,
    TextField,
    List,
    ListItem,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    Grid,
    Button,
    Divider,
    Checkbox,
    CircularProgress,
    // FormGroup,
    // FormControlLabel
} from '@material-ui/core'
import {
    Edit,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Delete,
    // CheckBoxOutlineBlank,
    // CheckBox as CheckBoxIcon,
} from '@material-ui/icons'
import API from '../../../lib/api'
import i18n from 'i18next'
import moment from 'moment'
import ReacTable from 'react-table'
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { NumberFormatInputCustom } from '../../general'
import { SlideUp } from '../../general/Transitions'


const styles = theme => ({
    vendor__quote__items__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    vendor__quote__items__table__quantity: {
        // color: theme.palette.text.secondary
    },
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        position: 'relative'
    },
    dialog__paper: {
        width: '100%',
        overflow: 'visible'
    },
    vendorPOItem__item__form__container: {
        // padding: theme.spacing(3)
    },
    vendorPO__items__descriptionExtended: {
        // width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    extendeDescription__icon: {
        fontSize: '20px',
        color: theme.palette.grey[500]
    },
    actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
})

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

class VendorPOItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSelectItemDialog: false,
            apiErrorMessage: null,
            vendorPOItem: null,
            receivedItem: null,
            deleting: false,
            activeEditRow: false,
            selectedDescriptionIndex: null,
            vendorPOItems: props.fromPOActions ? props.vendorPO.items.filter(vpi => !vpi.deleted) : props.vendorPO.items
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            vendorPOItems: nextProps.fromPOActions ? nextProps.vendorPO.items.filter(vpi => !vpi.deleted) : nextProps.vendorPO.items
        })
    }


    close = () => {
        this.setState({
            showSelectItemDialog: false
        })
    }

    updateReceivedItem = () => {
        const { receivedItem, vendorPOItems, vendorPOItem } = this.state,
            { vendorPO, updateVendorPOItems } = this.props;

        let body = { ...receivedItem };

        body.fileId = vendorPO.fileId;
        body.vendorPOItemId = vendorPOItem.id;

        API.Quotes.updateVendorPOReceivedItem({ body }).then(res => {
            if (res.file) {
                if (receivedItem.deleted){
                    const newItems = res.file.vendorPOs.find(vp => vp.id === vendorPO.id).items
                    updateVendorPOItems(newItems, res.file) 
                } else {
                    let newVendorPOItems = [...vendorPOItems],
                        newVendorPOItem = { ...vendorPOItem },
                        receivedIndex = vendorPOItem.receivedItems.findIndex(item => item.id === receivedItem.id),
                        vendorQIndex = newVendorPOItems.findIndex(item => item.id === vendorPOItem.id);

                    newVendorPOItem.receivedItems[receivedIndex] = receivedItem;
                    newVendorPOItem.receivedQuantity = newVendorPOItem.receivedItems.reduce(((total, row) => total + parseInt(row.quantity)), 0);
                    newVendorPOItem.receiving = newVendorPOItem.quantity - newVendorPOItem.receivedQuantity;
                    newVendorPOItems[vendorQIndex] = newVendorPOItem;

                    this.setState({
                        vendorPOItems: newVendorPOItems
                    })
                }

                this.setState({
                    activeEditRow: false,
                    receivedItem: null,
                    vendorPOItem: null,
                    deleting: false,
                })
            } else {
                this.setState({
                    activeEditRow: false,
                    receivedItem: null,
                    vendorPOItem: null,
                    deleting: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    editReceivedItem = (e, args) => {
        e.stopPropagation();
        const { vendorPOItem, receivedItem } = args;

        this.setState({
            activeEditRow: true,
            vendorPOItem,
            originalQuantity: receivedItem.quantity,
            receivedItem
        })
    }

    closeEditReceivedItem = () => {
        this.setState({
            activeEditRow: false,
            vendorPOItem: null,
            receivedItem: null
        })
    }

    handleDateChange = date => {
        const { receivedItem } = this.state;

        let newReceivedItem = { ...receivedItem }
        newReceivedItem.date = date

        this.setState({
            receivedItem: newReceivedItem
        });
    }

    handleChange = (e, index) =>{
        const { vendorPOItems } = this.state;
        const newItems = [...vendorPOItems];

        newItems[index].allReceive = e.target.checked;

        this.setState({
            vendorPOItems: newItems
        })

        this.props.updateVendorPOItems(newItems)

    }

    renderReceivingQuantity = (cellInfo) => {
        const { classes } = this.props,
            { vendorPOItems } = this.state;

        let allowItems = parseFloat(cellInfo.original.quantity || 0) - parseFloat(cellInfo.original.receivedQuantity || 0)

        if (!vendorPOItems[cellInfo.index].receiving && vendorPOItems[cellInfo.index].receiving !== "" && allowItems !== 0) {
            const newItems = [...vendorPOItems];

            newItems[cellInfo.index].receiving = allowItems === '0' ? '' : allowItems;

            this.setState({
                vendorPOItems: newItems
            })

            this.props.updateVendorPOItems(newItems)
        }

        return (
            <div style={{ textAlign: 'center', width: '100%' }}>
                <List component="nav" style={{ width: '100%', padding: '0' }}>
                    {cellInfo.original.receivedItems && cellInfo.original.receivedItems.sort((a, b) => {
                        return new Date(a.date) - new Date(b.date);
                    }).map((item, i) => (
                        <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px', borderBottom: i !== cellInfo.original.receivedItems.length - 1 ? '1px solid #e0e0e0' : 'none' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '120px' }}>
                                <Typography>
                                    {moment(item.date).format('MMM Do, YYYY')}
                                </Typography>
                                <Typography>{item.quantity} {cellInfo.original.unit}</Typography>
                            </div>
                            <div>
                                <IconButton style={{ padding: '3px' }} onClick={(e) => this.editReceivedItem(e, { vendorPOItem: cellInfo.original, receivedItem: item })}>
                                    <Edit style={{ fontSize: '16px' }} />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </List>

                {allowItems !== 0 &&
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <InlineDatePicker
                                    variant="outlined"
                                    label={i18n.t('quotes.pos.vendorPOs.itemReceivedDate')}
                                    value={vendorPOItems[cellInfo.index].receivingDate ? vendorPOItems[cellInfo.index].receivingDate : new Date()}
                                    style={{ width: '105px' }}
                                    styles={customStyles}
                                    format="MMM DD YYYY"
                                    disabled={vendorPOItems[cellInfo.index].deleted}
                                    onChange={date => {
                                        const newItems = [...vendorPOItems];

                                        newItems[cellInfo.index].receivingDate = date;

                                        this.setState({
                                            vendorPOItems: newItems
                                        })

                                        this.props.updateVendorPOItems(newItems)
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <TextField
                                autoFocus={true}
                                autoComplete='off'
                                variant="outlined"
                                id="quantity"
                                disabled={allowItems === 0 || vendorPOItems[cellInfo.index].deleted}
                                label={i18n.t('quotes.pos.vendorPOs.itemReceivedQuantity')}
                                error={vendorPOItems[cellInfo.index].receiving > allowItems || vendorPOItems[cellInfo.index].receiving === 0}
                                style={{ width: '85px' }}
                                className={classes.invoice__items__form__textField}
                                defaultValue={allowItems}
                                value={vendorPOItems[cellInfo.index].receiving}
                                onChange={e => {
                                    const newItems = [...vendorPOItems];

                                    newItems[cellInfo.index].receiving = e.target.value === '0' ? '' : e.target.value;

                                    this.setState({
                                        vendorPOItems: newItems
                                    })

                                    this.props.updateVendorPOItems(newItems)
                                }}
                                InputProps={{
                                    inputComponent: NumberFormatInputCustom,
                                    inputProps: { max: allowItems }
                                }}
                            />
                        </div>
                        {
                        // vendorPOItems[cellInfo.index].receiving && parseFloat(vendorPOItems[cellInfo.index].receiving) !== parseFloat(allowItems) &&
                        //     <div>
                        //         <FormGroup row>
                        //             <FormControlLabel
                        //                 control={
                        //                     <Checkbox
                        //                         icon={<CheckBoxOutlineBlank style={{ fontSize: '17px' }} />}
                        //                         checkedIcon={<CheckBoxIcon style={{ fontSize: '17px' }} />}
                        //                         checked={vendorPOItems[cellInfo.index].allReceive}
                        //                         onChange={(e) => this.handleChange(e, cellInfo.index)}
                        //                         value="allReceive"
                        //                         color="primary"
                        //                     />
                        //                 }
                        //                 label={
                        //                     <div style={{ fontSize: '13px', whiteSpace: 'break-spaces', textAlign: 'start' }}>
                        //                         {vendorPOItems[cellInfo.index].receiving > allowItems ? i18n.t("quotes.pos.vendorPOs.items.moreThanQuoted") : i18n.t("quotes.pos.vendorPOs.items.allReceive")}
                        //                     </div>
                        //                 }
                        //             />
                        //         </FormGroup>
                        //     </div>
                        }
                    </div>
                }
            </div>
        )
    }

    extendDescription = (index) => {
        const { selectedDescriptionIndex } = this.state;

        this.setState({
            selectedDescriptionIndex: selectedDescriptionIndex === index ? null : index
        })
    }

    renderCostField = (cellInfo) => {
        const { classes } = this.props,
            { vendorPOItems } = this.state;

        return (
            <div>
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id="quoteAmount"
                    disabled={vendorPOItems[cellInfo.index].deleted}
                    className={classes.preparing__form__textField}
                    value={parseFloat(vendorPOItems[cellInfo.index].unitAmount).toFixed(2)}
                    onBlur={e => {
                        const newItems = [...vendorPOItems];
                        newItems[cellInfo.index].unitAmount = e.target.value;
                        this.setState({
                            vendorPOItems: newItems
                        });
                        this.props.updateVendorPOItems(newItems);
                    }}
                    InputProps={{
                        inputComponent: NumberFormatInputCustom
                    }}
                />
            </div>
        )
    }

    renderQuantityField = (cellInfo) => {
        const { classes } = this.props,
            { vendorPOItems } = this.state;

        return (
            <div>
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    id="quoteAmount"
                    disabled={vendorPOItems[cellInfo.index].deleted}
                    className={classes.preparing__form__textField}
                    value={cellInfo.original.quantity}
                    onBlur={e => {
                        if (e.target.value !== 0 || e.target.value !== "0") {
                            const newItems = [...vendorPOItems];
                            newItems[cellInfo.index].quantity = e.target.value;

                            this.setState({ vendorPOItems: newItems });
                            this.props.updateVendorPOItems(newItems);
                        }
                    }}
                    InputProps={{
                        inputComponent: NumberFormatInputCustom
                    }}
                />
            </div>
        )
    }

    renderIncludeField = (cellInfo) => {
        const { vendorPOItems } = this.state;

        return (
            <Checkbox
                checked={cellInfo.original.include}
                tabIndex={-1}
                onChange={(e) => {
                    const newItems = [...vendorPOItems];
                    newItems[cellInfo.index].include = e.target.checked;

                    this.setState({ vendorPOItems: newItems });
                    this.props.updateVendorPOItems(newItems);
                }}
            />
        )
    }

    deleteItem = (index, value) => {
        const { vendorPOItems } = this.state;

        const newItems = [...vendorPOItems];
        newItems[index].deleted = value
        this.setState({
            vendorPOItems: newItems
        });
        this.props.updateVendorPOItems(newItems);
    }


    render() {
        const { classes, editable, infoEditable, vendorPO, fromPOActions } = this.props,
            { vendorPOItems, activeEditRow, receivedItem,
                vendorPOItem, originalQuantity, selectedDescriptionIndex, deleting } = this.state;


        if (!vendorPO && !vendorPOItems) return null

        let allowItems = null

        if (vendorPOItem) {
            allowItems = parseInt(vendorPOItem.quantity || 0) - parseInt(vendorPOItem.receivedQuantity || 0) + originalQuantity;
        }

        return (
            <div>
                <ReacTable
                    style={{ marginTop: '16px' }}
                    data={vendorPOItems.sort((a, b) => {
                        if (a.rfqItemId < b.rfqItemId) return -1;
                        if (a.rfqItemId > b.rfqItemId) return 1;
                        return 0
                    })}
                    columns={[
                        {
                            Header: i18n.t("quotes.pos.vendorPOs.items.line"),
                            id: 'rowIndex',
                            Cell: row => (<div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}>{row.index + 1}</div>),
                            width: 40
                        },
                        {
                            Header: i18n.t('quotes.pos.vendorPOs.items.description'),
                            id: 'description',
                            style: {
                                whiteSpace: 'pre-line',
                                alignItems: 'center',
                                display: 'flex'
                            },
                            Cell: row => (
                                <div style={{ width: '100%' }}>
                                    {row.original.partNumber && row.original.partNumber !== row.original.rfqItemPartNumber ? <>
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.clientPartNumber')} ${row.original.rfqItemPartNumber}`}
                                        </Typography>
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {`${i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')} ${row.original.partNumber}`}
                                        </Typography>
                                    </> :
                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                            {row.original.partNumber}
                                        </Typography>
                                    }
                                    <div onClick={() => this.extendDescription(row.index)}
                                        className={classes.vendorPO__items__descriptionExtended}
                                        style={{ alignItems: selectedDescriptionIndex === row.index ? 'flex-start' : 'center' }}>
                                        <Typography color='textSecondary'
                                            style={{ width: '155px', whiteSpace: selectedDescriptionIndex === row.index ? 'pre-line' : 'nowrap' }} noWrap={selectedDescriptionIndex === row.index ? false : true}>
                                            {row.original.description}
                                        </Typography>
                                        {row.original.description.length > 15 && <>
                                            {selectedDescriptionIndex === row.index ?
                                                <KeyboardArrowUp className={classes.extendeDescription__icon} />
                                                :
                                                <KeyboardArrowDown className={classes.extendeDescription__icon} />
                                            }
                                        </>}
                                    </div>
                                </div>
                            )
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.quantity'),
                            width: 100,
                            id: 'quantity',
                            show: infoEditable && !editable ? true : false,
                            Cell: this.renderQuantityField
                        },
                        {
                            Header: i18n.t('quotes.preparatingQuoteInformation.table.column5'),
                            width: 120,
                            id: 'cost',
                            show: infoEditable && !editable ? true : false,
                            Cell: this.renderCostField
                        },
                        // {
                        //     Header: i18n.t('quotes.preparatingQuoteInformation.table.include'),
                        //     id: 'include',
                        //     width: 80,
                        //     show: infoEditable && !editable ? true :false,
                        //     style: { display: 'flex', justifyContent: 'center' },
                        //     Cell: this.renderIncludeField
                        // },
                        {
                            Header: i18n.t('quotes.pos.vendorPOs.items.unitAmount'),
                            width: 90,
                            id: 'unitAmount',
                            show: !infoEditable,
                            className: classes.vendor__quote__items__table__cell__center,
                            Cell: row => (
                                <NumberFormat value={row.original.unitAmount || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    className={classes.totalRow}
                                />
                            )
                        },
                        {
                            Header: i18n.t('quotes.pos.vendorPOs.items.quoted'),
                            width: 70,
                            id: 'quoted',
                            show: !infoEditable,
                            className: classes.vendor__quote__items__table__cell__center,
                            Cell: row => (
                                <span className={classes.vendor__quote__items__table__quantity}>
                                    {row.original.quantity}
                                </span>
                            )
                        },
                        {
                            Header: i18n.t('quotes.pos.vendorPOs.items.received'),
                            id: 'received',
                            show: editable || infoEditable ? false : true,
                            className: classes.vendor__quote__items__table__cell__center,
                            Cell: row => {
                                return (
                                    <List component="nav" style={{ width: '100%', padding: '0' }}>
                                        {row.original.receivedItems && row.original.receivedItems.sort((a, b) => {
                                            return new Date(a.date) - new Date(b.date);
                                        }).map((item, i) => (
                                            <ListItem key={item.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', borderBottom: i !== row.original.receivedItems.length - 1 ? '1px solid #e0e0e0' : 'none' }}>
                                                <Typography style={{ width: '57px' }}>
                                                    {moment(item.date).format('MMM Do, YYYY')}
                                                </Typography>
                                                <Typography>{item.quantity} {item.unit}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                )
                            }
                        },
                        {
                            Header: i18n.t('quotes.pos.vendorPOs.items.receiving'),
                            width: 210,
                            id: 'receiving',
                            show: editable && !infoEditable ? true : false,
                            className: classes.vendor__quote__items__table__cell__center,
                            Cell: this.renderReceivingQuantity
                        },
                        {
                            Header: '',
                            id: 'deleteItem',
                            show: !fromPOActions,
                            accessor: '',
                            style: {
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            },
                            width: 45,
                            filterable: false,
                            Cell: row => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    { vendorPOItems.length > 1 &&
                                        <IconButton
                                            onClick={() => this.deleteItem(row.index, !row.original.deleted)}
                                            color="inherit"
                                            aria-label="Profile"
                                        >
                                            <Delete color={row.original.deleted ? 'error' : 'inherit'} style={{ fontSize: '0.7em' }} />
                                        </IconButton>
                                    }
                                </div>
                            )
                        }
                    ]}
                    pageSize={vendorPOItems.length}
                    className={classNames(classes.vendor__quote__items__table, ' -striped -highlight')}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filtrable={false}
                    resizable={false}
                    sortable={false}
                />


                <Dialog
                    open={activeEditRow ? true : false}
                    maxWidth='sm'
                    fullWidth={true}
                    TransitionComponent={SlideUp}
                    classes={{ paper: classes.dialog__paper }}>

                    <DialogTitle disableTypography={true}>
                        <Typography variant='body1' color="inherit">{i18n.t('quotes.pos.vendorPOs.editItemReceived')}</Typography>
                    </DialogTitle>

                    <div className={classes.vendorPOItem__item__form__container}>

                        <Grid container spacing={2} alignItems='flex-start' justify='flex-start' style={{ padding: '16px' }}>

                            <Grid item xs={3}>
                                <Typography variant='body1' color='textPrimary'>
                                    {i18n.t('quotes.pos.vendorPOs.item')}
                                </Typography>
                                <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                    {vendorPOItem && vendorPOItem.partNumber}
                                </Typography>
                                <Typography color='textSecondary' noWrap>{vendorPOItem && vendorPOItem.description}</Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant='body1' color='textPrimary'>
                                    {i18n.t('quotes.pos.vendorPOs.items.quoted')}
                                </Typography>
                                <Typography color='textSecondary' className={classes.vendor__quote__items__table__quantity}>
                                    {vendorPOItem && vendorPOItem.quantity}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant='body1' color='textPrimary'>
                                    {i18n.t('quotes.pos.vendorPOs.items.received')}
                                </Typography>
                                <Typography color='textSecondary' className={classes.vendor__quote__items__table__quantity}>
                                    {vendorPOItem && vendorPOItem.receivedItems.reduce(((total, row) => total + parseInt(row.quantity)), 0)}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} style={{ marginTop: '20px' }}>
                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                    <InlineDatePicker
                                        variant="outlined"
                                        format="MMM DD YYYY"
                                        label={i18n.t('quotes.pos.vendorPOs.itemReceivedDate')}
                                        value={receivedItem ? receivedItem.date : new Date()}
                                        style={{ width: '100%', marginTop: '5px' }}
                                        styles={customStyles}
                                        onChange={this.handleDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={2} style={{ marginTop: '20px' }}>
                                <TextField
                                    fullWidth
                                    autoFocus={true}
                                    label={i18n.t('quotes.pos.vendorPOs.itemReceivedQuantity')}
                                    autoComplete='off'
                                    variant="outlined"
                                    id="quantity"
                                    disabled={allowItems && allowItems === 0}
                                    error={allowItems && receivedItem.quantity > allowItems}
                                    style={{ marginTop: '5px' }}
                                    className={classes.invoice__items__form__textField}
                                    value={receivedItem ? receivedItem.quantity : 0}
                                    onChange={e => {
                                        const newReceivedItem = { ...receivedItem };

                                        newReceivedItem.quantity = e.target.value;

                                        this.setState({
                                            receivedItem: newReceivedItem
                                        })
                                    }}
                                    InputProps={{
                                        inputComponent: NumberFormatInputCustom,
                                        inputProps: { max: allowItems }
                                    }}
                                />
                            </Grid>

                            {this.state.showRequiredFieldsError && (
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                    <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                </Grid>
                            )}


                            <div>
                                { !deleting ?
                                    <Button
                                        className={classes.actionButton__delete}
                                        disabled={deleting}
                                        onClick={()=>{
                                            const newItem = {...receivedItem}
                                            newItem.deleted = true
                                            this.setState({
                                                receivedItem: newItem
                                            }, ()=> {
                                                this.updateReceivedItem()
                                            })
                                        }}>
                                        {i18n.t('quotes.quoteRequest.deleteVendorRequest')}
                                    </Button>
                                :
                                    <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                                }
                            </div>

                        </Grid>
                        <Divider />
                        <Grid container spacing={0} style={{ padding: '16px' }} justify={'space-between'}>
                            <Button
                                color='default'
                                variant="outlined"
                                onClick={this.closeEditReceivedItem}>
                                {i18n.t("quotes.pos.cancelEditVendorPOItem")}
                            </Button>

                            <Button
                                variant="contained"
                                color='primary'
                                disabled={receivedItem && (receivedItem.quantity === '0' || receivedItem.quantity === '') ? true : false}
                                onClick={this.updateReceivedItem}>
                                {i18n.t("quotes.pos.editVendorPoItem")}
                            </Button>
                        </Grid>
                    </div>
                </Dialog>
            </div>
        )
    }
}


export default withStyles(styles)(VendorPOItems)
