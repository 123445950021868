export const VENDOR_QUOTES_SET = 'VENDOR_QUOTES_SET'
export const VENDOR_QUOTE_UPDATE = 'VENDOR_QUOTE_UPDATE'
export const VENDOR_QUOTE_ADD = 'VENDOR_QUOTE_ADD'
export const VENDOR_QUOTE_DELETE = 'VENDOR_QUOTE_DELETE'

export function setVendorQuotes(vendorQuotes) {
    return {
        type: VENDOR_QUOTES_SET,
        vendorQuotes
    }
}

export function updateVendorQuote(vendorQuote) {
    return {
        type: VENDOR_QUOTE_UPDATE,
        vendorQuote
    }
}

export function deleteVendorQuote(vendorQuote){
    return {
        type:VENDOR_QUOTE_DELETE,
        vendorQuote
    }
}
export function addVendorQuote(vendorQuote){
    return {
        type:VENDOR_QUOTE_ADD,
        vendorQuote
    }
}