// Actions types

export const UPDATE_CLIENT_QUOTE = 'UPDATE_CLIENT_QUOTE'

//Actions

export function updateClientQuote(clientQuote) {
    return {
        type: UPDATE_CLIENT_QUOTE,
        clientQuote
    }
}
