import React from 'react';
import {
    withStyles,
    withMobileDialog,
    Grid,
    Typography,
    Button,
    List, 
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    CircularProgress,
    Breadcrumbs,
    Link,
    TextField,
    IconButton,
    MenuItem,
    Divider
} from '@material-ui/core';
import { 
    Close, KeyboardArrowRight,
    Check, Edit,
    Phone, MenuBook,
    Drafts, Today,
    People, Room,
    KeyboardArrowLeft,
    CenterFocusStrong,
    PlaylistAddCheck,
    DoneOutline,
    NotInterested
} from '@material-ui/icons'
import {DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import {geolocated} from "react-geolocated";
import Geocode from 'react-geocode';
import i18n from 'i18next';
import moment from "moment";
import config from '../../../config';
import API from '../../../lib/api'
import {ConfirmationDialog, GoogleMap} from '../../general'


const GOOGLE_API_KEY = config.google.mapsAPIKey;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

const styles = theme => ({
    event__editable__section:{
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1) + 2}px 0`,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ccc',
            filter: 'brightness(1.1);'
        }
    },
    event__noEditable__section:{
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1) + 2}px 0`
    },
    event__editable__icon: {
        color: theme.palette.blue[100],
        marginRight: theme.spacing(1)
    },
    editOverlay: {
        position: 'absolute',
        display: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: '2000',
        cursor: 'pointer'
    },
    event__edit__header:{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        margin: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
        }
    },
    event__edit__container: {
        padding: `0 ${theme.spacing(4)}px`,
        height: ''
    },
    visits__form_date__input: {
        fontSize: 'unset'
    },
    visits__form__date: {
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        margin: `${theme.spacing(1)}px 0`,
        fontSize: '0.8em'
    },
    buttonSpinner: theme.buttonSpinner,
    event__client__icon : {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-5px'
    },
    map__container: {
        border: 'solid thin #000',
        height: '210px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    check__display: {
        display: 'flex',
        alignItems: 'center'
    },
    check__closeButton: {
        padding: 0,
        color: theme.palette.error.main,
        marginLeft: theme.spacing(3)
    },
    breadcrumbs__icon: {
        color: theme.palette.blue[100],
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    link: {
        display: 'flex',
    },
    breadcrumbs__icon__disabled:{
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
});


const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

const options = [
    {
        value: 'New Client'
    }, {
        value: 'Sales Visit'
    }, {
        value: 'Followup'
    }, {
        value: 'Technical Visit'
    }
]


class EventEditable extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            event: props.event,
            currentTab: null,
            openEditEvent: false,
            saving: false,
            validating: false,
            deleteCheckInConfirmation: false,
            deleteCheckOutConfirmation: false,
            from: props.event ? new Date(props.event.from || '') : new Date(),
            to: props.event ? new Date(props.event.to || '') : new Date(),
            title: props.event ? props.event.title : '',
            description: props.event ? props.event.description : '',
            objectives: props.event ? props.event.objectives : '',
            topics: props.event ? props.event.topics : '',
            results: props.event ? props.event.results : '',
            nextSteps: props.event ? props.event.nextSteps : '',
            checkIn: props.event && props.event.id ? props.event.checkIn : null,
            checkOut: props.event && props.event.id ? props.event.checkOut : null,
            type: props.event && props.event.visit.id ? props.event.visit.type : options[0],
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.openVisitReport){
            this.setState({
                currentTab: 6,
                openEditEvent: true
            })
        } else {
            this.setState({
                currentTab: null,
                openEditEvent: false
            })
        }

        this.setState({
            event: nextProps.event,
            from: nextProps.event ? new Date(nextProps.event.from || '') : new Date(),
            to: nextProps.event ? new Date(nextProps.event.to || '') : new Date(),
            title: nextProps.event ? nextProps.event.title : '',
            description: nextProps.event ? nextProps.event.description : '',
            objectives: nextProps.event ? nextProps.event.objectives : '',
            topics: nextProps.event ? nextProps.event.topics : '',
            results: nextProps.event ? nextProps.event.results : '',
            nextSteps: nextProps.event ? nextProps.event.nextSteps : '',
            checkIn: nextProps.event && nextProps.event.id ? nextProps.event.checkIn : null,
            checkOut: nextProps.event && nextProps.event.id ? nextProps.event.checkOut : null,
            type: nextProps.event && nextProps.event.visit.id ? nextProps.event.visit.type : options[0]
        })
    }

    updateVisit=(status)=>{
        const { event, from, to, checkIn, title, topics,
                checkOut, nextSteps, results,
                objectives, description, type } = this.state;

        this.setState({
            saving: true
        })

        let body = {
            ...event,
            from,
            to,
            title,
            description,
            clientId: event.client.id,
            clientContactId: event.clientContact.id,
            isPrivate: event.private,
            place: event.place && event.place.address ? event.place.address : null,
            googleAddress: event.place && event.place.googleAddress ? event.place.googleAddress : null,
            placeLat: event.place && event.place.lat ? event.place.lat : null,
            placeLng: event.place && event.place.lng ? event.place.lng : null,
            checkInAddress: checkIn ? checkIn.address : null,
            checkInLat: checkIn ? checkIn.lat : null,
            checkInLng: checkIn ? checkIn.lng : null,
            checkInDateTime: checkIn ? checkIn.dateTime: null,
            checkOutAddress: checkOut ? checkOut.address : null,
            checkOutLat: checkOut ? checkOut.lat : null,
            checkOutLng: checkOut ? checkOut.lng : null,
            checkOutDateTime: checkOut ? checkOut.dateTime: null,
            visit: {
                ...event.visit,
                type,
                objectives,
                nextSteps,
                results,
                topics,
                status
            }
        }

        API.Events.updateEvent({ event: body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    event: res.data.record,
                    deleteCheckInConfirmation: false,
                    deleteCheckOutConfirmation: false
                })
                this.props.updateEvent && this.props.updateEvent(res.data.record)                    
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
        
    }

    selectTab=(tab)=>{
        const { currentTab } = this.state;

        this.setState({
            currentTab: currentTab === tab ? null : tab,
            openEditEvent: true
        })
    }

    handleFromDateChange = date => {
        this.setState({
            from: date
        });
    }

    handleToDateChange = date => {
        this.setState({
            to: date
        });
    }

    handleChangeSelect = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    backToDetails=()=>{
        this.setState({
            currentTab: null,
            openEditEvent: false
        })
    }

    deleteCheckIn=()=>{
        this.setState({
            checkIn: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            },
            checkOut: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            }
        }, ()=>{
            this.updateVisit(1)
        })
    }

    deleteCheckOut=()=>{
        this.setState({
            checkOut: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            }
        }, ()=>{
            this.updateVisit(1)
        })
    }

    render() {
        const { event, openEditEvent, type,
                currentTab, from, to, title, deleteCheckOutConfirmation,
                saving, validating, deleteCheckInConfirmation } = this.state,
             { classes, isOwn } = this.props;

        if(!event) return null

        return ( 
            <div>
                <Grid container spacing={0} justify='flex-end'>
                    <Typography variant='body1' color='textSecondary'>
                        {event.user ? event.user.name : ''}
                    </Typography>
                </Grid>

                <List className={classes.root}>
                    {/******* Event Info ********/}
                    <ListItem style={{minHeight:'75px', cursor: 'pointer'}} alignItems="flex-start" onClick={()=>this.selectTab(0)}>
                        <ListItemAvatar>
                            {event.isVisit === 1 ? <>
                                {event.visit.status.id === 1 && 
                                    <Today className={classes.event__editable__icon}/>
                                }
                                {event.visit.status.id === 2 && 
                                    <DoneOutline className={classes.event__editable__icon}/>
                                }
                                {event.visit.status.id === 3 && 
                                    <NotInterested className={classes.event__editable__icon}/>
                                }
                            </>:
                                <Today className={classes.event__editable__icon}/>
                            }
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography style={{fontSize: '1.3em', fontWeight: 'bold'}}>
                                    {event && event.title}
                                </Typography>
                            }
                            secondary={
                                <Typography 
                                    style={{
                                        color: event && event.visit && event.visit.status.id === 2  ? 'rgb(32, 181, 23)' : 'rgba(0, 0, 0, 0.54)'
                                    }}>

                                    <span style={event.visit.status.id === 3 ? {color: 'red'} : {}}>
                                        {`${event && event.visit && event.visit.status.id === 2 ? 'Done ' : event.visit.status.id === 1 ? '' : 'Canceled '}
                                            ${moment.utc(event.from).format('MMM Do hh:mm a')}`}
                                    </span>
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction onClick={()=>this.selectTab(0)}>
                            <KeyboardArrowRight/>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <Divider/>

                    {/******* VISIT ********/}
                    {event && event.isVisit === 1 && <>

                        {/******* Visit Client ********/}
                        <ListItem style={{minHeight:'75px', cursor: 'pointer'}} alignItems="flex-start" onClick={()=>this.selectTab(1)}>
                            <ListItemAvatar>
                                <People className={classes.event__editable__icon}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography style={{fontSize: '1.3em', fontWeight: 'bold'}}>
                                        {`${event && event.client.id ? `${event.client.name} `: ''}`}
                                    </Typography>
                                }
                                secondary={
                                     <Typography variant='body2' color='textSecondary'>
                                        {`${event && event.clientContact.id ? `${event.clientContact.firstName || ''} ${event.clientContact.lastName || ''}` : ''}`}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction onClick={()=>this.selectTab(1)}>
                                <KeyboardArrowRight/>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider/>

                        {/******* Visit Address ********/}
                        <ListItem style={{minHeight:'75px', cursor: 'pointer'}} alignItems="center" onClick={()=>this.selectTab(2)}>
                            <ListItemAvatar>
                                <Room className={classes.event__editable__icon}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={event && event.place && event.place.googleAddress ?
                                    <Typography variant='body1' color='textSecondary'>
                                        {event.place.googleAddress}
                                    </Typography>
                                :<>
                                    {event && event.place && event.place.address &&
                                        <Typography variant='body1' color='textSecondary' style={{display:'flex', aligItems: 'center'}}>
                                            {event.place.address}
                                        </Typography>
                                    }
                                </>}
                            />
                            <ListItemSecondaryAction onClick={()=>this.selectTab(2)}>
                                <KeyboardArrowRight/>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider/>

                        {/******* Visit Info ********/}
                        <ListItem style={{minHeight:'75px', cursor: 'pointer'}} alignItems="flex-start" onClick={()=>this.selectTab(3)}>
                            <ListItemAvatar>
                                <CenterFocusStrong className={classes.event__editable__icon}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography variant='body1' color='textPrimary'>
                                        {event.visit && event.visit.type ? event.visit.type : ''}
                                    </Typography>
                                }
                                secondary={
                                     <Typography variant='body2' color='textSecondary'>
                                        {event.visit && event.visit.objectives ? event.visit.objectives : event.visit.topics ? event.visit.topics : ''}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction onClick={()=>this.selectTab(3)}>
                                <KeyboardArrowRight/>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider/>

                        {/**** Visit CheckIn *****/}
                        { event.checkIn && event.checkIn.address && <>
                            <ListItem style={{minHeight:'75px'}} alignItems="center">
                                <ListItemAvatar>
                                    <Check className={classes.event__editable__icon}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <div style={{display: 'flex'}}>
                                            <div className={classes.check__display}>
                                                <Typography variant='body2' color='textPrimary' style={{marginRight: '8px'}}>{i18n.t("visits.form.checkIn")}</Typography>
                                                <Typography variant='body2' color='textSecondary'>{moment(event.checkIn.dateTime).format('HH:mm a')}</Typography>
                                            </div>
                                            { event.visit.status.id !== 3 && isOwn && 
                                                <div style={{display:'flex', alignItems: 'center'}}>
                                                    <IconButton
                                                        onClick={()=>{
                                                            this.setState({
                                                                deleteCheckInConfirmation: true
                                                            })
                                                        }}
                                                        aria-label="Close" className={classes.check__closeButton}>
                                                        <Close style={{fontSize: '18px'}}/>
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                />
                            </ListItem>
                            
                            <Divider/>
                        </>}



                        {/**** Visit Report *****/}
                        { event.checkIn && event.checkIn.address &&  (event.visit.results || event.visit.nextSteps) && <>
                            <ListItem style={{minHeight:'75px', cursor: 'pointer'}} alignItems="flex-start"  onClick={()=>this.selectTab(4)}>
                                <ListItemAvatar>
                                    <MenuBook className={classes.event__editable__icon}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={i18n.t('visits.form.visitReport')}
                                />
                                <ListItemSecondaryAction onClick={()=>this.selectTab(4)}>
                                    <KeyboardArrowRight/>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Divider/>
                        </>}


                        {/**** Visit CheckOut *****/}
                        { event.checkIn && event.checkIn.address && event.checkOut && event.checkOut.address && 
                            <ListItem style={{minHeight:'75px'}} alignItems="center">
                                <ListItemAvatar>
                                    <PlaylistAddCheck className={classes.event__editable__icon}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <div style={{display: 'flex'}}>
                                            <div className={classes.check__display}>
                                                <Typography variant='body2' color='textPrimary' style={{marginRight: '8px'}}>{i18n.t("visits.form.checkOut")}</Typography>
                                                <Typography variant='body2' color='textSecondary'>{moment(event.checkOut.dateTime).format('hh:mm a')}</Typography>
                                            </div>
                                            { event.visit.status.id !== 3 && isOwn && 
                                                <div style={{display:'flex', alignItems: 'center'}}>
                                                    <IconButton
                                                        onClick={()=>{
                                                            this.setState({
                                                                deleteCheckOutConfirmation: true
                                                            })
                                                        }}
                                                        aria-label="Close" className={classes.check__closeButton}>
                                                        <Close style={{fontSize: '18px'}}/>
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                />
                            </ListItem>
                        }
                    </>}
                </List>


                {/********* EDITABLE SECTION *********/}

                <div className={classes.editOverlay} style={{display: openEditEvent ? 'block' : 'none'}}>
                    <div className={classes.event__edit__header}>
                        <KeyboardArrowLeft style={{fontSize: '25px', marginRight: '16px', marginBottom: '4px'}} onClick={this.backToDetails}/>
                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                            <Link color="textSecondary" 
                                onClick={this.backToDetails} className={classes.link}>
                                <Today className={classes.breadcrumbs__icon__disabled} />
                                {event.isVisit ? i18n.t("visits.form.visit") : i18n.t("visits.form.event")}   
                            </Link>
                            
                            {currentTab === 1 &&
                                <Typography color="textPrimary" className={classes.link}>
                                    <People className={classes.breadcrumbs__icon} />
                                    {i18n.t("visits.form.clientAndContact")}
                                </Typography>
                            }

                            {currentTab === 2 &&
                                <Typography color="textPrimary" className={classes.link}>
                                    <Room className={classes.breadcrumbs__icon} />
                                    {i18n.t("visits.form.place")}
                                </Typography>
                            }

                            {currentTab === 3 &&
                                <Typography color="textPrimary" className={classes.link}>
                                    <CenterFocusStrong className={classes.breadcrumbs__icon} />
                                    {i18n.t("visits.form.visitDetail")}
                                </Typography>
                            }

                            {currentTab === 4 &&
                                <Typography color="textPrimary" className={classes.link}>
                                    <MenuBook className={classes.breadcrumbs__icon} />
                                    {i18n.t("visits.form.visitReport")}
                                </Typography>
                            }
                        </Breadcrumbs>
                    </div>

                    
                    <Grid container spacing={2} className={classes.event__edit__container}>

                        {/**** Edit Event Date, title, description *****/}
                        {currentTab === 0 && <>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            title: event.target.value
                                        })
                                    }}
                                    id="title"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.title}
                                    error={validating && !title}
                                    label={i18n.t("visits.form.title")}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} >
                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                    <DateTimePicker
                                        variant="outlined"
                                        label={i18n.t('visits.form.fromDate')}
                                        value={from}
                                        InputProps={{
                                            className: classes.visits__form_date__input
                                        }}
                                        format="MMM DD HH:mm a"
                                        className={classes.visits__form__date}
                                        styles={customStyles}
                                        onChange={this.handleFromDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                    <DateTimePicker
                                        variant="outlined"
                                        label={i18n.t('visits.form.toDate')}
                                        value={from > to ? from : to}
                                        InputProps={{
                                            className: classes.visits__form_date__input
                                        }}
                                        format="MMM DD HH:mm a"
                                        minDate={from}
                                        className={classes.visits__form__date}
                                        styles={customStyles}
                                        onChange={this.handleToDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.multilineInput
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            description: event.target.value
                                        })
                                    }}
                                    multiline
                                    rowsMax='3'
                                    rows='2'
                                    id="description"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.description}
                                    label={i18n.t("visits.form.description")}
                                />
                            </Grid>
                        </>}


                        {/***** Edit Visit Client ******/}
                        { currentTab === 1 && <>
                            <Grid item xs={12}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant='body1' color="textPrimary">
                                        {i18n.t('visits.form.client')}
                                    </Typography>
                                    <IconButton color='secondary' aria-label="Edit Address" style={{padding: '8px'}}>
                                        <Edit onClick={()=> alert('Not implemented yet')}/>
                                    </IconButton>
                                </div>
                                <Typography variant='body2' color="textSecondary">
                                    {event.client.name || ''}
                                    <br />
                                    {event.client.taxId &&
                                        <>
                                            {event.client.taxId || ''}
                                            <br />
                                        </>}
                                    {event.client.address.address1 || ''}
                                    <br />
                                    {event.client.address.address2 &&
                                        <>
                                            {event.client.address.address2 || ''}
                                        </>
                                    }
                                </Typography>

                                <Typography variant='body2' color="textSecondary">
                                    {event.client.phone &&
                                        <>
                                            <Phone className={classes.event__client__icon} />{event.client.phone}
                                        </>
                                    }
                                    <br />
                                    {event.client.fax &&
                                        <>
                                            <strong>{i18n.t('quotes.clientRFQ.fax')}</strong>{event.client.fax}
                                        </>
                                    }
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '16px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant='body1' color="textPrimary">
                                        {i18n.t('visits.form.clientContact')}
                                    </Typography>
                                    <IconButton color='secondary' aria-label="Edit Address" style={{padding: '8px'}}>
                                        <Edit onClick={()=> alert('Not implemented yet')}/>
                                    </IconButton>
                                </div>

                                <Typography variant='body2' color="textSecondary">
                                    {event.clientContact.firstName || ''} {event.clientContact.lastName || ''}
                                </Typography>

                                {event.clientContact.email &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Drafts className={classes.event__client__icon}/>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            onClick={() => this.setState({
                                                openNewNote: true,
                                            })} noWrap>{event.clientContact.email || ''}
                                        </Typography>
                                    </div>
                                }

                                {event.clientContact.phone &&
                                    <Typography variant='body2' color="textSecondary">
                                        <Phone className={classes.event__icon} />{event.clientContact.phone}
                                    </Typography>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                {
                                // <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=250x250&key=${GOOGLE_API_KEY}
                                //     &markers=color:blue%7Clabel:Location%7C${geoLocalization ? geoLocalization.geometry.location.lat : event && event.place && event.place.googleAddress ? event.place.lat : ''},
                                //     ${geoLocalization ? geoLocalization.geometry.location.lng : event && event.place && event.place.googleAddress ? event.place.lng : ''}`} 
                                //     height="250" width="250" alt="CheckIn Map"/>
                                }
                            </Grid>
                        </>}


                        {/***** Edit Visit Address ******/}
                        { currentTab === 2 && <>
                            <Grid item xs={12}>
                                {event.place && event.place.googleAddress ? <>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px'}}>
                                        <Typography variant='body2' color='textSecondary'>
                                            {event.place.googleAddress}
                                        </Typography>

                                        <IconButton color='secondary' aria-label="Edit Address" style={{padding: '8px'}}>
                                            <Edit onClick={()=> alert('Not implemented yet')}/>
                                        </IconButton>
                                    </div>

                                    <div className={classes.map__container}>
                                        <GoogleMap 
                                            location={{
                                                positions: [{
                                                    lat: event.place && event.place.googleAddress ? event.place.lat : '',
                                                    lng: event.place && event.place.googleAddress ? event.place.lng : '',
                                                    title: 'Location'
                                                }],
                                                zoom: 14
                                            }} 
                                        />
                                    </div>
                                </>:
                                    <>
                                        {event.place && event.place.address &&
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <Typography variant='body2' color='textSecondary' style={{display:'flex', aligItems: 'center'}}>
                                                    {event.place.address}
                                                </Typography>

                                                <IconButton color='secondary' aria-label="Edit Address">
                                                    <Edit/>
                                                </IconButton>
                                            </div>
                                        }
                                    </>
                                }
                            </Grid>
                        </>}

                        {/***** Edit Event Objectives and Topics ******/}
                        { currentTab === 3 && <>
                            <Grid item xs={7} sm={4}>
                                <TextField autoComplete='off'
                                    required
                                    id="visitType"
                                    select
                                    InputProps={{
                                        className: classes.event__form__input
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t("visits.form.type")}
                                    className={classes.event__form__textField}
                                    value={type}
                                    name='type'
                                    onChange={this.handleChangeSelect}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    style={{width: '100%'}}
                                    variant="outlined"
                                >
                                    {options ? options.map(option => (
                                        <MenuItem key={option.value} value={option.value} className={classes.event__form__menuItem}>
                                            {option.value}
                                        </MenuItem>
                                    )) : []}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            objectives: event.target.value
                                        })
                                    }}
                                    multiline
                                    rowsMax='3'
                                    rows='2'
                                    id="objectives"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.visit.objectives}
                                    label={i18n.t("visits.form.objectives")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.multilineInput
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            topics: event.target.value
                                        })
                                    }}
                                    multiline
                                    rowsMax='3'
                                    rows='2'
                                    id="topics"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.visit.topics}
                                    label={i18n.t("visits.form.topics")}
                                />
                            </Grid>
                        </>}


                        {/***** Edit Visit Report ******/}
                        { currentTab === 4 && <>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            results: event.target.value
                                        })
                                    }}
                                    multiline
                                    rowsMax='3'
                                    rows='2'
                                    id="results"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.visit.results}
                                    label={i18n.t("visits.form.results")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.multilineInput
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({
                                            nextSteps: event.target.value
                                        })
                                    }}
                                    multiline
                                    rowsMax='3'
                                    rows='2'
                                    id="nextSteps"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={event.visit.nextSteps}
                                    label={i18n.t("visits.form.nextSteps")}
                                />
                            </Grid>
                        </>}

                        { currentTab !== 1 && currentTab !== 2 &&
                            <Grid item xs={12} style={{textAlign: 'right', padding: '24px 4px'}}>
                                <Button
                                    color='primary'
                                    variant="contained"
                                    size="small" 
                                    disabled={saving}
                                    style={{minWidth: '80px'}}
                                    onClick={()=> alert('Not implemented yet')}
                                >
                                    {i18n.t("visits.form.update")}
                                    {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </div>

                <ConfirmationDialog
                    handleClose={()=>{
                        this.setState({
                            deleteCheckOutConfirmation: false,
                            deleteCheckInConfirmation: false
                        })
                    }}
                    loading={saving}
                    onConfirm={deleteCheckInConfirmation ? this.deleteCheckIn : this.deleteCheckOut}
                    message={deleteCheckInConfirmation ? i18n.t('visits.form.confirmDeleteCheckInMessage') : i18n.t('visits.form.confirmDeleteCheckOutMessage')}
                    cancelLabel={i18n.t('visits.form.cancelLabel')}
                    confirmLabel={i18n.t('visits.form.confirmLabel')}
                    open={deleteCheckInConfirmation || deleteCheckOutConfirmation}
                />
            </div>
        )
    }


}
export default geolocated()(withMobileDialog()(withStyles(styles)(EventEditable)));
