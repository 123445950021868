import React from 'react';
import {
    Paper,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Typography,
    withStyles,
    Link,
    Snackbar,
    SnackbarContent,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import auth from '../../authentication/auth';
import i18n from 'i18next';
import {
    Visibility,
    VisibilityOff,
} from '@material-ui/icons'

import Permissions from '../../lib/permissions'
import API from '../../lib/api'
import store from '../../store'


const logo_image = require('../../assets/images/logosmall.png');

const styles = theme => ({
    paper: {
        maxWidth: 300,
        margin: `${theme.spacing(2)}px auto`,
        marginTop: 50,
        padding: theme.spacing()
    },
    grid_error: {
        marginTop: theme.spacing()
    },
    grid: {
    },
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
    buttonSpinner: theme.buttonSpinner,
    login__header: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '10px 0',
    }
});


class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.inputs = {};
        this.state = {
            generalError: null,
            loading: false,
            showCurrentPassword: false
        };
    }

    changeState(state, data) {
        const { onStateChange } = this.props;

        if (onStateChange) {
            onStateChange(state, data);
        }
    }


    signIn = () => {
        const { password, username } = this.inputs;

        if (!username || username === '' || !password || password === '') {
            this.setState({
                loading: false,
                usernameEmpty: username ? false : true,
                passwordEmpty: password ? false : true
            });
            return;
        }

        this.setState({
            loading: true
        });

        auth.signIn({ username, password })
            .then(user => {
                let userId = user.challengeParam ? user.challengeParam.userAttributes['custom:userID'] : user.attributes['custom:userID']
                API.Users.getUser({
                    userId: userId
                }).then(dbUserResponse => {
                    if (dbUserResponse.status === 'success' && dbUserResponse.data.records) {
                        store.dispatch({
                            type: 'SET_ACCOUNT',
                            account: { ...dbUserResponse.data.records }
                        })
                        if (!Permissions.checkSensitiveInformationPermission()) {
                            store.dispatch({
                                type: 'SET_FILTER_PEOPLE',
                                filter: [{ ...dbUserResponse.data.records }]
                            })
                        }
                    }
                })
                this.signInSuccess(user);
            })
            .catch(err => {
                this.signInError(err)
            })
    }

    signInSuccess = (user) => {
        this.setState({
            generalError: null
        });

        this.checkContact(user);
    }

    checkContact(user) {
        auth.verifiedContact(user).then(data => {
            this.setState({
                loading: false
            });
            this.changeState('signedIn', user);
        });
    }

    signInError(err) {
        this.setState({
            loading: false
        });

        console.log(err)

        if (err.error && err.error.code === 'UserNotConfirmedException') {
            this.changeState('confirmSignUp', this.inputs.username);
            return
        }

        this.setState({
            generalError: i18n.t('login.invalidCredentials')
        });
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.signIn()
        }
    }
    handleClickShowCurrentPassword = () => {
        this.setState(state => ({
            showCurrentPassword: !state.showCurrentPassword
        }));
    }

    render() {
        const { authState, authData, classes } = this.props;
        if (!['signIn', 'signedOut', 'signedUp'].includes(authState)) {
            return null;
        }
        const { generalError, loading, usernameEmpty, passwordEmpty, showCurrentPassword } = this.state;

        return (
            <Paper className={classes.paper}>

                <div className={classes.login__header} >
                    <img alt="Prodek Inc" src={logo_image} />
                </div>



                <div style={{ margin: '16px' }}>
                    <Grid className={classes.grid} container spacing={1}>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth autoFocus required
                                error={usernameEmpty ? true : false}
                                id="username"
                                label={i18n.t('login.username')}
                                type="text"
                                defaultValue={authData}
                                onKeyDown={this.onKeyDown}
                                InputProps={{
                                    inputProps: {
                                        autoCapitalize: 'off',
                                        autoComplete: 'off',
                                        spellCheck: 'false',
                                        autoCorrect: "off"
                                    }
                                }}
                                onChange={event => {
                                    this.setState({ usernameEmpty: false });
                                    this.inputs.username = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={classes.grid} container spacing={1}>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth required
                                error={passwordEmpty ? true : false}
                                id="password"
                                label={i18n.t("login.password")}
                                type={showCurrentPassword ? 'text' : 'password'}
                                onKeyDown={this.onKeyDown}
                                InputProps={{
                                    inputProps: {
                                        autoCapitalize: 'off',
                                        autoComplete: 'off',
                                        spellCheck: 'false',
                                        autoCorrect: "off"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowCurrentPassword}
                                            >
                                                {showCurrentPassword ? <VisibilityOff className={classes.user__form__password__show_password_icon} /> : <Visibility className={classes.user__form__password__show_password_icon} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={event => {
                                    this.setState({ passwordEmpty: false });
                                    this.inputs.password = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" style={{ marginTop: '10px' }}>
                        <Grid item>
                            <Link component='button'
                                variant="body2"
                                color="secondary"
                                onClick={() => this.changeState('forgotPassword', this.inputs.username)}>
                                {i18n.t("login.forgotPassword")}
                            </Link>
                        </Grid>
                    </Grid>


                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={generalError !== null}
                        autoHideDuration={6000}
                        onClose={() => {
                            this.setState({
                                generalError: null
                            });
                        }} >
                        <SnackbarContent
                            className={classes.error}
                            message={<Typography className={classes.errorLabel}>{generalError}</Typography>} />
                    </Snackbar>


                    <Grid container justify="center" style={{ margin: '10px 0' }}>
                        <div className={classes.button__wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={this.signIn}
                            >
                                {i18n.t("login.signInButton")}
                            </Button>
                            {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                        </div>
                    </Grid>
                    {/* <Grid container justify="center">
                        <Grid item>
                            <Link component="button"
                                onClick={() => this.changeState('signUp')}
                                variant="body2" color="secondary">
                                Or {i18n.t("login.createAccount")}
                            </Link>
                        </Grid>
                    </Grid> */}
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(SignIn);
