import React, { Component } from 'react'
import {
    Drawer,
    ListItem,
    ListItemText,
    withStyles,
    CircularProgress
} from '@material-ui/core'
import { 
    CheckBoxOutlineBlank,
    CheckBox
} from "@material-ui/icons"

import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as peopleActions from '../../actions/peopleActions'
import * as apiStatusActions from '../../actions/apiStatusActions'
import API from '../../lib/api'

const drawerWidth = 280;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    dialog__paper: {
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        width: '400px'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog__content__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    people__list__container: {
        // height: '200px',
        overflow: 'auto'
    },
    people__selected__indicator__container: {

    },
    people__actions__container: {
        display: 'flex',
        paddingTop: theme.spacing(),
        justifyContent: 'space-between'
    },
    people__filter__button: {
        marginLeft: '5px'
    },
    list__item__highlighted: {
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.getContrastText(theme.palette.primary.main),
        // '&:hover': {
        //     backgroundColor: theme.palette.primary.light,
        //     color: theme.palette.getContrastText(theme.palette.primary.light),
        // }
    },
    menu__list__item: {
        borderRadius: 0
    },
    'menu__list__item--selected': {
        backgroundColor: 'rgba(116,185,255,0.3)',
        borderRadius: 0
    },
    menu__list__item__icon: {
        minWidth: 0,
        marginRight: 10,
        fontSize: '10pt'
    },
    'menu__list__item__icon--selected': {
        minWidth: 0,
        marginRight: 10,
        color: theme.palette.getContrastText('rgba(116,185,255,0.3)'),
        fontSize: '10pt'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    events__form__title__back: {
        fontSize: '30px'
    },
})

class ListEventEveryoneItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={1}
                data-selected={item.selected}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: 0,
                }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px',
                    alignItems: 'center'
                }}>
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}
                        onClick={item.onClick}>
                        {item.selected ? <CheckBox style={{ color: '#3f97f6', marginRight: '8px' }} /> : <CheckBoxOutlineBlank style={{ marginRight: '8px', color: '#707070' }} />}
                    </div>
                    {item.name}
                </div>
            </li>
        )
    }
}


class ListEventUserItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.userId}
                data-selected={item.selected}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: 0,
                }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px',
                    alignItems: 'center'
                }}>
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}
                        onClick={() => item.toggleSelectUser(item)}>
                        {item.selected ? <CheckBox style={{ color: '#3f97f6', marginRight: '8px' }} /> : <CheckBoxOutlineBlank style={{ marginRight: '8px', color: '#707070' }} />}
                    </div>
                    {item.userName}
                </div>
            </li>
        )
    }
}


class EventPeopleFilter extends Component {
    state = {
        peopleDialogVisible: false,
        allUsersSelected: false,
        selectedUsers: [],
        fetchingUsers: false
    }

    componentWillMount = () => {
        this.setState({
            fetchingUsers: true
        }, ()=>{
            this.fecthUsers();
        })
        
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.people.filterPeople && nextProps.people.filterPeople.length > 0){
            this.setState({
                selectedUsers: nextProps.people ? nextProps.people.filterPeople.map(u => u.userId) : []
            })
        }
    }

    fecthUsers = () => {
        const {account} = this.props

        API.Users.getUsers().then(res => {
            if (res && res.status === 'success') {
                if(account.userId && account.userTypeId === 3){
                    this.props.peopleActions.setFilterPeople(res.data.records.users.filter(u => u.userActive))
                } else {
                    this.props.peopleActions.setFilterPeople([{ userId: account.userId, userName: account.userName }])
                }
                this.props.peopleActions.setPeople(res.data.records.users.filter(u => u.userActive));
                this.setState({
                    fetchingUsers: false
                })
            }
        })
    }

    handleChange = (event) => {
        this.setState({ personName: event.target.value });
    }

    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ personName: value });
    }

    toggleAllUsers = () => {
        const {people} = this.props

        this.setState({
            selectedUsers: !this.state.allUsersSelected ? people.allPeople.map(p => {return p.userId}) : this.state.selectedUsers,
            allUsersSelected: !this.state.allUsersSelected
        })
    }
    toggleSelectUser = (user) => {
        const {people} = this.props

        let selectedUsers = [...this.state.selectedUsers],
            userInSelector = selectedUsers.find(u => u === user.userId);


        if (userInSelector) {
            selectedUsers = selectedUsers.filter(u => u !== user.userId)
        } else {
            selectedUsers.push(user.userId);
        }
        this.setState({
            selectedUsers,
            allUsersSelected: selectedUsers.length !== people.allPeople.length ? false : true
        })

    }

    handleFilter = () => {
        let { people } = this.props,
            { selectedUsers, allUsersSelected } = this.state;

        let filteredUsers = selectedUsers.length > 0 ? people.allPeople.filter(person => selectedUsers.indexOf(person.userId) >= 0) : allUsersSelected ? people.allPeople : []

        if (filteredUsers.length > 0) {
            API.Files.getUserFilesCounters({ usersIds: filteredUsers.map(u => u.userId) })
                .then(res => {
                    if (res.counters) {
                        this.props.peopleActions.setFilterCounters(res.counters);
                    }
                })
        } else {
            this.props.peopleActions.setFilterCounters(null);
        }
        this.props.generalActions.showPeopleFilter(false);
        this.props.peopleActions.setFilterPeople(filteredUsers);
        this.props.toggleOpenMenu()
    }

    onEnter = () => {
        let { people } = this.props;

        this.setState({
            allUsersSelected: people.filterPeople.length === people.allPeople.length ? true : false,
            selectedUsers: people.filterPeople.map(u => u.userId)
        })
    }

    render() {
        const {
            allUsersSelected,
            selectedUsers,
            fetchingUsers
        } = this.state,
            {
                classes,
                people,
                menuVisible,
                toggleOpenMenu
            } = this.props;

        let buttonLabel = i18n.t('peopleFilter.everyone');

        if (people.filterPeople && people.filterPeople.length > 0 && people.allPeople.length !== people.filterPeople.length) {
            if (people.filterPeople.length === 1 && people.filterPeople[0].userName) {
                buttonLabel = people.filterPeople[0].userName;
            } else {
                if(people.filterPeople.length !== 1){
                    buttonLabel = `${people.filterPeople.length} ${i18n.t('peopleFilter.users')}`
                }
            }

        }

        return (
            <div>
        
                <ListItem button
                    onClick={toggleOpenMenu}
                    className={people.filterPeople && people.filterPeople.length ? classes.list__item__highlighted : null}
                    variant={people.filterPeople && people.filterPeople.length ? 'contained' : 'text'}
                    color='default'
                >
                    <ListItemText>{ fetchingUsers ? <CircularProgress color='inherit' style={{width: '16px', height: '16px'}} /> : buttonLabel}</ListItemText>
                </ListItem>

                <Drawer open={menuVisible}
                    anchor="right"
                    onClose={toggleOpenMenu}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    
                    <mobiscroll.Form className={classes.form}>
                        <mobiscroll.FormGroup>
                            <mobiscroll.Listview
                                lang="es"
                                theme="ios"
                                itemType={ListEventEveryoneItem}
                                data={[{
                                    name: i18n.t('peopleFilter.everyone'),
                                    onClick: this.toggleAllUsers,
                                    selected: allUsersSelected
                                }]}
                                select="off"
                            />
                        </mobiscroll.FormGroup>

                        <mobiscroll.FormGroup>
                            <mobiscroll.Listview
                                lang="es"
                                theme="ios"
                                itemType={ListEventUserItem}
                                data={people.allPeople && people.allPeople.map((person, i) => {
                                    return {
                                        ...person,
                                        index: i,
                                        selected: selectedUsers.indexOf(person.userId) !== -1,
                                        toggleSelectUser: this.toggleSelectUser
                                    }
                                })}
                                select="off"
                            />
                        </mobiscroll.FormGroup>

                    </mobiscroll.Form>

                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                            <mobiscroll.Button color="primary" disabled={!allUsersSelected && selectedUsers.length === 0} onClick={this.handleFilter}>
                                {i18n.t("visits.filters.selectUsers")}
                                {
                                    // searching && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                                }
                            </mobiscroll.Button>
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        peopleActions: bindActionCreators(peopleActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people,
        account: state.account,
        general: state.general,
        apiStatus: state.apiStatus
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventPeopleFilter))