import React, { Component } from 'react'
import {
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    withMobileDialog,
    withStyles,
} from '@material-ui/core'
import i18n from 'i18next';
import {
    Home,
    AccountCircle,
    EventNote,
    CalendarViewDay,
    ThumbsUpDown
} from '@material-ui/icons'
import { withRouter } from "react-router";
import AppVersion from '../AppVersion';

import API from '../../lib/api'
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import auth from '../../authentication/auth';
import * as eventsActions from '../../actions/eventsActions'



const drawerWidth = 240;

const styles = (theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    menu__list__item: {
        borderRadius: 0
    },
    'menu__list__item--selected': {
        backgroundColor: 'rgba(116,185,255,0.3)',
        borderRadius: 0
    },
    menu__list__item__icon: {
        minWidth: 0,
        marginRight: 10,
        fontSize: '10pt'
    },
    'menu__list__item__icon--selected': {
        minWidth: 0,
        marginRight: 10,
        color: theme.palette.getContrastText('rgba(116,185,255,0.3)'),
        fontSize: '10pt'
    },
}))


class EventsLeftSidebar extends Component {
    state = {
        selected: '',
        logedInUser: null,
        user: null,
        userRoles: null,
        openProfile: false
    }

    navigate = (path) => {
        const { history, toggleOpenMenu } = this.props;
        toggleOpenMenu();
        history.push(path);
    }
    componentWillMount = () => {
        this.getUser()
    }

    getUser = () => {
        auth.getAuthenticatedUser()
            .then(user => {
                API.Users.getUserRoles({ userId: user.attributes['custom:userID'] }).then(res => {
                    let userRoles = res.data.records.map((elem, i) => {
                        return elem.roleId
                    })

                    this.setState({
                        logedInUser: user,
                        userRoles: userRoles
                    })
                })
            }).catch(err => {
                this.setState({ logedInUser: null })
            })
    }



    componentDidMount = () => {
        const { params } = this.props.match;
        if (params.type === 'calendar') {
            this.setState({
                selected: params.subsection
            })
        }
        if (params.type === 'list') {
            this.setState({
                selected: params.subsection
            })
        }
    }

    reloadEvents = () => {
        API.Events
            .refreshEvents().then(res => {
                let newEvents = res.data.records && res.data.records.length > 0 ? res.data.records.map(event => {
                    return {
                        ...event,
                        start: event.from,
                        end: event.to,
                        color: event.user.color,
                        text: event.isVisit ? event.client.name : event.title
                    }
                }) : [];
                this.props.eventsActions.setEvents(newEvents)
                this.props.toggleOpenMenu()
            })
    }

    render() {
        const {
            menuVisible,
            toggleOpenMenu,
            classes
        } = this.props,
            {
                selected,
                logedInUser,
            } = this.state;

        return (
            <div>
                <Drawer open={menuVisible}
                    onClose={toggleOpenMenu}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <List>
                        <ListItem button onClick={() => this.navigate('/dashboard')}>
                            <ListItemIcon
                                className={classes.menu__list__item__icon}>
                                <Home fontSize='inherit' />
                            </ListItemIcon>
                            <ListItemText>{i18n.t("sideBar.home")}</ListItemText>
                        </ListItem>
                        <Divider />

                        <ListItem button onClick={() => this.navigate('/visits/deals')}>
                            <ListItemIcon
                                className={classes.menu__list__item__icon}>
                                <ThumbsUpDown fontSize='inherit' />
                            </ListItemIcon>
                            <ListItemText>{i18n.t("sideBar.deals")}</ListItemText>
                        </ListItem>
                        <Divider />

                        <ListItem button onClick={() => {
                            this.navigate('/visits/calendar/month');
                            this.setState({
                                selected: 'month'
                            })
                        }}>
                            <ListItemIcon className={classes.menu__list__item__icon}>
                                <EventNote fontSize='inherit' />
                            </ListItemIcon>
                            <ListItemText>{i18n.t("visitsSideBar.calendar")}</ListItemText>
                        </ListItem>
                        {/* <Divider style={{ marginLeft: 40 }} /> */}
                        {/* <Collapse in={true} timeout="auto" unmountOnExit >
                            <List component="div">
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/calendar/month');
                                    this.setState({
                                        selected: 'month'
                                    })
                                }}
                                    className={selected === 'month' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.month")}</ListItemText>
                                </ListItem>
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/calendar/week');
                                    this.setState({
                                        selected: 'week'
                                    })
                                }}
                                    className={selected === 'week' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.week")}</ListItemText>
                                </ListItem>
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/calendar/day');
                                    this.setState({
                                        selected: 'day'
                                    })
                                }}
                                    className={selected === 'day' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.day")}</ListItemText>
                                </ListItem>
                            </List>
                        </Collapse> */}



                        <Divider />
                        <ListItem button onClick={() => {
                            this.navigate('/visits/list/scheduled');
                            this.setState({
                                selected: 'scheduled'
                            })
                        }}>
                            <ListItemIcon className={classes.menu__list__item__icon}>
                                <CalendarViewDay fontSize='inherit' />
                            </ListItemIcon>
                            <ListItemText>{i18n.t("visitsSideBar.list")}</ListItemText>
                        </ListItem>
                        <Divider style={{ marginLeft: 40 }} />
                        <Collapse in={true} timeout="auto" unmountOnExit >
                            <List component="div">
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/list/scheduled');
                                    this.setState({
                                        selected: 'scheduled'
                                    })
                                }}
                                    className={selected === 'scheduled' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.scheduled")}</ListItemText>
                                </ListItem>
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/list/past');
                                    this.setState({
                                        selected: 'past'
                                    })
                                }}
                                    className={selected === 'past' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.missed")}</ListItemText>
                                </ListItem>
                                <ListItem button onClick={() => {
                                    this.navigate('/visits/list/cancelled');
                                    this.setState({
                                        selected: 'cancelled'
                                    })
                                }}
                                    className={selected === 'cancelled' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.cancelled")}</ListItemText>
                                </ListItem>


                                <ListItem button onClick={() => {
                                    this.navigate('/visits/list/recently');
                                    this.setState({
                                        selected: 'recently'
                                    })
                                }}
                                    className={selected === 'recently' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.recently")}</ListItemText>
                                </ListItem>


                                <ListItem button onClick={() => {
                                    this.navigate('/visits/list/reports');
                                    this.setState({
                                        selected: 'reports'
                                    })
                                }}
                                    className={selected === 'reports' ? classes['menu__list__item--selected'] : classes.menu__list__item}>
                                    <ListItemText>{i18n.t("visitsSideBar.reports")}</ListItemText>
                                </ListItem>


                            </List>
                        </Collapse>
                        <Divider />
                    </List>
                    <List style={{ position: 'absolute', bottom: '0', margin: 'auto' }}>
                        {logedInUser && (
                            <ListItem>
                                <ListItemIcon className={classes.menu__list__item__icon}>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText>{logedInUser.attributes.name ? logedInUser.attributes.name : logedInUser.username}</ListItemText>
                            </ListItem>

                        )}

                        <ListItem>
                            <AppVersion />
                        </ListItem>
                    </List>
                </Drawer>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withMobileDialog()(EventsLeftSidebar))));