import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Button,
    Link,
    withStyles
} from '@material-ui/core/';

const styles = theme => ({
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
    cancelButton: {
        width: '110px',
        cursor: 'pointer'
    }
})

class ConfirmationLoseChangesDialog extends React.Component {
    render() {

        const {
            message,
            confirmLabel,
            cancelLabel,
            saveAndExitLabel,
            open,
            loading,
            title,
            classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.props.handleClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                {title &&
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Link
                        className={classes.cancelButton}
                        size='small'
                        variant="button"
                        color="secondary"
                        onClick={this.props.handleClose}>
                        {cancelLabel}
                    </Link>
                    <div className={classes.button__wrapper}>
                        <Button
                            style={{ width: '110px', marginRight: '5px' }}
                            disabled={loading}
                            color="primary"
                            size='small'
                            variant="contained"
                            onClick={this.props.onSaveAndExit}>
                            {saveAndExitLabel}
                        </Button>
                        <Button
                            style={{ width: '110px' }}
                            disabled={loading}
                            color="primary"
                            size='small'
                            variant="contained"
                            onClick={this.props.onConfirm}>
                            {confirmLabel}
                        </Button>
                        {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmationLoseChangesDialog);