import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Grid,
    CircularProgress,
    Link,
    Button,
    Card,
    withMobileDialog
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Phone,
    Drafts,
    Done,
    Warning,
    Map,
    OpenInNew,
    //Search,
} from '@material-ui/icons'
import config from '../../config';
import { EventFormSub2, EventClientForm, EventClientContactForm } from '.'
import Geocode from 'react-geocode';
import { SlideLeft } from '../general/Transitions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as clientsActions from '../../actions/clientsActions'
import * as eventsActions from '../../actions/eventsActions'
import API from '../../lib/api';
import { isMobile } from 'react-device-detect';
import GoogleMap from '../general/GoogleMap'

const GOOGLE_API_KEY = config.google.mapsAPIKey;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    map__container: {
        height: '320px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    button__wrapper: {
        margin: `${theme.spacing()}px 0`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    buttonSpinner: theme.buttonSpinner,
    events__form__title__back: {
        fontSize: '30px'
    },
    event__client__icon: {
        fontSize: '11pt',
        marginRight: '5px',
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    form: {
        height: '100%'
    },
    requiredInput: {
        background: '#ffffa3 !important',
        borderBottom: `2px solid ${theme.palette.error.main}`
    },
    event__addressLookup__disabled: {
        color: '#ccc',
        pointerEvents: 'none',
    },
    event__addressLookup: {
        fontSize: '40px',
        paddingRight: theme.spacing(1),
    },
    maps__links__containers: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 20
    },
    maps__link: {
        cursor: 'pointer',
        fontSize: '1em'
    },
    location__error__message__container: {
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    address__focussed: {
        border: `solid thin orange`
    },
    dialog__paper: {
        width: '100%'
    },
    events__editButton: {
        padding: '0 !important',
        textDecoration: 'underline',
        background: 'transparent !important'
    },
});

class ListEventTypeItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id} data-role={item.header ? "list-divider" : ""}
                data-selected={item.selected}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {item.value}
                {item.selected && !item.header ? <Done style={{ color: '#3f97f6' }} /> : ''}
            </li>
        )
    }
}

const options = [
    {
        id: 1,
        value: "Event"
    },
    {
        id: 2,
        header: true,
        value: ""
    },
    {
        id: 4,
        value: 'Sales Visit'
    }, {
        id: 5,
        value: 'Followup'
    }, {
        id: 6,
        value: 'Technical Visit'
    },
    {
        id: 7,
        value: 'Call'
    }
]
let timeout = null;
class EventFormSub1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openEventFormSub2: false,
            formType: null,
            geoLocalization: null,
            placeMessage: null,
            validating: false,
            searching: false,
            changes: false,
            eventType: props.event && props.event.visit ? props.event.visit.type || '' : '',
            results: props.event && props.event.visit ? props.event.visit.results || '' : '',
            nextSteps: props.event && props.event.visit ? props.event.visit.nextSteps || '' : '',
            client: props.event ? props.event.client || null : null,
            clientContact: props.event ? props.event.clientContact || null : null,
            location: props.event ? props.event.place || null : null,
            displayMap: false,
            deals: null,
            typing: false,
            locationConfirmed: props.event && props.event.locationConfirmed ? true : (props.event && props.event.id && props.event.place && props.event.place.googleAddress && (props.event.place.googleAddress === props.event.place.address) ? true : false),
            higlightConfirmationCard: false,
            fixingAddress: false,
            openEventClientForm: false,
            openEventClientContactForm: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            eventType: nextProps.event && nextProps.event.visit ? nextProps.event.visit.type || '' : '',
            results: nextProps.event && nextProps.event.visit ? nextProps.event.visit.results || '' : '',
            nextSteps: nextProps.event && nextProps.event.visit ? nextProps.event.visit.nextSteps || '' : '',
            client: nextProps.event ? nextProps.event.client || null : null,
            clientContact: nextProps.event ? nextProps.event.clientContact || null : null,
            location: nextProps.event ? nextProps.event.place || null : null,

        }, () => {
            if (this.state.location && this.state.location.address && !this.state.location.googleAddress) {
                this.validateAddress()
            }
        })

    }

    onEnter = () => {
        let props = this.props;
        this.setState({
            openEventFormSub2: false,
            formType: null,
            geoLocalization: null,
            placeMessage: null,
            validating: false,
            searching: false,
            changes: false,
            eventType: props.event && props.event.visit ? props.event.visit.type || '' : '',
            results: props.event && props.event.visit ? props.event.visit.results || '' : '',
            nextSteps: props.event && props.event.visit ? props.event.visit.nextSteps || '' : '',
            client: props.event ? props.event.client || null : null,
            clientContact: props.event ? props.event.clientContact || null : null,
            location: props.event && props.event.place ? props.event.place : null,
            displayMap: false,
            typing: false,
            locationConfirmed: props.event && props.event.locationConfirmed ? true : (props.event && props.event.id && props.event.place && props.event.place.googleAddress && (props.event.place.googleAddress === props.event.place.address) ? true : false),
            higlightConfirmationCard: false
        }, () => {
            if (!this.state.locationConfirmed && this.state.location && this.state.location.address && !this.state.location.googleAddress) {
                this.validateAddress()
            }
            //TESTING SOMETHIN ON IOS
            // if (this._locationText) {
            //     this._locationText.focus();
            // }
        })

        if (!this.state.deals) {
            let visitId = props.event && props.event.visit ? props.event.visit.id : null

            API.Deals.getDeals({ userId: props.account.userId, visitId })
                .then(res => {
                    if (res.data.records) {
                        this.setState({
                            deals: res.data.records.data ? res.data.records.data : res.data.records
                        })
                    }
                })
        }
    }

    selectType = (e) => {
        const { event } = this.props

        let newEvent = {
            ...event,
            visit: {
                ...event.visit,
                type: options[e.index].value
            }
        }

        this.setState({
            eventType: options[e.index].value
        })

        this.props.eventsActions.setEvent(newEvent)
        this.props.onClose()
    }
    toggleMap = () => {
        this.setState({
            displayMap: !this.state.displayMap
        })
    }

    manageEvent = (event) => {

        let body = {
            ...event,
            clientId: event.client && event.client.id ? event.client.id : null,
            clientContactId: event.clientContact && event.clientContact.id ? event.clientContact.id : null,
            isPrivate: event.private,
            place: event.place && event.place.address ? event.place.address : null,
            googleAddress: event.place && event.place.googleAddress ? event.place.googleAddress : null,
            placeLat: event.place && event.place.lat ? event.place.lat : null,
            placeLng: event.place && event.place.lng ? event.place.lng : null,
            checkInAddress: event.checkIn ? event.checkIn.address : null,
            checkInLat: event.checkIn ? event.checkIn.lat : null,
            checkInLng: event.checkIn ? event.checkIn.lng : null,
            checkInDateTime: event.checkIn ? event.checkIn.dateTime : null,
            checkOutAddress: event.checkOut ? event.checkOut.address : null,
            checkOutLat: event.checkOut ? event.checkOut.lat : null,
            checkOutLng: event.checkOut ? event.checkOut.lng : null,
            checkOutDateTime: event.checkOut ? event.checkOut.dateTime : null,
            visit: {
                ...event.visit,
                status: event.visit.status.id
            }
        }

        API.Events.updateEvent({ event: body }).then(res => {
            if (res.data) {
                this.props.eventsActions.updateEvent(res.data.record)
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    onClose = () => {
        const { client, clientContact,
            location, geoLocalization,
            locationConfirmed
        } = this.state,
            { type, event, readOnly, user } = this.props;

        let newEvent = { ...event }

        switch (type) {
            case 'client':
                if ((!client || (client && !client.id)) && (!clientContact || (clientContact && !clientContact.id))) {
                    this.props.onClose()
                    return
                }

                this.setState({
                    validating: true
                })
                if (client && client.id && (!clientContact || !clientContact.id)) {
                    return
                }
                this.setState({
                    validating: false
                })
                if (client && client.id && !location) {
                    let clientAddressComplete = `${(client.address.address1 ? client.address.address1 + ', ' : '')}${(client.address.address2 ? (client.address.address2 + ', ') : '')}${client && client.country.id ? client.country.name || '' : user ? user.userCountry.name : ''}`;

                    Geocode.fromAddress(clientAddressComplete).then(
                        response => {
                            if (response.status === 'OK') {
                                newEvent = {
                                    ...event,
                                    place: {
                                        address: clientAddressComplete,
                                        googleAddress: response.results[0].formatted_address,
                                        lat: response.results[0].geometry.location.lat,
                                        lng: response.results[0].geometry.location.lng
                                    },
                                    locationConfirmed: clientAddressComplete !== response.results[0].formatted_address ? false : true,
                                }

                                this.props.eventsActions.setEvent(newEvent)

                                if (readOnly) {
                                    this.manageEvent(newEvent)
                                }

                                this.props.onClose()

                                return
                            } else {
                                this.setState({
                                    searching: false,
                                    geoLocalization: null,
                                    placeMessage: null
                                })
                            }
                        },
                        error => {
                            //Error buscando la direccion.
                            console.error(error);
                            this.setState({
                                geoLocalization: null,
                                placeMessage: i18n.t('visits.eventForm.invalidAddress')
                            })
                        }
                    );
                }

                break;
            case 'visitReport':
                this.props.onClose()
                return
            case 'location':
                this.setState({
                    validating: true,
                })
                if (location && geoLocalization && locationConfirmed) {
                    newEvent = {
                        ...event,
                        place: {
                            address: location.address,
                            googleAddress: geoLocalization.formatted_address,
                            lat: geoLocalization.geometry.location.lat,
                            lng: geoLocalization.geometry.location.lng
                        }
                    }
                } else if (!readOnly) {
                    if (!locationConfirmed) {
                        this.setState({
                            higlightConfirmationCard: true
                        })
                        return
                    }

                    if (location && !location.googleAddress) {
                        this.setState({
                            placeMessage: i18n.t('visits.eventForm.lookupAddress')
                        })
                        return
                    }
                }
                this.setState({
                    validating: false,
                    placeMessage: null
                })
                break
            default:
                break;
        }

        this.props.eventsActions.setEvent(newEvent)

        if (readOnly) {
            this.manageEvent(newEvent)
        }
        this.setState({
            displayMap: false
        })
        this.props.onClose()
    }

    confirmAddress = () => {
        const { event } = this.props,
            { location, geoLocalization, } = this.state;
        let newEvent = {
            ...event,
            place: {
                address: geoLocalization ? geoLocalization.formatted_address : location.googleAddress,
                googleAddress: geoLocalization ? geoLocalization.formatted_address : location.googleAddress,
                lat: geoLocalization ? geoLocalization.geometry.location.lat : location.lat,
                lng: geoLocalization ? geoLocalization.geometry.location.lng : location.lng
            },
            locationConfirmed: true,
        }

        this.props.eventsActions.setEvent(newEvent)

        this.setState({
            locationConfirmed: true,
            fixingAddress: false,
            validating: false,
            higlightConfirmationCard: false
        }, () => {
            setTimeout(() => {
                this._locationText.focus()
            }, 500)
        })
    }

    inputChange = (e) => {
        if (e.target.name === 'results' || e.target.name === 'nextSteps') {
            this.setState({
                changes: true
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    inputLocationChange = (e) => {
        this.setState({
            location: {
                address: e.target.value
            }
        })
    }
    addressKeyDown = () => {
        const { location } = this.state;

        this.setState({
            typing: true,
            geoLocalization: null,
            placeMessage: null,
            locationConfirmed: false,
            fixingAddress: true,
        })

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            if (location && location.address && location.address.trim().length >= 3) {
                this.validateAddress()
            } else if (location.address.trim().length < 3 && location.address.trim().length > 0) {
                this.setState({
                    placeMessage: i18n.t('Visit address seems to be too short. Please verify entered address is a valid location address.')
                })
            } else if (location.address.trim().length === 0) {
                this.setState({
                    placeMessage: i18n.t('Visit address can not be empty. Please enter a valid a valid location address.')
                })
            }


            clearTimeout(timeout);
            timeout = null;
        }, 700)
    }

    validateAddress = () => {
        const { location } = this.state;
        if (location.address && location.address.trim() !== '') {
            this.setState({
                searching: true,
                //fixingAddress: false
            })
            Geocode.fromAddress(location.address).then(
                response => {
                    if (response.status === 'OK') {
                        this.setState({
                            searching: false,
                            geoLocalization: response.results[0],
                            placeMessage: null,
                            typing: false
                        })

                    } else {
                        this.setState({
                            searching: false,
                            geoLocalization: null,
                            placeMessage: null,
                            typing: false
                        })
                    }
                },
                error => {
                    //Error buscando la direccion.
                    this.setState({
                        geoLocalization: null,
                        placeMessage: i18n.t('visits.eventForm.invalidAddress'),
                        typing: false,
                        searching: false
                    })
                }
            );
        }
    };

    showSubFormPopUp = (type) => {
        this.setState({
            formType: type
        }, () => {
            this.setState({
                openEventFormSub2: true
            })
        })
    }

    saveVisitReport = () => {
        const { event } = this.props,
            { results, nextSteps } = this.state;

        this.setState({
            saving: true
        })

        let newEvent = {
            ...event,
            visit: {
                ...event.visit,
                results: results,
                nextSteps: nextSteps
            }
        }

        this.props.eventsActions.setEvent(newEvent)
        this.manageEvent(newEvent)
        this.setState({
            saving: false
        })
        this.props.onClose()
    }


    mapsSelector = () => {
        let { geoLocalization, location } = this.state,
            address = geoLocalization ? geoLocalization.formatted_address : location.googleAddress ? location.googleAddress : '';
        if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") !== -1) ||
            (navigator.platform.indexOf("iPad") !== -1) ||
            (navigator.platform.indexOf("iPod") !== -1))
            window.open(`maps://maps.google.com/?q=${address}`);
        else /* else use Google */
            window.open(`https://maps.google.com/?q=${address}`);
    }

    updateClient = (client) => {
        const { event } = this.props

        this.setState({
            selectedElement: client
        })

        let newEvent = {
            ...event,
            client: client,
            clientContact: null
        }

        this.props.eventsActions.setEvent(newEvent)
    }

    updateClientContact = (contact, clientId) => {
        const { event, clients } = this.props

        let index = clients.findIndex(cl => cl.id === clientId)

        this.setState({
            selectedElement: contact
        })

        let newEvent = {
            ...event,
            client: {
                ...event.client,
                contacts: clients[index].contacts
            },
            clientContact: contact
        }

        this.props.eventsActions.setEvent(newEvent)
    }

    showClientPopup = () => {
        this.setState({
            openEventClientForm: true
        })
    }

    showClientContactPopup = () => {
        this.setState({
            openEventClientContactForm: true
        })
    }

    addDeal = (newDeal) => {
        let deals = [...this.state.deals]
        deals.push(newDeal)

        this.setState({
            deals: deals
        })
    }

    render() {
        const {
            classes,
            type,
            readOnly,
            user,
            event,
            fullScreen
        } = this.props,
            { results,
                nextSteps,
                formType,
                placeMessage,
                geoLocalization,
                eventType,
                location,
                openEventFormSub2,
                client,
                clientContact,
                validating,
                searching, saving,
                displayMap,
                typing,
                locationConfirmed,
                higlightConfirmationCard,
                fixingAddress,
                deals,
                openEventClientForm,
                openEventClientContactForm
            } = this.state;

        let showSuggestedAddress = false;

        if (location && location.googleAddress !== location.address) {
            showSuggestedAddress = true;
        }

        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    onEnter={this.onEnter}
                    open={this.props.open || false}
                    TransitionComponent={SlideLeft}
                >
                    <DialogTitle disableTypography={true} className={classes.events__form__title}>
                        <IconButton onClick={this.onClose}>
                            <KeyboardArrowLeft
                                color='inherit'
                                className={classes.events__form__title__back} />
                        </IconButton>
                        <Typography variant='body1' color="inherit">
                            {type === 'client' ? i18n.t('visits.eventForm.visitClient') : type === 'location' ? i18n.t('visits.eventForm.visitLocation') : type === 'eventType' ? i18n.t('visits.eventForm.eventType') : i18n.t('visits.eventForm.visitReport')}
                        </Typography>
                        <div style={{ width: '60px' }} />
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        {type === 'client' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label
                                        style={{
                                            alignItems: 'center'
                                        }}
                                        onClick={((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) ? () => this.showSubFormPopUp('country') : () => { }}>
                                        {i18n.t('visits.eventForm.visitClientCountry')}
                                        <input
                                            value={client && client.country.id ? client.country.name || '' : user ? user.userCountry.name : ''}
                                            name='country'
                                            disabled />
                                        {((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) &&
                                            <KeyboardArrowRight
                                                style={{
                                                    color: '#ccc'
                                                }} />
                                        }
                                    </mobiscroll.Form.Label>

                                    <mobiscroll.Form.Label
                                        style={{ alignItems: 'center' }}
                                        onClick={((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) ? () => this.showSubFormPopUp('client') : () => { }}>
                                        {i18n.t('visits.eventForm.visitClient')}
                                        <input value={client ? client.name || '' : ''} name='client' disabled />
                                        {((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) &&
                                            <KeyboardArrowRight style={{ color: '#ccc' }} />
                                        }
                                    </mobiscroll.Form.Label>

                                    {client && client.id &&
                                        <Grid container spacing={2}
                                            style={{ padding: '24px', width: '100%', margin: 0 }} >
                                            <Grid item xs={12} style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <Typography
                                                    variant='body2'
                                                    color="textSecondary"
                                                    style={{ fontSize: '1.2em' }}>
                                                    {client.name || ''}
                                                </Typography>

                                                {((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) &&
                                                    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                        <mobiscroll.Button className={classes.events__editButton} onClick={this.showClientPopup}>{i18n.t("visits.client.edit")}</mobiscroll.Button>
                                                    </div>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant='body2'
                                                    color="textSecondary"
                                                    style={{ fontSize: '1em' }}>
                                                    {client.taxId &&
                                                        <>
                                                            {client.taxId || ''}
                                                            <br />
                                                        </>}
                                                    {client.address.address1 || (i18n.t('No address'))}
                                                    <br />
                                                    {client.address.address2 &&
                                                        <>
                                                            {client.address.address2 || ''}
                                                        </>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' color="textSecondary" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', fontSize: '1em' }}>
                                                    {client.phone &&
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Phone className={classes.event__client__icon} /> <a style={{ marginLeft: '4px' }} href={`tel://${client.phone}`}>{client.phone}</a>
                                                        </span>
                                                    }
                                                    {client.fax &&
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <strong>{i18n.t('quotes.clientRFQ.fax')}</strong><a style={{ marginLeft: '4px' }} href={`tel://${client.fax}`}>{client.fax}</a>
                                                        </span>
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {client && client.id && (
                                        <mobiscroll.Form.Label style={{ alignItems: 'center' }} className={validating && (!clientContact || !clientContact.id) ? classes.requiredInput : ''}
                                            onClick={((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) ? () => this.showSubFormPopUp('clientContact') : () => { }}>
                                            {i18n.t('visits.eventForm.visitClientContact')}
                                            <input value={`${clientContact ? clientContact.firstName || '' : ''} ${clientContact ? clientContact.lastName || '' : ''}`} name='clientContact' disabled />
                                            {((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) &&
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            }
                                        </mobiscroll.Form.Label>
                                    )}

                                    {client && client.id && clientContact && clientContact.id &&
                                        <Grid container spacing={2} style={{ padding: '24px 24px 0', width: '100%', margin: 0 }} >
                                            <Grid item xs={12} style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant='body2' color="textSecondary" style={{ fontSize: '1.2em' }}>
                                                    {clientContact.firstName || ''} {clientContact.lastName || ''}
                                                </Typography>
                                                {((event && event.isVisit === 1 && event.visit.status.id !== 3) || (event && event.visit && !event.visit.status)) && (event && event.visit && ((event.checkIn && !event.checkIn.address) || (!event.checkIn))) &&
                                                    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                        <mobiscroll.Button className={classes.events__editButton} onClick={this.showClientContactPopup}>{i18n.t("visits.client.edit")}</mobiscroll.Button>
                                                    </div>
                                                }
                                            </Grid>
                                            {clientContact.email &&
                                                <Grid item xs={12}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <Drafts className={classes.event__client__icon} />
                                                        <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            <a href={`mailto:${clientContact.email || ''}`}>{clientContact.email || ''}</a>
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            }
                                            {clientContact.phone &&
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' color="textSecondary" style={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                                        <Phone className={classes.event__client__icon} /><a href={`tel://${clientContact.phone}`}>{clientContact.phone}</a>
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }

                        {type === 'eventType' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Listview
                                        lang="es"
                                        theme="ios"
                                        itemType={ListEventTypeItem}
                                        onItemTap={this.selectType}
                                        data={options.map(i => {
                                            if (eventType && i.value === eventType) {
                                                return {
                                                    ...i,
                                                    id: i.value,
                                                    selected: true
                                                }
                                            } else {
                                                return {
                                                    ...i,
                                                    id: i.value,
                                                    selected: false
                                                }
                                            }
                                        })}
                                        select="single"
                                    />
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }

                        {type === 'visitReport' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Textarea value={results} name='results' onChange={this.inputChange}>
                                        {i18n.t('visits.eventForm.results')}
                                    </mobiscroll.Textarea>
                                    <mobiscroll.Textarea value={nextSteps} name='nextSteps' onChange={this.inputChange}>
                                        {i18n.t('visits.eventForm.nextSteps')}
                                    </mobiscroll.Textarea>
                                </mobiscroll.FormGroup>

                                {readOnly &&
                                    <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                                        <mobiscroll.Button color="primary" disabled={saving} onClick={this.saveVisitReport}>
                                            {i18n.t("visits.eventForm.saveEvent")}
                                            {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                        </mobiscroll.Button>
                                    </div>
                                }

                                <mobiscroll.FormGroup>
                                    <mobiscroll.FormGroupTitle>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
                                            {i18n.t('visits.eventForm.deals')}
                                        </div>
                                    </mobiscroll.FormGroupTitle>
                                    <mobiscroll.Form.Label
                                        onClick={() => this.showSubFormPopUp('deals')} style={{ alignItems: 'center' }}>
                                        {!deals ?
                                            <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                                            : <>
                                                {`${deals && deals.length > 0 ? `${deals.length} ${deals.length === 1 ? 'Deal' : 'Deals'}` : i18n.t('visits.eventForm.noDeals')}`}
                                                <input value={deals && deals.length > 0 ? '' : 'Create a deal'} name='type' disabled />
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            </>}
                                    </mobiscroll.Form.Label>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }

                        {type === 'location' &&
                            <mobiscroll.Form className={classes.form} onSubmit={(event) => {
                                event.preventDefault();
                            }}>
                                <mobiscroll.FormGroup style={{
                                    position: 'relative',
                                    height: '90%',
                                    backgroundColor: '#efeff4'
                                }}>

                                    {(!showSuggestedAddress || fixingAddress || locationConfirmed || readOnly) && (
                                        <>
                                            <Typography variant='body2' color='textSecondary'
                                                style={{
                                                    margin: '0 0 10px 10px'
                                                }}
                                            >
                                                {i18n.t('Visit address')}
                                            </Typography>

                                            <mobiscroll.Form.Label
                                                style={{
                                                    alignItems: 'center'
                                                }}
                                                className={validating && ((location && !location.googleAddress) || higlightConfirmationCard) ? classes.requiredInput : (fixingAddress ? classes.address__focussed : '')}>
                                                <textarea
                                                    ref={(comp) => { this._locationText = comp; }}
                                                    rows={3}
                                                    disabled={readOnly || (event && event.visit && event.checkIn && event.checkIn.address) ? true : false}
                                                    value={location ? location.address : ''}
                                                    onChange={this.inputLocationChange}
                                                    onKeyDown={this.addressKeyDown}
                                                    onFocus={(event) => event.target.select()} />
                                            </mobiscroll.Form.Label>
                                        </>
                                    )}

                                    {searching && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}

                                    {(geoLocalization || (location && location.googleAddress)) && !searching && !typing ?
                                        <>

                                            {/* SUGESTIN ADDRESS CARD */}
                                            {/* TODO: Sacar esto a un compoente */}
                                            {showSuggestedAddress && !readOnly && !locationConfirmed ? (
                                                <Card style={{
                                                    margin: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    border: higlightConfirmationCard ? 'solid thin orange' : undefined
                                                }}>
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Warning style={{ color: 'orange', marginRight: 5 }} />
                                                            <Typography variant='body1'
                                                                color='textPrimary'
                                                                style={{ margin: '16px', }}>
                                                                {i18n.t('Address verification')}
                                                            </Typography>
                                                        </div>
                                                        <Typography
                                                            style={{
                                                                padding: '0 16px',
                                                                marginBottom: 16
                                                            }}
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {i18n.t('The visit address does not match with the suggested address by Google Maps Service.')}
                                                            <br />
                                                            {i18n.t('Please verify visit address.')}
                                                        </Typography>
                                                        {/* Suggested address */}
                                                        <div style={{
                                                            backgroundColor: '#0080000f',
                                                            border: 'solid thin green',
                                                            padding: '16px',
                                                            margin: 10,
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}>
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {i18n.t('Google suggested address')}
                                                            </Typography>
                                                            <Typography variant='body1'
                                                                color='textPrimary'>
                                                                {geoLocalization ? geoLocalization.formatted_address : location.googleAddress ? location.googleAddress : ''}
                                                            </Typography>

                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                marginTop: 16,
                                                                alignItems: 'center'
                                                            }}>

                                                                <Link
                                                                    onClick={this.toggleMap}
                                                                    variant='button'
                                                                >
                                                                    {!displayMap ?
                                                                        <>
                                                                            <Map />
                                                                        </> :
                                                                        <>
                                                                            <Map style={{ color: '#3d3d3d' }} />
                                                                        </>}
                                                                </Link>
                                                                <Link
                                                                    variant='button'
                                                                    style={{
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.confirmAddress()
                                                                    }}>
                                                                    {i18n.t('Use Suggested Address')}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {/* Original Addres */}
                                                        <div
                                                            style={{
                                                                border: 'solid thin #dedede',
                                                                padding: '16px',
                                                                margin: 10,
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {i18n.t('Original address')}
                                                            </Typography>
                                                            <Typography
                                                                variant='body1'
                                                                color='textPrimary'>
                                                                {location.address}
                                                            </Typography>
                                                            <Link
                                                                variant='button'
                                                                style={{
                                                                    marginTop: 10,
                                                                    alignSelf: 'flex-end'
                                                                }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        fixingAddress: true
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            this._locationText.focus()
                                                                        }, 500)
                                                                    })
                                                                }}>
                                                                {i18n.t('Edit Current Address')}
                                                            </Link>
                                                        </div>


                                                    </div>
                                                </Card>
                                            ) : (
                                                    <div className={classes.maps__links__containers}>
                                                        <IconButton onClick={this.toggleMap}
                                                            className={classes.maps__link}
                                                        >
                                                            {!displayMap ?
                                                                <>
                                                                    <Map style={{ marginRight: 5 }} />
                                                                    {i18n.t('View map')}
                                                                </> :
                                                                <>
                                                                    <Map style={{ color: '#3d3d3d' }} />
                                                                    {i18n.t('Hide map')}
                                                                </>}
                                                        </IconButton>
                                                        {(false && isMobile) && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showConfirmOpenExternalMap: true
                                                                    })
                                                                }}
                                                                className={classes.maps__link}
                                                            >
                                                                <OpenInNew />
                                                                {i18n.t('Maps')}
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                )}


                                            {displayMap && (
                                                <div className={classes.map__container}>
                                                    <GoogleMap
                                                        location={{
                                                            positions: [{
                                                                lat: geoLocalization ? geoLocalization.geometry.location.lat : location.googleAddress ? location.lat : '',
                                                                lng: geoLocalization ? geoLocalization.geometry.location.lng : location.googleAddress ? location.lng : '',
                                                                label: 'Location'
                                                            }],
                                                            zoom: 14
                                                        }}
                                                    />
                                                </div>
                                            )}

                                        </> : (
                                            <>
                                                {placeMessage &&
                                                    <div
                                                        className={classes.location__error__message__container}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Warning className={classes.warning__message__icon} />
                                                            <Typography variant='body1' color='textSecondary' >
                                                                {placeMessage}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ display: 'flex', marginTop: 10 }}>
                                                            <Link onClick={() => {
                                                                this._locationText.focus()
                                                            }}>
                                                                {i18n.t('Edit Address')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )}
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }
                    </DialogContent>

                </Dialog>

                <Dialog
                    open={this.state.showConfirmOpenExternalMap || false}
                    classes={{
                        paper: classes.alert__paper
                    }} >

                    <Grid container spacing={1} style={{ padding: '24px 16px' }} >
                        <Grid item xs={12} >
                            <Typography variant='body1' color='textPrimary'>
                                {i18n.t('Do you want to viww visit address in your maps application?')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}>
                        <Button
                            variant='text'
                            style={{ width: 60 }}
                            onClick={() => {
                                this.setState({
                                    showConfirmOpenExternalMap: false,
                                })
                            }}>
                            {i18n.t('Cancel')}
                        </Button>
                        <Button
                            variant='text'
                            style={{ width: 60 }}
                            onClick={() => {
                                this.setState({
                                    showConfirmOpenExternalMap: false,
                                })
                                this.mapsSelector()
                            }}>
                            {i18n.t('Continue')}
                        </Button>
                    </div>
                </Dialog>

                <EventFormSub2
                    open={openEventFormSub2}
                    type={formType}
                    user={user}
                    addDeal={this.addDeal}
                    isOwn={this.props.isOwn}
                    onClose={() => {
                        this.setState({
                            formType: null,
                            openEventFormSub2: false
                        })
                    }}
                />


                <EventClientForm
                    open={openEventClientForm}
                    country={client && client.country.id ? client.country : user ? user.userCountry : null}
                    client={client ? client : null}
                    updateClient={this.updateClient}
                    onClose={() => {
                        this.setState({
                            openEventClientForm: false
                        })
                    }}
                />

                <EventClientContactForm
                    open={openEventClientContactForm}
                    client={client}
                    clientContact={clientContact ? clientContact : null}
                    updateClientContact={this.updateClientContact}
                    onClose={() => {
                        this.setState({
                            openEventClientContactForm: false
                        })
                    }}
                />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        event: state.event,
        clients: state.clients,
        client: state.client,
        account: state.account
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventFormSub1)));
