import React, { Component } from 'react'
import {
    Paper,
    withStyles,
    Button,
    Link,
    Divider,
    Tabs,
    Tab,
} from '@material-ui/core'
import {
    Drafts, DoneOutline
} from '@material-ui/icons'
import i18n from 'i18next';
import { ClientRFQReadOnly } from '../clientRFQ'
import {
    ClientQuoteReadOnly,
    ClientQuoteForm,
    ClientQuoteItemsList,
    QuoteTotals
} from '.'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
//Mientras metemos sagas
import * as generalActions from '../../../actions/generalActions'
import * as clientQuoteActions from '../../../actions/clientQuoteActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'
import * as filesActions from '../../../actions/filesActions'

import Permissions from '../../../lib/permissions'
import { Typography } from '@material-ui/core'


const styles = theme => {
    return ({
        preparing__root__paper: {
            padding: theme.spacing(2)
        },
        print__edit__buttons__container: {
            zIndex: 1,
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(),
            [theme.breakpoints.down('xs')]: {
                position: 'unset',
                padding: '16px 16px 0'
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                justifyContent: 'space-between',
            }
        },
        printButton: {
            ...theme.smallButton,
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.getContrastText(theme.palette.grey[400]),
            },
            color: theme.palette.getContrastText(theme.palette.grey[100]),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2),
            }
        },
        quote__editLink: theme.customLink,
        closeButton: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
        createClientQuote__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `${theme.spacing(3)}px`
        },
        client__quote__title__container: {
            padding: theme.spacing(),
            position: 'absolute'
        },
        clientQuotes__tabs: {
            margin: `0 ${theme.spacing()}px`
        },
        clientQuotes__tabs__flexContainer: {
        },
        clientQuotes__tabs__activeIndicator: {
            backgroundColor: 'transparent',
            zIndex: 11,
            marginLeft: '1px',
            width: 'calc(100% - 2px) !important'
        },
        legend__root__row: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '230px',
            [theme.breakpoints.down('sm')]: {
                width: '200px'
            }
        },
        clientQuote__status__icon__ready: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.secondary.main,
        },
        clientQuotes__tab: {
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100]
        },
        clientQuotes__tab__ready: {
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100],
            marginLeft: 'auto'
        },
        'clientQuotes__tab--selected': {
            backgroundColor: '#fff',
            borderTop: `1px solid ${theme.palette.grey[400]}`,
            borderLeft: `1px solid ${theme.palette.grey[400]}`,
            borderRight: `1px solid ${theme.palette.grey[400]}`,
            borderBottom: 'none',
            color: theme.palette.text.primary,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
            zIndex: 10
        },
        clientQuote__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.red[100],
        },
        clientQuote__detail__rootPaper: {
            margin: theme.spacing(),
            marginTop: 0,
            padding: theme.spacing(2),
            position: 'relative',
        },
        clientQuote__detail__noPaper: {
            boxShadow: 'none',
            margin: theme.spacing(),
            marginTop: 0,
            padding: theme.spacing(2),
            position: 'relative',
        },
        clientQuote__status__icon__requested: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.primary.main,
        },
    })
}

class PreparingQuoteInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editClientQuote: false,
            selectedTab: 0,
        }
    }

    openEditQuoteDialog = () => {
        this.setState({
            editClientQuote: true
        })
    }

    updateClientQuote = (file) => {
        this.setState({
            editClientQuote: false
        })

        this.props.filesActions.setFile(file)
        this.props.generalActions && this.props.generalActions.showSelectItemVendor(false);
    }

    handleChange = (e, value) => {
        this.setState({
            selectedTab: value
        });
    }

    render() {
        const { classes, editable, general, file } = this.props,
            { editClientQuote, selectedTab  } = this.state;

        if (!file) return null;

        let { showSelectItemVendor } = general;
        let firstReadyVQ = false,
            creatingClientQuote = file.clientQuote.length === 1 && file.clientQuote[0] && file.clientQuote[0].items.filter(item => item.amount).length === 0 &&  !file.clientQuote[0].validFor && !file.clientQuote[0].deliveryDays;

        return (
            <Paper className={classes.preparing__root__paper}>
                {file.clientQuote.length > 1 && <>
                    <Tabs
                        className={classes.clientQuotes__tabs}
                        value={selectedTab >= 0 ? selectedTab : 0}
                        onChange={this.handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        classes={{
                            indicator: classes.clientQuotes__tabs__activeIndicator,
                            flexContainer: classes.clientQuotes__tabs__flexContainer,
                            root: classes.file__tabs__root,
                        }}
                        variant="standard"
                        scrollButtons="auto"
                    >
                        {file.clientQuote.map((cq, i) => {
                            let alignToR = false;
                            if ((cq.version < file.clientQuote.length || (cq.version === file.clientQuote.length && file.status === 4)) && !firstReadyVQ) {
                                firstReadyVQ = true;
                                alignToR = true;
                            }
                            return (<Tab
                                key={i}
                                label={(
                                    <div className={classes.legend__root__row}>
                                        {i === 0 && file.status === 3 && (
                                            <Drafts className={classes.clientQuote__status__icon__preparing} />
                                        )}
                                        {(i > 0 || file.status >= 4) && (
                                            <DoneOutline className={classes.clientQuote__status__icon__ready} />
                                        )}

                                        <Typography noWrap >{`Quote Rev-${cq.version}`}</Typography>
                                    </div>)}
                                classes={{
                                    root: classNames(classes.clientQuotes__tab, alignToR ? classes.clientQuotes__tab__ready : ''),
                                    selected: classes['clientQuotes__tab--selected']
                                }}
                            />)
                        })}
                    </Tabs>
                </>}

                {file.clientQuote.map((cq, i) => {
                    if (i === selectedTab) {
                        let items = file.clientQuote[i] ? file.clientQuote[i].items : [];
                        
                        return (
                            <Paper key={i} className={file.clientQuote.length === 1 ? classes.clientQuote__detail__noPaper : classes.clientQuote__detail__rootPaper}>

                                {!creatingClientQuote && <>
                                    <ClientRFQReadOnly
                                        file={file}
                                        isQuote={true}
                                    />

                                    {editable && !creatingClientQuote && Permissions.checkSensitiveInformationPermission() && i === 0 &&
                                        <Divider />
                                    }
                                </>}

                                <div style={{ position: 'relative' }}>
                                    {editable && !creatingClientQuote && Permissions.checkSensitiveInformationPermission() && i === 0 && //file.status === 3 &&
                                        <div className={classes.print__edit__buttons__container}>
                                            <Link className={classes.quote__editLink}
                                                variant='button'
                                                onClick={this.openEditQuoteDialog}>
                                                {i18n.t('quotes.quoted.edit')}
                                            </Link>
                                        </div>
                                    }

                                    {editable && creatingClientQuote && Permissions.checkSensitiveInformationPermission() &&
                                        <div className={classes.createClientQuote__container}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.openEditQuoteDialog}>
                                                {i18n.t("quotes.preparatingQuoteInformation.createClientQuote")}
                                            </Button>
                                        </div>
                                    }

                                    {!creatingClientQuote &&
                                        <>
                                            <ClientQuoteReadOnly
                                                file={file}
                                                pos={i}
                                                preparing={editable}
                                            />

                                            <ClientQuoteItemsList
                                                currencySymbol={file.currencySymbol}
                                                items={items.sort((a, b) => { return a.rfqItem.id - b.rfqItem.id })}
                                                file={file}
                                                clientQuoteTaxPercent={file.clientQuote && file.clientQuote[i] ? file.clientQuote[i].taxPercent : null }
                                                openEditQuoteDialog={this.openEditQuoteDialog}
                                            />

                                            <QuoteTotals
                                                currencySymbol={file.currencySymbol}
                                                items={items}
                                                clientQuoteDate={file && file.clientQuote ? file.clientQuote[0].date : null}
                                                taxPercent={file.clientQuote && file.clientQuote[i] ? file.clientQuote[i].taxPercent : null}
                                                otherCosts={file.clientQuote && file.clientQuote[i] ? file.clientQuote[i].otherCosts : []}
                                            />
                                        </>
                                    }
                                </div>

                            </Paper>
                        )
                    } else {
                        return null;
                    }
                })}

                <ClientQuoteForm
                    file={file}
                    creatingClientQuote={creatingClientQuote}
                    open={editClientQuote || (showSelectItemVendor || false)}
                    editable={editable}
                    updateClientQuote={this.updateClientQuote}
                    onClose={() => {
                        this.setState({
                            editClientQuote: false
                        })
                        this.props.generalActions && this.props.generalActions.showSelectItemVendor(false);
                    }}
                />

            </Paper>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        clientQuoteActions: bindActionCreators(clientQuoteActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreparingQuoteInformation))