import React, { Component } from 'react'
import {
    withStyles,
    withMobileDialog,
    TextField,
    Grid,
    Typography
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import classNames from 'classnames'
import i18n from 'i18next'
import ReactTable from "react-table"
import NumberFormat from 'react-number-format'
import { NumberFormatInputCustom } from '../../general'

import moment from 'moment'
import config from '../../../config'

const styles = theme => ({
    invoice__items__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    invoice__items__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    invoice__items__table__descriptionExtended: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    invoice__items__form__textField: {
        marginTop: theme.spacing()
    },
    invoice__items__table__cell__rigth: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    pendingItems: {
        color: theme.palette.green[100]
    },
    forReceivingItems: {
        color: theme.palette.orange[100],
        whiteSpace: 'break-spaces',
        fontSize: '14px'
    },
    quoteitems__table__quantity: {
        color: theme.palette.text.secondary
    },
    extendeDescription__icon: {
        fontSize: '20px',
        color: theme.palette.grey[500]
    }
})



class InvoiceItems extends Component {
    state = {
        items: this.props.items.sort((a, b) => { return a.rfqItem.id - b.rfqItem.id }),
        selectedDescriptionIndex: null,
        vendorPOs: this.props.vendorPOs
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.items.sort((a, b) => { return a.rfqItem.id - b.rfqItem.id }),
            vendorPOs: nextProps.vendorPOs
        })
    }

    calculateInvoicedItemsQuantity = (row) => {
        const { invoices, invoice, editable } = this.props;

        let selectedItem = null,
            invoicedItems = 0;

        let aux = invoices.map((inv) => {
            if (editable) {
                selectedItem = invoice ? invoice.id !== inv.id ? inv.items.find(item => item.rfqItem.id === row.rfqItemId) : null : inv.items.find(item => item.rfqItem.id === row.rfqItemId)
                if (selectedItem) {
                    invoicedItems = invoicedItems + parseInt(selectedItem.invoiceQuantity || 0)
                }
            } else {
                if (inv.status !== 1) {
                    selectedItem = inv.items.find(item => item.rfqItem.id === row.rfqItemId)
                    if (selectedItem) {
                        invoicedItems = invoicedItems + parseInt(selectedItem.invoiceQuantity || 0)
                    }
                }
            }
            return inv
        })
        if (aux) {
            //Solo para quitar el warning mientras Mary revisa 
        }

        return invoicedItems

    }

    renderInvoiceItemQuantity = (cellInfo) => {
        const { classes, editable, invoice, file } = this.props,
            { items, vendorPOs } = this.state;

        let itemsReceived = [],
            vendorPOsReceived = vendorPOs.filter(vp => vp.items.find(item => !item.deleted && item.rfqItemId === cellInfo.original.rfqItem.id));
        vendorPOsReceived.forEach(vPo => {
            itemsReceived = itemsReceived.concat(vPo.items.filter(vp => !vp.deleted))
        })


        let invoicedItems = this.calculateInvoicedItemsQuantity(cellInfo.original) || 0,
            receivedItems = itemsReceived.filter(i => i.rfqItemId === cellInfo.original.rfqItem.id).reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0),
            diff = receivedItems > 0 ? parseInt(receivedItems) - parseInt(invoicedItems) : null;

        if (editable) {
            if (diff === 0) {
                items[cellInfo.index].invoiceQuantity = 0
                items[cellInfo.index].total = 0
            } else {
                let itemCost = items[cellInfo.index].amount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0)

                let amountWithProfit = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ? 
                    itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100) :
                    items[cellInfo.index].amount + ((items[cellInfo.index].amount * items[cellInfo.index].markupPercent) / 100) + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost / cellInfo.original.quantity : 0);
                
                items[cellInfo.index].total = items[cellInfo.index].invoiceQuantity ? amountWithProfit * items[cellInfo.index].invoiceQuantity : 0
            }

            if (!invoice && items[cellInfo.index].invoiceQuantity === null) {
                items[cellInfo.index].invoiceQuantity = diff || 0
            }
        }

        return (
            <div style={{ textAlign: 'center', width: '100%' }}>
                {!editable ?
                    <Typography>{items[cellInfo.index].invoiceQuantity || 0}</Typography>
                    : <>
                        {diff === 0 ?
                            <Typography className={classes.pendingItems}>{i18n.t("quotes.invoices.invoiced")}</Typography>
                            : <>
                                {!diff ?
                                    <Typography className={classes.forReceivingItems}>{i18n.t("quotes.invoices.forRecieving")}</Typography>
                                    :
                                    <TextField
                                        fullWidth
                                        autoFocus={true}
                                        autoComplete='off'
                                        variant="outlined"
                                        id="quantity"
                                        // disabled={items[cellInfo.index].invoiceQuantity === 0}
                                        error={items[cellInfo.index].invoiceQuantity > diff}
                                        style={{ marginBottom: '8px' }}
                                        className={classes.invoice__items__form__textField}
                                        value={items[cellInfo.index].invoiceQuantity || ''}
                                        defaultValue={diff}
                                        onChange={e => {
                                            const newItems = [...items];

                                            let itemCost = items[cellInfo.index].amount + (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost : 0)

                                            newItems[cellInfo.index].invoiceQuantity = e.target.value;
                                            newItems[cellInfo.index].error = items[cellInfo.index].invoiceQuantity > diff;
                                            newItems[cellInfo.index].total =  moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ?
                                                parseInt(e.target.value) * 
                                                    parseFloat(itemCost + ((itemCost * items[cellInfo.index].markupPercent) / 100)).toFixed(2) :
                                                parseInt(e.target.value) *
                                                    parseFloat(items[cellInfo.index].amount + ((items[cellInfo.index].amount * items[cellInfo.index].markupPercent) / 100) +
                                                        (items[cellInfo.index].weightedCost ? items[cellInfo.index].weightedCost / items[cellInfo.index].quantity : 0)).toFixed(2)

                                            this.setState({
                                                items: newItems
                                            })
                                            this.props.updateItems(newItems);
                                        }}
                                        onFocus={e => {
                                            const newItems = [...items];

                                            newItems[cellInfo.index].invoiceQuantity = e.target.value;
                                            newItems[cellInfo.index].error = items[cellInfo.index].invoiceQuantity > diff;

                                            this.setState({
                                                items: newItems
                                            })
                                            this.props.updateItems(newItems);
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatInputCustom,
                                            inputProps: { max: diff }
                                        }}
                                    />
                                }

                            </>
                        }
                    </>
                }
            </div>
        )
    }

    extendDescription = (index) => {
        const { selectedDescriptionIndex } = this.state;

        this.setState({
            selectedDescriptionIndex: selectedDescriptionIndex === index ? null : index
        })
    }

    render = () => {
        const { classes, editable, readOnly, clientQuoteTaxPercent, vendorPOs, file } = this.props,
            { items, selectedDescriptionIndex } = this.state;

        return (
            <Grid container>

                <Grid item xs={12}>
                    <ReactTable
                        data={items}
                        columns={[
                            {
                                Header: i18n.t("quotes.invoices.items.line"),
                                id: 'rowIndex',
                                Cell: row => (<div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>{row.index + 1}</div>),
                                width: 40
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.description"),
                                id: 'description',
                                Cell: (row) => (
                                    <div>
                                        <Typography className={classes.invoice__items__table__itemPartNumber} >{row.original.partNumber}</Typography>
                                        <div onClick={() => this.extendDescription(row.index)}
                                            className={classes.invoice__items__table__descriptionExtended}
                                            style={{ alignItems: selectedDescriptionIndex === row.index ? 'flex-start' : 'center' }}>
                                            <Typography color='textSecondary'
                                                style={{ whiteSpace: selectedDescriptionIndex === row.index ? 'pre-line' : 'nowrap' }} noWrap={selectedDescriptionIndex === row.index ? false : true}>
                                                {row.original.description}
                                            </Typography>
                                            {row.original.description && row.original.description.length > 35 && <>
                                                {selectedDescriptionIndex === row.index ?
                                                    <KeyboardArrowUp className={classes.extendeDescription__icon} />
                                                    :
                                                    <KeyboardArrowDown className={classes.extendeDescription__icon} />
                                                }
                                            </>}
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                Header: i18n.t('quotes.invoices.items.unitAmount'),
                                width: 120,
                                id: 'unitAmount',
                                show: !readOnly,
                                style: {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                },
                                Cell: row => {
                                    let itemCost = row.original.amount + row.original.weightedCost, 
                                        totalCost = moment(file.clientQuote[0].date).isAfter(config.prorratedDate) ?
                                            itemCost + ((itemCost * row.original.markupPercent) / 100) :
                                            row.original.amount + (row.original.weightedCost ? row.original.weightedCost / row.original.quantity : 0) + ((row.original.amount * row.original.markupPercent) / 100)

                                    return (
                                        <NumberFormat value={totalCost}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            style={{ fontSize: '16px' }}
                                            fixedDecimalScale={true}
                                        />
                                    )
                                },
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.quotedItems"),
                                id: 'quotedItems',
                                width: 60,
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '16px'
                                },
                                show: editable || readOnly ? true : false,
                                accessor: (row) => {
                                    return (
                                        <div>
                                            {row.quantity}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.receivedItems"),
                                id: 'receivedItems',
                                width: 110,
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '16px'
                                },
                                show: editable || readOnly ? true : false,
                                accessor: (row) => {
                                    let invoicedItems = this.calculateInvoicedItemsQuantity(row),
                                        itemsReceived = [],
                                        vendorPOsReceived = vendorPOs.filter(vp => vp.items.find(item => !item.deleted && item.rfqItemId === row.rfqItem.id));

                                    vendorPOsReceived.forEach(vPo => {
                                        itemsReceived = itemsReceived.concat(vPo.items.filter(vp => !vp.deleted))
                                    })

                                    let receivedItems = itemsReceived.filter(i => i.rfqItemId === row.rfqItemId).reduce(((total, ele) => parseInt(total) + (row.receivedQuantity ? parseInt(ele.receivedQuantity) : 0)), 0);

                                    return (
                                        <div>
                                            {row.acceptedVendorQuoteItem.id ?
                                                <div>
                                                    {receivedItems || 0}
                                                    {parseInt(receivedItems || 0) - parseInt(invoicedItems || 0) !== parseInt(receivedItems || 0) && (parseInt(receivedItems || 0) - parseInt(invoicedItems || 0) !== 0) &&
                                                        <span className={classes.pendingItems}>({parseInt(receivedItems || 0) - parseInt(invoicedItems || 0)})</span>
                                                    }
                                                </div>
                                                :
                                                <div>{i18n.t("quotes.invoices.items.inWarehouse")}</div>
                                            }
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.invoicedItems"),
                                id: 'invoicedItems',
                                width: 70,
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '16px'
                                },
                                show: editable || readOnly ? true : false,
                                accessor: (row) => {
                                    return (
                                        <div>
                                            {this.calculateInvoicedItemsQuantity(row)}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.deliveredItems"),
                                id: 'delivered',
                                width: 70,
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '16px'
                                },
                                show: editable || readOnly ? true : false,
                                accessor: (row) => {
                                    return (
                                        <div>
                                            {row.deliveredQuantity || 0}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: i18n.t("quotes.invoices.items.quantity"),
                                id: 'invoicedQuantity',
                                width: 80,
                                style: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
                                show: !readOnly,
                                Cell: this.renderInvoiceItemQuantity
                            },
                            {
                                Header: i18n.t('quotes.invoices.items.totals'),
                                width: 100,
                                id: 'totalColumn',
                                show: !readOnly,
                                className: classes.invoice__items__table__cell__rigth,
                                Cell: row => {
                                    let taxes = row.original.taxPercent ? (row.original.total * clientQuoteTaxPercent) / 100 : 0,
                                        itemsReceived = [],
                                        vendorPOsReceived = vendorPOs.filter(vp => vp.items.find(item => !item.deleted && item.rfqItemId === row.original.rfqItemId));

                                    vendorPOsReceived.forEach(vPo => {
                                        itemsReceived = itemsReceived.concat(vPo.items.filter(vpi => !vpi.deleted))
                                    })

                                    let receivedItems = itemsReceived.filter(i => i.rfqItemId === row.original.rfqItemId).reduce(((total, ele) => parseInt(total) + (row.original.receivedQuantity ? parseInt(ele.receivedQuantity) : 0)), 0);
                                    
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            {receivedItems !== 0 && row.original.total > 0 && (
                                                <NumberFormat value={row.original.total}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    style={{ fontSize: '16px' }}
                                                    fixedDecimalScale={true} />
                                            )}

                                            {taxes > 0 && <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                                <Typography variant='body2' color='textSecondary' style={{ fontSize: '13px', marginRight: '8px' }}>{i18n.t('quotes.invoices.items.taxes')}</Typography>
                                                <NumberFormat value={taxes}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    style={{ fontSize: '13px' }}
                                                    fixedDecimalScale={true} />
                                            </div>}
                                        </div>
                                    )
                                },
                                Footer: () => {
                                    let quotedClientTotalAmount = items.reduce(((total, row) => {
                                        let itemsReceived = [],
                                        vendorPOsReceived = vendorPOs.filter(vp => vp.items.find(item => !item.deleted && item.rfqItemId === row.rfqItemId));

                                        vendorPOsReceived.forEach(vPo => {
                                            itemsReceived = itemsReceived.concat(vPo.items.filter(vp => !vp.deleted))
                                        })

                                        let receivedItems = itemsReceived.filter(i => i.rfqItemId === row.rfqItemId).reduce(((total, ele) => parseInt(total) + (row.receivedQuantity ? parseInt(ele.receivedQuantity) : 0)), 0);
                                        return receivedItems !== 0 ? parseFloat(total) + row.total + (clientQuoteTaxPercent ? ((parseFloat(clientQuoteTaxPercent) * row.total) / 100) : 0) : 0
                                    }), 0);
                                    if (quotedClientTotalAmount === 0) return null;

                                    return (
                                        <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                            <NumberFormat value={quotedClientTotalAmount}
                                                displayType={'text'}
                                                decimalScale={2}
                                                style={{ fontSize: '16px' }}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    )
                                }
                            }
                        ]}
                        resizable={false}
                        sortable={false}
                        defaultPageSize={items.length}
                        pageSize={items.length}
                        className={classNames(classes.invoice__items__table, ' -striped -highlight')}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        filtrable={false}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default withMobileDialog()(withStyles(styles)(InvoiceItems));



