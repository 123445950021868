import React, { Component } from 'react'
import {
    Grid,
    Typography,
    CircularProgress,
    withStyles
} from '@material-ui/core'
import { Phone } from '@material-ui/icons'
import i18n from 'i18next'
import { ManageClientsPopup } from '.'
import CustomSelect from '../general/CustomSelect'
import { GlobalParametersContext } from '../../contexts/globalParametersContext'
//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as clientsActions from '../../actions/clientsActions'

import API from '../../lib/api';

const style = theme => ({
    clientRFQ__editActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(),
        minHeight: theme.spacing(4)
    },
    client__divider: {
        marginBottom: theme.spacing()
    },
    client__form__input: {
        fontSize: '0.9em',
    },
    client__form__textField: {
        margin: `${theme.spacing()}px 0`,
    },
    rfq__icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    client__search__clear: {
        '&:hover': {
            background: 'transparent'
        }
    }
})


const defaultClient = {
    country: {
        id: null
    },
    name: '',
    taxId: '',
    address: {
        address1: '',
        address2: ''
    },
    phone: '',
    fax: '',
    id: null,
    contacts: []
}

class Client extends Component {
    constructor(props) {
        super(props);
        const { file } = this.props;
        this.state = {
            countryFilter: file && file.client ? file.client.country.id : null,
            selectedClient: file && file.client ? {
                country: {
                    id: file.client.country.id
                },
                name: file.client.name,
                taxId: file.client.taxId,
                address: {
                    address1: file.client.address.address1,
                    address2: file.client.address.address2
                },
                phone: file.client.phone,
                fax: file.client.fax,
                id: file.client.id,
                contacts: file.client.contacts || []
            } : defaultClient,
            searchClient: file && file.client ? file.client.name : '',
            fetching: false,
            fetched: false
        }
    }

    componentWillMount = () => {
        const { selectedClient } = this.state,
            { eventClient, clientId, clients, client } = this.props;

        if (eventClient && clientId && ((clients && clients.length === 0) || !clients)) {
            this.fetchClientsAndSelectOne(clientId);
        }

        if (eventClient && client && client.id) {
            this.setState({
                countryFilter: client.country.id,
                selectedClient: client,
                fetching: false,
                fetched: true
            })
        }

        if (selectedClient && selectedClient.name) {
            this.fetchClients();
        }

    }

    fetchClientsAndSelectOne = (clientId) => {
        this.setState({
            fetching: true,
            fetched: false
        }, _ => {
            API.Clients.getClient({ clientId }).then(res => {
                let client = res.data.record;

                this.setState({
                    countryFilter: client.country.id,
                })

                this.props.clientsActions.setClient(client);
                this.props.changeClient(client)

                API.Clients.getClients({
                    countryId: client.country.id
                }).then(res => {
                    this.props.clientsActions.setClients(res.data.records);

                    this.setState({
                        countryFilter: client.country.id,
                        selectedClient: client,
                        fetching: false,
                        fetched: true
                    })
                })
            })
        })
    }

    fetchClients = () => {
        const {
            file
        } = this.props,
            {
                //searchClient,
                selectedClient,
                countryFilter
            } = this.state;

        this.setState({
            fetching: true,
            fetched: false
        }, _ => {
            API.Clients.getClients({
                //filter: searchClient,
                countryId: countryFilter
            }).then(res => {
                this.props.clientsActions.setClients(res.data.records);
                let client = null;
                if (selectedClient) {
                    client = res.data.records.find(c => c.id === selectedClient.id)
                    if (client) {
                        this.props.clientsActions.setClient(client);
                    } else {
                        this.setState({
                            //countryFilter: null,
                            selectedClient: defaultClient,
                        });
                        this.props.clientsActions.setClient({});
                        this.props.changeClient(defaultClient)
                    }
                }
                //Si el resultado es solo un cliente, lo seleccionamos de una vez
                if (res.data.records.length === 1) {
                    client = res.data.records[0];
                    this.setState({
                        countryFilter: client.country.id,
                        selectedClient: client,
                    })
                    this.props.clientsActions.setClient(client);

                    //Si no hay un cliente pre selecccionado llamamos a change client del padre
                    if (!file.client || (file.client && file.client.id !== client.id)) {
                        this.props.changeClient(client)
                    }
                }

                this.setState({
                    fetching: false,
                    fetched: true
                })
            })
        })
    }
    selectCountry = (country) => {
        const { selectedClient } = this.state,
            { clients } = this.props;

        this.setState({
            unsavedChanges: true
        });
        if (country) {
            let newClients = country.value ? clients.filter(c => c.country.id === country.value) : clients,
                clientIndex = newClients.length > 0 && selectedClient ? newClients.findIndex(c => c.id === selectedClient.id) : -1;
            this.setState({
                countryFilter: country.value,
                selectedClient: clientIndex === -1 ? defaultClient : selectedClient,
            }, _ => {
                this.fetchClients();
            })
        } else {
            this.setState({
                countryFilter: null,
                selectedClient: defaultClient,
            })
            this.props.clientsActions.setClient({});
            this.props.clientsActions.setClients([]);
            this.props.changeClient(defaultClient)
        }
    }
    selectClient = (selectedClient) => {
        const { clients } = this.props;
        let client = null;
        if (selectedClient) {
            client = clients.find(c => c.id === selectedClient.value)
            this.props.clientsActions.setClient(client);
            this.setState({
                selectedClient: client ? client : defaultClient,
                countryFilter: client ? client.country.id : null
            })
        } else {
            client = defaultClient;
            this.props.clientsActions.setClient({});
            this.setState({
                selectedClient: client,
                countryFilter: null
            })
        }

        this.props.changeClient(client)

    }
    changeClient = (client) => {
        this.setState({
            selectedClient: client || defaultClient,
            countryFilter: client ? client.country.id : null,
            searchClient: client ? client.name : ''
        })
        this.props.changeClient(client || defaultClient)
    }
    updateRFQClient = (client) => {
        this.props.updateRFQClient(client)
    }

    componentWillUnmount() {
        this.props.clientsActions.setClient({});
        this.props.clientsActions.setClients([]);
    }


    render() {
        const {
            classes,
            file,
            validateRFQ,
            clients,
            saving
        } = this.props,
            {
                fetched,
                countryFilter,
                searchClient,
                selectedClient,
                fetching
            } = this.state,
            {
                selectCountry,
                updateRFQClient,
                changeClient
            } = this;
        return (
            <GlobalParametersContext.Consumer>
                {({ countries }) => (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={7}>
                                <CustomSelect options={countries ? countries.map((e) => ({
                                    "label": e.countryName,
                                    "value": e.countryId
                                })) : []}
                                    placeholder={i18n.t('clients.selectCountry')}
                                    label={i18n.t('quotes.quoteRequest.quoteCountry')}
                                    value={countryFilter ? countryFilter : null}
                                    fullWidth={true}
                                    required={true}
                                    error={validateRFQ && !countryFilter ? true : false}
                                    disabled={fetching || saving}
                                    onSelect={selectCountry}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <ManageClientsPopup
                                    selectedClient={selectedClient}
                                    selectedCountryId={countryFilter}
                                    changeClient={changeClient}
                                    updateRFQClient={updateRFQClient} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {!countryFilter && (
                                <Grid item xs={12} sm={10}>
                                    <Typography variant='body2' color="textSecondary">{i18n.t('clients.selectClientCountry')}</Typography>
                                </Grid>
                            )}
                            {countryFilter && (
                                <Grid item xs={12} sm={10}>
                                    <CustomSelect
                                        options={clients ? clients.filter(c => countryFilter ? c.country.id === countryFilter : true).map(c => ({ label: c.name, value: c.id })) : []}
                                        label={i18n.t('quotes.quoteRequest.client')}
                                        placeholder={fetching ? i18n.t('clients.loadingClients') : i18n.t('clients.selectClient')}
                                        required={true}
                                        fullWidth={true}
                                        value={selectedClient ? selectedClient.id
                                            : file && file.client ? file.client.id
                                                : null}
                                        onSelect={this.selectClient}
                                        disabled={!fetched || fetching || saving ? true : false}
                                        error={validateRFQ && ((!selectedClient) || (selectedClient && selectedClient.id === null)) ? true : false}
                                    />
                                </Grid>
                            )}


                            {fetching && (
                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress color='secondary' size={24} style={{ marginLeft: '5px' }} />
                                </Grid>
                            )}
                            {fetched && searchClient && (clients.length === 0) && (
                                <Typography variant='body2' color='textSecondary'>{`${i18n.t('clients.noClientsWithTerm')}`}<strong>{`${searchClient}`}</strong></Typography>
                            )}

                        </Grid>

                        {(selectedClient && selectedClient.id) &&
                            <>
                                <Typography variant='body2' color="textSecondary">
                                    {selectedClient.name || ''}
                                    <br />
                                    {selectedClient.taxId &&
                                        <>
                                            {selectedClient.taxId || ''}
                                            <br />
                                        </>}
                                    {selectedClient.address.address1 || ''}
                                    <br />
                                    {selectedClient.address.address2 &&
                                        <>
                                            {selectedClient.address.address2 || ''}
                                        </>
                                    }
                                </Typography>

                                <Typography variant='body2' color="textSecondary">
                                    {selectedClient.phone &&
                                        <>
                                            <Phone className={classes.rfq__icon} />{selectedClient.phone}
                                        </>
                                    }
                                    <br />
                                    {selectedClient.fax &&
                                        <>
                                            <strong>{i18n.t('quotes.clientRFQ.fax')}</strong>{selectedClient.fax}
                                        </>
                                    }
                                </Typography>

                            </>

                        }
                    </div>
                )}
            </GlobalParametersContext.Consumer>
        )
    }
}

const mapStateToProps = ({ clients, file, client }) => ({ clients, file, client });
function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Client));