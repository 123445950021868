import React, { Component } from 'react'
import {
    withStyles,
    Card,
} from '@material-ui/core'
import { ManageClientRfq, ClientRFQReadOnly } from '.'
import RFQItems from './RFQItems'

import Permissions from '../../../lib/permissions'


const styles = theme => {
    return {
        clientrfq__details__info: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        clientrfq__paper: {
            borderRadius: '0 4px 4px 4px'
        },
    }
};

class ClientRFQCard extends Component {
    updateFile = (file) => {
        this.props.updateFile(file)
    }
    render = () => {
        const {
            editable,
            classes,
            file,
            clients
        } = this.props;
        return (
            <div>
                <Card classes={{ root: classes.clientrfq__paper }} style={{ position: 'relative' }}>

                    {(Permissions.checkSensitiveInformationPermission() || (!Permissions.checkSensitiveInformationPermission() && file && file.status === 1)) &&
                        <ManageClientRfq
                            editable={editable}
                            clients={clients}
                            updateFile={this.updateFile}
                            file={file}
                            onClose={this.handleCloseEditQuote}
                        />
                    }

                    <div className={classes.clientrfq__details__info}>

                        <ClientRFQReadOnly 
                            file={file} 
                            editable={editable}
                        />

                        <RFQItems
                            updateFile={this.updateFile}
                            showEditItemsButton={file.status === 1 && !file.finished ? true : false}
                            showCreateItemButton={file.status === 1 && !file.finished ? true : false}
                            showItemsROList={true}
                            file={file}
                            saveFileOnChange={true}
                            items={file.items}
                            updatedItems={(newItems) => {
                                this.setState({
                                    items: newItems
                                })
                            }}
                        />
                    </div>
                </Card>



            </div>
        )
    }
}

export default withStyles(styles)(ClientRFQCard);