import {
    SET_PEOPLE,
    SET_FILTER_PEOPLE,
    SET_FILTER_COUNTERS
} from '../actions/peopleActions'

function people(state = {}, action) {
    switch (action.type) {
        case SET_PEOPLE:
            return {
                ...state,
                allPeople: action.people
            }
        case SET_FILTER_PEOPLE:
            return {
                ...state,
                filterPeople: action.filter
            }
        case SET_FILTER_COUNTERS:
            return {
                ...state,
                counters: action.counters
            }
        default:
            return state;
    }
}

export default people