import React, { Component } from 'react'
import {
    Dialog,
    IconButton,
    withMobileDialog,
    Button,
    DialogTitle,
    withStyles,
} from '@material-ui/core'
import { CustomMessages } from '../general'
import i18n from 'i18next'
import { Close } from '@material-ui/icons'
import { SlideUp } from '../general/Transitions'

import { connect } from 'react-redux'
import { ManageClientContacts } from '.'

const styles = theme => ({
    dialog__paper: {
        width: '100%',
        overflow: 'visible'
    },
    dialog_content_container: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    manage__button: theme.manage__button
})


class ManageClientContactsPopup extends Component {
    state = {
        apiErrorMessage: null,
        showManageClientContactsPopup: false,
    }

    handleClose = () => {
        this.setState({
            showManageClientContactsPopup: false
        }, () => {
            this.props.onClose && this.props.onClose();
        })
    }

    showApiErrorMessage=(error)=>{
        this.setState({
            apiErrorMessage: error
        })
    }

    render() {

        let {
            showManageClientContactsPopup,
            apiErrorMessage
        } = this.state, {
            classes,
            general,
            client,
            eventClientContactId,
            selectedClientContact,
            hideButton,
            fromCompanies
        } = this.props;

        return (

            <>

                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                {!hideButton &&
                    <Button
                        className={classes.manage__button}
                        size="small"
                        variant="outlined"
                        onClick={() => this.setState({
                            showManageClientContactsPopup: true
                        })}>
                        {i18n.t('clients.manageClientContacts')}
                    </Button>
                }

                <Dialog
                    classes={
                        {
                            paper: classes.dialog__paper
                        }
                    }
                    open={showManageClientContactsPopup || general.showCreateClientContact || false}
                    maxWidth='sm'
                    TransitionComponent={SlideUp} >

                    <DialogTitle disableTypography={true}>
                        {client.name} {i18n.t('clients.contacts')}
                        <IconButton color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <div className={classes.dialog_content_container}>
                        <ManageClientContacts
                            eventClientContactId={eventClientContactId}
                            changeClientContact={this.props.changeClientContact}
                            updateRFQClientContact={this.props.updateRFQClientContact}
                            onClose={this.handleClose}
                            selectedClientContact={selectedClientContact}
                            showApiErrorMessage={this.showApiErrorMessage}
                            fromCompanies= {fromCompanies}
                        />
                       
                    </div>
                </Dialog>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        client: state.client,
        general: state.general,
    }
}

export default connect(mapStateToProps, null)(withMobileDialog()(withMobileDialog()(withStyles(styles, { withTheme: true })(ManageClientContactsPopup))))