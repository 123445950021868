import React, { Component } from 'react'
import auth from '../authentication/auth';
import { ConfirmSignUp, Authenticator } from 'aws-amplify-react';
import { SignUpForm, SignInForm, ForgotPassword } from '../components/login';
import Permissions from '../lib/permissions'


const authStates = [
  'signIn',
  'signUp',
  'confirmSignIn',
  'confirmSignUp',
  'forgotPassword',
  'verifyContact',
  'signedIn'
];

export default class Main extends Component {

  state = {
    logedInUser: null,
  }

  componentDidMount = () => {
    const { history, location } = this.props;
    auth.getAuthenticatedUser().then(_ => {
      history.push(`/dashboard`);
    }).catch(_ => {
      if (authStates.indexOf(location.pathname.substring(1)) >= 0) {
        let path = `${location.pathname.substring(1)}`;
        history.push(`${path}`);
        this.authenticator.handleStateChange(path);
      }
    });
  };


  onStateChange = async (authState) => {
    const { history, location } = this.props

    if (authState !== 'signedIn') {
      history.push(`${authState}`);
    }
    if (authState === 'signedIn') {
      //Si el url estaba en la pantalla de login (signIn), redireccionamos al dashboard
      if (location.pathname === '/signIn' ||
        location.pathname === 'signIn' ||
        location.pathname === '/') {

          const account = await Permissions.checkUser()
          
          if(account.userId){
            switch (account.userTypeId) {
              case 2:
                history.push(`/visits`);
                break;
              default:
                history.push(`/dashboard`);
                break;
            }
          } else {
            history.push(`/dashboard`);
          }
      }
    }
  };

  render() {
    return (
      <div>
        <Authenticator
          hideDefault={true}
          onStateChange={this.onStateChange}
          ref={f => { this.authenticator = f; }}>
          <SignInForm />
          <SignUpForm />
          <ForgotPassword {...this.props} override={'ForgotPassword'} />
          <ConfirmSignUp />
        </Authenticator>
      </div>
    )
  }
}
