import React from 'react'
import {
    makeStyles
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px'
    }
}))
export default function EmailsListSkeleton() {
    let classes = useStyles();
    return (
        <div className={classes.root}>
            <Skeleton variant="rect" width={'100%'} height={40} />
            <br />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={35} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={35} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={30} />
        </div>
    )
}
