import React from 'react'
import { Slide } from '@material-ui/core'


export function SlideUp(props) {
    return <Slide direction="up" {...props} />;
}

export function SlideDown(props) {
    return <Slide direction="down" {...props} />;
}

export function SlideLeft(props) {
    return <Slide direction="left" {...props}/>;
}
