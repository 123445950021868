import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    Typography,
    Divider,
    Hidden,
    Link,
    Paper,
    List,
    ListItem,
    ListItemText,
    TextField
} from '@material-ui/core';
import API from '../../lib/api'
import { withStyles } from '@material-ui/core/styles'
import {VendorQuotePopup} from '../quotes/vendorQR';
import i18n from 'i18next';
import classNames from 'classnames'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as filesActions from '../../actions/filesActions'

const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        list__vendors__cell: {
            display: 'flex',
            flexDirection: 'column'
        },
        vendor__quotes__table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        vendor__quotes__table__cell__content: {
            whiteSpace: 'normal'
        },
        vendor__quotes__select: {
            fontFamily: theme.typography.fontFamily,
            zIndex: '2',
            width: '147px',
            fontSize: '0.8em',
            margin: `${theme.spacing()}px 0`,
            textAlign: 'left'
        },
        vendor__status__icon__preparing: {
            marginLeft: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.secondary.main,
        },
        vendor__status__icon__requested: {
            marginLeft: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.primary.main,
        },
        vendor__status__icon__ready: {
            marginLeft: theme.spacing(),
            fontSize: '9pt',
            color: theme.palette.green[100],
        },
        vendor__quotes__tabs__divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        vendor__quote__items__itemPartNumber: {
            color: theme.palette.naval.main,
        },
        vendor__quote__items__quantity: {
            color: theme.palette.text.secondary
        },
        vendor__quote__salesPerson: {
            color: theme.palette.naval.main,
        },
        vendor__quote__items__descriptionResumed: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: '95%',
            cursor: 'pointer'
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer',
            whiteSpace: 'pre-line'
        },
        file_table_Link:{
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            whiteSpace: 'pre-line'
        },
        vendor__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '14pt',
            color: theme.palette.red[100],
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        list__vendors__cell__row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
    })
};

const options = [
    {
        label: 'PREPARING',
        value: 1
    },
    {
        label: 'REQUESTED',
        value: 2
    },
    {
        label: 'READY',
        value: 3
    },
]

class VendorQuotesList extends Component {

    state = {
        files: this.props.files,
        filesSize: this.props.files.length,
        showVendorQuote: false,
        vendorQuote: null,
        file: null,
        vendors: []
    }

    componentWillMount() {
        this.fetchVendors()
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            files: nextProps.files,
            filesSize: nextProps.files.length
        })
    }

    fetchVendors = () => {
        API.Vendors.getVendors()
            .then(vendorsResponse => {
                this.setState({
                    vendors: vendorsResponse.data.records || []
                })
            })
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    openEditVendorQuote = (file) => {
        API.Files.getFile({
            fileId: file.id
        }).then(res => {
            if (res.file) {
                this.props.filesActions.setFile(res.file);
                this.setState({
                    showVendorQuote: true,
                    file: res.file,
                    vendorQuote: res.file.vendorQuotes.find(vq => vq.id === file.vendorQuote.id),
                })
            }
        })
    }

    handleClose = () => {
        this.setState({
            showVendorQuote: false
        })
    }

    deleteVendorQuote = (vendorQuoteId) => {
        let vendorQuotes = [...this.state.filterVendorQuotes],
            index = vendorQuotes.findIndex(vq => vq.id === vendorQuoteId);

        vendorQuotes.splice(index, 1)

        this.setState({
            filterVendorQuotes: vendorQuotes,
            vendorQuotesSize: vendorQuotes.length
        })
    }

    updateVendorQuote = (vendorQuote) => {
        let vendorQuotes = [...this.state.filterVendorQuotes],
            index = vendorQuotes.findIndex(vq => vq.vendorQuoteId === vendorQuote.id);

        if (index !== -1) {
            let newVendorQuote = {
                ...vendorQuotes[index],
                vendorName: vendorQuote.vendor.name,
                vendorItems: vendorQuote.items.map((item) => {
                    return {
                        rfqItemPartNumber: item.rfqItem.partNumber,
                        rfqItemUnit: item.rfqItem.unit,
                        vendorQuoteItemDeliveryDays: item.deliveryDays,
                        vendorQuoteItemDescription: item.description,
                        vendorQuoteItemQuantity: item.quantity,
                        vendorQuotedItemUnitAmount: item.unitAmount
                    }
                })
            }

            vendorQuotes[index] = newVendorQuote

            this.setState({
                filterVendorQuotes: vendorQuotes
            })
        }
    }

    render = () => {
        const { classes } = this.props,
            {   files,
                filesSize,
                showVendorQuote,
                file, vendorQuote,
                vendors
            } = this.state

        if(!files) return null

        return (
            <div>
                <Hidden xsDown implementation="css">
                    <ReactTable
                        data={files}
                        columns={[
                            {
                                Header: i18n.t("quotes.requestedByClient.table.dateFile"),
                                id: 'file',
                                width: 200,
                                filterable: true,
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    minHeight: '80px'
                                },
                                accessor: row => (
                                    <div className={classes.rfq__table__fileDescriptions}>
                                        <Link
                                            variant="body2"
                                            onClick={()=>this.props.handleShowQuote(row)}
                                            className={classes.file_table_Link}>
                                            <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                        </Link>

                                        <div className={classes.rfq__table__fileDatesContainer}>
                                            <Typography
                                                variant='body2'
                                                color='textSecondary'>
                                                {this.quoteDateFormat(row.createDateTime)}
                                            </Typography>
                                        </div>
                                        <div className={classes.list__file__lastUpdate__container}>
                                            <Typography
                                                className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ?
                                                    classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ?
                                                        classes['list__file__lastUpdate--late'] :
                                                        classes['list__file__lastUpdate--veryLate'])} >
                                                {moment(row.lastUpdateDateTime).fromNow(true)}
                                            </Typography>
                                        </div>
                                    </div>),
                                Filter: ({ onChange }) => (
                                    <div className={classes.flexCell}>
                                        <TextField
                                            id='txtFile'
                                            style={{ flex: 1 }}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    </div>
                                ),
                                filterMethod: (filter, row) => {
                                    let { value } = filter;
                                    return `${row._original.id}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || `${this.quoteDateFormat(row._original.createDateTime)}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                }
                            },
                            {
                                Header: i18n.t("quotes.vendorQuotesList.table.column1"),
                                filterable: true,
                                id: 'vendor',
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                },
                                accessor: row => {
                                    return (
                                        <div className={classes.list__vendors__cell}>
                                            <Link
                                                variant="body2"
                                                className={classes.rfq__table__fileLink}
                                                onClick={() => this.openEditVendorQuote(row)}
                                            >
                                                <strong>{row.vendorQuote.id} {row.vendorQuote.vendor.name}</strong>
                                            </Link>
                                            <Typography color='textSecondary'>
                                                {row.vendorQuote.status.id === 1 &&
                                                    `${i18n.t("quotes.vendorQuotesList.created")} ${this.quoteDateFormat(row.vendorQuote.vendorQuoteDateTimeCreated)}`
                                                }
                                                {row.vendorQuote.status.id === 2 &&
                                                    `${i18n.t("quotes.vendorQuotesList.requested")} ${this.quoteDateFormat(row.vendorQuote.vendorQuoteDateTimeRequested)}`
                                                }
                                                {row.vendorQuote.status.id === 3 &&
                                                    `${i18n.t("quotes.vendorQuotesList.ready")} ${this.quoteDateFormat(row.vendorQuote.vendorQuoteDateTimeResponse)}`
                                                }
                                            </Typography>
                                        </div>
                                    )
                                },
                                filterMethod: (filter, row) => {
                                    return row._original.vendorQuote.vendor.name.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 || `${row._original.vendorQuote.status.id === 1 ? this.quoteDateFormat(row._original.vendorQuote.vendorQuoteDateTimeCreated)
                                        : row._original.vendorQuote.status.id === 2 ? this.quoteDateFormat(row._original.vendorQuote.vendorQuoteDateTimeRequested)
                                            : this.quoteDateFormat(row._original.vendorQuote.vendorQuoteDateTimeResponse)}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 ||
                                        `${options.find(v => v.value === row._original.vendorQuote.status.id).label}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                }
                            },
                            {
                                Header: i18n.t("quotes.vendorQuotesList.table.column3"),
                                filterable: true,
                                id: 'user_Author',
                                width: 150,
                                accessor: row => <Typography className={classes.vendor__quote__salesPerson}>{row.salesPerson.userName}</Typography>,
                                style: {
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                },
                                filterMethod: (filter, row) => {
                                    return `${row._original.salesPerson.userName}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                }
                            }
                        ]}
                        pageSize={filesSize}
                        onPageSizeChange={(pageSize, page) =>
                            this.setState({ page, pageSize })
                        }
                        className={classNames(classes.vendor__quotes__table, ' -striped -highlight')}
                        showPaginationTop={filesSize >= 50 ? true : false}
                        showPaginationBottom={filesSize >= 50 ? true : false}
                    />
                </Hidden>
                <Hidden smUp implementation="css">
                    <Paper>
                        <List>
                            {files.map((row, i) => (
                                <div key={`${row.fileId}-${i}`}>
                                    <ListItem key={row.fileId} >
                                        <ListItemText>
                                            <div>
                                                <Link
                                                    variant="body2"
                                                    className={classes.rfq__table__fileLink}
                                                    onClick={() => this.props.handleShowQuote(row)}
                                                >
                                                    <strong>{row.vendorName}</strong>
                                                </Link>
                                                <Typography color='textSecondary'>
                                                    {i18n.t("quotes.vendorQuotesList.created")} {this.quoteDateFormat(row.vendorQuoteDateTimeCreated)}
                                                    {row.vendorQuote.status.id >= 2 &&
                                                        <>
                                                            <br />
                                                            {i18n.t("quotes.vendorQuotesList.requested")} {this.quoteDateFormat(row.vendorQuoteDateTimeRequested)}
                                                        </>
                                                    }
                                                    {row.vendorQuote.status.id === 3 &&
                                                        <>
                                                            <br />
                                                            {i18n.t("quotes.vendorQuotesList.ready")} {this.quoteDateFormat(row.vendorQuoteDateTimeResponse)}
                                                        </>
                                                    }
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography className={classes.list__fileAuthor} >{row.salesPerson.userName}</Typography>
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                    {i < (files.length - 1) && (
                                        <Divider />
                                    )}
                                </div>
                            ))}
                        </List>
                    </Paper>
                </Hidden>

                <VendorQuotePopup
                    vendorQuote={vendorQuote}
                    open={showVendorQuote}
                    file={file}
                    vendors={vendors}
                    handleClose={this.handleClose}
                    onExited={()=>{
                        this.setState({
                            vendorQuote: null
                        })
                    }}
                    deleteVendorQuote={this.deleteVendorQuote}
                    updateVendorQuote={this.updateVendorQuote}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}

export default connect( '', mapDispatchToProps)(withStyles(styles)(VendorQuotesList));