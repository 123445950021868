import React, { Component } from 'react'
import {
    withStyles,
    Typography,
    Grid,
} from '@material-ui/core'
import { Drafts, Phone } from '@material-ui/icons'
import classNames from 'classnames'
import moment from 'moment'
import i18n from 'i18next'
import { NoteForm } from '../../notes'
import auth from '../../../authentication/auth';


const styles = theme => {
    return {
        clientrfq__hader__container: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
            position: 'relative'
        },
        clientrfq__hader__container__clientName__container: {
            overflow: 'hidden'
        },
        clientrfq__hader__container__clientName__label: {
            textAlign: 'left',
            color: '#000',
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(4.5)
            }

        },
        clientrfq__hader__container__salesperson__date: {
            marginLeft: 0,
            marginRight: theme.spacing()
        },
        clientrfq__details__info: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        rfq__subtitle__divider: {
            width: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        customLink: theme.customLink,
        rfq__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-3px'
        },
        rfq__icon__email: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-5px',
            color: theme.palette.blue[100]
        },
        rfq__editLink: theme.customLink,
        oportunity__probability__label: {
            border: `solid thin ${theme.palette.secondary.main}`,
            fontSize: '8pt',
            borderRadius: theme.spacing() / 2,
            padding: theme.spacing() / 2,
            color: theme.palette.text.secondary,
            textAlign: 'center',
            width: '80px',
            lineHeight: 1
        },
        'oportunity__probability__label--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability__label--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability__label--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        printButton: {
            ...theme.smallButton,
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.getContrastText(theme.palette.grey[400]),
            },
            color: theme.palette.getContrastText(theme.palette.grey[100]),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2),
            }
        },
        subTitle: {
            color: theme.palette.blue[100]
        },
    }
};


const probabilities = ['unknow', 'likely', 'verylikely'];

class ClientRFQReadOnly extends Component {
    state = {
        fileId: this.props.fileId,
        openNewNote: false,
        note: null,
        logedInUser: null
    }

    componentDidMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    closeNewNote = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    handelNoteSaved = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }

    render = () => {
        const {
            openNewNote,
            note,
            logedInUser,
        } = this.state,
            { isQuote,
                classes,
                file,
                editable
            } = this.props;

        if (!file) return null

        return (
            <div>
                <div>
                    <Grid container
                        justify="flex-start"
                        alignItems='baseline'
                        spacing={1}
                        className={classes.clientrfq__hader__container}>

                        <Grid item xs={12} sm={12} md={editable ? 7 : 9} zeroMinWidth
                            className={classes.clientrfq__hader__container__clientName__container}>
                            <Typography
                                variant='h6'
                                className={classes.clientrfq__hader__container__clientName__label}
                            > {file.client.name}
                            </Typography>
                            {!isQuote &&
                                <Typography color='textSecondary'>{`Created ${moment(file.createDateTime).format('MMM Do, YYYY')}`}  {file.salesPerson.userName}</Typography>
                            }
                        </Grid>


                        <Grid item xs={12} sm={8} md={3} zeroMinWidth style={{ textAlign: 'right' }}>
                            <Typography variant='body2' color='textPrimary'>
                                {file.requestForQuote.name}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {file.salesRepresentative && file.salesRepresentative.name && (
                                    <Typography noWrap variant='body2' color='textSecondary' className={classes.clientrfq__hader__container__salesperson__date}>
                                        {file.salesRepresentative.name}
                                    </Typography>
                                )}
                                {file.oportunity.name && !isQuote && (
                                    <Typography variant='body2' color='textSecondary' className={classNames(classes.oportunity__probability__label, classes[`oportunity__probability__label--${probabilities[file.probability.id - 1]}`])}>
                                        {file.oportunity.name ? file.oportunity.name : ''}
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}>
                            <Grid container
                                spacing={1}
                                justify="space-between">
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.quoteRequest.client')}</Typography>

                                    <Typography variant='body2' color="textSecondary" noWrap>
                                        {file.client.country.name}
                                        <br />
                                        {file.client.address.address1}
                                        {file.client.address.address2 && (
                                            <>
                                                <br />
                                                {file.client.address.address2}
                                            </>
                                        )}
                                        <br />
                                        <span><Phone className={classes.rfq__icon} />{file.client.phone}</span>
                                        {file.client.fax && (
                                            <>
                                                <br />
                                                <strong>{`${i18n.t('quotes.clientRFQ.fax')} `}</strong>{file.client.fax}
                                            </>
                                        )}
                                        <br />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.quoteRequest.clientContact')}</Typography>

                                    <Typography variant='body2' color="textSecondary" noWrap style={{ textAlign: 'left' }}>
                                        <span>{file.client.contact.firstName} {file.client.contact.lastName}</span>
                                        {file.client.contact.email && (
                                            <>
                                                <br />
                                                <Drafts className={classNames(classes.customLink, classes.rfq__icon__email)} onClick={() => this.setState({
                                                    openNewNote: true
                                                })} />
                                                <span className={classes.customLink} onClick={() => this.setState({
                                                    openNewNote: true
                                                })}>{file.client.contact.email}</span>
                                            </>
                                        )}
                                        {file.client.contact.phone && (
                                            <>
                                                <br />
                                                <Phone className={classes.rfq__icon} />{file.client.contact.phone}
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.quoteRequest.rfq')}</Typography>

                                    <Typography variant='body2' color="textSecondary" noWrap>
                                        {file.requestForQuote.reference && (
                                            file.requestForQuote.reference
                                        )}
                                        {file.requestForQuote.description && (
                                            <>
                                                <br />
                                                {file.requestForQuote.description}
                                            </>
                                        )}
                                        <br />
                                        {file.requestForQuote.currency}


                                        {!isQuote && <>
                                            {file.quoteTerms.name && (
                                                <>
                                                    <br />
                                                    <strong>{`${i18n.t('quotes.clientRFQ.quoteTerms')} `}</strong>{file.quoteTerms.name}
                                                    {file.requestForQuote.quoteTermsReference && (
                                                        <span style={{ marginLeft: '16px' }}> <strong>{`${i18n.t('quotes.quoteRequest.quoteTermsReferences')} `}</strong>{file.requestForQuote.quoteTermsReference}</span>
                                                    )}
                                                </>
                                            )}

                                            {file.paymentTerms.name && (
                                                <>
                                                    <br />
                                                    <strong>{`${i18n.t('quotes.clientRFQ.paymentTerms')} `}</strong>{file.paymentTerms.name}
                                                </>
                                            )}
                                        </>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>

                <NoteForm
                    open={openNewNote}
                    file={file}
                    note={note}
                    section={2}
                    user={logedInUser}
                    sendByEmail={true}
                    emailAddress={file && file.client.contact.email ? {
                        value: file.client.contact.email,
                        label: `${file.client.contact.firstName} ${file.client.contact.lastName}`
                    } : null}
                    addNewNote={this.handelNoteSaved}
                    handleClose={this.closeNewNote}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ClientRFQReadOnly);