//Account information

import {
    SET_ACCOUNT,
    CLEAR_ACCOUNT
} from '../actions/accountActions'

function account(state = {}, action) {
    switch (action.type) {
        case SET_ACCOUNT:
            return { ...action.account };
        case CLEAR_ACCOUNT:
            return {}
        default:
            return state;
    }
}

export default account