import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'
import store from '../../store'

const Users = {
    getUsers: (args) => {
        let { agenda } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users${agenda ? '?agenda=1' : ''}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createUser: (args) => {
        let user = args.user;
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users`;
            auth.getAuthenticatedUser().then(logueduser => {
                const prodekAuthToken = logueduser.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'POST',
                    body: JSON.stringify(user)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateUser: (args) => {
        let user = args.user;
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users/${user.userId}`;
            auth.getAuthenticatedUser().then(logueduser => {
                const prodekAuthToken = logueduser.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                delete user.userId

                fetch(url, {
                    headers,
                    method: 'PUT',
                    body: JSON.stringify(user)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getUserRoles: (args) => {
        let { userId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users/${userId}/roles`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getUser: (args) => {
        let { userId } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users/${userId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getUserDashboardInfo: () => {
        let state = store.getState(),
            usersID = [],
            peopleFilter = state.people.filterPeople ? state.people.filterPeople.map(user => user.userId) : null

        if (peopleFilter && peopleFilter.length !== state.people.allPeople.length) {
            usersID = peopleFilter.join(',')
        }

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/dashboard?users=${usersID}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteUser: (args) => {
        let { userId } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/users/${userId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    method: 'DELETE',
                    body: JSON.stringify(args),
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    enableUser: (args) => {
        let { userId } = args || {};
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/users/${userId}`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    body: JSON.stringify(args.user),
                    headers,
                    method: 'PATCH'
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
}

export default Users;