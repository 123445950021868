import React from 'react';
import {
    Modal,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    withStyles,
    Typography,
    Paper
} from '@material-ui/core/';
import i18n from 'i18next';

const styles = theme => ({
    paper: theme.modal.paper,
    formContainer: theme.modal.container,
    title: theme.modal.title,
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    dates: {
        display: 'flex'
    },
    date: {
        marginRight: theme.spacing(2)
    },
    buttons: theme.modal.buttons
})

class SearchDialog extends React.Component {

    state = {
        client: '',
        salesPerson: ''
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const { classes, show } = this.props
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={show}
                onClose={this.props.handleClose}
            >
                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        <Typography color='secondary' variant='h5'>{i18n.t("search.title")}</Typography>
                    </div>
                    <div className={classes.formContainer}>
                        <form className={classes.form} autoComplete="off">
                            <div className={classes.dates}>
                                <FormControl className={classes.date}>
                                    <TextField
                                        id="date"
                                        type="date"
                                        style={{ flex: 1 }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        id="date"
                                        type="date"
                                        style={{ flex: 1 }}
                                    />
                                </FormControl>
                            </div>
                            <FormControl className={classes.field}>
                                <InputLabel htmlFor="salesPerson">{i18n.t("search.salesPerson")}</InputLabel>
                                <Select
                                    value={this.state.salesPerson}
                                    onChange={this.handleChange}
                                    input={<Input name="salesPerson" id="salesPerson" />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Mary Ontiveros</MenuItem>
                                    <MenuItem value={20}>Carlos Tovar</MenuItem>
                                    <MenuItem value={30}>Luis Lopez</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.field}>
                                <InputLabel htmlFor="client">{i18n.t("search.client")}</InputLabel>
                                <Select
                                    value={this.state.client}
                                    onChange={this.handleChange}
                                    input={<Input name="client" id="client" />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Mary Ontiveros</MenuItem>
                                    <MenuItem value={20}>Carlos Tovar</MenuItem>
                                    <MenuItem value={30}>Luis Lopez</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.field}>
                                <TextField
                                    id="reference"
                                    type="text"
                                    label={i18n.t("search.reference")}
                                    style={{ flex: 1 }}
                                />
                            </FormControl>
                        </form>

                        <div className={classes.buttons}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={this.props.handleClose}>
                                {i18n.t("search.cancelBtn")}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => { alert('Not implemented yet') }}>
                                {i18n.t("search.searchBtn")}
                            </Button>
                        </div>
                    </div>
                </Paper>
            </Modal>
        );
    }
}

export default withStyles(styles)(SearchDialog);
