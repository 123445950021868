import React, { Component } from 'react'

import {
    Grid,
    Link,
    TextField,
    withStyles,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    InputAdornment,
    DialogActions
} from '@material-ui/core'
import i18n from 'i18next'
import {Close} from '@material-ui/icons'
import classNames from 'classnames'
import ReactTable from "react-table"
import NumberFormat from 'react-number-format'
import {NumberFormatInputCustom, ConfirmationDialog, CustomSelect} from '../../general'
import {GlobalParametersContext} from '../../../contexts/globalParametersContext'

import API from '../../../lib/api'

const styles = theme => {
    return ( {
        other__cost__form__items__table: {
            fontSize: theme.typography.fontSize * 0.8,
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
            border: `solid thin ${theme.palette.grey[300]}`
        },
        other__cost__form__items__tablecell__rigth: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        other__costs__title: {
            padding: '0 4px',
            marginBottom: '10px'
        },
        subTitle: {
            color: theme.palette.blue[100]
        },
        manage__button: {
            ...theme.manage__button
        },
        other__cost__closeWindow: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
        preparing__otherCost__delete: {
            minWidth: '60px',
            borderColor: theme.palette.red[80],
            '&:hover': {
                backgroundColor: theme.palette.red[60],
                color: theme.palette.getContrastText(theme.palette.red[100])
            },
            color: theme.palette.red[80],
        },
        other__cost__select__container: {
            marginTop: theme.spacing()
        },
         other__costs__add__button: {
            ...theme.customLink
        },
    })
}

class InvoiceOtherCosts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceOtherCosts: props.otherCosts,
            showOtherCostForm: false,
            otherCostIndex : null,
            otherCostAmount: null,
            selectedOtherCost: null,
            otherCostComments: null,
            validateOtherCost: false,
            deleteOtherCostIdx: null,
            openConfirmationDialog: false,
            otherCostSize: props.otherCosts ? props.otherCosts.length : 0,
            addNewCost: false,
            costName: null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            invoiceOtherCosts: nextProps.otherCosts,
            otherCostSize: nextProps.otherCosts ? nextProps.otherCosts.filter(oc => !oc.deleted).length : 0
        })
    }

    calculateInvoicedOtherCosts = (row) => {
        const { invoices } = this.props;

        let selectedOtherCost = null,
            invoicedOtherCost = 0;

        invoices.filter(i => i.status !== 1).forEach((inv) => {
            selectedOtherCost = inv.otherCosts.find(oc => parseInt(oc.clientQuoteOtherCostId) === parseInt(row.clientQuoteOtherCostId))

            if (selectedOtherCost) {
                invoicedOtherCost = invoicedOtherCost + parseFloat(selectedOtherCost.invoiceAmount)
            } else {
                if(!row.clientQuoteOtherCostId){
                    invoicedOtherCost = row.invoiceAmount
                }
            }
        })

        return invoicedOtherCost


    }

    renderInvoiceOtherCostAmount = (cellInfo) => {
        const { classes, editable, invoice } = this.props,
            { invoiceOtherCosts } = this.state;

        let invoicedOtherCost = this.calculateInvoicedOtherCosts(cellInfo.original),
            diff = invoiceOtherCosts[cellInfo.index].amount - invoicedOtherCost

        if (!invoice && invoiceOtherCosts[cellInfo.index].invoiceAmount === null) {
            invoiceOtherCosts[cellInfo.index].invoiceAmount = diff < 0 ? 0 : diff
        }

        return (
            <div style={{ width: '100%' }}>
                {editable ?
                    <TextField
                        fullWidth
                        autoFocus={true}
                        autoComplete='off'
                        variant="outlined"
                        id={`quantity-${cellInfo.index}`}
                        // disabled={otherCosts[cellInfo.index].invoiceAmount === 0}
                        // error={diff !== 0 && otherCosts[cellInfo.index].invoiceAmount > diff}
                        style={{ marginBottom: '8px' }}
                        className={classes.invoice__items__form__textField}
                        value={invoiceOtherCosts[cellInfo.index].invoiceAmount}
                        onChange={e => {
                            const newOtherCosts = [...invoiceOtherCosts];

                            newOtherCosts[cellInfo.index].invoiceAmount = e.target.value;
                            // newOtherCosts[cellInfo.index].error = newOtherCosts[cellInfo.index].invoiceAmount > diff;

                            this.setState({
                                otherCosts: newOtherCosts
                            })
                            this.props.updateOtherCosts(newOtherCosts);
                        }}
                        onFocus={e => {
                            const newOtherCosts = [...invoiceOtherCosts];

                            newOtherCosts[cellInfo.index].invoiceAmount = e.target.value;

                            this.setState({
                                invoiceOtherCosts: newOtherCosts
                            })
                            this.props.updateOtherCosts(newOtherCosts);
                        }}
                        InputProps={{
                            inputComponent: NumberFormatInputCustom,
                        }}
                    />
                    :
                    <NumberFormat value={invoiceOtherCosts[cellInfo.index].invoiceAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        style={{ fontSize: '16px' }}
                        fixedDecimalScale={true} />
                }
            </div>
        )

    }

    addOtherCost = () => {
        const { otherCostAmount,
            selectedOtherCost,
            addNewCost,
            addNewOtherCostFunction,
            costName } = this.state;

        this.setState({
            validateOtherCost: true
        })

        if(addNewCost){
            if (!costName || otherCostAmount === '' || !otherCostAmount || selectedOtherCost === '' || !selectedOtherCost){
                return
            }
        } else {
            if (otherCostAmount === '' || selectedOtherCost === '' || !otherCostAmount || !selectedOtherCost) {
                return
            }
        }


        if(addNewCost){
            API.GlobalParameters.createOtherCost({name: costName}).then(res => {
                addNewOtherCostFunction && addNewOtherCostFunction(res.data.record)
                this.setState({
                    selectedOtherCost: res.data.record.otherCostId
                }, ()=>{
                    this.addOtherCostToDocument()
                })
            })
        } else {
            this.addOtherCostToDocument()
        }
    
    }

    addOtherCostToDocument=()=>{
        const { selectedRow,
            otherCostIndex,
            otherCostAmount,
            otherCostComments,
            selectedOtherCost,
            invoiceOtherCosts } = this.state,
            { otherCosts } = this.context;

        let newOtherCosts = [...invoiceOtherCosts],
            newOtherCost = {
                clientQuoteOtherCostId: selectedRow ? selectedRow.clientQuoteOtherCostId : null,
                otherCostId: selectedOtherCost,
                name: otherCosts.find(oc => oc.otherCostId === selectedOtherCost).otherCostName,
                amount: otherCostAmount,
                invoiceAmount: otherCostAmount,
                comments: otherCostComments
            };

        if (selectedRow) {
            newOtherCosts[otherCostIndex] = {
                ...newOtherCosts[otherCostIndex],
                ...newOtherCost
            }
        } else {
            newOtherCosts = [...newOtherCosts, newOtherCost]
        }

        this.setState({
            showOtherCostForm: false,
            validateOtherCost: false,
            quoteOtherCosts: newOtherCosts,
            otherCostSize: newOtherCosts.length,
            selectedOtherCost: null,
            selectedRow: null,
            otherCostAmount: null,
            otherCostIndex: null,
            otherCostComments: null,
            addNewCost: false,
            costName: null
        }, () => {
            this.props.updateOtherCosts && this.props.updateOtherCosts(newOtherCosts)
        })
    }

    confirmDeleteOtherCost = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    cancelConfirmationDeleteOtherCost = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    deleteOtherCost = () => {
        const { otherCostIndex } = this.state;
        const otherCosts = [...this.state.invoiceOtherCosts]

        otherCosts[otherCostIndex].deleted = true

        let newQuoteOtherCosts = otherCosts.filter(oc => !oc.deleted)

        this.setState({
            otherCosts,
            showOtherCostForm: false,
            selectedRow: null,
            otherCostIndex: null,
            openConfirmationDialog: false,
            otherCostSize: newQuoteOtherCosts.length,
            addNewCost: false,
            costName: null
        })

        this.props.updateOtherCosts && this.props.updateOtherCosts(otherCosts)
    }

    editOtherCost = (row) => {
        const { invoiceOtherCosts } = this.state,
            index = invoiceOtherCosts && invoiceOtherCosts.length > 0 ? invoiceOtherCosts.findIndex(oc => oc.id === row.id) : null;

        this.setState({
            showOtherCostForm: true,
            selectedRow: row,
            otherCostIndex: index,
            selectedOtherCost: row.otherCostId,
            otherCostAmount: row.amount,
            otherCostComments: row.comments,
            addNewCost: false,
            costName: null
        })
    }

    closeOtherCost = () => {
        this.setState({
            selectedRow: null,
            otherCostIndex: null,
            showOtherCostForm: false,
            validateOtherCost: false,
            selectedOtherCost: null,
            otherCostAmount: null,
            otherCostComments: null,
            addNewCost: false,
            costName: null
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };


    render() {
        const { classes, editable, readOnly, invoice, currencySymbol } = this.props,
            { invoiceOtherCosts, 
                showOtherCostForm,
                otherCostAmount,
                selectedOtherCost,
                otherCostComments,
                validateOtherCost,
                openConfirmationDialog,
                selectedRow,
                otherCostSize,
                addNewCost,
                costName
            } = this.state;

        return (
            <GlobalParametersContext.Consumer>
                {({ otherCosts, addNewOtherCost }) => {
                    let newOtherCost = otherCosts ? [...otherCosts] : []

                    newOtherCost.unshift({
                        otherCostId: -1,
                        otherCostName: "Create New Cost"
                    })

                    return (
                        <Grid container>
                            <Grid item xs={12} className={classes.other__costs__title}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{i18n.t('quotes.invoices.otherCostsTable.title')}</Typography>
                                    {!readOnly &&
                                        <div className={classes.other__costs__container_addButton}>
                                            <Button
                                                className={classes.manage__button}
                                                size="small"
                                                variant='outlined'
                                                onClick={() => this.setState({ showOtherCostForm: !showOtherCostForm })}>
                                                {i18n.t('quotes.preparatingQuoteInformation.addOtherCosts')}
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            
                            { invoiceOtherCosts.length > 0 &&
                                <Grid item xs={12}>
                                    <ReactTable
                                        data={invoiceOtherCosts}
                                        columns={[
                                            {
                                                Header: i18n.t('quotes.invoices.otherCostsTable.name'),
                                                id: 'otherCostTableName',
                                                accessor: 'name',
                                                style: {
                                                    whiteSpace: 'pre-wrap',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                },
                                            },
                                            {
                                                Header: i18n.t('quotes.invoices.otherCostsTable.quoted'),
                                                id: 'quoted',
                                                show: editable || readOnly ? true : false,
                                                className: classes.other__cost__form__items__tablecell__rigth,
                                                accessor: row => {
                                                    return (
                                                        <NumberFormat value={row.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            style={{ fontSize: '16px' }}
                                                            fixedDecimalScale={true} />
                                                    )
                                                },
                                                width: 120
                                            },
                                            {
                                                Header: i18n.t('quotes.invoices.otherCostsTable.invoiced'),
                                                id: 'invoiced',
                                                show: editable || readOnly ? true : false,
                                                className: classes.other__cost__form__items__tablecell__rigth,
                                                accessor: row => {
                                                    let invoicedOtherCost = this.calculateInvoicedOtherCosts(row)
                                                    return (
                                                        <NumberFormat value={invoicedOtherCost ? invoicedOtherCost : 0}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            style={{ fontSize: '16px' }}
                                                            fixedDecimalScale={true} />
                                                    )
                                                },
                                                width: 120
                                            },
                                            {
                                                Header: i18n.t("quotes.invoices.otherCostsTable.amount"),
                                                id: 'amount',
                                                width: 80,
                                                show: readOnly ? false : true,
                                                Cell: this.renderInvoiceOtherCostAmount,
                                                className: classes.other__cost__form__items__tablecell__rigth,
                                                Footer: () => {
                                                    let invoicedOtherCosts = otherCosts.reduce(((total, row) => parseFloat(total) + (row.invoiceAmount ? parseFloat(row.invoiceAmount || 0) : 0)), 0);
                                                    if (invoicedOtherCosts === 0) return null;

                                                    return (
                                                        <div>
                                                            <NumberFormat value={invoicedOtherCosts}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                style={{ fontSize: '16px' }}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            },
                                            {
                                                Header: '',
                                                id: 'deleteOtherCost',
                                                show: readOnly ? false : true,
                                                style: {
                                                    textAlign: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                },
                                                width: 45,
                                                filterable: false,
                                                accessor: row =>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Link
                                                            variant='button'
                                                            className={classes.other__costs__add__button}
                                                            onClick={() => {
                                                                this.editOtherCost(row)
                                                            }}>
                                                            {i18n.t('quotes.preparatingQuoteInformation.editOtherCost')}
                                                        </Link>
                                                    </div>
                                            }
                                        ]}
                                        defaultPageSize={invoiceOtherCosts.filter(oc=> !oc.deleted).length}
                                        pageSize={otherCostSize}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize })
                                        }
                                        filtrable={false}
                                        sortable={false}
                                        resizable={false}
                                        className={classNames(classes.other__cost__form__items__table, ' -striped -highlight')}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                    />
                                </Grid>
                            }

                            {showOtherCostForm &&
                                <Dialog
                                    maxWidth={'xs'}
                                    fullWidth={true}
                                    onClose={this.closeOtherCost}
                                    scroll='paper'
                                    aria-labelledby="otherCost_form"
                                    open={true}
                                >
                                    <DialogTitle id="otherCost_form" disableTypography={true}>
                                        {invoice && invoice.id ? `${i18n.t('quotes.invoices.otherCostsTable.invoice')} ${invoice.id} - ` : i18n.t('quotes.invoices.otherCostsTable.invoiceOtherCosts')} 
                                        <IconButton color="inherit"
                                            onClick={this.closeOtherCost}
                                            aria-label="Close" className={classes.other__cost__closeWindow}>
                                            <Close />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container spacing={0} alignItems='flex-start'>
                                            <Grid item xs={12} className={classes.other__cost__select__container}>
                                                <CustomSelect options={newOtherCost ? newOtherCost.map((e) => ({
                                                        "label": e.otherCostName,
                                                        "value": e.otherCostId
                                                    })) : []}
                                                    label={i18n.t('quotes.preparatingQuoteInformation.cost')}
                                                    value={selectedOtherCost ? selectedOtherCost : null}
                                                    required={true}
                                                    error={validateOtherCost && !selectedOtherCost ? true : false}
                                                    fullWidth
                                                    onSelect={(cost) => {
                                                        if(cost && cost.value === -1){
                                                            this.setState({ 
                                                                addNewCost: true,
                                                                addNewOtherCostFunction: addNewOtherCost,
                                                                selectedOtherCost: cost ? cost.value : null
                                                            })
                                                        } else {
                                                            this.setState({
                                                                addNewCost: false,
                                                                addNewOtherCostFunction: null,
                                                                selectedOtherCost: cost ? cost.value : null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            {addNewCost &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required={addNewCost ? true : false}
                                                        autoComplete='off'
                                                        id="cost-name"
                                                        name="costName"
                                                        type='text'
                                                        label={i18n.t('quotes.preparatingQuoteInformation.costName')}
                                                        fullWidth
                                                        value={costName ? costName : ''}
                                                        margin="normal"
                                                        variant="outlined"
                                                        error={addNewCost && (costName === '' || !costName) && validateOtherCost ? true : false}
                                                        onChange={this.handleChange('costName')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <TextField
                                                    autoComplete='off'
                                                    id="text-comment"
                                                    name="otherCostComments"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.comment')}
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    value={otherCostComments ? otherCostComments : ''}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={this.handleChange('otherCostComments')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField autoComplete='off' required
                                                    variant="outlined"
                                                    id="text-other_cost_amount"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.amount')}
                                                    name="otherCostAmount"
                                                    error={(otherCostAmount === '' || !otherCostAmount) && validateOtherCost ? true : false}
                                                    onChange={this.handleChange('otherCostAmount')}
                                                    style={{
                                                        width: '80%',
                                                        marginTop: '10px',
                                                    }}
                                                    value={otherCostAmount ? otherCostAmount : ''}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                                                        inputComponent: NumberFormatInputCustom,
                                                    }}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions style={{ justifyContent: selectedRow ? 'space-between' : 'flex-end' }}>
                                        {selectedRow &&
                                            <Button
                                                className={classes.preparing__otherCost__delete}
                                                onClick={this.confirmDeleteOtherCost}>
                                                {i18n.t('quotes.preparatingQuoteInformation.deleteOtherCost')}
                                            </Button>
                                        }
                                        <div className={classes.button__wrapper}>
                                            <Button
                                                style={{ minWidth: '100px' }}
                                                color="primary"
                                                variant="contained"
                                                onClick={this.addOtherCost}>
                                                {i18n.t('quotes.preparatingQuoteInformation.addOtherCost')}
                                            </Button>
                                        </div>
                                    </DialogActions>
                                </Dialog>
                            }

                            <ConfirmationDialog
                                handleClose={this.cancelConfirmationDeleteOtherCost}
                                onConfirm={this.deleteOtherCost}
                                message={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmOtherCostMessage')}
                                cancelLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.cancelLabel')}
                                confirmLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmLabel')}
                                open={openConfirmationDialog}
                            />
                        </Grid>
                    )
                }}
            </GlobalParametersContext.Consumer>
        )
    }
}

InvoiceOtherCosts.contextType = GlobalParametersContext;



export default withStyles(styles)(InvoiceOtherCosts)