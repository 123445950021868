import React from 'react';
import {
    Grid,
    withStyles,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    withMobileDialog,
} from '@material-ui/core';
import {
    Close,
    Warning
} from '@material-ui/icons'
import i18n from 'i18next';
import moment from 'moment'
import { GoogleMap } from '../../general'

const styles = theme => ({
    map__container: {
        border: 'solid thin #000',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    visit__dialog__closeWindow: {
        position: 'absolute',
        top: '5px',
        padding: '6px',
        right: theme.spacing(),
    }
});

class VisitCheckInCheckOut extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            event: props.event,
            openCheckInInfo: props.openCheckInInfo,
            kmReference: props.kmReference || 0,
            lat: props.checkIn ? props.checkIn.lat : null,
            lng: props.checkIn ? props.checkIn.lng : null
        };
    }

    componentWillReceiveProps(nextProps){
         this.setState({
            event: nextProps.event,
            openCheckInInfo: nextProps.openCheckInInfo,
            kmReference: nextProps.kmReference,
            lat: nextProps.checkIn ? nextProps.checkIn.lat : null,
            lng: nextProps.checkIn ? nextProps.checkIn.lng : null
        })
    }

    render() {
        const { classes, width } = this.props;
        const { event, kmReference, lat, lng,
                openCheckInInfo } = this.state;

        if(!event) return null

        return (
            
            <Dialog aria-labelledby="translate-dialog"
                open={openCheckInInfo}
                width={'xs'}
                fullWidth={true}>
                <DialogTitle disableTypography={true} id="simple-dialog-title">
                    {i18n.t("visits.details.checkInVisit")}
                    <IconButton color="inherit"
                        onClick={this.props.handleClose}
                        aria-label="Close" className={classes.visit__dialog__closeWindow}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                { event &&
                    <Grid container spacing={1} style={{ padding: width === 'xs' ? '24px 16px' : '32px' }}>
                        {moment.utc(event.from).diff(moment(new Date()), 'hours') > 24 ?
                            <Typography variant='body2' color='textSecondary' style={{marginLeft: '8px'}}>
                                <Warning className={classes.warning__message__icon} />
                                {`${i18n.t("visits.details.noCheckIn")} ${moment.utc(event.from).format('MMM Do')} yet`}
                            </Typography>
                        :<>
                            {
                            // {moment.utc(event.from) < moment() ?
                            //     <Typography variant='body2' color='textSecondary' style={{marginLeft: '8px'}}>
                            //         <Warning className={classes.warning__message__icon} />
                            //         {i18n.t("visits.details.noCheckInPassVisit")}
                            //     </Typography>
                            // :<>
                            }
                                {kmReference > 10 &&
                                    <Typography variant='body2' color='textSecondary'>
                                        <Warning className={classes.warning__message__icon} />
                                        {`${i18n.t("visits.form.mayorDistance")}`}   
                                    </Typography>
                                }
                        </>}



                        { lat && lng && kmReference > 10 &&
                            <Grid item xs={12}>
                                <div className={classes.map__container}>
                                    <GoogleMap 
                                        location={{
                                            positions: [{
                                                lat: event.place.lat,
                                                lng: event.place.lng,
                                                title: 'Location'
                                            },{
                                                lat: lat,
                                                lng: lng,
                                                title: 'Check-In'
                                            }],
                                            zoom: 13
                                        }} 
                                    />
                                </div>
                            </Grid>
                        }
                    </Grid>
                }
            </Dialog>
        );
    }
}


export default withMobileDialog()(withStyles(styles)(VisitCheckInCheckOut));
