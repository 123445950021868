import React, { Component } from 'react'
import {
    Typography,
    Collapse
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { Send, Drafts, DoneOutline, HelpOutline, Notifications } from '@material-ui/icons'
import i18n from 'i18next';
import PropTypes from 'prop-types'

const styles = theme => {
    return ({
        legend__root: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
        },
        legend__root__container: {
            display: 'flex',
            flexDirection: 'column'
        },
        legend__root__row: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: theme.spacing(),
        },
        legend__label: {
            fontSize: '8pt'
        },
        legend__badge__icon: {
            right: -theme.spacing(),
            marginLeft: theme.spacing(),
            fontSize: '10pt'
        },
        vendor__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.red[100],
        },
        vendor__status__icon__requested: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.primary.main,
        },
        vendor__status__icon__ready: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.secondary.main,
        },
        legend__help__label__collapse: {
            padding: 0,
        },
        legend__help__label__container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer'
        },
        legend__help__icon: {
            marginRight: theme.spacing(),
        },
        legend__help__label: {
        },
        legend__collapse__container: {
            marginTop: theme.spacing(),
            textAlign: 'left'
        },
        legend__vendorrfq: {
            marginTop: theme.spacing(),
        },
        legend__title: {
            fontSize: '10pt',
        },
        rfq__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '14pt',
            color: theme.palette.red[100],
        },
    })
};

class QuotesListLegend extends Component {
    static propTypes = {
        status: PropTypes.number.isRequired
    }
    state = {
        showLegend: false
    }
    render() {
        const {
            classes,
            status
        } = this.props,
            { showLegend } = this.state;

        return (
            <div className={classes.legend__root}>
                <div className={classes.legend__help__label__container}
                    onClick={() => this.setState({
                        showLegend: !this.state.showLegend
                    })}>
                    <HelpOutline size='small' color='secondary' className={classes.legend__help__icon} />
                    <Typography color='textSecondary' >{i18n.t('listLegend.help')}</Typography>
                </div>
                <Collapse in={showLegend} className={classes.legend__collapse__container}>
                    <Typography color='textSecondary' className={classes.legend__title}><strong>{i18n.t('listLegend.files')}</strong></Typography>
                    <div className={classes.legend__root__container}>
                        <div className={classes.legend__root__row}>
                            <Notifications fontSize="small" className={classes.rfq__notification__icon} />
                            <Typography color='textSecondary' className={classes.legend__label}>{i18n.t('listLegend.requiredAttention')}</Typography>
                        </div>
                    </div>
                    {status === 2 && (
                        <div className={classes.legend__vendorrfq}>
                            <Typography color='textSecondary' className={classes.legend__title}><strong>{i18n.t('listLegend.vendorRfq')}</strong></Typography>
                            <div className={classes.legend__root__row}>
                                <Drafts className={classes.vendor__status__icon__preparing} />
                                <Typography color='textSecondary' className={classes.legend__label}>{i18n.t('listLegend.preparingVendorQuote')}</Typography>
                            </div>
                            <div className={classes.legend__root__row}>
                                <Send className={classes.vendor__status__icon__requested} />
                                <Typography color='textSecondary' className={classes.legend__label}>{i18n.t('listLegend.requestedVendorQuote')}</Typography>
                            </div>
                            <div className={classes.legend__root__row}>
                                <DoneOutline className={classes.vendor__status__icon__ready} />
                                <Typography color='textSecondary' className={classes.legend__label}>{i18n.t('listLegend.readyVendorQuote')}</Typography>
                            </div>
                        </div>
                    )}
                </Collapse>
            </div>
        )
    }
}

export default withStyles(styles)(QuotesListLegend);