import React, { useEffect, useState } from 'react'
import {
    Paper,
    Table,
    Typography,
    Grid,
    ListItemIcon,
    ListItemText,
    ListItem,
    List,
    ListItemSecondaryAction,
    TableCell,
    TableRow,
    TableBody,
    TablePagination,
    makeStyles,
    IconButton,
    Hidden,
    InputBase,
    Divider,
    Checkbox,
    withMobileDialog,
    FormControlLabel
} from '@material-ui/core'
import { EmailsListSkeleton } from './'
import {
    MoveToInbox,
    Delete,
    Archive,
    Inbox,
    Attachment,
    Search as SearchIcon,
} from '@material-ui/icons'
import Select from 'react-select';
import i18n from 'i18next'
import moment from 'moment'
import API from '../../lib/api'
import { EmailDetails } from './'
import {
    isMobile
} from "react-device-detect";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 0,
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px',
        display: 'flex',
        overflow: 'auto',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 5px',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        // top: 65
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    table: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    table__row: {
        cursor: 'pointer',
        border: 'solid thin transparent',
        '&:hover': {
            backgroundColor: '#eeeeee',
            boxShadow: '3px 3px #dddddd',
            border: 'solid thin #dddddd'
        }
    },
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '60vh',
        width: '100%'
    },
    table__column__sender: {
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    menu__list__item: {
        borderRadius: 0
    },
    'menu__list__item--selected': {
        backgroundColor: 'rgba(116,185,255,0.3)',
        borderRadius: 0
    },
    'menu__list__item__icon--selected': {
        color: theme.palette.getContrastText('rgba(116,185,255,0.3)')
    },
    'mail--read': {
        backgroundColor: 'rgba(239,239,239,0.3)'
    },
    'mail--unread': {
        backgroundColor: 'unset'
    },
    unread__badge: {
        right: -15,
        top: 5
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        border: '1px solid #c4c4c4',
        boxShadow: 'none',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    iconButton: {
        padding: '6px'
    },
    header__actions__container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    emailType__select: {
        width: '100%',
        fontFamily: theme.typography.fontFamily
    },
}))

const folders = [{
    id: 1,
    name: 'inbox'
},
{
    id: 2,
    name: 'archive'
},
{
    id: 3,
    name: 'trash'
},];

const options = [
    {
        value: 3,
        label: 'All'
    },
    {
        value: 1,
        label: 'Read'
    },
    {
        value: 2,
        label: 'Unread'
    }
]

const customStyles = {
    control: base => ({
        ...base,
        textAlign: 'left',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',
        fontFamily: 'inherit',
        '&:hover': {
            borderColor: '#000',
        }
    }),
}

function EmailsList(props) {
    let { history, width } = props;
    let defaultRows = width === 'xs' ? 10 : 20;



    let [emails, setEmails] = useState([]),
        [fetching, setFetching] = useState('fetching'),
        [filtering, setFiltering] = useState(false),
        [selectedMenu, setSelectedMenu] = useState(null),
        [unreadCounters, setUnreadCounters] = useState([]),
        [totalsCounters, setTotalsCounters] = useState({
            totalArchive: 0,
            totalInbox: 0,
            totalTrash: 0
        }),
        [selectedEmail, setSelectedEmail] = useState(null),
        [selectedEmails, setSelectedEmails] = useState([]),
        [checkAll, setCheckAll] = useState(false),
        [rowsPerPage, setRowsPerPage] = useState(defaultRows),
        [page, setPage] = useState(0),
        [search, setSearch] = useState(''),
        [selectedEmailType, setType] = useState(options[0]),
        currentUser = true,
        classes = useStyles();


    const updateEmails = () => {
        fetchEmails(selectedMenu.id, true, {}, selectedEmailType.value, search)
    }

    const fetchEmails = (folderId, silent, { explicitRows, explicitPage } = {}, type, search) => {
        if (!silent) {
            setFetching('fetching')
        }

        let rowsToFetch = explicitRows ? explicitRows : rowsPerPage
        API.Emails.getEmails({
            folderId: folderId,
            recordCount: rowsToFetch,
            type,
            search,
            currentUser,
            offset: (!isNaN(parseInt(explicitPage)) ? (explicitPage) : (page)) * rowsToFetch
        })
            .then(response => {
                setEmails(response.data.records.emails || []);
                setUnreadCounters(response.data.records.unreadCounters);
                setTotalsCounters(response.data.records.totalCounters);
                setFetching('fetched');
            }).catch(err => {
                console.log(err);
                setFetching('fetched');
            })
    }

    const handleChangefolder = (newFolderName) => {
        let newFolder = folders.find(f => f.name === newFolderName);
        setSelectedMenu(newFolder)
        setSelectedEmail(null)
        setPage(0)
        setRowsPerPage(defaultRows)
        fetchEmails(newFolder.id, false, {}, selectedEmailType.value, search);
        history.push(`/emails/${newFolderName.toLowerCase()}`);
    }

    const handleOpenEmail = (e, email) => {
        e && e.stopPropagation()
        let { folder } = props.match.params;
        if (email) {
            setSelectedEmail(email)
            history.push(`/emails/${folder}/${email.emailId}`);
        } else {
            history.push(`/emails/${folder}`);
            setSelectedEmail(null)
        }
    }
    const handleCloseEmail = () => {
        let { folder } = props.match.params;
        history.push(`/emails/${folder}`);
        setSelectedEmail(null)
    }

    const formatedDate = (date) => {
        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            return moment(date).format('MMM DD')
        }
    }

    const handleCheck = (e) => {
        e.stopPropagation();
        let newChecked = [...selectedEmails];
        if (e.target.checked === true || e.target.checked === 'true') {
            newChecked.push(e.target.id)
        } else {
            newChecked = newChecked.filter(c => c !== e.target.id)
        }
        setSelectedEmails(newChecked)
    }

    useEffect(() => {
        let isSuscribed = true;
        let { folder } = props.match.params,
            folderId = folder === 'inbox' ? 1 : folder === 'archive' ? 2 : 3;
        if (!selectedMenu || (selectedMenu && folderId !== selectedMenu.id)) {
            setFetching('fetching')
            setSelectedEmail(null)
            API.Emails.getEmails({
                folderId,
                recordCount: rowsPerPage,
                offset: (page) * rowsPerPage,
                currentUser
            })
                .then(response => {
                    if (isSuscribed) {
                        let newEmails = response.data.records.emails;
                        setEmails(newEmails || []);
                        setUnreadCounters(response.data.records.unreadCounters);
                        setTotalsCounters(response.data.records.totalCounters);
                        setFetching('fetched');
                        let newFolder = folders.find(f => f.id === folderId);
                        setSelectedMenu(newFolder)
                        if (props.match.params.emailId) {
                            let email = newEmails.find(e => parseInt(e.emailId) === parseInt(props.match.params.emailId));
                            handleOpenEmail(null, email)
                        } else {
                            handleCloseEmail()
                        }
                    }
                }).catch(err => {
                    console.log(err)
                    if (isSuscribed) {
                        setFetching('fetched')
                    }
                })
        }
        return () => {
            isSuscribed = false
        };
    }, [setFetching, setEmails, props.match.params])

    const onChangePage = (e, newPage) => {
        setPage(newPage)
        fetchEmails(selectedMenu.id, false, {
            explicitRows: rowsPerPage,
            explicitPage: newPage
        }, selectedEmailType.value, search)
    }
    const onChangeRowsPerPage = (e) => {
        if (e.target.value) {
            setRowsPerPage(parseInt(e.target.value))
            fetchEmails(selectedMenu.id, false, {
                explicitRows: e.target.value,
                explicitPage: page
            }, selectedEmailType.value, search)
        }
    }

    const handleChangeEmailType = (emailType) => {
        setFiltering(true)
        setType(emailType)
        setPage(0)
        setRowsPerPage(defaultRows)
        fetchEmails(selectedMenu.id, false, {}, emailType.value, search);
    }

    const handleChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const filterEmailsBySearch = () => {
        setFiltering(true)
        fetchEmails(selectedMenu.id, false, {}, selectedEmailType.value, search);
    }

    const pagination = () => {
        let currentTotal = selectedMenu ? (selectedMenu.id === 1 ? totalsCounters.totalInbox : (selectedMenu.id === 2 ? totalsCounters.totalArchive : totalsCounters.totalTrash)) : 0;

        return (
            <TablePagination
                component={width === 'xs' || width === 'sm' ? 'li' : undefined}
                style={{ width: '100%' }}
                rowsPerPageOptions={[50, 20, 10, 5]}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage='Rows: '
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                count={currentTotal}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}`} />
        )
    }

    const changeEmailsFolder = (folderId) => {
        setFetching('fetching')

        API.Emails.changeEmailsFolder({
            body: {
                emailsIds: selectedEmails,
                folderId: folderId
            },
            search,
            type: selectedEmailType.value
        })
            .then(response => {
                let newFolder = folders.find(f => f.id === folderId);
                setSelectedMenu(newFolder)
                setEmails(response.data.records.emails || []);
                setUnreadCounters(response.data.records.unreadCounters);
                setTotalsCounters(response.data.records.totalCounters);
                setSelectedEmails([])
                setFetching('fetched')
            }).catch(err => {
                console.log(err);
            })
    }

    const handleCheckAll = (e) => {
        e.stopPropagation()
        setCheckAll(e.target.checked)

        if (e.target.checked) {
            setSelectedEmails(emails.map(e => `${e.emailId}`))
        } else {
            setSelectedEmails([])
        }
    }

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', width: '100%' }}>
                <Paper className={classes.root}>
                    <Grid container spacing={1}>
                        <Hidden xsDown>
                            <Grid item sm={3} md={2} >
                                <List>
                                    <ListItem button className={selectedMenu && selectedMenu.name === 'inbox' ? classes['menu__list__item--selected'] : classes.menu__list__item}
                                        onClick={() => {
                                            handleChangefolder('inbox')
                                        }}>
                                        <ListItemIcon style={{ minWidth: 0, marginRight: 10 }}>
                                            <MoveToInbox
                                                className={selectedMenu && selectedMenu.name === 'inbox' ? classes['menu__list__item__icon--selected'] : ''} />
                                        </ListItemIcon>
                                        <ListItemText primary={i18n.t('emails.inbox')} />
                                        <ListItemSecondaryAction>
                                            {unreadCounters.find(uc => uc.folderId === 1) ? unreadCounters.find(uc => uc.folderId === 1).unreadCount : null}
                                        </ListItemSecondaryAction>
                                    </ListItem>

                                    <ListItem button className={selectedMenu && selectedMenu.name === 'archive' ? classes['menu__list__item--selected'] : classes.menu__list__item}
                                        onClick={() => {
                                            handleChangefolder('archive')
                                        }}>
                                        <ListItemIcon style={{ minWidth: 0, marginRight: 10 }}
                                            className={selectedMenu && selectedMenu.name === 'archive' ? classes['menu__list__item__icon--selected'] : ''} >
                                            <Archive />
                                        </ListItemIcon>
                                        <ListItemText primary={i18n.t('emails.archive')} />
                                        <ListItemSecondaryAction>
                                            {unreadCounters.find(uc => uc.folderId === 2) ? unreadCounters.find(uc => uc.folderId === 2).unreadCount : null}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button className={selectedMenu && selectedMenu.name === 'trash' ?
                                        classes['menu__list__item--selected'] : classes.menu__list__item}
                                        onClick={() => {
                                            handleChangefolder('trash')
                                        }}>
                                        <ListItemIcon style={{ minWidth: 0, marginRight: 10 }}
                                            className={selectedMenu && selectedMenu.name === 'trash' ? classes['menu__list__item__icon--selected'] : ''} >
                                            <Delete />
                                        </ListItemIcon>
                                        <ListItemText primary={i18n.t('emails.trash')} />
                                        <ListItemSecondaryAction>
                                            {unreadCounters.find(uc => uc.folderId === 3) ? unreadCounters.find(uc => uc.folderId === 3).unreadCount : null}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Hidden>

                        <Grid item xs={12} sm={9} md={10} style={{
                            minHeight: '90vh', position: 'relative'
                        }}>
                            {(isMobile || width === 'xs') && (
                                <Typography variant='h5' style={{
                                    margin: 10,
                                    textAlign: 'left'
                                }}>
                                    {selectedMenu && selectedMenu.id === 1 ? i18n.t('emails.inbox') : (selectedMenu && selectedMenu.id === 2 ? i18n.t('emails.archive') : i18n.t('emails.trash'))}
                                </Typography>
                            )}

                            {!selectedEmail && ((emails.length === 0 && filtering) || emails.length > 0) &&
                                <Grid container item spacing={1} display='flex' alignItems='center'>
                                    <Grid item xs={4} sm={2}>
                                        <Select
                                            required
                                            value={selectedEmailType}
                                            className={classes.emailType__select}
                                            onChange={handleChangeEmailType}
                                            options={options}
                                            maxMenuHeight='150'
                                            styles={{
                                                ...customStyles,
                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                            }}
                                            menuPosition='fixed'
                                            menuPlacement='auto'
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <Paper className={classes.search__root}>
                                            <InputBase
                                                className={classes.search__input}
                                                placeholder={i18n.t('Search email')}
                                                aria-label={i18n.t('Search email')}
                                                onChange={handleChangeSearch}
                                                value={search}
                                            />
                                            <IconButton className={classes.iconButton}
                                                aria-label="search" onClick={filterEmailsBySearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            }

                            {fetching === 'fetching' && (
                                <EmailsListSkeleton />
                            )}
                            {!selectedEmail && (
                                <>
                                    {fetching === 'fetched' && emails.length > 0 && (
                                        <>
                                            <Paper>
                                                <Hidden only={['xs', 'sm']}>

                                                    <div style={{ width: '100%', display: 'flex', paddingLeft: '20px', justifyContent: 'space-between' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox value={checkAll}
                                                                    onChange={handleCheckAll} />
                                                            }
                                                            label={i18n.t('emails.selectAll')}
                                                        />
                                                        {selectedEmails.length > 0 && (
                                                            <div className={classes.header__actions__container} >
                                                                <div style={{ display: 'flex' }}>
                                                                    {selectedMenu.id !== 1 &&
                                                                        <IconButton className={classes.iconButton} style={{ marginRight: '8px' }}
                                                                            onClick={() => changeEmailsFolder(1)}>
                                                                            <MoveToInbox />
                                                                        </IconButton>
                                                                    }

                                                                    {selectedMenu.id !== 2 &&
                                                                        <IconButton className={classes.iconButton} style={{ marginRight: '8px' }}
                                                                            onClick={() => changeEmailsFolder(2)}>
                                                                            <Archive />
                                                                        </IconButton>
                                                                    }

                                                                    {selectedMenu.id !== 3 &&
                                                                        <IconButton className={classes.iconButton}
                                                                            onClick={() => changeEmailsFolder(3)}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <Table className={classes.table}
                                                        size="small">
                                                        <TableBody>
                                                            {emails.map(row => (
                                                                <TableRow key={row.emailId}
                                                                    className={`${classes.table__row} ${row.read ? classes['mail--read'] : classes['mail--unread']}`}
                                                                    onClick={() => handleOpenEmail(null, row)}
                                                                >
                                                                    <TableCell padding='checkbox' onClick={(e) => { e.stopPropagation() }}>
                                                                        <Checkbox
                                                                            checked={selectedEmails.includes(`${row.emailId}`)}
                                                                            onChange={handleCheck}
                                                                            id={`${row.emailId}`} />
                                                                    </TableCell>
                                                                    <TableCell classes={{
                                                                        root: classes.table__column__sender
                                                                    }}
                                                                        style={{
                                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                                        }}>
                                                                        {row.senderWithFormat ? row.senderWithFormat.split('<')[0] : row.sender}
                                                                    </TableCell>
                                                                    <TableCell style={{ maxWidth: 300 }}>
                                                                        <Typography noWrap><span style={{
                                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                                        }}>{row.subject}</span> - {row.bodyPlain}</Typography>
                                                                    </TableCell>
                                                                    <TableCell padding='none'>
                                                                        {row.attachmentsCount > 0 && (
                                                                            <Attachment />
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell padding='none' align='right' style={{ paddingRight: 5 }}>
                                                                        {formatedDate(row.timestamp)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                {pagination()}
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Hidden>

                                                <Hidden mdUp>
                                                    <div style={{ display: 'flex', width: '100%', marginRight: '' }}>
                                                        <FormControlLabel
                                                            style={{ margin: '0 0 0 8px' }}
                                                            control={
                                                                <Checkbox value={checkAll}
                                                                    onChange={handleCheckAll} />
                                                            }
                                                            label={i18n.t('emails.selectAll')}
                                                        />
                                                    </div>
                                                    <List>
                                                        {emails.map(row => (
                                                            <ListItem button
                                                                key={row.emailId}
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}>
                                                                <div style={{
                                                                    width: '100%',
                                                                    position: 'relative'
                                                                }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Checkbox
                                                                            style={{ padding: '0', marginRight: "4px" }}
                                                                            checked={selectedEmails.includes(`${row.emailId}`)}
                                                                            onChange={handleCheck}
                                                                            id={`${row.emailId}`}
                                                                        />
                                                                        <Typography noWrap nClick={(e) => handleOpenEmail(e, row)} style={{
                                                                            maxWidth: '80%'
                                                                        }}><span style={{
                                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                                        }}>{row.senderWithFormat ? row.senderWithFormat : row.sender}</span></Typography>
                                                                    </div>
                                                                    <div onClick={(e) => handleOpenEmail(e, row)}>
                                                                        <Typography noWrap style={{
                                                                            maxWidth: '80%'
                                                                        }}><span style={{
                                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                                        }} >{row.subject}</span></Typography>
                                                                        <Typography noWrap style={{
                                                                            maxWidth: '80%'
                                                                        }}>{row.bodyPlain}</Typography>
                                                                        {row.attachmentsCount > 0 && (
                                                                            <Attachment style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                right: 4
                                                                            }} />
                                                                        )}
                                                                        <Typography style={{
                                                                            position: 'absolute',
                                                                            bottom: 0,
                                                                            right: 4
                                                                        }} noWrap>{formatedDate(row.timestamp)}</Typography>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ width: '100%', heigth: 5 }} />
                                                            </ListItem>
                                                        ))}
                                                        {/* <Box style={{ width: '100%' }}> */}
                                                        {pagination()}
                                                        {/* </Box> */}
                                                    </List>
                                                </Hidden>
                                            </Paper>
                                        </>
                                    )}

                                    {fetching === 'fetched' && emails.length === 0 && (
                                        <div className={classes.empty__state__container}>
                                            <Inbox style={{ fontSize: 50 }} color='secondary' />
                                            <Typography variant='body2'>{i18n.t('emails.noMails')}</Typography>
                                        </div>
                                    )}
                                </>
                            )}
                            {selectedEmail && (
                                <EmailDetails
                                    history={history}
                                    selectedEmail={selectedEmail}
                                    selectedMenu={selectedMenu}
                                    back={handleCloseEmail}
                                    setSelectedMenu={setSelectedMenu}
                                    folders={folders}
                                    updateEmails={updateEmails} />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>
    )
}


export default (withMobileDialog()(EmailsList))