import React, { Component } from 'react'
import EventsHeader from '../components/visits/EventsHeader';

export default class Layout extends Component {

  render() {
    return (
      <div className="App">
        <EventsHeader {...this.props} />
        <div style={{marginTop: '64px'}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
