import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import moment from "moment";
import {
    withStyles,
    CircularProgress,
    Divider,
    Drawer,
    Chip,
    Typography
} from '@material-ui/core'
import {
    KeyboardArrowRight,
} from '@material-ui/icons'
import { EventFilterSelector, EventPeopleFilter } from '.'
import API from '../../lib/api'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'
import * as peopleActions from '../../actions/peopleActions'


const drawerWidth = 280;

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    buttonSpinner: theme.buttonSpinner,
    events__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    },
    formGroup__content: {
        background: '#FFF',
        padding: '16px',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    formGroup__content__clickeableSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    chip: {
        margin: theme.spacing(0.5),
    },
});


class EventList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searching: false,
            keywords: '',
            missedCheckIn: false,
            formType: null,
            client: null,
            country: null,
            eventType: '',
            chipData: []
        }
    }

    navigate = (path) => {
        const { history, toggleOpenMenu } = this.props;
        toggleOpenMenu();
        history.push(path);
    }

    componentWillReceiveProps(nextProps) {
        const { eventsFilters, people, user } = nextProps;

        this.setState({
            keywords: eventsFilters.search || '',
            missedCheckIn: eventsFilters.missedCheckIn || false
        })

        let chipData = []

        if (people.filterPeople.length > 1 || (people.filterPeople.length === 1 && people.filterPeople[0].userId && people.filterPeople[0].userId !== parseInt(user.attributes['custom:userID']))) {
            chipData[0] = { key: 0, label: `${people.filterPeople.length} ${i18n.t('visits.filters.users')}` }
        }

        if (people.filterPeople.length === 1 && !people.filterPeople[0].userId) {
            chipData[0] = { key: 0, label: i18n.t('visits.filters.everyone') }
        }

        if (eventsFilters.missedCheckIn) {
            chipData[1] = { key: 1, label: i18n.t('visits.filters.missedCheckIn') }
        }

        if (eventsFilters.search) {
            chipData[2] = {
                key: 2,
                label: eventsFilters.search
            }
            //i18n.t('visits.filters.keyWords')}
        }

        this.setState({
            chipData: chipData
        })

    }

    onEnter = () => {
        this.setState({
            keywords: '',
            missedCheckIn: false,
            formType: null,
            client: null,
            country: null,
            eventType: '',
        })
    }

    showSubFormPopUp = (type) => {
        this.setState({
            formType: type
        }, () => {
            this.setState({
                filterVisible: true
            })
        })
    }

    selectCountry = (country) => {
        this.setState({
            country: country
        })
    }

    selectClient = (client) => {
        this.setState({
            client: client
        })
    }

    selectEventType = (eventType) => {
        this.setState({
            eventType: eventType
        })
    }

    inputChange = (e) => {
        const { eventsFilters } = this.props;

        this.setState({
            keywords: e.target.value
        })

        this.props.eventsActions.setFilters({
            ...eventsFilters,
            search: e.target.value
        });
    }

    setMissedCheckIn = (e) => {
        const { eventsFilters } = this.props;

        this.setState({
            missedCheckIn: e.target.checked
        })

        this.props.eventsActions.setFilters({
            ...eventsFilters,
            missedCheckIn: e.target.checked
        });

    }

    handlerSearch = () => {
        const { people, eventsFilters } = this.props,
            { keywords, client, eventType, missedCheckIn } = this.state;

        let usersIDs = people.filterPeople.map(u => u.userId)

        // Mantener filtros en redux
        this.props.eventsActions.setFilters({
            ...eventsFilters,
            search: keywords || null,
            missedCheckIn: missedCheckIn
        });

        this.setState({
            searching: true
        })

        let users = usersIDs ? usersIDs.join(',') : "";

        API.Events.getEvents({
            m: eventsFilters.m,
            y: eventsFilters.y,
            to: null,
            from: null,
            type: eventType || null,
            search: keywords || null,
            client: client ? client.name : null,
            missedCheckIn: missedCheckIn || null,
            usersID: users
        }).then(res => {

            let newEvents = res.data.records && res.data.records.length > 0 ? res.data.records.map(event => {
                return {
                    ...event,
                    start: event.from,
                    end: event.to,
                    color: event.user.color,
                    text: event.isVisit ? event.client.name : event.title
                }
            }) : [];

            this.setState({
                searching: false
            })

            this.props.eventsActions.setEvents(newEvents)
            this.props.toggleOpenMenu()

        });
    }

    toggleOpenFilterMenu = () => {
        this.setState({
            filterVisible: !this.state.filterVisible
        })
    }

    toggleOpenUserMenu = () => {
        this.setState({
            userMenuVisible: !this.state.userMenuVisible
        })
    }

    handleDeleteChip = (filter) => {
        const { user, people, eventsFilters } = this.props;

        switch (filter.key) {
            case 0:
                let newUser = people.allPeople.find(u => u.userId === parseInt(user.attributes['custom:userID']))

                this.props.peopleActions.setFilterPeople([newUser]);
                break;
            case 1:
                this.setState({
                    missedCheckIn: false,
                })

                this.props.eventsActions.setFilters({
                    ...eventsFilters,
                    missedCheckIn: null
                });
                break;
            default:
                this.setState({
                    keywords: '',
                })

                this.props.eventsActions.setFilters({
                    ...eventsFilters,
                    search: null
                });
                break;
        }
    }

    toggleOpenMenu = () => {
        const { eventsFilters, people, user } = this.props

        let activeFilter = eventsFilters.search || eventsFilters.missedCheckIn || people.filterPeople.length > 1 || (people.filterPeople.length === 1 && people.filterPeople[0].userId !== parseInt(user.attributes['custom:userID'])) ? true : false

        if (!activeFilter) {
            this.handlerSearch()
        }
    }

    render() {
        const { searching, keywords, missedCheckIn,
            // client, country, eventType,
            formType, chipData } = this.state,
            { classes, menuVisible, user, eventsFilters, people } = this.props;

        let selectedDate = moment(`${eventsFilters.y}-${eventsFilters.m >= 10 ? eventsFilters.m : `0${eventsFilters.m}`}-01`),
            activeFilter = eventsFilters.search || eventsFilters.missedCheckIn || people.filterPeople.length > 1 || (people.filterPeople.length === 1 && people.filterPeople[0].userId !== parseInt(user.attributes['custom:userID'])) ? true : false


        return (
            <div>
                <Drawer open={menuVisible}
                    anchor="right"
                    onClose={this.toggleOpenMenu}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <mobiscroll.Form className={classes.form}>

                        <mobiscroll.FormGroup style={{ marginTop: '8px' }}>
                            <mobiscroll.FormGroupTitle>
                                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
                                    {`${selectedDate.format("MMMM")} ${selectedDate.format('YYYY')}`}
                                </div>
                            </mobiscroll.FormGroupTitle>
                            <div style={{ background: '#fff', position: 'relative' }}>
                                <EventPeopleFilter
                                    menuVisible={this.state.userMenuVisible}
                                    toggleOpenMenu={this.toggleOpenUserMenu}
                                    user={user}
                                // reloadList={this.reloadEvents} 
                                />
                                <KeyboardArrowRight style={{ color: '#ccc', position: 'absolute', zIndex: 1, right: 0, top: '13px' }} />
                            </div>
                            <Divider />

                            {
                                // <mobiscroll.Form.Label onClick={() => this.showSubFormPopUp('country')} style={{ alignItems: 'center' }}>
                                //     {i18n.t('visits.filters.country')}
                                //     <input value={country && country.countryName ? country.countryName : ''} name='type' disabled />
                                //     <KeyboardArrowRight style={{ color: '#ccc' }} />
                                // </mobiscroll.Form.Label>

                                // { country &&
                                //     <mobiscroll.Form.Label onClick={() => this.showSubFormPopUp('client')} style={{ alignItems: 'center' }}>
                                //         {i18n.t('visits.filters.client')}
                                //         <input value={client && client.name ? client.name : ''} name='type' disabled />
                                //         <KeyboardArrowRight style={{ color: '#ccc' }}/>
                                //     </mobiscroll.Form.Label>
                                // }

                                // <mobiscroll.Form.Label onClick={() => this.showSubFormPopUp('eventType')} style={{ alignItems: 'center' }}>
                                //     {i18n.t('visits.filters.type')}
                                //     <input value={eventType} name='type' disabled />
                                //     <KeyboardArrowRight style={{ color: '#ccc' }} />
                                // </mobiscroll.Form.Label>
                            }


                            <mobiscroll.Switch className='longLabel' checked={missedCheckIn} onChange={this.setMissedCheckIn}>
                                {i18n.t('visits.filters.missedCheckIn')}
                            </mobiscroll.Switch>

                            <mobiscroll.Form.Label className='longLabel'>
                                {i18n.t('visits.filters.keyWords')}
                                <input value={keywords} name='keywords' onChange={this.inputChange} />
                            </mobiscroll.Form.Label>
                        </mobiscroll.FormGroup>

                        <mobiscroll.FormGroup >
                            {chipData.length > 0 && (
                                <Typography
                                    variant='body2'
                                    style={{
                                        margin: '0 10px 10px 10px'
                                    }} color='textSecondary'> {i18n.t('visits.filters.filteredBy')}</Typography>
                            )}
                            {chipData.map(data => {
                                return (
                                    <Chip
                                        key={data.key}
                                        label={data.label.length <= 10 ? data.label : `${data.label}...`}
                                        onDelete={() => this.handleDeleteChip(data)}
                                        className={classes.chip}
                                    />
                                );
                            })}
                        </mobiscroll.FormGroup>

                        <mobiscroll.FormGroup>
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '8px', background: '#fff' }}>
                                <mobiscroll.Button color="primary"
                                    disabled={searching}
                                    onClick={this.handlerSearch}
                                    style={{ position: 'relative' }}>
                                    {activeFilter ? i18n.t("visits.filters.filterButton") : i18n.t("visits.filters.filterButtonClose")}
                                    {searching && <CircularProgress color='secondary'
                                        className={classes.buttonSpinner} size={24} />}
                                </mobiscroll.Button>
                            </div>
                        </mobiscroll.FormGroup>
                    </mobiscroll.Form>
                </Drawer>

                <EventFilterSelector
                    menuVisible={this.state.filterVisible}
                    toggleOpenMenu={this.toggleOpenFilterMenu}
                    type={formType}
                    selectCountry={this.selectCountry}
                    selectClient={this.selectClient}
                    selectType={this.selectEventType}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        events: state.events,
        people: state.people,
        eventsFilters: state.eventsFilters
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch),
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventList));
