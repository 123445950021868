import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    withMobileDialog
} from '@material-ui/core'
import {
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Add,
    CheckBoxOutlineBlank,
    CheckBox
} from '@material-ui/icons'
import { SlideLeft } from '../general/Transitions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EventFormSub3, EventClientForm, EventClientContactForm } from '.'
import * as clientsActions from '../../actions/clientsActions'
import * as eventsActions from '../../actions/eventsActions'
import { GlobalParametersContext } from '../../contexts/globalParametersContext'
import { DealsList, DealForm } from './deals'
import API from '../../lib/api';

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    events__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    },
    dialog__paper: {
        width: '100%'
    },
    deals__container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px',
        textAlign: 'center'
    },
});

class ListEventCountryItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.countryId}
                data-selected={item.selected}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 0,
                }}>
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}
                        onClick={() => { item.selectCountry(item.index) }}>
                        {item.selected ? <CheckBox style={{ color: '#3f97f6', marginRight: '8px' }} /> : <CheckBoxOutlineBlank style={{ marginRight: '8px', color: '#707070' }} />}
                    </div>
                    {item.countryName}
                </div>
            </li>
        )
    }
}

class ListEventClientItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id}
                data-selected={item.selected}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 0,
                }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px',
                    alignItems: 'center'
                }}>
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}
                        onClick={() => item.selectClient(item.index)}>
                        {item.selected ? <CheckBox style={{ color: '#3f97f6', marginRight: '8px' }} /> : <CheckBoxOutlineBlank style={{ marginRight: '8px', color: '#707070' }} />}
                    </div>
                    {item.name}
                </div>
                <div style={{
                    margin: '-10px -10px -15px -20px',
                    padding: '10px 10px 10px 20px',
                    display: 'flex',
                }}
                    onClick={() => {
                        item.activeDetail(item.index, 'client')
                    }}>
                    <KeyboardArrowRight style={{ color: '#ccc' }} />
                </div>
            </li>
        )
    }
}

class ListEventClientContactItem extends React.Component {
    render() {
        const { item } = this.props;

        return (
            <li key={item.id}
                data-selected={item.selected}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 0,
                }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px',
                    alignItems: 'center'
                }}>
                    <div style={{ margin: -10, padding: 10, display: 'flex' }}
                        onClick={() => item.selectClientContact(item.index)}>
                        {item.selected ? <CheckBox style={{ color: '#3f97f6', marginRight: '8px' }} /> : <CheckBoxOutlineBlank style={{ marginRight: '8px', color: '#707070' }} />}
                    </div>
                    {`${item.firstName || ''} ${item.lastName || ''}`}
                </div>
                <div style={{
                    margin: '-10px -10px -15px -20px',
                    padding: '10px 10px 10px 20px',
                    display: 'flex',
                }}
                    onClick={() => item.activeDetail(item.index, 'clientContact')}>
                    <KeyboardArrowRight style={{ color: '#ccc' }} />
                </div>
            </li>
        )
    }
}


class EventFormSub2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: null,
            formType: null,
            clients: this.props.clients,
            showDealPopup: false,
            filterCountries: [],
            selectedDeal: null,
            selectedElement: null,
            openEventFormSub3: false,
            openEventClientForm: false,
            openEventClientContactForm: false,
            country: props.event && props.event.client ? props.event.client.country : props.user ? props.user.userCountry : null,
            client: props.event && props.event.client && props.event.client.id ? props.event.client : null,
            clientContact: props.event && props.event.clientContact && props.event.clientContact.id ? props.event.clientContact : null,
        }
    }

    componentWillReceiveProps(nextProps) {
        let contacts = []

        if (nextProps.event && nextProps.event.client && nextProps.event.client.contacts) {
            contacts = nextProps.event.client.contacts
        } else {
            if (nextProps.clients && nextProps.event && nextProps.event.client && nextProps.event.client.id) {
                contacts = nextProps.clients.find(c => c.id === nextProps.event.client.id) ? nextProps.clients.find(c => c.id === nextProps.event.client.id).contacts : []
            }
        }

        this.setState({
            clients: nextProps.clients,
            filterCountries: this.context && this.context.countries ? this.context.countries : [],
            contacts: contacts,
            country: nextProps.event && nextProps.event.client ? nextProps.event.client.country : nextProps.user ? nextProps.user.userCountry : null,
            client: nextProps.event && nextProps.event.client && nextProps.event.client.id ? nextProps.event.client : null,
            clientContact: nextProps.event && nextProps.event.clientContact && nextProps.event.clientContact.id ? nextProps.event.clientContact : null,
        })
        if (!nextProps.clients || nextProps.clients.length === 0 || (nextProps.event && nextProps.event.client && nextProps.event.client.country.id && nextProps.clients.length !== 0 && parseInt(nextProps.clients[0].country.id) !== parseInt(nextProps.event.client.country.id))) {
            if (nextProps.event && nextProps.event.client && nextProps.event.client.country.id) {
                this.fetchClients(nextProps.event.client.country.id)
            } else {
                if (nextProps.user) {
                    this.fetchClients(nextProps.user.userCountry.id)
                }
            }
        }
    }

    fetchClients = (countryId) => {
        API.Clients.getClients({
            countryId: countryId
        }).then(res => {
            this.props.clientsActions.setClients(res.data.records);
        })
    }

    showSubFormPopUp = (index, type) => {
        const { clients, event } = this.props,
            { contacts } = this.state;

        let newContacts = contacts ? contacts : event && event.id && clients && clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)) ? clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)).contacts : []

        this.setState({
            formType: type,
            selectedElement: type === 'client' ? clients[index] : newContacts[index]
        }, () => {
            this.setState({
                openEventFormSub3: true
            })
        })
    }

    selectCountry = (e, countries) => {
        const { event } = this.props;

        this.props.clientsActions.setClients([])

        let country = countries.find(country => country.countryId === e.id),
            newEvent = {
                ...event,
                client: {},
                clientContact: null,
                place: null,
                locationConfirmed: false
            }

        newEvent.client.country = {
            id: country.countryId,
            name: country.countryName
        }

        this.props.eventsActions.setEvent(newEvent)
        this.props.onClose()
    }

    selectClient = (index) => {
        const { event, clients } = this.props
        let newClient = clients[index];
        let newEvent = {
            ...event,
            client: newClient,
            clientContact: newClient.contacts && newClient.contacts.length === 1 ? newClient.contacts[0] : null,
            place: null,
            locationConfirmed: false
        }


        this.props.eventsActions.setEvent(newEvent)
        this.props.onClose()
    }

    selectClientContact = (index) => {
        const { contacts } = this.state,
            { event, clients } = this.props

        let newContacts = contacts ? contacts : event && event.id && clients && clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)) ? clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)).contacts : []

        let newEvent = {
            ...event,
            clientContact: newContacts[index]
        }

        this.props.eventsActions.setEvent(newEvent)
        this.props.onClose()
    }

    showClientPopup = () => {
        this.setState({
            openEventClientForm: true
        })
    }

    showClientContactPopup = () => {
        this.setState({
            openEventClientContactForm: true
        })
    }

    closeThisForm = () => {
        this.setState({
            formType: null,
            selectedElement: null,
            openEventFormSub3: false,
        })

        this.props.onClose()
    }

    updateClient = (client) => {
        const { event } = this.props

        this.setState({
            selectedElement: client
        })

        let newEvent = {
            ...event,
            client: client,
            clientContact: null
        }

        this.props.eventsActions.setEvent(newEvent)
    }

    updateClientContact = (contact, clientId) => {
        const { event, clients } = this.props

        let index = clients.findIndex(cl => cl.id === clientId)

        this.setState({
            selectedElement: contact
        })

        let newEvent = {
            ...event,
            client: {
                ...event.client,
                contacts: clients[index].contacts
            },
            clientContact: contact
        }

        this.props.eventsActions.setEvent(newEvent)
    }

    deleteClientContact = (contact, clientId) => {
        const { clients, event } = this.props

        let index = clients.findIndex(cl => cl.id === clientId)

        this.setState({
            selectedElement: null
        })

        let newEvent = {
            ...event,
            client: {
                ...event.client,
                contacts: clients[index].contacts.filter(cl => cl.id !== contact.id)
            },
            clientContact: event.clientContact && event.clientContact.id === contact.id ? null : event.clientContact
        }

        this.props.eventsActions.setEvent(newEvent)
    }

    activeDealPopup = () => {
        this.setState({
            showDealPopup: true
        })
    }

    addDeal=(newDeal)=>{
        this.setState({
            selectedDeal: newDeal
        })
        this.props.addDeal(newDeal)
    }

    filterCountries=(e)=>{
        const { countries } = this.context

        let newCountries = [...countries]

        this.setState({
            filterCountries: newCountries.filter(co => co.countryName.toLowerCase().includes(e.target.value.toLowerCase()))
        })
    }

    filterClients=(e)=>{
        const { clients } = this.props

        let newClients = [...clients]

        this.setState({
            clients: newClients.filter(co => co.name.toLowerCase().includes(e.target.value.toLowerCase()))
        })
    }

    render() {
        const { classes, type, event, fullScreen } = this.props,
            { contacts, country, selectedElement, formType, openEventClientContactForm,
                clientContact, openEventFormSub3, openEventClientForm, client,
                showDealPopup, selectedDeal, filterCountries, clients} = this.state;

        let newContacts = contacts ? contacts : event && event.id && clients && clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)) ? clients.find(x => parseInt(x.country.id) === parseInt(event.client.country.id)).contacts : []

        return (
            <>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    open={this.props.open}
                    TransitionComponent={SlideLeft}>

                    <GlobalParametersContext.Consumer>
                        {({ countries }) => (<>
                            <DialogTitle disableTypography={true} className={classes.events__form__title}>
                                <IconButton onClick={this.props.onClose}>
                                    <KeyboardArrowLeft color='inherit' className={classes.events__form__title__back} />
                                </IconButton>
                                <Typography variant='body1' color="inherit">
                                    {type === 'country' ? i18n.t('visits.eventForm.selectConuntry') : type === 'client' ? i18n.t('visits.eventForm.selectClient') : type === 'deals' ? i18n.t('visits.eventForm.deals') : i18n.t('visits.eventForm.selectClientContact')}
                                </Typography>
                                {type !== 'country' && type !== 'deals' ?
                                    <IconButton style={{ color: '#007bff', padding: '4px' }} onClick={type === 'client' ? this.showClientPopup : this.showClientContactPopup}>
                                        <Add />
                                    </IconButton>
                                    :
                                    <div style={{ width: type === 'deals' ? '55px' : '20px' }} />
                                }
                            </DialogTitle>

                            <DialogContent style={{ padding: '0', background: type ==='deals' ? '#fafafa' : '#fff'}}>
                                {type === 'country' &&
                                    <mobiscroll.Form className={classes.form}>
                                        <mobiscroll.FormGroup>
                                            <label>
                                                <input mbsc-input type="text" placeholder="Search" data-icon="search" data-icon-align="right" onChange={this.filterCountries}/>
                                            </label>
                                            <mobiscroll.Listview
                                                lang="es"
                                                theme="ios"
                                                itemType={ListEventCountryItem}
                                                //onItemTap={(e) => this.selectCountry(e, countries)}
                                                data={filterCountries.map((i, index) => {
                                                    if (country && i.countryId === country.id) {
                                                        return {
                                                            ...i,
                                                            id: i.countryId,
                                                            selected: true,
                                                            selectCountry: () => { this.selectCountry({ id: i.countryId }, countries) }
                                                        }
                                                    } else {
                                                        return {
                                                            ...i,
                                                            id: i.countryId,
                                                            selected: false,
                                                            selectCountry: () => { this.selectCountry({ id: i.countryId }, countries) }
                                                        }
                                                    }
                                                })}
                                                select="off"
                                            />
                                        </mobiscroll.FormGroup>
                                    </mobiscroll.Form>
                                }

                                {type === 'client' &&
                                    <mobiscroll.Form className={classes.form}>
                                        <mobiscroll.FormGroup style={{ marginTop: '11px' }}>
                                            <mobiscroll.FormGroupTitle>
                                                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px', fontSize: '16px' }}>
                                                    {country && country.name}
                                                </div>
                                            </mobiscroll.FormGroupTitle>
                                            <label>
                                                <input mbsc-input type="text" placeholder="Search" data-icon="search" data-icon-align="right" onChange={this.filterClients}/>
                                            </label>
                                            <mobiscroll.Listview
                                                lang="es"
                                                theme="ios"
                                                itemType={ListEventClientItem}
                                                data={clients.map((i, index) => {
                                                    if (client && i.id === client.id) {
                                                        return {
                                                            ...i,
                                                            index: index,
                                                            selected: true,
                                                            activeDetail: this.showSubFormPopUp,
                                                            selectClient: this.selectClient
                                                        }
                                                    } else {
                                                        return {
                                                            ...i,
                                                            index: index,
                                                            selected: false,
                                                            activeDetail: this.showSubFormPopUp,
                                                            selectClient: this.selectClient
                                                        }
                                                    }
                                                })}
                                                select="off"
                                            />
                                        </mobiscroll.FormGroup>
                                    </mobiscroll.Form>
                                }

                                {type === 'clientContact' &&
                                    <mobiscroll.Form className={classes.form}>
                                        <mobiscroll.FormGroup style={{ marginTop: '11px' }}>
                                            <mobiscroll.FormGroupTitle>
                                                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px', fontSize: '16px' }}>
                                                    {client && client.name}
                                                </div>
                                            </mobiscroll.FormGroupTitle>

                                            {!client || !client.id ?
                                                <Typography style={{ margin: '16px' }} color="textSecondary">{i18n.t('quotes.quoteRequest.selectClientFirst')}</Typography>
                                                : <>
                                                    {newContacts.length === 0 ?
                                                        <Typography style={{ margin: '16px' }} color="textSecondary">{i18n.t('quotes.quoteRequest.noClientContacts')}</Typography>
                                                        :
                                                        <mobiscroll.Listview
                                                            lang="es"
                                                            theme="ios"
                                                            itemType={ListEventClientContactItem}
                                                            data={newContacts.map((i, index) => {
                                                                if (clientContact && i.id === clientContact.id) {
                                                                    return {
                                                                        ...i,
                                                                        index: index,
                                                                        selected: true,
                                                                        activeDetail: this.showSubFormPopUp,
                                                                        selectClientContact: this.selectClientContact
                                                                    }
                                                                } else {
                                                                    return {
                                                                        ...i,
                                                                        index: index,
                                                                        selected: false,
                                                                        activeDetail: this.showSubFormPopUp,
                                                                        selectClientContact: this.selectClientContact
                                                                    }
                                                                }
                                                            })}
                                                            select="off"
                                                        />
                                                    }
                                                </>}
                                        </mobiscroll.FormGroup>
                                    </mobiscroll.Form>
                                }

                                {type === 'deals' &&
                                    <div className={classes.deals__container}>
                                        <DealsList 
                                            newDeal={selectedDeal}
                                            activeDealPopup={this.activeDealPopup}
                                            visitId={event && event.visit && event.visit.id ? event.visit.id : null}
                                        />
                                    </div>
                                }

                            </DialogContent>
                        </>)}
                    </GlobalParametersContext.Consumer>
                </Dialog>
                <EventFormSub3
                    open={openEventFormSub3}
                    isOwn={this.props.isOwn}
                    type={formType}
                    element={selectedElement}
                    client={client}
                    onClose={() => {
                        this.setState({
                            formType: null,
                            selectedElement: null,
                            openEventFormSub3: false,
                        })
                    }}
                    deleteClientContact={this.deleteClientContact}
                    closeFormSub2={this.closeThisForm}
                    showClientPopup={this.showClientPopup}
                    showClientContactPopup={this.showClientContactPopup}
                />

                <DealForm
                    open={showDealPopup}
                    addDeal={this.addDeal}
                    visitId={event && event.visit && event.visit.id ? event.visit.id : null}
                    onClose={()=>{
                        this.setState({
                            showDealPopup: false,
                            selectedDeal: null
                        })
                    }}
                />

                <EventClientForm
                    open={openEventClientForm}
                    country={country}
                    client={selectedElement}
                    updateClient={this.updateClient}
                    onClose={() => {
                        this.setState({
                            openEventClientForm: false,
                            selectedElement: null
                        })
                    }}
                />

                <EventClientContactForm
                    open={openEventClientContactForm}
                    client={client}
                    clientContact={selectedElement}
                    updateClientContact={this.updateClientContact}
                    onClose={() => {
                        this.setState({
                            openEventClientContactForm: false,
                            selectedElement: null
                        })
                    }}
                />

            </>
        );
    }
}

EventFormSub2.contextType = GlobalParametersContext;


function mapStateToProps(state) {
    return {
        event: state.event,
        clients: state.clients,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventFormSub2)));
