import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    Typography,
    Divider,
    Tabs,
    Tab,
    Hidden,
    Link,
    Paper,
    List,
    ListItem,
    ListItemText,
    TextField,
    Select,
    InputAdornment
} from '@material-ui/core';
import API from '../../../lib/api'
import { TasksShortcut } from '../common'
import { withStyles } from '@material-ui/core/styles'
import { Send, Drafts, DoneOutline, Inbox, Search, Block } from '@material-ui/icons'
import { VendorQuotePopup } from '.';
import i18n from 'i18next';
import classNames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as vendorQuotesActions from '../../../actions/vendorQuotesActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'
import * as generalActions from '../../../actions/generalActions'
import * as filesActions from '../../../actions/filesActions'
import * as vendorsActions from '../../../actions/vendorsActions'


const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        list__vendors__cell: {
            display: 'flex',
            flexDirection: 'column',
        },
        vendor__quotes__table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        vendor__quotes__table__cell__content: {
            whiteSpace: 'normal'
        },
        vendor__quotes__select: {
            fontFamily: theme.typography.fontFamily,
            zIndex: '2',
            width: '147px',
            fontSize: '0.8em',
            margin: `${theme.spacing()}px 0`,
            textAlign: 'left'
        },
        vendor__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.red[100],
        },
        vendor__status__icon__requested: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.primary.main,
        },
        vendor__status__icon__ready: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.secondary.main,
        },
        vendor__quotes__tabs__divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        vendor__quote__items__itemPartNumber: {
            color: theme.palette.naval.main,
        },
        vendor__quote__items__quantity: {
            color: theme.palette.text.secondary
        },
        vendor__quote__salesPerson: {
            color: theme.palette.naval.main,
        },
        vendor__quote__items__descriptionResumed: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: '95%',
            cursor: 'pointer'
        },
        vendorRfq__table__fileLink: {
            color: theme.palette.text.primary,
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        vendorRfq__table__vendorLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        vendor__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '14pt',
            color: theme.palette.red[100],
        },
        list__vendors__cell__row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        list__fileCountry: { fontSize: '9pt' },
        vendorRFQ__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    })
};


class VendorQuotesList extends Component {

    state = {
        vendorQuotes: null,
        filterVendorQuotes: null,
        vendorQuotesSize: 0,
        currentTab: 0,
        file: null,
        vendorQuote: null,
        highlightRow: null,
        showVendorQuote: false
    }

    componentWillMount() {
        this.setVendorQuotesData(this.props.vendorQuotes)
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.vendorQuotes) {
            this.setVendorQuotesData(nextProps.vendorQuotes)
        }
    }

    setVendorQuotesData = (vendorQuotes) => {
        let preparingVQ = vendorQuotes.filter(v => v.vendorQuoteStatusId === 1),
            requestedVQ = vendorQuotes.filter(v => v.vendorQuoteStatusId === 2),
            readyVQ = vendorQuotes.filter(v => v.vendorQuoteStatusId === 3),
            readyNR = vendorQuotes.filter(v => v.vendorQuoteStatusId === 4),
            filteredVQ = preparingVQ,
            currentTab = this.state.currentTab;
        const { highlightRow } = this.state;


        if (preparingVQ.length > 0) {
            currentTab = 0;
            filteredVQ = preparingVQ;
        } else if (preparingVQ.length === 0 && requestedVQ.length > 0) {
            currentTab = 1;
            filteredVQ = requestedVQ;
        } else if (readyVQ.length > 0) {
            currentTab = 2;
            filteredVQ = readyVQ;
        }

        if (highlightRow) {
            let modifiedVQ = vendorQuotes.find(vq => vq.id === highlightRow);
            if (modifiedVQ) {
                currentTab = modifiedVQ.status.id - 1;
                filteredVQ = currentTab === 0 ? preparingVQ : currentTab === 1 ? requestedVQ : readyVQ
            }
        }
        this.setState({
            vendorQuotes: vendorQuotes,
            preparingQuotesCount: preparingVQ.length,
            requestedQuotesCount: requestedVQ.length,
            readyQuotesCount: readyVQ.length,
            nonResponsiveCount: readyNR.length,
            currentTab,
            filterVendorQuotes: filteredVQ,
            vendorQuotesSize: filteredVQ.length
        })
    }


    filterVendorQuotes = (e, value) => {
        const { vendorQuotes } = this.state;

        let newVendorQuotes = vendorQuotes.filter(v => v.vendorQuoteStatusId === value + 1)
        this.setState({
            currentTab: value,
            vendorQuotesSize: newVendorQuotes.length,
            filterVendorQuotes: newVendorQuotes
        })
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    openEditVendorQuote = (row) => {
        if (row.fileId) {
            API.Files.getFile({
                fileId: row.fileId
            }).then(res => {
                if (res.file) {
                    this.props.filesActions.setFile(res.file);
                    this.setState({
                        showVendorQuote: true,
                        file: res.file,
                        vendorQuote: res.file.vendorQuotes.find(vq => vq.id === row.vendorQuoteId),
                    })
                }
            })
        }
    }
    handleClose = () => {
        this.setState({
            showVendorQuote: false,
        })
    }
    onExited = () => {
        this.props.filesActions.setFile({});
        this.setState({
            file: null,
            vendorQuote: null
        })
    }

    deleteVendorQuote = (vendorQuote) => {
        this.setState({
            highlightRow: vendorQuote.fileId
        })

        setTimeout(() => {
            this.setState({ highlightRow: null });
        }, 7000);
    }

    updateVendorQuote = (vendorQuote, editVendorRFQ) => {


        this.setState({
            currentTab: vendorQuote ? vendorQuote.status.id - 1 : this.state.currentTab,
            vendorQuote: vendorQuote,
            highlightRow: vendorQuote.id
        }, () => {
            this.props.vendorQuotesActions.updateVendorQuote(vendorQuote)
        });

        setTimeout(() => {
            this.setState({ highlightRow: null });
        }, 7000);
    }


    render = () => {
        const { classes, countries, vendors } = this.props,
            { filterVendorQuotes,
                vendorQuotesSize,
                currentTab,
                preparingQuotesCount,
                requestedQuotesCount,
                readyQuotesCount,
                nonResponsiveCount,
                file, vendorQuote,
                highlightRow,
                showVendorQuote
            } = this.state,
            counters = [preparingQuotesCount, requestedQuotesCount, readyQuotesCount, nonResponsiveCount];

        const options = [
            {
                label: i18n.t('vendorQuoteStatus.preparing'),
                value: 1
            },
            {
                label: i18n.t('vendorQuoteStatus.requested'),
                value: 2
            },
            {
                label: i18n.t('vendorQuoteStatus.ready'),
                value: 3
            },
            {
                label: i18n.t('vendorQuoteStatus.nonResponsive'),
                value: 4
            }
        ]
        return (
            <div>
                <div>
                    <Tabs
                        className={classes.quotes__statusTabs}
                        value={currentTab}
                        onChange={this.filterVendorQuotes}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >

                        {options.map((option, i) => (
                            <Tab
                                key={i}
                                label={<div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {i === 0 && (<Drafts className={classes.vendor__status__icon__preparing} />)}
                                    {i === 1 && (<Send className={classes.vendor__status__icon__requested} />)}
                                    {i === 2 && (<DoneOutline className={classes.vendor__status__icon__ready} />)}
                                    {i === 3 && (<Block style={{ color: '#ccc' }} className={classes.vendor__status__icon__ready} />)}
                                    {`${counters[i]} ${option.label}`}
                                </div>}
                                style={{ width: 120 }}

                            />
                        ))}
                    </Tabs>
                    <Divider className={classes.vendor__quotes__tabs__divider} />

                    {filterVendorQuotes && filterVendorQuotes.length > 0 && (
                        <div>
                            <Hidden xsDown implementation="css">
                                <ReactTable
                                    data={filterVendorQuotes}
                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                background: (rowInfo ? (highlightRow === rowInfo.original.vendorQuoteId ? '#ffffb3' : null) : null)
                                            }
                                        }
                                    }}
                                    columns={[
                                        {
                                            Header: i18n.t("quotes.vendorQuotesList.table.column1"),
                                            filterable: true,
                                            id: 'vendor',
                                            width: 230,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                            accessor: row => {
                                                return (
                                                    <div className={classes.vendorRFQ__table__fileDescriptions}>
                                                        <div className={classes.list__vendors__cell} style={{ whiteSpace: 'pre' }}>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.vendorRfq__table__vendorLink}
                                                                onClick={() => this.openEditVendorQuote(row)}
                                                            >
                                                                <Typography className={classes.vendorRfq__table__vendorLink} style={{ width: '85%' }} noWrap><strong>{row.vendorName}</strong></Typography>
                                                            </Link>
                                                            <Typography color='textSecondary'>
                                                                {i18n.t("quotes.vendorQuoteRequest.vendorQuote")} {`${row.vendorQuoteId}`}
                                                            </Typography>
                                                            <Typography color='textSecondary' style={{ fontSize: '14px' }}>
                                                                {row.vendorQuoteStatusId === 1 &&
                                                                    `${i18n.t("quotes.vendorQuotesList.created")} ${this.quoteDateFormat(row.vendorQuoteDateTimeCreated)}`
                                                                }
                                                                {row.vendorQuoteStatusId === 2 &&
                                                                    `${i18n.t("quotes.vendorQuotesList.requested")} ${this.quoteDateFormat(row.vendorQuoteDateTimeRequested)}`
                                                                }
                                                                {row.vendorQuoteStatusId === 3 &&
                                                                    `${i18n.t("quotes.vendorQuotesList.ready")} ${this.quoteDateFormat(row.vendorQuoteDateTimeResponse)}`
                                                                }
                                                            </Typography>

                                                            {row.vendorQuoteDateTimeResponse &&
                                                                `${moment(row.vendorQuoteDateTimeResponse).diff(moment(row.vendorQuoteDateTimeRequested, 'days'), 'days')} days`
                                                            }

                                                            {row.vendorQuoteDateTimeRequested &&
                                                                <div className={classes.list__file__lastUpdate__container}>
                                                                    <Typography className={moment().diff(moment(row.vendorQuoteDateTimeRequested), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.vendorQuoteDateTimeRequested), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                        {moment(row.vendorQuoteDateTimeRequested).fromNow(true)}
                                                                    </Typography>
                                                                </div>
                                                            }

                                                            <TasksShortcut
                                                                row={row}
                                                                handleShowQuote={this.props.handleShowQuote}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            },
                                            Filter: ({ onChange }) => (
                                                <TextField
                                                    id='txtUser_Author'
                                                    autoComplete='off'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Search style={{ fontSize: '12px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            ),
                                            filterMethod: (filter, row) => {
                                                return row._original.vendorName.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 ||
                                                    `${row._original.vendorQuoteId}`.indexOf(filter.value) >= 0 ||
                                                    `${row._original.vendorQuoteStatusId === 1 ? this.quoteDateFormat(row._original.vendorQuoteDateTimeCreated)
                                                        : row._original.vendorQuoteStatusId === 2 ? this.quoteDateFormat(row._original.vendorQuoteDateTimeRequested)
                                                            : this.quoteDateFormat(row._original.vendorQuoteDateTimeResponse)}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 ||
                                                    `${options.find(v => v.value === row._original.vendorQuoteStatusId).label}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.vendorQuotesList.table.column2"),
                                            filterable: true,
                                            id: 'client',
                                            accessor: row => {
                                                return (
                                                    <div className={classes.list__fileDetails}>
                                                        <Typography className={classes.list__fileClient} noWrap>{row.clientName}</Typography>
                                                        <Link
                                                            variant="body2"
                                                            className={classes.vendorRfq__table__fileLink}
                                                            onClick={() => this.props.handleShowQuote(row.fileId)}
                                                        >
                                                            {i18n.t("quotes.requestedByClient.table.file")} {`${row.fileId}`}
                                                        </Link>
                                                        <Typography
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {this.quoteDateFormat(row.fileCreateDateTime)}
                                                        </Typography>
                                                        <Typography className={classes.list__fileName}>{`${row.rfqName}`}</Typography>
                                                        <Typography className={classes.list__fileReference}>{`${row.rfqReference}`}</Typography>
                                                    </div>
                                                )
                                            },
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                            Filter: ({ onChange }) => (
                                                <div className={classes.flexCell}>
                                                    <TextField
                                                        id='txtQuoteClientName'
                                                        autoComplete='off'
                                                        style={{ flex: 1 }}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search style={{ fontSize: '12px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            filterMethod: (filter, row) => {
                                                let { value } = filter;
                                                return (`${row._original.clientName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                    `${row._original.rfqName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                    `${row._original.rfqReference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.country"),
                                            filterable: true,
                                            id: 'country',
                                            width: 60,
                                            accessor: row => {
                                                return (
                                                    <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>
                                                        {row.clientCountryCode}
                                                    </Typography>
                                                )
                                            },
                                            Filter: ({ filter, onChange }) => (
                                                <div className={classes.flexCell}>
                                                    <Select
                                                        disabled={!countries || (countries && countries.length <= 0)}
                                                        native
                                                        fullwidth="true"
                                                        onChange={event => onChange(event.target.value)}
                                                        style={{ width: '100%', minWidth: '55px' }}
                                                        classes={{
                                                            select: classes.country__select
                                                        }}
                                                        value={filter ? filter.value : "all"}
                                                    >
                                                        <option value="all">All</option>
                                                        {countries && countries.map(country => (
                                                            <option key={country} value={country}>{country}</option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            ),
                                            filterMethod: (filter, row) => {
                                                let { value } = filter;
                                                return value.toLowerCase() === 'all' ? true : (row._original.clientCountryCode.toLowerCase() === value.toLowerCase())

                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.vendorQuotesList.table.column3"),
                                            filterable: true,
                                            id: 'user_Author',
                                            width: 150,
                                            accessor: row => <Typography className={classes.vendor__quote__salesPerson}>{row.salesPersonName}</Typography>,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                            Filter: ({ onChange }) => (
                                                <TextField
                                                    id='txtUser_Author'
                                                    autoComplete='off'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Search style={{ fontSize: '12px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            ),
                                            filterMethod: (filter, row) => {
                                                return `${row._original.salesPersonName}`.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                                            }
                                        }
                                    ]}
                                    minRows={0}
                                    pageSize={vendorQuotesSize}
                                    onPageSizeChange={(pageSize, page) =>
                                        this.setState({ page, pageSize })
                                    }
                                    className={classNames(classes.vendor__quotes__table, ' -striped -highlight')}
                                    showPaginationTop={filterVendorQuotes && filterVendorQuotes.length >= 50 ? true : false}
                                    showPaginationBottom={filterVendorQuotes && filterVendorQuotes.length >= 50 ? true : false}
                                />
                            </Hidden>
                            <Hidden smUp implementation="css">
                                <Paper>
                                    <List>
                                        {filterVendorQuotes.map((row, i) => (
                                            <div key={`${row.fileId}-${i}`}>
                                                <ListItem key={row.fileId} >
                                                    <ListItemText>
                                                        <div>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.vendorRfq__table__fileLink}
                                                                onClick={() => this.props.handleShowQuote(row.fileId, { vendorQuoteId: row.vendorQuoteId })}
                                                            >
                                                                <strong>{row.vendorName}</strong>
                                                            </Link>
                                                            <Typography color='textSecondary'>
                                                                {i18n.t("quotes.vendorQuotesList.created")} {this.quoteDateFormat(row.vendorQuoteDateTimeCreated)}
                                                                {row.vendorQuoteStatusId >= 2 &&
                                                                    <>
                                                                        <br />
                                                                        {i18n.t("quotes.vendorQuotesList.requested")} {this.quoteDateFormat(row.vendorQuoteDateTimeRequested)}
                                                                    </>
                                                                }
                                                                {row.vendorQuoteStatusId === 3 &&
                                                                    <>
                                                                        <br />
                                                                        {i18n.t("quotes.vendorQuotesList.ready")} {this.quoteDateFormat(row.vendorQuoteDateTimeResponse)}
                                                                    </>
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            {row.vendorItems.map((item, i) => (
                                                                <div key={i} style={{ padding: '0 8px' }}>
                                                                    <div className={classes.vendor__quote__items__itemPartNumber}>
                                                                        {item.rfqItemPartNumber}
                                                                    </div>
                                                                    <div className={classes.vendor__quote__items__descriptionResumed}>
                                                                        {item.vendorQuoteItemDescription}
                                                                    </div>
                                                                    <span className={classes.vendor__quote__items__quantity}>
                                                                        {item.vendorQuoteItemQuantity} {item.rfqItemUnit}
                                                                    </span>
                                                                    {i !== row.vendorItems.length - 1 &&
                                                                        <Divider style={{ margin: '5px 0' }} />
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            <Typography className={classes.list__fileAuthor} >{row.ssalesPersonName}</Typography>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {i < (filterVendorQuotes.length - 1) && (
                                                    <Divider />
                                                )}
                                            </div>
                                        ))}
                                    </List>
                                </Paper>
                            </Hidden>

                            <VendorQuotePopup
                                vendorQuote={vendorQuote}
                                open={showVendorQuote}
                                file={file}
                                vendors={vendors}
                                handleClose={this.handleClose}
                                onExited={this.onExited}
                                //fetchVendors={this.fetchVendors}
                                deleteVendorQuote={this.deleteVendorQuote}
                                updateVendorQuote={this.updateVendorQuote}
                            />
                        </div>
                    )}
                    {filterVendorQuotes && filterVendorQuotes.length === 0 && (

                        <div className={classes.empty__state__container}>
                            <Inbox style={{ fontSize: 50 }} color='secondary' />
                            <Typography variant='body2'>{i18n.t('quotes.vendorQuotesList.noQuotes')}</Typography>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        vendors: state.vendors,
        vendorQuotes: state.vendorQuotes,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuotesList))