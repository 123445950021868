import React, { Component } from 'react'
import { TextField, Paper, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Select from 'react-select'
import i18n from 'i18next'
import { Star } from '@material-ui/icons'

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        // padding: theme.spacing(),
        // fontSize: '0.9em',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: 0,
        fontSize: '0.9em'
    },
    noOptionsMessage: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 5,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    'select--fullwidth': {
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        zIndex: '2',
        fontSize: '0.8em',
        margin: `${theme.spacing()}px 0`,
    },
    select: {
        fontFamily: theme.typography.fontFamily,
        width: '90%',
        zIndex: '2',
        fontSize: '0.8em',
        margin: `${theme.spacing()}px 0`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
})

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {/* {props.children} */}
            {i18n.t('noItemsFound')}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {

    return (
        <TextField
            required={props.selectProps.required}
            fullWidth
            margin="normal"
            variant="outlined"
            className={props.selectProps.classes.select}
            label={props.selectProps.label}
            InputLabelProps={{
                shrink: true,
            }
            }
            error={props.selectProps.error}
            InputProps={{
                inputComponent,
                inputProps: {
                    ...props.selectProps.inputProps,
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Menu(props) {
    return (
        <Paper square
            className={props.selectProps.classes.paper}
            style={props.selectProps.optionsStyle}
            {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
};



class CustomSelect extends Component {
    state = {
        single: (this.props.value && this.props.options) ? this.props.options.find(o => o.value === this.props.value) : null
    }

    handleChange = name => value => {
        this.setState({
            [name]: value,
        });
        this.props.onSelect(value);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.value === null) {
            this.setState({
                single: null,
            });
        } else if (nextProps.value >= 0) {
            let selected = this.props.options.find(o => o.value === nextProps.value);
            this.setState({
                single: selected,
            });
        }
    }

    customFilter(option, searchText) {
        if (option.data.realLabel.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }


    render() {
        const { classes,
            theme,
            required,
            label,
            error,
            placeholder,
            options,
            inputProps,
            optionsStyle,
            fullWidth,
            disabled } = this.props;
        const selectStyles = {
            singleValue: base => ({
                ...base,
                paddingTop: 3,
                fontSize: '0.9em'
            }),
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                padding: 0,
                '& input': {
                    font: 'inherit',
                    fontSize: 'inherit'
                },
            }),
            placeholder: base => ({
                ...base,
                fontSize: '0.9em'
            }),
            option: style => ({
                ...style,
                color: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamily,
            }),
        }

        return (
            <Select
                label={label}
                isDisabled={disabled}
                required={required}
                optionsStyle={optionsStyle}
                classes={{
                    ...classes,
                    select: fullWidth ?
                        classes['select--fullwidth'] :
                        classes.select
                }}
                error={error}
                options={options.map(option => {
                    return {
                        value: option.value,
                        realLabel: option.label,
                        label: option.icon ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Star color={'primary'} style={{ fontSize: '16px', marginRight: '8px' }} />
                                <Typography style={{ fontSize: '12px' }}>{option.label}</Typography>
                            </div>
                            :
                            option.label
                    }
                })}
                inputProps={inputProps}
                components={components}
                value={this.state.single}
                onChange={this.handleChange('single')}
                placeholder={placeholder}
                isClearable
                fullWidth
                styles={{
                    ...selectStyles,
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                filterOption={this.customFilter}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                menuPlacement='auto'
            />
        )
    }
}

CustomSelect.propTypes = {
    error: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(CustomSelect)