import React, { Component } from 'react'
import styled from 'styled-components'
import { EventsCalendar } from './index'
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    withMobileDialog
} from '@material-ui/core/';
import {
    Today
} from '@material-ui/icons';
import i18n from 'i18next'
import API from '../../lib/api'
import { EventDetails } from '../visits'
import { TaskDetailDialog } from '../widgets'
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'


import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css'

const styles = theme => ({
    container: {
        maxWidth: '1100px',
        margin: 'auto'
    },
    dashboard__cardHeader: {
        ...theme.dashboardCard.header,
        fontSize: '25px',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)',
        background: `linear-gradient(60deg, #ffa726, #fb8c00)`
    },
    leftIcon: {
        marginRight: theme.spacing() / 2,
        fontSize: '16px'
    },
    dashboard__cardHeader__action: {
        marginTop: 0
    },
    dashboard__cardHeader__content: {
        flex: 'none'
    },
    'dashboard__cardHeader__button--selected': theme.dashboardCard['actionButton--selected'],
});

const CalendarWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:row;
    @media (max-width: 400px) {
        flex-direction:column;
        padding:10px;
    }
    .users__selector{
        /* max-width:50%; */
        min-width:300px;
        flex:1;
        @media (max-width: 400px) {
            margin-bottom:50px;
        }
    }
    .full__calendar{
        /* max-width:50%; */
        flex:3;
        @media (max-width: 400px) {
            font-size:7pt;
        }
    }
`


class UsersAndCalendar extends Component {
    state = {
        events: [],
        loading: false,
        openTaskDetail: false,
        selectedTask: null,
        openEventDetails: false
    }

    fetchEvents = (args) => {
        const { month, year } = args;

        this.setState({
            loading: true
        })

        this.props.setParams(month, year)

        API.Events.getDashboardEvents({ m: month, y: year })
            .then(res => {
                let newEvents = res.data.records && res.data.records.length > 0
                    ? res
                        .data
                        .records
                        .map(event => {
                            if (event.file) {
                                return {
                                    ...event,
                                    start: new Date(event.taskDateTime),
                                    end: new Date(event.taskDateTime)
                                }
                            } else {
                                let fromDate = new Date(event.from),
                                    newFrom = new Date(fromDate.getTime() + fromDate.getTimezoneOffset() * 60000),
                                    toDate = new Date(event.to),
                                    newTo = new Date(toDate.getTime() + toDate.getTimezoneOffset() * 60000)

                                return {
                                    ...event,
                                    start: newFrom,
                                    end: newTo
                                }
                            }
                        })
                    : [];
                // let stateEvents = [...events];
                // newEvents.forEach(event => {
                //     if (!events.find(ev => ev.id === event.id)) {
                //         stateEvents.push(event);
                //     }
                // });

                this.setState({
                    loading: false,
                    events: newEvents
                })
            });
    }

    closeTaskDetail = () => {
        this.setState({
            selectedTask: null,
            openTaskDetail: false
        })
    }

    openElement = (element) => {
        if (element.file) {
            this.setState({
                selectedTask: element,
                openTaskDetail: true
            })
        } else {
            this.props.eventsActions.setEvent(element)
            this.setState({
                loadEventComponent: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        openEventDetails: true
                    })
                }, 500)
            })
        }
    }

    completeTask = (e) => {
        const { events, selectedTask } = this.state;

        let index = events.findIndex(task => task.id === selectedTask.id),
            newEvents = [
                ...events.slice(0, index),
                {
                    ...events[index],
                    taskComplete: e.target.checked ? 1 : 0
                },
                ...events.slice(index + 1)
            ];

        this.setState({
            events: newEvents
        }, () => {
            let task = newEvents[index];

            API.Notes.markTask({
                fileId: task.file.id,
                noteId: task.id,
                completed: task.taskComplete
            }).then(updated => {
                if (updated.status === 'success') {
                    this.setState({
                        selectedTask: task
                    })
                }
            })
        })
    }

    render() {
        const { events, loading, openTaskDetail,
            selectedTask, openEventDetails, loadEventComponent } = this.state,
            { classes, width } = this.props;

        return (
            <div className={classes.container} style={{ padding: width === 'xs' ? '16px' : '0 50px 50px' }}>
                <Card>
                    <CardHeader
                        disableTypography={true}
                        classes={{
                            root: classes.dashboard__cardHeader,
                            action: classes.dashboard__cardHeader__action,
                            content: classes.dashboard__cardHeader__content
                        }}
                        title={<div style={{ display: 'flex', alignItems: 'center' }}>
                            <Today />
                            <Typography variant='h6' style={{ color: '#fff', marginLeft: '8px' }}>
                                {i18n.t('dashboard.agenda')}
                            </Typography>
                        </div>}
                    />
                    <CardContent style={{ padding: width === 'xs' ? 0 : '32px' }}>
                        <CalendarWrapper>
                            <div className="full__calendar">
                                <EventsCalendar
                                    dashboard={true}
                                    fetchEvents={this.fetchEvents}
                                    events={events}
                                    loading={loading}
                                    openElement={this.openElement}
                                />
                            </div>
                        </CalendarWrapper>
                    </CardContent>
                </Card>

                <TaskDetailDialog
                    selectedTask={selectedTask}
                    onClose={this.closeTaskDetail}
                    open={openTaskDetail}
                    completeTask={this.completeTask}
                />
                {loadEventComponent && (
                    <EventDetails
                        open={openEventDetails}
                        onClose={() => {
                            this.props.eventsActions.clearEvent(null)
                            this.setState({
                                openEventDetails: false
                            })
                        }}
                    />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(UsersAndCalendar)));
