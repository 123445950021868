import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import i18n from 'i18next';

const styles = theme => {
    return (
        {
            quote__totals__container: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
            },
            totals__container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            },
            totals__row: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '200px'
            }
        })
}

class VendorPOTotals extends Component {

    state = {
        items: this.props.items
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.items
        })
    }


    render() {
        const { otherCosts, currencySymbol, classes } = this.props,
            { items } = this.state;

        let otherCostTotal = otherCosts && otherCosts.length > 0 ? otherCosts.filter(oc => !oc.item || !oc.item.id).reduce(((total, item) => parseFloat(total) + parseFloat(item.amount)), 0) : 0,
            itemsQuotedVendorTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + (parseFloat((item.quantity * parseFloat(item.unitAmount || 0))))
                ), 0)
                : 0),
            total = otherCostTotal + itemsQuotedVendorTotal

        return (
            <div>
                {itemsQuotedVendorTotal > 0 && (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.quote__totals__container}>
                            <div className={classes.totals__container}>
                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.otherCosts')} </Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                        <NumberFormat value={otherCostTotal}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </Typography>
                                </div>
                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.quoted')} </Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                        <NumberFormat value={itemsQuotedVendorTotal}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </Typography>
                                </div>
                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'><strong>{i18n.t('quotes.totals.total')}</strong></Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}><strong>
                                        <NumberFormat value={total}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </strong></Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(VendorPOTotals);