import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

import store from '../../store'


const Files = {
    updateFileStatus: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${body.fileId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(body),
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteFile: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${body.fileId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(body),
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getFiles: (args) => {
        let { status, params } = args || {};

        let state = store.getState(),
            peopleFilter = state.people.filterPeople ? state.people.allPeople && state.people.allPeople.length === state.people.filterPeople.length ? [] : state.people.filterPeople.map(user => user.userId) : []

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files${status ? `?status=${status}` : ''}`;

            if (peopleFilter) {
                url += `&users=${peopleFilter.join(',')}`
            }

            if(params){
                url += params.selectedDateTo ? `&selectedDateTo=${params.selectedDateTo}` : ''
                url += params.selectedDateFrom ? `&selectedDateFrom=${params.selectedDateFrom}` : ''
                url += params.selectedCountry ? `&selectedCountry=${params.selectedCountry}` : ''
                url += params.selectedClient ? `&selectedClient=${params.selectedClient}` : ''
                url += params.selectedSalesPerson ? `&selectedSalesPerson=${params.selectedSalesPerson}` : ''
            }


            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getFile: (args) => {
        let { fileId } = args;
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
    },
    searchFiles: (args) => {
        let { fileReference, option } = args || {};
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files?search=true&fileReference=${fileReference}&option=${option}`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getUserFilesCounters: (args) => {
        let { usersIds } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/counters?users=${usersIds.join(',')}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    getFileRecipients: (args) => {
        let { fileId } = args;
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/files/${fileId}/recipients`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
    }
}

export default Files;