import React, { Component } from 'react'
import {
    Button,
    Dialog,
    Grid,
    TextField,
    MenuItem,
    withMobileDialog,
    DialogTitle,
    Typography,
    Divider,
    IconButton,
    withStyles,
    Link,
    Paper,
    Hidden,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    InputAdornment
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    Close
} from '@material-ui/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Delete } from '@material-ui/icons'
import i18n from 'i18next'
import ReactTable from "react-table"

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'

import { PreviousQuotedItems } from '../../itemsLookup'
import { ConfirmationDialog, CustomSelect, CustomMessages } from '../../general'
import { SlideUp } from '../../general/Transitions'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import API from '../../../lib/api';

const defaultItemInputs = {
    partNumber: '',
    description: '',
    references: '',
    manufacturer: '',
    quantity: '',
    unit: {
        unitName: 'EACH',
        unitCode: 'EACH'
    }
}


const styles = theme => ({
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    dialog__paper: {
        width: '760px',
        overflow: 'visible'
    },
    clientRFQ__item__form__container: {
        padding: theme.spacing(2)
    },
    clientRFQ__item__form__input: {
        fontSize: '0.8em',
    },
    clientRFQ__item__form__textArea: {
        fontSize: '0.8em',
        padding: 10
    },
    clientRFQ__item__form__quantity__input: {
        fontSize: '0.9em',
    },
    clientRFQ__item__form__textField: {
        width: '100%',
        margin: `${theme.spacing()}px 0`
    },
    clientRFQ__item__form__textField__quantity: {
        width: '100%',
        margin: `${theme.spacing()}px 0`
    },
    clientRFQ__item__form__textField__unit: {
        width: '100%',
        margin: `${theme.spacing()}px 0`
    },
    clientRFQ__form__menuItem: {
        fontSize: '1em'
    },
    clientRFQ__items__table: {
        display: 'flex',
        flexDirection: 'column'
    },
    clientRFQ__form__items__table: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    customLink: theme.customLink,
    rfq__noItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.orange[80],
        borderRadius: theme.spacing() / 2,
        width: '80px',
        padding: '0 5px',
        marginLeft: theme.spacing(2),
    },
    rfq__warning__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    createitem__container: {
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
    },
    createitem__container_RO: {
        padding: theme.spacing(),
        paddingRight: theme.spacing(2),
    },
    edititems__link: {
        marginRight: theme.spacing(2),
        marginBottom: -theme.spacing(),
    },
    clientRFQ__addItemButton: {

    },
    clientRFQ__deleteItemButton: {
        minWidth: '60px',
        width: '60px',
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        margin: '0 0 5px 5px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    add__item__button: {
        backgroundColor: 'white',
        color: theme.palette.blue[100],
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
        }
    },
    customTranslateLink: {
        ...theme.customLink,
        textTransform: 'capitalize',
        position: 'absolute',
        zIndex: '100',
        fontSize: '11px',
        top: '8px',
        right: '5px'
    },
    buttonSpinner: theme.buttonSpinner,
    buttonSpinnerTranslate: {
        ...theme.buttonSpinner,
        zIndex: 10,
        marginTop: '6px',
        right: '16px',
        left: 'auto'
    },
    translate__closeWindow: {
        position: 'absolute',
        top: '5px',
        padding: '6px',
        right: theme.spacing(),
    },
})



const QuoteItemsStyles = theme => {
    return ({
        quoteitems__paper: {
            margin: theme.spacing(2)
        },
        quoteitems__table: {
            fontSize: theme.typography.fontSize * 0.8,
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
            border: `solid thin ${theme.palette.grey[300]}`
        },
        quoteitems__table__row: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        quoteitems__table__itemPartNumber: {
            color: theme.palette.naval.main,
        },
        quoteitems__table__descriptionExtended: {
            width: '100%',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between'
        },
        customLink: theme.customLink,
        extendeDescription__icon: {
            fontSize: '20px',
            color: theme.palette.grey[500]
        }
    })
};


class QuoteItemsComponent extends Component {
    state = {
        selectedItem: null,
        items: this.props.items.filter(it => !it.deleted)
    }

    componentWillReceiveProps=(nextProps)=>{
        this.setState({
            items: nextProps.items.filter(it => !it.deleted)
        })
    }

    selectItem = (e, index) => {
        e.stopPropagation()
        const { selectedItem } = this.state;
        this.setState({
            selectedItem: index === selectedItem ? null : index
        })
    }
    render = () => {
        const { classes } = this.props,
            { selectedItem, items } = this.state;

        return (
            <Paper className={classes.quoteitems__paper}>

                <Hidden xsDown implementation="css">
                    <ReactTable
                        data={items}
                        columns={[
                            {
                                Header: i18n.t("quotes.clientRFQ.table.column1"),
                                id: 'rowIndex',
                                Cell: row => (<div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>{row.index + 1}</div>),
                                width: 40
                            },
                            {
                                Header: i18n.t("quotes.clientRFQ.table.column2"),
                                id: 'description',
                                style: {
                                    whiteSpace: 'pre-line'
                                },
                                Cell: (row) => {
                                    return (
                                        <div>
                                            <Typography className={classes.quoteitems__table__itemPartNumber} >{row.original.partNumber}</Typography>
                                            <div
                                                className={classes.quoteitems__table__descriptionExtended}
                                                style={{ alignItems: selectedItem === row.index ? 'flex-start' : 'center' }}
                                                onClick={(e) => this.selectItem(e, row.index)}>
                                                <Typography color='textSecondary'
                                                    style={{ whiteSpace: selectedItem === row.index ? 'pre-line' : 'nowrap' }}
                                                    noWrap>
                                                    {row.original.description}
                                                </Typography>
                                                {row.original.description.length > 50 && <>
                                                    {selectedItem === row.index ?
                                                        <KeyboardArrowUp className={classes.extendeDescription__icon} />
                                                        :
                                                        <KeyboardArrowDown className={classes.extendeDescription__icon} />
                                                    }
                                                </>}
                                            </div>
                                            <Typography color='textSecondary'>
                                                {row.original.manufacturer}
                                            </Typography>

                                            {row.original.references && (
                                                <Typography color='textSecondary'>
                                                    {row.original.references}
                                                </Typography>
                                            )}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: i18n.t("quotes.clientRFQ.table.column3"),
                                id: 'quantity',
                                width: 100,
                                accessor: (row) => (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        {row.quantity} {typeof row.unit === 'string' ? row.unit : row.unit.unitName}
                                    </div>
                                ),
                            }, {
                                Header: '',
                                width: 40,
                                Cell: row => (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <Link className={classes.customLink}
                                            variant='button'
                                            onClick={() => this.props.editItem && this.props.editItem(row.original)}>
                                            {i18n.t('quotes.clientRFQ.editItem')}
                                        </Link>
                                    </div>
                                ),
                                show: this.props.showEditItemsButton ? true : false
                            }
                        ]}
                        resizable={false}
                        sortable={false}
                        defaultPageSize={items.length}
                        pageSize={items.length}
                        className={classNames(classes.quoteitems__table, ' -striped -highlight')}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        filtrable={false}
                    />
                </Hidden>

                <Hidden smUp implementation="css">
                    <List>
                        {items.map((row, i) => (
                            <ListItem key={i}>
                                <ListItemText>
                                    <Typography variant='body2'>
                                        <span className={classes.quoteitems__table__itemPartNumber} >{row.partNumber}</span>
                                        <br />
                                        {row.description}
                                        <br />
                                        {row.references}
                                        <span>
                                            {row.manufacturer}
                                        </span>
                                        {row.quantity} {typeof row.unit === 'string' ? row.unit : row.unit.unitName}
                                    </Typography>
                                    {this.props.showEditItemsButton && (
                                        <Link className={classes.customLink}
                                            variant='button'
                                            onClick={() => this.props.editItem && this.props.editItem(row.original)}>
                                            {i18n.t('quotes.clientRFQ.editItem')}
                                        </Link>
                                    )}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Hidden>
            </Paper>)
    }
}

const QuoteItems = withStyles(QuoteItemsStyles)(QuoteItemsComponent);


class RFQItems extends Component {
    state = {
        showItemForm: false,
        editItemMode: false,
        itemInputs: defaultItemInputs,
        validateItem: false,
        saving: false,
        items: this.props.items ? [...this.props.items].filter(it=> !it.deleted) : [],
        readOnlyItems: [...this.props.items] || [],
        showRequiredFieldsError: false,
        unsavedChanges: false,
        deleteItemIdx: null,
        openConfirmationDialog: false,
        validateItems: this.props.validateItem,
        apiErrorMessage: null,
        fetching: false,
        lastPartNumber: null,
        itemData: null,
        typing: false,
        showError: false,
        dontDelete: false,
        translatedDescription: null,
        openTranslateWindow: false,
        translading: false
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.items) {
            this.setState({
                items: nextProps.items ? [...nextProps.items].filter(it=> !it.deleted) : [],
                readOnlyItems: [...nextProps.items]
            })
        }
        this.setState({
            validateItems: nextProps.validateItems
        })
    }

    openCreateItem = () => {
        this.setState({
            showItemForm: true,
            editItemMode: false,
            lastPartNumber: null,
            itemData: null
        })
    }

    openEditItem = (item) => {
        this.fetchQuotedItem({
            partNumber: item.partNumber
        })
        this.setState({
            showItemForm: true,
            editItemMode: true,
            lastPartNumber: item.partNumber || null,
            itemInputs: {
                id: item.id || null,
                partNumber: item.partNumber || '',
                description: item.description || '',
                references: item.references || '',
                manufacturer: item.manufacturer || '',
                quantity: item.quantity || '',
                unit: item.unit || {
                    unitName: 'EACH',
                    unitCode: 'EACH'
                }
            }
        })
    }

    closeCreateItem = () => {
        this.setState({
            showItemForm: false,
            editItemMode: false,
            lastPartNumber: null,
            partNumber: null,
            unsavedChanges: false,
            validateItem: false,
            showRequiredFieldsError: false,
            itemInputs: defaultItemInputs,
            itemData: null
        })
        this.props.generalActions && this.props.generalActions.showAddItem(false);
    }
    closeEditItems = () => {
        this.setState({
            unsavedChanges: false,
            validateItem: false,
            lastPartNumber: null,
            partNumber: null,
            showRequiredFieldsError: false,
            itemInputs: defaultItemInputs,
            itemData: null
        })
    }
    handleAcceptConfirmation = () => {

        this.setState({
            showItemForm: false,
            editItemMode: false,
            unsavedChanges: false,
            validateItem: false,
            items: [...this.state.readOnlyItems],
            showRequiredFieldsError: false,
            itemInputs: defaultItemInputs,
        })
        this.props.generalActions && this.props.generalActions.showAddItem(false);
    }

    fetchingItem = (error) => {
        if (error) {
            this.setState({
                showError: true
            })
        } else {
            this.setState({
                showError: false,
                fetching: true
            })
        }
    }

    renderItemsFields = (cellInfo) => {
        const { classes } = this.props,
            { validateItems, items, fetching, saving, 
            translading, typing, showError } = this.state;

        return (
            <GlobalParametersContext.Consumer>
                {({ units }) => (
                    <>
                        <Grid container spacing={1}>
                            <Grid className={classes.clientRFQ__items__table} item sm={6} xs={12}>
                                <TextField autoComplete='off' required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.clientRFQ__item__form__input,
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <PreviousQuotedItems
                                                    index={cellInfo.index}
                                                    partNumber={items[cellInfo.index].partNumber}
                                                    fetchingItem={this.fetchingItem}
                                                    fetched={() => {
                                                        this.setState({
                                                            typing: false,
                                                            fetching: false
                                                        })
                                                    }}
                                                    setItem={(itemData, index) => {
                                                        if (itemData) {
                                                            if (index >= 0) {
                                                                let items = [...this.state.items];
                                                                items[index].itemData = itemData;
                                                                items[index].partNumber = itemData.record.item.partNumber || '';
                                                                items[index].description = itemData.record.item.description || '';
                                                                items[index].manufacturer = itemData.record.item.manufacturer || '';
                                                                this.setState({
                                                                    items,
                                                                });
                                                            }
                                                        } else {
                                                            if (index >= 0) {
                                                                let items = [...this.state.items];
                                                                items[index].itemData = null;
                                                                this.setState({
                                                                    items,
                                                                });
                                                            }
                                                        }

                                                    }}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                    onBlur={e => {
                                        const items = [...this.state.items];
                                        if (items[cellInfo.index].partNumber !== e.target.value.trim()) {
                                            items[cellInfo.index].partNumber = e.target.value.trim();
                                            this.setState({
                                                items,
                                                unsavedChanges: true
                                            });
                                            this.props.updateItems(items);
                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                        }
                                    }}
                                    onChange={() => {
                                        this.setState({
                                            typing: true
                                        })
                                        this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                    }}
                                    disabled={fetching || saving ? true : false}
                                    style={{ width: '100%' }}
                                    className={classes.clientRFQ__item__form__textField}
                                    // id="quoteItem"
                                    type="text"
                                    variant="outlined"
                                    error={validateItems && !items[cellInfo.index].partNumber ? true : false}
                                    defaultValue={this.state.items[cellInfo.index].partNumber}
                                    label={i18n.t('quotes.quoteRequest.quoteItem')}
                                />
                                {showError && (
                                    <Grid item xs={10}>
                                        <Typography variant='body2' color='textSecondary'>{i18n.t('vendors.minimumChars')}</Typography>
                                    </Grid>
                                )}

                                {items[cellInfo.index].description !== '' &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                                        <Link className={classes.customTranslateLink}
                                            variant='button'
                                            onClick={() => this.openTranslate(items[cellInfo.index].description)}>
                                            {i18n.t('quotes.clientRFQ.translate')}
                                        </Link>
                                        {translading && <CircularProgress color='secondary' className={classes.buttonSpinnerTranslate} size={24}/>}
                                    </div>
                                }
                                <TextField autoComplete='off' required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.clientRFQ__item__form__textField}
                                    InputProps={{
                                        className: classes.clientRFQ__item__form__input
                                    }}
                                    variant="outlined"
                                    placeholder=" "
                                    onChange={e => {
                                        let items = [...this.state.items];
                                        if (items[cellInfo.index].description !== e.target.value.trim()) {
                                            items[cellInfo.index].description = e.target.value.trim();
                                            this.setState({ items });
                                        }
                                        this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                    }}
                                    onBlur={e => {
                                        let items = [...this.state.items];
                                        if (items[cellInfo.index].description !== e.target.value.trim()) {
                                            items[cellInfo.index].description = e.target.value.trim();
                                            this.setState({ items, unsavedChanges: true });
                                            this.props.updateItems(items);
                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                        }
                                    }}
                                    disabled={fetching || saving || typing ? true : false}
                                    error={validateItems && !items[cellInfo.index].description ? true : false}
                                    style={{ width: '100%' }}
                                    multiline
                                    rows="6"
                                    type="text"
                                    id="quoteItemDescription"
                                    defaultValue={this.state.items[cellInfo.index].description}
                                    label={i18n.t('quotes.quoteRequest.quoteItemDescription')}
                                />
                            </Grid>
                            <Grid className={classes.clientRFQ__items__table} item sm={4} xs={12}>
                                <TextField autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.clientRFQ__item__form__input
                                    }}
                                    onBlur={e => {
                                        let items = [...this.state.items];
                                        if (items[cellInfo.index].manufacturer !== e.target.value.trim()) {
                                            items[cellInfo.index].manufacturer = e.target.value.trim();
                                            this.setState({ items, unsavedChanges: true });
                                            this.props.updateItems(items);
                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                        }
                                    }}
                                    onChange={e => {
                                        let items = [...this.state.items];
                                        if (items[cellInfo.index].manufacturer !== e.target.value.trim()) {
                                            items[cellInfo.index].manufacturer = e.target.value.trim();
                                            this.setState({ items });
                                        }
                                        this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                    }}
                                    disabled={fetching || saving || typing ? true : false}
                                    style={{ width: '100%' }}
                                    className={classes.clientRFQ__item__form__textField}
                                    // id="quoteItemManufacturer"
                                    type="text"
                                    variant="outlined"
                                    placeholder=" "
                                    defaultValue={this.state.items[cellInfo.index].manufacturer}
                                    label={i18n.t('quotes.quoteRequest.quoteItemManufacturer')}
                                />

                                <TextField autoComplete='off'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.clientRFQ__item__form__textField}
                                    InputProps={{
                                        className: classes.clientRFQ__item__form__input
                                    }}

                                    onBlur={e => {
                                        const items = [...this.state.items];
                                        if (items[cellInfo.index].references !== e.target.value.trim()) {
                                            items[cellInfo.index].references = e.target.value.trim();
                                            this.setState({ items, unsavedChanges: true });
                                            this.props.updateItems(items);
                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                        }
                                    }}
                                    disabled={fetching || saving || typing ? true : false}
                                    style={{ width: '100%' }}
                                    multiline
                                    rows="6"
                                    type="text"
                                    id="quoteItemComment"
                                    variant="outlined"
                                    placeholder=" "
                                    defaultValue={this.state.items[cellInfo.index].references}
                                    label={i18n.t('quotes.quoteRequest.quoteItemComment')}
                                />
                            </Grid>
                            <Grid className={classes.clientRFQ__items__table} item sm={2} xs={6}>
                                <TextField autoComplete='off'
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={validateItems && !items[cellInfo.index].quantity ? true : false}
                                    defaultValue={this.state.items[cellInfo.index].quantity}
                                    InputProps={{
                                        className: classes.clientRFQ__item__form__quantity__input,
                                        inputProps: { min: 0 }
                                    }}
                                    onBlur={e => {
                                        const items = [...this.state.items];
                                        if (items[cellInfo.index].quantity !== e.target.value) {
                                            items[cellInfo.index].quantity = e.target.value;
                                            this.setState({ items, unsavedChanges: true });
                                            this.props.updateItems(items);
                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                        }
                                    }}
                                    disabled={fetching || saving || typing ? true : false}
                                    className={classes.clientRFQ__item__form__textField}
                                    id="quoteItemQuantity"
                                    type="number"
                                    min={1}
                                    variant="outlined"
                                    placeholder=" "
                                    label={i18n.t('quotes.quoteRequest.quoteItemQuantity')}
                                />


                                <TextField autoComplete='off'
                                    required
                                    id="quoteItemUnit"
                                    select
                                    inputProps={{
                                        className: classes.clientRFQ__item__form__input
                                    }}
                                    disabled={fetching || saving || typing ? true : false}
                                    label={i18n.t('quotes.quoteRequest.quoteItemUnit')}
                                    error={validateItems && (!items[cellInfo.index].unit || (items[cellInfo.index].unit && items[cellInfo.index].unit.unitCode === 'None')) ? true : false}
                                    className={classes.clientRFQ__item__form__textField__unit}
                                    value={typeof items[cellInfo.index].unit === 'string' ? items[cellInfo.index].unit : items[cellInfo.index].unit.unitCode}
                                    onChange={e => {
                                        const items = [...this.state.items],
                                            newUnit = units.find(u => u.unitCode === e.target.value);
                                        if (newUnit) {
                                            items[cellInfo.index].unit = {
                                                unitCode: newUnit.unitCode,
                                                unitName: newUnit.unitName
                                            }
                                        } else {
                                            items[cellInfo.index].unit = {
                                                unitCode: 'None',
                                                unitName: ''
                                            };
                                        }

                                        this.setState({
                                            items, unsavedChanges: true
                                        });
                                        this.props.updateItems(items);
                                        this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem value={null} className={classes.clientRFQ__form__menuItem}>
                                        {i18n.t('quotes.quoteRequest.quoteSelectNone')}
                                    </MenuItem>
                                    {units ? units.map(option => (
                                        <MenuItem key={option.unitName} value={option.unitName} className={classes.clientRFQ__form__menuItem}>
                                            {option.unitName}
                                        </MenuItem>
                                    )) : []}
                                </TextField>
                            </Grid>
                        </Grid>
                    </>
                )}
            </GlobalParametersContext.Consumer>
        )
    }

    confirmDeleteItem = (idx, dontDelete) => {
        this.setState({
            dontDelete,
            openConfirmationDialog: true,
            deleteItemIdx: idx
        })
    }

    cancelConfirmationDeleteItem = () => {
        this.setState({
            openConfirmationDialog: false,
            deleteItemIdx: null
        })
    }

    deleteItem = () => {
        const { deleteItemIdx, items } = this.state;
        const newItems = [...items];
        // newItems.splice(deleteItemIdx, 1)
        newItems[deleteItemIdx].deleted = true
        this.setState({
            items: newItems,
            openConfirmationDialog: false,
            unsavedChanges: true,
            deleting: true
        }, () => {
            if (!this.props.saveFileOnChange) {
                this.props.updateItems && this.props.updateItems(newItems)
                this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
            } else {
                this.props.updateItems && this.props.updateItems(newItems)
                this.saveRFQ();
            }
        })

    }
    fetchQuotedItem = ({ index, partNumber }) => {
        const { lastPartNumber } = this.state;
        if (partNumber && partNumber !== lastPartNumber) {
            this.setState({
                fetching: true,
                lastPartNumber: partNumber
            }, () => {
                API.Items.getQuotedItem({ partNumber })
                    .then(res => {
                        if (res.status === 'success') {
                            let itemData = res.data;
                            if (itemData.record && itemData.record.item) {
                                if (index >= 0) {
                                    let items = [...this.state.items];
                                    items[index].itemData = itemData;
                                    items[index].partNumber = itemData.record.item.partNumber || '';
                                    items[index].description = itemData.record.item.description || '';
                                    items[index].manufacturer = itemData.record.item.manufacturer || '';
                                    this.setState({
                                        items,
                                    });
                                } else {
                                    this.setState({
                                        itemData,
                                        itemInputs: {
                                            ...this.state.itemInputs,
                                            partNumber: itemData.record.item.partNumber || '',
                                            description: itemData.record.item.description || '',
                                            manufacturer: itemData.record.item.manufacturer || '',
                                        }
                                    })
                                }
                            } else {
                                if (index >= 0) {
                                    let items = [...this.state.items];
                                    items[index].itemData = null;
                                    items[index].description = '';
                                    items[index].manufacturer = '';
                                    this.setState({
                                        items,
                                    });
                                } else {
                                    this.setState({
                                        itemData: null,
                                        itemInputs: {
                                            ...this.state.itemInputs,
                                            description: '',
                                            manufacturer: '',
                                        }
                                    })
                                }
                            }
                        } else {
                            this.setState({
                                itemData: null,
                            })
                        }
                        this.setState({
                            typing: false,
                            fetching: false
                        })
                    })
            })
        }
    }

    handleItems = () => {
        const { items, itemInputs } = this.state
        let newItems = [...items];

        this.setState({
            validateItem: true,
        })
        if (itemInputs.partNumber === '' || itemInputs.description === '' || itemInputs.quantity === '' || itemInputs.unit === '' || !itemInputs.unit) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        if (itemInputs.id) {
            let indx = newItems.findIndex(it => it.id === itemInputs.id);
            newItems = [
                ...newItems.slice(0, indx),
                {
                    itemData: this.state.itemData || null,
                    ...itemInputs
                },
                ...newItems.slice(indx + 1)
            ]
        } else {
            itemInputs.itemData = this.state.itemData || null;
            newItems.push(itemInputs)
        }


        this.setState({
            // showItemForm: false,
            // editItemMode: false,
            validateItem: false,
            items: newItems,
        }, () => {
            if (this.props.file && this.props.saveFileOnChange) {
                this.props.updateItems && this.props.updateItems(newItems)
                this.saveRFQ();
            } else {
                this.setState({
                    showItemForm: false,
                    itemInputs: defaultItemInputs,
                    editItemMode: false,
                    showRequiredFieldsError: false
                })
                this.props.generalActions && this.props.generalActions.showAddItem(false);
                this.props.updateItems && this.props.updateItems(newItems)
                this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
            }

        })

    }

    saveRFQ = () => {
        const { file } = this.props,
            { items } = this.state;

        this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(false);
        this.setState({
            saving: true
        })

        let body = {
            id: file.requestForQuote.id,
            clientId: file.client.id,
            clientContactId: file.client.contact.id,
            salesRepUserId: file.salesRepUserId,
            probabilityId: file.probability.id,
            oportunityId: file.oportunity.id,
            clientRFQ: {
                dateTime: file.requestForQuote.createDateTime,
                name: file.requestForQuote.name,
                reference: file.requestForQuote.reference,
                description: file.requestForQuote.description,
                quoteTermsReference: file.requestForQuote.quoteTermsReference,
                currency: file.requestForQuote.currency,
                paymentTermsId: file.paymentTerms.id,
                quoteTermsId: file.quoteTerms.id,
                fileId: file.id
            },
            clientRFQItems: items.map((item, i) => (
                {
                    rfqItemId: item.id || null,
                    partNumber: item.partNumber,
                    references: item.references,
                    description: item.description,
                    manufacturer: item.manufacturer,
                    quantity: item.quantity,
                    deleted: item.deleted,
                    unit: typeof item.unit === 'string' ? item.unit : item.unit.unitCode
                }))
        }

        API.Quotes.updateRFQ({ body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    validateItem: false,
                    itemInputs: defaultItemInputs,
                    showItemForm: false,
                    editItemMode: false,
                    showRequiredFieldsError: false,
                    unsavedChanges: false,
                    deleting: false,
                    items: res.data.records.file.items.map(item => {
                        return {
                            ...item,
                            unit: {
                                unitName: item.unit,
                                unitCode: item.unit
                            }
                        }
                    })
                }, _ => {

                    this.props.generalActions && this.props.generalActions.showAddItem(false);
                    this.props.updateFile && this.props.updateFile(res.data.records.file, true)
                    this.props.updateItems && this.props.updateItems(res.data.records.file.items.map(item => {
                        return {
                            ...item,
                            unit: {
                                unitName: item.unit,
                                unitCode: item.unit
                            }
                        }
                    }))
                })
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    renderEditTable = () => {
        const { items, saving } = this.state,
            { classes, file } = this.props;

        return (
            <ReactTable
                data={items}
                columns={[
                    {
                        Header: "",
                        accessor: "",
                        style: {
                            textAlign: 'center',
                        },
                        width: 30,
                        filterable: false,
                        Cell: row => (row.index + 1)
                    },
                    {
                        Header: i18n.t('quotes.quoteRequest.quoteItemitemsInfo'),
                        id: 'items_info',
                        accessor: "",
                        Cell: this.renderItemsFields
                    },
                    {
                        Header: '',
                        id: 'deleteItem',
                        accessor: '',
                        style: {
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        },
                        width: 45,
                        filterable: false,
                        Cell: row => {
                            let vendorQuotesItems = [];

                            if (file && file.vendorQuotes && file.vendorQuotes.length > 0) {
                                file.vendorQuotes.forEach(vq => {
                                    if (vq.items.length > 0) {
                                        vendorQuotesItems = vendorQuotesItems.concat(vq.items)
                                    }
                                })
                            }

                            let dontDelete = vendorQuotesItems.length === 0 ? false :
                                vendorQuotesItems.find(vqI => vqI.rfqItemId === row.original.id) ? true : false

                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        onClick={() => this.confirmDeleteItem(row.index, dontDelete)}
                                        color="inherit"
                                        disabled={saving ? true : false}
                                        aria-label="Profile"
                                    >
                                        <Delete color={saving ? 'inherit' : 'error'} style={{ fontSize: '0.7em' }} />
                                    </IconButton>
                                </div>
                            )
                        }
                    }
                ]}
                sortable={false}
                defaultPageSize={items.length > 0 ? items.length : 2}
                pageSize={items.length}
                onPageSizeChange={(pageSize, page) =>
                    this.setState({ page, pageSize })
                }
                className={`${classes.clientRFQ__form__items__table} -striped -highlight`}
                showPaginationTop={false}
                showPaginationBottom={false}
            />
        )
    }

    openTranslate = (text) => {
        this.setState({
            translading: true
        })

        API.Translate.translateText({ text })
            .then(res => {
                if(res.data){
                    this.setState({
                        translading: false,
                        openTranslateWindow: true,
                        translatedDescription: res.data
                    })
                } else {
                    this.setState({
                        translading: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        
    }

    render() {
        const {
            //fullScreen,
            classes,
            showEditItemsButton,
            showItemsROList,
            showCreateItemButton,
            general
        } = this.props,
            {
                itemInputs,
                deleting,
                validateItem,
                showItemForm,
                saving,
                items,
                readOnlyItems,
                editItemMode,
                apiErrorMessage,
                fetching,
                typing,
                showError,
                dontDelete,
                openTranslateWindow,
                translatedDescription,
                translading
            } = this.state;

        let { showAddItem } = general;

        return (
            <div>
                <GlobalParametersContext.Consumer>
                    {({ units }) => (
                        <>

                            <CustomMessages
                                type={'error'}
                                message={apiErrorMessage}
                                clearMessage={() => this.setState({
                                    apiErrorMessage: null
                                })}
                            />

                            {showItemsROList &&
                                <>
                                    {readOnlyItems && readOnlyItems.length > 0 ? (
                                        <QuoteItems
                                            showEditItemsButton={showEditItemsButton}
                                            editItem={(item) => {
                                                this.openEditItem(item)
                                            }}
                                            items={readOnlyItems}
                                        />
                                    ) :
                                        (
                                            <div className={classes.rfq__noItems}>
                                                <Typography>
                                                    {i18n.t('quotes.requestedByClient.noClientQuotesItems')}
                                                </Typography>
                                            </div>
                                        )}
                                </>
                            }

                            {!showItemsROList && items && items.length > 0 && (
                                <Grid container justify='flex-end'>
                                    <Grid item xs={12}>
                                        {this.renderEditTable()}
                                    </Grid>
                                </Grid>
                            )}

                            {showCreateItemButton && (
                                <Grid container justify='flex-end' className={!showItemsROList ? classes.createitem__container : classes.createitem__container_RO}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        className={classes.add__item__button}
                                        onClick={this.openCreateItem}>
                                        {i18n.t('quotes.quoteRequest.addRFQItem')}
                                    </Button>
                                </Grid>
                            )}




                            {/* CREATE NEW DIALOG AND EDIT ITEM*/}
                            <Dialog
                                open={showItemForm || showAddItem ? true : false}
                                maxWidth='md'
                                TransitionComponent={SlideUp}
                                classes={
                                    {
                                        paper: classes.dialog__paper
                                    }
                                }>

                                <DialogTitle disableTypography={true}>
                                    {editItemMode ?
                                        <Typography variant='body1' color="inherit">{i18n.t('quotes.quoteRequest.editRFQItem')}</Typography>
                                        :
                                        <Typography variant='body1' color="inherit">{i18n.t('quotes.quoteRequest.createItemTitle')}</Typography>
                                    }
                                </DialogTitle>




                                <div className={classes.clientRFQ__item__form__container}>

                                    <Grid container spacing={2} alignItems='flex-start' justify='flex-start'>
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                autoComplete='off'
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.clientRFQ__item__form__input,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <PreviousQuotedItems
                                                                partNumber={this.state.itemInputs.partNumber}
                                                                fetchingItem={this.fetchingItem}
                                                                fetched={() => {
                                                                    this.setState({
                                                                        typing: false,
                                                                        fetching: false
                                                                    })
                                                                }}
                                                                setItem={(itemData) => {
                                                                    if (itemData) {
                                                                        this.setState({
                                                                            itemData,
                                                                            itemInputs: {
                                                                                ...this.state.itemInputs,
                                                                                partNumber: itemData.record.item.partNumber || '',
                                                                                description: itemData.record.item.description || '',
                                                                                manufacturer: itemData.record.item.manufacturer || '',
                                                                            }
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            itemData: null,
                                                                        })
                                                                    }

                                                                }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => {
                                                    this.setState({
                                                        typing: true,
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            partNumber: e.target.value
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                                }}
                                                onBlur={e => {
                                                    this.setState({
                                                        unsavedChanges: itemInputs.partNumber !== e.target.value ? true : this.state.unsavedChanges,
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            partNumber: e.target.value.trim()
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                                }}
                                                fullWidth
                                                error={itemInputs.partNumber === '' && validateItem}
                                                disabled={fetching || saving ? true : false}
                                                value={itemInputs.partNumber}
                                                className={classes.clientRFQ__item__form__textField}
                                                id="partNumber"
                                                type="text"
                                                variant="outlined"
                                                label={i18n.t('quotes.quoteRequest.quoteItem')}
                                            />
                                            {showError && (
                                                <Grid item xs={10}>
                                                    <Typography variant='body2' color='textSecondary'>{i18n.t('vendors.minimumChars')}</Typography>
                                                </Grid>
                                            )}
                                            <TextField autoComplete='off'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className: classes.clientRFQ__item__form__input
                                                }}
                                                onChange={e => {
                                                    this.setState({
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            manufacturer: e.target.value
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                                }}
                                                onBlur={e => {
                                                    this.setState({
                                                        unsavedChanges: itemInputs.manufacturer !== e.target.value ? true : this.state.unsavedChanges,
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            manufacturer: e.target.value.trim()
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                                }}
                                                disabled={fetching || saving || typing ? true : false}
                                                value={itemInputs.manufacturer}
                                                className={classes.clientRFQ__item__form__textField}
                                                id="rfqItemManufacturer"
                                                type="text"
                                                variant="outlined"
                                                label={i18n.t('quotes.quoteRequest.quoteItemManufacturer')}
                                            />

                                            {itemInputs.description !== '' &&
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                                                    <Link className={classes.customTranslateLink}
                                                        variant='button'
                                                        disabled={translading}
                                                        onClick={() => this.openTranslate(itemInputs.description)}>
                                                        {i18n.t('quotes.clientRFQ.translate')}
                                                    </Link>
                                                    {translading && <CircularProgress color='secondary' className={classes.buttonSpinnerTranslate} size={24}/>}
                                                </div>
                                            }
                                            <TextField autoComplete='off' required
                                                className={classes.clientRFQ__item__form__textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.clientRFQ__item__form__input
                                                }}
                                                error={itemInputs.description === '' && validateItem}
                                                onChange={e => {
                                                    this.setState({
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            description: e.target.value
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true)
                                                }}
                                                onBlur={e => {
                                                    this.setState({
                                                        unsavedChanges: itemInputs.description !== e.target.value ? true : this.state.unsavedChanges,
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            description: e.target.value.trim()
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                                }}
                                                disabled={fetching || saving || typing ? true : false}
                                                value={itemInputs.description}
                                                multiline
                                                rows="8"
                                                type="text"
                                                id="description"
                                                variant="outlined"
                                                label={i18n.t('quotes.quoteRequest.quoteItemDescription')}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField autoComplete='off'
                                                className={classes.clientRFQ__item__form__textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.clientRFQ__item__form__textArea
                                                }}

                                                onBlur={e => {
                                                    this.setState({
                                                        unsavedChanges: itemInputs.references !== e.target.value ? true : this.state.unsavedChanges,
                                                        itemInputs: {
                                                            ...itemInputs,
                                                            references: e.target.value.trim()
                                                        }
                                                    })
                                                    this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                                }}
                                                disabled={fetching || saving || typing ? true : false}
                                                defaultValue={itemInputs.references}
                                                multiline
                                                rows="5"
                                                type="text"
                                                id="quoteItemComment"
                                                variant="outlined"
                                                label={i18n.t('quotes.quoteRequest.quoteItemComment')}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid item sm={4} xs={12}>
                                                    <TextField autoComplete='off' required
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            className: classes.clientRFQ__item__form__quantity__input,
                                                            inputProps: { min: 0 }
                                                        }}
                                                        onBlur={e => {
                                                            this.setState({
                                                                unsavedChanges: itemInputs.quantity !== e.target.value ? true : this.state.unsavedChanges,
                                                                itemInputs: {
                                                                    ...itemInputs,
                                                                    quantity: e.target.value.trim()
                                                                }
                                                            })
                                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);

                                                        }}
                                                        error={itemInputs.quantity === '' && validateItem}
                                                        disabled={fetching || saving || typing ? true : false}
                                                        defaultValue={itemInputs.quantity}
                                                        className={classes.clientRFQ__item__form__textField__quantity}
                                                        id="quoteItemQuantity"
                                                        variant="outlined"
                                                        label={i18n.t('quotes.quoteRequest.quoteItemQuantity')}
                                                    />


                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <CustomSelect
                                                        label={i18n.t('quotes.quoteRequest.quoteItemUnit')}
                                                        error={!itemInputs.unit && validateItem}
                                                        value={itemInputs.unit ? (typeof itemInputs.unit === 'string' ? itemInputs.unit : itemInputs.unit.unitCode) : 'EACH'}
                                                        required={true}
                                                        options={units ? units.map(unit => ({
                                                            value: unit.unitCode,
                                                            label: unit.unitName
                                                        })) : []}
                                                        disabled={fetching || saving || typing ? true : false}
                                                        onSelect={unit => {
                                                            this.setState({
                                                                unsavedChanges: true,
                                                                itemInputs: {
                                                                    ...itemInputs,
                                                                    unit: unit ? units.find(u => u.unitCode === unit.value) : null
                                                                }
                                                            })
                                                            this.props.handleUnsaveChanges && this.props.handleUnsaveChanges(true);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {this.state.showRequiredFieldsError && (
                                            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                            </Grid>
                                        )}
                                        {editItemMode && (
                                            <Grid item xs={12}>
                                                <Button
                                                    disabled={saving || deleting ? true : false}
                                                    onClick={() => {
                                                        let indx = items.findIndex(it => it.id === this.state.itemInputs.id);
                                                        this.confirmDeleteItem(indx);
                                                    }}
                                                    className={classes.clientRFQ__deleteItemButton}
                                                >
                                                    {i18n.t("quotes.quoteRequest.deleteItem")}
                                                    {deleting && !saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={0} style={{ marginTop: '16px' }} justify={'space-between'}>
                                        <Button
                                            color='default'
                                            variant="outlined"
                                            onClick={this.closeCreateItem}>
                                            {i18n.t("quotes.quoteRequest.cancelEdit")}
                                        </Button>
                                        {editItemMode && (
                                            <Button
                                                disabled={fetching || saving || deleting ? true : false}
                                                variant="contained"
                                                color='primary'
                                                className={classes.clientRFQ__addItemButton}
                                                onClick={this.handleItems}>
                                                {i18n.t("quotes.quoteRequest.saveItem")}
                                                {!deleting && saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                            </Button>
                                        )}
                                        {!editItemMode && (
                                            <Button
                                                disabled={fetching || saving || deleting ? true : false}
                                                variant="contained"
                                                color='primary'
                                                className={classes.clientRFQ__addItemButton}
                                                onClick={this.handleItems}>
                                                {i18n.t("quotes.quoteRequest.addItem")}
                                                {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                            </Button>
                                        )}
                                    </Grid>
                                </div>
                            </Dialog>

                            <Dialog aria-labelledby="translate-dialog"
                                open={openTranslateWindow}
                                maxWidth={'xs'}
                                onClose={() => {
                                    this.setState({
                                        openTranslateWindow: false,
                                        translatedDescription: null
                                    })
                                }} >
                                <DialogTitle disableTypography={true} id="simple-dialog-title">
                                    {i18n.t("quotes.quoteRequest.translate")}
                                    <IconButton color="inherit"
                                        onClick={() => {
                                            this.setState({
                                                openTranslateWindow: false,
                                                translatedDescription: null
                                            })
                                        }}
                                        aria-label="Close" className={classes.translate__closeWindow}>
                                        <Close />
                                    </IconButton>
                                </DialogTitle>
                                <div style={{ padding: '32px' }}>
                                    <Typography variant='body2' color='textSecondary'>
                                        {translatedDescription}
                                    </Typography>
                                </div>
                            </Dialog>

                            <ConfirmationDialog
                                handleClose={this.cancelConfirmationDeleteItem}
                                onConfirm={dontDelete ? this.cancelConfirmationDeleteItem : this.deleteItem}
                                message={dontDelete ? i18n.t('quotes.quoteRequest.dontDelete') : i18n.t('quotes.quoteRequest.confirmDeleteItemsMessage')}
                                cancelLabel={dontDelete ? '' : i18n.t('quotes.quoteRequest.confirmDeleteItemsNo')}
                                confirmLabel={dontDelete ? i18n.t('quotes.quoteRequest.confirmDontDelete') : i18n.t('quotes.quoteRequest.confirmDeleteItemsYes')}
                                open={this.state.openConfirmationDialog}
                            />

                        </>
                    )}
                </GlobalParametersContext.Consumer>
            </div>

        )
    }
}

RFQItems.propTypes = {
    updateItems: PropTypes.func,
    items: PropTypes.array,
    handleUnsaveChanges: PropTypes.func,
}


function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog({ breakpoint: 'xs' })(withStyles(styles)(RFQItems)))