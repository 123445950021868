// Actions types

export const GET_FILES = 'GET_FILES'
export const SET_FILES = 'SET_FILES'
export const SET_FILE = 'SET_FILE'
export const SORT_FILES = 'SORT_FILES'
export const ADD_FILE = 'ADD_FILE'
export const UPDATE_FILE = 'UPDATE_FILE'
export const MARK_FILE_TASK = 'MARK_FILE_TASK'
export const ADD_FILE_NOTE= 'ADD_FILE_NOTE'
export const DELETE_FILE = 'DELETE_FILE'


//Actions
export function getFiles(args) {
    return {
        type: GET_FILES,
        args
    }
}

export function setFiles(files) {
    return {
        type: SET_FILES,
        files
    }
}

export function sortFiles(sortBy) {
    return {
        type: SORT_FILES,
        sortBy
    }
}
export function addFile(file) {
    return {
        type: ADD_FILE,
        file
    }
}

export function deleteFile(fileId) {
    return {
        type: DELETE_FILE,
        fileId
    }
}

export function updateFile(file) {
    return {
        type: UPDATE_FILE,
        file
    }
}

export function setFile(file) {
    return {
        type: SET_FILE,
        file
    }
}

export function markFileTask(task) {
    return {
        type: MARK_FILE_TASK,
        task
    }
}

export function addNote(note) {
    return {
        type: ADD_FILE_NOTE,
        note
    }
}