import React, { Component } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    List,
    ListItem,
    Typography,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    Divider,
    withStyles,
} from '@material-ui/core'
import { Person as SalesPersonIcon, Close } from "@material-ui/icons"
import Permissions from '../../lib/permissions'

import i18n from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as peopleActions from '../../actions/peopleActions'
import * as apiStatusActions from '../../actions/apiStatusActions'

import { SlideUp } from '../general/Transitions'


import API from '../../lib/api'

const styles = theme => ({
    dialog__paper: {
        //width: '100%',
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        width: '400px',
        height: 'auto'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog__content__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    people__list__container: {
        height: '200px',
        overflow: 'auto'
    },
    people__selected__indicator__container: {

    },
    people__actions__container: {
        display: 'flex',
        paddingTop: theme.spacing(),
        justifyContent: 'space-between'
    },
    people__filter__button: {
        marginLeft: '5px',
        minWidth: '100px'
    }
})


class DashboardPeopleFilter extends Component {
    state = {
        peopleDialogVisible: false,
        loading: false,
        allUsersSelected: false,
        selectedUsers: []
    }

    componentWillMount = () => {
        this.fecthUsers();
    }

    componentWillReceiveProps(nextProps) {
        const { people } = nextProps

        this.setState({
            allUsersSelected: people && people.filterPeople.length === people.allPeople.length ? true : false,
            selectedUsers: people && people.filterPeople ? people.filterPeople : this.state.selectedUsers
        })
    }


    fecthUsers = () => {
        const { account } = this.props

        API.Users.getUsers().then(res => {
            if (res && res.status === 'success') {
                this.props.peopleActions.setPeople(res.data.records.users.filter(u => u.userActive));
                if(account.userId && account.userTypeId === 3){
                    this.props.peopleActions.setFilterPeople(res.data.records.users.filter(u => u.userActive));
                }
            }
        })
    }

    toggleAllUsers = () => {
        const { people } = this.props,
            { allUsersSelected } = this.state;

        let selectedUsers = people.allPeople,
            allUsers = !allUsersSelected;

        this.setState({
            allUsersSelected: allUsers,
            selectedUsers: !allUsers ? [] : selectedUsers
        })
    }

    toggleSelectUser = (user) => {
        const { allUsersSelected } = this.state

        let selectedUsers = allUsersSelected ? [] : [...this.state.selectedUsers],
            userInSelector = selectedUsers.find(u => u.userId === user.userId);

        if (userInSelector) {
            selectedUsers = selectedUsers.filter(u => u.userId !== user.userId)
        } else {
            selectedUsers.push(user);
        }

        this.setState({
            selectedUsers,
            allUsersSelected: selectedUsers.length > 0 ? false : true
        })

    }

    hidePeopleDialog = () => {
        this.setState({
            peopleDialogVisible: false
        })
    }

    showPeopleDialog = () => {
        this.setState({
            peopleDialogVisible: true
        })
    }

    handleFilter = () => {
        let { selectedUsers } = this.state;

        this.props.peopleActions.setFilterPeople(selectedUsers)
        this.props.fetchInfoUsers()

        this.hidePeopleDialog()
    }

    render() {
        const {
            peopleDialogVisible,
            allUsersSelected,
            loading,
            selectedUsers
        } = this.state,
            {
                classes,
                people,
                general,
            } = this.props;

        let buttonLabel = i18n.t('peopleFilter.everyone');

        if (!allUsersSelected) {
            if (selectedUsers && selectedUsers.length > 0) {
                if (selectedUsers.length === 1) {
                    let person = people.allPeople.find(user => user.userId === parseInt(selectedUsers[0].userId))
                    buttonLabel = person ? person.userName : i18n.t('peopleFilter.everyone');
                } else {
                    buttonLabel = `${selectedUsers.length} ${i18n.t('peopleFilter.users')}`;
                }
            }
        }

        return (
            <div>
                <Button
                    size='small'
                    onClick={this.showPeopleDialog}
                    className={classes.people__filter__button}
                    variant={selectedUsers && selectedUsers.length ? 'contained' : 'text'}
                    color={!allUsersSelected ? 'primary' : 'default'}
                    disabled={Permissions.checkSensitiveInformationPermission() ? false : true}>
                    <SalesPersonIcon style={{ marginRight: 5 }} /> {buttonLabel}
                </Button>

                <Dialog
                    maxWidth='sm'
                    open={peopleDialogVisible || general.showPeopleFilter || false}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    onExited={this.onExited}
                    TransitionComponent={SlideUp}>
                    <div>
                        <DialogTitle disableTypography={true} >
                            {i18n.t('peopleFilter.title')}
                            <IconButton color="inherit"
                                disabled={loading}
                                onClick={this.hidePeopleDialog}
                                aria-label="Close" className={classes.closeButton}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div className={classes.dialog__content__container}>
                                <div className={classes.people__list__container}>
                                    <List dense={true}>
                                        <ListItem button onClick={this.toggleAllUsers}>
                                            <ListItemText>{i18n.t('peopleFilter.everyone')}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={this.toggleAllUsers}
                                                    checked={allUsersSelected}
                                                />
                                            </ListItemSecondaryAction>

                                        </ListItem>
                                        {people.allPeople && people.allPeople.map((person, i) => (
                                            <ListItem key={i} button onClick={() => this.toggleSelectUser(person)}>
                                                <ListItemText>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div style={{background: person.userColor, width: '16px', height: '16px', borderRadius: '10px', marginRight: '8px'}}></div>
                                                        {person.userName}
                                                    </div>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => this.toggleSelectUser(person)}
                                                        checked={selectedUsers && selectedUsers.find(p => p.userId === person.userId) ? true : false}
                                                    />
                                                </ListItemSecondaryAction>

                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.people__actions__container}>
                                <Typography color='textSecondary'>
                                    {allUsersSelected ? i18n.t('peopleFilter.allUsers') : selectedUsers ? `${selectedUsers.length} ${selectedUsers.length === 1 ? i18n.t('peopleFilter.userSelected') : i18n.t('peopleFilter.usersSelected')}` : ''}
                                </Typography>
                                <Button color='primary'
                                    disabled={!allUsersSelected && selectedUsers && selectedUsers.length === 0}
                                    size='small'
                                    variant='contained' onClick={this.handleFilter}>
                                    {i18n.t('peopleFilter.filter')}
                                </Button>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        peopleActions: bindActionCreators(peopleActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people,
        general: state.general,
        apiStatus: state.apiStatus,
        account: state.account
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardPeopleFilter))