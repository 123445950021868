import React from 'react';
import moment from 'moment';
import {
    Tab,
    Divider,
    Tabs,
    Badge,
    Typography,
    CircularProgress,
    Link,
    IconButton,
    Grid,
    withStyles
} from '@material-ui/core/';
import Skeleton from 'react-loading-skeleton';
import { Notifications, Refresh, InsertDriveFile, Inbox } from '@material-ui/icons'
import { NoteForm, Note } from '../notes'
import { LogTable } from '../quotes/common'
import API from '../../lib/api'
import i18n from 'i18next';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../../actions/filesActions'
import * as generalActions from '../../actions/generalActions'
import * as apiStatusActions from '../../actions/apiStatusActions'
import Permissions from '../../lib/permissions'


const styles = theme => ({
    notes__list__container: {
        flexGrow: 1,
        overflow: 'hidden',
        minHeight: theme.spacing(5)
    },
    notes__list__loading__container: {
        position: 'relative',
        height: '50px',
        width: '50px',
        margin: 'auto'
    },
    notes__list__tabs__divider: {
        // marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.secondary.main}`
    },
    note__list__addButton: {
        ...theme.customLink
    },
    noteList__logTable: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    note__list__logTable__author: {
        color: theme.palette.green[100]
    },
    notes__tasks__badge: {
        top: theme.spacing() / 2,
        right: theme.spacing(),
        fontSize: '8pt',
        backgroundColor: 'transparent'
    },
    'tasks__notification__icon--yellow': {
        margin: `0 ${theme.spacing()}px ${theme.spacing() - 5}px`,
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    'tasks__notification__icon--red': {
        margin: `0 ${theme.spacing()}px ${theme.spacing() - 5}px`,
        fontSize: '14pt',
        color: theme.palette.red[100],
    },
    notes__list__edit__btn: {
        marginLeft: theme.spacing(2),
        padding: '8px'
    },
    notes__list__edit__btn__icon: {
        fontSize: '21px'
    },
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing() * 7,
    },
    document__link: {
        color: 'blue',
        cursor: 'pointer'
    }
});



class NoteList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            filteredNotes: [],
            log: [],
            notes: [],
            firstLoad: true,
            note: null,
            file: props.file,
            changedNote: null,
            openNewNote: false,
            currentTab: props.showTasksSection ? 1 : props.showLog ? 2 : 0,
            fileDocuments: [],
            availableClientDocs: [],
            availableVendorDocs: [],
            loadingDocs: []
        }
    }

    componentWillMount = () => {
        const { showTasksSection, showLog } = this.props;
        this.setAvailableDocs();
        this.fetchNotes()
        this.setState({
            currentTab: showTasksSection ? 1 : showLog ? 2 : this.state.currentTab,
        })
    }
    fetchNotes = () => {
        const { file,
            showTasksSection,
        } = this.props;

        this.setState({
            loading: true
        })

        API.Notes.getNotes({
            fileId: file.id
        }).then(res => {
            if (res.data) {
                let notes = res.data.records.notes.filter(note => note.isTask === 0 && !note.sendByEmail),
                    tasks = res.data.records.notes.filter(note => note.isTask === 1 && !note.sendByEmail),
                    emails = res.data.records.notes.filter(note => note.sendByEmail === 1),
                    fileDocuments = res.data.records.notes.filter(note => note.documents.length > 0).map(note => note.documents).flat(),
                    currentTab = this.state.currentTab;

                if (this.state.firstLoad) {
                    if (notes.length === 0 && tasks.length !== 0) {
                        currentTab = 1
                    } else if (emails.length !== 0 && Permissions.checkSensitiveInformationPermission()) {
                        currentTab = 2
                    }
                }
                this.setState({
                    firstLoad: false,
                    notes: res.data.records.notes,
                    log: res.data.records.log,
                    currentTab: showTasksSection ? 1 : currentTab,
                    fileDocuments: fileDocuments.filter(d => d.name).map(doc => {
                        let separate = doc.name.split('.'),
                            // date = moment(parseInt(separate[0].split('-')[2])).format('MMM Do, hh:mm:ss a'),
                            name = `${separate[0].split('-')[0]}${separate[0].split('-')[1] ? `-${separate[0].split('-')[1]}` : ''}.${doc.name.split('.')[1]}`

                        return {
                            ...doc,
                            label: name,
                            date: moment(doc.timestamp).format('MMM Do, hh:mm:ss a')
                        }
                    }),
                    filteredNotes: showTasksSection ? tasks : currentTab === 0 ? notes : currentTab === 1 ? tasks : emails,
                    loading: false
                })
            }
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.showTasksSection) {
            this.setState({
                currentTab: nextProps.showTasksSection ? 1 : 0
            })
        }

        if (nextProps.showLog) {
            this.setState({
                currentTab: 2
            })
        }
        if (nextProps.general && nextProps.general.reloadNotes) {
            this.props.generalActions.reloadNotes(false);
            this.fetchNotes();
        }
    }

    openNewNote = (e, note) => {
        e.stopPropagation()
        this.setState({
            note: note ? note : null,
            openNewNote: true
        })
    }

    closeNewNote = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }

    completeTask = (e, index) => {
        const { filteredNotes } = this.state;

        let newNotes = [
            ...filteredNotes.slice(0, index),
            {
                ...filteredNotes[index],
                taskComplete: e.target.checked ? 1 : 0
            },
            ...filteredNotes.slice(index + 1)
        ];


        this.setState({
            filteredNotes: newNotes
        }, () => {
            let task = newNotes[index];
            API.Notes.markTask({
                fileId: task.fileId,
                noteId: task.id,
                completed: task.taskComplete
            }).then(updated => {
                if (updated.status === 'success') {
                    let updatedTask = updated.data.record[0];
                    this.props.filesActions.markFileTask(updatedTask)
                }
            })
        })
    }

    fetchVendorPDF = ({ vendorQuote, reportType, pdfName }) => {
        const { file } = this.props,
            { loadingDocs } = this.state;
        if (loadingDocs.indexOf(pdfName) < 0) {
            if (file) {
                this.setState({
                    loadingDocs: [...loadingDocs, pdfName]
                }, () => {
                    API.Reports.getVendorPDFReport({ fileId: file.id, reportType: reportType, vendorQuoteId: vendorQuote.id })
                        .then(reportRes => {
                            this.downloadPdf({ dataUrl: reportRes.dataUrl, pdfName })
                            this.setState({
                                loadingDocs: this.state.loadingDocs.filter(ld => ld !== pdfName)
                            })
                        })
                })
            }
        }
    }

    fetchClientPDF = ({ reportType, invoiceId, pdfName }) => {
        let { file } = this.props,
            { loadingDocs } = this.state;
        if (loadingDocs.indexOf(pdfName) < 0) {
            if (file) {

                this.setState({
                    loadingDocs: [...loadingDocs, pdfName]
                }, () => {
                    API.Reports.getClientPDFReport({ fileId: file.id, reportType: reportType, invoiceId })
                        .then(reportRes => {
                            this.downloadPdf({ dataUrl: reportRes.dataUrl, pdfName })
                            this.setState({
                                loadingDocs: this.state.loadingDocs.filter(ld => ld !== pdfName)
                            })
                        })
                })
            }
        }
    }
    downloadPdf = ({ dataUrl, pdfName }) => {
        const linkSource = dataUrl;
        const downloadLink = document.createElement("a");
        const fileName = pdfName;
        if (undefined === window.navigator.msSaveOrOpenBlob) {
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        // IE-specific code
        else {
            function dataURItoBlob(dataURI) {
                // convert base64 to raw binary data held in a string
                // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
                var byteString = atob(dataURI.split(',')[1]);

                // separate out the mime component
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

                // write the bytes of the string to an ArrayBuffer
                var ab = new ArrayBuffer(byteString.length);

                // create a view into the buffer
                var ia = new Uint8Array(ab);

                // set the bytes of the buffer to the correct values
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                // write the ArrayBuffer to a blob, and you're done
                var blob = new Blob([ab], { type: mimeString });
                return blob;

            }
            let blob = dataURItoBlob(linkSource);
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
    }

    setAvailableDocs = () => {
        const { file } = this.props;

        let availableClientDocs = [], availableVendorDocs = [];

        switch (file.status) {
            case 2:
            case 3:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                if (file.status === 3 && file.clientQuote.length > 0) {
                    let items = file.clientQuote[0] ? file.clientQuote[0].items : [],
                        creatingClientQuote = items.filter(item => item.amount).length === 0 && file.clientQuote[0] && !file.clientQuote[0].validFor && !file.clientQuote[0].deliveryDays;
                    if (!creatingClientQuote) {
                        availableClientDocs.push({
                            label: i18n.t('printing.clientDocs.quote'),
                            value: 'quote'
                        })
                    }
                }
                break;
            case 4:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                break
            case 5:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.po'),
                    value: 'vendorpo'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: 'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: 'proform'
                })
                break;
            case 6:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                break;
            case 7:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.po'),
                    value: 'vendorpo'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: 'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: 'proform'
                })
                break;
            default:
                break;
        }
        this.setState({
            availableClientDocs,
            availableVendorDocs: file.vendorQuotes.length > 0 ? availableVendorDocs : [],
        })
    }
    updateNote = (note) => {
        const { notes } = this.state;
        let newNotes = [...notes],
            index = newNotes.findIndex(n => n.id === note.id);

        newNotes[index] = note;

        this.setState({
            changedNote: index,
            notes: newNotes
        })
        this.filterNotes(null, note.isTask ? 1 : note.sendByEmail ? 2 : 0)

        this.closeNewNote()
    }

    addNewNote = (note) => {
        const { notes } = this.state;
        let newNotes = [...notes];

        newNotes.unshift(note)

        this.setState({
            changedNote: 0,
            notes: newNotes
        })

        this.filterNotes(null, note.isTask ? 1 : note.sendByEmail ? 2 : 0)

        this.closeNewNote()
        this.props.filesActions.addNote(note);
    }

    deleteNote = (noteId) => {
        const { filteredNotes } = this.state;
        let newNotes = [...filteredNotes],
            index = newNotes.findIndex(n => n.id === noteId);

        newNotes.splice(index, 1);

        this.setState({
            filteredNotes: newNotes
        })

        this.closeNewNote()
    }

    filterNotes = (e, value) => {
        const { notes } = this.state;

        let newNotes = []

        switch (value) {
            case 0:
                newNotes = notes.filter(note => note.isTask === 0 && !note.sendByEmail);
                break;
            case 1:
                newNotes = notes.filter(note => note.isTask === 1 && !note.sendByEmail)
                break;
            case 2:
                this.setAvailableDocs();
                newNotes = notes.filter(note => note.sendByEmail === 1);
                break;
            case 3:
                this.setAvailableDocs();
                break;
            default:
                newNotes = notes.filter(note => note.isTask === 0)
                break;
        }


        this.setState({
            currentTab: value,
            filteredNotes: newNotes
        })
    }

    render() {
        const { filteredNotes,
            loading, openNewNote, log,
            fileDocuments,
            note, file, currentTab,
            availableClientDocs,
            availableVendorDocs,
            loadingDocs } = this.state;
        const { classes, user, tasksCount, olderTask, dashboardSummary } = this.props;

        let options = [
            {
                label: i18n.t('notes.list.tabs.notes'),
                value: 1
            },
            {
                label: i18n.t('notes.list.tabs.tasks'),
                value: 2
            },
            {
                label: i18n.t('notes.list.tabs.emails'),
                value: 3
            },
            {
                label: i18n.t('notes.list.tabs.files'),
                value: 4
            },
            {
                label: i18n.t('notes.list.tabs.log'),
                value: 5
            },
        ];

        if (dashboardSummary) {
            options = options.splice(0, 3)
        }

        return (
            <div className={classes.notes__list__container}>

                <Grid container spacing={0} alignItems='center'>
                    <Grid item xs={11}>
                        <Link
                            variant='button'
                            className={classes.note__list__addButton}
                            onClick={(e) => this.openNewNote(e, null)}>
                            {currentTab === 0 ?
                                i18n.t("notes.addNote") : currentTab === 1 ? i18n.t("notes.addTask") : i18n.t("notes.addEmail")
                            }
                        </Link>
                    </Grid>

                    <Grid item xs={1}>
                        <IconButton
                            className={classes.notes__list__edit__btn}
                            aria-label="Search"
                            onClick={this.fetchNotes}>
                            <Refresh className={classes.notes__list__edit__btn__icon} />
                        </IconButton>
                    </Grid>
                </Grid>


                {loading &&
                    <div>
                        {[0, 1, 2, 3, 4, 5].map((i) => (
                            <div key={i}>
                                <h6><Skeleton /></h6>
                                <Skeleton count={3} />
                            </div>
                        ))}

                        <div className={classes.notes__list__loading__container}>
                            <CircularProgress color='secondary' size={24} className='form__submitButton__spinner' />
                        </div>
                    </div>
                }


                <NoteForm
                    open={openNewNote}
                    file={file}
                    note={note}
                    user={user}
                    section={currentTab < 3 ? currentTab : 0}
                    deleteNote={this.deleteNote}
                    updateNote={this.updateNote}
                    addNewNote={this.addNewNote}
                    handleClose={this.closeNewNote}
                />


                {!loading &&
                    <div>
                        <Tabs
                            className={classes.quotes__statusTabs}
                            value={currentTab}
                            onChange={this.filterNotes}
                            indicatorColor="secondary"
                            textColor="secondary"
                        >

                            {options.map((option, i) => {
                                if (!Permissions.checkSensitiveInformationPermission() && (option.label === 'Documents' || option.label === 'Emails' || option.label === 'Log')) {
                                    return <Tab disabled={true} style={{ width: 0, minWidth: 0, padding: 0 }} />
                                } else {
                                    return (
                                        <Tab
                                            key={i}
                                            label={
                                                <div>
                                                    {option.label}
                                                    {tasksCount > 0 && i === 1 &&
                                                        <Badge
                                                            badgeContent={tasksCount}
                                                            classes={{ badge: classes.notes__tasks__badge }} >
                                                            <Notifications fontSize="small" className={moment().diff(moment(olderTask), 'days') > 4 ? classes['tasks__notification__icon--red'] : classes['tasks__notification__icon--yellow']} />
                                                        </Badge>
                                                    }
                                                </div>
                                            }
                                            style={{ width: 120 }}
                                            classes={{
                                                root: classes.notes__list__tab,
                                                selected: classes['notes__list__tab--selected']
                                            }}
                                        />
                                    )
                                }
                            })}
                        </Tabs>
                        <Divider className={classes.notes__list__tabs__divider} />

                        {filteredNotes && filteredNotes.length > 0 && currentTab < 3 &&
                            <div>
                                {filteredNotes.map((note, i) => {
                                    return (
                                        <Note
                                            key={i}
                                            note={note}
                                            index={i}
                                            user={user}
                                            highlight={false}
                                            openNewNote={this.openNewNote}
                                            completeTask={this.completeTask}
                                            dashboardSummary={dashboardSummary}
                                        />
                                    )
                                })}
                            </div>
                        }

                        {filteredNotes && filteredNotes.length === 0 && currentTab < 3 &&
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{currentTab === 0 ? i18n.t('notes.noNotes') : currentTab === 1 ? i18n.t('notes.noTasks') : i18n.t('notes.noMails')}</Typography>
                            </div>
                        }

                        {currentTab === 3 && !dashboardSummary && (
                            <div>
                                {(fileDocuments.length > 0 && fileDocuments.filter(doc => doc.url).length > 0) || availableClientDocs.length > 0 || availableVendorDocs.length > 0 ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='body1' color='textSecondary'>{i18n.t('printing.fileUpdatedDocuments')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider color='textSecondary' />
                                        </Grid>
                                        {availableClientDocs.length > 0 && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' color='textSecondary'>{i18n.t('printing.clientDocsList')}</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ paddingTop: 0 }}>
                                                    <Divider color='textSecondary' />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} >
                                                        {availableClientDocs.map((doc, i) => (
                                                            <Grid key={`${'client'}-${i}`} item xs={6} sm={4}>
                                                                <Link underline='always' className={classes.document__link}
                                                                    onClick={() => {
                                                                        this.fetchClientPDF({
                                                                            reportType: doc.value,
                                                                            invoiceId: null,
                                                                            pdfName: `${doc.label}-${file.id}.pdf`
                                                                        })
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                        <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                                        <Typography variant='body2' noWrap>{decodeURI(doc.label)}-{file.id}{'.pdf'}</Typography>
                                                                    </div>
                                                                </Link>
                                                                {loadingDocs.indexOf(`${doc.label}-${file.id}.pdf`) >= 0 && (
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Typography color='textSecondary' variant='body2'>{i18n.t('printing.downloading')}</Typography>
                                                                        <CircularProgress color='secondary' size={24} style={{ marginLeft: '10px' }} className='form__submitButton__spinner' />
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        ))}
                                                        {file.invoices.length > 0 && file.invoices.map((invoice, i) => (
                                                            <React.Fragment key={i}>
                                                                <Grid key={`${'invoice'}-${i}`} item xs={6} sm={4}>
                                                                    <Link underline='always' className={classes.document__link}
                                                                        onClick={() => {
                                                                            this.fetchClientPDF({
                                                                                reportType: 'invoice',
                                                                                invoiceId: invoice.id,
                                                                                pdfName: `${i18n.t('printing.clientDocs.invoice')}-${invoice.id}.pdf`
                                                                            })
                                                                        }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                                            <Typography variant='body2' noWrap>{i18n.t('printing.clientDocs.invoice')}-{invoice.id}{'.pdf'}</Typography>
                                                                        </div>
                                                                        {loadingDocs.indexOf(`${i18n.t('printing.clientDocs.invoice')}-${invoice.id}.pdf`) >= 0 && (
                                                                            <div style={{ display: 'flex' }}>
                                                                                <Typography color='textSecondary' variant='body2'>{i18n.t('printing.downloading')}</Typography>
                                                                                <CircularProgress color='secondary' size={24} style={{ marginLeft: '10px' }} className='form__submitButton__spinner' />
                                                                            </div>
                                                                        )}
                                                                    </Link>
                                                                </Grid>
                                                                <Grid key={`${'pl'}-${i}`} item xs={6} sm={4}>
                                                                    <Link underline='always' className={classes.document__link}
                                                                        onClick={() => {
                                                                            this.fetchClientPDF({
                                                                                reportType: 'packingList',
                                                                                invoiceId: invoice.id,
                                                                                pdfName: `${i18n.t('printing.clientDocs.packingList')}-${invoice.id}.pdf`
                                                                            })
                                                                        }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                                            <Typography variant='body2' noWrap>{i18n.t('printing.clientDocs.packingList')}-{invoice.id}{'.pdf'}</Typography>
                                                                        </div>
                                                                    </Link>
                                                                    {loadingDocs.indexOf(`${i18n.t('printing.clientDocs.packingList')}-${invoice.id}.pdf`) >= 0 && (
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Typography color='textSecondary' variant='body2'>{i18n.t('printing.downloading')}</Typography>
                                                                            <CircularProgress color='secondary' size={24} style={{ marginLeft: '10px' }} className='form__submitButton__spinner' />
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        {availableVendorDocs.length > 0 && file.vendorQuotes.length > 0 && (
                                            <>
                                                {file.vendorQuotes.map((vq, i) => (
                                                    <React.Fragment key={i}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='body2' color='textSecondary'>{i18n.t('printing.vendorDocsList')} {vq.vendor.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ paddingTop: 0 }}>
                                                            <Divider color='textSecondary' />
                                                        </Grid>
                                                        <Grid item xs={6}></Grid>
                                                        {availableVendorDocs.map((doc, j) => (
                                                            <Grid key={`${vq.id}-${j}`} item xs={6} sm={4}>
                                                                <Link underline='always' className={classes.document__link}
                                                                    onClick={() => {
                                                                        this.fetchVendorPDF({
                                                                            vendorQuote: vq,
                                                                            reportType: doc.value,
                                                                            pdfName: `${doc.label}-${vq.id}.pdf`
                                                                        })
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                        <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                                        <Typography variant='body2' noWrap>{doc.label}-{vq.id}{'.pdf'}</Typography>
                                                                    </div>
                                                                </Link>
                                                                {loadingDocs.indexOf(`${doc.label}-${vq.id}.pdf`) >= 0 && (
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Typography color='textSecondary' variant='body2'>{i18n.t('printing.downloading')}</Typography>
                                                                        <CircularProgress color='secondary' size={24} style={{ marginLeft: '10px' }} className='form__submitButton__spinner' />
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}

                                        {fileDocuments.length > 0 && fileDocuments.filter(doc => doc.url).length > 0 && (
                                            <>

                                                <Grid item xs={6} style={{ marginTop: 24 }}>
                                                    <Typography variant='body1' color='textSecondary'>
                                                        {i18n.t('printing.filePeviousDocuments')}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider color='textSecondary' />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} style={{ marginTop: 16 }} >
                                                        {fileDocuments.filter(doc => doc.url).map((doc, i) => {
                                                            return (
                                                                <Grid key={`${doc.id}-${i}`} item xs={6} sm={4}>
                                                                    <a key={`${doc.id}-${i}`} href={`https://${escape(doc.url.replace('https://', '').trim())}`} target='_blank' rel="noopener noreferrer">
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                                            <Typography key={doc.id} variant='body2' noWrap>{decodeURI(doc.label)}</Typography>
                                                                        </div>
                                                                    </a>
                                                                    <Typography key={doc.id} variant='body2' noWrap>{doc.date}</Typography>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                    </Grid>
                                ) : (
                                        <div className={classes.empty__state__container}>
                                            <Inbox style={{ fontSize: 50 }} color='secondary' />
                                            <Typography variant='body2'>{i18n.t('notes.noDocuments')}</Typography>
                                        </div>
                                    )}


                            </div>
                        )}

                        {log && log.length > 0 && currentTab === 4 && !dashboardSummary &&
                            <div>
                                <LogTable log={log} />
                            </div>
                        }

                        {log && log.length === 0 && currentTab === 4 && !dashboardSummary &&
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('notes.noLog')}</Typography>
                            </div>

                        }
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NoteList));