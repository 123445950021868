import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
    Button,
    Divider,
    Link,
    CircularProgress
} from '@material-ui/core'
import { ConfirmationReturnStatusDialog, CustomMessages, ConfirmationDialog } from '../../general'
import { VendorQuoteReadOnlyInformation, VendorQuoteItems } from '../vendorQR'
import { VendorPOReadOnly, VendorPOActions, VendorPOForm, VendorPOTotals } from '../po'
import API from '../../../lib/api'
import i18n from 'i18next';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as filesActions from '../../../actions/filesActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'

const styles = theme => {
    return ({
        po__clientName__label: {
            textAlign: 'left',
            color: '#000',
        },
        po__divider: {
            margin: '16px 0'
        },
        buttonSpinner: theme.buttonSpinner,
        button__wrapper: {
            width: 'fit-content',
            position: 'relative'
        },
        print__edit__buttons__container: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        poClient__editLink: theme.customLink,
        createVendorPO__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `${theme.spacing(3)}px`
        },
    })
}


class VendorsPO extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmationDialog: false,
            openConfirmationRestoreDialog: false,
            loading: false,
            file: props.file,
            validating: false,
            apiErrorMessage: null,
            editVendorPODialogOpen: false,
            vendorPO: props.vendorPO ? props.vendorPO : null,
            poNumber: props.vendorPO && props.vendorPO.poNumber ? props.vendorPO.poNumber : props.vendorPO.id,
            poDate: props.vendorPO && props.vendorPO.date ? props.vendorPO.date : new Date(),
            poDeliveryDate: props.vendorPO && props.vendorPO.deliveryDate ? props.vendorPO.deliveryDate : (new Date()).setDate((new Date()).getDate() + props.vendorPO.vendorQuoteDeliveryDays),
            poComment: props.vendorPO && props.vendorPO.comment ? props.vendorPO.comment : '',
            deliveryAddress: props.vendorPO && props.vendorPO.deliveryAddress ? props.vendorPO.deliveryAddress : null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file
        })

        if (nextProps.vendorPO) {
            this.setState({
                vendorPO: nextProps.vendorPO ? nextProps.vendorPO : null,
                poNumber: nextProps.vendorPO && nextProps.vendorPO.poNumber ? nextProps.vendorPO.poNumber : nextProps.vendorPO.id,
                poDate: nextProps.vendorPO && nextProps.vendorPO.date ? nextProps.vendorPO.date : new Date(),
                poDeliveryDate: nextProps.vendorPO && nextProps.vendorPO.deliveryDate ? nextProps.vendorPO.deliveryDate : (new Date()).setDate((new Date()).getDate() + nextProps.vendorQuoteDeliveryDays),
                poComment: nextProps.vendorPO && nextProps.vendorPO.comment ? nextProps.vendorPO.comment : '',
                deliveryAddress: nextProps.vendorPO && nextProps.vendorPO.deliveryAddress ? nextProps.vendorPO.deliveryAddress : null
            })
        }
    }

    confirmReturnVendorPO = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    confirmChangeVendorPOStatus = ({ comments }) => {
        const { vendorPO } = this.state

        this.changeVendorPOStatus(vendorPO && vendorPO.status.id === 3 ? 2 : 1, comments)
    }


    changeVendorPOStatus = (status, returnComments) => {
        const { poDeliveryDate, poDate, deliveryAddress, poNumber, poComment, vendorPO } = this.state;

        this.setState({
            validating: true
        })

        if (vendorPO.status.id === 1 && poNumber === '') {
            return
        }

        this.setState({
            validating: false,
            loading: true
        })

        let body = {};

        if (vendorPO.status.id === 1) {
            body = {
                id: vendorPO.id,
                poNumber,
                date: poDate,
                isVoid: false,
                statusId: vendorPO.status.id,
                estimatedDeliveryDate: new Date(poDeliveryDate),
                comments: poComment,
                fileId: vendorPO.fileId,
                poItems: vendorPO.items
            }

            if (deliveryAddress) body.deliveryAddressId = deliveryAddress.id;

            API.Quotes.updateVendorPO({ body }).then(res => {
                if (res.record) {
                    let changeStatusBody = {
                        id: vendorPO.id,
                        statusId: status,
                        vendorPOItems: vendorPO.items.map(item => {
                            return {
                                id: item.id,
                                receivedQuantity: parseInt(item.receivedQuantity || 0) + parseInt(item.receiving || 0),
                                receiving: item.receiving,
                                receivingDate: item.receivingDate
                            }
                        })
                    }

                    if (returnComments) {
                        changeStatusBody.comments = returnComments
                    }


                    API.Quotes.changeStatusVendorPO({ body: changeStatusBody }).then(resp => {
                        if (resp.record) {
                            this.setState({
                                loading: false,
                                openConfirmationDialog: false
                            })
                            this.props.updateVendorsPO(resp.record.file, vendorPO.id)

                        } else {
                            this.setState({
                                loading: false,
                                openConfirmationDialog: false,
                                apiErrorMessage: `${i18n.t('apiErrorMessage')} ${resp.message ? resp.message : ''}`
                            })
                        }
                    })
                } else {
                    this.setState({
                        loading: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        } else {
            body = {
                id: vendorPO.id,
                statusId: status,
                poItems: vendorPO.items.map(item => {
                    return {
                        id: item.id,
                        receivedQuantity: returnComments ? parseInt(item.receivedQuantity || 0) : parseInt(item.receivedQuantity || 0) + parseInt(item.receiving || 0),
                        receiving: item.receiving || 0,
                        receivingDate: item.receivingDate || new Date()
                    }
                })
            }


            if (returnComments) {
                body.comments = returnComments
            }

            API.Quotes.changeStatusVendorPO({ body }).then(resp => {
                if (resp.record) {
                    this.setState({
                        loading: false,
                        openConfirmationDialog: false
                    })
                    this.props.updateVendorsPO(resp.record.file, vendorPO.id)

                } else {
                    this.setState({
                        loading: false,
                        openConfirmationDialog: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${resp.message ? resp.message : ''}`
                    })
                }
            })
        }
    }

    saveVendorPO = () => {
        const { vendorPO, poDeliveryDate, poDate, deliveryAddress, poNumber, poComment } = this.state;

        this.setState({
            validating: true
        })

        if (poNumber === '') {
            return
        }

        this.setState({
            loading: true
        })

        let body = {
            id: vendorPO.id,
            poNumber,
            date: poDate,
            statusId: vendorPO.status.id,
            estimatedDeliveryDate: new Date(poDeliveryDate),
            comments: poComment,
            fileId: vendorPO.fileId
        }
        if (deliveryAddress) body.deliveryAddressId = deliveryAddress.id;

        API.Quotes.updateVendorPO({ body }).then(res => {
            if (res.record) {
                this.setState({
                    loading: false
                })
                this.props.updateVendorsPO(res.record.file)
            } else {
                this.setState({
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    updateVendorPO = (file) => {
        this.props.updateVendorsPO(file)
        this.handleCloseEditVendorPO()
    }

    handleCloseEditVendorPO = () => {
        this.setState({
            editVendorPODialogOpen: false
        })
    }

    changeToNextStatus = (vendorPO) => {
        this.setState({
            vendorPO
        }, () => {
            this.changeVendorPOStatus(vendorPO.status.id === 1 ? 2 : 3, null)
        })
    }

    cancelConfirmationRestore = () => {
        this.setState({
            openConfirmationRestoreDialog: false
        })
    }

    restoreVendorPO = () => {
        const { vendorPO } = this.state;

        this.setState({
            openConfirmationRestoreDialog: false
        })

        let body = {
            id: vendorPO.id,
            poNumber: vendorPO.poNumber,
            date: vendorPO.date,
            statusId: vendorPO.status.id,
            estimatedDeliveryDate: vendorPO.deliveryDate,
            comments: vendorPO.comment,
            isVoid: false,
            fileId: vendorPO.fileId,
            deliveryAddressId: vendorPO.deliveryAddress.id,
            poItems: vendorPO.items.map(item => {
                return {
                    id: item.id,
                    unitAmount: item.unitAmount,
                    quantity: item.quantity,
                    partNumber: item.partNumber,
                    deliveryDays: item.deliveryDays,
                    comments: item.comments,
                    unit: item.unit,
                    description: item.description,
                    rfqItemId: item.rfqItemId
                }
            })
        }

        API.Quotes.updateVendorPO({ body }).then(res => {
            if (res.record) {
                this.setState({
                    successMessage: i18n.t('successfullySavedMessage'),
                })
                this.updateVendorPO(res.record.file)
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }


    render() {
        const { classes, currencySymbol, editable } = this.props,
            { file,
                apiErrorMessage,
                openConfirmationDialog,
                loading,
                editVendorPODialogOpen,
                vendorPO,
                openConfirmationRestoreDialog
            } = this.state;

        if (!vendorPO) return null

        let requested = vendorPO.items.filter(vpi=> !vpi.deleted).reduce(((total, row) => parseFloat(total) + (row.quantity ? parseFloat(row.quantity || 0) : 0)), 0),
            received = vendorPO.items.filter(vpi=> !vpi.deleted).reduce(((total, row) => parseFloat(total) + (row.receivedQuantity ? parseFloat(row.receivedQuantity || 0) : 0)), 0);

        return (
            <div style={{ width: '100%', paddingLeft: '8px' }}>
                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                {editable && vendorPO.status.id === 1 && !vendorPO.poNumber ?
                    <div className={classes.createVendorPO__container}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    editVendorPODialogOpen: true
                                })
                            }}>
                            {i18n.t("quotes.pos.poDetails.createVendorPO")}
                        </Button>
                    </div>
                    :
                    <>
                        <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                {(vendorPO.status.id === 2 || vendorPO.status.id === 4) && !vendorPO.isVoid &&
                                    <Typography variant='body1' color='textPrimary' style={{ fontSize: '16px' }}>{`${i18n.t('quotes.pos.poDetails.received')}:  ${received} of ${requested}`}</Typography>
                                }
                                <div>
                                    {loading && <div style={{
                                        position: 'relative',
                                        height: '50px',
                                        width: '100px'
                                    }}>
                                        <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                                    </div>}

                                    {!loading && editable && !vendorPO.isVoid &&
                                        <VendorPOActions
                                            file={file}
                                            vendorPO={vendorPO}
                                            disabledExit={() => null}
                                            updateFile={this.props.updateFile}
                                            confirmReturnVendorPO={this.confirmReturnVendorPO}
                                            changeToNextStatus={this.changeToNextStatus} />
                                    }

                                    {vendorPO.isVoid === 1 && editable &&
                                        <Typography variant='body1' color='textSecondary' style={{ fontSize: '20px' }}>
                                            {i18n.t('quotes.pos.poDetails.void')}
                                        </Typography>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={10} md={10}>
                                <VendorPOReadOnly
                                    vendorPO={vendorPO}
                                />
                            </Grid>


                            {editable && !vendorPO.isVoid && vendorPO.status.id < 3 &&
                                <Grid item xs={12} sm={2} md={2} >
                                    <div className={classes.print__edit__buttons__container}>
                                        <Link className={classes.poClient__editLink}
                                            variant='button'
                                            onClick={() => {
                                                this.setState({
                                                    editVendorPODialogOpen: true
                                                })
                                            }}>
                                            {i18n.t('quotes.pos.poDetails.editClientPO')}
                                        </Link>
                                    </div>
                                </Grid>
                            }

                            {editable && vendorPO.isVoid === 1 &&
                                <Grid item xs={12} sm={2} md={2} >
                                    <div className={classes.print__edit__buttons__container}>
                                        <Link className={classes.poClient__editLink}
                                            variant='button'
                                            onClick={() => {
                                                this.setState({
                                                    openConfirmationRestoreDialog: true
                                                })
                                            }}>
                                            {i18n.t('quotes.pos.poDetails.restoreVendorPO')}
                                        </Link>
                                    </div>
                                </Grid>
                            }
                        </Grid>

                        <Divider className={classes.po__divider} />

                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant='h6'
                                    className={classes.po__clientName__label}
                                > {vendorPO.vendor.name}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <VendorQuoteReadOnlyInformation
                                    file={file}
                                    isVendorPO={true}
                                    vendorQuote={vendorPO}
                                    currencySymbol={currencySymbol}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VendorQuoteItems
                                    items={vendorPO.items.sort((a, b) => { return a.rfqItemId - b.rfqItemId })}
                                    isVendorPO={true}
                                    vendorQuoteSummary={false}
                                    vendorQuote={vendorPO}
                                    currencySymbol={currencySymbol}
                                    vendorQuoteStatusId={3}
                                />

                                <VendorPOTotals
                                    currencySymbol={file.currencySymbol}
                                    items={vendorPO.items.filter(vpi=> !vpi.deleted)}
                                    otherCosts={vendorPO.otherCosts || []}
                                />
                            </Grid>
                        </Grid>
                    </>
                }

                <ConfirmationReturnStatusDialog
                    handleClose={() => {
                        this.setState({
                            openConfirmationDialog: false
                        })
                    }}
                    onConfirm={this.confirmChangeVendorPOStatus}
                    message={`${i18n.t('quotes.pos.confirmation.confirmReturnVendorPOMessage')} ${vendorPO.status && vendorPO.status.id === 3 ? 'Sent to Vendor' : 'Preparing'} ? `}
                    loading={loading}
                    inputLabel={i18n.t('quotes.vendorQuoteRequest.confirmation.explainReason')}
                    cancelLabel={i18n.t('quotes.pos.confirmation.cancel')}
                    confirmLabel={`${i18n.t('quotes.pos.confirmation.confirmLabel')} ${vendorPO.status && vendorPO.status.id === 3 ? 'Sent to Vendor' : 'Preparing'}`}
                    open={openConfirmationDialog}
                />

                <ConfirmationDialog
                    handleClose={this.cancelConfirmationRestore}
                    onConfirm={this.restoreVendorPO}
                    message={i18n.t('quotes.pos.vendorPOs.confirmation.confirmRestoreVendorPOMessage')}
                    cancelLabel={i18n.t('quotes.pos.vendorPOs.confirmation.cancelVoid')}
                    confirmLabel={i18n.t('quotes.pos.vendorPOs.confirmation.confirmRestoreLabel')}
                    open={openConfirmationRestoreDialog}
                />

                <VendorPOForm
                    open={editVendorPODialogOpen}
                    updateVendorPO={this.updateVendorPO}
                    handleClose={this.handleCloseEditVendorPO}
                    vendorPO={vendorPO}
                    updateFile={this.props.updateFile}
                />
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus,
        file: state.file
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorsPO));