import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    IconButton
} from '@material-ui/core'
import { SlideUp } from '../../general/Transitions'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import {  CustomMessages, ConfirmationDialog } from '../../general'
import { VendorPOItems } from '.'
import { Close } from '@material-ui/icons'
import MomentUtils from '@date-io/moment';
import API from '../../../lib/api'
import i18n from 'i18next';

const styles = theme => {
    return ({
        po__textField__comment: {
            marginTop: theme.spacing(),
            whiteSpace: 'pre-line'
        },
        po__form__textField: {
            margin: '8px 0'
        },
        po__form__date: {
            fontFamily: theme.typography.fontFamily,
            margin: `${theme.spacing()}px 0`,
            fontSize: '0.8em'
        },
        po__form__date__input: {
            fontSize: 'unset'
        },
        po__form__input: {
            padding: theme.spacing() - 1,
            fontSize: '0.8em'
        },
        po__form__select: {
            fontSize: '0.8em',
            width: '100%'
        },
        buttonSpinner: theme.buttonSpinner,
        button__wrapper: {
            width: 'fit-content',
            position: 'relative'
        },
        editClientPOForm__title__closeButton: {
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
            padding: '5px'
        },
        vendorPOForm__dialog__content: {
            marginTop: '16px'
        },
        subaction__bar__actionButton__void: {
            backgroundColor: 'white',
            color: theme.palette.red[90],
            textDecoration: 'underline',
            minWidth: '100px',
            margin: 0,
            '&:hover': {
                backgroundColor: 'white',
                filter: 'brightness(1.1);'
            }
        },
    })
}

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}


class VendorPOForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isVoid: props.vendorPO && props.vendorPO.isVoid,
            validating: false,
            apiErrorMessage: null,
            openConfirmationDialog: false, 
            vendorPO: props.vendorPO,
            poNumber: props.vendorPO && props.vendorPO.poNumber ? props.vendorPO.poNumber : props.vendorPO.vendorQuoteId,
            poDate:  props.vendorPO && props.vendorPO.date ? props.vendorPO.date : new Date(),
            poDeliveryDate: props.vendorPO && props.vendorPO.deliveryDate ? props.vendorPO.deliveryDate : (new Date()).setDate((new Date()).getDate() + props.vendorPO.vendorQuoteDeliveryDays),
            poComment:  props.vendorPO && props.vendorPO.comment ? props.vendorPO.comment : '',
            deliveryAddress:  props.vendorPO && props.vendorPO.deliveryAddress ? props.vendorPO.deliveryAddress : null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.vendorPO) {
            this.setState({
                vendorPO: nextProps.vendorPO,
                poNumber: nextProps.vendorPO.poNumber ? nextProps.vendorPO.poNumber : nextProps.vendorPO.vendorQuoteId,
                poDate: nextProps.vendorPO.date ? nextProps.vendorPO.date : new Date(),
                poDeliveryDate: nextProps.vendorPO.deliveryDate ? nextProps.vendorPO.deliveryDate : (new Date()).setDate((new Date()).getDate() + nextProps.vendorPO.vendorQuoteDeliveryDays),
                poComment: nextProps.vendorPO.comment ? nextProps.vendorPO.comment : '',
                isVoid: nextProps.vendorPO.isVoid,
                deliveryAddress: nextProps.vendorPO.deliveryAddress ? nextProps.vendorPO.deliveryAddress : null
            })
        }
    }

    handleDateChange = date => {
        this.setState({ 
            poDate: date,
            poDeliveryDate: (new Date(date)).setDate((new Date(date)).getDate() + this.props.vendorPO.vendorQuoteDeliveryDays)
        });
    }

    handleDeliveryDateChange = date => {
        this.setState({ poDeliveryDate: date });
    }

    saveVendorPO = () => {
        const { poDeliveryDate, poDate, deliveryAddress,
             poNumber, poComment, vendorPO, isVoid } = this.state;

        this.setState({
            openConfirmationDialog: false,
            validating: true
        })

        if (poNumber === '') {
            return
        }

        this.setState({
            loading: true
        })

        let body = {
            id: vendorPO.id,
            poNumber,
            date: poDate,
            statusId: vendorPO.status.id,
            estimatedDeliveryDate: new Date(poDeliveryDate),
            comments: poComment,
            isVoid: isVoid && !vendorPO.poNumber ? false : isVoid ? true : false,
            fileId: vendorPO.fileId,
            poItems: vendorPO.items.map(item=>{
                return {
                    id: item.id,
                    unitAmount: item.unitAmount,
                    quantity: item.quantity,
                    partNumber: item.partNumber, 
                    deliveryDays: item.deliveryDays, 
                    comments: item.comments, 
                    unit: item.unit, 
                    description: item.description, 
                    rfqItemId: item.rfqItemId,
                    deleted: item.deleted || item.quantity === 0 || item.quantity === "0" ? true : false
                }
            })
        }

        if (deliveryAddress) body.deliveryAddressId = deliveryAddress.id;

        API.Quotes.updateVendorPO({ body }).then(res => {
            if (res.record) {
                this.setState({
                    successMessage: i18n.t('successfullySavedMessage'),
                    loading: false,
                })
                this.props.updateVendorPO(res.record.file)
            } else {
                this.setState({
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    updateVendorPOItems = (vendorPOItems, file) => {
        let newVendorPO = { ...this.state.vendorPO }

        newVendorPO.items = vendorPOItems;

        if(file && file.id){
            this.props.updateFile(file)
        }
        this.setState({
            vendorPO: newVendorPO
        })
    }

    confirmVoidVendorPO = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    cancelConfirmationVoid = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    voidVendorPO = () => {
        this.setState({
            isVoid: true
        }, ()=> {
            this.saveVendorPO()
        })
    }


    render() {
        const { classes, open } = this.props,
            {   poDeliveryDate,
                poDate,
                poNumber,
                apiErrorMessage,
                poComment,
                deliveryAddress,
                loading,
                validating,
                vendorPO,
                openConfirmationDialog
            } = this.state;

        if(!vendorPO) return null

        return (
            <GlobalParametersContext.Consumer>
                {({ deliveryAddresses }) =>
                    <div>
                        <Dialog
                            maxWidth={'sm'}
                            fullWidth={true}
                            TransitionComponent={SlideUp}
                            aria-labelledby="ClientPO-form"
                            open={open}
                        >
                            <DialogTitle id="ClientPO-form" disableTypography={true}>
                                {vendorPO.fileId ? `${i18n.t('quotes.preparing.file')} ${vendorPO.fileId} - ` : ''}{i18n.t('quotes.pos.poDetails.editVendorPOTitle')} {vendorPO.poNumber}
                                <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close" className={classes.editClientPOForm__title__closeButton}>
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent className={classes.vendorPOForm__dialog__content}>
                                <CustomMessages
                                    type={'error'}
                                    message={apiErrorMessage}
                                    clearMessage={() => this.setState({
                                        apiErrorMessage: null
                                    })}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                className: classes.po__form__input
                                            }}
                                            onChange={event => {
                                                this.setState({
                                                    poNumber: event.target.value
                                                });
                                            }}
                                            error={validating && poNumber === ''}
                                            value={poNumber}
                                            className={classes.po__form__textField}
                                            id="vendorPoNumber"
                                            type="text"
                                            variant="outlined"
                                            label={i18n.t('quotes.pos.poDetails.vendorPoNumber')}
                                        />

                                        <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                            <InlineDatePicker
                                                variant="outlined"
                                                label={i18n.t('quotes.pos.poDetails.poDate')}
                                                value={poDate}
                                                format="MMM DD YYYY"
                                                InputProps={{
                                                    className: classes.po__form__date__input
                                                }}
                                                className={classes.po__form__date}
                                                styles={customStyles}
                                                onChange={this.handleDateChange}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                            <InlineDatePicker
                                                variant="outlined"
                                                label={`${i18n.t('quotes.pos.poDetails.eta')} (+ ${vendorPO.vendorQuoteDeliveryDays} ${i18n.t('quotes.pos.poDetails.quoteDeliveryDays')})  `}
                                                value={poDeliveryDate}
                                                minDate={(new Date(poDate)).setDate((new Date(poDate)).getDate() + vendorPO.vendorQuoteDeliveryDays)}
                                                InputProps={{
                                                    className: classes.po__form__date__input
                                                }}
                                                format="MMM DD YYYY"
                                                error={validating && poDeliveryDate === null}
                                                className={classes.po__form__date}
                                                styles={customStyles}
                                                onChange={this.handleDeliveryDateChange}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={8} md={8}>
                                        <FormControl variant="outlined" style={{ marginTop: '8px'}}>
                                            <InputLabel htmlFor="po_delivery_address">
                                                {i18n.t('quotes.pos.poDetails.deliveryAddress')}
                                            </InputLabel>
                                            <Select required
                                                native
                                                className={classes.po__form__select}
                                                value={deliveryAddress ? deliveryAddress.id : 0}
                                                onChange={e => {
                                                    if (e.target.value !== 0) {
                                                        this.setState({
                                                            deliveryAddress: deliveryAddresses.find(d => d.id === parseInt(e.target.value))
                                                        });
                                                    } else {
                                                        this.setState({
                                                            deliveryAddress: null
                                                        });
                                                    }
                                                }}
                                                input={
                                                    <OutlinedInput required
                                                        name="po_delivery_address"
                                                        labelWidth={60}
                                                        id="po_delivery_address"
                                                    />
                                                }
                                            >
                                                <option value={0}>{'None'}</option>
                                                {deliveryAddresses && deliveryAddresses.map((d =>
                                                    <option key={d.id} value={d.id}>{d.name}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {deliveryAddress && deliveryAddress.id &&
                                            <div>
                                                <br />
                                                <Typography variant='body2'>{deliveryAddress.contactPerson || ''}</Typography>
                                                <Typography variant='body2' noWrap>{deliveryAddress.address1 || ''}</Typography>
                                                <Typography variant='body2' noWrap>{deliveryAddress.address2 || ''}</Typography>
                                                <Typography variant='body2'>{`${deliveryAddress.city || ''}, ${deliveryAddress.state || ''}. ${deliveryAddress.zipCode || ''}`}</Typography>
                                            </div>
                                        }
                                    </Grid>
                                
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label={i18n.t('quotes.pos.poDetails.poComments')}
                                            multiline
                                            rowsMax={8}
                                            rows={2}
                                            fullWidth
                                            className={classes.po__textField__comment}
                                            variant="outlined"
                                            value={poComment}
                                            onChange={event => {
                                                this.setState({
                                                    poComment: event.target.value
                                                });
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <VendorPOItems
                                            updateVendorPOItems={this.updateVendorPOItems}
                                            vendorPO={vendorPO}
                                            infoEditable={true}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: vendorPO.status.id <= 2 && vendorPO.poNumber ? 'space-between': 'flex-end'}}>
                                {(vendorPO && vendorPO.status.id <= 2 && vendorPO.poNumber) && 
                                    <Button
                                        className={classes.subaction__bar__actionButton__void}
                                        onClick={this.confirmVoidVendorPO}>
                                        {i18n.t('quotes.pos.poDetails.voidPO')}
                                    </Button>
                                }
                                <div className={classes.button__wrapper}>
                                    {this.state.showRequiredFieldsError && (
                                        <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '5px' }}>
                                            <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                        </Grid>
                                    )}
                                    <div className={classes.button__wrapper}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            onClick={this.saveVendorPO}>
                                            {i18n.t('quotes.pos.poDetails.saveBtn')}
                                        </Button>
                                        {loading && <CircularProgress className={classes.buttonSpinner} size={24} />}
                                    </div>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <ConfirmationDialog
                            handleClose={this.cancelConfirmationVoid}
                            onConfirm={this.voidVendorPO}
                            message={i18n.t('quotes.pos.vendorPOs.confirmation.confirmVoidVendorPOMessage')}
                            cancelLabel={i18n.t('quotes.pos.vendorPOs.confirmation.cancelVoid')}
                            confirmLabel={i18n.t('quotes.pos.vendorPOs.confirmation.confirmVoidLabel')}
                            open={openConfirmationDialog}
                        />

                    </div>
                }
            </GlobalParametersContext.Consumer>
        )
    }
}

export default withStyles(styles)(VendorPOForm);