import React, { Component } from 'react'
import config from '../config';
import {
    Typography,
} from '@material-ui/core'
export default class AppVersion extends Component {
    render() {
        return (
            <Typography variant='caption' color='textSecondary'>
                Version: {config.appVersion}
            </Typography>
        )
    }
}
