import React, { Component } from 'react'
import {
    Button,
    Link,
    withStyles
} from '@material-ui/core'
import i18n from 'i18next';
import { ClientRFQForm } from '.'

const style = theme => (
    {
        rfq__editLink: theme.customLink,
        print__edit__buttons__container: {
            position: 'absolute',
            zIndex: 2,
            right: theme.spacing(4),
            top: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                position: 'unset',
                padding: '16px 16px 0'
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                justifyContent: 'space-between',
            }
        }
    }
)



class ManageClientRfq extends Component {
    state = {
        showQuotePopup: false,
        fileNoteTitle: '',
        validateForm: false,
        fileNoteContent: '', 
        apiErrorMessage: null, 
        saving: false
    }
    handleCreateNewQuote = () => {
        this.setState({
            showQuotePopup: true,
        })
    }
     handleEditQuote = () => {
        this.setState({
            showQuotePopup: true,
        })
    }
    closeCreateNewQuote = () => {
        this.setState({
            showQuotePopup: false,
        })
        this.props.onClose && this.props.onClose()
    }

    handleCreateRFQ = (file) => {
        this.closeCreateNewQuote();
        this.props.addNewRFQ && this.props.addNewRFQ(file);
    }


    handleUpdateRFQ = (file) => {
        this.closeCreateNewQuote();
        this.props.updateFile && this.props.updateFile(file);
    }

    afterSaveNote=()=>{
        this.setState({
            showQuotePopup: true
        })
    }

    render() {
        const { file, addNewRFQ, openEdit, editable, classes } = this.props,
            { showQuotePopup } = this.state;

        return (
            <>
                {addNewRFQ && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleCreateNewQuote}
                    >
                        {i18n.t("quotes.newQuote")}
                    </Button>
                )}

                {editable &&
                    <div className={classes.print__edit__buttons__container}>
                        <Link className={classes.rfq__editLink}
                            variant='button'
                        onClick={this.handleEditQuote}
                        >
                            {i18n.t('quotes.requestedByClient.editRFQ')}
                        </Link>
                    </div>
                }

                <ClientRFQForm
                    open={showQuotePopup || (openEdit ? true : false)}
                    updateFile={this.props.updateFile}
                    addNewRFQ={this.handleCreateRFQ}
                    file={file}
                    handleClose={this.closeCreateNewQuote}
                />

            </>
        )
    }
}

export default withStyles(style)(ManageClientRfq);
