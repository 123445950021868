import {
    SET_EVENTS,
    ADD_EVENT,
    DELETE_EVENT,
    UPDATE_EVENT
} from '../actions/eventsActions'


function events(state = [], action) {
    let index = null;
    switch (action.type) {
        case SET_EVENTS:
            if (action.events) {
                return [...action.events]
            }
            return state;
        case ADD_EVENT:
            return [
                {
                    ...action.event
                },
                ...state
            ].map(event => {
                return {
                    ...event,
                    start: event.from,
                    end: event.to,
                    color: event.user ? event.user.color : '#ccc',
                    text: event.isVisit ? event.client.name : event.title,
                    lastUpdate: new Date()
                }
            })
        case UPDATE_EVENT:
            index = state.findIndex(c => c.id === action.event.id);
            return [
                ...state.slice(0, index),
                {
                    ...action.event,
                    start: action.event.from,
                    end: action.event.to,
                    color: action.event.user.color,
                    text: action.event.isVisit ? action.event.client.name : action.event.title,
                    lastUpdate: new Date()
                },
                ...state.slice(index + 1)
            ]
        case DELETE_EVENT:
            return state.filter(c => c.id !== action.event.id)
        default:
            return state
    }
}
export default events