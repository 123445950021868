import React from 'react'

import {
    TextField,
    Button,
    InputAdornment,
    Dialog,
    Grid,
    CircularProgress,
    DialogContent,
    DialogActions,
    DialogTitle,
    withStyles,
    Typography
} from '@material-ui/core'
import i18n from 'i18next'
import API from '../../../lib/api'
import { ConfirmationDialog } from '../../general'
import { NumberFormatInputCustom, CustomMessages } from '../../general'
import { SlideUp } from '../../general/Transitions'

const styles = theme => ({
    vendor__quote__items__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        marginTop: theme.spacing(2),
        border: `solid thin ${theme.palette.grey[300]}`
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main,
    },
    vendor__quote__items__table__quantity: {
        color: theme.palette.text.secondary
    },
    vendor__form__input: {
        fontSize: '9pt'
    },
    vendorQuoteItems__closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        position: 'relative'
    },
    clientRFQ__item__form__input: {
        fontSize: '0.8em'
    },
    clientRFQ__item__form__quantity__input: {
        fontSize: '0.9em',
    },
    clientRFQ__item__form__textField__comments: {
        margin: `${theme.spacing()}px 0`,
        width: '100%'
    },
    clientRFQ__item__form__textField: {
        margin: `${theme.spacing()}px 0`
    },
    clientRFQ__item__form__textField__quantity: {
        margin: `${theme.spacing()}px 0`
    },
    clientRFQ__item__form__textField__unit: {
        margin: `${theme.spacing()}px 0`
    },
    vendor__form__removeItem__button: {
        minWidth: '95px',
        width: '95px',
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        margin: '0 0 5px 0px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    vendor__form__includeItem__button: {
        minWidth: '105px',
        width: '95px',
        backgroundColor: 'white',
        color: theme.palette.green[100],
        textDecoration: 'underline',
        margin: '0 0 5px 0px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    vendor__form__textField__noEditable: {
        background: '#0000000f'
    },
    dialgoContent: {
        paddingBottom: 0
    },
    vendor__quote__items__table__descriptionResumed: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
})

class VendorQuoteEditItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            apiErrorMessage: null,
            vendorQuoteItems: props.items,
            showEditItemDialog: props.showEditItemDialog,
            selectedItem: props.selectedItem,
            confirmRemoveItem: false,
            showRequiredFieldsError: false,
            validating: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showEditItemDialog: nextProps.showEditItemDialog,
            vendorQuoteItems: nextProps.items,
            selectedItem: nextProps.selectedItem
        })
    }

    removeItem = () => {
        const { vendorQuoteItems, selectedItem } = this.state;

        let index = vendorQuoteItems.findIndex(vq => vq.id === selectedItem.id),
            items = [...vendorQuoteItems];

        items[index].include = false
        this.setState({
            vendorQuoteItems: items
        }, () => {
            this.saveItems()
        })
    }

    includeItem = () => {
        const { vendorQuoteItems, selectedItem } = this.state;

        let index = vendorQuoteItems.findIndex(vq => vq.id === selectedItem.id),
            items = [...vendorQuoteItems];

        items[index].include = true
        this.setState({
            vendorQuoteItems: items
        }, () => {
            this.saveItems()
        })
    }

    saveItems = () => {
        const { vendorQuoteItems, selectedItem } = this.state,
            { vendorQuote, vendorQuoteStatusId } = this.props;

        let index = vendorQuoteItems.findIndex(vq => vq.id === selectedItem.id);

        this.setState({
            validating: true
        })

        if ((vendorQuoteItems[index].unitAmount === '' || !vendorQuoteItems[index].unitAmount) && vendorQuoteStatusId === 2) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        if ((vendorQuoteItems[index].quantity === '' || !vendorQuoteItems[index].quantity) && vendorQuoteStatusId === 2) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            validating: false,
            saving: true,
            showRequiredFieldsError: false
        })

        this.props.onClose()

        let body = {
            id: vendorQuote.id,
            fileId: vendorQuote.fileId,
            vendorId: vendorQuote.vendor.id,
            vendorContactId: vendorQuote.contact.id,
            comments: vendorQuote.comments,
            otherCosts: vendorQuote.otherCosts,
            deliveryDays: vendorQuote.deliveryDays,
            quoteTermsId: vendorQuote.quoteTermsId,
            quoteTermsReference: vendorQuote.quoteTermsReference,
            paymentTermsId: vendorQuote.paymentTermsId,
            items: vendorQuoteItems.map((item, i) => {
                return {
                    id: vendorQuote ? item.id : null,
                    rfqItemId: item.rfqItemId,
                    quantity: item.quantity,
                    unitAmount: item.unitAmount,
                    description: item.description,
                    deliveryDays: item.deliveryDays,
                    references: item.references,
                    include: item.include,
                    comments: item.comments
                }
            })
        }

        API.Quotes.updateVendorRFQ({ body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    confirmRemoveItem: false
                })
                this.props.updateVendorQuoteItems(res.data.record.vendorQuotes.find(vq => vq.id === vendorQuote.id))
                // this.props.onClose()
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }
    close = () => {
        this.setState({
            showEditItemDialog: false
        })
    }
    onExited = () => {
        this.props.onClose();
    }

    render() {
        const { vendorQuoteStatusId, classes, currencySymbol } = this.props,
            { vendorQuoteItems, saving, apiErrorMessage,
                showEditItemDialog, selectedItem, confirmRemoveItem,
                validating, showRequiredFieldsError } = this.state;

        if (!vendorQuoteItems || !selectedItem) return null

        let index = vendorQuoteItems.findIndex(vq => vq.id === selectedItem.id);

        return (
            <div>
                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    TransitionComponent={SlideUp}
                    aria-labelledby="form-items"
                    open={showEditItemDialog}
                    onExited={this.onExited}
                >
                    <DialogTitle disableTypography={true} id="form-items">
                        {i18n.t('quotes.vendorQuoteRequest.editVendorsItems')}
                    </DialogTitle>
                    <DialogContent className={classes.dialgoContent}>
                        <Grid container spacing={1} alignItems='flex-start' justify='flex-start' style={{ marginTop: '16px' }}>
                            <Grid item sm={12} xs={12}>
                                {vendorQuoteStatusId < 3 ?
                                    <TextField
                                        id="itemReference"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.vendor__form__input
                                        }}
                                        fullWidth
                                        autoComplete='off'
                                        onBlur={e => {
                                            const items = [...vendorQuoteItems]
                                            items[index].references = e.target.value
                                            this.setState({
                                                vendorQuoteItems: items
                                            })
                                        }}
                                        className={classes.clientRFQ__item__form__textField}
                                        type="text"
                                        variant="outlined"
                                        defaultValue={selectedItem.references}
                                        label={i18n.t('quotes.vendorQuoteRequest.itemsTable.partNumber')}
                                    />
                                    :
                                    <Typography className={classes.vendor__quote__items__table__itemPartNumber}>
                                        {selectedItem.references}
                                    </Typography>
                                }
                                <Typography color='textSecondary'>
                                    {`${selectedItem.quantity} ${selectedItem.unit}`}
                                </Typography>

                                {vendorQuoteStatusId === 2 &&
                                    <Typography color='textSecondary'>
                                        {selectedItem.description}
                                    </Typography>
                                }
                            </Grid>
                            {vendorQuoteStatusId === 1 &&
                                <Grid item sm={6} xs={12}>
                                    <TextField autoComplete='off'
                                        id="itemDescription"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.vendor__form__input
                                        }}
                                        fullWidth
                                        multiline
                                        rows={'3'}
                                        onBlur={e => {
                                            const items = [...vendorQuoteItems]
                                            items[index].description = e.target.value
                                            this.setState({
                                                vendorQuoteItems: items
                                            })
                                        }}
                                        disabled={vendorQuoteStatusId === 2}
                                        className={`${classes.clientRFQ__item__form__textField} ${vendorQuoteStatusId === 2 ? classes.vendor__form__textField__noEditable : ''}`}
                                        defaultValue={selectedItem.description}
                                        type="text"
                                        variant="outlined"
                                        label={i18n.t('quotes.vendorQuoteRequest.itemsTable.description')}
                                    />
                                </Grid>
                            }
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    autoComplete='off'
                                    id="vendorComments"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.vendor__form__input
                                    }}
                                    fullWidth
                                    multiline
                                    rows={'3'}
                                    onBlur={e => {
                                        const items = [...vendorQuoteItems]
                                        items[index].comments = e.target.value
                                        this.setState({
                                            vendorQuoteItems: items
                                        })
                                    }}
                                    className={classes.clientRFQ__item__form__textField__comments}
                                    type="text"
                                    variant="outlined"
                                    defaultValue={selectedItem.comments}
                                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.comments')}
                                />
                            </Grid>
                            {vendorQuoteStatusId === 1 && selectedItem.include && (
                                <Grid item xs={12}>
                                    <Button
                                        disabled={saving}
                                        className={classes.vendor__form__removeItem__button}
                                        onClick={() => this.setState({
                                            confirmRemoveItem: true
                                        })}>
                                        {i18n.t('quotes.vendorQuoteRequest.removeItem')}
                                    </Button>
                                </Grid>
                            )}

                            {vendorQuoteStatusId === 1 && !selectedItem.include && (
                                <Grid item xs={12}>
                                    <Button
                                        disabled={saving}
                                        className={classes.vendor__form__includeItem__button}
                                        onClick={this.includeItem}>
                                        {i18n.t('quotes.vendorQuoteRequest.includeItem')}
                                    </Button>
                                </Grid>
                            )}

                            {vendorQuoteStatusId === 2 &&
                                <Grid item sm={3} xs={6}>
                                    <TextField required
                                        fullWidth
                                        autoComplete='off'
                                        variant="outlined"
                                        id="vendor_quote_amount"
                                        error={validating && (vendorQuoteItems[index].unitAmount === '' || !vendorQuoteItems[index].unitAmount)}
                                        label={i18n.t('quotes.vendorQuoteRequest.itemsTable.amount')}
                                        name="otherCostAmount"
                                        className={classes.clientRFQ__item__form__textField}
                                        onBlur={(e) => {
                                            const items = [...vendorQuoteItems]
                                            items[index].unitAmount = e.target.value
                                            this.setState({
                                                vendorQuoteItems: items
                                            })
                                        }}
                                        style={{
                                            marginTop: '10px',
                                        }}
                                        defaultValue={selectedItem.unitAmount}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                                            inputComponent: NumberFormatInputCustom,
                                        }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        autoComplete='off'
                                        variant="outlined"
                                        id="vendor_quote_delivery_days"
                                        label={i18n.t('quotes.vendorQuoteRequest.itemsTable.delivery')}
                                        name="otherCostAmount"
                                        className={classes.clientRFQ__item__form__textField}
                                        onBlur={(e) => {
                                            const items = [...vendorQuoteItems]
                                            items[index].deliveryDays = e.target.value
                                            this.setState({
                                                vendorQuoteItems: items
                                            })
                                        }}
                                        style={{
                                            marginTop: '10px',
                                        }}
                                        defaultValue={selectedItem.deliveryDays}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end" >Days</InputAdornment>,
                                            inputComponent: NumberFormatInputCustom,
                                        }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>

                        <ConfirmationDialog
                            handleClose={() => this.setState({
                                confirmRemoveItem: false
                            })}
                            loading={saving}
                            onConfirm={this.removeItem}
                            message={i18n.t('quotes.vendorQuoteRequest.confirmRemoveMessage')}
                            cancelLabel={i18n.t('quotes.vendorQuoteRequest.confirmRemoveCancel')}
                            confirmLabel={i18n.t('quotes.vendorQuoteRequest.confirmRemoveConfirm')}
                            open={confirmRemoveItem}
                        />

                        {showRequiredFieldsError && (
                            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions style={{
                        justifyContent: 'space-between',
                    }}>
                        <Button
                            color='default'
                            variant="outlined"
                            onClick={this.close}>
                            {i18n.t("quotes.quoteRequest.cancelEdit")}
                        </Button>
                        <div className={classes.button__wrapper}>
                            <Button
                                disabled={saving}
                                color="primary"
                                variant="contained"
                                onClick={this.saveItems}
                                className={classes.vendor__form__save__button}>
                                {i18n.t("quotes.vendorQuoteRequest.editItem")}
                            </Button>
                            {saving && <CircularProgress className={classes.buttonSpinner} size={24} />}
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(VendorQuoteEditItems)
