import React from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Grid,
    Button,
    Dialog,
    CircularProgress,
    DialogContent,
    DialogTitle,
    DialogActions,
    withMobileDialog,
} from '@material-ui/core';
import { Close, Warning } from '@material-ui/icons'
import {geolocated} from "react-geolocated";
import Geocode from 'react-geocode';
import i18n from 'i18next';
import {EventEditable, VisitCheckInCheckOut} from '.'
import {SlideUp} from '../../general/Transitions'
import moment from "moment";
import { ConfirmationDialog } from '../../general'
import config from '../../../config';
import API from '../../../lib/api'

const GOOGLE_API_KEY = config.google.mapsAPIKey;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

const styles = theme => ({
    dialog__paper: {
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        minHeight: '560px',
        position: 'relative'
    },
    visit__form__title__closeButton: {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        padding: '5px'
    },
    event__actions__cancelEvent: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        margin: '0 0 5px 5px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
        warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
        event__checkin__button: {
        minWidth: '80px',
        backgroundColor: theme.palette.blue[100],
        color: '#fff'
    },
    buttonSpinner: theme.buttonSpinner,
});


class EventDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            event: props.event,
            openCheckInInfo: false,
            checkMessage: null,
            checkIn: props.event ? props.event.checkIn : null,
            checkOut: props.event ? props.event.checkOut : null,
            kmReference: 0,
            saving: false,
            openVisitReport: false,
            confirmCancelEvent: false
        }
    }
    

    componentWillReceiveProps(nextProps){
        this.setState({
            event: nextProps.event
        })
    }

    updateVisit=(status)=>{
        const { event, checkIn, checkOut } = this.state;

        this.setState({
            saving: true
        })

        let body = {
            ...event,
            clientId: event.client.id,
            clientContactId: event.clientContact.id,
            isPrivate: event.private,
            place: event.place && event.place.address ? event.place.address : null,
            googleAddress: event.place && event.place.googleAddress ? event.place.googleAddress : null,
            placeLat: event.place && event.place.lat ? event.place.lat : null,
            placeLng: event.place && event.place.lng ? event.place.lng : null,
            checkInAddress: checkIn ? checkIn.address : null,
            checkInLat: checkIn ? checkIn.lat : null,
            checkInLng: checkIn ? checkIn.lng : null,
            checkInDateTime: checkIn ? checkIn.dateTime: null,
            checkOutAddress: checkOut ? checkOut.address : null,
            checkOutLat: checkOut ? checkOut.lat : null,
            checkOutLng: checkOut ? checkOut.lng : null,
            checkOutDateTime: checkOut ? checkOut.dateTime: null,
            visit: {
                ...event.visit,
                status
            }
        }

        API.Events.updateEvent({ event: body }).then(res => {
            if (res.data) {
                this.setState({
                    saving: false,
                    event: res.data.record,
                    confirmCancelEvent: false
                })
                this.props.updateEvent && this.props.updateEvent(res.data.record)                    
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
        
    }

    rad=(x)=>{
        return x * Math.PI / 180
    }

    getDistance = (lat1,lon1,lat2,lon2) => {
        var R = 6378.137; //Radio de la tierra en km
        var dLat = this.rad( lat2 - lat1 );
        var dLong = this.rad( lon2 - lon1 );
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(this.rad(lat1)) * Math.cos(this.rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        return d.toFixed(3);
    }

    calculateCheckIn =()=>{
        const {isGeolocationAvailable, 
                isGeolocationEnabled, 
                coords, geoLocalization} = this.props,
                { event } = this.state;

        let placeLat = geoLocalization ? geoLocalization.geometry.location.lat : event && event.place && event.place.lat ? event.place.lat : null,
            placeLng = geoLocalization ? geoLocalization.geometry.location.lng : event && event.place && event.place.lng ? event.place.lng : null;

        let message= null

        if(!isGeolocationAvailable){
            message = i18n.t('visits.form.noGeolocationAvailable')
        }
         
        if(!isGeolocationEnabled)  (
            message = i18n.t('visits.form.noGeolocationEnabled')
        ) 

        if(message){
            this.setState({
               checkMessage: message
            })
            return
        }

        if(coords){
            let km = this.getDistance(coords.latitude, coords.longitude, placeLat, placeLng)

            if(moment.utc(event.from).diff(moment(new Date()), 'hours') > 24 || km > 10) {
                this.setState({
                    kmReference: km,
                    checkIn: {
                        lat: coords.latitude,
                        lng: coords.longitude
                    }
                }, ()=>{
                    this.setState({
                        openCheckInInfo: true
                    })
                })
            } else {
                Geocode.fromLatLng(coords.latitude, coords.longitude).then(
                    response => {
                        if (response.status === 'OK') {
                            const address = response.results[0].formatted_address;

                            this.setState({
                                checkIn: {
                                    address: address,
                                    dateTime: new Date(),
                                    lat: coords.latitude,
                                    lng: coords.longitude
                                }
                            })
                            this.updateVisit(event.visit.status.id)
                            
                        } else {
                            this.setState({
                                checkIn: {
                                    address: null,
                                    lat: coords.latitude,
                                    lng: coords.longitude
                                }
                            })
                        }
                    },
                    error => {
                        this.setState({
                            checkIn: {
                                address: null,
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        })
                    }
                );
            }
        }
    }

    setCheckOut=()=>{
        const { isGeolocationAvailable, isGeolocationEnabled, coords } = this.props;

        let message= null
        if(!isGeolocationAvailable){
            message = i18n.t('visits.form.noGeolocationAvailable')
        }
         
        if(!isGeolocationEnabled)  (
            message = i18n.t('visits.form.noGeolocationEnabled')
        ) 

        if(message){
            this.setState({
               checkMessage: message
            })
            return
        }
        
        if(coords){
            Geocode.fromLatLng(coords.latitude, coords.longitude).then(
                response => {
                    if (response.status === 'OK') {
                        const address = response.results[0].formatted_address;

                        this.setState({
                            checkOut: {
                                address: address,
                                dateTime: new Date(),
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        }, ()  => {
                            this.updateVisit(2)
                        })
                    } else {
                        this.setState({
                            checkOut: {
                                address: null,
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        })
                    }
                },
                error => {
                    this.setState({
                        checkOut: {
                            address: null,
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    })
                }
            );
        }
    }

    setVisitReport=()=>{
        this.setState({
            openVisitReport: true
        })
    }

    cancelVisit=()=>{
        this.updateVisit(3)
    }


    render() {
        const { classes, width, logedInUser } = this.props,
              { event, openCheckInInfo, openVisitReport,
                checkMessage, checkIn, kmReference, saving,
                confirmCancelEvent } = this.state;

        if(!event) return null

        let isOwn = logedInUser === parseInt(event.user.id)

        return (
            <Dialog
                classes={{
                    paper: classes.dialog__paper
                }}
                fullScreen={width === 'xs' ? true : false}
                open={this.props.open}
                maxWidth='sm'
                fullWidth={true}
                scroll={'paper'}
                TransitionComponent={SlideUp}
            >                    
                <DialogTitle disableTypography={true}>
                    <Typography variant='body1' color="inherit">
                        {event.isVisit ? i18n.t('visits.details.visit') : i18n.t('visits.details.title')}
                    </Typography>
                    <IconButton color="inherit"
                        onClick={this.props.handleClose}
                        aria-label="Close" className={classes.visit__form__title__closeButton}>
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent >
                    <EventEditable
                        isOwn={isOwn}
                        event={event}
                        openVisitReport={openVisitReport}
                        updateEvent={this.props.updateEvent}
                    />

                     { checkMessage && 
                        <Grid item xs={12}>
                            <Typography variant='body2' color='textSecondary' style={{display: 'flex'}}>
                                <Warning className={classes.warning__message__icon}/>
                                {checkMessage}
                            </Typography>
                        </Grid>
                    }

                </DialogContent>
                <DialogActions >
                    <Grid container spacing={0} justify={'space-between'} alignItems={'center'}>
                        {((event.visit.status.id && event.visit.status.id === 1) || !event.visit.status.id) && (
                            <Button
                                className={classes.event__actions__cancelEvent}
                                onClick={()=>{
                                    this.setState({
                                        confirmCancelEvent: true
                                    })
                                }}>
                                {i18n.t('visits.cancelEvent')}
                            </Button>
                        )}

                        {event.visit.status.id && (event.visit.status.id === 3 || event.visit.status.id === 2) && (
                            <Button
                                className={classes.event__actions__cancelEvent}
                                onClick={()=>this.updateVisit(1)}>
                                {i18n.t('visits.reschedule')}
                            </Button>
                        )}

                        {!confirmCancelEvent && event.isVisit === 1 && event.visit.status.id !== 3 && event.visit.status.id !== 2 && (!event.checkIn || !event.checkIn.address) ?
                            <Button
                                size='small'
                                variant="contained"
                                disabled={saving}                                
                                className={classes.event__checkin__button}
                                onClick={this.calculateCheckIn}>

                                {i18n.t("visits.form.checkIn")}
                                {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                            </Button>
                        :
                            <div>
                                {!confirmCancelEvent && event.isVisit === 1 && event.visit.status.id !== 3 && event.visit.status.id !== 2 && (!event.visit.results || event.visit.results === "" || !event.visit.nextSteps || event.visit.nextSteps === '') && 
                                    <Button
                                        size='small'
                                        variant="contained"
                                        disabled={saving}                                        
                                        style={{marginRight: !event.checkOut || !event.checkOut.address ? '16px' : 0}}
                                        className={classes.event__checkin__button}
                                        onClick={this.setVisitReport}>

                                        {i18n.t("visits.form.visitReport")}
                                    </Button>
                                }

                                {!confirmCancelEvent && event.isVisit === 1 && event.visit.status.id !== 3 && event.visit.status.id !== 2 && (!event.checkOut || !event.checkOut.address) &&
                                    <Button
                                        size='small'
                                        variant="contained"
                                        disabled={saving}
                                        className={classes.event__checkin__button}
                                        onClick={this.setCheckOut}>

                                        {i18n.t("visits.form.checkOut")}
                                        {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </Button>
                                }
                            </div>
                        }
                    </Grid>
                </DialogActions>

                <ConfirmationDialog
                    handleClose={()=>{
                        this.setState({
                            confirmCancelEvent: false
                        })
                    }}
                    loading={saving}
                    onConfirm={this.cancelVisit}
                    message={i18n.t('visits.form.confirmCancelEventInMessage')}
                    cancelLabel={i18n.t('visits.form.cancelLabel')}
                    confirmLabel={i18n.t('visits.form.confirmCancelLabel')}
                    open={confirmCancelEvent}
                />

                <VisitCheckInCheckOut
                    event={event}
                    checkIn={checkIn}
                    handleClose={()=>{
                        this.setState({
                            openCheckInInfo: false
                        })
                    }}
                    kmReference={kmReference}
                    openCheckInInfo={openCheckInInfo}
                />

            </Dialog>
        )
    }
}

export default geolocated()(withMobileDialog()(withStyles(styles)(EventDetails)));
