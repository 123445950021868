import React from 'react';
import {
    Grid,
    TextField, Button,
    CircularProgress,
    withStyles,
    Typography,
    Link,
    FormGroup,
    FormControlLabel,
    Breadcrumbs,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    withMobileDialog,
    Checkbox,
    MenuItem,
} from '@material-ui/core';
import {connect} from 'react-redux'
import { Warning, 
        CenterFocusStrong,
        Room, Today,
        People} from '@material-ui/icons'
import i18n from 'i18next';
import moment from 'moment'
import { DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import {geolocated} from "react-geolocated";
import Geocode from 'react-geocode';
import MomentUtils from '@date-io/moment';
import {SlideUp} from '../../general/Transitions'
import {ConfirmationDialog, GoogleMap} from '../../general'
import {EventSelectClient} from '.'
import API from '../../../lib/api'
import config from '../../../config';

const GOOGLE_API_KEY = config.google.mapsAPIKey;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

const styles = theme => ({
    dialog__paper: {
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        maxWidth: '500px',
        minHeight: '580px'
    },
    input: {
        padding: theme.spacing()
    },
    textField: {
        width: '100%',
        margin: `${theme.spacing()}px 0`
    },
    visits__form_date__input: {
        fontSize: 'unset'
    },
    visits__form__date: {
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        margin: `${theme.spacing()}px 0`,
        fontSize: '0.8em'
    },
    visit__form__title__closeButton: {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        padding: '5px'
    },
    check__closeButton: {
        padding: '4px'
    },
    button__wrapper: {
        margin: `${theme.spacing()}px 0`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    visits__form__menuItem: {
        fontSize: '1em'
    },
    customLink: {
        ...theme.customLink,
        fontFamily: theme.typography.fontFamily,
    },
    buttonSpinner: theme.buttonSpinner,
    eventForm__actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    event__status__icon__scheduled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    event__status__icon__canceled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    event__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.green[100],
    },
    action__bar__currentstatus: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing()
    },
    icon: {
        fontSize: '11pt',
        marginRight: '5px',
        marginBottom: '-3px'
    },
    event__form__input: {
        fontSize: '0.9em',
    },
    event__form__textField: {
        width: '100%',
        margin: `${theme.spacing()}px 0`,
    },
    event__form__menuItem: {
        fontSize: '1em'
    },
    eventForm__select: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        fontFamily: theme.typography.fontFamily
    },
    eventForm__otherContacts__select: {
        zIndex: 10,
    },
    map__container: {
        border: 'solid thin #000',
        height: '320px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    check__display: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    check__display__container: {
        background: '#e6e6e6',
        width: '100%',
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        display: 'flex',
        justifyContent: 'space-between'
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    link: {
        display: 'flex',
    },
    breadcrumbs__icon: {
        color: theme.palette.blue[100],
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    breadcrumbs__icon__disabled:{
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
});

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

const options = [
    {
        value: 'New Client'
    },
    {
        value: 'Sales Visit'
    },
    {
        value: 'Followup'
    },
    {
        value: 'Technical Visit'
    }
]


class EventForm extends React.Component {

    constructor(props) {
        super(props);

        this.inputs = {
            title: props.event ? props.event.title : '',
            description: props.event ? props.event.description : '',
            objectives: props.event && props.event.visit.id ? props.event.visit.objectives : '',
            topics: props.event && props.event.visit.id ? props.event.visit.topics : ''
        };

        this.state = {
            showRequiredFieldsError: false,
            isPrivate: false, 
            isVisit: false,
            from: new Date(), 
            to: new Date(), 
            eventClient:  null,
            eventClientContact:  null,
            type:  options[0].value,
            otherContacts:  [],
            place: {},
            validAddress: false,
            validating: false,
            saving: false,
            openSelectClient: false,
            geoLocalization: null,
            fetching: false,
            placeMessage: null,
            currentTab: 0,
            lastStep: 0
        };
    }

    
    manageEvent = () => {
        const { title, description, objectives, topics } = this.inputs,
            { isPrivate, from, to, eventClient, place,
            eventClientContact, isVisit, type, geoLocalization } = this.state;

        this.setState({
            validating: true
        })

        if(!title || title === '' || (isVisit && !type)){
            this.setState({
                showRequiredFieldsError: true
            })
            return 
        }

        this.setState({
            validating: false,
            loading: true
        })

        let body = {
            id: null,
            isPrivate,
            title,
            description,
            isVisit,
            from: moment(from).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(to).format('YYYY-MM-DD HH:mm:ss'),
            place: place.address ? place.address : '',
            clientId: eventClient ? eventClient.id : null,
            clientContactId: eventClientContact ? eventClientContact.id : null,
            googleAddress: geoLocalization ? geoLocalization.formatted_address : null,
            placeLat: geoLocalization ? geoLocalization.geometry.location.lat : null,
            placeLng: geoLocalization ? geoLocalization.geometry.location.lng : null,
            visit: null
        }

        if (isVisit){
            body.visit = {
                objectives,
                topics,
                type,
                status: 1
            }
        }

        API.Events.createEvent({ event: body }).then(res => {
            if (res.data) {
                this.setState({
                    loading: false
                })
                this.props.addNewEvent && this.props.addNewEvent(res.data.record)
                this.props.handleClose()
            } else {
                this.setState({
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    eventIsPrivate = (e) => {
        this.setState({
            isPrivate: e.target.checked
        });
    }

    handleFromDateChange = date => {
        this.setState({
            from: date
        });
    }

    handleToDateChange = date => {
        this.setState({
            to: date
        });
    }

    confirmDeleteVisist = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    cancelConfirmationDelete = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    deleteEvent = () => {
        const { event } = this.props

        this.setState({
            loading: true
        })

        API.Events.deleteEvent({ eventId: event.id }).then(res => {
            if (res.success) {
                this.setState({
                    openConfirmationDialog: false,
                    loading: false
                })

                this.props.deleteEvent && this.props.deleteEvent(event.id)
                this.props.handleClose({deleted: true})
            } else {
                this.setState({
                    openConfirmationDialog: false,
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    setClientAndContact=(client, clientContact)=>{
        this.setState({
            place: {
                address: `${client.address.address1} ${client.address.address2 ? client.address.address2 : ''}`
            },
            openSelectClient: false,
            eventClient: client && client.id ? client : null,
            eventClientContact: clientContact && clientContact.id ? clientContact : null
        }, ()=>{
            this.validateAddress()
        })
    }

    handleChangeSelect = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    rad=(x)=>{
        return x * Math.PI / 180
    }

    getDistance = (lat1,lon1,lat2,lon2) => {
        var R = 6378.137; //Radio de la tierra en km
        var dLat = this.rad( lat2 - lat1 );
        var dLong = this.rad( lon2 - lon1 );
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(this.rad(lat1)) * Math.cos(this.rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        return d.toFixed(3);
    }

    validateAddress = () => {
        const { place, checkIn } = this.state;

        this.setState({
            fetching: true
        })

        Geocode.fromAddress(place.address).then(
            response => {
                if (response.status === 'OK') {

                    let km = 0 
                    if(checkIn && checkIn.lat && checkIn.lng){
                        km = this.getDistance(checkIn.lat, checkIn.lng, response.results[0].geometry.location.lat, response.results[0].geometry.location.lng)
                    }

                    this.setState({
                        showRequiredFieldsError: false,
                        checkMessage: km > 10 ? i18n.t('visits.form.mayorDistance') : null,
                        geoLocalization: response.results[0],
                        validAddress: place.address,
                        fetching: false,
                        placeMessage: null
                    })

                } else {
                    this.setState({
                        checkMessage: null,
                        geoLocalization: null,
                        validAddress: false,
                        fetching: false,
                        placeMessage: null
                    })
                }
            },
            error => {
                //Error buscando la direccion.
                console.error(error);
                this.setState({
                    geoLocalization: null,
                    checkMessage: null,
                    validAddress: false,
                    fetching: false,
                    placeMessage: i18n.t('visits.form.invalidAddress')
                })
            }
        );
    };

    onExit=()=>{
        this.inputs = {
            title: '',
            description: '',
            objectives: '',
            topics: ''
        };

        this.setState({
            isPrivate: false, 
            isVisit:  false,
            from:  new Date(), 
            to: new Date(), 
            eventClient: null,
            eventClientContact: null,
            status: 1,
            type: '',
            place: {},
            validAddress: false,
            geoLocalization: null,
            currentTab: 0,
            lastStep: 0
        });
    }

    changeTab = (value) => {
        this.setState({
            currentTab: value
        })
    }

    createVisit=()=>{
        this.setState({
            validating: true,
        })

        const { title } = this.inputs

        if(!title){
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            isVisit: true,
            validating: false,
            showRequiredFieldsError: false,
            lastStep: this.setState.lastStep > 1 ? this.setState.lastStep : 1
        })
        this.changeTab(1)
    }

    validClientAndContact=()=>{
        const { eventClient, eventClientContact } = this.state

        if(!eventClient || (eventClient && !eventClient.id) || (eventClient && eventClient.id && (!eventClientContact || (eventClientContact && !eventClientContact.id)) )) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            showRequiredFieldsError: false,
            lastStep: this.setState.lastStep > 2 ? this.setState.lastStep : 2
        })
        this.changeTab(2)
    }

    validVisitAddress=()=>{
        this.setState({
            validating: true
        })

        const { geoLocalization, validAddress } = this.state

        if(!geoLocalization || !validAddress){
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            validating: false,
            showRequiredFieldsError: false,
            lastStep: this.setState.lastStep > 3 ? this.setState.lastStep : 3
        })

        this.changeTab(3)
    }


    render() {
        const { event, classes, width } = this.props;
        const { isPrivate, from, to, loading, isVisit,
                validating, openConfirmationDialog, type, place, 
                eventClientContact, eventClient, placeMessage, lastStep,
                geoLocalization, validAddress, fetching, currentTab } = this.state;

        return (
            <Dialog
                classes={{
                    paper: classes.dialog__paper
                }}
                width={'xs'}
                fullScreen={width === 'xs' ? true : false}
                fullWidth={true}
                open={this.props.open}
                onExit={this.onExit}
                TransitionComponent={SlideUp}
            >

                <DialogTitle disableTypography={true}>
                    <Typography variant='body1' color="inherit">
                        {isVisit ? i18n.t('visits.formTitleNewVisit') : i18n.t('visits.formTitleNewEvent')}
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    { lastStep > 0 &&   
                        <Grid container spacing={1} style={{marginTop: '8px'}}>
                            <Grid item xs={12} style={{borderBottom: '1px solid #e0e0e0', paddingBottom: '16px'}}>
                                <Breadcrumbs aria-label="breadcrumb" separator="›">
                                    <Link color="textSecondary" 
                                        onClick={()=>this.changeTab(0)} className={classes.link}>
                                        <Today className={classes.breadcrumbs__icon__disabled} />
                                        {i18n.t("visits.form.visit")}   
                                    </Link>
                                    
                                    {lastStep === 1 &&
                                        <Typography onClick={()=>this.changeTab(1)}  color="textPrimary" className={classes.link}>
                                            <People className={classes.breadcrumbs__icon} />
                                            {i18n.t("visits.form.clientAndContact")}
                                        </Typography>
                                    }

                                    {lastStep >= 2 &&
                                        <Link color="textSecondary" onClick={()=>this.changeTab(1)} className={classes.link}>
                                            <People className={classes.breadcrumbs__icon__disabled} />
                                            {i18n.t("visits.form.clientAndContact")}    
                                        </Link>
                                    }

                                    {lastStep === 2 &&
                                        <Typography color="textPrimary" onClick={()=>this.changeTab(2)} className={classes.link}>
                                            <Room className={classes.breadcrumbs__icon} />
                                            {i18n.t("visits.form.place")}
                                        </Typography>
                                    }

                                    {lastStep >= 3 &&
                                        <Link color="textSecondary" onClick={()=>this.changeTab(2)} className={classes.link}>
                                            <Room className={classes.breadcrumbs__icon__disabled} />
                                            {i18n.t("visits.form.place")}   
                                        </Link>
                                    }

                                    {lastStep === 3 &&
                                        <Typography color="textPrimary" onClick={()=>this.changeTab(3)} className={classes.link}>
                                            <CenterFocusStrong className={classes.breadcrumbs__icon} />
                                            {i18n.t("visits.form.visitDetail")}
                                        </Typography>
                                    }
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    }

                    {/******** EVENT ********/}
                    <Grid container spacing={1} style={{marginTop: '8px', display: currentTab === 0 ? 'flex' : 'none'}}>
                        <Grid item xs={12}>
                            <TextField autoComplete='off' fullWidth required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                                className={classes.textField}
                                onChange={event => {
                                    this.inputs.title = event.target.value;
                                }}
                                id="title"
                                type="text"
                                variant="outlined"
                                value={this.inputs.title}
                                error={validating && !this.inputs.title}
                                label={i18n.t("visits.form.title")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <DateTimePicker
                                    variant="outlined"
                                    label={i18n.t('visits.form.fromDate')}
                                    value={from}
                                    disablePast
                                    InputProps={{
                                        className: classes.visits__form_date__input
                                    }}
                                    format="MMM DD hh:mm a"
                                    className={classes.visits__form__date}
                                    styles={customStyles}
                                    onChange={this.handleFromDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <DateTimePicker
                                    variant="outlined"
                                    label={i18n.t('visits.form.toDate')}
                                    value={from > to ? from : to}
                                    InputProps={{
                                        className: classes.visits__form_date__input
                                    }}
                                    format="MMM DD hh:mm a"
                                    minDate={from}
                                    className={classes.visits__form__date}
                                    styles={customStyles}
                                    onChange={this.handleToDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField autoComplete='off' fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.multilineInput
                                }}
                                className={classes.textField}
                                onChange={event => {
                                    this.inputs.description = event.target.value;
                                }}
                                multiline
                                rowsMax='3'
                                rows='2'
                                id="description"
                                type="text"
                                variant="outlined"
                                value={this.inputs.description}
                                label={i18n.t("visits.form.description")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroup style={{padding: '0 4px'}}>
                                <FormControlLabel
                                    className={classes.user__form__user_active_field}
                                    control={
                                        <Checkbox
                                            checked={isPrivate}
                                            onChange={this.eventIsPrivate}
                                            value="isPrivate"
                                            color="primary"
                                        />
                                    }
                                    label={i18n.t("visits.form.isPrivate")}
                                />
                            </FormGroup>
                        </Grid>
                        { 
                            // event &&
                            // <div style={{position: 'absolute', bottom: '100px', left: 0}}>
                            //     <Button
                            //         className={classes.eventForm__actionButton__delete}
                            //         onClick={this.confirmDeleteVisist}>
                            //         {i18n.t('visits.details.delete')}
                            //     </Button>
                            // </div>
                        }
                    </Grid>



                    {/********* IS A VISIT **********/}    

                    <Grid container spacing={1} style={{marginTop: '16px'}}>
 
                        {/********* Client ********/}
                        <Grid item xs={12} style={{marginTop: '8px', display: currentTab === 1 ? 'flex' : 'none'}}>
                            <EventSelectClient
                                selectClient={this.setClientAndContact}
                                eventClientId={eventClient ? eventClient.id : null}
                                eventClientContactId={eventClientContact ? eventClientContact.id :  null}
                            />
                        </Grid>

                        {/******** Address ********/}
                        <Grid item xs={12} style={{marginTop: '8px', display: currentTab === 2 ? 'flex' : 'none'}}>
                            <Grid container item spacing={1}>
                                <Grid item xs={10}>
                                    <TextField autoComplete='off' 
                                        fullWidth required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.input
                                        }}
                                        error={validating  && (!place || !place.address)}
                                        className={classes.textField}
                                        onChange={event => {
                                            this.setState({
                                                place: {
                                                    ...place,
                                                    address: event.target.value
                                                }
                                            })
                                            if(validAddress && event.target.value !== validAddress){
                                                this.setState({
                                                    fetching: false,
                                                    checkMessage: null,
                                                    geoLocalization: null,
                                                    validAddress: false,
                                                    placeMessage: null
                                                })
                                            }

                                            if(event.target.value === ''){
                                                this.setState({
                                                    fetching: false,
                                                    checkMessage: null,
                                                    geoLocalization: null,
                                                    validAddress: false,
                                                    placeMessage: null
                                                })
                                            } 
                                        }}
                                        id="place"
                                        type="text"
                                        variant="outlined"
                                        disabled={fetching}
                                        value={place.address}
                                        label={i18n.t("visits.form.place")}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                                    <Link className={classes.customLink} 
                                        disabled={fetching}
                                        onClick={this.validateAddress}>
                                        {i18n.t('visits.form.lookup')}
                                    </Link>
                                </Grid>
                                { placeMessage && 
                                    <Grid item xs={12}>
                                        <Typography variant='body2' color='textSecondary' style={{display: 'flex'}}>
                                            <Warning className={classes.warning__message__icon} />
                                            {placeMessage}
                                        </Typography>
                                    </Grid>
                                }
                                
                                { validAddress &&
                                    <Grid item xs={12}>
                                        <Typography variant='body2' color='textSecondary'>
                                            {geoLocalization ? geoLocalization.formatted_address : event && event.place && event.place.googleAddress ? event.place.googleAddress : ''}
                                        </Typography>
                                        <div className={classes.map__container}>
                                            <GoogleMap 
                                                location={{
                                                    positions: [{
                                                        lat: geoLocalization ? geoLocalization.geometry.location.lat : event && event.place && event.place.googleAddress ? event.place.lat : '',
                                                        lng: geoLocalization ? geoLocalization.geometry.location.lng : event && event.place && event.place.googleAddress ? event.place.lng : '',
                                                        label: 'Location'
                                                    }],
                                                    zoom: 14
                                                }} 
                                            />
                                            {
                                            // <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=250x250&key=${GOOGLE_API_KEY}
                                            //     &markers=color:blue%7Clabel:Location%7C${geoLocalization ? geoLocalization.geometry.location.lat : event && event.place && event.place.googleAddress ? event.place.lat : ''},
                                            //     ${geoLocalization ? geoLocalization.geometry.location.lng : event && event.place && event.place.googleAddress ? event.place.lng : ''}`} 
                                            //     height="250" width="250" alt="CheckIn Map"/>
                                            }
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {/******* Visit Info *******/}

                        <Grid item xs={12} style={{marginTop: '8px', display: currentTab === 3 ? 'flex' : 'none'}}>
                            <Grid container item spacing={1}>
                                <Grid item xs={6}>
                                    <TextField autoComplete='off'
                                        required
                                        id="visitType"
                                        select
                                        InputProps={{
                                            className: classes.event__form__input
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t("visits.form.type")}
                                        className={classes.event__form__textField}
                                        value={type}
                                        name='type'
                                        onChange={this.handleChangeSelect}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        variant="outlined"
                                    >
                                        {options ? options.map(option => (
                                            <MenuItem key={option.value} value={option.value} className={classes.event__form__menuItem}>
                                                {option.value}
                                            </MenuItem>
                                        )) : []}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.multilineInput
                                        }}
                                        className={classes.textField}
                                        onChange={event => {
                                            this.inputs.objectives = event.target.value;
                                        }}
                                        multiline
                                        rowsMax='3'
                                        rows='1'
                                        id="objectives"
                                        type="text"
                                        variant="outlined"
                                        label={i18n.t("visits.form.objectives")}
                                        value={this.inputs.objectives}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.multilineInput
                                        }}
                                        className={classes.textField}
                                        onChange={event => {
                                            this.inputs.topics = event.target.value;
                                        }}
                                        multiline
                                        rowsMax='3'
                                        rows='1'
                                        id="topics"
                                        type="text"
                                        variant="outlined"
                                        value={this.inputs.topics}
                                        label={i18n.t("visits.form.topics")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions >
                    <Grid container spacing={0} justify={'space-between'} alignItems={'center'}>
                        {this.state.showRequiredFieldsError && <>
                            {currentTab === 2 && !geoLocalization && place.address ?
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '5px' }}>
                                    <Typography color='error'>{i18n.t("visits.form.noValidAddress")}</Typography>
                                </Grid>
                            :
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '5px' }}>
                                    <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                </Grid>
                            }
                        </>}

                        <div>
                            <Button
                                color='default'
                                variant="outlined"
                                size='small'
                                style={{minWidth: '80px'}}
                                onClick={this.props.handleClose}>
                                {i18n.t("visits.form.cancel")}
                            </Button>
                        </div>

                        { currentTab === 0 && 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className={classes.button__wrapper}>
                                    <Button
                                        size='small'
                                        color='primary'
                                        variant="contained"
                                        disabled={loading}
                                        style={{minWidth: '90px'}}
                                        onClick={this.manageEvent}
                                    >
                                        {i18n.t("visits.form.save")}
                                        {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        size='small'
                                        color='primary'
                                        variant="contained"
                                        disabled={loading}
                                        style={{minWidth: '90px', marginLeft: '16px'}}
                                        onClick={this.createVisit}
                                    >
                                        {i18n.t("visits.form.createVisit")}
                                    </Button>
                                </div>
                            </div>
                        }

                        { currentTab === 1 &&
                            <Button
                                size='small'
                                color='primary'
                                variant="contained"
                                disabled={loading}
                                style={{minWidth: '90px', marginLeft: '16px'}}
                                onClick={this.validClientAndContact}
                            >
                                {i18n.t("visits.form.where")}
                            </Button>
                        }

                        { currentTab === 2 &&
                            <Button
                                size='small'
                                color='primary'
                                variant="contained"
                                disabled={loading}
                                style={{minWidth: '90px', marginLeft: '16px'}}
                                onClick={this.validVisitAddress}
                            >
                                {i18n.t("visits.form.what")}
                            </Button>
                        }

                        { currentTab === 3 &&
                            <div className={classes.button__wrapper}>
                                <Button
                                    size='small'
                                    color='primary'
                                    variant="contained"
                                    disabled={loading}
                                    style={{minWidth: '90px'}}
                                    onClick={this.manageEvent}
                                >
                                    {i18n.t("visits.form.finish")}
                                    {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </div>
                        }

                    </Grid>
                </DialogActions>


                <ConfirmationDialog
                    handleClose={this.cancelConfirmationDelete}
                    onConfirm={this.deleteEvent}
                    loading={loading}
                    message={i18n.t('visits.confirmation.message')}
                    cancelLabel={i18n.t('visits.confirmation.cancelDelete')}
                    confirmLabel={i18n.t('visits.confirmation.confirmDeleteLabel')}
                    open={openConfirmationDialog || false}
                />

            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client,
        clients: state.clients
    }
}

export default connect(mapStateToProps)(geolocated()(withMobileDialog()(withStyles(styles)(EventForm))));