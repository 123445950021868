import React from 'react'

import {
    TextField,
    Hidden,
    Button,
    IconButton,
    InputAdornment,
    List,
    Link,
    ListItem,
    ListItemText,
    Paper,
    Grid,
    Checkbox,
    Dialog,
    CircularProgress,
    DialogContent,
    DialogActions,
    DialogTitle,
    withStyles,
    Typography,
} from '@material-ui/core'
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import i18n from 'i18next'
import { Close } from '@material-ui/icons'
import API from '../../../lib/api'
import ReacTable from 'react-table'
import classNames from 'classnames'
import { VendorQuoteItemsForm } from '.'
import { NumberFormatInputCustom, CustomMessages } from '../../general'
import { SlideUp } from '../../general/Transitions'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'


const styles = theme => ({
    vendor__quote__items__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`,
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main,
    },
    vendor__quote__items__table__quantity: {
        color: theme.palette.text.secondary
    },
    vendor__form__input: {
        fontSize: '9pt'
    },
    vendor__form__textField: {
    },
    vendor__quote__items__list: {
        margin: 0,
        padding: 0
    },
    vendor__quote__items__list__paper: {
        padding: theme.spacing(),
        marginBottom: theme.spacing(),
        width: '100%'
    },
    vendor__quote__items__list__item: {
        margin: 0,
        padding: 0
    },
    vendorQuoteItems__btn: {
        ...theme.smallButton,
        borderColor: theme.palette.blue[100],
        color: theme.palette.blue[100]
    },
    "vendorQuoteItems__btn--remove": {
        borderColor: theme.palette.red[100],
        color: theme.palette.red[100]
    },
    vendorquote__addItem__button__container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    vendorquote__addItem__button: {
        ...theme.customLink,
        marginTop: theme.spacing()
    },
    vendorquote__addItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    vendorQuoteItems__closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        position: 'relative'
    },
    vendorQuote__noItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.orange[80],
        borderRadius: theme.spacing() / 2,
        width: '80px',
        padding: '0 5px',
        marginTop: theme.spacing()
    },
    vendor__quote__items__table__descriptionResumed: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    extendeDescription__icon: {
        fontSize: '20px',
        color: theme.palette.grey[500]
    }
})

class VendorQuoteFormItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSelectItemDialog: false,
            apiErrorMessage: null,
            vendorQuoteItems: props.items.filter(it => !it.deleted),
            selectedDescription: null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            vendorQuoteItems: nextProps.items.filter(it => !it.deleted)
        })
    }

    includeItems = () => {
        const { vendorQuoteItems } = this.state,
            { saveOnChange } = this.props;

        if (saveOnChange) {
            const { vendorQuote } = this.props

            this.setState({
                saving: true
            })

            let body = {
                id: vendorQuote.id,
                fileId: vendorQuote.fileId,
                vendorId: vendorQuote.vendor.id,
                vendorContactId: vendorQuote.contact.id,
                comments: vendorQuote.comments,
                otherCosts: vendorQuote.otherCosts,
                items: vendorQuoteItems.map((item, i) => {
                    return {
                        id: vendorQuote ? item.id : null,
                        rfqItemId: item.rfqItemId,
                        quantity: item.quantity,
                        unitAmount: item.unitAmount,
                        include: item.include,
                        references: item.references,
                        description: item.description,
                        deliveryDays: item.deliveryDays,
                        comments: item.comments
                    }
                })
            }

            API.Quotes.updateVendorRFQ({ body }).then(res => {
                if (res.data) {
                    this.setState({
                        saving: false,
                    })
                    this.close();
                    this.props.updateVendorQuoteItems(res.data.record.file.vendorQuotes.find(vq => vq.id === vendorQuote.id))

                } else {
                    this.setState({
                        saving: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        } else {
            this.close();
            this.props.updateVendorQuoteItems(vendorQuoteItems)
        }
    }

    close = () => {
        this.setState({
            showSelectItemDialog: false
        })
        this.props.generalActions && this.props.generalActions.showAddVendorQuoteItem(false);
    }

    extendDescription = (e, index) => {
        e.stopPropagation()
        const { selectedDescription } = this.state;

        this.setState({
            selectedDescription: index === selectedDescription ? null : index
        })
    }

    render() {
        const { vendorQuoteStatusId,
            classes,
            currencySymbol,
            desactiveSection,
            general, editable, vendorQuote,
            vendorQuoteSummary, isVendorPO } = this.props,
            { vendorQuoteItems,
                showSelectItemDialog,
                saving, selectedDescription,
                apiErrorMessage } = this.state;


        if (!vendorQuoteItems) return null

        let { showAddVendorQuoteItem } = general;
        return (
            <div>
                <CustomMessages
                    type={'error'}
                    message={apiErrorMessage}
                    clearMessage={() => this.setState({
                        apiErrorMessage: null
                    })}
                />

                <Hidden xsDown implementation='css'>

                    {(!vendorQuoteSummary || editable || (vendorQuoteSummary && vendorQuote.items && vendorQuote.items.length > 0)) &&
                        <div style={{ marginBottom: '8px' }}>

                            <VendorQuoteItemsForm
                                editable={editable}
                                isVendorPO={isVendorPO}
                                vendorQuoteSummary={vendorQuoteSummary}
                                updateVendorQuoteItems={this.props.updateVendorQuoteItems}
                                currencySymbol={currencySymbol}
                                vendorQuote={vendorQuote}
                                vendorQuoteStatusId={vendorQuoteStatusId}
                                vendorQuoteItems={vendorQuoteItems}
                                generalActions={this.props.generalActions}
                                validateVendorQuote={this.props.validateVendorQuote}
                            />
                        </div>
                    }

                    {vendorQuoteSummary && vendorQuote.items && vendorQuote.items.length === 0 &&
                        <div className={classes.vendorQuote__noItems}>
                            <Typography>
                                {i18n.t('quotes.vendorQR.notVendorRFQItems')}
                            </Typography>
                        </div>
                    }

                    {/* Lo que se muestra en el detail del vendor quote */}
                    {vendorQuoteStatusId === 1 && vendorQuoteSummary &&
                        <div className={classes.vendorquote__addItem}>
                            <Link variant='button'
                                disabled={desactiveSection}
                                className={classes.vendorquote__addItem__button}
                                onClick={() => this.setState({ showSelectItemDialog: true })}>
                                {i18n.t('quotes.vendorQuoteRequest.selectVendorsItems')}
                            </Link>
                        </div>
                    }

                    <Dialog
                        maxWidth={'sm'}
                        fullWidth={true}
                        TransitionComponent={SlideUp}
                        aria-labelledby="form-items"
                        open={showSelectItemDialog || (showAddVendorQuoteItem || false)}
                    >
                        <DialogTitle disableTypography={true} id="form-items">
                            {i18n.t('quotes.vendorQuoteRequest.selectVendorsItems')}
                            <IconButton color="inherit"
                                onClick={this.close}
                                aria-label="Close"
                                className={classes.vendorQuoteItems__closeButton}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}
                                alignItems='center'
                                justify='center'
                                style={{ marginTop: '16px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color='textSecondary'>{i18n.t('quotes.vendorQuoteRequest.selectItemsToBeIncluded')}</Typography>
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: '16px' }}>
                                <ReacTable
                                    data={vendorQuoteItems.sort((a, b) => {
                                        if (a.rfqItemId < b.rfqItemId) return -1;
                                        if (a.rfqItemId > b.rfqItemId) return 1;
                                        return 0
                                    })}
                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                const items = [...vendorQuoteItems]
                                                items[rowInfo.index].include = !vendorQuoteItems[rowInfo.index].include
                                                this.setState({
                                                    vendorQuoteItems: items
                                                })
                                            }
                                        }
                                    }}
                                    columns={[
                                        {
                                            Header: i18n.t("quotes.clientRFQ.table.column1"),
                                            id: 'rowIndex',
                                            Cell: row => (<div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                width: '100%'
                                            }}>{row.index + 1}</div>),
                                            width: 40
                                        },
                                        {
                                            Header: i18n.t('quotes.vendorQuoteRequest.itemsTable.column1'),
                                            id: 'description',
                                            style: {
                                                whiteSpace: 'pre-line'
                                            },
                                            Cell: row => (
                                                <div>
                                                    {row.original.references && row.original.references !== row.original.partNumber ? <>
                                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                                            {row.original.partNumber}
                                                        </Typography>
                                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                                            {row.original.references}
                                                        </Typography>
                                                    </> :
                                                        <Typography className={classes.vendor__quote__items__table__itemPartNumber} >
                                                            {row.original.references}
                                                        </Typography>
                                                    }

                                                    {vendorQuoteStatusId !== 3 && (
                                                        <Typography color='textSecondary'>
                                                            {row.original.quantity} {row.original.unit}
                                                        </Typography>
                                                    )}

                                                    <div onClick={(e) => this.extendDescription(e, row.index)}
                                                        className={classes.vendor__quote__items__table__descriptionResumed}
                                                        style={{ alignItems: selectedDescription === row.index ? 'flex-start' : 'center' }}>
                                                        <Typography color='textSecondary'
                                                            style={{ whiteSpace: selectedDescription === row.index ? 'pre-line' : 'nowrap' }}
                                                            noWrap>
                                                            {row.original.description}
                                                        </Typography>
                                                        {row.original.description.length > 35 && <>
                                                            {selectedDescription === row.index ?
                                                                <KeyboardArrowUp className={classes.extendeDescription__icon} />
                                                                :
                                                                <KeyboardArrowDown className={classes.extendeDescription__icon} />
                                                            }
                                                        </>}
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            Header: i18n.t('quotes.vendorQuoteRequest.itemsTable.addToVendorQuote'),
                                            width: 100,
                                            className: classes.vendor__quote__items__table__cell__center,
                                            show: (vendorQuoteStatusId === 1 ? true : false),
                                            Cell: row => (
                                                <div>
                                                    <Checkbox
                                                        checked={row.original.include}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </div>
                                            )
                                        }
                                    ]}
                                    pageSize={vendorQuoteItems.length}
                                    className={classNames(classes.vendor__quote__items__table, ' -striped -highlight')}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    filtrable={false}
                                    resizable={false}
                                    sortable={false} />
                            </div>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div className={classes.button__wrapper}>
                                <Button
                                    disabled={saving}
                                    color="primary"
                                    variant="contained"
                                    onClick={this.includeItems}
                                    className={classes.vendor__form__save__button}>
                                    {i18n.t("quotes.vendorQuoteRequest.doneSelectItems")}
                                </Button>
                                {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                            </div>
                        </DialogActions>
                    </Dialog>


                </Hidden>
                <Hidden smUp implementation='css'>
                    <List className={classes.vendor__quote__items__list}>

                        {vendorQuoteItems.map((row, i) => (
                            <ListItem key={i} className={classes.vendor__quote__items__list__item}>
                                <Paper className={classes.vendor__quote__items__list__paper}>
                                    <ListItemText style={{ margin: 0, padding: 0 }}>
                                        <span className={classes.vendor__quote__items__table__itemPartNumber}>{row.references}</span>
                                        <br />
                                        <br />
                                        {vendorQuoteStatusId === 1 && (
                                            <div>
                                                <TextField
                                                    required
                                                    id='itemDescription'
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={true}
                                                    inputProps={{ className: classes.vendor__form__input }}
                                                    fullWidth
                                                    multiline
                                                    rowsMax='4'
                                                    rows='4'
                                                    onBlur={e => {
                                                        const items = [...vendorQuoteItems]
                                                        items[i].description = e.target.value
                                                        this.setState({
                                                            vendorQuoteItems: items
                                                        })
                                                        this.props.updateVendorQuoteItems(items)
                                                    }}
                                                    defaultValue={row.description}
                                                    className={classes.vendor__form__textField}
                                                    type='text'
                                                    variant='outlined'
                                                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.description')} />
                                                <br />
                                                <br />
                                                <TextField
                                                    id='vendorComments'
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ className: classes.vendor__form__input }}
                                                    disabled={desactiveSection || (vendorQuoteStatusId === 1 && !row.include) || vendorQuoteStatusId > 1}
                                                    fullWidth
                                                    multiline
                                                    rowsMax='4'
                                                    rows='4'
                                                    onBlur={e => {
                                                        const items = [...vendorQuoteItems]
                                                        items[i].comments = e.target.value
                                                        this.setState({
                                                            vendorQuoteItems: items
                                                        })
                                                        this.props.updateVendorQuoteItems(items)
                                                    }}
                                                    className={classes.vendor__form__textField}
                                                    type='text'
                                                    variant='outlined'
                                                    defaultValue={row.comments}
                                                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.comments')} />
                                                <br />

                                                <span className={classes.vendor__quote__items__table__quantity}>
                                                    {row.quantity} {row.unit}
                                                </span>
                                            </div>
                                        )}

                                        {vendorQuoteStatusId === 2 && (
                                            <div>
                                                <div className={classes.vendor__quote__items__table__quantity}>
                                                    <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.description')}</strong>
                                                    <br />
                                                    {row.description}
                                                    <br />
                                                    <br />
                                                    {row.comments &&
                                                        <span>
                                                            <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.comments')}</strong>
                                                            <br />
                                                            {row.comments}
                                                            <br />
                                                        </span>
                                                    }
                                                </div>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={desactiveSection || (vendorQuoteStatusId === 1 && !row.include) || vendorQuoteStatusId === 3}
                                                    id="vendor_quote_amount"
                                                    label={i18n.t('quotes.vendorQuoteRequest.itemsTable.amount')}
                                                    name="otherCostAmount"
                                                    className={classes.preparing__form__textField}
                                                    onBlur={(e) => {
                                                        const items = [...vendorQuoteItems]
                                                        items[i].unitAmount = e.target.value
                                                        this.setState({
                                                            vendorQuoteItems: items
                                                        })
                                                        this.props.updateVendorQuoteItems(items)
                                                    }}
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                    defaultValue={row.unitAmount}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                                                        inputComponent: NumberFormatInputCustom,
                                                    }}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="vendor_quote_unit"
                                                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.unit')}
                                                            name="otherCostAmount"
                                                            disabled={desactiveSection || (vendorQuoteStatusId === 1 && !row.include) || vendorQuoteStatusId === 3}
                                                            className={classes.preparing__form__textField}
                                                            onBlur={(e) => {
                                                                const items = [...this.state.vendorQuoteItems]
                                                                vendorQuoteItems[i].quantity = e.target.value
                                                                this.setState({
                                                                    vendorQuoteItems: items
                                                                })
                                                                this.props.updateVendorQuoteItems(items)
                                                            }}
                                                            style={{
                                                                marginTop: '10px',
                                                            }}
                                                            defaultValue={row.quantity}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end" >{row.unit}</InputAdornment>,
                                                                inputComponent: NumberFormatInputCustom,
                                                            }}
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="vendor_quote_delivery_days"
                                                            label={i18n.t('quotes.vendorQuoteRequest.itemsTable.delivery')}
                                                            disabled={desactiveSection || (vendorQuoteStatusId === 1 && !row.include) || vendorQuoteStatusId === 3}
                                                            name="otherCostAmount"
                                                            className={classes.preparing__form__textField}
                                                            onBlur={(e) => {
                                                                const items = [...this.state.items]
                                                                items[i].deliveryDays = e.target.value
                                                                this.setState({
                                                                    vendorQuoteItems: items
                                                                })
                                                                this.props.updateVendorQuoteItems(items)
                                                            }}
                                                            style={{
                                                                marginTop: '10px'
                                                            }}
                                                            defaultValue={row.deliveryDays}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end" >Days</InputAdornment>,
                                                                inputComponent: NumberFormatInputCustom,
                                                            }}
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}

                                        {vendorQuoteStatusId === 3 && (
                                            <div>
                                                <div className={classes.vendor__quote__items__table__quantity}>
                                                    <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.description')}</strong>
                                                    <br />
                                                    {row.description}
                                                    <br />
                                                    <br />
                                                    {row.comments &&
                                                        <span>
                                                            <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.comments')}</strong>
                                                            <br />
                                                            {row.comments}
                                                            <br />
                                                        </span>
                                                    }
                                                </div>

                                                <span className={classes.vendor__quote__items__table__quantity}>
                                                    {row.quantity} {row.unit}
                                                    <br />
                                                    <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.amount')}</strong> {row.unitAmount}
                                                    <br />
                                                    <strong>{i18n.t('quotes.vendorQuoteRequest.itemsTable.delivery')}</strong> {row.deliveryDays}
                                                </span>
                                            </div>
                                        )}

                                        <br />
                                        {vendorQuoteStatusId === 1 && (
                                            <Button
                                                variant="outlined"
                                                disabled={desactiveSection}
                                                onClick={(e) => {
                                                    const items = [...vendorQuoteItems]
                                                    items[i].include = !row.include
                                                    this.setState({
                                                        vendorQuoteItems: items
                                                    })
                                                    this.props.updateVendorQuoteItems(items)
                                                }}
                                                className={`${classes.vendorQuoteItems__btn} ${row && row.include ? classes["vendorQuoteItems__btn--remove"] : ''}`}>
                                                {row && row.include ?
                                                    i18n.t('quotes.vendorQuoteRequest.itemsTable.removeToVendorQuote')
                                                    :
                                                    i18n.t('quotes.vendorQuoteRequest.itemsTable.addToVendorQuote')}
                                            </Button>
                                        )}
                                    </ListItemText>
                                </Paper>
                            </ListItem>
                        ))}
                    </List>
                </Hidden>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuoteFormItems))
