import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    Typography,
    Link,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import API from '../../lib/api'
import { SlideUp } from '../general/Transitions'
import { withStyles } from '@material-ui/core/styles'
import { Inbox, Close } from '@material-ui/icons'
import { VendorQuotePopup } from '../quotes/vendorQR';
import { FileDetails } from '../quotes'
import i18n from 'i18next';
import classNames from 'classnames'
import Permissions from '../../lib/permissions'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as peopleActions from '../../actions/peopleActions'

const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__vendors__cell: {
            display: 'flex',
            flexDirection: 'column',
        },
        vendor__quotes__table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        vendor__quote__salesPerson: {
            color: theme.palette.naval.main,
        },
        vendorRfq__table__fileLink: {
            color: theme.palette.text.primary,
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        vendorRfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default',
        },
        vendorRfq__table__vendorLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        vendorRfq__table__vendorLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        list__vendors__cell__row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        list__fileCountry: { fontSize: '9pt' },
        vendorRFQ__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        filesList__closeWindow: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        }
    })
};


class VendorQuotesListDialog extends Component {

    state = {
        vendorQuotes: null,
        file: null,
        selectedVendorQuote: null,
        showVendorQuote: false,
        showFileDetails: false,
        selectedFileId: null
    }

    componentWillMount() {
        const { vendorQuotes } = this.props;

        this.setState({
            vendorQuotes: vendorQuotes
        })
    }
    componentWillReceiveProps = nextProps => {
        this.setState({
            vendorQuotes: nextProps.vendorQuotes
        })
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    openEditVendorQuote = (row) => {
        if (row.fileId) {
            API.Files.getFile({
                fileId: row.fileId
            }).then(res => {
                if (res.file) {
                    // this.props.filesActions.setFile(res.file);
                    this.setState({
                        showVendorQuote: true,
                        file: res.file,
                        selectedVendorQuote: res.file.vendorQuotes.find(vq => vq.id === row.vendorQuoteId),
                    })
                }
            })
        }
    }

    handleClose = () => {
        this.setState({
            showVendorQuote: false,
        })
    }

    updateVendorQuote = (vendorQuote, editVendorRFQ) => {

    }

    handleShowQuote = (file) => {
        this.setState({
            showFileDetails: true,
            selectedFile: file
        })
    }

    closeFileDetails = () => {
        this.setState({
            showFileDetails: false,
            selectedFileId: null
        })
    }


    render = () => {
        const { classes, people } = this.props,
            { file,
                selectedVendorQuote,
                vendorQuotes,
                showVendorQuote,
                showFileDetails,
                selectedFile
            } = this.state;

        if (!vendorQuotes) return null

        return (
            <div>
                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    onClose={this.props.onClose}
                    // scroll='body'
                    aria-labelledby="filesList"
                    open={this.props.open}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="notes_form" disableTypography={true}>
                        {i18n.t("dashboard.filesList.title")}
                        <IconButton color="inherit"
                            onClick={this.props.onClose}
                            aria-label="Close" className={classes.filesList__closeWindow}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ padding: '16px', overflow: 'overlay' }}>
                        {vendorQuotes && vendorQuotes.length > 0 && (
                            <ReactTable
                                data={vendorQuotes}
                                columns={[
                                    {
                                        Header: i18n.t("quotes.vendorQuotesList.table.column1"),
                                        filterable: false,
                                        id: 'vendor',
                                        width: 230,
                                        show: Permissions.checkSensitiveInformationPermission(),
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                        accessor: row => {
                                            return (
                                                <div className={classes.vendorRFQ__table__fileDescriptions}>
                                                    <div className={classes.list__vendors__cell}>
                                                        <Link
                                                            variant="body2"
                                                            className={Permissions.checkSensitiveInformationPermission() ? classes.vendorRfq__table__vendorLink : classes.vendorRfq__table__vendorLink__disabled}
                                                            onClick={() => {
                                                                if (Permissions.checkSensitiveInformationPermission()) {
                                                                    this.openEditVendorQuote(row)
                                                                }
                                                            }}
                                                            underline={Permissions.checkSensitiveInformationPermission() ? 'hover' : 'never'}
                                                        >
                                                            <Typography
                                                                className={Permissions.checkSensitiveInformationPermission() ? classes.vendorRfq__table__vendorLink : classes.vendorRfq__table__vendorLink__disabled}
                                                                style={{ width: '85%' }} noWrap>
                                                                <strong>{row.vendor.name}</strong>
                                                            </Typography>
                                                        </Link>
                                                        <Typography color='textSecondary'>
                                                            {i18n.t("quotes.vendorQuoteRequest.vendorQuote")} {`${row.id}`}
                                                        </Typography>
                                                        <Typography color='textSecondary'>
                                                            {row.vendorQuoteStatusId === 1 &&
                                                                `${i18n.t("quotes.vendorQuotesList.created")} ${this.quoteDateFormat(row.createDateTime)}`
                                                            }
                                                            {row.vendorQuoteStatusId === 2 &&
                                                                `${i18n.t("quotes.vendorQuotesList.requested")} ${this.quoteDateFormat(row.requestedDateTime)}`
                                                            }
                                                            {row.vendorQuoteStatusId === 3 &&
                                                                `${i18n.t("quotes.vendorQuotesList.ready")} ${this.quoteDateFormat(row.responseDateTime)}`
                                                            }
                                                        </Typography>
                                                        <div className={classes.list__file__lastUpdate__container}>
                                                            <Typography className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                {moment(row.lastUpdateDateTime).fromNow(true)}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.vendorQuotesList.table.column2"),
                                        filterable: false,
                                        id: 'client',
                                        accessor: row => {
                                            return (
                                                <div className={classes.list__fileDetails}>
                                                    <Typography className={classes.list__fileClient} noWrap>
                                                        {row.client ? row.client.name : ''}
                                                    </Typography>

                                                    <Link
                                                        variant="body2"
                                                        className={classes.vendorRfq__table__fileLink}
                                                        onClick={() => {
                                                            // if (Permissions.checkSensitiveInformationPermission()) {
                                                            this.handleShowQuote(row)
                                                            // }
                                                        }}
                                                        underline='hover'
                                                    >
                                                        {i18n.t("quotes.requestedByClient.table.file")} {`${row.fileId}`}
                                                    </Link>

                                                    <Typography
                                                        variant='body2'
                                                        color='textSecondary'>
                                                        {this.quoteDateFormat(row.fileCreateDateTime)}
                                                    </Typography>
                                                </div>
                                            )
                                        },
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.vendorQuotesList.table.column3"),
                                        filterable: false,
                                        id: 'user_Author',
                                        width: 90,
                                        show: people && people.filterPeople.length > 1 ? true : false,
                                        accessor: row => <Typography className={classes.vendor__quote__salesPerson}>{row.author.name}</Typography>,
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }
                                    }
                                ]}
                                pageSize={vendorQuotes && vendorQuotes.length >= 50 ? 50 : vendorQuotes.length}
                                showPaginationTop={vendorQuotes && vendorQuotes.length >= 50 ? true : false}
                                showPaginationBottom={vendorQuotes && vendorQuotes.length >= 50 ? true : false}
                                className={classNames(classes.vendor__quotes__table, ' -striped -highlight')}
                                sortable={false}
                                resizable={false}

                            />
                        )}
                        {vendorQuotes && vendorQuotes.length === 0 && (
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('quotes.vendorQuotesList.noQuotes')}</Typography>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>

                <VendorQuotePopup
                    vendorQuote={selectedVendorQuote}
                    open={showVendorQuote}
                    file={file}
                    handleClose={this.handleClose}
                    onExited={null}
                    deleteVendorQuote={this.deleteVendorQuote}
                    updateVendorQuote={this.updateVendorQuote}
                />

                <FileDetails
                    open={showFileDetails}
                    onClose={this.closeFileDetails}
                    fileId={selectedFile ? selectedFile.fileId : null}
                    olderTask={selectedFile ? selectedFile.olderTask : null}
                    notesCount={selectedFile ? selectedFile.notesCount : null}
                    status={selectedFile ? selectedFile.fileStatusId : null}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VendorQuotesListDialog))