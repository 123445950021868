import React, { useState } from 'react';
import { connect } from 'react-redux'
import {
    Paper,
    Grid,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import randomstring from "randomstring"
import randomColor from "randomcolor";
import { CustomMessages } from '../general'
import i18n from 'i18next';

import ConfirmationDialog from '../general/ConfirmationDialog'
import API from '../../lib/api'

const styles = theme => ({
    user__form__paper: {
        ...theme.modal.paper,
        maxWidth: '550px'
    },
    user__form: theme.modal.container,
    user__form__title: theme.modal.title,
    user__form__buttons: {
        ...theme.modal.buttons,
        marginTop: 0,
    },
    user__form__user_active_field: {
        margin: 0
    },
    input: {
        padding: theme.spacing()
    },
    textField: {
        margin: `${theme.spacing()}px 0`
    },
    success: {
        backgroundColor: theme.palette.green[100],
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '18pt',
        color: theme.palette.orange[100],
    },
    actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        marginBottom: theme.spacing(2),
        textDecoration: 'underline',
        minWidth: '100px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
});

const profiles = [
    {
        id: 1,
        name: 'Admin'
    },
    {
        id: 2,
        name: 'Agent'
    },
    {
        id: 3,
        name: 'Super Admin'
    },
]

const UserForm = props => {
    const { classes, selectedUser, handleClose, general, addUser, updateUser, removeUserFromList } = props,
        [name, setName] = useState(selectedUser ? selectedUser.userName : ''),
        [isActive, setIsActive] = useState(selectedUser ? selectedUser.userActive ? true : false : true),
        [email, setEmail] = useState(selectedUser ? selectedUser.userEmail : ''),
        [username, setUsername] = useState(selectedUser ? selectedUser.userLogin : ''),
        [profile, setProfile] = useState(selectedUser ? selectedUser.userTypeId : 2),
        [countryId, setCountry] = useState(selectedUser ? selectedUser.countryId : 14),
        [saving, setSaving] = useState(false),
        [validating, setValidating] = useState(false),
        [errorMessage, setErrorMessage] = useState(null),
        [confirmDeleteUser, setConfirmDeleteUser] = useState(false),
        [deleteError, setDeleteError] = useState(false),
        [deleting, setDeleting] = useState(false);

    const validateEmail = (email) => {
        var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        return re.test(String(email.trim()).toLowerCase());
    }

    const handleChangeUsername = e => {
        if (e.target.value && e.target.value !== '' && e.target.value.length <= 10) {
            setUsername(e.target.value.replace(/ /g, ""))
        }

        if (e.target.value === '') {
            setUsername(e.target.value)
        }
    };

    const deleteUser = () => {
        setDeleting(true)

        API.Users.deleteUser({ userId: selectedUser.userId, username })
            .then(response => {
                if (response.success) {
                    removeUserFromList(selectedUser.userId)
                    setDeleting(false)
                    setConfirmDeleteUser(false)

                    handleClose()
                } else {
                    setDeleteError(true)
                    setDeleting(false)
                    setConfirmDeleteUser(false)

                }

            }).catch(err => {
                setDeleting(false)
            })
    }

    const manageUser = () => {
        setValidating(true)
        setErrorMessage(null)

        if (!name || !username || (!selectedUser && !email)) {
            return
        }

        setValidating(false)
        setSaving(true)

        let newUser = {
            username,
            name,
            email,
            profile,
            countryId,
            isActive
        }

        if (!selectedUser) {
            let a = randomstring.generate({
                length: 2,
                charset: 'alphabetic',
                capitalization: 'uppercase'
            }),
                b = randomstring.generate({
                    length: 4,
                    charset: 'alphabetic',
                    capitalization: 'lowercase'
                }),
                c = randomstring.generate({
                    length: 4,
                    charset: 'numeric'
                });


            let newPassword = a + b + c
            newUser.password = encodeURIComponent(newPassword)
            newUser.color = randomColor()

            API.Users.createUser({ user: newUser })
                .then(response => {
                    if (response.success) {
                        setSaving(false)

                        addUser(response.data)
                        handleClose()
                    } else {
                        if (response.errors.code === 'UsernameExistsException') {
                            setErrorMessage('There is already an account with this email or with this username')
                        }
                        setSaving(false)
                    }

                }).catch(err => {
                    setSaving(false)
                })
        } else {
            newUser.userId = selectedUser.userId

            API.Users.updateUser({ user: newUser })
                .then(response => {
                    if (response.success) {
                        setSaving(false)

                        updateUser(response.data)
                        handleClose()
                    } else {
                        setSaving(false)
                    }

                }).catch(err => {
                    setSaving(false)
                })
        }

    }

    return (
        <Paper className={classes.user__form__paper} tabIndex={-1}>
            { deleteError &&
                <CustomMessages
                    type={'error'}
                    message={'The user has participated as creator or responsible for any file so it cannot be deleted. To restrict access, mark the user as inactive.'}
                    clearMessage={() => setDeleteError(false)}
                />
            }
            <div className={classes.user__form__title}>
                <Typography style={{color: '#fff'}} variant='h5'>{selectedUser ? `${selectedUser.userName}` : `${i18n.t('users.formTitle')}`}</Typography>
            </div>
            <div className={classes.user__form}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            { !selectedUser ?
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Warning className={classes.warning__message__icon} />
                                    <Typography variant='body2'>
                                        {i18n.t('users.instructions')}
                                    </Typography>
                                </div>
                                :
                                <Typography variant='h6'>
                                    {`${selectedUser.userLogin} - ${selectedUser.userEmail}`}
                                </Typography>
                            }
                        </Grid>

                        { !selectedUser && <>
                            <Grid item xs={7}>
                                <TextField autoComplete='off' fullWidth required
                                    id="email"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    value={email}
                                    helperText={errorMessage ? errorMessage : ''}
                                    error={(validating && !email) || (email && !validateEmail(email)) || errorMessage}
                                    variant="outlined"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        if (validateEmail(e.target.value)) {
                                            let suggestedUsername = e.target.value.split('@')[0]
                                            setUsername(suggestedUsername)
                                        }
                                    }}
                                    label={i18n.t("users.email")}
                                />
                            </Grid>
                        

                            <Grid item xs={5}>
                                <TextField autoComplete='off' fullWidth required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    error={(validating && !username) || errorMessage}
                                    value={username}
                                    variant="outlined"
                                    onChange={handleChangeUsername}
                                    id="username"
                                    type="text"
                                    disabled
                                    label={i18n.t("users.username")}
                                />
                            </Grid>
                        </>}

                        <Grid item xs={7}>
                            <TextField autoComplete='off' fullWidth required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                                className={classes.textField}
                                error={validating && !name}
                                value={name}
                                variant="outlined"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                id="name"
                                type="text"
                                label={i18n.t("users.name")}
                            />
                        </Grid>

                        <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                            <FormControl
                                variant="outlined"
                                required
                                style={{width: '100%'}}
                                error={validating && !profile}
                                color='secondary'>
                                <InputLabel id="user-profile-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}>
                                    {i18n.t("users.profile")}
                                </InputLabel>
                                <Select
                                    labelId="select-outlined-label"
                                    id="select-outlined"
                                    value={profile}
                                    onChange={(e) => {
                                        setProfile(e.target.value)
                                    }}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={45}>

                                    {profiles.map(pro => {
                                        return (
                                            <MenuItem key={pro.id} value={pro.id}>{pro.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        { general && general.globalParameters && general.globalParameters.countries &&
                            <Grid item xs={7} style={{display: 'flex', alignItems: 'center'}}>
                                <FormControl
                                    variant="outlined"
                                    required
                                    style={{width: '100%'}}
                                    color='secondary'>
                                    <InputLabel id="user-profile-selector"
                                        classes={{
                                            root: classes.select__label
                                        }}>
                                        {i18n.t("users.country")}
                                    </InputLabel>
                                    <Select
                                        labelId="select-outlined-label"
                                        id="select-outlined"
                                        value={countryId}
                                        onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}
                                        classes={{
                                            root: classes.select__root
                                        }}
                                        labelWidth={100}>

                                        {general.globalParameters.countries.map(country => {
                                            return (
                                                <MenuItem key={country.countryId} value={country.countryId}>{country.countryName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        { selectedUser &&
                            <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.user__form__user_active_field}
                                        control={
                                            <Switch
                                                checked={isActive}
                                                onChange={(e) => {
                                                    setIsActive(e.target.checked)
                                                }}
                                                value="isActive"
                                                color="primary"
                                            />
                                        }
                                        label={isActive ? i18n.t("users.userActive") : i18n.t("users.userInActive")}
                                    />
                                </FormGroup>
                            </Grid>
                        }
                    </Grid>
                </div>

                <Grid container justify="center" style={{marginTop: '30px'}}>
                    { selectedUser &&
                        <Grid item xs={12}>
                            <Button
                                className={classes.actionButton__delete}
                                disabled={saving}
                                onClick={() => {
                                    setConfirmDeleteUser(true)
                                }}>
                                {i18n.t('users.deleteUser')}
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.user__form__buttons}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={handleClose}>
                            {i18n.t("users.cancelBtn")}
                        </Button>
                        <div style={{position: 'relative'}}>
                            <Button
                                disabled={deleting || saving || !name || !username || !validateEmail(email) || (!selectedUser && !email) ? true : false}
                                color="primary"
                                onClick={manageUser}
                                variant="contained">
                                {selectedUser ? i18n.t("users.updateBtn") : i18n.t("users.createBtn")}
                            </Button>
                            {saving &&
                                <CircularProgress style={{ position: 'absolute', left: '40%', top: '6px'}} color='secondary' size={24} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>

            {confirmDeleteUser &&
                <ConfirmationDialog
                    handleClose={() => {
                        setConfirmDeleteUser(false)
                    }}
                    onConfirm={deleteUser}
                    warningMessage={'If the user is part of any file as creator or responsible, it cannot be deleted.'}
                    message={'Are you sure you wish to delete this user?'}
                    cancelLabel={'No!'}
                    confirmLabel={'Delete It'}
                    loading={deleting}
                    open={confirmDeleteUser}
                />
            }
        </Paper>
    );
}

function mapStateToProps(state) {
    return {
        general: state.general
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserForm));