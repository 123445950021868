import {
    SET_CLIENT,
    ADD_CLIENT_CONTACT,
    UPDATE_CLIENT_CONTACT,
    DELETE_CLIENT_CONTACT
} from '../actions/clientsActions'


function client(state = [], action) {
    let contactIndex = null;
    switch (action.type) {
        case SET_CLIENT:
            if (action.client) {
                return action.client
            }
            return state;
        case ADD_CLIENT_CONTACT:
            if (state.id && action.contact.clientId === state.id) {
                return {
                    ...state,
                    contacts: [
                        action.contact,
                        ...state.contacts
                    ].sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0
                    })
                };
            }
            return state
        case UPDATE_CLIENT_CONTACT:
            if (state.id && action.contact.clientId === state.id) {
                contactIndex = state.contacts.findIndex(c => c.id === action.contact.id);
                return {
                    ...state,
                    contacts: [
                        ...state.contacts.slice(0, contactIndex),
                        { ...action.contact },
                        ...state.contacts.slice(contactIndex + 1)
                    ].sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0
                    })
                }
            }

            return state
        case DELETE_CLIENT_CONTACT:
            if (state.id && action.contact.clientId === state.id) {
                return {
                    ...state,
                    contacts: state.contacts.filter(c => c.id !== action.contact.id)
                }
            }
            return state
        default:
            return state
    }
}
export default client