import React, { Component } from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    withStyles,
    withMobileDialog
} from '@material-ui/core';
import {
    Menu as MenuIcon,
} from '@material-ui/icons/';
import { withRouter } from "react-router";
import EmailsLeftSidebar from './EmailsLeftSidebar';
import LeftSidebar from '../LeftSidebar';
import styled from 'styled-components';
import auth from '../../authentication/auth';
import {
    isMobile
} from "react-device-detect";

const logo = require('../../assets/images/logosmall.png');
const logomobile = require('../../assets/images/logomobile.png');

const HeaderLogo = styled.div`
    flex-grow:1;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        margin-right:5px;
    };
`;



const styles = (theme) => ({
    blankSpace: {
        height: '50px',
        background: 'blue'
    },
    logoAndSearchContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: theme.maxWith,
        margin: '0 auto',
        marginLeft: '-20px',
        background: 'yellow',
    },
    toolbar: {
        maxWidth: theme.maxWith,
        margin: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    mail__button__container: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '16px',
        cursor: 'pointer'
    }
})

class EmailsHeader extends Component {
    state = {
        menuVisible: false,
        anchorEl: null,
        user: null
    }

    componentWillMount() {
        const { history } = this.props;
        auth.getAuthenticatedUser()
            .then(user => {
                this.setState({
                    user
                })
            }).catch(err => {
                auth.signOut().then(() => {
                    history.push('/signIn');
                }).catch(err => console.log(err));
            });
    }


    toggleOpenMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        });
    }


    render() {
        const { classes, width } = this.props
        return (
            <div>
                <AppBar position="fixed" color="default">
                    {isMobile || width === 'xs' ? (
                        <EmailsLeftSidebar
                            menuVisible={this.state.menuVisible}
                            toggleOpenMenu={this.toggleOpenMenu}
                        />
                    ) : (
                            <LeftSidebar
                                menuVisible={this.state.menuVisible}
                                toggleOpenMenu={this.toggleOpenMenu}
                            />
                        )}
                    <Toolbar className={classes.toolbar} >
                        <div style={width !== 'xs' ? { width: '194px', display: 'flex' } : {}}>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={this.toggleOpenMenu} >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <HeaderLogo style={{ width: '50%', position: 'absolute', left: 0, right: 0, margin: 'auto' }}>
                            <img src={isMobile || width === 'xs' ? logomobile : logo} alt='Prodek'
                                style={{
                                    width: isMobile || width === 'xs' ? 50 : 95,
                                    paddingTop: isMobile || width === 'xs' ? 0 : 8,
                                    margin: 0
                                }} />
                        </HeaderLogo>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default withRouter(withMobileDialog()(withStyles(styles)(EmailsHeader)));