import React, { Component } from 'react';
import {
    Paper,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Typography,
    withStyles, 
    Link, 
    Snackbar,
    SnackbarContent
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons'
import auth from '../../authentication/auth';
import i18n from 'i18next';

const styles = theme => ({
    paper: {
        padding: theme.spacing(),
        maxWidth: '350px',
        margin: `${theme.spacing(2)}px auto`
    },
    complete: {
        color: 'green',
        fontSize: '17px',
        marginRight: theme.spacing(),
        width: '0.7em',
        height: '0.7em'
    },
    incomplete: {
        color: 'red',
        marginRight: theme.spacing(),
        fontSize: '17px',
        width: '0.7em',
        height: '0.7em'
    },
    buttons: {
        margin: theme.spacing(),
        position: 'relative'
    },
    align__items: {
        display: 'flex',
        alignItems: 'center'
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
    buttonSpinner: theme.buttonSpinner
});

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.inputs = {};
        this.state = { 
            generalError: null,
            loading: false,
            invalidPassword: true
        }
    }

    changeState = (state, data) => {
        const { onStateChange } = this.props;
        if (onStateChange) {
            onStateChange(state, data);
        }
    };

    signUp=()=> {
        const { username, password, email, phone_number, name, userID } = this.inputs;

        if (!username || username === '' || !password || password === '' || !email || email === '' || !name || name === '' || !userID || userID === '') {
            this.setState({
                loading: false,
                usernameEmpty: username ? false : true,
                passwordEmpty: password ? false : true,
                emailEmpty: email ? false : true,
                userIdEmpty: userID ? false : true,
                nameEmpty: name ? false : true
            });
            return;
        }

        if (!this.validateEmail(email)){
            this.setState({
                generalError: 'Enter a valid email account'
            });
            return
        }

        if (!this.validatePassword(password)){
            return 
        }

        this.setState({
            loading: true
        });

        auth.signUp({username, password, email, phone_number, name, userID})
            .then(res => {
                this.signUpSuccess(username)
            })
            .catch(
                err => this.signUpError(err)
            );
    }

    signUpSuccess=(username)=> {
        this.setState({ 
            generalError: null,
        });
        this.changeState('confirmSignUp', username);
    }


    signUpError=(err)=> {
        let message = err.error ? err.error.message : err.message;

        if(err.error){

            if (message.startsWith('Phone numbers must follow these formatting rules')) {
                message = 'Phone numbers must follow these formatting rules: A phone number must start with' +
                        ' a plus (+) sign, followed immediately by the country code. A phone number can o' +
                        'nly contain the + sign and digits. You must remove any other characters from a p' +
                        'hone number, such as parentheses, spaces, or dashes (-) before submitting the va' +
                        'lue to the service. For example, a United States-based phone number must follow ' +
                        'this format: +14325551212.'
            }

            if (message.startsWith("1 validation error detected: Value at 'username'") || message.startsWith('The first parameter')) {
                message = 'The username must not be empty or contain whitespace';
            }

            if (message.startsWith('Attributes did not conform to the schema: email:')) {
                message = 'The email must not be empty';
            }

            if (message.startsWith('Password cannot be empty')) {
                message = 'The password must not be empty';
            }
        }

        this.setState({ 
            loading: false,
            generalError: message 
        });
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePassword =(password) => {
        var format = /[!@#$%^&*(),._~?"':;{}|<>]/g;
        if (password.toUpperCase() !== password && password.toLowerCase() !== password && /\d/.test(password) && format.test(password)){
            return true
        } else {
            return false
        }
    }

    hasLowerCase = str => {
        return str.toUpperCase() !== str;
    };

    hasUpperCase = str => {
        return str.toLowerCase() !== str;
    };

    hasNumber = str => {
        return /\d/.test(str);
    };

    hasSpecialCharacters = str => {
        var format = /[!@#$%^&*(),._~?"':;{}|<>]/g;
        return format.test(str);
    };

    render() {
        const { authState, classes } = this.props;
        if (authState !== 'signUp') {
            return null;
        }
        const { password } = this.inputs;
        const { 
            generalError, 
            usernameEmpty, 
            passwordEmpty, 
            emailEmpty, 
            nameEmpty,
            userIdEmpty,
            loading,
        } = this.state;

        return (
            <Paper className={classes.paper}>
                <div style={{margin: '16px'}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth autoFocus required
                                error={nameEmpty ? true : false}
                                id="name"
                                label={i18n.t("login.name")}
                                type="text"
                                onChange={event => {
                                    this.setState({ nameEmpty: false });
                                    this.inputs.name = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth required
                                error={usernameEmpty ? true : false}
                                id="username"
                                label={i18n.t("login.username")}
                                type="text"
                                onChange={event => {
                                    this.setState({ usernameEmpty: false });
                                    this.inputs.username = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth required
                                error={passwordEmpty ? true : false}
                                id="password"
                                label={i18n.t("login.password")}
                                type="password"
                                onChange={event => {
                                    this.setState({ passwordEmpty: false });
                                    this.inputs.password = event.target.value;
                                }}
                            />
                        </Grid>
                        <div style={{textAlign: 'left'}}>
                            <div className={classes.align__items}>
                                {password && password.length > 8 ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                <Typography style={{ marginRight: '5px', color: password && password.length > 8 ? '#ccc' : '#000', fontSize: '10px' }}>Password length greater than or equal to 8</Typography>
                            </div>

                            <div className={classes.align__items}>
                                {password && this.hasUpperCase(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                <Typography style={{ marginRight: '5px', color: password && this.hasUpperCase(password) ? '#ccc' : '#000', fontSize: '10px' }}>Password must have uppercase characters</Typography>
                            </div>

                            <div className={classes.align__items}>
                                {password && this.hasLowerCase(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                <Typography style={{ marginRight: '5px', color: password && this.hasLowerCase(password) ? '#ccc' : '#000', fontSize: '10px' }}>Password must have lowercase characters</Typography>
                            </div>

                            <div className={classes.align__items}>
                                {password && this.hasNumber(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                <Typography style={{ marginRight: '5px', color: password && this.hasNumber(password) ? '#ccc' : '#000', fontSize: '10px' }}>Password must have numeric characters</Typography>
                            </div>

                            <div className={classes.align__items}>
                                {password && this.hasSpecialCharacters(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                <Typography style={{ marginRight: '5px', color: password && this.hasSpecialCharacters(password) ? '#ccc' : '#000', fontSize: '10px' }}>{`Password must have characters from this set: ^ $ * . { } ( ) ? " ! @ # % & , > <  : ; | _ ~ ' `}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth required
                                error={emailEmpty ? true : false}
                                id="email"
                                label={i18n.t("login.email")}
                                type="email"
                                onChange={event => {
                                    this.setState({ emailEmpty: false });
                                    this.inputs.email = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth
                                id="phone_number"
                                label={i18n.t("login.phoneNumber")}
                                type="tel"
                                placeholder="+1 555 555 1212"
                                onChange={event => {
                                    this.inputs.phone_number = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField autoComplete='off' fullWidth required
                                error={userIdEmpty ? true : false}
                                id="userID"
                                label={i18n.t("login.userId")}
                                type="text"
                                onChange={event => {
                                    this.setState({ userIdEmpty: false });
                                    this.inputs.userID = event.target.value;
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '10px' }}>
                        <Grid item>
                            <Link component='button'
                                onClick={() => this.changeState('signIn')}
                                variant="body2" color="secondary">
                                {i18n.t("login.haveAnAccount")}
                            </Link>
                        </Grid>
                    </Grid>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={generalError !== null}
                        autoHideDuration={6000}
                        onClose={() => {
                            this.setState({
                                generalError: null
                            });
                        }} >
                        <SnackbarContent
                            className={classes.error}
                            message={<Typography className={classes.errorLabel}>{generalError}</Typography>} />
                    </Snackbar>
                
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <div className={classes.buttons}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={this.signUp}
                            >
                                {i18n.t("login.signUp")}
                            </Button>
                            {loading && 
                                <CircularProgress color='secondary' className={classes.buttonSpinner} size={24}/>
                            }
                        </div>
                    </Grid>
                </div>
            </Paper>
        )
    }
}

export default withStyles(styles)(SignUp);