import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Grid,
    Button
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    Phone,
    Drafts,
    Warning
} from '@material-ui/icons'
import { SlideLeft } from '../general/Transitions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as eventsActions from '../../actions/eventsActions'
import * as clientsActions from '../../actions/clientsActions'
import API from '../../lib/api';

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    events__form__title__back: {
        fontSize: '30px'
    },
    event__client__icon: {
        fontSize: '11pt',
        marginRight: '5px',
    },
    events__editButton: {
        textDecoration: 'underline',
        background: 'transparent !important'
    },
    customLink: {
        ...theme.customLink,
        marginRight: theme.spacing(2)
    },
    form: {
        height: '100%'
    },
    formGroup__content__delete: {
        background: '#FFF',
        padding: 0,
        borderBottom: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    event__deleteButton: {
        color: `${theme.palette.error.main} !important`
    },
    warning__message__icon: {
        marginRight: theme.spacing(1),
        fontSize: '14pt',
        color: theme.palette.orange[100]
    },
});


class EventNestedSubListForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            element: props.element,
            client: props.client,
            deleting: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            element: nextProps.element,
            client: nextProps.client
        })
    }

    showdeleteConfirmation = () => {
        this.refs.deleteContactConfirmation.instance.show();
    }

    deleteContact = () => {
        const { element, client } = this.state,
            { type } = this.props;

        this.setState({
            deleting: true
        })

        switch (type) {
            case 'client':

                break;
            case 'clientContact':
                API.Clients.deleteClientContact({
                    clientContact: {
                        ...element,
                        clientId: client.id
                    }
                }).then(res => {
                    if (res.status === 'success') {
                        this.props.clientsActions.deleteClientContact({
                            ...element,
                            clientId: client.id
                        });

                        this.props.deleteClientContact(element, client.id)
                        this.props.onClose()
                    } else {
                        this.setState({
                            deleting: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
                break;
            default:
                break;
        }
    }

    render() {
        const { classes, type } = this.props,
            { element, client } = this.state;

        if (!element) return null
        return (<>
            <Dialog
                classes={{
                    paper: classes.dialog__paper
                }}
                scroll='paper'
                fullScreen={true}
                open={this.props.open}
                TransitionComponent={SlideLeft}
            >

                <>

                    <DialogTitle disableTypography={true} className={classes.events__form__title}>
                        <IconButton onClick={this.props.onClose}>
                            <KeyboardArrowLeft color='inherit' className={classes.events__form__title__back} />
                        </IconButton>
                        <Typography variant='body1' color="inherit">
                            {type === 'client' ? i18n.t('visits.client.client') : i18n.t('visits.client.clientContact')}
                        </Typography>
                        <mobiscroll.Button className={classes.events__editButton} onClick={type === 'client' ? this.props.showClientPopup : this.props.showClientContactPopup}>{i18n.t("visits.client.edit")}</mobiscroll.Button>
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        {type === 'client' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <Grid container spacing={2} style={{ padding: '24px' }} >
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color="textSecondary" style={{ fontSize: '1.2em' }}>
                                                {element.country.name || ''}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant='body1' color="textPrimary" style={{ fontSize: '1.2em' }}>
                                                {element.name || ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color="textSecondary" style={{ fontSize: '1em' }}>
                                                {element.taxId &&
                                                    <>
                                                        {element.taxId || ''}
                                                        <br />
                                                    </>}
                                                {element.address.address1 || ''}
                                                <br />
                                                {element.address.address2 &&
                                                    <>
                                                        {element.address.address2 || ''}
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                        {element.phone &&
                                            <Grid item xs={12}>
                                                <Typography variant='body2' color="textSecondary" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', fontSize: '1em' }}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Phone className={classes.event__client__icon} /> <a style={{ marginLeft: '4px' }} href={`tel://${element.phone}`}>{element.phone}</a>
                                                    </span>
                                                </Typography>
                                            </Grid>
                                        }
                                        {element.fax &&
                                            <Grid item xs={12}>
                                                <Typography variant='body2' color="textSecondary" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', fontSize: '1em' }}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <strong>{i18n.t('quotes.clientRFQ.fax')}</strong><a style={{ marginLeft: '4px' }} href={`tel://${element.fax}`}>{element.fax}</a>
                                                    </span>
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }


                        {type === 'clientContact' &&
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup>
                                    <Grid container spacing={2} style={{ padding: '24px 24px 0' }} >
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color="textSecondary" style={{ fontSize: '1.2em' }}>
                                                {client.name || ''}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant='body1' color="textPrimary" style={{ fontSize: '1.2em' }}>
                                                {element.firstName || ''} {element.lastName || ''}
                                            </Typography>
                                        </Grid>
                                        {element.email &&
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <Drafts className={classes.event__client__icon} />
                                                    <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}
                                                        variant='body2'
                                                        color='textSecondary'>
                                                        <a href={`mailto:${element.email || ''}`}>{element.email || ''}</a>
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        }
                                        {element.phone &&
                                            <Grid item xs={12}>
                                                <Typography variant='body2' color="textSecondary" style={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                                    <Phone className={classes.event__client__icon} /><a href={`tel://${element.phone}`}>{element.phone}</a>
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </mobiscroll.FormGroup>

                                <mobiscroll.FormGroup>
                                    <mobiscroll.FormGroupContent
                                        className={classes.formGroup__content__delete}>
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <Button
                                                size='small'
                                                className={classes.event__deleteButton}
                                                onClick={this.showdeleteConfirmation}
                                            >
                                                {i18n.t("visits.eventDetails.deleteContactButton")}
                                            </Button>
                                        </div>
                                    </mobiscroll.FormGroupContent>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        }
                    </DialogContent>
                </>

                {/* DELETE EVENT CONFIRMATION POPUP */}
                <mobiscroll.Popup
                    ref="deleteContactConfirmation"
                    display="center"
                    buttons={[
                        {
                            text: i18n.t('visits.eventDetails.deleteContactButton'),
                            icon: 'delete',
                            handler: this.deleteContact
                        },
                        'cancel'
                    ]}
                >
                    <div className="mbsc-align-center mbsc-padding">
                        <Typography variant='body2' color='textSecondary' style={{ display: 'flex' }}>
                            <Warning className={classes.warning__message__icon} />
                            {i18n.t('visits.confirmation.deleteContactMessage')}
                        </Typography>
                    </div>
                </mobiscroll.Popup>
            </Dialog>
        </>);
    }
}

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch),
        clientsActions: bindActionCreators(clientsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventNestedSubListForm));
