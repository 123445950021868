import {
    SET_CLIENTS,
    ADD_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    ADD_CLIENT_CONTACT,
    UPDATE_CLIENT_CONTACT,
    DELETE_CLIENT_CONTACT,
} from '../actions/clientsActions'


function clients(state = [], action) {
    let index = null,
        contactIndex = null;
    switch (action.type) {
        case SET_CLIENTS:
            return [...action.clients].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            })
        case ADD_CLIENT:
            return [
                { ...action.client },
                ...state
            ]
                // .sort((a, b) => {
                //     if (a.name < b.name) return -1;
                //     if (a.name > b.name) return 1;
                //     return 0
                // })
        case UPDATE_CLIENT:
            index = state.findIndex(c => c.id === action.client.id);
            return [
                ...state.slice(0, index),
                {
                    ...action.client
                },
                ...state.slice(index + 1)
            ].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            })
        case DELETE_CLIENT:
            return state.filter(c => c.id !== action.client.id)
        case ADD_CLIENT_CONTACT:
            index = state.findIndex(c => c.id === action.contact.clientId);
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        contacts: [
                            action.contact,
                            ...state[index].contacts
                        ].sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0
                        })
                    },
                    ...state.slice(index + 1)
                ]
            }
            return state
        case UPDATE_CLIENT_CONTACT:
            index = state.findIndex(c => c.id === action.contact.clientId);
            if (index >= 0) {
                contactIndex = state[index].contacts.findIndex(c => c.id === action.contact.id);
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        contacts: [
                            ...state[index].contacts.slice(0, contactIndex),
                            { ...action.contact },
                            ...state[index].contacts.slice(contactIndex + 1)
                        ].sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0
                        })
                    },
                    ...state.slice(index + 1)
                ]
            }

            return state
        case DELETE_CLIENT_CONTACT:
            index = state.findIndex(c => c.id === action.contact.clientId);
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        contacts: state[index].contacts.filter(c => c.id !== action.contact.id)
                    },
                    ...state.slice(index + 1)
                ]
            }
            return state
        default:
            return state
    }
}
export default clients