import React from 'react'
import {
    IconButton,
    Snackbar,
    SnackbarContent
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: 'white',
    },
    error: {
        backgroundColor: 'white',
        color: '#3c3c3c'
    },
    info: {
        backgroundColor: 'white',//theme.palette.primary.main,
        color: '#3c3c3c'
    },
    warning: {
        backgroundColor: amber[700],
        color: '#3c3c3c'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    'message--info': {
        color: '#808080'
    },
    'message--error': {
        color: '#3c3c3c'
    },
    'message--warning': {
        color: '#3c3c3c'
    },
    'message--success': {
        color: '#3c3c3c'
    },

    'icon--info': {
        color: '#808080'
    },
    'icon--error': {
        color: theme.palette.error.dark
    },
    'icon--warning': {
        color: amber[700],
    },
    'icon--success': {
        color: green[600],
    }

}));

export default function CustomSnackBar(props) {
    const classes = useStyles();
    const { className, message, onClose, variant, hideCloseButton, disableDismiss, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={props.open || false}
            autoHideDuration={6000}
            onClose={!disableDismiss ? onClose : null}
        >
            <SnackbarContent
                className={`${classes[variant]} ${className}`}
                message={
                    <span
                        id="client-snackbar"
                        className={`${classes.message} ${classes[`message--${variant}`]}`}>
                        <Icon
                            className={`${classes.icon} ${classes.iconVariant} ${classes[`icon--${variant}`]}`} />
                        {message}
                    </span>
                }
                action={[
                    !hideCloseButton ? (
                        <IconButton
                            key="close"
                            color="inherit"
                            onClick={onClose}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>) : null,
                ]}
                {...other}
            />
        </Snackbar>
    )
}